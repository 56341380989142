import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

const TextArea = React.forwardRef((props, ref) => {
  const {className, error, errorMsg, id, autofocus, ...rest} = props;
  let containerClassName = `ikt-ap_textarea${error ? '_error' : ''}${className ? ` ${className}` : ``}`;
  const { t } = useTranslation();

  return (
    <div className="ikt-ap_textarea-container">
      <textarea
        autoFocus={autofocus}
        className={containerClassName}
        id={id}
        placeholder={t('enter')}
        {...rest}
        ref={ref}
      />
      {errorMsg && <span className="ikt-ap_input-error-msg">{errorMsg}</span>}
    </div>
  )
});

export default TextArea;

