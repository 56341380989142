import BaseAPIService from './Base';

export default class ManufacturerProvidersAPIService extends BaseAPIService {
  getManufacturerDeliveryTypes = async () => {
    return this.request('api/manufacturers/provider/delivery-types').then((data) => {
      return data;
    });
  };

  getManufacturerStatuses = async () => {
    return this.request('api/manufacturers/provider/statuses').then((data) => {
      return data;
    });
  };

  getManufacturerLocations = async () => {
    return this.request('api/manufacturers/provider/locations').then((data) => {
      return data;
    });
  };
}
