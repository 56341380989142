import BaseAPIService from './Base';

export default class RequestsAPIService extends BaseAPIService {
  getRequests = async (page) => {
    return this.request(`api/approvals/manufacturer/pending${page ? `?page=${page}` : ''}`).then((data) => {
      return data;
    });
  };

  approveRequest = async (id) => {
    return await this.request(`/api/approvals/${id}/approve`, {
      method: 'POST'
    });
  };

  rejectRequest = async (id) => {
    return await this.request(`/api/approvals/${id}/reject`, {
      method: 'POST'
    });
  };

  filterRequests = async (filters) => {
    let url = `?${filters.page && filters.page !== '' ? `page=${filters.page}` : '?'}${filters.type && filters.type !== '' ? `&filter[type]=${filters.type}` : ''}${filters.customer_id && filters.customer_id !== '' ? `&filter[customer_id]=${filters.customer_id}` : ''}`;
    return this.request(`api/approvals/manufacturer/pending${url}`).then((data) => {
      return data;
    });
  };
}
