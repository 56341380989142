import React from 'react';
import { withTranslation } from 'react-i18next';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './style.scss';
import GridItem from "components/Grid/GridItem";
import CloseButton from "../../assets/img/buttons/delete.svg";
import IconButton from "@material-ui/core/IconButton";
import CustomDatePicker from "../../components/CustomDatePicker";
import Input from 'components/Input/Input';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import TextArea from "../../components/CustomTextArea";
import moment from "moment";
import { ErrorTooltip, ErrorTooltipAlt } from '../../components/ErrorTooltip/ErrorTooltip';
import Autocomplete from "../../components/CustomAutocomplete";

import {connect} from "react-redux";
import {
  getCostCenterItemsList,
  getCostCentersList,
  getOrdersList,
  getPositionsList,
  getPositionCostCentersList,
  getPositionCostCenterItemsList,
  resetProviders,
} from "../../store/actions/timeSheetsProviders";

class TimeEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: true,
      selectedOrder: '',
      selectedPosition: '',
      selectedPositionCostCenter: '',
      selectedPositionCostCenterItem: '',
      selectedCostCenter: '',
      selectedCostCenterItem: '',
    };
  }

  componentDidMount() {
    const {
      isEdit,
      data,
      onChange,
    } = this.props;
    if (isEdit) {
      if (data.loggable_type == "App\\Models\\PositionCostCenter") {
        this.setState({
          selectedOrder: data.loggable_meta.order_id,
          selectedPosition: data.loggable_meta.position_id,
          selectedPositionCostCenter: data.loggable_id ? data.loggable_id : '',
        }, () => {
          this.props.getPositionsList(data.loggable_meta.order_id);
          this.props.getPositionCostCentersList(data.loggable_meta.position_id);
        });
      } else if (data.loggable_type == "App\\Models\\PositionCostCenterItem") {
        this.setState({
          selectedOrder: data.loggable_meta.order_id,
          selectedPosition: data.loggable_meta.position_id,
          selectedPositionCostCenter: data.loggable_meta.position_cost_center_id,
          selectedPositionCostCenterItem: data.loggable_id ? data.loggable_id : '',
        }, () => {
          this.props.getPositionsList(data.loggable_meta.order_id);
          this.props.getPositionCostCentersList(data.loggable_meta.position_id);
          this.props.getPositionCostCenterItemsList(data.loggable_meta.position_cost_center_id);
        });
      } else if (data.loggable_type == "App\\Models\\CostCenter") {
        this.setState({
          selectedCostCenter: data.loggable_id,
        }, () => {
          this.props.getCostCenterItemsList(data.loggable_id);
        });
      } else if (data.loggable_type == "App\\Models\\CostCenterItem") {
        this.setState({
          selectedCostCenter: data.loggable_meta ? data.loggable_meta.cost_center_id : '',
          selectedCostCenterItem: data.loggable_id,
        }, () => {
          if (data.loggable_meta && data.loggable_meta.cost_center_id)
            this.props.getCostCenterItemsList(data.loggable_meta.cost_center_id);;
        });
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

  onCardClose = () => {
    this.setState({isCardOpen: !this.state.isCardOpen})
  };

  handleInputChange = (fieldName, value) => {
    const {data, onChange} = this.props;
    const newData = {...data};
    if (fieldName === 'time') {
      if (value &&
        (!/^\d+([.]\d*)?$/.test(value) || isNaN(parseFloat(value))))
        return;
    }
    newData[fieldName] = value;
    if (fieldName === 'type') {
      if (value == 0) {
        newData.loggable_id = this.state.selectedPositionCostCenter;
      } else if (value == 1) {
        newData.loggable_id = this.state.selectedPositionCostCenterItem;
      } else if (value == 2) {
        newData.loggable_id = this.state.selectedCostCenter;
      } else if (value == 3) {
        newData.loggable_id = this.state.selectedCostCenterItem;
      }
    }
    onChange(newData);
  };

  handleTypeChange = (id) => {
    let newId = null;
    if (id == 0 || id == 1) {
      if (this.state.selectedPositionCostCenterItem !== null && this.state.selectedPositionCostCenterItem !== undefined && this.state.selectedPositionCostCenterItem !== '') {
        newId = 1;
      } else {
        newId = 0;
      }
    } else if (id == 2 || id == 3) {
      if (this.state.selectedCostCenterItem !== null && this.state.selectedCostCenterItem !== undefined && this.state.selectedCostCenterItem !== '')  {
        newId = 3;
      } else {
        newId = 2;
      }
    }
    this.handleInputChange('type', newId);
  }

  handleOrdersDropdownChange = (value) => {
    const {getPositionsList, onChange, data} = this.props;
    if (this.state.selectedOrder !== value) {
      this.setState({
        selectedOrder: value,
        selectedPosition: '',
        selectedPositionCostCenter: '',
        selectedPositionCostCenterItem: '',
        selectedCostCenter: '',
        selectedCostCenterItem: '',
      }, () => {
        onChange({
          ...data,
          loggable_id: '',
        });
        getPositionsList(value);
      });
    }
  }

  handlePositionDropdownChange = (value) => {
    const {getPositionCostCentersList, onChange, data} = this.props;
    if(this.state.selectedPosition != value) {
      this.setState({
        selectedPosition: value,
        selectedPositionCostCenter: '',
        selectedPositionCostCenterItem: '',
        selectedCostCenter: '',
        selectedCostCenterItem: '',
      }, () => {
        onChange({
          ...data,
          loggable_id: '',
        });
        getPositionCostCentersList(value);
      });
    }
  }

  handlePositionCostCenterDropdownChange = (value) => {
    const { onChange, data, getPositionCostCenterItemsList } = this.props;
    if(this.state.selectedPositionCostCenter != value) {
      this.setState({
        selectedPositionCostCenter: value,
        selectedPositionCostCenterItem: '',
      }, () => {
        onChange({
          ...data,
          type: 0,
          loggable_id: value,
        });
        getPositionCostCenterItemsList(value);
      });
    }
  }

  handlePositionCostCenterItemDropdownChange = (value) => {
    const { onChange, data } = this.props;
    const { selectedPositionCostCenter } = this.state;
    if(this.state.selectedPositionCostCenterItem != value) {
      this.setState({
        selectedPositionCostCenterItem: value,
      }, () => {
        onChange({
          ...data,
          type: value !== -1 ? 1 : 0,
          loggable_id: value !== -1 ? value : selectedPositionCostCenter,
        });
      });
    }
  }

  handleCostCenterDropdownChange = (value) => {
    const { getCostCenterItemsList, onChange, data } = this.props;
    if(this.state.selectedCostCenter != value) {
      this.setState({
        selectedCostCenter: value,
        selectedCostCenterItem: '',
      }, () => {
        onChange({
          ...data,
          loggable_id: value,
          type: 2, 
        });
        getCostCenterItemsList(value);
      });
    }
  }

  handleCostCenterItemDropdownChange = (value) => {
    const { onChange, data } = this.props;
    const { selectedCostCenter } = this.state;
    if(this.state.selectedCostCenterItem != value) {
      this.setState({
        selectedCostCenterItem: value,
      }, () => {
        onChange({
          ...data,
          type: value !== -1 ? 3 : 2,
          loggable_id: value !== -1 ? value : selectedCostCenter,
        });
      });
    }
  }

  render() {
    const {
      data,
      id,
      onRemoveEntry,
      ordersList,
      costCentersList,
      costCentersItemsList,
      orderPositionsList,
      positionCostCentersList,
      positionCostCenterItemsList,
      order,
      errors,
      isEdit,
      t
    } = this.props
    const {
      isCardOpen,
      selectedOrder,
      selectedPosition,
      selectedPositionCostCenter,
      selectedPositionCostCenterItem,
      selectedCostCenter,
      selectedCostCenterItem,
    } = this.state;
    
    const ordersListActual = ordersList;
    const positionsList = orderPositionsList[selectedOrder] ? orderPositionsList[selectedOrder] : [];
    const positionCostCentersItemsListActual = [{id: -1, value: t('none')}, ...positionCostCenterItemsList];
    const costCentersItemsListActual = [{id: -1, value: t('none')}, ...costCentersItemsList];
    const actualErrors = errors ? errors : {};
    const sanitizeTooltipErrorTitle = error => (error ? error[0] : "");
    return (
      <div className="time-entry-content">
        <GridItem className="time-entry-info" xs={12}>
          <div className="time-entry-title">
            <span className="entry-title">{`${t('time_entry')} ${!isEdit ? id + 1 : ''}`}</span>
            { !isEdit &&
              <IconButton
                aria-label="close"
                className="remove-btn"
                onClick={onRemoveEntry}
              >
                <img alt="remove-time-entry-btn" src={CloseButton}/>
              </IconButton>
            }
          </div>
          {isCardOpen &&
            <div className="time-entry-info-content">
              { !order ? <div className="timer-entry-type">
                <span>{ t('type') }</span>
                <div className="timer-type-items">
                  <div
                    className={`timer-type ${data.type === 0 || data.type == 1 ? 'selected' : ''}`}
                    onClick={() => this.handleTypeChange(0)}
                  >
                    <div>{ t('operational') }</div>
                  </div>
                  <div
                    className={`timer-type ${data.type === 2 || data.type === 3 ? 'selected' : ''}`}
                    onClick={() => this.handleTypeChange(2)}
                  >
                    <div>{ t('non_operational') }</div>
                  </div>
                </div>
              </div> : null }
              <div className="timer-date-picker-container">
                <div className="timer-entry-data-item">
                  <span className="timer-entry-data-item-title">{ t('date') }</span>
                  <CustomDatePicker
                    className="timer-entry-date-picker"
                    handleChange={date => this.handleInputChange('entry_date', moment(date).format('YYYY-MM-DD'))}
                    selectedDate={data.entry_date}
                  />
                </div>
                <div className="timer-entry-data-item time-hours">
                  <span className="timer-entry-data-item-title">{ t('time') } ( { t('e_g') }: 2h 20min = 2.33)</span>
                  <ErrorTooltip title={sanitizeTooltipErrorTitle(actualErrors.time)}>
                    <Input
                      value={data.time}
                      onChange={e => this.handleInputChange('time', e.target.value)}
                      error={actualErrors.time} />
                  </ErrorTooltip>
                </div>
              </div>
              {
                data.type === 0 || data.type == 1
                  ? <div className="timer-dropdowns">
                      <div className="timer-dropdowns-row">
                        <div className="timer-entry-data-item">
                          <span className="timer-entry-data-item-title">{ t('order') }</span>
                          <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(actualErrors.loggable_id)}>
                            <Autocomplete
                              buttonText={selectedOrder}
                              buttonProps={{className: '', round: true, size: "sm"}}
                              dropdownList={ordersListActual}
                              error={actualErrors.loggable_id}
                              hoverColor="dark"
                              getData={(term) => this.props.getOrdersList(term)}
                              onClick={(value) => this.handleOrdersDropdownChange(value.id)}
                              placeholder={t('enter')}
                              secondaryValueField={'order_no'}
                              displayTextFormat={'[{2}] {1}'}
                              discardIcon={true}
                            />
                          </ErrorTooltipAlt>
                        </div>
                        <div className="timer-entry-data-item">
                          <span className="timer-entry-data-item-title">{ t('position') }</span>
                          <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(actualErrors.loggable_id)}>
                            <Dropdown
                              buttonText={selectedPosition}
                              buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedOrder}}
                              dropdownList={positionsList}
                              onClick={(value) => this.handlePositionDropdownChange(value.id)}
                              error={actualErrors.loggable_id}
                              customClass={"time-entry-dropdown"}
                            />
                          </ErrorTooltipAlt>
                        </div>
                      </div>
                      <div className="timer-dropdowns-row">
                        <div className="timer-entry-data-item">
                          <span className="timer-entry-data-item-title">{ t('cost_center') }</span>
                          <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(actualErrors.loggable_id)}>
                            <Dropdown
                              buttonText={selectedPositionCostCenter ? selectedPositionCostCenter : -1}
                              buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedOrder || !selectedPosition}}
                              dropdownList={positionCostCentersList}
                              onClick={(value) => this.handlePositionCostCenterDropdownChange(value.id)}
                              error={actualErrors.loggable_id}
                              customClass={"time-entry-dropdown"}
                            />
                          </ErrorTooltipAlt>
                        </div>
                        <div className="timer-entry-data-item">
                          <span className="timer-entry-data-item-title">{ t('cost_center_item') }</span>
                          <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(actualErrors.loggable_id)}>
                            <Dropdown
                              buttonText={selectedPositionCostCenterItem ? selectedPositionCostCenterItem : -1}
                              buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedOrder || !selectedPosition || !selectedPositionCostCenter }}
                              dropdownList={positionCostCentersItemsListActual}
                              onClick={(value) => this.handlePositionCostCenterItemDropdownChange(value.id)}
                              error={actualErrors.loggable_id}
                              customClass={"time-entry-dropdown"}
                            />
                          </ErrorTooltipAlt>
                        </div>
                      </div>
                    </div>
                  : <div className="timer-dropdowns-alt">
                      <div className="timer-entry-data-item">
                        <span className="timer-entry-data-item-title">{ t('cost_center') }</span>
                        <Dropdown
                          buttonText={selectedCostCenter ? selectedCostCenter : -1}
                          buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm"}}
                          dropdownList={costCentersList}
                          onClick={(value) => this.handleCostCenterDropdownChange(value.id)}
                          error={actualErrors.loggable_id}
                          customClass={"time-entry-dropdown"}
                        />
                      </div>
                      <div className="timer-entry-data-item">
                        <span className="timer-entry-data-item-title">{ t('cost_center_item') }</span>
                        <Dropdown
                          buttonText={selectedCostCenterItem ? selectedCostCenterItem : -1}
                          buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedCostCenter }}
                          dropdownList={costCentersItemsListActual}
                          onClick={(value) => this.handleCostCenterItemDropdownChange(value.id)}
                          error={actualErrors.loggable_id}
                          customClass={"time-entry-dropdown"}
                        />
                      </div>
                    </div>
              }
              <div className="timer-entry-note">
                <div className="timer-entry-data-item">
                  <span className="timer-entry-data-item-title">{ t('note') }</span>
                  <div className="description-test-area">
                    <ErrorTooltip title={sanitizeTooltipErrorTitle(actualErrors.description)}>
                      <TextArea
                        value={data.description}
                        onChange={e => this.handleInputChange('description', e.target.value)}
                        error={actualErrors.description} />
                    </ErrorTooltip>
                  </div>
                </div>
              </div>
            </div>
          }
        </GridItem>
        <div
          className="card-btn"
          onClick={() => this.onCardClose()}
        >
          <div>
            {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProp = state => {
  return {
    ordersList: state.timeSheetsProviders.ordersList,
    orderPositionsList: state.timeSheetsProviders.positionsList,
    positionCostCentersList: state.timeSheetsProviders.positionCostCentersList,
    positionCostCenterItemsList: state.timeSheetsProviders.positionCostCenterItemsList,
    costCentersList: state.timeSheetsProviders.costCentersList,
    costCentersItemsList: state.timeSheetsProviders.costCentersItemsList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrdersList: (term) => dispatch(getOrdersList(term)),
    getPositionsList: (id) => dispatch(getPositionsList(id)),
    getPositionCostCentersList: (id) => dispatch(getPositionCostCentersList(id)),
    getPositionCostCenterItemsList: (id) => dispatch(getPositionCostCenterItemsList(id)),
    getCostCentersList: () => dispatch(getCostCentersList()),
    getCostCenterItemsList: (id) => dispatch(getCostCenterItemsList(id)),
    resetProviders: () => dispatch(resetProviders())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(TimeEntry));
