import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {timeSheetsProvidersServiceApi} from 'services/API';

import {
  getHoursLoggedSuccess,
  getHoursLoggedFailure,
  getTasksListSuccess,
  getTasksListFailure,
  getCostCenterItemsListSuccess,
  getCostCenterItemsListFailure,
  getCostCentersListSuccess,
  getCostCentersListFailure,
  getPositionCostCenterItemsListSuccess,
  getPositionCostCenterItemsListFailure,
  getPositionCostCentersListSuccess,
  getPositionCostCentersListFailure,
  getOrdersListSuccess,
  getOrdersListFailure,
  getPositionsListSuccess,
  getPositionsListFailure,
} from 'store/actions/timeSheetsProviders';
import {
  GET_HOURS_LOGGED,
  GET_ORDERS_LIST,
  GET_POSITIONS_LIST,
  GET_TASKS_LIST,
  GET_COST_CENTERS_LIST,
  GET_COST_CENTERS_ITEMS_LIST,
  GET_POSITION_COST_CENTERS_LIST,
  GET_POSITION_COST_CENTERS_ITEMS_LIST,
} from 'store/actionTypes/timeSheetsProvidersActionType';

export const getHoursLoggedEpic = (actions$) => actions$.pipe(
  ofType(GET_HOURS_LOGGED),
  switchMap((action) => from(timeSheetsProvidersServiceApi.getHoursLogged(action.payload.dateFrom, action.payload.dateTo)).pipe(
    switchMap((response) => {
      return of(getHoursLoggedSuccess(response.data));
    }),
    catchError(err => {
      return of(getHoursLoggedFailure(err));
    })
  ))
);

export const getOrdersListEpic = (actions$) => actions$.pipe(
  ofType(GET_ORDERS_LIST),
  switchMap((action) => from(timeSheetsProvidersServiceApi.getOrdersList(action.data)).pipe(
    switchMap((response) => {
      return of(getOrdersListSuccess(response.data));
    }),
    catchError(err => {
      return of(getOrdersListFailure(err));
    })
  ))
);

export const getPositionsListEpic = (actions$) => actions$.pipe(
  ofType(GET_POSITIONS_LIST),
  switchMap((action) => from(timeSheetsProvidersServiceApi.getPositionsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getPositionsListSuccess(action.payload, response.data));
    }),
    catchError(err => {
      return of(getPositionsListFailure(err));
    })
  ))
);

export const getTasksListEpic = (actions$) => actions$.pipe(
  ofType(GET_TASKS_LIST),
  switchMap((action) => from(timeSheetsProvidersServiceApi.getTasksList(action.payload)).pipe(
    switchMap((response) => {
      return of(getTasksListSuccess(action.payload, response.data));
    }),
    catchError(err => {
      return of(getTasksListFailure(err));
    })
  ))
);

export const getCostCentersListEpic = (actions$) => actions$.pipe(
  ofType(GET_COST_CENTERS_LIST),
  switchMap(() => from(timeSheetsProvidersServiceApi.getCostCentersList()).pipe(
    switchMap((response) => {
      return of(getCostCentersListSuccess(response.data));
    }),
    catchError( err => {
      return of(getCostCentersListFailure(err));
    })
  ))
);

export const getCostCenterItemsListEpic = (actions$) => actions$.pipe(
  ofType(GET_COST_CENTERS_ITEMS_LIST),
  switchMap((action) => from(timeSheetsProvidersServiceApi.getCostCenterItemsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getCostCenterItemsListSuccess(response.data));
    }),
    catchError(err => {
      return of(getCostCenterItemsListFailure(err));
    })
  ))
);

export const getPositionCostCentersListEpic = (actions$) => actions$.pipe(
  ofType(GET_POSITION_COST_CENTERS_LIST),
  switchMap((action) => from(timeSheetsProvidersServiceApi.getPositionCostCentersList(action.payload)).pipe(
    switchMap((response) => {
      return of(getPositionCostCentersListSuccess(response.data));
    }),
    catchError( err => {
      return of(getPositionCostCentersListFailure(err));
    })
  ))
);

export const getPositionCostCenterItemsListEpic = (actions$) => actions$.pipe(
  ofType(GET_POSITION_COST_CENTERS_ITEMS_LIST),
  switchMap((action) => from(timeSheetsProvidersServiceApi.getPositionCostCenterItemsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getPositionCostCenterItemsListSuccess(response.data));
    }),
    catchError(err => {
      return of(getPositionCostCenterItemsListFailure(err));
    })
  ))
);
