import React from "react";
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {getUserOwn, resetForgotPasswordError} from 'store/actions/auth';
import {removeUser, resetUserData} from 'store/actions/usersData';
import {forgotPassword} from "../../store/actions/auth";

import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import GridItem from '../../components/Grid/GridItem';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { translationSupportedLanguages } from 'constants/constants';

import './style.scss';
import RemoveDialog from "../RemoveDialog";
import ResetPasswordDialog from "./PasswordResetDialog";

class ViewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isResetDialogOpen: false,
      isRemoveUserOpen: false,
    }
  }

  isShowingLoggedInUser = () => {
    const { id } = this.props;
    const loggedInUserId = localStorage.getItem("authUserId");
    return loggedInUserId == id;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.isForgotPasswordReady === false && this.props.auth.isForgotPasswordReady) {
      this.setState(
        {isResetDialogOpen: true}
      );
    }
  }

  componentWillUnmount() {
    const { resetData, getUserOwn } = this.props;

    if (!this.isShowingLoggedInUser()) {
      resetData();
    } else {
      getUserOwn();
    }
  }

  handleResetPassword = () => {
    const {
      forgotPassword,
      userData,
      user
    } = this.props;
    const actualUser = !this.isShowingLoggedInUser() ? user : userData;
    const data = {email: actualUser.email};
    forgotPassword(JSON.stringify(data))
  };

  handleResetDialogClose = () => {
    const {resetForgotPasswordError} = this.props;
    resetForgotPasswordError();
    this.setState({
      isResetDialogOpen: false
    })
  };

  handleDeleteButton = () => {
    this.setState({
      isRemoveUserOpen: true
    });
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveUserOpen: false,
    });
  }

  handleRemoveUser = () => {
    const {
      metaData,
      onClose,
      removeUser,
      filters,
      searchTerms,
      id
    } = this.props;
    removeUser({id, page: metaData.current_page, filters, searchTerms});
    onClose();
  }

  render() {
    const {
      isOpen,
      onClose,
      id,
      user,
      userData,
      onEditUser,
      t
    } = this.props;
    const {isRemoveUserOpen, isResetDialogOpen} = this.state;
    const actualUser = !this.isShowingLoggedInUser() ? user : userData;
    const role = actualUser.roles && actualUser.roles.length > 0 ? actualUser.roles.map(r => r.name).join(', ') : '';
    const userLanguage = translationSupportedLanguages.find(el => el.id === actualUser.selected_language);
    const language = userLanguage ? userLanguage.value : '';
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="view-user-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ t('view_user') }</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img
                alt="view-user-close-btn"
                src={CloseButton}
              />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="view-user-content">
          <div className="user-title-container">
            <GridItem xs={6}>
              <div className="user-name">{`${actualUser.first_name} ${actualUser.last_name ? actualUser.last_name : ''}`}</div>
            </GridItem>
            <GridItem xs={6}>
              <div className="view-user-btn">
                <Button
                  className="cancel-btn"
                  onClick={() => this.handleDeleteButton()}
                  disabled={this.isShowingLoggedInUser()}
                >
                  { t('delete').toUpperCase() }
                </Button>
                <Button
                  className="create-btn"
                  onClick={() => onEditUser(id)}
                >
                  { t('edit').toUpperCase() }
                </Button>
              </div>
            </GridItem>
          </div>
          <div className="user-data-container">
            <div className="user-info-field">
              <div className="user-info-item">
                <GridItem xs={4} className="user-info-title">{ t('email') }</GridItem>
                <GridItem xs={8}>{actualUser.email}</GridItem>
              </div>
              <div className="user-info-item">
                <GridItem xs={4} className="user-info-title">{ t('phone') }</GridItem>
                <GridItem xs={8}>{actualUser.phone}</GridItem>
              </div>
              <div className="user-info-item">
                <GridItem xs={4} className="user-info-title">{ t('role') }</GridItem>
                <GridItem xs={8}>{role}</GridItem>
              </div>
              <div className="user-info-item">
                <GridItem xs={4} className="user-info-title">{ t('language') }</GridItem>
                <GridItem xs={8}>{language}</GridItem>
              </div>
            </div>
            <div className="user-info-field">
              <div className="user-info-item">
                <GridItem xs={12} className="user-info-title">{ t('note') }</GridItem>
              </div>
              <div className="user-info-item">
                <GridItem xs={12}>
                  {actualUser.notes}
                </GridItem>
              </div>
            </div>
          </div>
          <div className="user-title-container">
            <GridItem xs={6}>
              <div className="user-name">{ t('account_details') }</div>
            </GridItem>
          </div>
          <div className="user-data-container">
            <div className="user-info-field">
              <div className="user-info-item">
                <GridItem xs={4} className="user-info-title">{ t('email') }</GridItem>
                <GridItem xs={3}>{actualUser.email}</GridItem>
              </div>
            </div>
          </div>
          <div className="user-title-container">
            <GridItem xs={6}>
              <div className="view-user-btn">
                <Button
                  className="reset-btn"
                  onClick={this.handleResetPassword}
                >
                  { t('reset_password').toUpperCase() }
                </Button>
              </div>
            </GridItem>
          </div>
        </div>
        {isRemoveUserOpen && (
          <RemoveDialog
            isOpen={isRemoveUserOpen}
            title={t('remove_user')}
            text={t('remove_user_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleRemoveUser}
          />
        )}
        {
          isResetDialogOpen &&
          <ResetPasswordDialog
            isOpen={isResetDialogOpen}
            email={actualUser.email}
            onClose={this.handleResetDialogClose}
          />
        }
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    auth: state.auth,
    user: state.users.user,
    userData: state.auth.userData,
    metaData: state.users.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserOwn: () => dispatch(getUserOwn()),
    resetData: () => dispatch(resetUserData()),
    removeUser: (data) =>  dispatch(removeUser(data)),
    forgotPassword: data => dispatch(forgotPassword(data)),
    resetForgotPasswordError: () => dispatch(resetForgotPasswordError())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ViewUser));
