import BaseAPIService from './Base';

export default class InvoicesAPIService extends BaseAPIService {
  getInvoices = async (orderId, searchTerms, filters, sortBy, sortByOrder) => {
    const query = `?${filters.page && filters.page !== '' ? `page=${filters.page}` : ''}${filters.dateFrom && filters.dateFrom !== '' ? `&filter[issued_at][from]=${filters.dateFrom}` : ''}${filters.dateTo && filters.dateTo !== '' ? `&filter[issued_at][to]=${filters.dateTo}` : ''}${sortBy ? `&order=${sortByOrder ? '' : '-'}${sortBy}` : ''}${searchTerms ? ('&search-term=' + searchTerms) : ''}`;
    return this.request(`api/orders/${orderId}/invoices${query}`).then((data) => {
      return data;
    });
  };

  getAllInvoices = async (searchTerms, filters, sortBy, sortByOrder) => {
    const query = `?${filters.page && filters.page !== '' ? `page=${filters.page}` : ''}${filters.type ? `&filter[type]=${filters.type}` : ''}${filters.dateFrom && filters.dateFrom !== '' ? `&filter[issued_at][from]=${filters.dateFrom}` : ''}${filters.dateTo && filters.dateTo !== '' ? `&filter[issued_at][to]=${filters.dateTo}` : ''}${sortBy ? `&order=${sortByOrder ? '' : '-'}${sortBy}` : ''}${searchTerms ? ('&search-term=' + searchTerms) : ''}`;
    return this.request(`api/invoices${query}`).then((data) => {
      return data;
    });
  };

  createInvoice = async (orderId, data) => {
    return this.request(`api/orders/${orderId}/invoices-batch`, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((data) => {
      return data;
    });
  };

  updateInvoice = async (invoiceId, data) => {
    return this.request(`api/orders/invoices-batch/${invoiceId}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    }).then((data) => {
      return data;
    });
  };

  deleteInvoice = async (id) => {
    return this.request(`api/orders/invoices/${id}`, {
      method: 'DELETE',
    });
  };

  setInvoiceStatus = async (invoiceId, statusId) => {
    return this.request(`api/invoices/${invoiceId}/status/${statusId}`, {
      method: 'PUT'
    }).then((data) => {
      return data;
    });
  }

  downloadInvoicePDF = async (id) => {
    return this.requestFile(`api/orders/invoices/${id}/export/pdf/download`, {
      method: 'GET',
    });
  }

  downloadInvoiceOpenDocument = async (id) => {
    return this.requestFile(`api/orders/invoices/${id}/export/odt/download`, {
      method: 'GET',
    });
  }

  downloadInvoiceOTF = async (id) => {
    return this.requestFile(`api/orders/invoices/${id}/export/rtf/download`, {
      method: 'GET',
    });
  }

  previewInvoice = async (data) => {
    return this.requestFile(`api/invoices/preview`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }
}
