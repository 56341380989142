import BaseAPIService from './Base';

export default class ManufacturerOrderPositionAPIService extends BaseAPIService {
  createManufacturerOrderPosition = async (data) => {
    return this.request('api/manufacturers/orders/1/positions', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  updateManufacturerOrderPosition = async (id, data) => {
    return this.request(`api/manufacturers/orders/positions/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  getManufacturerOrderPosition = async (id) => {
    return this.request(`api/manufacturers/orders/positions/${id}`).then((data) => {
      return data;
    });
  };

  deleteManufacturerOrderPosition = async (id) => {
    return this.request(`api/manufacturers/orders/positions/${id}`, {
      method: 'DELETE',
    });
  };

  getManufacturerOrderPositionIndex = async () => {
    return this.request('api/manufacturers/orders/1/positions').then((data) => {
      return data;
    });
  };
}
