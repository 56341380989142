import React from 'react';
import { connect } from 'react-redux';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Input from 'components/Input/Input';
import InputAdornment from "@material-ui/core/InputAdornment";
import InputWithIcon from 'components/InputWithIcon';
import IconButton from '@material-ui/core/IconButton';
import Description from '../../EditDescription/Description';
import CustomAutocomplete from "components/CustomAutocomplete";
import Dropdown from "components/CustomDropdown/CustomDropdown";
import { ErrorTooltip, ErrorTooltipAlt } from 'components/ErrorTooltip/ErrorTooltip';
import DescriptionIcon from "../../../assets/img/inputIcons/btn-edit-textinput.svg";
import DeleteIcon from '../../../assets/img/buttons/delete.svg';
import DuplicateIcon from '../../../assets/img/buttons/btn-copy.svg';
import EditIcon from '../../../assets/img/buttons/edit.svg';

import { calculateMaterialRetailPrice, formatNumericValue } from 'utils/utils';
import { getFittingsMaterialsList } from 'store/actions/materialProviders';

import './style.scss';

class Fitting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDescriptionOpen: false,
    };
  }

  handleInputValueChange = (fieldName, value) => {
    const { data, fittingsMaterials, onDataChange } = this.props;
    if (fieldName == 'amount' && value <= 0)
      return;
    let updatedData = {
      ...data,
      [fieldName]: value,
    };
    if (fieldName == 'material_id') {
      if (!isNaN(value) && fittingsMaterials.find(el => el.id == value))
        updatedData['material'] = fittingsMaterials.find(el => el.id == value);
      else
        updatedData['material'] = null;
    }
    onDataChange(updatedData);
  }

  handleToggleDescription = () => {
    this.setState({ isDescriptionOpen: !this.state.isDescriptionOpen });
  };

  handleOpenDescription = () => {
    if (!this.state.isDescriptionOpen)
      this.setState({ isDescriptionOpen: true });
  }

  handleDescriptionKeyDown = (event) => {
    if (event.keyCode == 16 || event.keyCode == 9) { // ignore shift and tab keys
      return;
    } else {
      this.handleOpenDescription();
    }
  }

  render() {
    const { data, fittingsMaterials, metrics, errors, onEdit, onDuplicate, onRemove, modifiable, currencySymbol, t } = this.props;
    const { isDescriptionOpen } = this.state;

    let fittingsMaterialsActual = fittingsMaterials.map(el => ({id: el.id, value: el.code, name: el.name, code: el.code}));
    if (data.material_id && !fittingsMaterialsActual.find(el => el.id === data.material_id) && data.material) {
      fittingsMaterialsActual.push({ id: data.material_id, value: data.material.code, name: data.material.name, code: data.material.code });
    }

    const metricsList = Object.keys(metrics).map(el => ({id: el, value: metrics[el]})).filter(el => el.value == 'pcs');
    let selectedMetricOption = '';
    if (metricsList.length > 0) {
      selectedMetricOption = metricsList[0].id;
    }
    
    let materialName = '';
    let materialPrice = '';
    let totalPrice = '';
    const material = data.material;
    if (material) {
      materialName = material.name;
      let purchasingPrice = null;
      if (
        material.material_price &&
        material.material_price[0] &&
        !isNaN(material.material_price[0].price)
      )
        purchasingPrice = material.material_price[0].price;
      else
        purchasingPrice = material.default_price;
      materialPrice = calculateMaterialRetailPrice(purchasingPrice, parseFloat(material.discount_percentage), parseFloat(material.surcharge_percentage), parseFloat(material.waste_percentage));
      if (!isNaN(materialPrice) && !isNaN(data.amount))
        totalPrice = formatNumericValue(data.amount * materialPrice, 2);
      materialPrice = formatNumericValue(materialPrice, 2);
    }

    const sanitizeTooltipErrorTitle = error => (error ? error : "");

    return (
      <GridContainer className="fitting-container">
        <GridItem className="grid-item fc-article">
          <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(errors && errors.material_id)}>
            <div>
              <CustomAutocomplete
                buttonText={data.material_id ? data.material_id : ''}
                buttonProps={{className: '', round: true, size: "sm", disabled: !modifiable}}
                className={'autocomplete-article'}
                discardIcon={true}
                dropdownList={fittingsMaterialsActual}
                hoverColor="dark"
                getData={(value) => getFittingsMaterialsList(value)}
                onClick={(value) => this.handleInputValueChange('material_id', value.id)}
                error={errors && errors.material_id}
              />
            </div>
          </ErrorTooltipAlt>
        </GridItem>
        <GridItem className="grid-item fc-amount">
          <ErrorTooltip title={sanitizeTooltipErrorTitle(errors && errors.amount)}>
            <Input
              value={ data.amount || '' }
              onChange={e => this.handleInputValueChange('amount', e.target.value)}
              type={'number'}
              error={errors && errors.amount}
              readOnly={!modifiable}
            />
          </ErrorTooltip>
        </GridItem>
        <GridItem className="grid-item fc-metric">
          <Dropdown
            buttonText={selectedMetricOption}
            buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
            dropdownList={metricsList}
            disabled={!modifiable}
            hoverColor="dark"
            onClick={item => this.handleInputValueChange('unit', item.id)}
          />
        </GridItem>
        <GridItem className="grid-item fc-price">
          <InputWithIcon
            value={materialPrice}
            readOnly={true}
            endAdornment={<InputAdornment position="end">{currencySymbol}</InputAdornment>}
          />
        </GridItem>
        <GridItem className="grid-item fc-total-price">
          <InputWithIcon
            value={totalPrice}
            readOnly={true}
            endAdornment={<InputAdornment position="end">{currencySymbol}</InputAdornment>}
          />
        </GridItem>
        <GridItem
          className="grid-item fc-description"
          onKeyDown={modifiable ? this.handleDescriptionKeyDown : null}
          onClick={modifiable ? this.handleOpenDescription : null}>
          <InputWithIcon
            value={data.description || ''}
            error={errors && errors.description}
            readOnly={!modifiable}
            endAdornment={
              <InputAdornment position="end">
                <img alt="description-img" src={DescriptionIcon} />
              </InputAdornment>
            }
          />
        </GridItem>
        <GridItem className="grid-item fc-actions">
          { modifiable && (
            <IconButton
              aria-label="close"
              className="action-btn"
              disabled={!modifiable}
              onClick={onRemove}
            >
              <img alt="fitting-delete-btn" src={DeleteIcon} />
            </IconButton>
          )}
          { modifiable && (
            <IconButton
              aria-label="close"
              className="action-btn"
              disabled={!modifiable}
              onClick={onDuplicate}
            >
              <img alt="fitting-duplicate-btn" src={DuplicateIcon} />
            </IconButton>
          )}
          { modifiable && (
            <IconButton
              aria-label="close"
              className="action-btn"
              disabled={!modifiable}
              onClick={onEdit}
            >
              <img alt="fitting-edit-btn" src={EditIcon} />
            </IconButton>
          )}
        </GridItem>
        { isDescriptionOpen && (
          <Description
            isOpen={isDescriptionOpen}
            onClose={this.handleToggleDescription}
            value={data.description}
            onChange={(value) => this.handleInputValueChange('description', value) }
          />
        )}
      </GridContainer>
    )
  }
}

const mapStateToProp = state => {
  return {
    fittingsMaterials: state.materialProviders.fittingsMaterials,
    metrics: state.globals.metrics,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFittingsMaterialsList: (term) => dispatch(getFittingsMaterialsList(term)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(Fitting);
