export const GET_USERS = 'usersEpic/GET_USERS';
export const GET_USERS_SUCCESS = 'usersEpic/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'usersEpic/GET_USERS_ERROR';

export const GET_USER = 'usersEpic/GET_CUSTOMER';
export const GET_USER_SUCCESS = 'usersEpic/GET_USER_SUCCESS';
export const RESET_USERS_DATA = 'usersEpic/RESET_USERS_DATA';

export const ADD_USER = 'usersEpic/ADD_USER';
export const ADD_USER_SUCCESS = 'usersEpic/ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'usersEpic/ADD_USER_ERROR';

export const SET_USER_STATUS = 'usersEpic/SET_USER_STATUS';
export const SET_USER_STATUS_SUCCESS = 'usersEpic/SET_USER_STATUS_SUCCESS';
export const SET_USER_STATUS_FAILURE = 'usersEpic/SET_USER_STATUS_FAILURE';

export const UPDATE_USER = 'usersEpic/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'usersEpic/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'usersEpic/UPDATE_USER_ERROR';

export const REMOVE_USER = 'usersEpic/REMOVE_USER';

export const FILTER_USERS = 'usersEpic/FILTER_USERS';
