import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {getUsers, getUser, setUserStatus, removeUser, filterUsers} from 'store/actions/usersData';
import {getRoles} from 'store/actions/roles';
import {UsersListSortByOptions} from 'constants/constants';
import {capitalize} from 'utils/utils';

import MaterialArrowUp from '../../assets/img/material_arrow_up.svg';
import MaterialArrowDown from '../../assets/img/material_arrow_down.svg';
import Button from "components/CustomButtons/Button.jsx";
import CreateUser from "../CreateUser";
import Dropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomMultiselect from 'components/CustomMultiselect/CustomMultiselect';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Loader from "../../components/Loader/Loader";
import ViewButton from '../../components/ViewButton';
import ViewUser from "../ViewUser";

import Add from "@material-ui/icons/Add";
import CloseButton from "../../assets/img/buttons/delete.svg";
import EditButton from "../../assets/img/buttons/edit.svg";
import EyeButton from "../../assets/img/buttons/eye.svg";
import IconButton from "@material-ui/core/IconButton";

import './style.scss';
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomPagination from "../../components/CustomPagination";
import RemoveDialog from "../RemoveDialog";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewTable: true,
      isCreateUserOpen: false,
      isEditUserOpen: false,
      isViewUserOpen: false,
      isRemoveUserOpen: false,
      deletedUserId: '',
      userId: '',
      filters: {
        page: 1,
        role: [-1],
        status: '',
        order: 'last_name',
        orderDir: true,
      }
    };
  }

  componentDidMount() {
    this.reloadDataList();
    this.props.getRoles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchTerms != prevProps.searchTerms) {
      if (this.state.filters.page != 1) {
        this.setState({
          filters: {
            ...this.state.filters,
            page: 1,
          },
        }, () => {
          this.props.filterUsers(this.state.filters, this.props.searchTerms);
        });
      } else {
        this.props.filterUsers(this.state.filters, this.props.searchTerms);
      }
    }
    /* sample code that triggers a "clear search", should the page require it
    setTimeout(() => {
      this.props.onSearchReset && this.props.onSearchReset();
    }, 2500);
    */
  }

  isUsersDataReady = () => {
    const {
      usersBusy
    } = this.props;
    return (
      !usersBusy
    );
  }

  reloadDataList = () => {
    this.props.filterUsers(this.state.filters, this.props.searchTerms);
  }

  handlePageClick = (data) => {
    const {selected} = data;
    this.handleFilters('page', selected + 1)
  };

  handleViewChange = () => {
    this.setState({
      isViewTable: !this.state.isViewTable
    })
  };

  handleToggleCreateUser = () => {
    this.setState({
      isCreateUserOpen:  !this.state.isCreateUserOpen
    })
  };

  handleShowViewUser = (id) => {
    this.props.getUser(id);
    this.setState({
      isViewUserOpen: true,
      userId: id,
    })
  };

  handleCloseViewUser = () => {
    this.setState({
      isViewUserOpen: false,
    })
  };

  handleShowEditUser = (id) => {
    if (id) {
      this.props.getUser(id);
    }
    this.setState({
      isEditUserOpen: true,
      isViewUserOpen: false,
    })
  };

  handleCloseEditUser = () => {
    this.setState({
      isEditUserOpen: false,
    })
  };

  handleUserStatusChange = (id, value) => {
    const {
      metaData,
      searchTerms,
      setUserStatus,
    } = this.props;
    const {
      filters,
    } = this.state;
    setUserStatus(id, value, filters, searchTerms);
  };

  handleFilters = (fieldName, value) => {
    const {filters} = this.state;
    const newFilters = {...filters};
    if (fieldName === 'role') {
      if (value.length == 0)
        newFilters[fieldName] = [-1];
      else
        newFilters[fieldName] = value;
    } else if (fieldName === 'order') {
      newFilters[fieldName] = value;
      newFilters['orderDir'] = true;
    } else {
      if (+value !== 2)
        newFilters[fieldName] = value;
      else
        newFilters[fieldName] = '';
    }
    this.setState({
      filters: newFilters,
    }, () => {
      this.reloadDataList();
    });
  };

  handleSortByFlipOrder = () => {
    this.handleFilters('orderDir', !this.state.filters.orderDir);
  };

  handleDeleteButton = (id) => {
    this.setState({
      isRemoveUserOpen: true,
      deletedUserId: id
    });
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveUserOpen: false,
    });
  }

  handleRemoveUser = () => {
    const {
      deleteUser,
      metaData
    } = this.props;
    const {deletedUserId} = this.state;
    deleteUser({id: deletedUserId, page: metaData.current_page, searchTerms: this.props.searchTerms, filters: this.state.filters })
    this.handleRemoveDialogClose();
  };

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return text;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  renderUser = (user, index) => {
    const { t } = this.props;
    const role = user.roles && user.roles.length > 0 ? user.roles.map(r => r.name).join(', ') : '';
    return(
      <GridContainer
        className={`users-item ${ index % 2 === 0 ? 'dark' : '' }`}
        key={index}
      >
        <GridItem className="user-item-title bold" xs={1}>{user.id}</GridItem>
        <GridItem className="user-item-title" xs={2} lg={1}>{this.wrapText(user.first_name,10)}</GridItem>
        <GridItem className="user-item-title _last-name" xs={2} lg={1}>{this.wrapText(user.last_name,10)}</GridItem>
        <GridItem className="user-item-title _email" xs={2}>{this.wrapText(user.email, 18)}</GridItem>
        <GridItem className="user-item-title _phone" xs={2}>{this.wrapText(user.phone,20)}</GridItem>
        <GridItem className="user-item-title" xs={3} lg={2}>{this.wrapText(role,15)}</GridItem>
        <GridItem className="user-item-title status" xs={2} lg={1}>
          <Dropdown
            buttonText={user.status}
            buttonIconType="white"
            buttonProps={{className: `dropdown-blue-style stock-dropdown _status-dropdown ${user.status === 1 ? 'inactive_user' : ''}`, round: true, size: "sm"}}
            menuWidthCorrection={true}
            dropdownList={[{id: 0, value: t('active')}, {id: 1, value: t('inactive')}]}
            hoverColor="dark"
            onClick={(value) => this.handleUserStatusChange(user.id, value.id)}
          />
        </GridItem>
        <GridItem className="user-item-title _actions" xs={2}>
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleDeleteButton(user.id)}
          >
            <img
              alt="users-remove-btn"
              src={CloseButton}
            />
          </IconButton>
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleShowEditUser(user.id)}>
            <img
              alt="users-edit-btn"
              src={EditButton}
            />
          </IconButton>
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleShowViewUser(user.id)}>
            <img
              alt="users-view-btn"
              src={EyeButton}
            />
          </IconButton>
        </GridItem>
      </GridContainer>
    )
  };

  renderUserCard = (user, index) => {
    const { t } = this.props;
    let role = user.roles && user.roles.length > 0 ? capitalize(user.roles[0].name) : '';
    let roleAdditional = '';
    if (user.roles && user.roles.length > 1) {
      user.roles && user.roles.length !== 0 && user.roles.map((item, index) => roleAdditional = roleAdditional + `${capitalize(item.name)}${index < user.roles.length - 1 ? ', ' : '' }`);
    }
    return (
      <GridContainer className="users-card-item" key={index}>
        <div className="user-id-container">
          <div className="user-id">{user.id}</div>
          <div className="user-role bold">{roleAdditional ? <span title={roleAdditional}>{role}</span> : role}</div>
        </div>
        <div className="user-name bold">{`${user.first_name} ${user.last_name}`}</div>
        <div className="user-email">{user.email}</div>
        <div className="user-phone-number">{user.phone}</div>
        <div className="user-status-actions">
          <div className="user-status">
            <Dropdown
              buttonText={user.status}
              buttonIconType="white"
              buttonProps={{className: `dropdown-blue-style stock-dropdown ${user.status === 1 ? 'inactive_user' : ''}`, round: true, size: "sm"}}
              dropdownList={[{id: 0, value: t('active')}, {id: 1, value: t('inactive')}]}
              hoverColor="dark"
              onClick={(value) => this.handleUserStatusChange(user.id, value.id)}
            />
          </div>
          <div className="user-actions">
            <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() => this.handleDeleteButton(user.id)}
            >
              <img
                alt="users-remove-btn"
                src={CloseButton}
              />
            </IconButton>
            <IconButton aria-label="close" className="action-btn" onClick={() => this.handleShowEditUser(user.id)}>
              <img
                alt="users-edit-btn"
                src={EditButton}
              />
            </IconButton>
            <IconButton aria-label="close" className="action-btn" onClick={() => this.handleShowViewUser(user.id)}>
              <img
                alt="users-view-btn"
                src={EyeButton}
              />
            </IconButton>
          </div>
        </div>
      </GridContainer>
    )
  };

  render() {
    const {
      users,
      userRoles,
      user,
      metaData,
      t
    } = this.props;
    const {
      isViewTable,
      isCreateUserOpen,
      isEditUserOpen,
      isViewUserOpen,
      isRemoveUserOpen,
      deletedUserId,
      userId,
      filters
    } = this.state;
    let userRolesData = [{id: -1, value: t('all_roles'), is_select_all: true}];
    userRoles.map(item => userRolesData.push({id: item.id, value: item.name}));
    return (
      <div className="ikt-ap_users-container">
        {this.isUsersDataReady() ? (
          <>
          <GridContainer>
            <GridItem className="users-dropdown-content" xs={12}>
              <CustomMultiselect
                buttonValue={filters.role}
                buttonPlaceholder={t('all_roles')}
                buttonProps={{className: 'user-dropdown-style', round: true, size: "sm"}}
                menuWidthCorrection={true}
                dropdownList={userRolesData}
                onChange={value => this.handleFilters('role', value)}
              />
              <Dropdown
                buttonText={filters.status}
                buttonPlaceholder={t('all_statuses')}
                buttonProps={{className: 'user-dropdown-style', round: true, size: "sm"}}
                menuWidthCorrection={true}
                dropdownList={[{id: 2, value: t('all_statuses')}, {id: 0, value: t('active')}, {id: 1, value: t('inactive')}]}
                onClick={value => this.handleFilters('status', value.id)}
              />
              <Dropdown
                buttonText={filters.order}
                buttonProps={{className: 'user-dropdown-style', round: true, size: "sm"}}
                menuWidthCorrection={true}
                dropdownList={UsersListSortByOptions}
                translateValues={true}
                onClick={value => this.handleFilters('order', value.id)}
              />
              <ViewButton
                className="project-view-button"
                isTable={isViewTable}
                onViewChange={this.handleViewChange}
              />
              <Button  onClick={this.handleToggleCreateUser} size="sm" className="create-user-btn">
                <Add /> { t('create').toUpperCase() }
              </Button>
            </GridItem>
            {
              isViewTable && (
                <GridContainer className="users-header">
                  <GridItem className={"users-header-title" + (filters.order === 'id' ? ' col-sort-active' : '')} xs={1}>
                    <span onClick={filters.order === 'id' ? this.handleSortByFlipOrder : null}>
                      { t('user_id') } { filters.order === 'id' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                    </span>
                  </GridItem>
                  <GridItem className="users-header-title" xs={2} lg={1}>{ t('first_name') }</GridItem>
                  <GridItem className={"users-header-title _last-name" + (filters.order === 'last_name' ? ' col-sort-active' : '')} xs={2} lg={1}>
                    <span onClick={filters.order === 'last_name' ? this.handleSortByFlipOrder : null}>
                      { t('last_name') } { filters.order === 'last_name' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                    </span>
                  </GridItem>
                  <GridItem className="users-header-title _email" xs={2}>{ t('email') }</GridItem>
                  <GridItem className="users-header-title _phone" xs={2}>{ t('phone') }</GridItem>
                  <GridItem className={"users-header-title" + (filters.order === 'role' ? ' col-sort-active' : '')} xs={3} lg={2}>
                    <span onClick={filters.order === 'role' ? this.handleSortByFlipOrder : null}>
                      { t('role') } { filters.order === 'role' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                    </span>
                  </GridItem>
                  <GridItem className="users-header-title" xs={2} lg={1}>{ t('status') }</GridItem>
                  <GridItem className="users-header-title _actions" xs={2}>{ t('actions') }</GridItem>
                </GridContainer>
            )}
            <GridContainer className="users-content">
              {
                users && users.length !== 0 &&
                isViewTable
                  ? users.map((user, index) => this.renderUser(user, index))
                  : users.map((user, index) => this.renderUserCard(user, index))
              }
              {
                users && users.length === 0 &&
                <div className="no_users">{ t('no_users_found') }</div>
              }
            </GridContainer>
          </GridContainer>
          <GridContainer className="pagination-container">
            {
              metaData.last_page > 1 &&
              <CustomPagination
                pageCount={metaData.last_page}
                handlePageClick={this.handlePageClick}
              />
            }
          </GridContainer>
          </>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
        {
          isCreateUserOpen &&
          <CreateUser
            isOpen={isCreateUserOpen}
            filters={filters}
            searchTerms={this.props.searchTerms}
            onClose={this.handleToggleCreateUser}
          />
        }
        {
          isEditUserOpen && Object.keys(user).length !== 0 &&
          <CreateUser
            isOpen={isEditUserOpen}
            filters={filters}
            searchTerms={this.props.searchTerms}
            onClose={this.handleCloseEditUser}
          />
        }
        {
          isViewUserOpen && Object.keys(user).length !== 0 &&
          <ViewUser
            isOpen={isViewUserOpen}
            onClose={this.handleCloseViewUser}
            onEditUser={this.handleShowEditUser}
            filters={filters}
            searchTerms={this.props.searchTerms}
            id={userId}
          />
        }
        {isRemoveUserOpen && (
          <RemoveDialog
            isOpen={isRemoveUserOpen}
            title={t('remove_user')}
            text={t('remove_user_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleRemoveUser}
          />
        )}
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
  searchTerms: PropTypes.string,
  onSearchReset: PropTypes.func
};

const mapStateToProp = state => {
  return {
    users: state.users.users,
    usersBusy: state.users.usersBusy,
    user: state.users.user,
    userRoles: state.roles.roles,
    metaData: state.users.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRoles: () => dispatch(getRoles()),
    getUser: (id) => dispatch(getUser(id)),
    setUserStatus: (id, value, filters, searchTerms) => dispatch(setUserStatus(id, value, filters, searchTerms)),
    deleteUser: (data) => dispatch(removeUser(data)),
    filterUsers: (filters, searchTerms) => dispatch(filterUsers(filters, searchTerms))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(Users)));
