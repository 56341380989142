import {ofType} from 'redux-observable';
import {concat, from, of, merge} from 'rxjs';
import {switchMap, catchError, mergeMap, endWith} from 'rxjs/operators';
import {customersAPI} from 'services/API';
import NotificationService from '../services/NotificationService';
import {
  getCustomers,
  getCustomersSuccess,
  getCustomersFailure,
  getCustomerSuccess,
  addCustomerSuccess,
  addCustomerFailure,
  updateCustomerSuccess,
  updateCustomerFailure,
  validateCustomerImportFileSuccess,
  validateCustomerImportFileFailure,
  startCustomerFileImportSuccess,
  startCustomerFileImportFailure,
  removeCustomerSuccess,
  removeCustomerFailure,
  removeCustomersSingleSuccess,
  removeCustomersSingleFailure,
  removeCustomersFinish,
} from '../store/actions/customers';
import {
  ADD_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMERS,
  REMOVE_CUSTOMERS_SINGLE_SUCCESS,
  REMOVE_CUSTOMERS_SINGLE_FAILURE,
  REMOVE_CUSTOMERS_FINISH,
  VALIDATE_IMPORTED_CUSTOMER_FILE,
  START_CUSTOMER_FILE_IMPORT
} from '../store/actionTypes/customerActionTypes';

export const getCustomersEpic = (actions$) => actions$.pipe(
  ofType(GET_CUSTOMERS),
  switchMap((action) => from(customersAPI.getCustomers(action.payload.page, action.payload.filters, action.payload.searchTerms)).pipe(
    switchMap((response) => {
      return of(getCustomersSuccess(response))
    }),
    catchError( err => {
        return of(getCustomersFailure(err))
      }
    )
  ))
);

export const getCustomerEpic = (actions$) => actions$.pipe(
  ofType(GET_CUSTOMER),
  switchMap((action) => from(customersAPI.getCustomer(action.payload)).pipe(
    switchMap((response) => {
      return of(getCustomerSuccess(response.data))
    }),
    catchError( err => {
        return of(getCustomersFailure(err))
      }
    )
  ))
);

export const addCustomerEpic = (actions$) => actions$.pipe(
  ofType(ADD_CUSTOMER),
  switchMap((action) => from(customersAPI.createCustomer(action.payload.data)).pipe(
    switchMap((response) => {
      const loadListInfo = action.payload.loadListInfo;
      if (loadListInfo) {
        return concat(
          of(getCustomers(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
          of(addCustomerSuccess(response)),
        );
      } else {
        return of(addCustomerSuccess(response));
      }
    }),
    catchError( err => {
        NotificationService.error(err.responseBody ? err.responseBody.message : err);
        return of(addCustomerFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const updateCustomerEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_CUSTOMER),
  switchMap((action) => from(customersAPI.updateCustomer(action.payload.id, action.payload.data)).pipe(
    switchMap((response) => {
      const loadListInfo = action.payload.loadListInfo;
      if (loadListInfo) {
        return concat(
          of(getCustomers(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
          of(updateCustomerSuccess(response)),
        );
      } else {
        return of(updateCustomerSuccess(response));
      }
    }),
    catchError( err => {
        return of(updateCustomerFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const removeCustomerEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_CUSTOMER),
  switchMap((action) => from(customersAPI.deleteCustomer(action.payload.id)).pipe(
    switchMap((response) => {
      NotificationService.success('Customer was removed');
      const loadListInfo = action.payload.loadListInfo;
      if (loadListInfo) {
        return concat(
          of(getCustomers(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
          of(removeCustomerSuccess())
        );
      } else {
        return of(removeCustomerSuccess());
      }
    }),
    catchError( err => {
        return of(removeCustomerFailure(err));
      }
    )
  ))
);

export const removeCustomersEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_CUSTOMERS),
  mergeMap(({payload}) => {
    const observables = payload.data.map(id => from(customersAPI.deleteCustomer(id)).pipe(
      switchMap((response) => {
        return of(removeCustomersSingleSuccess(id));
      }),
      catchError(err => {
        return of(removeCustomersSingleFailure(id, err));
      })
    ));
    return merge(
      ...observables
    ).pipe(endWith(removeCustomersFinish(payload.loadListInfo.page, payload.loadListInfo.filters, payload.loadListInfo.searchTerms)));
  })
);

export const removeCustomersFinishEpic = (actions$, state$) => actions$.pipe(
  ofType(REMOVE_CUSTOMERS_FINISH),
  switchMap((action) => {
    const statePart = state$.value.customers;
    const errors = statePart.batchRemoveState.errors;
    if (Object.keys(errors).length == 0) {
      NotificationService.success('Customers were removed');
    } else {
      let errorMessage = 'There were errors removing customers:';
      const keys = Object.keys(errors);
      for(let i = 0, len = keys.length; i < len; ++i) {
        const key = keys[i];
        const value = errors[key];
        errorMessage += `${i > 0 ? ', ' : ''} ${key} - ${value[0].responseBody.error}`;
      }
      NotificationService.error(errorMessage, true);
    }
    return of(getCustomers(action.payload.page, action.payload.filters, action.payload.searchTerms));
  })
);

export const customerImportedFileValidationEpic = (actions$) => actions$.pipe(
  ofType(VALIDATE_IMPORTED_CUSTOMER_FILE),
  mergeMap((action) => from(customersAPI.validateImportedCustomerFile(action.payload)).pipe(
    switchMap((response) => {
      return of(validateCustomerImportFileSuccess(response.data))
    }),
    catchError( err => {
        return of(validateCustomerImportFileFailure(err))
      }
    )
  ))
);

export const startCustomerImportFileEpic = (actions$) => actions$.pipe(
  ofType(START_CUSTOMER_FILE_IMPORT),
  mergeMap((action) => from(customersAPI.startImportCustomerFile(action.payload)).pipe(
    switchMap((response) => {
      return of(startCustomerFileImportSuccess(response.data))
    }),
    catchError( err => {
        return of(startCustomerFileImportFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);



