import React from "react";
import { withTranslation } from 'react-i18next';

import GridItem from "../../components/Grid/GridItem";

import './style.scss';

class NotesView extends React.Component {
  render() {
    const {data, t} = this.props;
    return(
      <>
        <div className="contact-data-container">
          <div className="contact-info-field">
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('general') }</GridItem>
              <GridItem xs={8} className="note-info-content">{data.note_general}</GridItem>
            </div>
          </div>
          <div className="contact-info-field">
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('business') }</GridItem>
              <GridItem xs={8} className="note-info-content">{data.note_business}</GridItem>
            </div>
          </div>
          <div className="contact-info-field">
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('personal') }</GridItem>
              <GridItem xs={8} className="note-info-content">{data.note_personal}</GridItem>
            </div>
          </div>
          <div className="contact-info-field">
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('activities') }</GridItem>
              <GridItem xs={8} className="note-info-content">{data.note_activities}</GridItem>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(NotesView);
