import { handleActions } from 'redux-actions';
import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_CLIENT_INQUIRIES_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  REMOVE_ORDER_SUCCESS,
  VALIDATE_ORDER_DETAILS_SUCCESS,
  VALIDATE_ORDER_DETAILS_FAILURE,
  RESET_ORDER_DETAILS_DATA,
  VALIDATE_ORDER_POSITIONS_SUCCESS,
  VALIDATE_ORDER_POSITIONS_FAILURE,
  RESET_ORDER_POSITIONS_DATA,
  VALIDATE_IMPORTED_POSITION_FILE_SUCCESS,
  VALIDATE_IMPORTED_POSITION_FILE_FAILURE,
  START_POSITION_FILE_IMPORT_SUCCESS,
  START_POSITION_FILE_IMPORT_FAILURE,
  START_TASK_FILE_IMPORT_SUCCESS,
  START_TASK_FILE_IMPORT_FAILURE,
  VALIDATE_POSITIONS_TASK_SUCCESS,
  VALIDATE_POSITIONS_TASK_FAILURE,
  RESET_POSITIONS_TASK_ERRORS,
  VALIDATE_ORDER_FITTINGS_SUCCESS,
  VALIDATE_ORDER_FITTINGS_FAILURE,
  RESET_FITTINGS_DATA,
  VALIDATE_ORDER_COST_CENTER_SUCCESS,
  VALIDATE_ORDER_COST_CENTER_FAILURE,
  RESET_COST_CENTER_DATA,
  ORDER_POSITION_CALCULATION_SUCCESS,
  ORDER_COST_CENTER_CALCULATION_FAILURE,
  ORDER_COST_CENTER_CALCULATION_SUCCESS,
  RESET_ORDER_DATA,
  RESET_FILE_IMPORT_DATA,
  FILTER_ORDERS,
  GET_ORDER_POSITION_DEFAULT_COST_CENTER_SUCCESS,
  GET_ORDER_TOTAL_COST_SUCCESS,
  GET_ORDER_SETTINGS_SUCCESS,
  DUPLICATE_ORDER_SUCCESS,
  SET_ORDER_STATUS,
  SET_ORDER_STATUS_SUCCESS,
} from '../actionTypes/ordersActionTypes';

export const DEFAULT_ORDERS_STATE = {
  orders: [],
  clientInquiries: [],
  ordersMetaData: {},
  currentOrder: {},
  orderSettings: [],
  orderTotalCost: {},
  ordersBusy: false,
  isOrderCreated: false,
  isOrderUpdated: false,
  isOrderDeleted: false,
  isOrderDuplicated: false,
  redirectToOrderId: null,
  ordersErrors: '',
  orderPositionImportValidationData: {
    processID: ''
  },
  orderPositionImportValidationErrors: {},
  orderPositionImportedFileData: {
    data: {
      order: {
      }
    },
    processID: ''
  },
  orderPositionImportedFileErrors: {},
  orderTaskImportedFileData: {
    id: '',
    data: {}
  },
  orderTaskImportedFileErrors: {},
  isOrderDetailsValid: false,
  orderDetailsValidationErrors: {},
  isOrderPositionsValid: false,
  orderPositionsValidationErrors: [],
  isOrderPositionTaskValid: false,
  orderPositionTaskValidationErrors: [],
  isOrderFittingsValid: false,
  orderFittingsValidationErrors: [],
  isOrderCostCenterValid: false,
  orderCostCenterValidationErrors: [],
  orderPositionCosts: {},
  defaultCostCenterList: []
};

export const orders = handleActions(
  {
    [GET_ORDERS]: (state) => ({
      ...state,
      ordersBusy: true
    }),
    [GET_ORDERS_SUCCESS]: (state, action) => ({
      ...state,
      orders: action.payload.data,
      ordersMetaData: action.payload.meta,
      ordersBusy: false
    }),
    [GET_ORDERS_ERROR]: (state, action) => ({
      ...state,
      ordersError: action.payload.message,
      ordersBusy: false
    }),
    [FILTER_ORDERS]: (state) => ({
      ...state,
      ordersBusy: true
    }),
    [GET_CLIENT_INQUIRIES_SUCCESS]: (state, action) => ({
      ...state,
      clientInquiries: action.payload
    }),
    [GET_ORDER]: (state) => ({
      ...state,
      ordersBusy: true
    }),
    [GET_ORDER_SUCCESS]: (state, action) => ({
      ...state,
      currentOrder: action.payload,
      ordersBusy: false
    }),
    [GET_ORDER_ERROR]: (state, action) => ({
      ...state,
      ordersError: action.payload.message,
      ordersBusy: false
    }),
    [GET_ORDER_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      orderSettings: action.payload
    }),
    [GET_ORDER_TOTAL_COST_SUCCESS]: (state, action) => ({
      ...state,
      orderTotalCost: action.payload
    }),
    [GET_ORDER_POSITION_DEFAULT_COST_CENTER_SUCCESS]: (state, action) => ({
      ...state,
      defaultCostCenterList: action.payload,
    }),
    [CREATE_ORDER]: (state) => ({
      ...state,
      ordersBusy: true,
      orderDetailsValidationErrors: {},
      orderPositionsValidationErrors: [],
      orderPositionTaskValidationErrors: []
    }),
    [CREATE_ORDER_SUCCESS]: (state) => ({
      ...state,
      isOrderCreated: true,
      ordersBusy: false,
    }),
    [CREATE_ORDER_ERROR]: (state, action) => ({
      ...state,
      ordersError: action.payload.message,
      isOrderDetailsValid: false,
      isOrderPositionsValid: false,
      isOrderCostCenterValid: false,
      ordersBusy: false
    }),
    [UPDATE_ORDER]: (state) => ({
      ...state,
      ordersBusy: true,
      redirectToOrderId: null,
    }),
    [UPDATE_ORDER_SUCCESS]: (state, action) => ({
      ...state,
      isOrderUpdated: true,
      ordersBusy: false,
      redirectToOrderId: action.payload,
    }),
    [UPDATE_ORDER_ERROR]: (state, action) => ({
      ...state,
      ordersError: action.payload.message,
      ordersBusy: false
    }),
    [REMOVE_ORDER_SUCCESS]: (state) => ({
      ...state,
      isOrderDeleted: true
    }),
    [DUPLICATE_ORDER_SUCCESS]: (state) => ({
      ...state,
      isOrderDuplicated: true
    }),
    [SET_ORDER_STATUS]: (state, action) => ({
      ...state,
      redirectToOrderId: null,
    }),
    [SET_ORDER_STATUS_SUCCESS]: (state, action) => ({
      ...state,
      redirectToOrderId: action.payload,
    }),
    [VALIDATE_ORDER_DETAILS_SUCCESS]: (state) => ({
      ...state,
      isOrderDetailsValid: true,
      orderDetailsValidationErrors: {}
    }),
    [VALIDATE_ORDER_DETAILS_FAILURE]: (state, action) => ({
      ...state,
      isOrderDetailsValid: false,
      orderDetailsValidationErrors: action.payload.order ? action.payload.order : {}
    }),
    [RESET_ORDER_DETAILS_DATA]: (state) => ({
      ...state,
      isOrderDetailsValid: false,
      orderDetailsValidationErrors: {}
    }),
    [VALIDATE_ORDER_POSITIONS_SUCCESS]: (state) => ({
      ...state,
      isOrderPositionsValid: true,
      orderPositionsValidationErrors: []
    }),
    [VALIDATE_ORDER_POSITIONS_FAILURE]: (state, action) => ({
      ...state,
      isOrderPositionsValid: false,
      orderPositionsValidationErrors: action.payload.positions
    }),
    [RESET_ORDER_POSITIONS_DATA]: (state) => ({
      ...state,
      isOrderPositionsValid: false,
      orderPositionsValidationErrors: []
    }),
    [VALIDATE_IMPORTED_POSITION_FILE_SUCCESS]: (state, action) => ({
      ...state,
      orderPositionImportValidationData: action.payload
    }),
    [VALIDATE_IMPORTED_POSITION_FILE_FAILURE]: (state, action) => ({
      ...state,
      orderPositionImportValidationErrors: action.payload
    }),
    [START_POSITION_FILE_IMPORT_SUCCESS]: (state, action) => ({
      ...state,
      orderPositionImportedFileData: action.payload
    }),
    [START_POSITION_FILE_IMPORT_FAILURE]: (state, action) => ({
      ...state,
      orderPositionImportedFileErrors: action.payload
    }),
    [START_TASK_FILE_IMPORT_SUCCESS]: (state, action) => ({
      ...state,
      orderTaskImportedFileData: action.payload
    }),
    [START_TASK_FILE_IMPORT_FAILURE]: (state, action) => ({
      ...state,
      orderTaskImportedFileErrors: action.payload
    }),
    [VALIDATE_POSITIONS_TASK_SUCCESS]: (state) => ({
      ...state,
      isOrderPositionTaskValid: true,
      orderPositionTaskValidationErrors: []
    }),
    [VALIDATE_POSITIONS_TASK_FAILURE]: (state, action) => ({
      ...state,
      isOrderPositionTaskValid: false,
      orderPositionTaskValidationErrors: action.payload
    }),
    [RESET_POSITIONS_TASK_ERRORS]: (state) => ({
      ...state,
      isOrderPositionTaskValid: false,
      orderPositionTaskValidationErrors: []
    }),
    [VALIDATE_ORDER_FITTINGS_SUCCESS]: (state) => ({
      ...state,
      isOrderFittingsValid: true,
      orderFittingsValidationErrors: []
    }),
    [VALIDATE_ORDER_FITTINGS_FAILURE]: (state, action) => ({
      ...state,
      isOrderFittingsValid: false,
      orderFittingsValidationErrors: action.payload.positions
    }),
    [RESET_FITTINGS_DATA]: (state) => ({
      ...state,
      isOrderFittingsValid: false,
      orderFittingsValidationErrors: []
    }),
    [VALIDATE_ORDER_COST_CENTER_SUCCESS]: (state) => ({
      ...state,
      isOrderCostCenterValid: true,
      orderCostCenterValidationErrors: []
    }),
    [VALIDATE_ORDER_COST_CENTER_FAILURE]: (state, action) => ({
      ...state,
      isOrderCostCenterValid: false,
      orderCostCenterValidationErrors: action.payload.positions
    }),
    [RESET_COST_CENTER_DATA]: (state) => ({
      ...state,
      isOrderCostCenterValid: false,
      orderCostCenterValidationErrors: []
    }),
    [ORDER_POSITION_CALCULATION_SUCCESS]: (state, action) => ({
      ...state,
      orderPositionCosts: {...state.orderPositionCosts, [action.payload.id]: action.payload.data}
    }),
    [ORDER_COST_CENTER_CALCULATION_SUCCESS]: (state, action) => ({
      ...state,
      orderCostCenterCosts: {...state.orderCostCenterCosts, [action.payload.id]: action.payload.data}
    }),
    [ORDER_COST_CENTER_CALCULATION_FAILURE]: (state, action) => ({
      ...state,
      orderCostCenterCosts: {...state.orderCostCenterCosts, [action.payload.id]: undefined}
    }),
    [RESET_FILE_IMPORT_DATA]: (state) => ({
      ...state,
      orderPositionImportValidationData: {
        processID: ''
      },
      orderPositionImportValidationErrors: {},
      orderPositionImportedFileData: {
        data: {
          order: {}
        },
        processID: ''
      },
      orderPositionImportedFileErrors: {},
      orderTaskImportedFileData: {
        id: '',
        data: {}
      },
      orderTaskImportedFileErrors: {},
    }),
    [RESET_ORDER_DATA]: (state) => ({
      ...state,
      orders: [],
      currentOrder: {},
      ordersBusy: false,
      isOrderCreated: false,
      isOrderUpdated: false,
      isOrderDeleted: false,
      isOrderDuplicated: false,
      ordersErrors: '',
      isOrderDetailsValid: false,
      orderDetailsValidationErrors: {},
      isOrderPositionsValid: false,
      orderPositionsValidationErrors: [],
      isOrderPositionTaskValid: false,
      orderPositionTaskValidationErrors: [],
      isOrderFittingsValid: false,
      orderFittingsValidationErrors: [],
      isOrderCostCenterValid: false,
      orderCostCenterValidationErrors: [],
      orderPositionCosts: {}
    }),
  },
  DEFAULT_ORDERS_STATE
);
