import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import Button from "components/CustomButtons/Button.jsx";
import CreateNotfication from "../CreateNotification";
import Dropdown from "components/CustomDropdown/CustomDropdown.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ViewNotification from '../ViewNotification';

import CloseButton from "../../assets/img/buttons/delete.svg";
import EditButton from "../../assets/img/buttons/edit.svg";
import IconButton from "@material-ui/core/IconButton";
import ViewButton from "../../assets/img/buttons/eye.svg";

import './style.scss';
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Loader from "../../components/Loader/Loader";

const notifications = [
  {userId: 'Super Admin', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'},
  {userId: 'Admin', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'},
  {userId: 'Supplier', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'},
  {userId: 'Manufacturer', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'},
  {userId: 'Co-worker', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'}
];

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateNotificationOpen: false,
      isViewNotificationOpen: false
    };
  }

  isNotificationsDataReady = () => {
    /*const {
      notifications
    } = this.props;*/
    return (
      notifications &&
      notifications.length !== 0
    );
  }

  handleCreateNotification = () => {
    this.setState({
      isCreateNotificationOpen: !this.state.isCreateNotificationOpen
    })
  };

  handleViewNotification = () => {
    this.setState({
      isViewNotificationOpen: !this.state.isViewNotificationOpen
    })
  };

  renderNotification = (notification, index) => {
    return(
      <GridContainer
        className={`notifications-item ${ index % 2 === 0 ? 'dark' : '' }`}
        direction="row"
        xs={12}
      >
        <GridItem className="notifications-item-title" xs={2}>Date & Time</GridItem>
        <GridItem className="notifications-item-title" xs={2}>Type</GridItem>
        <GridItem className="notifications-item-title" xs={3}>Action</GridItem>
        <GridItem className="notifications-item-title" xs={3}>Requested User</GridItem>
        <GridItem className="notifications-item-title" xs={2}>
          <IconButton aria-label="close" className="action-btn">
            <img
              alt="notification-delete-btn"
              src={CloseButton}
            />
          </IconButton>
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={this.handleViewNotification}
          >
            <img
              alt="notification-view-btn"
              src={ViewButton}
            />
          </IconButton>
          <IconButton aria-label="close" className="action-btn">
            <img
              alt="notification-edit-btn"
              src={EditButton}
            />
          </IconButton>
        </GridItem>
      </GridContainer>
    )
  };

  render() {
    const {isCreateNotificationOpen, isViewNotificationOpen} = this.state;
    return (
      <div className="ikt-ap_notifications-container">
        {this.isNotificationsDataReady() ? (
          <GridContainer>
            <GridItem className="notifications-dropdown-content" direction="row" justify="flex-end" xs={12}>
              <Dropdown
                buttonText={""}
                buttonProps={{className: 'notifications-dropdown-style', round: true, size: "sm"}}
                dropdownList={[{id: 0, value: "All Recipients"}]}
              />
              <Dropdown
                buttonText={""}
                buttonProps={{className: 'notifications-dropdown-style', round: true, size: "sm"}}
                dropdownList={[{id: 0, value: "Sort by Name"}]}
              />
              <Button
                className="create-notification-btn"
                onClick={this.handleCreateNotification}
              >
                + CREATE
              </Button>
            </GridItem>
            <GridContainer className="notifications-header" direction="row" xs={12}>
              <GridItem className="notifications-header-title" xs={2}>Name of Notification</GridItem>
              <GridItem className="notifications-header-title" xs={2}>Frequency</GridItem>
              <GridItem className="notifications-header-title" xs={3}>Recipients</GridItem>
              <GridItem className="notifications-header-title" xs={3}>Email Text</GridItem>
              <GridItem className="notifications-header-title" xs={2}>Actions</GridItem>
            </GridContainer>
            <GridContainer className="notifications-content">
              {notifications && notifications.map((notification, index) => this.renderNotification(notification, index))}
            </GridContainer>
          </GridContainer>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
        <CreateNotfication isOpen={isCreateNotificationOpen} onClose={this.handleCreateNotification}/>
        <ViewNotification isOpen={isViewNotificationOpen} onClose={this.handleViewNotification}/>
      </div>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Notifications));
