import BaseAPIService from './Base';

export default class CustomersAPIService extends BaseAPIService {
  createCustomer = async (data) => {
    return await this.request('api/customers', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  updateCustomer = async (id, data) => {
    return await this.request(`api/customers/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  deleteCustomer = async (id) => {
    return await this.request(`api/customers/${id}`, {
      method: 'DELETE',
    });
  };

  getCustomer = async (id) => {
    const url = id ? `api/customers/${id}` : `api/customers/0`;
    return this.request(url).then((data) => {
      return data;
    });
  };

  getCustomers = async (page, filters, searchTerms) => {
    let filtersPart = '';
    if (filters.categories && filters.categories.length > 0) {
      let filterCategories = [];
      for (let i in filters.categories) {
        const categoryId = filters.categories[i];
        filterCategories.push(`filter[category_id][${i}]=${categoryId}`);
      }
      const filterCategoryPart = filterCategories.length > 0 ? `&${filterCategories.join("&")}` : '';
      filtersPart += filterCategoryPart;
    }
    let sortByData = filters.sortBy ? `&order=${filters.sortByOrder ? '' : '-'}${filters.sortBy}` : '';
    filtersPart += sortByData;
    return this.request(`api/customers/own?page=${page ? page : 1}${searchTerms ? ('&search-term=' + searchTerms) : ''}${filtersPart}`).then((data) => {
      return data;
    });
  };

  validateImportedCustomerFile = async (data) => {
    return await this.request('api/import/customers/validate', {
      method: 'POST',
      body: data
    });
  }

  startImportCustomerFile = async (data) => {
    const configData = {
      background: 0,
      config: {
        has_header: data.has_header,
        custom_mapping: data.custom_mapping,
        save_mapping: data.save_mapping,
        category_id: data.category_id
      },
    };
    return await this.request(`api/import/customers/start/${data.processId}`, {
      method: 'POST',
      body: JSON.stringify(configData)
    });
  }
}
