import React from 'react';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Position from './CostCenterPosition';

import './style.scss';

class CostCenter extends React.Component {
  componentDidMount() {
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    wrapper.scrollTop = 0;
  }

  render() {
    const {costCenterPositions, positionsData, errors, onDataChange} = this.props;
    return (
      <GridContainer>
        <GridItem className="cost-center-positions-container" xs={12}>
          {
            costCenterPositions.length !== 0 &&
              costCenterPositions.map((position, index) => {
                return(
                  <Position
                    key={index}
                    onChange={(data) => onDataChange(index, data)}
                    data={position}
                    numericName={positionsData[index].numeric_name}
                    title={positionsData[index].title}
                    id={index}
                    errors={errors[index]}
                    modifiable={positionsData[index].can_edit}
                  />
                )
              })
          }
          <div className="list-space-holder">{ '\u00A0' }</div>
        </GridItem>
      </GridContainer>
    )
  }
}

export default CostCenter;
