import {ofType} from 'redux-observable';
import {from, of, concat} from 'rxjs';
import {switchMap, mergeMap, catchError} from 'rxjs/operators';
import {manufacturerProvidersAPI} from 'services/API';
import {
  getManufacturersDeliveryTypeSuccess,
  getManufacturersDeliveryTypeFailure,
  getManufacturersLocationsSuccess,
  getManufacturersLocationsFailure,
  getManufacturersStatusesSuccess,
  getManufacturersStatusesFailure
} from '../store/actions/manufacturerProviders';
import {
  GET_MANUFACTURERS_LOCATIONS,
  GET_MANUFACTURERS_STATUSES,
  GET_MANUFACTURERS_DELIVERY_TYPES
} from '../store/actionTypes/manufacturerProvidersActionTypes';

export const getManufacturerLocationsEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURERS_LOCATIONS),
  switchMap(() => from(manufacturerProvidersAPI.getManufacturerLocations()).pipe(
    switchMap((response) => {
      return of(getManufacturersLocationsSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturersLocationsFailure(err))
      }
    )
  ))
);

export const getManufacturerDeliveryTypesEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURERS_DELIVERY_TYPES),
  switchMap(() => from(manufacturerProvidersAPI.getManufacturerDeliveryTypes()).pipe(
    switchMap((response) => {
      return of(getManufacturersDeliveryTypeSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturersDeliveryTypeFailure(err))
      }
    )
  ))
);

export const getManufacturerStatusesEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURERS_STATUSES),
  switchMap(() => from(manufacturerProvidersAPI.getManufacturerStatuses()).pipe(
    switchMap((response) => {
      return of(getManufacturersStatusesSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturersStatusesFailure(err))
      }
    )
  ))
);
