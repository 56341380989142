import { handleActions } from 'redux-actions';
import {
  GET_PROGRESS_SUCCESS
} from '../actionTypes/progressActionTypes';

export const DEFAULT_PROGRESS_STATE = {
  progress: [],
  metaData: {}
};

export const progress = handleActions(
  {
    [GET_PROGRESS_SUCCESS]: (state, action) => ({
      ...state,
      progress: action.payload.data,
      metaData: action.payload
    })
  },
  DEFAULT_PROGRESS_STATE
);
