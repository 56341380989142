import {
  GET_LOGS,
  GET_LOGS_SUCCESS,
  GET_LOGS_ERROR,
  FILTER_LOGS
} from "../actionTypes/logsActionTypes";

export const getLogs = (id, page) => {
  return {type: GET_LOGS, payload: {id, page}}
};

export const  getLogsSuccess = (logs) => {
  return {
    type: GET_LOGS_SUCCESS,
    payload: logs
  }
};

export const getLogsFailure = (error)  => {
  return {
    type: GET_LOGS_ERROR,
    payload: error
  }
};

export const filterLogs = (id, filters)  => {
  return {
    type: FILTER_LOGS,
    payload: {id, filters}
  }
};

