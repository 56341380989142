import React from 'react';

import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import GridItem from 'components/Grid/GridItem';
import Dialog from '@material-ui/core/Dialog';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Button from "../../components/CustomButtons/Button";
import './style.scss';
import TextArea from "../../components/CustomTextArea";
import Autocomplete from "../../components/CustomAutocomplete";
import { withTranslation } from 'react-i18next';
import {
  getOrdersList,
  getPositionsList,
  getPositionCostCentersList,
  getPositionCostCenterItemsList,
  getCostCentersList,
  getCostCenterItemsList,
  resetProviders,
} from "../../store/actions/timeSheetsProviders";
import {
  startTimer,
  pauseTimer,
  resumeTimer,
  updateTimerNote,
  finalizeTimer,
  deleteTimer,
  getCurrentTimer,
  resetError,
} from "../../store/actions/timer";

import {connect} from "react-redux";
import Time from './Time';
import TimerUtil from './TimerUtil';

class Timer extends React.Component {

  static UPDATE_NOTE_TIMEOUT_DURATION = 5000;

  updateNoteTimeout = null;  // component state updates go through too often asynchronously, so this is better off as member variable

  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      selectedOrder: '',
      selectedPosition: '',
      selectedPositionCostCenter: '',
      selectedPositionCostCenterItem: '',
      selectedCostCenter: '',
      selectedCostCenterItem: '',
      note: '',
    };
  }

  componentDidMount() {
    const {
      getOrdersList,
      getCostCentersList,
      getCurrentTimer,
    } = this.props;

    getOrdersList();
    getCostCentersList();
    getCurrentTimer();

    TimerUtil.subscribe(this);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.currentTimer && this.props.currentTimer) {
      const timer = this.props.currentTimer;
      if (timer.loggable_type === "App\\Models\\PositionCostCenter") {
        this.setState({
          type: 0,
          note: timer.notes ? timer.notes : '',
          selectedOrder: timer.loggable_meta.order_id,
          selectedPosition: timer.loggable_meta.position_id,
          selectedPositionCostCenter: timer.loggable_id,
        });
        this.props.getPositionsList(timer.loggable_meta.order_id);
        this.props.getPositionCostCentersList(timer.loggable_meta.position_id);
      } if (timer.loggable_type === "App\\Models\\PositionCostCenterItem") {
        this.setState({
          type: 1,
          note: timer.notes ? timer.notes : '',
          selectedOrder: timer.loggable_meta.order_id,
          selectedPosition: timer.loggable_meta.position_id,
          selectedPositionCostCenter: timer.loggable_meta.position_cost_center_id,
          selectedPositionCostCenterItem: timer.loggable_id,
        });
        this.props.getPositionsList(timer.loggable_meta.order_id);
        this.props.getPositionCostCentersList(timer.loggable_meta.position_id);
        this.props.getPositionCostCenterItemsList(timer.loggable_meta.position_cost_center_id);
      } else if (timer.loggable_type === "App\\Models\\CostCenter") {
        this.setState({
          type: 2,
          note: timer.notes ? timer.notes : '',
          selectedCostCenter: timer.loggable_id,
          selectedCostCenterItem: '',
        });
        this.props.getCostCenterItemsList(timer.loggable_id);
      } else if (timer.loggable_type === "App\\Models\\CostCenterItem") {
        this.setState({
          type: 3,
          note: timer.notes ? timer.notes : '',
          selectedCostCenter: timer.loggable_meta.cost_center_id,
          selectedCostCenterItem: timer.loggable_id,
        });
        this.props.getCostCenterItemsList(timer.loggable_meta.cost_center_id);
      }
      const clientRequestMidpoint = this.props.timerRequestSent + Math.floor((this.props.timerResponseReceived - this.props.timerRequestSent) / 2);
      const clientServerClockOffsetCorrection = (this.props.timerServerTimestamp - clientRequestMidpoint) * 1000;
      const timerStart = new Date(timer.started_at * 1000 - clientServerClockOffsetCorrection);
      const timeResidue = timer.logged_time ? (timer.logged_time * 1000) : 0;
      TimerUtil.startTimer(timerStart, timeResidue, Boolean(timer.paused_at));
    }

    if (!prevProps.isTimerPaused && this.props.isTimerPaused) {
      const timer = this.props.currentTimer;
      const timeResidue = timer.logged_time ? (timer.logged_time * 1000) : null;
      TimerUtil.pauseTimer(timeResidue);
    } else if (prevProps.isTimerPaused && !this.props.isTimerPaused && this.props.isTimerStarted) {
      const timer = this.props.currentTimer;
      const clientRequestMidpoint = this.props.timerRequestSent + Math.floor((this.props.timerResponseReceived - this.props.timerRequestSent) / 2);
      const clientServerClockOffsetCorrection = (this.props.timerServerTimestamp - clientRequestMidpoint) * 1000;
      const timerStart = new Date(timer.started_at * 1000 - clientServerClockOffsetCorrection);
      const timeResidue = timer.logged_time ? (timer.logged_time * 1000) : null;
      TimerUtil.resumeTimer(timerStart, timeResidue);
    }

    if (!prevProps.isTimerFinalized && this.props.isTimerFinalized) {
      TimerUtil.resetTimer();
      this.props.onTimerClose();
    }

    if (!prevProps.isTimerDeleted && this.props.isTimerDeleted) {
      TimerUtil.resetTimer();
    }
  }

  componentWillUnmount() {
    TimerUtil.unsubscribe(this);
  }

  onTimerChange = (time) => {
    this.forceUpdate();
  }

  updateNote = () => {
    this.props.updateTimerNote(this.state.note);

    if (this.updateNoteTimeout) {
      this.updateNoteTimeout = null;
    }
  }

  onDeleteTime = () => {
    if (this.updateNoteTimeout) {
      clearTimeout(this.updateNoteTimeout);
      this.updateNoteTimeout = null;
    }
    this.props.deleteTimer();
  }

  onSaveTime = () => {
    if (this.updateNoteTimeout) {
      clearTimeout(this.updateNoteTimeout);
      this.updateNoteTimeout = null;
    }
    this.props.finalizeTimer({ notes: this.state.note });
  }

  onTimerStart = () => {
    let loggable_id, type;
    if (this.state.type == 0) {
      type = "position_cost_center";
      loggable_id = this.state.selectedPositionCostCenter;
    } else if (this.state.type == 1) {
      type = "position_cost_center_item";
      loggable_id = this.state.selectedPositionCostCenterItem;
    } else if (this.state.type == 2) {
      type = "cost_center";
      loggable_id = this.state.selectedCostCenter;
    } else if (this.state.type == 3) {
      type = "cost_center_item";
      loggable_id = this.state.selectedCostCenterItem;
    }
    this.props.startTimer({
      loggable_id,
      type,
      notes: this.state.note,
    });
  }

  onTimerPause = () => {
    this.props.pauseTimer();
  }

  onTimerResume = () => {
    this.props.resumeTimer();
  }

  handleTypeChangeOperational = () => {
    const { isTimerStarted, resetTimerError } = this.props;
    if (!isTimerStarted) {
      let newType;
      if (this.state.selectedPositionCostCenterItem !== -1) {
        newType = 1;
      } else {
        newType = 0;
      }
      this.setState({
        type: newType,
      });
      resetTimerError();
    }
  };

  handleTypeChangeNonOperational = () => {
    const { isTimerStarted, resetTimerError } = this.props;
    if (!isTimerStarted) {
      let newType;
      if (this.state.selectedCostCenterItem) {
        newType = 3;
      } else {
        newType = 2;
      }
      this.setState({
        type: newType,
      });
      resetTimerError();
    }
  };

  handleOrdersDropdownChange = (value) => {
    const { getPositionsList, resetTimerError } = this.props;
    if (this.state.selectedOrder != value) {
      this.setState({
        selectedOrder: value,
        selectedPosition: '',
        selectedPositionCostCenter: '',
        selectedPositionCostCenterItem: '',
      }, () => {
        getPositionsList(value);
        resetTimerError();
      });
    }
  }

  handlePositionDropdownChange = (value) => {
    const { getPositionCostCentersList, resetTimerError } = this.props;
    if (this.state.selectedPosition != value) {
      this.setState({
        selectedPosition: value,
        selectedPositionCostCenter: '',
        selectedPositionCostCenterItem: '',
      }, () => {
        getPositionCostCentersList(value);
        resetTimerError();
      });
    }
  }

  handlePositionCostCenterDropdownChange = (value) => {
    const { getPositionCostCenterItemsList, resetTimerError } = this.props;
    if(this.state.selectedPositionCostCenter != value) {
      this.setState({
        selectedPositionCostCenter: value,
        selectedPositionCostCenterItem: '',
      }, () => {
        if (this.state.type !== 0)
          this.setState({
            type: 0,
          });
        getPositionCostCenterItemsList(value);
        resetTimerError();
      });
    }
  }

  handlePositionCostCenterItemDropdownChange = (value) => {
    const { resetTimerError } = this.props;
    if(this.state.selectedPositionCostCenterItem != value) {
      this.setState({
        selectedPositionCostCenterItem: value,
      }, () => {
        if ((value !== -1 && this.state.type !== 1) || (value === -1 && this.state.type !== 0))
          this.setState({
            type: value !== -1 ? 1 : 0,
          });
        resetTimerError();
      });
    }
  }

  handleCostCenterDropdownChange = (value) => {
    const { getCostCenterItemsList, resetTimerError } = this.props;
    this.setState({
      selectedCostCenter: value,
      selectedCostCenterItem: '',
    }, () => {
      if (this.state.type !== 2)
        this.setState({
          type: 2,
        });
      getCostCenterItemsList(value);
      resetTimerError();
    });
  }

  handleCostCenterItemDropdownChange = (value) => {
    const { resetTimerError } = this.props;
    this.setState({
      selectedCostCenterItem: value,
    }, () => {
      if ((value !== -1 && this.state.type !== 3) || (value === -1 && this.state.type !== 2))
        this.setState({
          type: value !== -1 ? 3 : 2,
        });
      resetTimerError();
    });
  }

  handleNoteChange = (value) => {
    if (this.state.note != value) {
      this.setState({
        note: value,
      }, () => {
        if (this.updateNoteTimeout) {
          clearTimeout(this.updateNoteTimeout);
        }
        this.updateNoteTimeout = setTimeout(this.updateNote, Timer.UPDATE_NOTE_TIMEOUT_DURATION);
      });
    }
  }

  render() {
    const {
      ordersList,
      orderPositionsList,
      positionCostCentersList,
      positionCostCenterItemsList,
      costCentersList,
      costCenterItemsList,
      isTimerOpen,
      isTimerStarted,
      timerError,
      t
    } = this.props
    const {
      type,
      selectedOrder,
      selectedPosition,
      selectedPositionCostCenter,
      selectedPositionCostCenterItem,
      selectedCostCenter,
      selectedCostCenterItem,
      note,
    } = this.state;

    const ordersListActual = ordersList;
    const positionsList = orderPositionsList[selectedOrder] ? orderPositionsList[selectedOrder] : [];
    const positionCostCenterItemsListActual = [{id: -1, value: t('none')}, ...positionCostCenterItemsList];
    const costCenterItemsListActual = [{id: -1, value: t('none')}, ...costCenterItemsList];
    
    return (
      <Dialog
        PaperProps={{
          classes: {
            root: 'timer-container',
          }
        }}
        maxWidth={false}
        open={isTimerOpen}
      >
        <div
          className="timer-header"
        >
          <GridItem className="timer-header-title" xs={1}>
            <span>{ t('timer') }</span>
          </GridItem>
          <GridItem className="timer-header-content" xs={2}>
            <div className="timer-time">
              <span>{ TimerUtil.msToTime(TimerUtil.time) }</span>
            </div>
            <ArrowUpwardIcon onClick={this.props.onTimerClose} style={{color: "white", cursor: 'pointer'}}/>
          </GridItem>
        </div>
        <div className="timer-content">
          <div className="timer-main-content">
            <div className="timer-info-content">
              <span>{ t('type') }</span>
              <div className="timer-type-items">
                <div
                  className={`timer-type ${type === 0 || type === 1 ? 'selected' : ''}`}
                  onClick={() => this.handleTypeChangeOperational()}
                >
                  <div>{ t('operational') }</div>
                </div>
                <div
                  className={`timer-type ${(type === 2 || type === 3) ? 'selected' : ''}`}
                  onClick={() => this.handleTypeChangeNonOperational()}
                >
                  <div>{ t('non-operational') }</div>
                </div>
              </div>
              <div className={`timer-order-position-cost${type === 0 || type === 1 ? ' two-row' : ''}`}>
                { (type === 0 || type === 1) && (
                  <>
                    <div className="timer-row">
                      <div className="timer-dropdown-item">
                        <span>{ t('order') }</span>
                        <Autocomplete
                          buttonText={selectedOrder}
                          buttonProps={{className: '', round: true, size: "sm", disabled: isTimerStarted}}
                          dropdownList={ordersListActual}
                          error={timerError && timerError.errors && timerError.errors['loggable_id'] && timerError.errors['loggable_id'][0]}
                          hoverColor="dark"
                          getData={(term) => this.props.getOrdersList(term)}
                          onClick={(value) => this.handleOrdersDropdownChange(value.id)}
                          placeholder={t('enter')}
                          secondaryValueField={'order_no'}
                          displayTextFormat={'[{2}] {1}'}
                          discardIcon={true}
                          alternativeDisabledMode={true}
                        />
                      </div>
                      <div className="timer-dropdown-item">
                        <span>{ t('position') }</span>
                        <Dropdown
                          buttonText={selectedPosition}
                          buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedOrder || isTimerStarted}}
                          dropdownList={positionsList}
                          onClick={(value) => this.handlePositionDropdownChange(value.id)}
                          error={timerError && timerError.errors && timerError.errors['loggable_id'] && timerError.errors['loggable_id'][0]}
                        />
                      </div>
                    </div>
                    <div className="timer-row">
                      <div className="timer-dropdown-item">
                        <span>{ t('cost_center') }</span>
                        <Dropdown
                          buttonText={selectedPositionCostCenter}
                          buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedOrder || !selectedPosition || isTimerStarted}}
                          dropdownList={positionCostCentersList}
                          onClick={(value) => this.handlePositionCostCenterDropdownChange(value.id)}
                          error={timerError && timerError.errors && timerError.errors['loggable_id'] && timerError.errors['loggable_id'][0]}
                        />
                      </div>
                      <div className="timer-dropdown-item">
                        <span>{ t('cost_center_item') }</span>
                        <Dropdown
                          buttonText={selectedPositionCostCenterItem !== null && selectedPositionCostCenterItem !== undefined && selectedPositionCostCenterItem !== '' ? selectedPositionCostCenterItem : (selectedOrder && selectedPosition && selectedPositionCostCenter ? -1 : '')}
                          buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedOrder || !selectedPosition || !selectedPositionCostCenter || isTimerStarted}}
                          dropdownList={positionCostCenterItemsListActual}
                          onClick={(value) => this.handlePositionCostCenterItemDropdownChange(value.id)}
                          error={timerError && timerError.errors && timerError.errors['loggable_id'] && timerError.errors['loggable_id'][0]}
                        />
                      </div>
                    </div>
                  </>
                )}
                { (type === 2 || type === 3) && (
                  <>
                    <div className="timer-dropdown-item">
                      <span>{ t('cost_center') }</span>
                      <Dropdown
                        buttonText={selectedCostCenter}
                        buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: isTimerStarted}}
                        dropdownList={costCentersList}
                        onClick={(value) => this.handleCostCenterDropdownChange(value.id)}
                        error={timerError && timerError.errors && timerError.errors['loggable_id'] && timerError.errors['loggable_id'][0]}
                      />
                    </div>
                    <div className="timer-dropdown-item">
                      <span>{ t('cost_center_item') }</span>
                      <Dropdown
                        buttonText={selectedCostCenterItem !== null && selectedCostCenterItem !== undefined && selectedCostCenterItem !== '' ? selectedCostCenterItem : (selectedCostCenter ? -1 : '')}
                        buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedCostCenter || isTimerStarted}}
                        dropdownList={costCenterItemsListActual}
                        onClick={(value) => this.handleCostCenterItemDropdownChange(value.id)}
                        error={timerError && timerError.errors && timerError.errors['loggable_id'] && timerError.errors['loggable_id'][0]}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="timer-note">
                <span>{ t('note') }</span>
                <div className="description-test-area">
                  <TextArea
                    value={note}
                    onChange={(e) => this.handleNoteChange(e.target.value)}
                    error={timerError && timerError.errors && timerError.errors['notes'] && timerError.errors['notes'][0]}
                  />
                </div>
              </div>
            </div>
            <Time
              onTimerStart={this.onTimerStart}
              onTimerPause={this.onTimerPause}
              onTimerResume={this.onTimerResume}
            />
          </div>
          <div className="timer-btn-bottom">
            <Button disabled={!isTimerStarted} className="timer-btn cancel-btn" onClick={this.onDeleteTime}>{ t('delete_time').toUpperCase() }</Button>
            <Button disabled={!isTimerStarted} className="timer-btn create-btn" onClick={this.onSaveTime}>{ t('save_time').toUpperCase() }</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProp = state => {
  return {
    coworkers: state.providers.coworkersList,
    ordersList: state.timeSheetsProviders.ordersList,
    orderPositionsList: state.timeSheetsProviders.positionsList,
    positionCostCentersList: state.timeSheetsProviders.positionCostCentersList,
    positionCostCenterItemsList: state.timeSheetsProviders.positionCostCenterItemsList,
    costCentersList: state.timeSheetsProviders.costCentersList,
    costCenterItemsList: state.timeSheetsProviders.costCentersItemsList,
    currentTimer: state.timers.currentTimer,
    timerRequestSent: state.timers.requestSent,
    timerServerTimestamp: state.timers.serverTimestamp,
    timerResponseReceived: state.timers.responseReceived,
    isTimerStarted: state.timers.isTimerStarted,
    isTimerPaused: state.timers.isTimerPaused,
    isTimerFinalized: state.timers.isTimerFinalized,
    isTimerDeleted: state.timers.isTimerDeleted,
    timerError: state.timers.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrdersList: (searchTerm) => dispatch(getOrdersList(searchTerm)),
    getPositionsList: (id) => dispatch(getPositionsList(id)),
    getPositionCostCentersList: (id) => dispatch(getPositionCostCentersList(id)),
    getPositionCostCenterItemsList: (id) => dispatch(getPositionCostCenterItemsList(id)),
    getCostCentersList: () => dispatch(getCostCentersList()),
    getCostCenterItemsList: (id) => dispatch(getCostCenterItemsList(id)),
    resetProviders: () => dispatch(resetProviders()),
    startTimer: (data) => dispatch(startTimer(data)),
    pauseTimer: () => dispatch(pauseTimer()),
    resumeTimer: () => dispatch(resumeTimer()),
    updateTimerNote: (note) => dispatch(updateTimerNote(note)),
    finalizeTimer: (data) => dispatch(finalizeTimer(data)),
    deleteTimer: () => dispatch(deleteTimer()),
    getCurrentTimer: () => dispatch(getCurrentTimer()),
    resetTimerError: () => dispatch(resetError()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Timer));
