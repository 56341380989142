import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {filterLogs} from 'store/actions/logs';
import {LogsListSortByOptions} from 'constants/constants';

import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DatePicker from 'components/CustomDatePicker';
import './style.scss';
import CustomPagination from "../../components/CustomPagination";

class OrderLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        page: '',
        order: 'created_at'
      },
      date_filter: null,
    };
  }

  handlePageClick = (data) => {
    const {orderId, filterLogs} = this.props;
    const {filters} = this.state;
    const newFilters = {...filters};
    newFilters.page = data.selected + 1;
    filterLogs(orderId, newFilters)
    this.setState({
      filters: newFilters
    })
  };

  handleDateChange = (name, value) => {
    const dateValue = value ? moment(value).format('YYYY-MM-DD') : null;
    this.handleFilters('filterDate', dateValue);
    this.setState({
      [name]: dateValue
    })
  }

  handleFilters = (fieldName, value) => {
    const {orderId, filterLogs} = this.props;
    const {filters} = this.state;
    const newFilters = {...filters};
    newFilters[fieldName] = value;
    filterLogs(orderId, newFilters);
    this.setState({
      filters: newFilters
    })
  };

  renderLog = (log, index) => {
    return (
      <GridContainer
        className={`logs-item ${index % 2 === 0 ? 'dark' : ''}`}
        direction="row"
        xs={12}
      >
        <GridItem className="logs-item-title" xs={2}>{moment(log.created_at).format('YYYY-MM-DD')}</GridItem>
        <GridItem className="logs-item-title" xs={2}>{moment(log.created_at).format('hh:mm:ss')}</GridItem>
        <GridItem className="logs-item-title" xs={6}>{log.action ? log.action : ''}</GridItem>
        <GridItem className="logs-item-title bold blue" xs={2}>{log.user && `${log.user.first_name} ${log.user.last_name}`}</GridItem>
      </GridContainer>
    );
  };

  render() {
    const { logs, metaData, t } = this.props;
    const { filters } = this.state;
    return (
      <>
      <GridContainer className="project-logs-container">
        <GridItem className="project-logs-top" xs={12}>
          <GridItem className="logs-title" xs={3}>
            <span>{logs.length !== 0 ? (logs.length + ' ' + t('records')) : ''}</span>
          </GridItem>
          <GridItem className="logs-top-right-bar">
            <DatePicker 
              className="date-picker" 
              placeholder={ t('filter_dates') }
              handleChange={date => this.handleDateChange('date_filter', date)} 
              selectedDate={this.state.date_filter}
            />
            <Dropdown
              buttonText={filters.order}
              buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
              dropdownList={LogsListSortByOptions}
              translateValues={true}
              onClick={value => this.handleFilters('order', value.id)}
            />
          </GridItem>
        </GridItem>
        <GridContainer className="logs-header" direction="row" xs={12}>
          <GridContainer
            className="logs-header-content"
            direction="row"
            xs={12}
          >
            <GridItem className="logs-header-title" xs={2}>
              { t('date') }
            </GridItem>
            <GridItem className="logs-header-title" xs={2}>
              { t('time') }
            </GridItem>
            <GridItem className="logs-header-title" xs={6}>
              { t('action') }
            </GridItem>
            <GridItem className="logs-header-title" xs={2}>
              { t('user') }
            </GridItem>
          </GridContainer>
        </GridContainer>
        <GridContainer className="logs-content">
          {logs && logs.map((log, index) => this.renderLog(log, index))}
        </GridContainer>
      </GridContainer>
      <GridContainer className="pagination-container">
        {
          metaData.last_page > 1 &&
          <CustomPagination
            pageCount={metaData.last_page}
            handlePageClick={this.handlePageClick}
          />
        }
      </GridContainer>
      </>
    );
  }
}

const mapStateToProp = state => {
  return {
    logs: state.logs.logs,
    metaData: state.logs.metadata
  };
};

const mapDispatchToProps = dispatch => {
  return {
    filterLogs: (id, filters) => dispatch(filterLogs(id, filters))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(OrderLog));
