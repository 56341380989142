import {ofType} from 'redux-observable';
import {from, of, concat, merge} from 'rxjs';
import {switchMap, mergeMap, catchError, endWith} from 'rxjs/operators';
import {coworkerTimeSheetServiceApi} from 'services/API';
import NotificationService from '../services/NotificationService';
import {
  filterCoworkerTimeSheets,
  getCoworkerTimeEntriesSuccess,
  getCoworkerTimeEntriesFailure,
  getCoworkerTimeEntrySuccess,
  getCoworkerTimeEntryFailure,
  createCoworkerTimeEntrySuccess,
  createCoworkerTimeEntryFailure,
  createCoworkerTimeEntryFinish,
  updateCoworkerTimeEntrySuccess,
  updateCoworkerTimeEntryFailure,
  deleteCoworkerTimeEntrySuccess,
  deleteCoworkerTimeEntryFailure
} from 'store/actions/coworkerTimeSheet';
import {
  GET_COWORKER_TIME_ENTRIES,
  GET_COWORKER_TIME_ENTRY,
  CREATE_COWORKER_TIME_ENTRY,
  CREATE_COWORKER_TIME_ENTRY_FINISH,
  UPDATE_COWORKER_TIME_ENTRY,
  DELETE_COWORKER_TIME_ENTRY,
  FILTER_COWORKER_TIME_SHEETS
} from 'store/actionTypes/coworkerTimeSheetActionsTypes';
import {
  getHoursLogged,
} from 'store/actions/timeSheetsProviders';

export const getCoworkerTimeEntriesEpic = (actions$) => actions$.pipe(
  ofType(GET_COWORKER_TIME_ENTRIES),
  switchMap(() => from(coworkerTimeSheetServiceApi.getCoworkerTimeEntries()).pipe(
    switchMap((response) => {
      return of(getCoworkerTimeEntriesSuccess(response.data))
    }),
    catchError(err => {
      return of(getCoworkerTimeEntriesFailure(err));
    })
  ))
);

export const getCoworkerTimeEntryEpic = (actions$) => actions$.pipe(
  ofType(GET_COWORKER_TIME_ENTRY),
  switchMap((action) => from(coworkerTimeSheetServiceApi.getCoworkerTimeEntry(action.payload)).pipe(
    switchMap((response) => {
      return of(getCoworkerTimeEntrySuccess(response.data))
    }),
    catchError(err => {
      return of(getCoworkerTimeEntryFailure(err));
    })
  ))
);

export const addCoworkerTimeEntryEpic = (actions$) => actions$.pipe(
  ofType(CREATE_COWORKER_TIME_ENTRY),
  mergeMap(({payload}) => {
    const observables = payload.entries.map(el => from(coworkerTimeSheetServiceApi.addNewCoworkerTimeEntry(el)).pipe(
      switchMap((response) => {
        NotificationService.success("Time entry created successfully.");
        return of(createCoworkerTimeEntrySuccess(el.uid, response));
      }),
      catchError(err => {
        return of(createCoworkerTimeEntryFailure(el.uid, err));
      })
    ));
    return merge(
      ...observables
    ).pipe(endWith(createCoworkerTimeEntryFinish(payload.filters, payload.sort, payload.sortOrder, payload.searchTerms)));
  }
));

export const createCoworkerTimeEntryFinishEpic = (actions$, state$) => actions$.pipe(
  ofType(CREATE_COWORKER_TIME_ENTRY_FINISH),
  switchMap(({payload}) => {
    const statePart = state$.value.timeSheetsProviders;
    return statePart.hoursLoggedRequest ?
      concat(
        of(filterCoworkerTimeSheets(payload.filters, payload.sort, payload.sortOrder, payload.searchTerms)),
        of(getHoursLogged(statePart.hoursLoggedRequest.dateFrom, statePart.hoursLoggedRequest.dateTo)),
      ) :
      of(filterCoworkerTimeSheets(payload.filters, payload.sort, payload.sortOrder, payload.searchTerms));
  })
);

export const updateCoworkerTimeEntryEpic = (actions$, state$) => actions$.pipe(
  ofType(UPDATE_COWORKER_TIME_ENTRY),
  switchMap(({payload}) => from(coworkerTimeSheetServiceApi.updateCoworkerTimeEntry(payload.id, payload.data)).pipe(
    switchMap((response) => {
      NotificationService.success("Time entry edited successfully.");
      const statePart = state$.value.timeSheetsProviders;
      return statePart.hoursLoggedRequest ?
        concat(
          of(filterCoworkerTimeSheets(payload.filters, payload.sort, payload.sortOrder, payload.searchTerms)),
          of(getHoursLogged(statePart.hoursLoggedRequest.dateFrom, statePart.hoursLoggedRequest.dateTo)),
          of(updateCoworkerTimeEntrySuccess(payload.id, response)),
        ) : concat(
          of(filterCoworkerTimeSheets(payload.filters, payload.sort, payload.sortOrder, payload.searchTerms)),
          of(updateCoworkerTimeEntrySuccess(payload.id, response)),
        );
    }),
    catchError(err => {
      return of(updateCoworkerTimeEntryFailure(payload.id, err));
    })
  ))
);

export const removeCoworkerTimeEntryEpic = (actions$, state$) => actions$.pipe(
  ofType(DELETE_COWORKER_TIME_ENTRY),
  switchMap(({payload}) => from(coworkerTimeSheetServiceApi.deleteCoworkerTimeEntry(payload.id)).pipe(
    switchMap((response) => {
      NotificationService.success("Time entry deleted successfully.");
      const statePart = state$.value.timeSheetsProviders;
      return statePart.hoursLoggedRequest ?
        concat(
          of(filterCoworkerTimeSheets(payload.filters, payload.sort, payload.sortOrder, payload.searchTerms)),
          of(getHoursLogged(statePart.hoursLoggedRequest.dateFrom, statePart.hoursLoggedRequest.dateTo)),
          of(deleteCoworkerTimeEntrySuccess(response)),
        ) : concat(
          of(filterCoworkerTimeSheets(payload.filters, payload.sort, payload.sortOrder, payload.searchTerms)),
          of(deleteCoworkerTimeEntrySuccess(response)),
        );
    }),
    catchError(err => {
      return of(deleteCoworkerTimeEntryFailure(err));
    })
  ))
);

export const filterCoworkerTimeSheetsEpic = (actions$) => actions$.pipe(
  ofType(FILTER_COWORKER_TIME_SHEETS),
  switchMap((action) => from(coworkerTimeSheetServiceApi.filterTimeSheets(action.payload.filters, action.payload.sortBy, action.payload.sortByOrder, action.payload.searchTerms)).pipe(
    switchMap((response) => {
      return of(getCoworkerTimeEntriesSuccess(response));
    }),
    catchError(err => {
      return of(getCoworkerTimeEntriesFailure(err));
    })
  ))
);
