import { handleActions } from 'redux-actions';
import {
  GET_COUNTRIES_SUCCESS,
  GET_CITIES_SUCCESS
} from '../actionTypes/locationActionTypes';

export const DEFAULT_LOCATIONS_STATE = {
  countries: [],
  cities: []
};

export const locations = handleActions(
  {
    [GET_COUNTRIES_SUCCESS]: (state, action) => ({
      ...state,
      countries: action.payload,
    }),
    [GET_CITIES_SUCCESS]: (state, action) => ({
      ...state,
      cities: action.payload,
    })
  },
  DEFAULT_LOCATIONS_STATE
);
