import {
  GET_MATERIAL_CATEGORIES,
  GET_MATERIAL_CATEGORIES_SUCCESS,
  GET_MATERIAL_CATEGORIES_ERROR,
  GET_MATERIAL_CATEGORIES_PARENTS,
  GET_MATERIAL_CATEGORIES_PARENTS_SUCCESS,
  GET_MATERIAL_CATEGORIES_PARENTS_ERROR,
  GET_MATERIAL_CATEGORY,
  GET_MATERIAL_CATEGORY_SUCCESS,
  GET_MATERIAL_CATEGORY_ERROR,
  ADD_MATERIAL_CATEGORY,
  ADD_MATERIAL_CATEGORY_SUCCESS,
  ADD_MATERIAL_CATEGORY_ERROR,
  UPDATE_MATERIAL_CATEGORY,
  UPDATE_MATERIAL_CATEGORY_SUCCESS,
  UPDATE_MATERIAL_CATEGORY_ERROR,
  GET_MATERIAL_CATEGORY_SETTINGS,
  GET_MATERIAL_CATEGORY_SETTINGS_SUCCESS,
  GET_MATERIAL_CATEGORY_SETTINGS_ERROR,
  REMOVE_MATERIAL_CATEGORY,
  REMOVE_MATERIAL_CATEGORY_SUCCESS,
  REMOVE_MATERIAL_CATEGORY_ERROR,
  RESET_MATERIAL_CATEGORY
} from '../actionTypes/materialCategoriesActionTypes';

import NotificationService from '../../services/NotificationService';

export const getMaterialCategories = (page, searchTerms, isGetAllData) => {
  return {type: GET_MATERIAL_CATEGORIES, payload: { page, searchTerms, isGetAllData }}
};

export const getMaterialCategoriesSuccess = (data) => {
  return {type: GET_MATERIAL_CATEGORIES_SUCCESS, payload: data}
};

export const getMaterialCategoriesFailure = (error) => {
  return {type: GET_MATERIAL_CATEGORIES_ERROR, payload: error}
};

export const getMaterialCategoriesParents = (searchTerms) => {
  return {type: GET_MATERIAL_CATEGORIES_PARENTS, payload: searchTerms }
};

export const getMaterialCategoriesParentsSuccess = (data) => {
  let parents = [];
  data.forEach(item => parents.push({id: item.id, value: item.name}))
  return {type: GET_MATERIAL_CATEGORIES_PARENTS_SUCCESS, payload: parents}
};

export const getMaterialCategoriesParentsFailure = (error) => {
  return {type: GET_MATERIAL_CATEGORIES_PARENTS_ERROR, payload: error}
};

export const getMaterialCategory = (id) => {
  return {type: GET_MATERIAL_CATEGORY, payload: id}
};

export const getMaterialCategorySuccess = (category) => {
  return {type: GET_MATERIAL_CATEGORY_SUCCESS, payload: category}
};

export const getMaterialCategoryFailure = (error) => {
  return {type: GET_MATERIAL_CATEGORY_ERROR, payload: error}
};

export const addMaterialCategory = (data) => {
  return {type: ADD_MATERIAL_CATEGORY, payload: data}
};

export const addMaterialCategorySuccess = () => {
  NotificationService.success('Material category was added successfully');
  return {type: ADD_MATERIAL_CATEGORY_SUCCESS}
};

export const addMaterialCategoryFailure = (error) => {
  NotificationService.error(error.message);
  return {type: ADD_MATERIAL_CATEGORY_ERROR, payload: error.errors}
};

export const updateMaterialCategory = (data) => {
  return {type: UPDATE_MATERIAL_CATEGORY, payload: data}
};

export const updateMaterialCategorySuccess = () => {
  NotificationService.success('Material category updated successfully');
  return {type: UPDATE_MATERIAL_CATEGORY_SUCCESS}
};

export const updateMaterialCategoryFailure = (error) => {
  NotificationService.error(error);
  return {type: UPDATE_MATERIAL_CATEGORY_ERROR, payload: error.errors}
};

export const deleteMaterialCategory = (data) => {
  return {type: REMOVE_MATERIAL_CATEGORY, payload: data}
};

export const deleteMaterialCategorySuccess = () => {
  NotificationService.success('Material category removed');
  return {type: REMOVE_MATERIAL_CATEGORY_SUCCESS}
};

export const deleteMaterialCategoryFailure = (error) => {
  return {type: REMOVE_MATERIAL_CATEGORY_ERROR, payload: error}
};

export const getMaterialCategorySettings = () => {
  return {type: GET_MATERIAL_CATEGORY_SETTINGS}
};

export const getMaterialCategorySettingsSuccess = (data) => {
  return {type: GET_MATERIAL_CATEGORY_SETTINGS_SUCCESS, payload: data}
};

export const getMaterialCategorySettingsFailure = (error) => {
  return {type: GET_MATERIAL_CATEGORY_SETTINGS_ERROR, payload: error}
};

export const resetMaterialCategory = () => {
  return {type: RESET_MATERIAL_CATEGORY}
};
