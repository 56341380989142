import {
  GET_COWORKER_TIME_ENTRIES,
  GET_COWORKER_TIME_ENTRIES_SUCCESS,
  GET_COWORKER_TIME_ENTRIES_ERROR,
  GET_COWORKER_TIME_ENTRY,
  GET_COWORKER_TIME_ENTRY_SUCCESS,
  GET_COWORKER_TIME_ENTRY_ERROR,
  CREATE_COWORKER_TIME_ENTRY,
  CREATE_COWORKER_TIME_ENTRY_SUCCESS,
  CREATE_COWORKER_TIME_ENTRY_ERROR,
  CREATE_COWORKER_TIME_ENTRY_FINISH,
  UPDATE_COWORKER_TIME_ENTRY,
  UPDATE_COWORKER_TIME_ENTRY_SUCCESS,
  UPDATE_COWORKER_TIME_ENTRY_ERROR,
  DELETE_COWORKER_TIME_ENTRY,
  DELETE_COWORKER_TIME_ENTRY_SUCCESS,
  DELETE_COWORKER_TIME_ENTRY_ERROR,
  FILTER_COWORKER_TIME_SHEETS,
  RESET_LAST_FINISHED_EDIT,
  RESET_TIME_ENTRY_DATA
} from '../actionTypes/coworkerTimeSheetActionsTypes';

export const getCoworkerTimeEntries = () => {
  return {type: GET_COWORKER_TIME_ENTRIES}
};

export const getCoworkerTimeEntriesSuccess = (entries) => {
  return {type: GET_COWORKER_TIME_ENTRIES_SUCCESS, payload: entries}
};

export const getCoworkerTimeEntriesFailure = (error) => {
  return {type: GET_COWORKER_TIME_ENTRIES_ERROR, payload: error}
};

export const getCoworkerTimeEntry = (id) => {
  return {type: GET_COWORKER_TIME_ENTRY, payload: id}
};

export const getCoworkerTimeEntrySuccess = (entry) => {
  return {type: GET_COWORKER_TIME_ENTRY_SUCCESS, payload: entry}
};

export const getCoworkerTimeEntryFailure = (error) => {
  return {type: GET_COWORKER_TIME_ENTRY_ERROR, payload: error}
};

export const createCoworkerTimeEntry = (entries, filters, sort, sortOrder, searchTerms) => {
  return {type: CREATE_COWORKER_TIME_ENTRY, payload: { entries, filters, sort, sortOrder, searchTerms }}
};

export const createCoworkerTimeEntrySuccess = (uid, data) => {
  return {type: CREATE_COWORKER_TIME_ENTRY_SUCCESS, payload: { uid, data }}
};

export const createCoworkerTimeEntryFailure = (uid, error) => {
  return {type: CREATE_COWORKER_TIME_ENTRY_ERROR, payload: { uid, error }}
};

export const createCoworkerTimeEntryFinish = (filters, sort, sortOrder, searchTerms) => {
  return {type: CREATE_COWORKER_TIME_ENTRY_FINISH, payload: { filters, sort, sortOrder, searchTerms }}
};

export const updateCoworkerTimeEntry = (id, data, filters, sort, sortOrder, searchTerms) => {
  return {type: UPDATE_COWORKER_TIME_ENTRY, payload: { id, data, filters, sort, sortOrder, searchTerms }}
};

export const updateCoworkerTimeEntrySuccess = (id, data) => {
  return {type: UPDATE_COWORKER_TIME_ENTRY_SUCCESS, payload: {id, data}}
};

export const updateCoworkerTimeEntryFailure = (id, error) => {
  return {type: UPDATE_COWORKER_TIME_ENTRY_ERROR, payload: {id, error}}
};

export const deleteCoworkerTimeEntry = (id, filters, sort, sortOrder, searchTerms) => {
  return {type: DELETE_COWORKER_TIME_ENTRY, payload: { id, filters, sort, sortOrder, searchTerms }}
};

export const deleteCoworkerTimeEntrySuccess = (data) => {
  return {type: DELETE_COWORKER_TIME_ENTRY_SUCCESS, payload: data}
};

export const deleteCoworkerTimeEntryFailure = (error) => {
  return {type: DELETE_COWORKER_TIME_ENTRY_ERROR, payload: error}
};

export const filterCoworkerTimeSheets = (filters, sortBy, sortByOrder, searchTerms) => {
  return {type: FILTER_COWORKER_TIME_SHEETS, payload: { filters, sortBy, sortByOrder, searchTerms }};
};

export const resetLastFinishedEdit = () => {
  return {type: RESET_LAST_FINISHED_EDIT};
}

export const resetTimeEntryData = () => {
  return {type: RESET_TIME_ENTRY_DATA};
}
