import React from "react";
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CloseButton from "assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";

class MaterialImportDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: '',
      selectedReplace: 0,
    };
  }

  handleCategoryChange = (id) => {
    this.setState({
      selectedCategory: id
    })
  };

  handleReplaceChange = (id) => {
    this.setState({
      selectedReplace: id
    })
  };

  handleImportMaterial = () => {
    const {onSubmit} = this.props;
    const {selectedCategory, selectedReplace} = this.state;
    if (selectedCategory !== '') {
      onSubmit(selectedCategory, selectedReplace);
    }
  }

  render() {
    const {
      isOpen,
      categories,
      onClose,
      t
    } = this.props;
    const {
      selectedCategory,
      selectedReplace,
    } = this.state;
    let categoriesList = [];
    categories.forEach(element => categoriesList.push({id: element.id, value: element.name}));
    const replaceList = [{id: 0, value: t('no')}, {id: 1, value: t('yes')}];
    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'import-material-categories-dialog',
          }
        }}>
        <MuiDialogTitle className="import-material-title" disableTypography>
          <Typography variant="h6" className="dialog-title">
            { t('import_material') }
          </Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="file-import-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="import-material-content">
          <div className="title">{t('select_material_category_from_list')}:</div>
          <div>
            <Dropdown
              buttonText={selectedCategory}
              buttonProps={{className: 'import-material-dropdown-style', round: true, size: "sm"}}
              dropdownList={categoriesList}
              hoverColor="dark"
              onClick={item => this.handleCategoryChange(item.id)}
            />
          </div>
          <div className="title">{t('replace_existing_materials')}</div>
          <div>
            <Dropdown
              buttonText={selectedReplace}
              buttonProps={{className: 'import-material-dropdown-style', round: true, size: "sm"}}
              dropdownList={replaceList}
              hoverColor="dark"
              onClick={item => this.handleReplaceChange(item.id)}
            />
          </div>
        </div>
        <div className="import-material-btn-container">
          <Button
            className="cancel-btn"
            onClick={onClose}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="import-btn"
            onClick={this.handleImportMaterial}
          >
            { t('import').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(MaterialImportDialog);
