import BaseAPIService from './Base';

export default class ProgressAPIService extends BaseAPIService {
  getProgress = async (id) => {
    return this.request(`api/positions?filter[order_id]=${id}&order=name&per-page=0`).then((data) => {
      return data;
    });
  };

  filterProgress = async (id, filters) => {
    let order = filters.sort_by;
    if (filters.sort_by_order === 'desc') {
      order = '-' + order;
    }
    let url = `${filters.page !== '' ? `&page=${filters.page}` : ''}${order ? `&order=${order}` : ''}${filters.coworker_id !== '' ? `&filter[coworker_id]=${filters.coworker_id}` : ''}${filters.show_completed ? '&filter[progress]=100' : '&filter[progress]=<100'}&per-page=0`;
    return this.request(`api/positions?filter[order_id]=${id}${url}`).then((data) => {
      return data;
    });
  };

  updateProgress = async (id, data) => {
    return this.request(`api/positions/progress/${id}`, {
      method: 'PUT',
      body: JSON.stringify({progress: data})
    });
  };
}
