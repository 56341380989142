import BaseAPIService from './Base';

import moment from 'moment';

export default class TimeSheetsProvidersAPIService extends BaseAPIService {
  
  getHoursLogged = async (dateFrom, dateTo) => {
    const from = moment(dateFrom).format('YYYY-MM-DD');
    const to = moment(dateTo).format('YYYY-MM-DD');
    return this.request(`api/timesheet/own/entries/hours-logged?from=${from}&to=${to}&with_breakdown=1`).then((data) => {
      return data;
    });
  }

  getOrdersList = async (searchTerm) => {
    const searchTermPart = searchTerm ? `?search-term=${searchTerm}` : '';
    return this.request(`api/timesheet/own/manufacturer/orders${searchTermPart}`).then((data) => {
      return data;
    });
  };

  getPositionsList = async (id) => {
    return this.request(`api/timesheet/own/manufacturer/orders/${id}/positions`).then((data) => {
      return data;
    });
  };

  getTasksList = async (id) => {
    return this.request(`api/timesheet/own/manufacturer/positions/${id}/tasks`).then((data) => {
      return data;
    });
  };

  getCostCentersList = async () => {
    return this.request('api/timesheet/own/manufacturer/cost-centers').then((data) => {
      return data;
    });
  };

  getCostCenterItemsList = async (id) => {
    return this.request(`api/timesheet/own/manufacturer/cost-centers/${id}/items`).then((data) => {
      return data;
    });
  };

  getPositionCostCentersList = async (id) => {
    return this.request(`api/timesheet/own/positions/${id}/cost-centers`).then((data) => {
      return data;
    });
  };

  getPositionCostCenterItemsList = async (id) => {
    return this.request(`api/timesheet/own/cost-centers/${id}/items`).then((data) => {
      return data;
    });
  };
}
