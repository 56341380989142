import React from 'react';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import { translationSupportedLanguages } from 'constants/constants';
import flagEn from 'assets/img/translation/flagEn.png';
import flagDe from 'assets/img/translation/flagDe.png';
import './customDropdownLanguage.scss';

export default React.forwardRef((props, ref) => {
  const { ...rest } = props;
  const dropdownList = translationSupportedLanguages.map(el => {
    let selectedFlag = null;
    switch(el.id) {
      case 'en':
        selectedFlag = flagEn;
        break;
      case 'de':
        selectedFlag = flagDe;
        break;
    }
    return {
      ...el,
      icon: selectedFlag,
    };
  });
  return (
    <CustomDropdown
      buttonProps={{
        className: 'cdl-dropdown-button-style',
        round: true,
        size: 'sm'
      }}
      className={'cdl'}
      dropdownList={dropdownList}
      { ...rest }
    />
  )
});