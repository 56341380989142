import BaseAPIService from './Base';

export default class InquiriesAPIService extends BaseAPIService {
  getInquiries = async () => {
    return this.request('api/orders/own').then((data) => {
      return data;
    });
  };

  getInquiry = async (id) => {
    return this.request(`api/orders/own/${id}`).then((data) => {
      return data;
    });
  };

  createInquiry = async (data) => {
    return this.request('api/orders/own', {
      method: 'POST',
      body: data
    });
  };

  deleteInquiry = async (id) => {
    return this.request(`api/orders/${id}`, {
      method: 'DELETE',
    });
  };

  updateInquiry = async (id, data) => {
    return this.request(`api/orders/own/${id}`, {
      method: 'PUT',
      body: data
    });
  };

  filterInquiries = async (filters) => {
    let url = `?${filters.page !== '' ? `page=${filters.page}` : '?'}${filters.order !== '' ? `&order=${filters.order}` : ''}${filters.type !== '' ? `&filter[type]=${filters.type}` : ''}${filters.status !== '' ? `&filter[status]=${filters.status}` : ''}${filters.starred !== '' ? `&filter[is_starred]=${filters.starred}` : ''}`;
    return this.request(`api/inquiries/own${url}`).then((data) => {
      return data;
    });
  };
}
