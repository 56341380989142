export const ADD_MANUFACTURER_ORDER = 'manufacturerOrderEpic/ADD_MANUFACTURER_ORDER';
export const ADD_MANUFACTURER_ORDER_SUCCESS = 'manufacturerOrderEpic/ADD_MANUFACTURER_ORDER_SUCCESS';
export const ADD_MANUFACTURER_ORDER_ERROR = 'manufacturerOrderEpic/ADD_MANUFACTURER_ORDER_ERROR';

export const UPDATE_MANUFACTURER_ORDER = 'manufacturerOrderEpic/UPDATE_MANUFACTURER_ORDER';
export const UPDATE_MANUFACTURER_ORDER_SUCCESS = 'manufacturerOrderEpic/UPDATE_MANUFACTURER_ORDER_SUCCESS';
export const UPDATE_MANUFACTURER_ORDER_ERROR = 'manufacturerOrderEpic/UPDATE_MANUFACTURER_ORDER_ERROR';

export const GET_MANUFACTURER_ORDER = 'manufacturerOrderEpic/GET_MANUFACTURER_ORDER';
export const GET_MANUFACTURER_ORDER_SUCCESS = 'manufacturerOrderEpic/GET_MANUFACTURER_ORDER_SUCCESS';
export const GET_MANUFACTURER_ORDER_ERROR = 'manufacturerOrderEpic/GET_MANUFACTURER_ORDER_ERROR';

export const DELETE_MANUFACTURER_ORDER = 'manufacturerOrderEpic/DELETE_MANUFACTURER_ORDER';
export const DELETE_MANUFACTURER_ORDER_SUCCESS = 'manufacturerOrderEpic/DELETE_MANUFACTURER_ORDER_SUCCESS';
export const DELETE_MANUFACTURER_ORDER_ERROR = 'manufacturerOrderEpic/DELETE_MANUFACTURER_ORDER_ERROR';

export const GET_MANUFACTURER_ORDERS = 'manufacturerOrderEpic/GET_MANUFACTURER_ORDERS';
export const GET_MANUFACTURER_ORDERS_SUCCESS = 'manufacturerOrderEpic/GET_MANUFACTURER_ORDERS_SUCCESS';
export const GET_MANUFACTURER_ORDERS_ERROR = 'manufacturerOrderEpic/GET_MANUFACTURER_ORDERS_ERROR';
