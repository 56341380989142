import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import moment from "moment";

import {
  duplicateOrder,
  getOrder,
  getOrderSettings,
  getOrderTotalCost,
  setOrderDeadLine,
  deleteOrder,
  resetOrderData
} from 'store/actions/orders';
import {
  getDeliveryTypeList,
  getPaymentTypeList,
  getOrderStatusList,
  getInquiryStatusList,
  getCustomersList,
  getCoworkersList
} from 'store/actions/providers';
import { getLogs } from 'store/actions/logs';
import { getProgress } from 'store/actions/progress';
import { getStyleProgressDependsOnStatus } from 'utils/utils';

import {
  ProjectDetailsAllTabs,
  InquiryDetailsAllTabs
} from 'constants/constants';

import PersonIcon from '@material-ui/icons/Person';
import Button from 'components/CustomButtons/Button.jsx';
import BorderLinearProgress from 'components/BorderLinearProgress';
import DatePicker from 'components/CustomDatePicker';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Information from '../Information';
import Invoice from '../Invoice';
import Loader from 'components/Loader/Loader';
import Log from '../Log';
import Progress from '../Progress';
import ProjectTabs from '../../components/ProjectTabs/ProjectTabs';
import Timesheets from '../Timesheets';

import EditButton from 'assets/img/buttons/edit.svg';

import './style.scss';
import 'react-circular-progressbar/dist/styles.css';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import RemoveDialog from "../RemoveDialog";
import ConfirmDialog from "../ConfirmDialog";

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 0,
      isRemoveOrderOpen: false,
      isDuplicateDialogOpen: false,
      retrievedOrder: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id);
      this.props.getOrderSettings(this.props.match.params.id);
      this.props.getOrderTotalCost(this.props.match.params.id);
      this.props.getProgress(this.props.match.params.id);
      this.props.getLogs(this.props.match.params.id);
    }
    this.props.getCustomers();
    //this.props.getCoworkers();  // check if other need same treatment
    this.props.getDeliveryTypeList();
    this.props.getPaymentTypeList();
    this.props.getOrderStatusList();
    this.props.getInquiryStatusList()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isOrderDeleted !== this.props.isOrderDeleted) {
      this.props.history.push(this.props.order.type === 0 ? '/admin/orders' : '/admin/inquiries');
      this.props.resetOrderData();
    }
    if(prevProps.isOrderDuplicated !== this.props.isOrderDuplicated) {
      this.props.history.push(this.props.order.type === 0 ? '/admin/orders' : '/admin/inquiries');
      this.props.resetOrderData();
    }
    if (!this.state.retrievedOrder && this.props.order && this.props.order.manufacturer_id) {
      const manufacturerId = this.props.order.manufacturer_id;
      this.setState({
        retrievedOrder: true,
      }, () => {
        const userRoles = JSON.parse(
          localStorage.getItem("authUserRoles")
        );
        if (Boolean(userRoles.find(role => role.name === "admin"))) {
          this.props.getCoworkers(manufacturerId);  // get coworkers for id
        } else {
          this.props.getCoworkers();  // get own coworkers
        }
      });
    }
  }

  componentWillUnmount() {
    const { resetOrderData } = this.props;
    resetOrderData();
  }

  isOrderDetailsDataReady = () => {
    const { logs, progress, order, orderStatusList, inquiryStatusList, orderSettings } = this.props;
    return logs && progress && Object.keys(order).length !== 0 && orderStatusList.length !== 0
      && inquiryStatusList.length !== 0 && orderSettings && orderSettings.length;
  };

  onTabItemClick = id => {
    this.setState({
      selectedTabId: id
    });
  };

  handleToggleDuplicateDialog = () => {
    this.setState({
      isDuplicateDialogOpen: !this.state.isDuplicateDialogOpen,
    });
  };

  handleDuplicateOrder = () => {
    const {
      duplicateOrder,
      order,
      filters,
      searchTerms
    } = this.props;
    duplicateOrder({id: order.id, isOrder: order.type === 0, filters: filters, searchTerms: searchTerms, preventFetch: true});
    this.handleToggleDuplicateDialog()
  }

  handleToggleRemoveDialog = () => {
    this.setState({
      isRemoveOrderOpen: !this.state.isRemoveOrderOpen,
    });
  };

  handleRemoveOrder = () => {
    const {
      deleteOrder,
      order,
      filters,  // this is null, filters will not be applied on refresh
      searchTerms,
    } = this.props;
    deleteOrder(order.id, order.type === 0, filters, searchTerms, true);
    this.handleToggleRemoveDialog()
  };

  getOrderSelectedComponent = () => {
    const { order } = this.props;
    const { selectedTabId } = this.state;
    let orderSelectedComponent;
    switch (selectedTabId) {
      case 0:
        orderSelectedComponent = <Information isOrder={true} />;
        break;
      case 1:
        orderSelectedComponent =
          <Progress
            order={order}
          />;
        break;
      case 2:
        orderSelectedComponent = <Timesheets order={order} />;
        break;
      case 3:
        orderSelectedComponent = <Invoice match={this.props.match} order={order} />;
        break;
      case 4:
        orderSelectedComponent =
          <Log
            orderId={order.id}
          />;
        break;
      default:
        return null;
    }
    return orderSelectedComponent;
  };

  getInquirySelectedComponent = () => {
    const { order } = this.props;
    const { selectedTabId } = this.state;
    let inquirySelectedComponent;
    switch (selectedTabId) {
      case 0:
        inquirySelectedComponent = <Information canEdit={order.can_edit} isOrder={true} />;
        break;
      case 1:
        inquirySelectedComponent =
          <Progress
            order={order}
          />;
        break;
      case 2:
        inquirySelectedComponent =
          <Invoice match={this.props.match} order={order} />;
        break;
      case 3:
        inquirySelectedComponent =
          <Log />;
        break;
      default:
        return null;
    }
    return inquirySelectedComponent;
  };

  render() {
    const {
      classes,
      order,
      coworkers,
      orderStatusList,
      inquiryStatusList,
      deleteOrder,
      t
    } = this.props;
    const statusList = order.type === 0 ? orderStatusList : inquiryStatusList;
    const {
      selectedTabId,
      isRemoveOrderOpen,
      isDuplicateDialogOpen
    } = this.state;
    const isDataReady = this.isOrderDetailsDataReady();
    const styleProgressData = getStyleProgressDependsOnStatus(
      order.status,
      order.type === 0,
    );
    const orderStatus =
      order.status !== null && order.status >= 0 && statusList.length !== 0
        ? statusList.find(item => item.id === order.status.toString()).value
        : '';
    const coworker =
      order.coworker_id && coworkers.length !== 0
        ? coworkers.find(item => item.id === order.coworker_id.toString())
        : null;
    const coworkerImg = null; // in future this will be available
    let selectedComponent =
      order.type === 0
        ? this.getOrderSelectedComponent()
        : this.getInquirySelectedComponent();
    return (
      <div className="ikt-ap_order-details-container">
        {isDataReady ? (
          <GridContainer className="ikt-app_order-details-content-container">
            <GridItem className="ikt-ap_order-details-top-wrapper" xs={12}>
              <GridContainer
                className="ikt-ap_order-details-top-container"
                direction="row"
                justify="space-between"
              >
                <GridItem className="ikt-ap_order-details-top-left">
                  <ProjectTabs
                    tabs={
                      order.type === 0
                        ? ProjectDetailsAllTabs
                        : InquiryDetailsAllTabs
                    }
                    selectedTab={selectedTabId}
                    onTabItemClick={this.onTabItemClick}
                  />
                </GridItem>
                <GridItem className="ikt-ap_order-details-top-right">
                  {
                    order.can_delete && order.origin_type === 0 &&
                    <Button
                      className="ikt-ap_order-details-btn delete-order-btn"
                      onClick={() => this.handleToggleRemoveDialog()}
                    >
                      {order.type === 0 ? t('delete_order').toUpperCase() : t('delete_inquiry').toUpperCase() }
                    </Button>
                  }
                  <Button
                    className="ikt-ap_order-details-btn duplicate-order-btn"
                    onClick={() => this.handleToggleDuplicateDialog()}
                  >
                    {order.type === 0 ? t('duplicate_order').toUpperCase() : t('duplicate_inquiry').toUpperCase() }
                  </Button>
                  { order.can_edit && 
                    <Link to={order.type === 0 ? `/admin/orders/edit-order/${order.id}` : `/admin/inquiries/edit-inquiry/${order.id}`}>
                      <Button className="ikt-ap_order-details-btn">{ (order.type === 0 ? t('edit_order') : t('edit_inquiry')).toUpperCase() }</Button>
                    </Link>
                  }
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem className="order-info-progress-bar-container" xs={12}>
              <GridItem className="progress-bar-content" xs={4}>
                <span>{ t('progress') }</span>
                <BorderLinearProgress
                  className={classes.margin}
                  variant="determinate"
                  value={styleProgressData.progress}
                />
              </GridItem>
              <GridItem className="information-content" xs={5}>
                <GridItem className="info-item _info-status">
                  <span className="info-title">{ t('status') }</span>
                  {orderStatus && (
                    <div className={`order-status ${styleProgressData.style}`}>
                      <span>{orderStatus}</span>
                    </div>
                  )}
                </GridItem>
                <GridItem className="info-item" xs={2}>
                  <span className="info-title">{ t('deadline') }</span>
                  {order && (
                    <span className="info-name">
                      {moment(order.due_date).format('YYYY/MM/DD')}
                    </span>
                  )}
                </GridItem>
                <GridItem className="info-item" xs={1}>
                  <DatePicker
                    className={'order-details-date-picker'}
                    handleChange={date =>
                      this.props.setDate({
                        id: order.id,
                        value: moment(date).format('YYYY-MM-DD')
                      })
                    }
                    selectedDate={order.due_date}
                    icon={EditButton}
                  />
                </GridItem>
                <GridItem className="info-item">
                  <span className="info-title">{ order.type === 0 ? t('order_manager') : t('inquiry_manager') }</span>
                  <span className="info-name">
                    { coworker ? coworker.value : "none" }
                  </span>
                </GridItem>
                { coworker && <GridItem className="info-item item-order-manager-img" xs={1}>
                  { coworkerImg ? <img alt="order-manager-img" className="order-manager-icon" src={coworkerImg} /> : <PersonIcon className="order-manager-icon" /> }
                  </GridItem> }
              </GridItem>
            </GridItem>
            <GridItem className="order-info-content-container" xs={12}>{selectedComponent}</GridItem>
            { isRemoveOrderOpen && (
              <RemoveDialog
                isOpen={isRemoveOrderOpen}
                title={order.type === 0 ? t('remove_order') : t('remove_inquiry')}
                text={order.type === 0 ? t('remove_order_confirmation') : t('remove_inquiry_confirmation')}
                onClose={this.handleToggleRemoveDialog}
                onRemove={this.handleRemoveOrder}
              />
            )}
            {isDuplicateDialogOpen && (
              <ConfirmDialog
                isOpen={isDuplicateDialogOpen}
                title={order.type === 0 ? t('duplicate_order') : t('duplicate_inquiry')}
                text={order.type === 0 ? t('duplicate_order_confirmation') : t('duplicate_inquiry_confirmation')}
                onClose={this.handleToggleDuplicateDialog}
                onConfirm={this.handleDuplicateOrder}
              />
            )}
          </GridContainer>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

OrderDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    order: state.orders.currentOrder,
    orderSettings: state.orders.orderSettings,
    isOrderDeleted: state.orders.isOrderDeleted,
    isOrderDuplicated: state.orders.isOrderDuplicated,
    coworkers: state.providers.coworkersList,
    orderStatusList: state.providers.orderStatusList,
    inquiryStatusList: state.providers.inquiryStatusList,
    logs: state.logs.logs,
    progress: state.progress.progress
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrder: id => dispatch(getOrder(id)),
    getOrderSettings: id => dispatch(getOrderSettings(id)),
    getOrderTotalCost: id => dispatch(getOrderTotalCost(id)),
    getCustomers: () => dispatch(getCustomersList()),
    getCoworkers: (manufacturerId) => dispatch(getCoworkersList(manufacturerId)),
    getLogs: (id, page) => dispatch(getLogs(id, page)),
    getProgress: id => dispatch(getProgress(id)),
    getDeliveryTypeList: () => dispatch(getDeliveryTypeList()),
    getPaymentTypeList: () => dispatch(getPaymentTypeList()),
    getOrderStatusList: () => dispatch(getOrderStatusList()),
    getInquiryStatusList: () => dispatch(getInquiryStatusList()),
    setDate: date => dispatch(setOrderDeadLine(date)),
    deleteOrder: (id, isOrder, filters, searchTerms, preventFetch) => dispatch(deleteOrder(id, isOrder, filters, searchTerms, preventFetch)),
    duplicateOrder: (data) => dispatch(duplicateOrder(data)),
    resetOrderData: () => dispatch(resetOrderData())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(OrderDetails)));
