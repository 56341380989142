import BaseAPIService from './Base';

export default class ManufacturerOrderAPIService extends BaseAPIService {
  createManufacturerOrder = async (data) => {
    return this.request('api/manufacturers/1/orders', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  updateManufacturerOrder = async (id, data) => {
    return this.request(`api/manufacturers/orders/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  getManufacturerOrder = async (id) => {
    return this.request(`api/manufacturers/orders/${id}`).then((data) => {
      return data;
    });
  };

  deleteManufacturerOrder = async (id) => {
    return this.request(`api/manufacturers/orders/${id}`, {
      method: 'DELETE',
    });
  };

  getManufacturerOrders = async (id) => {
    return this.request(`api/manufacturers/${id}/orders`).then((data) => {
      return data;
    });
  };
}
