import React from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {config} from 'Config';
import {getCustomer} from 'store/actions/customers';

import EyeButton from 'assets/img/buttons/eye.svg';
import DownloadButton from 'assets/img/buttons/download.svg';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import IconButton from "@material-ui/core/IconButton";
import Loader from 'components/Loader/Loader';
import CostCard from '../CostCard';
import RTViewer from '../../components/RTViewer/RTViewer';

import './style.scss';

class OrderInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewFile: false,
      url: ''
    };
  }

  componentDidMount() {
    const {order, getCustomer} = this.props;
    if (order.customer_id)
      getCustomer(order.customer_id);
  }

  isDataReady = () => {
    const {order, customer, paymentTypeList, deliveryTypeList} = this.props;
    return Object.keys(order).length !== 0  && paymentTypeList.length !== 0 && deliveryTypeList.length !== 0
  };

  renderSupportFile = (file) => {
    return(
      <div
        className="order-support-file"
        key={file.file.id}
      >
        <div className="file-info">
          <span className="file-name-data">{file.name.replace(/(.{40})..+/, "$1…")}</span>
          {/*<span className="file-size">{`${file.size / 1000} KB`}</span>*/}
        </div>
        <div className="file-delete-btn">
          <a href={`${config.apiBaseUrl}${file.file.url}`} target="_blank" download>
            <IconButton aria-label="close" className="close-btn">
              <img
                alt="information-download-btn"
                src={EyeButton}
              />
            </IconButton>
          </a>
        </div>
      </div>
    )
  };

  render() {
    const {
      order,
      customer,
      paymentTypeList,
      deliveryTypeList,
      t
    } = this.props;
    const {isViewFile, url} = this.state;
    const paymentType = order.payment_type !== null && order.payment_type !== undefined && paymentTypeList.length !== 0 ? paymentTypeList.find(item => item.id === order.payment_type.toString()).value : '';
    const deliveryType = order.delivery_type !== null && order.delivery_type !== undefined && deliveryTypeList.length !== 0 ? deliveryTypeList.find(item => item.id === order.delivery_type.toString()).value : '';
    return (
      <GridContainer className="project-information-wrapper-container">
        {
          this.isDataReady()
            ? ( <>
                  <GridItem className="project-information-left-container" xs={8}>
                    <GridItem className="project-details-card">
                      <GridItem className="project-details-card-title" xs={12}>
                        <span>{order.type === 0 ? t('order_details') : t('inquiry_details')}</span>
                      </GridItem>
                      <GridItem className="project-details-content" xs={12}>
                        <GridItem className="project-details-content-item" xs={12}>
                          <GridItem xs={5}>
                            <span>{ order.type === 0 ? t('order_id') : t('inquiry_id') }</span>
                          </GridItem>
                          <GridItem xs={5}>
                            <span className="item-info">{order && order.order_no}</span>
                          </GridItem>
                        </GridItem>
                        <GridItem className="project-details-content-item" xs={12}>
                          <GridItem xs={5}>
                            <span>{ t('name') }:</span>
                          </GridItem>
                          <GridItem xs={5}>
                            <span className="item-info">{order.name}</span>
                          </GridItem>
                        </GridItem>
                        <GridItem className="project-details-content-item" xs={12}>
                          <GridItem xs={5}>
                            <span>{ t('type') }:</span>
                          </GridItem>
                          <GridItem xs={5}>
                            <span className="item-info">{order.origin_type === 0 ? 'Manual' : 'From Webshop'}</span>
                          </GridItem>
                        </GridItem>
                        <GridItem className="project-details-content-item" xs={12}>
                          <GridItem xs={5}>
                            <span>{ t('payment_type') }:</span>
                          </GridItem>
                          <GridItem xs={5}>
                            <span className="item-info">{paymentType}</span>
                          </GridItem>
                        </GridItem>
                        <GridItem className="project-details-content-item" xs={12}>
                          <GridItem xs={5}>
                            <span>{ t('delivery_type') }:</span>
                          </GridItem>
                          <GridItem xs={5}>
                            <span className="item-info">{deliveryType}</span>
                          </GridItem>
                        </GridItem>
                        <GridItem xs={5}>
                          <span>{ t('notes') }:</span>
                        </GridItem>
                        <GridItem xs={12} className="rt-notes-container">
                          <RTViewer
                            value={order.description}
                          />
                          {/*<span className="item-info">
                            {order.description}
                          </span>*/}
                        </GridItem>
                      </GridItem>
                    </GridItem>
                    <GridItem className="project-details-card">
                      <GridItem className="project-details-card-title" xs={12}>
                        <span>{ t('customer_contact_details') }</span>
                      </GridItem>
                      { order.customer ?
                        <GridItem className="project-details-content" xs={12}>
                          <GridItem className="project-details-content-item" xs={12}>
                            <GridItem xs={5}>
                              <span>{ t('full_name') }:</span>
                            </GridItem>
                            <GridItem xs={5}>
                              <span className="item-info">{`${order.customer.first_name} ${order.customer.last_name}`}</span>
                            </GridItem>
                          </GridItem>
                          <GridItem className="project-details-content-item" xs={12}>
                            <GridItem xs={5}>
                              <span>{ t('phone') }:</span>
                            </GridItem>
                            <GridItem xs={5}>
                              <span className="item-info">{order.customer.phone_mobile}</span>
                            </GridItem>
                          </GridItem>
                          <GridItem className="project-details-content-item" xs={12}>
                            <GridItem xs={5}>
                              <span>{ t('delivery_address') }:</span>
                            </GridItem>
                            <GridItem className="content-row-item" xs={5}>
                              <span className="item-info">{order.customer.address}</span>
                            </GridItem>
                          </GridItem>
                          <GridItem className="project-details-content-item" xs={12}>
                            <GridItem xs={5}>
                              <span>{ t('billing_address') }:</span>
                            </GridItem>
                            <GridItem xs={5}>
                              <span className="item-info">{ t('same_as_shipping_address') }</span>
                            </GridItem>
                          </GridItem>
                          <GridItem className="project-details-content-item item-margin" xs={12}>
                            <GridItem xs={5}>
                              <span>{ t('payment_method') }:</span>
                            </GridItem>
                            <GridItem className="content-row-item" xs={5}>
                              <span className="item-info">{ t('cash') }</span>
                            </GridItem>
                          </GridItem>
                        </GridItem>
                        :
                        <GridItem className="project-details-content" xs={12}>
                          <GridItem className="project-details-content-item" xs={12}>
                            <GridItem xs={12}>
                              <span>{ t('no_customer_selected') }</span>
                            </GridItem>
                          </GridItem>
                        </GridItem>
                      }
                    </GridItem>
                    {
                      order.support_files && order.support_files.length !== 0 &&
                        <GridItem className="project-details-card">
                          <GridItem className="project-details-card-title" xs={12}>
                            <span>{ t('attached_documents') }</span>
                          </GridItem>
                          <GridItem className="project-details-content" xs={12}>
                            {
                              order.support_files.map(item => this.renderSupportFile(item))
                            }
                          </GridItem>
                        </GridItem>
                    }
                  </GridItem>
                  <GridItem className="project-information-right-container" xs={4}>
                    <CostCard canEdit={this.props.canEdit} />
                  </GridItem>
                </>
              )
            : (
                <div className="ikt-ap_loader">
                  <Loader />
                </div>
            )
        }
      </GridContainer>
    )
  }
}

const mapStateToProp = state => {
  return {
    order: state.orders.currentOrder,
    orderTotalCost: state.orders.orderTotalCost,
    customers: state.customers.customers,
    customer: state.customers.customer,
    paymentTypeList: state.providers.paymentTypeList,
    deliveryTypeList: state.providers.deliveryTypeList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomer: (id) => dispatch(getCustomer(id)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(OrderInformation));
