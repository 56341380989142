import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

// handles anchoring and display
class SidebarPopper extends React.Component {
  
  constructor(props) {
    super(props);
  }
 
  render() {
    let classNames = "sidebar-popper-container";
    if(!this.props.open)
      classNames += " hidden";

    const stylePosition = {};

    if(this.props.anchorEl && this.props.sidebar) {
      const anchorRect = this.props.anchorEl.getBoundingClientRect();
      const sidebarRect = this.props.sidebar.getBoundingClientRect();
      stylePosition.top = Math.round(anchorRect.top + anchorRect.height / 2);
      stylePosition.left = sidebarRect.right + 10; // 10 is for element arrow
    }
    
    return (
      <div className={classNames} style={stylePosition} ref={this.props.inputRef}>
        { this.props.children }
      </div>
    );
  }
}

SidebarPopper.defaultProps = {
  open: false
};

SidebarPopper.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  sidebar: PropTypes.object,
};

export default React.forwardRef((props, ref) => {
  return <SidebarPopper {...props} inputRef={ref} />;
});
