import {ofType} from 'redux-observable';
import {from, of, concat} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {timerServiceApi} from 'services/API';
import {
  startTimerSuccess,
  startTimerFailure,
  pauseTimerSuccess,
  pauseTimerFailure,
  resumeTimerSuccess,
  resumeTimerFailure,
  updateTimerNoteSuccess,
  updateTimerNoteFailure,
  finalizeTimerSuccess,
  finalizeTimerFailure,
  deleteTimerSuccess,
  deleteTimerFailure,
  getCurrentTimerSuccess,
  getCurrentTimerFailure,
} from 'store/actions/timer';
import {
  START_TIMER,
  PAUSE_TIMER,
  RESUME_TIMER,
  UPDATE_TIMER_NOTE,
  FINALIZE_TIMER,
  DELETE_TIMER,
  GET_CURRENT_TIMER
} from 'store/actionTypes/timerActionTypes';
import {
  getHoursLogged,
} from 'store/actions/timeSheetsProviders';
import {
  filterCoworkerTimeSheets,
} from 'store/actions/coworkerTimeSheet';
import NotificationService from '../services/NotificationService';

export const startTimerEpic = (actions$) => actions$.pipe(
  ofType(START_TIMER),
  switchMap((action) => from(timerServiceApi.startTimer(action.payload)).pipe(
    switchMap((response) => {
      return of(startTimerSuccess(response.data));
    }),
    catchError(err => {
      return of(startTimerFailure(err && err.responseBody ? err.responseBody : err));
    })
  ))
);

export const pauseTimerEpic = (actions$) => actions$.pipe(
  ofType(PAUSE_TIMER),
  switchMap(() => from(timerServiceApi.pauseTimer()).pipe(
    switchMap((response) => {
      return of(pauseTimerSuccess(response.data));
    }),
    catchError(err => {
      return of(pauseTimerFailure(err && err.responseBody ? err.responseBody : err));
    })
  ))
);

export const resumeTimerEpic = (actions$) => actions$.pipe(
  ofType(RESUME_TIMER),
  switchMap(() => from(timerServiceApi.resumeTimer()).pipe(
    switchMap((response) => {
      return of(resumeTimerSuccess(response.data));
    }),
    catchError(err => {
      return of(resumeTimerFailure(err && err.responseBody ? err.responseBody : err));
    })
  ))
);

export const updateTimerNoteEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_TIMER_NOTE),
  switchMap((action) => from(timerServiceApi.updateTimerNote(action.payload)).pipe(
    switchMap((response) => {
      return of(updateTimerNoteSuccess());
    }),
    catchError(err => {
      return of(updateTimerNoteFailure(err && err.responseBody ? err.responseBody : err));
    })
  ))
);

export const finalizeTimerEpic = (actions$, state$) => actions$.pipe(
  ofType(FINALIZE_TIMER),
  switchMap((action) => from(timerServiceApi.finalizeTimer(action.payload)).pipe(
    switchMap((response) => {
      NotificationService.success('Time entry saved successfully');
      const statePartTimesheetProviders = state$.value.timeSheetsProviders;
      const statePartTimesheets = state$.value.timeSheets;
      if (statePartTimesheets.filterRequest && statePartTimesheetProviders.hoursLoggedRequest) {
        return concat(
          of(filterCoworkerTimeSheets(statePartTimesheets.filterRequest.filters, statePartTimesheets.filterRequest.sortBy, statePartTimesheets.filterRequest.sortByOrder, statePartTimesheets.filterRequest.searchTerms)),
          of(getHoursLogged(statePartTimesheetProviders.hoursLoggedRequest.dateFrom, statePartTimesheetProviders.hoursLoggedRequest.dateTo)),
          of(finalizeTimerSuccess()),
        );
      } else if (statePartTimesheets.filterRequest) {
        return concat(
          of(filterCoworkerTimeSheets(statePartTimesheets.filterRequest.filters, statePartTimesheets.filterRequest.sortBy, statePartTimesheets.filterRequest.sortByOrder, statePartTimesheets.filterRequest.searchTerms)),
          of(finalizeTimerSuccess()),
        );
      } else if (statePartTimesheetProviders.hoursLoggedRequest) {
        return concat(
          of(getHoursLogged(statePartTimesheetProviders.hoursLoggedRequest.dateFrom, statePartTimesheetProviders.hoursLoggedRequest.dateTo)),
          of(finalizeTimerSuccess()),
        );
      } else {
        return of(finalizeTimerSuccess());
      } 
    }),
    catchError(err => {
      return of(finalizeTimerFailure(err && err.responseBody ? err.responseBody : err));
    })
  ))
);

export const deleteTimerEpic = (actions$) => actions$.pipe(
  ofType(DELETE_TIMER),
  switchMap(() => from(timerServiceApi.deleteTimer()).pipe(
    switchMap(() => {
      return of(deleteTimerSuccess());
    }),
    catchError(err => {
      return of(deleteTimerFailure(err && err.responseBody ? err.responseBody : err));
    })
  ))
);

export const getCurrentTimerEpic = (actions$) => actions$.pipe(
  ofType(GET_CURRENT_TIMER),
  switchMap(() => from(timerServiceApi.getCurrentTimer()).pipe(
    switchMap((response) => {
      return of(getCurrentTimerSuccess(response.data));
    }),
    catchError(err => {
      return of(getCurrentTimerFailure(err && err.responseBody ? err.responseBody : err));
    })
  ))
);
