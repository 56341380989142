export const GET_MATERIALS = 'materialsEpic/GET_MATERIALS';
export const GET_MATERIALS_SUCCESS = 'materialsEpic/GET_MATERIALS_SUCCESS';
export const GET_MATERIALS_ERROR = 'materialsEpic/GET_MATERIALS_ERROR';

export const GET_MATERIALS_FOR_CATEGORY = 'materialsEpic/GET_MATERIALS_FOR_CATEGORY';
export const GET_MATERIALS_FOR_CATEGORY_SUCCESS = 'materialsEpic/GET_MATERIALS_FOR_CATEGORY_SUCCESS';
export const GET_MATERIALS_FOR_CATEGORY_ERROR = 'materialsEpic/GET_MATERIALS_FOR_CATEGORY_ERROR';

export const GET_MATERIAL = 'materialsEpic/GET_MATERIAL';
export const GET_MATERIAL_SUCCESS = 'materialsEpic/GET_MATERIAL_SUCCESS';
export const GET_MATERIAL_ERROR = 'materialsEpic/GET_MATERIAL_ERROR';

export const GET_MATERIALS_IN_BUNDLES =
  'materialsEpic/GET_MATERIALS_IN_BUNDLES';
export const GET_MATERIALS_IN_BUNDLE_SUCCESS =
  'materialsEpic/GET_MATERIALS_IN_BUNDLE_SUCCESS';
export const GET_MATERIALS_IN_BUNDLE_ERROR =
  'materialsEpic/GET_MATERIALS_IN_BUNDLE_ERROR';
export const GET_MATERIALS_IN_BUNDLES_FINISH =
  'materialsEpic/GET_MATERIALS_IN_BUNDLES_FINISH';

export const ADD_MATERIAL = 'materialsEpic/ADD_MATERIAL';
export const ADD_MATERIAL_SUCCESS = 'materialsEpic/ADD_MATERIAL_SUCCESS';
export const ADD_MATERIAL_ERROR = 'materialsEpic/ADD_MATERIAL_ERROR';

export const DUPLICATE_MATERIAL = 'materialsEpic/DUPLICATE_MATERIAL';
export const DUPLICATE_MATERIAL_SUCCESS = 'materialsEpic/DUPLICATE_MATERIAL_SUCCESS';
export const DUPLICATE_MATERIAL_ERROR = 'materialsEpic/DUPLICATE_MATERIAL_ERROR';

export const ADD_COMPOUND_MATERIAL = 'materialsEpic/ADD_COMPOUND_MATERIAL';
export const ADD_COMPOUND_MATERIAL_SUCCESS =
  'materialsEpic/ADD_COMPOUND_MATERIAL_SUCCESS';
export const ADD_COMPOUND_MATERIAL_ERROR =
  'materialsEpic/ADD_COMPOUND_MATERIAL_ERROR';

export const UPDATE_COMPOUND_MATERIAL =
  'materialsEpic/UPDATE_COMPOUND_MATERIAL';

  export const UPDATE_COMPOUND_MATERIAL_BASE =
  'materialsEpic/UPDATE_COMPOUND_MATERIAL_BASE';
export const UPDATE_COMPOUND_MATERIAL_BASE_ERROR =
  'materialsEpic/UPDATE_COMPOUND_MATERIAL_BASE_ERROR';
export const UPDATE_COMPOUND_MATERIAL_BASE_SUCCESS =
  'materialsEpic/UPDATE_COMPOUND_MATERIAL_BASE_SUCCESS';


export const UPDATE_MATERIAL = 'materialsEpic/UPDATE_MATERIAL';
export const UPDATE_MATERIAL_SUCCESS = 'materialsEpic/UPDATE_MATERIAL_SUCCESS';
export const UPDATE_MATERIAL_ERROR = 'materialsEpic/UPDATE_MATERIAL_ERROR';

export const ADD_MATERIAL_REQUEST = 'materialsEpic/ADD_MATERIAL_REQUEST';
export const ADD_MATERIAL_REQUEST_SUCCESS =
  'materialsEpic/ADD_MATERIAL_REQUEST_SUCCESS';
export const ADD_MATERIAL_REQUEST_ERROR =
  'materialsEpic/ADD_MATERIAL_REQUEST_ERROR';

export const UPDATE_MATERIAL_REQUEST = 'materialsEpic/UPDATE_MATERIAL_REQUEST';
export const UPDATE_MATERIAL_REQUEST_SUCCESS =
  'materialsEpic/UPDATE_MATERIAL_REQUEST_SUCCESS';
export const UPDATE_MATERIAL_REQUEST_ERROR =
  'materialsEpic/UPDATE_MATERIAL_REQUEST_ERROR';

export const UPDATE_MATERIAL_CATEGORY_VALUES = 'materialsEpic/UPDATE_MATERIAL_CATEGORY_VALUES';
export const UPDATE_MATERIAL_CATEGORY_VALUES_SUCCESS =
  'materialsEpic/UPDATE_MATERIAL_CATEGORY_VALUES_SUCCESS';
export const UPDATE_MATERIAL_CATEGORY_VALUES_ERROR =
  'materialsEpic/UPDATE_MATERIAL_CATEGORY_VALUES_ERROR';

export const UPDATE_MULTIPLE_MATERIALS_VALUES = 'materialsEpic/UPDATE_MULTIPLE_MATERIALS_VALUES';
export const UPDATE_MULTIPLE_MATERIALS_VALUES_SUCCESS =
  'materialsEpic/UPDATE_MULTIPLE_MATERIALS_VALUES_SUCCESS';
export const UPDATE_MULTIPLE_MATERIALS_VALUES_ERROR =
  'materialsEpic/UPDATE_MULTIPLE_MATERIALS_VALUES_ERROR';

export const UPDATE_MULTIPLE_MATERIALS_PRICE = 'materialsEpic/UPDATE_MULTIPLE_MATERIALS_PRICE';
export const UPDATE_MULTIPLE_MATERIALS_PRICE_SUCCESS =
  'materialsEpic/UPDATE_MULTIPLE_MATERIALS_PRICE_SUCCESS';
export const UPDATE_MULTIPLE_MATERIALS_PRICE_ERROR =
  'materialsEpic/UPDATE_MULTIPLE_MATERIALS_PRICE_ERROR';

export const APPROVE_MATERIAL = 'materialsEpic/APPROVE_MATERIAL';
export const APPROVE_MATERIAL_SUCCESS =
  'materialsEpic/APPROVE_MATERIAL_SUCCESS';
export const APPROVE_MATERIAL_ERROR = 'materialsEpic/APPROVE_MATERIAL_ERROR';

export const SET_MATERIAL_AVAILABILITY =
  'materialsEpic/SET_MATERIAL_AVAILABILITY';

export const START_MATERIAL_FILE_IMPORT =
  'materialsEpic/START_MATERIAL_FILE_IMPORT';
export const START_MATERIAL_FILE_IMPORT_SUCCESS =
  'materialsEpic/START_MATERIAL_FILE_IMPORT_SUCCESS';
export const START_MATERIAL_FILE_IMPORT_FAILURE =
  'materialsEpic/START_MATERIAL_FILE_IMPORT_FAILURE';

export const DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED =
  'materialsEpic/DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED';
export const DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_SUCCESS =
  'materialsEpic/DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_SUCCESS';
export const DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_ERROR =
  'materialsEpic/DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_ERROR';

export const REMOVE_MATERIAL = 'materialsEpic/REMOVE_MATERIAL';
export const REMOVE_MATERIAL_SUCCESS = 'materialsEpic/REMOVE_MATERIAL_SUCCESS';
export const REMOVE_MATERIAL_ERROR = 'materialsEpic/REMOVE_MATERIAL_ERROR';

export const RESTORE_MATERIAL = 'materialsEpic/RESTORE_MATERIAL';
export const RESTORE_MATERIAL_SUCCESS = 'materialsEpic/RESTORE_MATERIAL_SUCCESS';
export const RESTORE_MATERIAL_ERROR = 'materialsEpic/RESTORE_MATERIAL_ERROR';

export const RESTORE_MATERIALS = "materialsEpic/RESTORE_MATERIALS";
export const RESTORE_MATERIALS_SINGLE_SUCCESS = "materialsEpic/RESTORE_MATERIALS_SINGLE_SUCCESS";
export const RESTORE_MATERIALS_SINGLE_FAILURE = "materialsEpic/RESTORE_MATERIALS_SINGLE_FAILURE";
export const RESTORE_MATERIALS_FINISH = "materialsEpic/RESTORE_MATERIALS_SINGLE_FINISH";

export const REMOVE_MATERIALS = "materialsEpic/REMOVE_MATERIALS";
export const REMOVE_MATERIALS_SINGLE_SUCCESS = "materialsEpic/REMOVE_MATERIALS_SINGLE_SUCCESS";
export const REMOVE_MATERIALS_SINGLE_FAILURE = "materialsEpic/REMOVE_MATERIALS_SINGLE_FAILURE";
export const REMOVE_MATERIALS_FINISH = "materialsEpic/REMOVE_MATERIALS_SINGLE_FINISH";

export const REMOVE_COMPOUND_MATERIAL =
  'materialsEpic/REMOVE_COMPOUND_MATERIAL';
export const REMOVE_COMPOUND_MATERIAL_SUCCESS =
  'materialsEpic/REMOVE_COMPOUND_MATERIAL_SUCCESS';
export const REMOVE_COMPOUND_MATERIAL_ERROR =
  'materialsEpic/REMOVE_COMPOUND_MATERIAL_ERROR';

export const RESET_MATERIAL_DATA = 'materialEpic/RESET_MATERIAL_DATA';

export const RESET_MATERIAL_DUPLICATE_DATA = 'materialEpic/RESET_MATERIAL_DUPLICATE_DATA';

