import BaseAPIService from './Base';

export default class MaterialProvidersAPIService extends BaseAPIService {
  getAllMaterials = async (term, metric) => {
    return this.request(`api/manufacturers/provider/own/materials/__all__?${term ? `term=${term}` : ''}${metric !== null && metric !== undefined && metric !== '' ? `&metric=${metric}` : ''}`).then((data) => {
      return data;
    });
  };

  getMainMaterialsList = async (term) => {
    return this.request(`api/manufacturers/provider/own/materials/main_material${term ? `?term=${term}` : ''}`).then((data) => {
      return data;
    });
  };

  getPositionMaterialsList = async (term) => {
    return this.request(`api/position-materials${term ? `?term=${term}` : ''}`).then((data) => {
      return data;
    });
  };

  getFittingsMaterialsList = async (term) => {
    return this.request(`api/manufacturers/provider/own/materials-fittings${term ? `?term=${term}` : ''}`).then((data) => {
      return data;
    });
  };

  getEdgeMaterialsList = async (term) => {
    return this.request(`api/manufacturers/provider/own/materials/edge_material${term ? `?term=${term}` : ''}`).then((data) => {
      return data;
    });
  };

  getCoveringMaterialsList = async (term) => {
    return this.request(`api/manufacturers/provider/own/materials/covering_material${term ? `?term=${term}` : ''}`).then((data) => {
      return data;
    });
  };

  getSurfaceMaterialsList = async (term) => {
    return this.request(`api/manufacturers/provider/own/materials/surface_material${term ? `?term=${term}` : ''}`).then((data) => {
      return data;
    });
  }

  getSelectedMaterials = async (ids) => {
    const queryPart = ids.map(el => (`selected_ids[]=${el}`)).join('&');
    return this.request(`api/manufacturers/provider/own/materials/only-selected?${queryPart}`).then((data) => {
      return data;
    });
  }
}
