import React from 'react';
import { withTranslation } from 'react-i18next';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import ClassRow from './ClassRow';

import RemoveDialog from 'views/RemoveDialog';
import Button from 'components/CustomButtons/Button';

import './style.scss';

class Allowance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemoveClassOpen: false,
      selectedIndexForRemoval: null,
    };
  }

  handleDataChange = (index, dataObj) => {
    const { data, onDataChange } = this.props;
    const newData = [...data];
    newData[index] = dataObj;
    onDataChange(newData);
  }

  handleAddClass = () => {
    const { data, onDataChange } = this.props;
    const newData = [...data];
    newData.push({ cad_class_name: '', left: '', right: '', top: '', bottom: '', cad_import: false});
    onDataChange(newData);
  }

  handleRemoveClassClick = (index) => {
    this.setState({
      isRemoveClassOpen: true,
      selectedIndexForRemoval: index,
    });
  }
  
  handleRemoveClass = () => {
    const { data, onDataChange } = this.props;
    const { selectedIndexForRemoval } = this.state;
    const newData = [...data];
    newData.splice(selectedIndexForRemoval, 1);
    onDataChange(newData);
    this.handleRemoveDialogClose();
  }

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveClassOpen: false,
      selectedIndexForRemoval: null,
    });
  }

  matchErrors = (index) => {
    const { errors } = this.props;
    if (errors) {
      const errorsArray = Object.keys(errors);
      const needle = `${index}.`;
      const result = {};
      errorsArray.forEach(el => {
        if (el.indexOf(needle) === 0) {
          const newKey = el.substring(needle.length);
          result[newKey] = errors[el];
        }
      });
      return result;
    } else {
      return null;
    }
  }

  render() {
    const {
      data,
      errors,
      t
    } = this.props;
    const {
      isRemoveClassOpen
    } = this.state;
    return (
      <div className="position-content-allowance">
        <div className={`position-info ${true ? 'bottom-borders' : ''}`}>
          <GridItem className="position-info-container">
            <GridItem className="position-info-title">
              <div className="position-title">{ t('allowance') }</div>
            </GridItem>
          </GridItem>
          <div className="position-title-button">
            <Button className="add-btn" onClick={this.handleAddClass}>{ t('add_class').toUpperCase() }</Button>
          </div>
        </div>
        <div className="position-info-content">
          {
            data.length !== 0 &&
              <GridContainer className="position-tasks">
                <GridContainer className="position-info-header">
                  <GridItem className="alc-class"><span>{ t('cad_class') }</span></GridItem>
                  <GridItem className="alc-left"><span>{ t('left') }</span></GridItem>
                  <GridItem className="alc-right"><span>{ t('right') }</span></GridItem>
                  <GridItem className="alc-top"><span>{ t('top') }</span></GridItem>
                  <GridItem className="alc-below"><span>{ t('below') }</span></GridItem>
                </GridContainer>
                {data.map((obj, index) => (
                  <ClassRow
                    key={index}
                    data={obj}
                    errors={this.matchErrors(index)}
                    onDataChange={(data) => this.handleDataChange(index, data)}
                    onRemove={() => this.handleRemoveClassClick(index)}
                  />
                ))}
              </GridContainer>
          }
        </div>
        {isRemoveClassOpen && (
          <RemoveDialog
            isOpen={isRemoveClassOpen}
            title={t('remove_cad_class')}
            text={t('remove_cad_class_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleRemoveClass}
          />
        )}
      </div>
    )
  }
}

export default withTranslation()(Allowance);
