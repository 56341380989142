import React from 'react';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

import GridItem from 'components/Grid/GridItem';
import ConfirmDialog from '../ConfirmDialog';

import './style.scss';
import IconButton from "@material-ui/core/IconButton";
import PlusButton from "../../assets/img/buttons/plus-circle.svg";
import MinusButton from "../../assets/img/buttons/minus-circle.svg";
import EyeButton from "../../assets/img/buttons/eye.svg";
import {getClientInquiries} from "../../store/actions/orders";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {getStyleProgressDependsOnStatus} from "../../utils/utils";
import {getInquiryStatusList} from "../../store/actions/providers";
import {Link} from "react-router-dom";

class CustomersInquiries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialogOpen: false,
      removeAction: false,
      selectedInquiry: ''
    };
  }

  componentDidMount() {
    this.getInquiriesData();
    this.props.getStatusList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.clientId !== this.props.clientId) {
      this.getInquiriesData();
    }
  }

  getInquiriesData = () => {
    const {getInquiries} = this.props;
    let data = {};
    data.customer_id = this.props.clientId;
    getInquiries(data)
  };

  onActionClick = (inquiry, doesContainPositions) => {
    this.setState({
      selectedInquiry: inquiry,
      confirmDialogOpen: true,
      removeAction: doesContainPositions
    })
  }

  onDialogClose = () => {
    this.setState({
      confirmDialogOpen: false,
    })
  }

  handleInquiryPositions = () => {
    const {
      onAddPositions,
      onRemovePositions,
      linkedInquiries,
      addedInquiries,
    } = this.props;
    const {selectedInquiry} = this.state;
    const doesContainPositions = linkedInquiries.indexOf(selectedInquiry.id) != -1 || addedInquiries.indexOf(selectedInquiry.id) != -1;
    doesContainPositions ? onRemovePositions(selectedInquiry.id) : onAddPositions(selectedInquiry.id);
    this.setState({
      removeAction: doesContainPositions,
      confirmDialogOpen: false,
    })
  }

  renderInquiry = (inquiry, index) => {
    const {
      statusList,
      currencySymbol,
      linkedInquiries,
      addedInquiries,
      referentialOrderId,
      t
    } = this.props;
    const doesContainPositions = linkedInquiries.indexOf(inquiry.id) != -1 || addedInquiries.indexOf(inquiry.id) != -1;
    const styleProgressData = getStyleProgressDependsOnStatus(inquiry.status, false);
    const isImportedInOtherOrder = inquiry.linked_order_id !== null && inquiry.linked_order_id !== undefined && inquiry.linked_order_id != referentialOrderId;
    return(
      <div
        className= "inquiry-item"
        key={index}
      >
        <div className="inquiry-info">
          <div className="inquiry-title-cost">
            <div className="inquiry-title">{inquiry.name}</div>
            <div className="inquiry-cost">{`${inquiry.cost} ${currencySymbol}`}</div>
          </div>
          <div className="inquiry-data">
            { t('custom') }
            { inquiry.positions.length === 0 && (
                <span className="no-positions-found">{ '  ' + t('no_positions_found') }</span>
            )}
            { isImportedInOtherOrder && (
              <span className="no-positions-found">{ '  ' + t('already_imported') }</span>
            )}
          </div>
        </div>
        <div className="inquiry-action-btn">
          <GridItem className={`progress-container ${styleProgressData.style}`}>
            <div style={{ maxWidth: "24px" }}>
              <CircularProgressbar
                value={styleProgressData.progress}
                strokeWidth={50}
                styles={buildStyles({
                  strokeLinecap: "butt"
                })}
              />
            </div>
          </GridItem>
          <Link to={`/admin/inquiries/inquiry-details/${inquiry.id}`}>
            <IconButton aria-label="view" className="action-btn">
              <img
                alt="view-inquiry"
                src={EyeButton}
              />
            </IconButton>
          </Link>
          {
            inquiry.positions.length !== 0 && !isImportedInOtherOrder &&
              <IconButton
                aria-label="add"
                className="action-btn"
                onClick={() => this.onActionClick(inquiry, doesContainPositions)}
              >
                <img
                  alt="add-inquiry-positions"
                  src={doesContainPositions ? MinusButton : PlusButton}
                />
              </IconButton>
          }
        </div>
      </div>
    )
  }


  render() {
    const {inquiries, isBusy, addedInquiries, referentialOrderId, t} = this.props;
    const {confirmDialogOpen, removeAction, selectedInquiry} = this.state;
    
    let actualInquiriesList = null;
    if (inquiries) {
      actualInquiriesList = inquiries.filter(inquiry => {
        const isImportedInOtherOrder = inquiry.linked_order_id !== null && inquiry.linked_order_id !== undefined && inquiry.linked_order_id != referentialOrderId;
        return !isImportedInOtherOrder;
      });
    }

    let dialogTitle = null;
    let dialogText = null;
    if (removeAction) {
      dialogTitle = t('remove_inquiry');
      
      const isInquiryAdded = addedInquiries.indexOf(selectedInquiry.id) != -1;
      if (isInquiryAdded) {
        dialogText = t('remove_inquiry_from_order');
      } else {
        dialogText = t('remove_inquiry_from_order_alternative');
      }
    } else {
      dialogTitle = t('add_inquiry');
      dialogText = t('add_inquiry_to_order');
    }

    return (
      <div className="customer-inquiry-details">
        <GridItem className="customer-inquiry-details-title" xs={12}>
          <span>{ t('clients_outstanding_inquiries') }</span>
        </GridItem>
        <GridItem className="customer-inquiry-details-content" xs={12}>
          {actualInquiriesList && !isBusy && actualInquiriesList.map((inquiry, index) => this.renderInquiry(inquiry, index))}
        </GridItem>
        {confirmDialogOpen &&
          <ConfirmDialog
            title={dialogTitle}
            text={dialogText}
            onClose={this.onDialogClose}
            onConfirm={this.handleInquiryPositions}
            isOpen={confirmDialogOpen}
          />
        }
      </div>
    )
  }
}

const mapStateToProp = state => {
  return {
    inquiries: state.orders.clientInquiries,
    statusList: state.providers.inquiryStatusList,
    isBusy: state.orders.ordersBusy,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getInquiries: (data) => dispatch(getClientInquiries(data)),
    getStatusList: () => dispatch(getInquiryStatusList())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CustomersInquiries));
