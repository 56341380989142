export const GET_REQUESTS_LIST = 'requestsEpic/GET_REQUESTS_LIST';
export const GET_REQUESTS_LIST_SUCCESS = 'requestsEpic/GET_REQUESTS_LIST_SUCCESS';
export const GET_REQUESTS_LIST_ERROR = 'requestsEpic/GET_REQUESTS_LIST_ERROR';

export const APPROVE_REQUEST = 'requestsEpic/APPROVE_REQUEST';
export const APPROVE_REQUEST_SUCCESS = 'requestsEpic/APPROVE_REQUEST_SUCCESS';
export const APPROVE_REQUEST_ERROR = 'requestsEpic/APPROVE_REQUEST_ERROR';

export const REJECT_REQUEST = 'requestsEpic/REJECT_REQUEST';
export const REJECT_REQUEST_SUCCESS = 'requestsEpic/REJECT_REQUEST_SUCCESS';
export const REJECT_REQUEST_ERROR = 'requestsEpic/REJECT_REQUEST_ERROR';

export const FILTER_REQUESTS = 'requestsEpic/FILTER_REQUESTS';




