import BaseAPIService from './Base';

export default class LogsAPIService extends BaseAPIService {
  getLogs = async (id, page) => {
    return this.request(`api/orders/${id}/logs${page ? `?page=${page}` : ''}`).then((data) => {
      return data;
    });
  }

  filterLogs = async (id, filters) => {
    let url = `${filters.page !== '' ? `?page=${filters.page}` : '?'}${filters.order !== '' ? `&order=${filters.order}` : ''}${filters.filterDate ? `&filter[created_at][]=>${filters.filterDate} 00:00:00&filter[created_at][]=<${filters.filterDate} 23:59:59` : ''}`;
    return this.request(`api/orders/${id}/logs${url}`).then((data) => {
      return data;
    });
  };
}
