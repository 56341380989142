import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from "@material-ui/core/styles/withStyles";

export const ErrorTooltipFn = marginTop => {
    return withStyles((theme) => ({
        tooltip: {
            marginTop: `${marginTop ? marginTop + 4 : 4}px`
        },
    }))(Tooltip);
};

export const ErrorTooltip = ErrorTooltipFn();
export const ErrorTooltipAlt = ErrorTooltipFn(-14);