import { handleActions } from 'redux-actions';
import {
  GET_HOURS_LOGGED,
  GET_ORDERS_LIST,
  GET_POSITIONS_LIST,
  GET_TASKS_LIST,
  GET_COST_CENTERS_LIST,
  GET_COST_CENTERS_ITEMS_LIST,
  GET_POSITION_COST_CENTERS_LIST,
  GET_POSITION_COST_CENTERS_ITEMS_LIST,
  GET_HOURS_LOGGED_SUCCESS,
  GET_ORDERS_LIST_SUCCESS,
  GET_POSITIONS_LIST_SUCCESS,
  GET_TASKS_LIST_SUCCESS,
  GET_COST_CENTERS_LIST_SUCCESS,
  GET_COST_CENTERS_ITEMS_LIST_SUCCESS,
  GET_POSITION_COST_CENTERS_LIST_SUCCESS,
  GET_POSITION_COST_CENTERS_ITEMS_LIST_SUCCESS,
  GET_HOURS_LOGGED_ERROR,
  GET_ORDERS_LIST_ERROR,
  GET_POSITIONS_LIST_ERROR,
  GET_TASKS_LIST_ERROR,
  GET_COST_CENTERS_LIST_ERROR,
  GET_COST_CENTERS_ITEMS_LIST_ERROR,
  GET_POSITION_COST_CENTERS_LIST_ERROR,
  GET_POSITION_COST_CENTERS_ITEMS_LIST_ERROR,
  RESET_TIME_SHEET_PROVIDERS
} from '../actionTypes/timeSheetsProvidersActionType';

export const DEFAULT_TIME_SHEETS_PROVIDERS_STATE = {
  timesheetsProvidersBusy: false,
  hoursLogged: null,
  hoursBreakdown: null,
  hoursLoggedRequest: null,
  ordersList: [],
  positionsList: [],
  tasksList: [],
  costCentersList: [],
  costCentersItemsList: [],
  positionCostCentersList: [],
  positionCostCenterItemsList: [],
  error: null,
};

export const timeSheetsProviders = handleActions(
  {
    [GET_HOURS_LOGGED]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: true,
      hoursLoggedRequest: {
        dateFrom: action.payload.dateFrom,
        dateTo: action.payload.dateTo,
      },
    }),
    [GET_ORDERS_LIST]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: true,
    }),
    [GET_POSITIONS_LIST]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: true,
    }),
    [GET_TASKS_LIST]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: true,
    }),
    [GET_COST_CENTERS_LIST]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: true,
    }),
    [GET_COST_CENTERS_ITEMS_LIST]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: true,
    }),
    [GET_POSITION_COST_CENTERS_LIST]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: true,
    }),
    [GET_POSITION_COST_CENTERS_ITEMS_LIST]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: true,
    }),
    [GET_HOURS_LOGGED_SUCCESS]: (state, action) => ({
      ...state,
      hoursLogged: action.payload.total,
      hoursBreakdown: action.payload.breakdown,
      timesheetsProvidersBusy: false,
    }),
    [GET_ORDERS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: false,
      ordersList: action.payload,
    }),
    [GET_POSITIONS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: false,
      positionsList: {
        ...state.positionsList,
        [action.payload.id]: action.payload.data,
      },
    }),
    [GET_TASKS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: false,
      tasksList: {
        ...state.tasksList,
        [action.payload.id]: action.payload.data,
      },
    }),
    [GET_COST_CENTERS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: false,
      costCentersList: action.payload,
    }),
    [GET_COST_CENTERS_ITEMS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: false,
      costCentersItemsList: action.payload,
    }),
    [GET_POSITION_COST_CENTERS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: false,
      positionCostCentersList: action.payload,
    }),
    [GET_POSITION_COST_CENTERS_ITEMS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: false,
      positionCostCenterItemsList: action.payload,
    }),
    [GET_HOURS_LOGGED_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      timesheetsProvidersBusy: false,
    }),
    [GET_ORDERS_LIST_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      timesheetsProvidersBusy: false,
    }),
    [GET_POSITIONS_LIST_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      timesheetsProvidersBusy: false,
    }),
    [GET_TASKS_LIST_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      timesheetsProvidersBusy: false,
    }),
    [GET_COST_CENTERS_LIST_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      timesheetsProvidersBusy: false,
    }),
    [GET_COST_CENTERS_ITEMS_LIST_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      timesheetsProvidersBusy: false,
    }),
    [GET_POSITION_COST_CENTERS_LIST_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      timesheetsProvidersBusy: false,
    }),
    [GET_POSITION_COST_CENTERS_ITEMS_LIST_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      timesheetsProvidersBusy: false,
    }),
    [RESET_TIME_SHEET_PROVIDERS]: (state, action) => ({
      ...state,
      timesheetsProvidersBusy: false,
      error: null,
      ordersList: [],
      positionsList: [],
      tasksList: [],
      costCentersList: [],
      costCentersItemsList: [],
      positionCostCentersList: [],
      positionCostCenterItemsList: [],
      hoursLogged: null,
      hoursBreakdown: null,
    }),
  },
  DEFAULT_TIME_SHEETS_PROVIDERS_STATE
);
