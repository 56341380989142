import CategoriesAPIService from './Categories';
import CoworkersAPIService from './Coworkers';
import CustomersAPIService from './Customers';
import LocationsAPIService from "./Locations";
import MaterialsAPIService from './Materials';
import MaterialProvidersAPIService from "./MaterialProvders";
import MaterialCategoriesAPIService from './MaterialCategories';
import ManufacturerAPIService from './Manufacturer';
import ManufacturerProvidersAPIService from './ManufacturerProviders';
import ManufacturerAvailabilityAPIService from './ManufacturerAvailability';
import ManufacturerOrderAPIService from './ManufacturerOrder';
import ManufacturerOrderPositionAPIService from './ManufacturerOrderPosition';
import ManufacturerScheduleAPIService from './ManufacturerSchedule';
import UserAPIService from "./UserService";
import OrdersAPIService from "./Orders";
import InquiriesAPIService from "./Inquiries";
import ProvidersAPIService from "./Providers";
import AdminTimeSheetAPIService from './AdminTimeSheet';
import CoworkerTimeSheetAPIService from "./CoworkerTimeSheet";
import TimeSheetsProvidersAPIService from "./TimeSheetsProviders";
import TimerAPIService from "./Timer";
import SettingsAPIService from "./Settings";
import UsersAPIService from "./UsersData";
import RolesAPIService from "./Roles";
import RequestsAPIService from "./Requests";
import LogsAPIService from "./Logs";
import ProgressAPIService from "./Progress";
import GlobalsAPIService from "./Globals";
import InvoicesAPIService from './Invoices';
import InvoicesProvidersAPIService from './InvoicesProviders';

export const categoriesAPI = new CategoriesAPIService();
export const coworkersAPI = new CoworkersAPIService();
export const customersAPI = new CustomersAPIService();
export const locationsAPI = new LocationsAPIService();
export const materialsAPI = new MaterialsAPIService();
export const materialProvidersAPI = new MaterialProvidersAPIService();
export const materialCategoriesAPI = new MaterialCategoriesAPIService();
export const manufacturerAPI = new ManufacturerAPIService();
export const manufacturerProvidersAPI = new ManufacturerProvidersAPIService();
export const manufacturerAvailabilityAPI = new ManufacturerAvailabilityAPIService();
export const manufacturerOrderAPI = new ManufacturerOrderAPIService();
export const manufacturerOrderPositionAPI = new ManufacturerOrderPositionAPIService();
export const manufacturerScheduleAPI = new ManufacturerScheduleAPIService();
export const userServiceApi = new UserAPIService();
export const orderServiceApi = new OrdersAPIService();
export const inquiryServiceApi = new InquiriesAPIService();
export const providersServiceApi = new ProvidersAPIService();
export const adminTimeSheetServiceApi = new AdminTimeSheetAPIService();
export const coworkerTimeSheetServiceApi = new CoworkerTimeSheetAPIService();
export const timeSheetsProvidersServiceApi = new TimeSheetsProvidersAPIService();
export const timerServiceApi = new TimerAPIService();
export const settingsServiceApi = new SettingsAPIService();
export const usersServiceApi = new UsersAPIService();
export const rolesServiceApi = new RolesAPIService();
export const requestsServiceApi = new RequestsAPIService();
export const logsServiceApi = new LogsAPIService();
export const progressServiceApi = new ProgressAPIService();
export const globalsAPI = new GlobalsAPIService();
export const invoicesServiceApi = new InvoicesAPIService();
export const invoicesProvidersServiceApi = new InvoicesProvidersAPIService();
