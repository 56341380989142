import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {locationsAPI} from 'services/API';
import {
  getCountriesSuccess,
  getCountriesFailure,
  getCitiesSuccess,
  getCitiesFailure
} from '../store/actions/locations';
import { GET_COUNTRIES, GET_CITIES} from '../store/actionTypes/locationActionTypes';

export const getCountriesEpic = (actions$) => actions$.pipe(
  ofType(GET_COUNTRIES),
  switchMap(() => from(locationsAPI.getCountries()).pipe(
    switchMap((response) => {
      return of(getCountriesSuccess(response.data))
    }),
    catchError( err => {
        return of(getCountriesFailure(err))
      }
    )
  ))
);

export const getCitiesEpic = (actions$) => actions$.pipe(
  ofType(GET_CITIES),
  switchMap(({payload}) => from(locationsAPI.getCities(payload.countryId, payload.searchTerm)).pipe(
    switchMap((response) => {
      return of(getCitiesSuccess(response.data))
    }),
    catchError( err => {
        return of(getCitiesFailure(err))
      }
    )
  ))
);
