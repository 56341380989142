import React from "react";
import { withTranslation } from 'react-i18next';

import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import GridItem from "../../components/Grid/GridItem";
import Input from "../../components/Input/Input";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import GridContainer from "../../components/Grid/GridContainer";

import './style.scss';
import CloseButton from "../../assets/img/buttons/delete.svg";
import IconButton from "@material-ui/core/IconButton";

class ShippingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: this.props.id === 0
    };
  }

  onCardClose = () => {
    this.setState({
      isCardOpen: !this.state.isCardOpen
    })
  }

  render() {
    const {countries, cities, data, id, onChange, onRemove, errors, t} = this.props;
    const {isCardOpen} = this.state;
    return(
      <div className="shipping-card-content">
        <div className={`shipping-card-info ${isCardOpen ? 'shipping-card-bottom-borders' : ''}`}>
          <div className="shipping-card-info-title">
            <div className="shipping-card-header">
              <div className="shipping-card-title">
                {`${ t('shipping_address') } ${id + 1}`}
              </div>
              <div className="position-total-price">
                  <IconButton aria-label="close" className="close-btn position-delete-btn" onClick={onRemove}>
                    <img alt="close-task-btn" src={CloseButton}/>
                  </IconButton>
              </div>
            </div>
            {
              isCardOpen &&
                <div className="shipping-card-data">
                  <GridContainer>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('first_name') }</span>
                      <Input
                        autofocus={true}
                        value={data.first_name}
                        onChange={(e) => onChange('first_name', e.target.value)}
                        error={errors[`shipping_addresses.${id}.first_name`]}
                        errorMsg={errors[`shipping_addresses.${id}.first_name`]}
                      />
                    </GridItem>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('last_name') }</span>
                      <Input
                        value={data.last_name}
                        onChange={(e) => onChange('last_name', e.target.value)}
                        error={errors[`shipping_addresses.${id}.last_name`]}
                        errorMsg={errors[`shipping_addresses.${id}.last_name`]}
                      />
                    </GridItem>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('telephone') }</span>
                      <Input
                        value={data.phone}
                        onChange={(e) => onChange('phone', e.target.value)}
                        error={errors[`shipping_addresses.${id}.phone`]}
                        errorMsg={errors[`shipping_addresses.${id}.phone`]}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('mobile_phone') }</span>
                      <Input
                        value={data.phone_mobile}
                        onChange={(e) => onChange('phone_mobile', e.target.value)}
                        error={errors[`shipping_addresses.${id}.phone_mobile`]}
                        errorMsg={errors[`shipping_addresses.${id}.phone_mobile`]}
                      />
                    </GridItem>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('email') }</span>
                      <Input
                        value={data.email}
                        onChange={(e) => onChange('email', e.target.value)}
                        error={errors[`shipping_addresses.${id}.email`]}
                        errorMsg={errors[`shipping_addresses.${id}.email`]}
                      />
                    </GridItem>
                    <GridItem></GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('address') }</span>
                      <Input
                        value={data.address}
                        onChange={(e) => onChange('address', e.target.value)}
                        error={errors[`shipping_addresses.${id}.address`]}
                        errorMsg={errors[`shipping_addresses.${id}.address`]}
                      />
                    </GridItem>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('post_code') }</span>
                      <Input
                        value={data.postal_code}
                        onChange={(e) => onChange('postal_code', e.target.value)}
                        error={errors[`shipping_addresses.${id}.postal_code`]}
                        errorMsg={errors[`shipping_addresses.${id}.postal_code`]}
                      />
                    </GridItem>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('city') }</span>
                      <Input
                        value={data.city}
                        onChange={(e) => onChange('city', e.target.value)}
                        error={errors[`shipping_addresses.${id}.city`]}
                        errorMsg={errors[`shipping_addresses.${id}.city`]}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem className="shipping-card-item" xs={4}>
                      <span className="shipping-card-item-title">{ t('country') }</span>
                      <Input
                        value={data.country}
                        onChange={(e) => onChange('country', e.target.value)}
                        error={errors[`shipping_addresses.${id}.country`]}
                        errorMsg={errors[`shipping_addresses.${id}.country`]}
                      />
                    </GridItem>
                    <GridItem xs={8}></GridItem>
                  </GridContainer>
                </div>
            }
          </div>
          <div className="shipping-card-btn-container">
            <div
              className={`card-btn ${isCardOpen ? 'cost-bottom-borders' : ''}`}
              onClick={() => this.onCardClose()}
            >
              {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ShippingDetails);
