import React from 'react';
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './style.scss';
import GridItem from "components/Grid/GridItem";
import CloseButton from "../../assets/img/buttons/delete.svg";
import IconButton from "@material-ui/core/IconButton";
import CustomDatePicker from "../../components/CustomDatePicker";
import Input from 'components/Input/Input';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import TextArea from "../../components/CustomTextArea";
import moment from "moment";
import { ErrorTooltip, ErrorTooltipAlt } from '../../components/ErrorTooltip/ErrorTooltip';

import {
  getPositionsList,
  getPositionCostCentersList,
  getPositionCostCenterItemsList,
  resetProviders,
} from "../../store/actions/timeSheetsProviders";

class TimeEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: true,
      selectedOrder: props.order ? props.order.id : '',
      selectedPosition: '',
      selectedCostCenterId: '',
      selectedCostCenterItemId: '',
      fetchedPostionCostCentersList: null,
      fetchedPostionCostCenterItemsList: null,
    };
  }

  componentDidMount() {
    const {
      getPositionsList,
      order,
    } = this.props;
    const {
      selectedOrder
    } = this.state;
    if (order) {
      getPositionsList(selectedOrder);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    const { selectedPosition, selectedCostCenterId } = this.state;

    if (data.position_id) {
      // editing entry
      if (selectedPosition === null || selectedPosition === undefined || selectedPosition === '') {
        let selectedCostCenterId = '';
        let selectedCostCenterItemId = -1;
        if (data.loggable_type == 'App\\Models\\PositionCostCenter') {
          selectedCostCenterId = data.loggable_id;
        } else if (data.loggable_type == 'App\\Models\\PositionCostCenterItem') {
          selectedCostCenterId = data.loggable_meta.position_cost_center_id;
          selectedCostCenterItemId = data.loggable_id;
        }
        this.setState({
          selectedPosition: data.loggable_meta.position_id,
          selectedCostCenterId,
          selectedCostCenterItemId,
        });
      } else if (!this.state.fetchedPostionCostCentersList || (selectedPosition && this.state.fetchedPostionCostCentersList != selectedPosition)) {
        this.setState({
          fetchedPostionCostCentersList: selectedPosition,
        }, () => {
          this.props.getPositionCostCentersList(selectedPosition);
        });
      } else if (!this.state.fetchedPostionCostCenterItemsList || (selectedCostCenterId && this.state.fetchedPostionCostCenterItemsList != selectedCostCenterId)) {
        this.setState({
          fetchedPostionCostCenterItemsList: selectedCostCenterId,
        }, () => {
          this.props.getPositionCostCenterItemsList(selectedCostCenterId);
        });
      }
    }
  }

  onCardClose = () => {
    this.setState({isCardOpen: !this.state.isCardOpen})
  };

  handleInputChange = (fieldName, value) => {
    const {data, onChange} = this.props;
    const newData = {...data};
    if (fieldName === 'time') {
      if (value &&
        (!/^\d+([.]\d*)?$/.test(value) || isNaN(parseFloat(value))))
        return;
    }
    newData[fieldName] = value;
    if (fieldName === 'type') {
      newData.loggable_id = '';
    }
    onChange(newData);
  };

  handlePositionDropdownChange = (value) => {
    const {getPositionCostCentersList, onChange, data} = this.props;
    if (this.state.selectedPosition != value) {
      this.setState({
        selectedPosition: value,
        selectedCostCenterId: '',
        selectedCostCenterItemId: '',
        fetchedPostionCostCentersList: value,
      }, () => {
        onChange({
          ...data,
          type: 0,
          loggable_id: '',
        });
        getPositionCostCentersList(value);
      });
    }
  }
  
  handleCostCenterDropdownChange = (value) => {
    const {getPositionCostCenterItemsList, onChange, data} = this.props;
    if (this.state.selectedCostCenterId != value) {
      this.setState({
        selectedCostCenterId: value,
        selectedCostCenterItemId: '',
      }, () => {
        onChange({
          ...data,
          type: 0,
          loggable_id: value,
        });
        getPositionCostCenterItemsList(value);
      });
    }
  }

  handleCostCenterItemsDropdownChange = (value) => {
    const {onChange, data} = this.props;
    const {selectedCostCenterId} = this.state;
    if (this.state.selectedCostCenterItemId != value) {
      this.setState({
        selectedCostCenterItemId: value,
      }, () => {
        onChange({
          ...data,
          type: value !== -1 ? 1 : 0,
          loggable_id: value !== -1 ? value : selectedCostCenterId,
        });
      });
    }
  }

  render() {
    const {
      data,
      id,
      onRemoveEntry,
      orderPositionsList,
      positionCostCentersList,
      positionCostCenterItemsList,
      order,
      errors,
      isEdit,
      t
    } = this.props
    const {
      isCardOpen,
      selectedPosition,
      selectedCostCenterId,
      selectedCostCenterItemId,
    } = this.state;
    const positionsList = orderPositionsList[order.id] ? orderPositionsList[order.id] : [];
    const positionCostCenterItemsListActual = [{id: -1, value: t('none')}, ...positionCostCenterItemsList];
    const actualErrors = errors ? errors : {};
    const sanitizeTooltipErrorTitle = error => (error ? error[0] : "");
    return (
      <div className="time-entry-content">
        <GridItem className="time-entry-info" xs={12}>
          <div className="time-entry-title">
            <span className="entry-title">{`${t('time_entry')} ${!isEdit ? id + 1 : ''}`}</span>
            { !isEdit && <IconButton
              aria-label="close"
              className="remove-btn"
              onClick={onRemoveEntry}
            >
              <img alt="remove-time-entry-btn" src={CloseButton}/>
            </IconButton> }
          </div>
          {isCardOpen &&
            <div className="time-entry-info-content">
              <div className="timer-date-picker-container">
                <div className="timer-entry-data-item">
                  <span className="timer-entry-data-item-title">{ t('date') }</span>
                  <CustomDatePicker
                    className="timer-entry-date-picker"
                    handleChange={date => this.handleInputChange('entry_date', moment(date).format('YYYY-MM-DD'))}
                    selectedDate={data.entry_date}
                  />
                </div>
                <div className="timer-entry-data-item time-hours">
                  <span className="timer-entry-data-item-title">{ t('time') } ( { t('e_g') }: 2h 20min = 2.33)</span>
                  <ErrorTooltip title={sanitizeTooltipErrorTitle(actualErrors.time)}>
                    <Input
                      value={data.time}
                      onChange={e => this.handleInputChange('time', e.target.value)}
                      error={actualErrors.time} />
                  </ErrorTooltip>
                </div>
              </div>
              <div className="timer-dropdowns">
                <div className="timer-entry-data-item">
                  <span className="timer-entry-data-item-title">{ t('position') }</span>
                  <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(actualErrors.loggable_id)}>
                    <Dropdown
                      buttonText={selectedPosition}
                      buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm"}}
                      dropdownList={positionsList}
                      onClick={(value) => this.handlePositionDropdownChange(value.id)}
                      error={actualErrors.loggable_id}
                      customClass={"time-entry-dropdown"}
                    />
                  </ErrorTooltipAlt>
                </div>
                <div className="timer-entry-data-item">
                  <span className="timer-entry-data-item-title">{ t('cost_center') }</span>
                  <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(actualErrors.loggable_id)}>
                    <Dropdown
                      buttonText={selectedCostCenterId ? selectedCostCenterId : -1}
                      buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedPosition}}
                      dropdownList={positionCostCentersList}
                      onClick={(value) => this.handleCostCenterDropdownChange(value.id)}
                      error={actualErrors.loggable_id}
                      customClass={"time-entry-dropdown"}
                    />
                  </ErrorTooltipAlt>
                </div>
                <div className="timer-entry-data-item">
                  <span className="timer-entry-data-item-title">{ t('cost_center_item') }</span>
                  <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(actualErrors.loggable_id)}>
                    <Dropdown
                      buttonText={selectedCostCenterItemId ? selectedCostCenterItemId : -1}
                      buttonProps={{className: 'dropdown-blue-style timer-dropdown', round: true, size: "sm", disabled: !selectedPosition || !selectedCostCenterId}}
                      dropdownList={positionCostCenterItemsListActual}
                      onClick={(value) => this.handleCostCenterItemsDropdownChange(value.id)}
                      error={actualErrors.loggable_id}
                      customClass={"time-entry-dropdown"}
                    />
                  </ErrorTooltipAlt>
                </div>
              </div>
              <div className="timer-entry-note">
                <div className="timer-entry-data-item">
                  <span className="timer-entry-data-item-title">{ t('note') }</span>
                  <div className="description-test-area">
                    <ErrorTooltip title={sanitizeTooltipErrorTitle(actualErrors.description)}>
                      <TextArea
                        value={data.description}
                        onChange={e => this.handleInputChange('description', e.target.value)}
                        error={actualErrors.description} />
                    </ErrorTooltip>
                  </div>
                </div>
              </div>
            </div>
          }
        </GridItem>
        <div
          className="card-btn"
          onClick={() => this.onCardClose()}
        >
          <div>
            {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProp = state => {
  return {
    ordersList: state.timeSheetsProviders.ordersList,
    orderPositionsList: state.timeSheetsProviders.positionsList,
    positionCostCentersList: state.timeSheetsProviders.positionCostCentersList,
    positionCostCenterItemsList: state.timeSheetsProviders.positionCostCenterItemsList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPositionsList: (id) => dispatch(getPositionsList(id)),
    getPositionCostCentersList: (id) => dispatch(getPositionCostCentersList(id)),
    getPositionCostCenterItemsList: (id) => dispatch(getPositionCostCenterItemsList(id)),
    resetProviders: () => dispatch(resetProviders()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(TimeEntry));
