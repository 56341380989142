import {
  GET_ORDER_STATUS_LIST,
  GET_ORDER_STATUS_LIST_SUCCESS,
  GET_ORDER_STATUS_LIST_FAILURE,
  GET_INQUIRY_STATUS_LIST,
  GET_INQUIRY_STATUS_LIST_SUCCESS,
  GET_INQUIRY_STATUS_LIST_FAILURE,
  GET_COWORKERS_LIST,
  GET_COWORKERS_LIST_SUCCESS,
  GET_COWORKERS_LIST_FAILURE,
  GET_SELECTED_CUSTOMERS,
  GET_SELECTED_CUSTOMERS_SUCCESS,
  GET_SELECTED_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_LIST,
  GET_CUSTOMERS_LIST_SUCCESS,
  GET_CUSTOMERS_LIST_FAILURE,
  GET_SUPPLIERS_LIST,
  GET_SUPPLIERS_LIST_SUCCESS,
  GET_SUPPLIERS_LIST_FAILURE,
  GET_MATERIALS_LIST,
  GET_MATERIALS_LIST_SUCCESS,
  GET_MATERIALS_LIST_FAILURE,
  GET_TYPE_LIST,
  GET_TYPE_LIST_SUCCESS,
  GET_TYPE_LIST_FAILURE,
  GET_DELIVERY_TYPE_LIST,
  GET_DELIVERY_TYPE_LIST_SUCCESS,
  GET_DELIVERY_TYPE_LIST_FAILURE,
  GET_PAYMENT_TYPE_LIST,
  GET_PAYMENT_TYPE_LIST_SUCCESS,
  GET_PAYMENT_TYPE_LIST_FAILURE,
  GET_TASK_UNIT_LIST,
  GET_TASK_UNIT_LIST_SUCCESS,
  GET_TASK_UNIT_LIST_FAILURE,
  GET_COST_CENTER_UNIT_LIST,
  GET_COST_CENTER_UNIT_LIST_SUCCESS,
  GET_COST_CENTER_UNIT_LIST_FAILURE,
  GET_CURRENCIES_LIST,
  GET_CURRENCIES_LIST_SUCCESS,
  GET_CURRENCIES_LIST_FAILURE,
  RESET_PROVIDERS_DATA
} from '../actionTypes/providerActionTypes';

export const getOrderStatusList = () => {
  return {type: GET_ORDER_STATUS_LIST}
};

export const getOrderStatusListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_ORDER_STATUS_LIST_SUCCESS, payload: selectData}
};

export const getOrderStatusListFailure = (error) => {
  return {type: GET_ORDER_STATUS_LIST_FAILURE, payload: error}
};

export const getInquiryStatusList = () => {
  return {type: GET_INQUIRY_STATUS_LIST}
};

export const getInquiryStatusListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_INQUIRY_STATUS_LIST_SUCCESS, payload: selectData}
};

export const getInquiryStatusListFailure = (error) => {
  return {type: GET_INQUIRY_STATUS_LIST_FAILURE, payload: error}
};

export const getCoworkersList = (id) => {
  return {type: GET_COWORKERS_LIST, payload: id}
};

export const getCoworkersListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_COWORKERS_LIST_SUCCESS, payload: selectData}
};

export const getCoworkersListFailure = (error) => {
  return {type: GET_COWORKERS_LIST_FAILURE, payload: error}
};

export const getSelectedCustomers = (ids) => {
  return {type: GET_SELECTED_CUSTOMERS, payload: ids}
};

export const getSelectedCustomersSuccess = (data) => {
  return {type: GET_SELECTED_CUSTOMERS_SUCCESS, payload: data}
};

export const getSelectedCustomersFailure = (error) => {
  return {type: GET_SELECTED_CUSTOMERS_FAILURE, payload: error}
};

export const getCustomersList = (term, excludeSuppliers = true) => {
  return {type: GET_CUSTOMERS_LIST, payload: {term, excludeSuppliers}}
};

export const getCustomersListSuccess = (data) => {
  return {type: GET_CUSTOMERS_LIST_SUCCESS, payload: data}
};

export const getCustomersListFailure = (error) => {
  return {type: GET_CUSTOMERS_LIST_FAILURE, payload: error}
};

export const getSuppliersList = (data) => {
  return {type: GET_SUPPLIERS_LIST, payload: data}
};

export const getSuppliersListSuccess = (data) => {
  const mappedData = data.map(item => ({'id':item.id, 'value': item.supplier_name ? item.supplier_name : (item.last_name ? (item.last_name + ' ') : '') + item.first_name}));
  return {type: GET_SUPPLIERS_LIST_SUCCESS, payload: mappedData}
};

export const getSuppliersListFailure = (error) => {
  return {type: GET_SUPPLIERS_LIST_FAILURE, payload: error}
};

export const getMaterialsList = (data) => {
  return {type: GET_MATERIALS_LIST, payload: data}
};

export const getMaterialsListSuccess = (data) => {
  return {type: GET_MATERIALS_LIST_SUCCESS, payload: data}
};

export const getMaterialsListFailure = (error) => {
  return {type: GET_MATERIALS_LIST_FAILURE, payload: error}
};

export const getTypeList = () => {
  return {type: GET_TYPE_LIST}
};

export const getTypeListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_TYPE_LIST_SUCCESS, payload: selectData}
};

export const getTypeListFailure = (error) => {
  return {type: GET_TYPE_LIST_FAILURE, payload: error}
};

export const getDeliveryTypeList = () => {
  return {type: GET_DELIVERY_TYPE_LIST}
};

export const getDeliveryTypeListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_DELIVERY_TYPE_LIST_SUCCESS, payload: selectData}
};

export const getDeliveryTypeListFailure = (error) => {
  return {type: GET_DELIVERY_TYPE_LIST_FAILURE, payload: error}
};

export const getPaymentTypeList = () => {
  return {type: GET_PAYMENT_TYPE_LIST}
};

export const getPaymentTypeListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_PAYMENT_TYPE_LIST_SUCCESS, payload: selectData}
};

export const getPaymentTypeListFailure = (error) => {
  return {type: GET_PAYMENT_TYPE_LIST_FAILURE, payload: error}
};

export const getTaskUnitList = () => {
  return {type: GET_TASK_UNIT_LIST}
};

export const getTaskUnitListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id': item[0], 'value': item[1].slice(0, 6)})
  })
  return {type: GET_TASK_UNIT_LIST_SUCCESS, payload: selectData}
};

export const getTaskUnitListFailure = (error) => {
  return {type: GET_TASK_UNIT_LIST_FAILURE, payload: error}
};

export const getCostCenterUnitList = () => {
  return {type: GET_COST_CENTER_UNIT_LIST}
};

export const getCostCenterUnitListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_COST_CENTER_UNIT_LIST_SUCCESS, payload: selectData}
};

export const getCostCenterUnitListFailure = (error) => {
  return {type: GET_COST_CENTER_UNIT_LIST_FAILURE, payload: error}
};

export const getCurrenciesList = () => {
  return {type: GET_CURRENCIES_LIST}
};

export const getCurrenciesListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value': item[1].toUpperCase()})
  })
  return {type: GET_CURRENCIES_LIST_SUCCESS, payload: selectData}
};

export const getCurrenciesListFailure = (error) => {
  return {type: GET_CURRENCIES_LIST_FAILURE, payload: error}
};

export const resetProvidersData = () => {
  return {type: RESET_PROVIDERS_DATA}
};
