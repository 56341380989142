import {
  GET_ALL_MATERIALS,
  GET_ALL_MATERIALS_SUCCESS,
  GET_ALL_MATERIALS_ERROR,
  GET_POSITION_MATERIALS_LIST,
  GET_POSITION_MATERIALS_LIST_SUCCESS,
  GET_POSITION_MATERIALS_LIST_ERROR,
  GET_FITTINGS_MATERIALS_LIST,
  GET_FITTINGS_MATERIALS_LIST_SUCCESS,
  GET_FITTINGS_MATERIALS_LIST_ERROR,
  GET_MAIN_MATERIALS_LIST,
  GET_MAIN_MATERIALS_LIST_SUCCESS,
  GET_MAIN_MATERIALS_LIST_ERROR,
  GET_EDGE_MATERIALS_LIST,
  GET_EDGE_MATERIALS_LIST_SUCCESS,
  GET_EDGE_MATERIALS_LIST_ERROR,
  GET_COVERING_MATERIALS_LIST,
  GET_COVERING_MATERIALS_LIST_SUCCESS,
  GET_COVERING_MATERIALS_LIST_ERROR,
  GET_SURFACE_MATERIALS_LIST,
  GET_SURFACE_MATERIALS_LIST_SUCCESS,
  GET_SURFACE_MATERIALS_LIST_ERROR,
  GET_SELECTED_MATERIALS,
  GET_SELECTED_MATERIALS_SUCCESS,
  GET_SELECTED_MATERIALS_ERROR,
} from '../actionTypes/materialProvidersActionTypes';

export const getAllMaterials = (term, metric) => {
  return {type: GET_ALL_MATERIALS, payload: { term, metric }};
};

export const getAllMaterialsSuccess = (data) => {
  return {type: GET_ALL_MATERIALS_SUCCESS, payload: data};
};

export const getAllMaterialsFailure = (error) => {
  return {type: GET_ALL_MATERIALS_ERROR, payload: error};
};

export const getMainMaterialsList = (term) => {
  return {type: GET_MAIN_MATERIALS_LIST, payload: term}
};

export const getMainMaterialsListSuccess = (data) => {
  return {type: GET_MAIN_MATERIALS_LIST_SUCCESS, payload: data}
};

export const getMainMaterialsListFailure = (error) => {
  return {type: GET_MAIN_MATERIALS_LIST_ERROR, payload: error}
};

export const getPositionMaterialsList = (term) => {
  return {type: GET_POSITION_MATERIALS_LIST, payload: term};
};

export const getPositionMaterialsListSuccess = (data) => {
  return {type: GET_POSITION_MATERIALS_LIST_SUCCESS, payload: data};
};

export const getPositionMaterialsListFailure = (error) => {
  return {type: GET_POSITION_MATERIALS_LIST_ERROR, payload: error};
};

export const getFittingsMaterialsList = (term) => {
  return {type: GET_FITTINGS_MATERIALS_LIST, payload: term};
};

export const getFittingsMaterialsListSuccess = (data) => {
  return {type: GET_FITTINGS_MATERIALS_LIST_SUCCESS, payload: data};
};

export const getFittingsMaterialsListFailure = (error) => {
  return {type: GET_FITTINGS_MATERIALS_LIST_ERROR, payload: error};
};

export const getEdgeMaterialsList = (term) => {
  return {type: GET_EDGE_MATERIALS_LIST, payload: term}
};

export const getEdgeMaterialsListSuccess = (data) => {
  return {type: GET_EDGE_MATERIALS_LIST_SUCCESS, payload: data}
};

export const getEdgeMaterialsListFailure = (error) => {
  return {type: GET_EDGE_MATERIALS_LIST_ERROR, payload: error}
};

export const getCoveringMaterialsList = (term) => {
  return {type: GET_COVERING_MATERIALS_LIST, payload: term}
};

export const getCoveringMaterialsListSuccess = (data) => {
  return {type: GET_COVERING_MATERIALS_LIST_SUCCESS, payload: data}
};

export const getCoveringMaterialsListFailure = (error) => {
  return {type: GET_COVERING_MATERIALS_LIST_ERROR, payload: error}
};

export const getSurfaceMaterialsList = (term) => {
  return {type: GET_SURFACE_MATERIALS_LIST, payload: term}
};

export const getSurfaceMaterialsListSuccess = (data) => {
  return {type: GET_SURFACE_MATERIALS_LIST_SUCCESS, payload: data}
};

export const getSurfaceMaterialsListFailure = (error) => {
  return {type: GET_SURFACE_MATERIALS_LIST_ERROR, payload: error}
};

export const getSelectedMaterials = (term) => {
  return {type: GET_SELECTED_MATERIALS, payload: term}
};

export const getSelectedMaterialsSuccess = (data) => {
  return {type: GET_SELECTED_MATERIALS_SUCCESS, payload: data}
};

export const getSelectedMaterialsFailure = (error) => {
  return {type: GET_SELECTED_MATERIALS_ERROR, payload: error}
};
