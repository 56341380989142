import React from "react";
import { withTranslation } from 'react-i18next';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';

class CreateTaskHelpDialog extends React.Component {
  render() {
    const { isOpen, onClose, t } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="create-task-help-dialog-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{t('help')}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="file-import-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="create-task-help-dialog-content">
          <span className="help-title">Allgemein</span>
          <span>
            Ein Doppelklick auf eine Überschrift oder in den leeren Bereich unterhalb der Liste hängt eine neue Zeile an.<br />Die Zeilenhöhe läßt sich manuell am linken Bildschirmrand zwischen den betreffenden Positionsnummern durch ziehen mit der Maus einstellen.<br />Analog dazu lassen sich die Spaltenbreiten ebenfalls durch Ziehen mit der Maus in den Spaltenköpfen in der zweiten Zeile einstellen.<br />
          </span>
          <span className="help-title" style={{marginTop: "15px"}}>Felder der Holzliste</span>
          <span className="help-title">Nummer</span>
          <span>
            Momentan ist nur eine Nummerierung der Holzliste mit ganzen Zahlen möglich. Es gibt einen Befehl Neu nummerieren im Kontextmenü (rechte Maustaste) einer Holzlistenzeile, der das durchnummerieren einer Holzliste erlaubt
          </span>
          <span className="help-title">Beschreibung</span>
          <span>
            Längerer Text mit erweiterter Formatierungsmöglichkeit<br />
            Der Name beeinflusst hier die Orientierung des Werkstücks (siehe Feld Ecken).
          </span>
          <span className="help-title">Material</span>
          <span>
            Feld mit Top 10 Lookup. Es sind Materialien der Material(unter)gruppe Massiv und Platten erlaubt.
          </span>
          <span className="help-title">Menge</span>
          <span>
            Kann auch ein Dezimalbruch sein, dessen Darstellung immer kürzestmöglichst erfolg. (Beispielsweise 12,3400 -&gt; 12,34 oder 1,00 -&gt; 1)
          </span>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(CreateTaskHelpDialog);
