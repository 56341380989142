import {ofType} from 'redux-observable';
import {from, of, concat} from 'rxjs';
import {switchMap, mergeMap, catchError} from 'rxjs/operators';
import {manufacturerAPI} from 'services/API';
import {
  getManufacturers,
  getManufacturersSuccess,
  getManufacturersFailure,
  getManufacturer,
  getManufacturerSuccess,
  getManufacturerFailure,
  getManufacturerContactsSuccess,
  getManufacturerContactsFailure,
  addManufacturerSuccess,
  addManufacturerFailure,
  updateManufacturerSuccess,
  updateManufacturerFailure,
  removeManufacturerSuccess,
  removeManufacturerFailure,
  getManufacturerSettingsSuccess,
  getManufacturerSettingsFailure,
  addManufacturerRequestSuccess,
  addManufacturerRequestFailure,
  updateManufacturerRequestSuccess,
  updateManufacturerRequestFailure,
  filterManufacturers
} from '../store/actions/manufacturer';
import {
  GET_MANUFACTURERS,
  GET_MANUFACTURER,
  GET_MANUFACTURER_CONTACTS,
  ADD_MANUFACTURER,
  UPDATE_MANUFACTURER,
  ADD_MANUFACTURER_REQUEST,
  UPDATE_MANUFACTURER_REQUEST,
  SET_MANUFACTURER_STATUS,
  FILTER_MANUFACTURERS,
  GET_MANUFACTURER_SETTINGS, REMOVE_MANUFACTURER
} from '../store/actionTypes/manufacturerActionTypes';
import NotificationService from "../services/NotificationService";
import {addCustomerFailure} from "../store/actions/customers";

export const getManufacturersEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURERS),
  switchMap(({payload}) => from(manufacturerAPI.getManufacturers(payload)).pipe(
    switchMap((response) => {
      return of(getManufacturersSuccess(response))
    }),
    catchError( err => {
        return of(getManufacturersFailure(err))
      }
    )
  ))
);

export const getManufacturerEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURER),
  switchMap(({payload}) => from(manufacturerAPI.getManufacturer(payload)).pipe(
    switchMap((response) => {
      return of(getManufacturerSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturerFailure(err))
      }
    )
  ))
);

export const getManufacturerSettingsEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURER_SETTINGS),
  switchMap(({payload}) => from(manufacturerAPI.getManufacturerSettings(payload)).pipe(
    switchMap((response) => {
      return of(getManufacturerSettingsSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturerSettingsFailure(err))
      }
    )
  ))
);

export const getManufacturerContactsEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURER_CONTACTS),
  switchMap(() => from(manufacturerAPI.getManufacturerContacts()).pipe(
    switchMap((response) => {
      return of(getManufacturerContactsSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturerContactsFailure(err))
      }
    )
  ))
);

export const addManufacturerEpic = (actions$) => actions$.pipe(
  ofType(ADD_MANUFACTURER),
  switchMap((action) => from(manufacturerAPI.createManufacturer(action.payload.data)).pipe(
    switchMap((response) => concat(
      of(filterManufacturers(action.payload.filters, action.payload.searchTerms)),
      of(addManufacturerSuccess(response.data))
    )),
    catchError( err => {
      return of(addManufacturerFailure(err.responseBody))
      }
    )
  ))
);

export const updateManufacturerEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_MANUFACTURER),
  mergeMap((action) => from(manufacturerAPI.updateManufacturer(action.payload.id, action.payload.data)).pipe(
    switchMap((response) => concat(
      of(filterManufacturers(action.payload.filters, action.payload.searchTerms)),
      of(updateManufacturerSuccess(response.data))
    )),
    catchError( err => {
        return of(updateManufacturerFailure(err.responseBody))
      }
    )
  ))
);

export const addManufacturerRequestEpic = (actions$) => actions$.pipe(
  ofType(ADD_MANUFACTURER_REQUEST),
  switchMap(({payload}) => from(manufacturerAPI.createManufacturerRequest(payload)).pipe(
    switchMap((response) => concat(
      of(getManufacturers()),
      of(addManufacturerRequestSuccess(response.data))
    )),
    catchError( err => {
        return of(addManufacturerRequestFailure(err))
      }
    )
  ))
);

export const updateManufacturerRequestEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_MANUFACTURER_REQUEST),
  mergeMap((action) => from(manufacturerAPI.updateManufacturerRequest(action.payload.id, action.payload.data)).pipe(
    switchMap((response) => {
      return of(updateManufacturerRequestSuccess(response))
    }),
    catchError( err => {
        return of(updateManufacturerRequestFailure(err))
      }
    )
  ))
);


export const removeManufacturerEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_MANUFACTURER),
  switchMap((action) => from(manufacturerAPI.deleteManufacturer(action.payload.id)).pipe(
    switchMap((response) => {
      NotificationService.success('Manufacturer was removed');
      const loadListInfo = action.payload.loadListInfo;
      if (loadListInfo) {
        return concat(
          of(filterManufacturers(loadListInfo.filters, loadListInfo.searchTerms)),
          of(removeManufacturerSuccess())
        );
      } else {
        return of(removeManufacturerSuccess());
      }
    }),
    catchError( err => {
        return of(removeManufacturerFailure(err));
      }
    )
  ))
);

export const setManufacturerStatusEpic = (actions$) => actions$.pipe(
  ofType(SET_MANUFACTURER_STATUS),
  mergeMap((action) => from(manufacturerAPI.setManufacturerStatus(action.payload.id, action.payload.data.value)).pipe(
    switchMap(() => concat(
      of(filterManufacturers(action.payload.data.filters)),
      of(getManufacturer(action.payload.id))
    )),
    catchError( err => {
        return of(getManufacturersFailure(err))
      }
    )
  ))
);

export const filterManufacturersEpic = (actions$) => actions$.pipe(
  ofType(FILTER_MANUFACTURERS),
  switchMap((action) => from(manufacturerAPI.filterManufacturers(action.payload.filters, action.payload.searchTerms)).pipe(
    switchMap((response) => {
      return of(getManufacturersSuccess(response))
    }),
    catchError( err => {
        return of(getManufacturersFailure(err))
      }
    )
  ))
);
