import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  getMaterialListOrientationData,
  addMaterialListOrientationClass,
  removeMaterialListOrientationClass,
} from "store/actions/settings";

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import Input from 'components/Input/Input';
import InputWithIcon from 'components/InputWithIcon';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconSeite from 'assets/img/orientations/seite.svg';
import IconFront from 'assets/img/orientations/front.svg';
import IconBoden from 'assets/img/orientations/boden.svg';
import IconSearch from 'assets/img/search.svg';
import CloseIcon from '@material-ui/icons/Close';
import RemoveDialog from 'views/RemoveDialog';

import './style.scss';

class Orientation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orientationId: 2,
      currentFilterValue: '',
      currentAddInputValue: '',
      isRemoveClassOpen: false,
      selectedIdForRemoval: null,
    };
  }

  componentDidMount() {
    this.props.getData(this.state.orientationId);
  }

  handleOrientationChange = (id) => {
    const { getData } = this.props;
    const { currentFilterValue } = this.state;
    this.setState({
      orientationId: id,
      currentFilterValue: '',
    }, () => {
      getData(id, currentFilterValue);
    });
  }

  handleFilterInputChange = (value) => {
    const { getData } = this.props;
    const { orientationId } = this.state;
    this.setState({
      currentFilterValue: value,
    });
    getData(orientationId, value);
  }

  handleAddInputChange = (value) => {
    this.setState({
      currentAddInputValue: value,
    });
  }

  handleAddInputKeyDown = event => {
    if (event.keyCode == 13) {  // enter
      this.handleAdd();
    }
  }

  handleAdd = () => {
    const { onAddClass } = this.props;
    const { orientationId, currentFilterValue, currentAddInputValue, isRemoveClassOpen } = this.state;
    if (currentAddInputValue) {
      onAddClass(orientationId, currentAddInputValue, currentFilterValue);
    }
    this.setState({
      currentAddInputValue: '',
    });
  }

  handleRemoveClick = (index) => {
    const { data } = this.props;
    this.setState({
      isRemoveClassOpen: true,
      selectedIdForRemoval: data[index].id,
    });
  }

  handleRemoveClass = () => {
    const { onRemoveClass } = this.props;
    const { orientationId, currentFilterValue, selectedIdForRemoval } = this.state;
    onRemoveClass(selectedIdForRemoval, orientationId, currentFilterValue);
    this.handleRemoveDialogClose();
  }

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveClassOpen: false,
      selectedIdForRemoval: null,
    });
  }

  renderElement = (el, index) => {
    return (<div key={index} className="orientation-class-row">
      <span className="orientation-class-text" title={ el.keyword.length > 40 ? el.keyword : ''}>
        { el.keyword }
      </span>
      <div
        className="delete-btn-container"
        onClick={() => this.handleRemoveClick(index)}
      >
        <CloseIcon fontSize={'small'} />
      </div>
    </div>);
  }

  render() {
    const { data, t } = this.props;
    const { currentFilterValue, currentAddInputValue, isRemoveClassOpen } = this.state;
    return (
      <div className="position-content-orientation">
        <div className={`position-info ${true ? 'bottom-borders' : ''}`}>
          <GridItem className="position-info-container">
            <GridItem className="position-info-title">
              <div className="position-title">{ t('orientation') }</div>
            </GridItem>
          </GridItem>
        </div>
        <div className="position-info-content">
          <div className="orientations-container">
            <img src={IconSeite} className={this.state.orientationId === 2 ? 'orientation-selected' : ''} onClick={() => this.handleOrientationChange(2)} alt="orientation-seite" />
            <img src={IconFront} className={this.state.orientationId === 1 ? 'orientation-selected' : ''} onClick={() => this.handleOrientationChange(1)} alt="orientation-front" />
            <img src={IconBoden} className={this.state.orientationId === 3 ? 'orientation-selected' : ''} onClick={() => this.handleOrientationChange(3)} alt="orientation-boden" />
          </div>
          <div className="orientations-input">
            <InputWithIcon
              className={'filter-input'}
              startAdornment={
                <InputAdornment position="start">
                  <img
                    className="input-icon"
                    alt="tags-input-icon"
                    src={IconSearch}
                  />
                </InputAdornment>
              }
              onChange={(e) => this.handleFilterInputChange(e.target.value)}
              value={currentFilterValue}
            />
          </div>
          <div className="orientations-list">
            { data.map((el, index) => this.renderElement(el, index)) }
          </div>
          <div className="orientations-control-add">
            <Input
              className={'add-input'}
              onChange={(e) => this.handleAddInputChange(e.target.value)}
              onKeyDown={this.handleAddInputKeyDown}
              value={currentAddInputValue}
            />
            <Button className="add-btn" onClick={this.handleAdd}>{ t('add').toUpperCase() }</Button>
          </div>
        </div>
        {isRemoveClassOpen && (
          <RemoveDialog
            isOpen={isRemoveClassOpen}
            title={t('remove_class')}
            text={t('remove_class_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleRemoveClass}
          />
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    data: state.settings.materialListOrientationData,
    error: state.settings.materialListOrientationError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getData: (id, term) => dispatch(getMaterialListOrientationData(id, term)),
    onAddClass: (id, name, term) => dispatch(addMaterialListOrientationClass(id, name, term)),
    onRemoveClass: (id, typeId, term) => dispatch(removeMaterialListOrientationClass(id, typeId, term)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Orientation));
