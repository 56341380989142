export const ADD_MANUFACTURER_ORDER_POSITION = 'manufacturerOrderPositionEpic/ADD_MANUFACTURER_ORDER_POSITION';
export const ADD_MANUFACTURER_ORDER_POSITION_SUCCESS = 'manufacturerOrderPositionEpic/ADD_MANUFACTURER_ORDER_POSITION_SUCCESS';
export const ADD_MANUFACTURER_ORDER_POSITION_ERROR = 'manufacturerOrderPositionEpic/ADD_MANUFACTURER_ORDER_POSITION_ERROR';

export const UPDATE_MANUFACTURER_ORDER_POSITION = 'manufacturerOrderPositionEpic/UPDATE_MANUFACTURER_ORDER';
export const UPDATE_MANUFACTURER_ORDER_POSITION_SUCCESS = 'manufacturerOrderPositionEpic/UPDATE_MANUFACTURER_ORDER_POSITION_SUCCESS';
export const UPDATE_MANUFACTURER_ORDER_POSITION_ERROR = 'manufacturerOrderPositionEpic/UPDATE_MANUFACTURER_ORDER_POSITION_ERROR';

export const GET_MANUFACTURER_ORDER_POSITION = 'manufacturerOrderPositionEpic/GET_MANUFACTURER_ORDER_POSITION';
export const GET_MANUFACTURER_ORDER_POSITION_SUCCESS = 'manufacturerOrderPositionEpic/GET_MANUFACTURER_ORDER_POSITION_SUCCESS';
export const GET_MANUFACTURER_ORDER_POSITION_ERROR = 'manufacturerOrderPositionEpic/GET_MANUFACTURER_ORDER_POSITION_ERROR';

export const DELETE_MANUFACTURER_ORDER_POSITION = 'manufacturerOrderPositionEpic/DELETE_MANUFACTURER_ORDER_POSITION';
export const DELETE_MANUFACTURER_ORDER_POSITION_SUCCESS = 'manufacturerOrderPositionEpic/DELETE_MANUFACTURER_ORDER_POSITION_SUCCESS';
export const DELETE_MANUFACTURER_ORDER_POSITION_ERROR = 'manufacturerOrderPositionEpic/DELETE_MANUFACTURER_ORDER_POSITION_ERROR';

export const GET_ORDER_POSITION_INDEX = 'manufacturerOrderPositionEpic/GET_ORDER_POSITION_INDEX';
export const GET_ORDER_POSITION_INDEX_SUCCESS = 'manufacturerOrderPositionEpic/GET_ORDER_POSITION_INDEX_SUCCESS';
export const GET_ORDER_POSITION_INDEX_ERROR = 'manufacturerOrderPositionEpic/GET_ORDER_POSITION_INDEX_ERROR';
