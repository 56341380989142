import {
  GET_PROGRESS,
  GET_PROGRESS_SUCCESS,
  GET_PROGRESS_ERROR,
  UPDATE_PROGRESS,
  FILTER_PROGRESS
} from "../actionTypes/progressActionTypes";

export const getProgress = (id) => {
  return {type: GET_PROGRESS, payload: id}
};

export const getProgressSuccess = (progress) => {
  return {
    type: GET_PROGRESS_SUCCESS,
    payload: progress
  }
};

export const getProgressFailure = (error)  => {
  return {
    type: GET_PROGRESS_ERROR,
    payload: error
  }
};

export const filterProgress = (data)  => {
  return {
    type: FILTER_PROGRESS,
    payload: data
  }
};

export const updateProgress = (data)  => {
  return {
    type: UPDATE_PROGRESS,
    payload: data
  }
};
