/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {NavLink} from 'react-router-dom';
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import TimerUtil from 'views/Timer/TimerUtil';

// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Search from '../../assets/img/search.svg';
import KeyReturn from '../../assets/img/key_return.png';
import Bell from '../../assets/img/bell.svg';
import CloseButton from '../../assets/img/buttons/delete.svg';
import ArrowDownwardIcon from "../../assets/img/arrow-down.png";

import { logout } from 'store/actions/auth';
import './navbarLinksStyle.scss';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import ViewUser from '../../views/ViewUser';
import CreateUser from '../../views/CreateUser';

import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx';
import {debounce} from 'lodash';
import {getUser} from "../../store/actions/usersData";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      openSearch: true,
      openNotification: false,
      openProfile: false,
      isViewUserOpen: false,
      isEditUserOpen: false,
    };
  }

  componentDidMount() {
    TimerUtil.subscribe(this);
  }

  componentWillUnmount() {
    TimerUtil.unsubscribe(this);
  }

  componentDidUpdate() {
    if (this.props.resetSearch && this.props.searchFunc) {
      this.setState({ searchValue: ""});
      this.props.searchFunc(null);  // required to bring this.props.resetSearch back to false from Admin layout (Admin.jsx)
    }
  }

  handleSearchInputChange = e => {
    this.setState({ searchValue: e.target.value });
  }
  handleSearchInputKeyDown = e => {
    if (e.keyCode == 13) {
      this.performSearch();
    }
  }
  handleSearchInputKeyUp = e => {
    this.performSearch();
  }

  handleClearSearch = () => {
    this.setState({
      searchValue: ''
    })
    if(this.props.searchFunc) 
      this.props.searchFunc('');
  }
  performSearch = debounce( () => {
    // do validation of value
    const searchTerms = this.state.searchValue.trim();
    if(this.props.searchFunc)
      this.props.searchFunc(searchTerms);
  }, 250);

  handleClickNotification = () => {
    this.setState({ openNotification: !this.state.openNotification });
  };
  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  };
  handleClickProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };
  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };

  handleOpenViewUser = () => {
    this.setState({
      isViewUserOpen: true,
    });
  };

  handleCloseViewUser = () => {
    this.setState({
      isViewUserOpen: false,
    })
  };
  handleCloseEditUser = () => {
    this.setState({
      isEditUserOpen: false,
    })
  };

  handleShowEditUser = () => {
    const {getUser} = this.props;
    const userId = localStorage.getItem("authUserId");
    getUser(userId);
    this.setState({
      isEditUserOpen: true,
      isViewUserOpen: false,
    });
  };

  handleLogout = () => {
    const { logout } = this.props;
    this.setState({ openProfile: false });
    logout();
  };

  computeRenderSearch = () => {
    const excludedSearchRoutes = ['/admin/settings'];
    return excludedSearchRoutes.indexOf(window.location.pathname) === -1;
  }

  onTimerChange = () => {
    this.forceUpdate();
  }

  render() {
    const { brandText, classes, rtlActive, user, isTimerOpen, handleTimerOpen, t } = this.props;
    const { openSearch, openNotification, openProfile, isEditUserOpen, isViewUserOpen } = this.state;
    const renderSearch = this.computeRenderSearch();
    const userId = localStorage.getItem("authUserId");
    /*const searchButton =
      classes.top +
      ' ' +
      classes.searchButton +
      ' ' +
      classNames({
        [classes.searchRTL]: rtlActive
      });*/
    const dropdownItem = classNames(
      classes.dropdownItem,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
      [classes.wrapper]: true,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    const timerBtnClasses = classNames({
      [classes.timerBtn]: true,
      [classes.timerBtnActive]: TimerUtil.isRunning || TimerUtil.isPaused,
    });
    return (
      <div className={wrapper}>
        { !isTimerOpen && (
            <div className={timerBtnClasses}>
              <span className={classes.timerTitle}>{ t('timer') }</span>
              { (TimerUtil.isRunning || TimerUtil.isPaused) ? (
                <div className={classes.timerTime}>
                  <span>{ TimerUtil.msToTime(TimerUtil.time) }</span>
                </div>
              ) : (
                <div className={classes.inactiveBtn}>
                  <span>{ t('inactive') }</span>
                </div>
              ) }
              <img className={classes.openTimerBtn} onClick={handleTimerOpen} src={ArrowDownwardIcon} alt="timer-arrow-down"/>
            </div>
          )
        }
        <div className={managerClasses}>
          { renderSearch && <div className="search-outer-container search-outer-container-enter-done">
              <div className="search-icon-container">
                <Button
                  disabled
                  color="transparent"
                  justIcon
                  aria-label={ t('search') }
                  aria-owns={openNotification ? 'notification-menu-list' : null}
                  aria-haspopup="true"
                  className={`${rtlActive ? classes.buttonLinkRTL : classes.buttonLink} search-button-disabled`}
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ''
                  }}
                  buttonRef={node => {
                    this.anchorNotification = node;
                  }}
                >
                  <img
                    alt="admin-nav-bar-header-link"
                    className={
                      classes.headerLinksSvg +
                      ' ' +
                      (rtlActive
                        ? classes.links + ' ' + classes.linksRTL
                        : classes.links)
                    }
                    src={Search}
                  />
                </Button>
              </div>
              <div className="search-input-container">
                <input placeholder={brandText ? (t('search_in') + " " + t(brandText) + "...") : t('search') + "..."} onKeyDown={this.handleSearchInputKeyDown} onKeyUp={this.handleSearchInputKeyUp} onChange={this.handleSearchInputChange} value={this.state.searchValue} />
               {
                 !this.state.searchValue.trim() ?
                (<Button
                  color="transparent"
                  justIcon
                  aria-label={ t('search') }
                  aria-owns={null}
                  aria-haspopup="false"
                  onClick={this.performSearch}
                  className={(rtlActive ? classes.buttonLinkRTL : classes.buttonLink) + " button-key-return"}
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ''
                  }}
                  buttonRef={node => {
                    this.anchorNotification = node;
                  }}
                >
                  <img
                    alt="admin-nav-bar-header-link"
                    className={
                      classes.headerLinksSvg +
                      ' ' +
                      (rtlActive
                        ? classes.links + ' ' + classes.linksRTL
                        : classes.links) + ' img-key-return'
                    }
                    src={KeyReturn}
                  />
                </Button>
                ) :
                (
                <IconButton
              aria-label="close"
              className="close-btn button-key-return"
              onClick={this.handleClearSearch}
            >
              <img alt="create-user-close-btn" src={CloseButton} />
            </IconButton>
                )
            }
              </div>
            </div>
          }
        </div>

        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label={ t('Notifications') }
            aria-owns={openNotification ? 'notification-menu-list' : null}
            aria-haspopup="true"
            onClick={this.handleClickNotification}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ''
            }}
            buttonRef={node => {
              this.anchorNotification = node;
            }}
          >
            <img
              alt="admin-nav-bar-header-svg"
              className={
                classes.headerLinksSvg +
                ' ' +
                (rtlActive
                  ? classes.links + ' ' + classes.linksRTL
                  : classes.links)
              }
              src={Bell}
            />
            <span className={classes.notifications}></span>
            <Hidden smUp implementation="css">
              <span
                onClick={this.handleClickNotification}
                className={classes.linkText}
              >
                {rtlActive ? 'إعلام' : t('notification')}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openNotification}
            anchorEl={this.anchorNotification}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openNotification,
              [classes.popperResponsive]: false,
              [classes.popperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list"
                style={{ transformOrigin: '0 0 0' }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseNotification}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}
                      >
                        {rtlActive
                          ? 'إجلاء أوزار الأسيوي حين بل, كما'
                          : 'Mike John responded to your email'}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}
                      >
                        {rtlActive
                          ? 'شعار إعلان الأرضية قد ذلك'
                          : 'You have 5 new tasks'}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}
                      >
                        {rtlActive
                          ? 'ثمّة الخاصّة و على. مع جيما'
                          : "You're now friend with Andrew"}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}
                      >
                        {rtlActive ? 'قد علاقة' : 'Another Notification'}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}
                      >
                        {rtlActive ? 'قد فاتّبع' : 'Another One'}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label={ t('person') }
            justIcon
            aria-owns={openNotification ? 'profile-menu-list' : null}
            aria-haspopup="true"
            onClick={this.handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ''
            }}
            buttonRef={node => {
              this.anchorProfile = node;
            }}
          >
            {/*<Person
              className={
                classes.headerLinksSvg +
                ' ' +
                (rtlActive
                  ? classes.links + ' ' + classes.linksRTL
                  : classes.links)
              }
            />*/}
            <div className={classes.adminPerson}>
              <Person
                className={
                  classes.headerLinksSvg +
                  ' ' +
                  (rtlActive
                    ? classes.links + ' ' + classes.linksRTL
                    : classes.links)
                }
              />
            </div>
            <Hidden smUp implementation="css">
              <span
                onClick={this.handleClickProfile}
                className={classes.linkText}
              >
                {rtlActive ? 'الملف الشخصي' : t('profile')}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openProfile}
            anchorEl={this.anchorProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: false,
              [classes.popperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: '0 0 0' }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleOpenViewUser}
                        className={dropdownItem}
                      >
                        {rtlActive ? 'الملف الشخصي' : t('profile')}
                      </MenuItem>
                      <NavLink className='settings-nav-link' to="/admin/settings">
                      <MenuItem
                        onClick={this.handleCloseProfile}
                        className={dropdownItem}
                      >

                        {rtlActive ? 'الإعدادات' : t('settings')}
                      </MenuItem>
                      </NavLink>
                      <Divider light />
                      <MenuItem
                        onClick={this.handleLogout}
                        className={dropdownItem}
                      >
                        {rtlActive ? 'الخروج' : t('log_out')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {isViewUserOpen &&
          <ViewUser
            isOpen={isViewUserOpen}
            onClose={this.handleCloseViewUser}
            onEditUser={this.handleShowEditUser}
            id={userId}
          />
        }
        {isEditUserOpen && Object.keys(user).length !== 0 &&
          <CreateUser
            isOpen={isEditUserOpen}
            onClose={this.handleCloseEditUser}
          />
        }
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  brandText: PropTypes.string,
  searchFunc: PropTypes.func,
  resetSearch: PropTypes.bool,
  rtlActive: PropTypes.bool
};

const mapStateToProp = state => {
  return {
    user: state.users.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: (id) => dispatch(getUser(id)),
    logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(adminNavbarLinksStyle)(withTranslation()(HeaderLinks)));
