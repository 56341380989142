import { ProjectIssueImportanceLevel } from 'constants/constants';
import manufacturerImage1 from 'assets/img/manufacturers/manufacturer1.png';
import manufacturerImage2 from 'assets/img/manufacturers/manufacturer2.png';
import ProjectManagerImage from 'assets/img/peoplePhotos/photo1.jpg';

export const projectStatuses = [
  'New',
  'Manufacturing',
  'In Transit',
  'Delivered',
  'Declined',
  'Completed'
];

export const projectCategories = ['All Categories', 'Custom', 'Pre-built'];

export const projectManufactures = [
  'All Manufacturers',
  'Pinewood Hills Factory',
  'Old Agnes Woodworks',
  'Gerr Gerüstbauer'
];

export const projects = [
  {
    isStarred: true,
    id: 'C546587',
    name: 'Project name 1',
    manufacturer: 'Pinewood Hills Factory',
    projectManager: 'Zakke Djamboou',
    projectManagerImage: ProjectManagerImage,
    category: 'Custom',
    status: 'In Transit',
    price: 92144,
    deliveryPrice: 125,
    totalPrice: 460845,
    quantity: 5,
    customer: 'Langke Zambo',
    progress: 99,
    issue: {
      text: 'No Issues',
      importanceLevel: ProjectIssueImportanceLevel.NONE
    },
    delay: 0,
    skeletonMaterial: 'Hemlock arktique',
    skeletonColor: '#b1a396',
    bodyColor: '#b0b0b0',
    manufacturerId: 2,
    customerName: 'Jill Smith',
    customerPhone: '+90789098908775',
    deliveryAddressLine1: 'USA, Alabama',
    deliveryAddressLine2: 'New York, Abama street 34',
    residenceAddressLine1: 'Canada',
    manufacturerName: 'Pine Hill',
    manufacturerAddress: 'Bismarckstraße 101, 10625\nBerlin',
    timeToBuild: '25 days (12 September, 2019)',
    estimatedDelivery: '2 Days (14 September, 2019: My Home)',
    manufacturerImage: manufacturerImage1,
    customerFullName: 'Langke Zambo',
    deliveryAddress: 'My Home\nBismarckstraße 25\n10625 Berlin',
    billingAddress: 'Same as Shipping Address',
    paymentMethod: 'MasterCard Ending 6789\nEUGEN LAPSHIN',
    customerNotes: 'He has strong attention to details! Warning.',
    deadlineDate: '11/22/2019',
    projectCompleted: '67%'
  },
  {
    isStarred: true,
    id: 'P447789',
    name: 'Project name 2',
    manufacturer: 'Old Agnes Woodworks',
    category: 'Pre-built',
    status: 'New',
    price: 34355,
    customer: 'Akumjeli Akuchi',
    progress: 0,
    issue: {
      text: 'Rejected by Manager',
      importanceLevel: ProjectIssueImportanceLevel.HIGH
    },
    delay: 28
  },
  {
    isStarred: false,
    id: 'P587889',
    name: 'Project name 3',
    manufacturer: 'Gerr Gerüstbauer',
    category: 'Pre-built',
    status: 'New',
    price: 44628,
    customer: 'Wim Willems',
    progress: 66,
    issue: {
      text: 'Expiring Deadline',
      importanceLevel: ProjectIssueImportanceLevel.LOW
    },
    delay: 2
  },
  {
    isStarred: false,
    id: 'P587413',
    name: 'Project name 4',
    manufacturer: 'Pinewood Hills Factory',
    category: 'Pre-built',
    status: 'Delivered',
    price: 12783,
    customer: 'Thoma Fulloway',
    progress: 5,
    issue: {
      text: 'Confirmation Cancelled',
      importanceLevel: ProjectIssueImportanceLevel.HIGH
    },
    delay: 3
  },
  {
    isStarred: true,
    id: 'C787892',
    name: 'Project name 5',
    manufacturer: 'Pinewood Hills Factory',
    category: 'Custom',
    status: 'Manufacturing',
    price: 15882,
    customer: 'Mbah Enow',
    progress: 15,
    issue: {
      text: 'No Issues',
      importanceLevel: ProjectIssueImportanceLevel.NONE
    },
    delay: 0
  }
];

export const bodyMaterials = [
  'Hochlandesche',
  'Hemlock arktique',
  'Eiche Toscana',
  'Kernbuche',
  'Lärche',
  'Fichte antik',
  'Ulme'
];

export const skeletonMaterials = [
  'Hochlandesche',
  'Hemlock arktique',
  'Eiche Toscana',
  'Kernbuche',
  'Lärche',
  'Fichte antik',
  'Ulme'
];

export const handlesMaterials = ['Fichte antik', 'Plastic', 'Metal'];

const msInDay = 86400000;

export const manufacturers = [
  {
    id: 1,
    title: 'Pinewood Hills Factory',
    city: 'Hanover',
    deliveryDate: new Date(Date.now() + msInDay * 8),
    deliveryPrice: 0,
    cabinetPrice: 100,
    image: manufacturerImage1
  },
  {
    id: 2,
    title: 'Old Agnes Woodworks',
    city: 'Mannheim',
    deliveryDate: new Date(Date.now() + msInDay * 5),
    deliveryPrice: 59.99,
    cabinetPrice: 500,
    image: manufacturerImage2
  },
  {
    id: 3,
    title: 'Gerr Gerüstbauer',
    city: 'Aglasterhausen',
    deliveryDate: new Date(Date.now() + msInDay * 10),
    deliveryPrice: 10,
    cabinetPrice: 900,
    image: manufacturerImage1
  },
  {
    id: 4,
    title: 'Smith & Co Factory',
    city: 'New York',
    deliveryDate: new Date(Date.now() + msInDay * 8),
    deliveryPrice: 87,
    cabinetPrice: 346,
    image: manufacturerImage1
  },
  {
    id: 5,
    title: 'Super Wood Factory Ltd',
    city: 'London',
    deliveryDate: new Date(Date.now() + msInDay * 5),
    deliveryPrice: 59.99,
    cabinetPrice: 780,
    image: manufacturerImage2
  },
  {
    id: 6,
    title: 'Mac Ferson Factory',
    city: 'Berlin',
    deliveryDate: new Date(Date.now() + msInDay * 10),
    deliveryPrice: 78,
    cabinetPrice: 500,
    image: manufacturerImage1
  }
];
