export const GET_PRETEXT_PRESETS = 'invoicesProviders/GET_PRETEXT_PRESETS';
export const GET_PRETEXT_PRESETS_SUCCESS = 'invoicesProviders/GET_PRETEXT_PRESETS_SUCCESS';
export const GET_PRETEXT_PRESETS_ERROR = 'invoicesProviders/GET_PRETEXT_PRESETS_ERROR';

export const GET_ENDTEXT_PRESETS = 'invoicesProviders/GET_ENDTEXT_PRESETS';
export const GET_ENDTEXT_PRESETS_SUCCESS = 'invoicesProviders/GET_ENDTEXT_PRESETS_SUCCESS';
export const GET_ENDTEXT_PRESETS_ERROR = 'invoicesProviders/GET_ENDTEXT_PRESETS_ERROR';

export const GET_LAST_PRE_END_TEXT = 'invoicesProviders/GET_LAST_PRE_END_TEXT';
export const GET_LAST_PRE_END_TEXT_SUCCESS = 'invoicesProviders/GET_LAST_PRE_END_TEXT_SUCCESS';
export const GET_LAST_PRE_END_TEXT_ERROR = 'invoicesProviders/GET_LAST_PRE_END_TEXT_ERROR';

export const GET_INVOICE_TYPES_PRESETS = 'invoicesProviders/GET_INVOICE_TYPES_PRESETS';
export const GET_INVOICE_TYPES_PRESETS_SUCCESS = 'invoicesProviders/GET_INVOICE_TYPES_PRESETS_SUCCESS';
export const GET_INVOICE_TYPES_PRESETS_ERROR = 'invoicesProviders/GET_INVOICE_TYPES_PRESETS_ERROR';
