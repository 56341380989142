import {config} from '../../Config';
import { handleActions } from 'redux-actions';
import { AUTHORIZE, LOG_OUT, SET_LOGIN_ERRORS, RESET_LOGIN_ERRORS, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, RESET_FORGOT_PASSWORD_ERROR } from '../actionTypes/auth';
import {
  REGISTER_MANUFACTURER_SUCCESS,
  REGISTER_MANUFACTURER_FAILURE,
  RESET_REGISTER_DATA,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS_FAILURE,
  GET_USER_OWN_SUCCESS, GET_USER_OWN_FAILURE
} from "../actionTypes/user";

export const DEFAULT_AUTH_STATE = {
  isAuthorized: false,
  isRegistered: false,
  isProfileDetailsReceived: false,
  isForgotPasswordReady: false,
  forgotPasswordErrors: {},
  registerErrors: {},
  loginErrors: {},
  profileDetailsReceivedErrors: {},
  userData: {},
  profileData: {},
  isOwnUserDataReceived: false,
  isAdmin: false,
  userDataErrors: {}
};

export const auth = handleActions(
  {
    [AUTHORIZE]: (state, action) => ({
      ...state,
      isAuthorized: action.payload
    }),
    [LOG_OUT]: (state, action) => ({
      ...state,
      isAuthorized: false
    }),
    [SET_LOGIN_ERRORS]: (state, action) => ({
      ...state,
      loginErrors: { ...state.loginErrors, ...action.payload }
    }),
    [RESET_LOGIN_ERRORS]: (state, action) => {
      let loginErrors = { ...state.loginErrors };
      let fields = action.payload;
      fields &&
        fields.forEach(field => {
          delete loginErrors[field];
        });
      return {
        ...state,
        loginErrors
      };
    },
    [FORGOT_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      isForgotPasswordReady: true
    }),
    [FORGOT_PASSWORD_ERROR]: (state, action) => ({
      ...state,
      forgotPasswordErrors: action.payload
    }),
    [RESET_FORGOT_PASSWORD_ERROR]: (state, action) => ({
      ...state,
      isForgotPasswordReady: false,
      forgotPasswordErrors: {}
    }),
    [REGISTER_MANUFACTURER_SUCCESS]: (state, action) => ({
      ...state,
      isRegistered: true
    }),
    [REGISTER_MANUFACTURER_FAILURE]: (state, action) => ({
      ...state,
      registerErrors: action.payload
    }),
    [GET_PROFILE_DETAILS_SUCCESS]: (state, action) => {
      if (action.payload.data.logo) {
        localStorage.setItem("authManufacturerLogo", `${config.apiBaseUrl}${action.payload.data.logo.url}`);
      } else {
        localStorage.setItem("authManufacturerLogo", "none");
      }
      if (action.payload.data.user_id) {
        localStorage.setItem("authUserId", action.payload.data.user_id);
      } else {
        localStorage.setItem("authUserId", "none");
      }
      return {
        ...state,
        profileData: action.payload.data,
        isProfileDetailsReceived: true
      };
    },
    [GET_PROFILE_DETAILS_FAILURE]: (state, action) => {
      localStorage.setItem("authManufacturerLogo", "none");
      localStorage.setItem("authUserId", "none");
      return {
        ...state,
        profileDetailsReceivedErrors: action.payload
      };
    },
    [GET_USER_OWN_SUCCESS]: (state, action) => {
      return {
        ...state,
        userData: action.payload.user,
        isAdmin: action.payload.isAdmin,
        isOwnUserDataReceived: true
      }
    },
    [GET_USER_OWN_FAILURE]: (state, action) => {
      return {
        ...state,
        userDataErrors: action.payload
      }
    },
    [RESET_REGISTER_DATA]: (state, action) => ({
      ...state,
      isRegistered: false,
      registerErrors: {}
    }),
  },
  DEFAULT_AUTH_STATE
);
