import {
  GET_MANUFACTURERS_DELIVERY_TYPES,
  GET_MANUFACTURERS_DELIVERY_TYPES_SUCCESS,
  GET_MANUFACTURERS_DELIVERY_TYPES_ERROR,
  GET_MANUFACTURERS_LOCATIONS,
  GET_MANUFACTURERS_LOCATIONS_SUCCESS,
  GET_MANUFACTURERS_LOCATIONS_ERROR,
  GET_MANUFACTURERS_STATUSES,
  GET_MANUFACTURERS_STATUSES_SUCCESS,
  GET_MANUFACTURERS_STATUSES_ERROR
} from 'store/actionTypes/manufacturerProvidersActionTypes';

export const getManufacturersDeliveryTypes = () => {
  return { type: GET_MANUFACTURERS_DELIVERY_TYPES };
};

export const getManufacturersDeliveryTypeSuccess = data => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {
    type: GET_MANUFACTURERS_DELIVERY_TYPES_SUCCESS,
    payload: selectData
  };
};

export const getManufacturersDeliveryTypeFailure = error => {
  return {
    type: GET_MANUFACTURERS_DELIVERY_TYPES_ERROR,
    payload: error
  };
};

export const getManufacturersLocations = () => {
  return { type: GET_MANUFACTURERS_LOCATIONS };
};

export const getManufacturersLocationsSuccess = data => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {
    type: GET_MANUFACTURERS_LOCATIONS_SUCCESS,
    payload: selectData
  };
};

export const getManufacturersLocationsFailure = error => {
  return {
    type: GET_MANUFACTURERS_LOCATIONS_ERROR,
    payload: error
  };
};

export const getManufacturersStatuses = () => {
  return { type: GET_MANUFACTURERS_STATUSES };
};

export const getManufacturersStatusesSuccess = data => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {
    type: GET_MANUFACTURERS_STATUSES_SUCCESS,
    payload: selectData
  };
};

export const getManufacturersStatusesFailure = error => {
  return {
    type: GET_MANUFACTURERS_STATUSES_ERROR,
    payload: error
  };
};
