import { combineEpics } from 'redux-observable';
import { getProjectStatuses } from 'epics/projectStatuses';
import { getProjectCategories } from 'epics/projectCategories';
import { getProjectManufacturers } from 'epics/projectManufacturers';
import { getProjects, getSingleProject } from 'epics/projects';
import { getMaterials } from 'epics/materials';

import {
  getCoworkersEpic,
  getCoworkerEpic,
  addCoworkerEpic,
  updateCoworkerEpic,
  removeCoworkerEpic
} from './coworkersEpic';

import {
  getCustomersEpic,
  getCustomerEpic,
  addCustomerEpic,
  updateCustomerEpic,
  removeCustomerEpic,
  removeCustomersEpic,
  removeCustomersFinishEpic,
  customerImportedFileValidationEpic,
  startCustomerImportFileEpic
} from './customersEpic';

import {
  getAdminTimeEntriesEpic,
  getAdminTimeEntryEpic,
  addAdminTimeEntryEpic,
  createAdminTimeEntryFinishEpic,
  updateAdminTimeEntryEpic,
  removeAdminTimeEntryEpic,
  filterAdminTimeSheetsEpic
} from './adminTimeSheetEpic';

import {
  getCoworkerTimeEntriesEpic,
  getCoworkerTimeEntryEpic,
  addCoworkerTimeEntryEpic,
  createCoworkerTimeEntryFinishEpic,
  updateCoworkerTimeEntryEpic,
  removeCoworkerTimeEntryEpic,
  filterCoworkerTimeSheetsEpic
} from './coworkerTimeSheetEpic';

import {
  getInvoicesEpic,
  getAllInvoicesEpic,
  createInvoiceEpic,
  updateInvoiceEpic,
  deleteInvoiceEpic,
  setInvoiceStatusEpic,
  downloadInvoiceEpic,
  previewInvoiceEpic,
} from './invoicesEpic';

import {
  getPreTextPresetsEpic,
  getEndTextPresetsEpic,
  getLastPreEndTextEpic,
  getInvoiceTypesPresetsEpic,
} from './invoicesProvidersEpic';

import {
  getCategoriesEpic,
  getParentCategoriesEpic,
  getMaterialsCategoriesEpic,
  getMaterialsCategoriesProviderEpic,
  getContactsOwnCategoriesEpic,
  getOwnContactsCategoriesHierarchyEpic,
  getCategoriesTypesEpic,
  getCategoryEpic,
  addCategoryEpic,
  updateCategoryEpic,
  removeCategoryEpic,
  getChildCategoriesEpic,
} from './categoriesEpic';

import {
  getMetricsEpic,
  getCurrencyEpic,
} from './globalsEpic';

import {
  getMaterialCategoriesEpic,
  getMaterialCategoriesParentsEpic,
  getMaterialCategoryEpic,
  addMaterialCategoryEpic,
  updateMaterialCategoryEpic,
  removeMaterialCategoryEpic,
  getMaterialCategorySettingsEpic,
} from './materialCategoriesEpic';

import {
  getAllMaterialsEpic,
  getMainMaterialsListEpic,
  getPositionMaterialsListEpic,
  getFittingsMaterialsListEpic,
  getEdgeMaterialsListEpic,
  getCoveringMaterialsListEpic,
  getSurfaceMaterialsListEpic,
  getSelectedMaterialsEpic,
} from "./materialProviders";

import {
  getMaterialsEpic,
  getMaterialsForCategoryEpic,
  getMaterialsInBundlesEpic,
  updateCompoundMaterialEpic,
  updateCompoundMaterialBaseEpic,
  getMaterialEpic,
  addMaterialEpic,
  duplicateMaterialEpic,
  addCompoundMaterialEpic,
  updateMaterialEpic,
  addMaterialRequestEpic,
  updateMaterialRequestEpic,
  approveMaterialEpic,
  setMaterialAvailabilityEpic,
  startMaterialImportFileEpic,
  downloadMaterialImportSkippedEpic,
  removeMaterialEpic,
  restoreMaterialEpic,
  restoreMaterialsEpic,
  restoreMaterialsFinishEpic,
  removeMaterialsEpic,
  removeMaterialsFinishEpic,
  removeCompoundMaterialEpic,
  updateMaterialCategoryValuesEpic,
  updateMultipleMaterialsValuesEpic,
  updateMultipleMaterialsPriceEpic,
} from './materialsEpic';

import {
  getManufacturersEpic,
  getManufacturerEpic,
  removeManufacturerEpic,
  getManufacturerSettingsEpic,
  getManufacturerContactsEpic,
  addManufacturerEpic,
  updateManufacturerEpic,
  addManufacturerRequestEpic,
  updateManufacturerRequestEpic,
  setManufacturerStatusEpic,
  filterManufacturersEpic
} from './manufacturerEpic';

import {
  getManufacturerDeliveryTypesEpic,
  getManufacturerLocationsEpic,
  getManufacturerStatusesEpic
} from "./manufacturerProvidersEpic";

import {
  updateAvailabilityEpic,
  removeAvailabilityEpic
} from './manufacturerAvailabilityEpic';

import {
  getManufacturerOrdersEpic,
  getManufacturerOrderEpic,
  addManufacturerOrderEpic,
  updateManufacturerOrderEpic,
  removeManufacturerOrderEpic
} from './manufacturerOrderEpic';

import {
  getOrderPositionIndexEpic,
  getManufacturerOrderPositionEpic,
  addManufacturerOrderPositionEpic,
  updateManufacturerOrderPositionEpic,
  removeManufacturerOrderPositionEpic
} from './manufacturerOrderPositionEpic';

import {
  getNormalScheduleEpic,
  updateNormalScheduleEpic,
  getDateScheduleEpic,
  updateDateScheduleEpic,
  removeDateScheduleEpic,
  getMonthScheduleEpic
} from './manufacturerScheduleEpic';

import {
  registerManufacturerEpic,
  forgotPasswordEpic,
  getProfileDetailsEpic,
  getUserOwnEpic
} from './userEpic';

import {
  getOrdersEpic,
  getClientInquiriesEpic,
  getOrderEpic,
  getOrderSettingsEpic,
  updateOrderSettingsEpic,
  getOrderTotalCostEpic,
  createOrderEpic,
  duplicateOrderEpic,
  removeOrderEpic,
  updateOrderEpic,
  orderDetailsValidationEpic,
  orderPositionsValidationEpic,
  orderImportedPositionFileValidationEpic,
  startImportPositionFileEpic,
  startImportTaskFileEpic,
  orderPositionTaskValidationEpic,
  orderFittingsValidationEpic,
  orderCostCenterValidationEpic,
  orderPositionCalculationEpic,
  orderCostCenterCalculationEpic,
  setOrderStarredEpic,
  setOrderStatusEpic,
  setOrderDeadLineEpic,
  filterOrdersEpic,
  getOrderPositionDefaultCostCentersEpic
} from './ordersEpic';

import {
  getInquiriesEpic,
  getInquiryEpic,
  createInquiryEpic,
  updateInquiryEpic,
  removeInquiryEpic
} from './inquiriesEpic';

import {
  getOrderStatusListEpic,
  getInquiryStatusListEpic,
  getCoworkersListEpic,
  getCustomersListEpic,
  getSelectedCustomersEpic,
  getSuppliersListEpic,
  getMaterialsListEpic,
  getTypeListEpic,
  getDeliveryTypeListEpic,
  getPaymentListEpic,
  getTaskUnitListEpic,
  getCostCenterUnitListEpic,
  getCurrenciesListEpic
} from './providersEpic';

import {
  getHoursLoggedEpic,
  getOrdersListEpic,
  getPositionsListEpic,
  getTasksListEpic,
  getCostCentersListEpic,
  getCostCenterItemsListEpic,
  getPositionCostCentersListEpic,
  getPositionCostCenterItemsListEpic,
} from './timeSheetsProvidersEpic';

import {
  startTimerEpic,
  pauseTimerEpic,
  resumeTimerEpic,
  updateTimerNoteEpic,
  finalizeTimerEpic,
  deleteTimerEpic,
  getCurrentTimerEpic
} from './timerEpic';

import {
  getDefaultCostCentersEpic,
  updateDefaultCostCentersEpic,
  getMaterialsSettingsEpic,
  updateMaterialsSettingsEpic,
  getMaterialListAllowanceEpic,
  updateMaterialListAllowanceEpic,
  getMaterialListOrientationDataEpic,
  addMaterialListOrientationClassEpic,
  removeMaterialListOrientationClassEpic,
  getWebShopSettingsEpic,
  updateWebShopSettingsEpic,
  getProjectsSettingsEpic,
  updateProjectsSettingsEpic,
  getLetterheadsEpic,
  updateLetterheadsEpic,
  removeLetterheadsEpic,
} from './settingsEpic';

import {
  getUsersEpic,
  getUserEpic,
  addUserEpic,
  removeUserEpic,
  setUserStatusEpic,
  updateUserEpic,
  filterUsersEpic
} from './usersDataEpic';

import { getCountriesEpic, getCitiesEpic } from './locationsEpic';

import { getRolesEpic } from './rolesEpic';

import {
  getRequestsEpic,
  approveRequestEpic,
  rejectRequestEpic,
  filterRequestsEpic
} from './requestsEpic';

import { getLogsEpic, filterLogsEpic } from './logsEpic';

import {
  getProgressEpic,
  filterProgressEpic,
  updateProgressEpic
} from './progressEpic';

export const rootEpic = combineEpics(
  getAdminTimeEntriesEpic,
  getAdminTimeEntryEpic,
  addAdminTimeEntryEpic,
  createAdminTimeEntryFinishEpic,
  updateAdminTimeEntryEpic,
  removeAdminTimeEntryEpic,
  filterAdminTimeSheetsEpic,
  getCoworkerTimeEntriesEpic,
  getCoworkerTimeEntryEpic,
  addCoworkerTimeEntryEpic,
  createCoworkerTimeEntryFinishEpic,
  updateCoworkerTimeEntryEpic,
  removeCoworkerTimeEntryEpic,
  filterCoworkerTimeSheetsEpic,
  getInvoicesEpic,
  getAllInvoicesEpic,
  createInvoiceEpic,
  updateInvoiceEpic,
  deleteInvoiceEpic,
  setInvoiceStatusEpic,
  downloadInvoiceEpic,
  previewInvoiceEpic,
  getPreTextPresetsEpic,
  getEndTextPresetsEpic,
  getLastPreEndTextEpic,
  getInvoiceTypesPresetsEpic,
  getCoworkersEpic,
  getCoworkerEpic,
  addCoworkerEpic,
  updateCoworkerEpic,
  removeCoworkerEpic,
  getCountriesEpic,
  getCitiesEpic,
  getCustomersEpic,
  getCustomerEpic,
  addCustomerEpic,
  updateCustomerEpic,
  removeCustomerEpic,
  removeCustomersEpic,
  removeCustomersFinishEpic,
  customerImportedFileValidationEpic,
  startCustomerImportFileEpic,
  getMetricsEpic,
  getCurrencyEpic,
  getMaterialCategoriesEpic,
  getMaterialCategoriesParentsEpic,
  getMaterialCategoryEpic,
  addMaterialCategoryEpic,
  updateMaterialCategoryEpic,
  removeMaterialCategoryEpic,
  getMaterialCategorySettingsEpic,
  getMaterialsEpic,
  getMaterialsForCategoryEpic,
  getMaterialsInBundlesEpic,
  updateCompoundMaterialEpic,
  updateCompoundMaterialBaseEpic,
  getMaterialEpic,
  addMaterialEpic,
  duplicateMaterialEpic,
  addCompoundMaterialEpic,
  removeMaterialEpic,
  restoreMaterialEpic,
  restoreMaterialsEpic,
  restoreMaterialsFinishEpic,
  removeMaterialsEpic,
  removeMaterialsFinishEpic,
  removeCompoundMaterialEpic,
  updateMaterialCategoryValuesEpic,
  updateMultipleMaterialsValuesEpic,
  updateMultipleMaterialsPriceEpic,
  updateMaterialEpic,
  addMaterialRequestEpic,
  updateMaterialRequestEpic,
  approveMaterialEpic,
  setMaterialAvailabilityEpic,
  startMaterialImportFileEpic,
  downloadMaterialImportSkippedEpic,
  getAllMaterialsEpic,
  getMainMaterialsListEpic,
  getPositionMaterialsListEpic,
  getFittingsMaterialsListEpic,
  getEdgeMaterialsListEpic,
  getCoveringMaterialsListEpic,
  getSurfaceMaterialsListEpic,
  getSelectedMaterialsEpic,
  getSingleProject,
  getMaterials,
  getCategoriesEpic,
  getParentCategoriesEpic,
  getMaterialsCategoriesEpic,
  getMaterialsCategoriesProviderEpic,
  getContactsOwnCategoriesEpic,
  getOwnContactsCategoriesHierarchyEpic,
  getCategoriesTypesEpic,
  getCategoryEpic,
  addCategoryEpic,
  updateCategoryEpic,
  removeCategoryEpic,
  getChildCategoriesEpic,
  getManufacturersEpic,
  getManufacturerEpic,
  removeManufacturerEpic,
  getManufacturerSettingsEpic,
  getManufacturerContactsEpic,
  addManufacturerEpic,
  updateManufacturerEpic,
  addManufacturerRequestEpic,
  updateManufacturerRequestEpic,
  setManufacturerStatusEpic,
  filterManufacturersEpic,
  getManufacturerDeliveryTypesEpic,
  getManufacturerLocationsEpic,
  getManufacturerStatusesEpic,
  updateAvailabilityEpic,
  removeAvailabilityEpic,
  getManufacturerOrdersEpic,
  getManufacturerOrderEpic,
  addManufacturerOrderEpic,
  updateManufacturerOrderEpic,
  removeManufacturerOrderEpic,
  getOrderPositionIndexEpic,
  getManufacturerOrderPositionEpic,
  addManufacturerOrderPositionEpic,
  updateManufacturerOrderPositionEpic,
  removeManufacturerOrderPositionEpic,
  getNormalScheduleEpic,
  updateNormalScheduleEpic,
  getDateScheduleEpic,
  updateDateScheduleEpic,
  removeDateScheduleEpic,
  getMonthScheduleEpic,
  registerManufacturerEpic,
  forgotPasswordEpic,
  getProfileDetailsEpic,
  getUserOwnEpic,
  getProjectStatuses,
  getProjectCategories,
  getProjectManufacturers,
  getProjects,
  getOrdersEpic,
  getClientInquiriesEpic,
  getOrderEpic,
  getOrderSettingsEpic,
  updateOrderSettingsEpic,
  getOrderTotalCostEpic,
  createOrderEpic,
  duplicateOrderEpic,
  removeOrderEpic,
  updateOrderEpic,
  getOrderPositionDefaultCostCentersEpic,
  orderDetailsValidationEpic,
  orderPositionsValidationEpic,
  orderImportedPositionFileValidationEpic,
  startImportPositionFileEpic,
  startImportTaskFileEpic,
  orderPositionTaskValidationEpic,
  orderFittingsValidationEpic,
  orderCostCenterValidationEpic,
  orderPositionCalculationEpic,
  orderCostCenterCalculationEpic,
  setOrderStarredEpic,
  setOrderStatusEpic,
  setOrderDeadLineEpic,
  filterOrdersEpic,
  getOrderStatusListEpic,
  getInquiryStatusListEpic,
  getCoworkersListEpic,
  getCustomersListEpic,
  getSelectedCustomersEpic,
  getSuppliersListEpic,
  getTypeListEpic,
  getMaterialsListEpic,
  getDeliveryTypeListEpic,
  getPaymentListEpic,
  getTaskUnitListEpic,
  getCostCenterUnitListEpic,
  getHoursLoggedEpic,
  getOrdersListEpic,
  getPositionsListEpic,
  getTasksListEpic,
  getCostCentersListEpic,
  getCostCenterItemsListEpic,
  getPositionCostCentersListEpic,
  getPositionCostCenterItemsListEpic,
  getCurrenciesListEpic,
  startTimerEpic,
  pauseTimerEpic,
  resumeTimerEpic,
  updateTimerNoteEpic,
  finalizeTimerEpic,
  deleteTimerEpic,
  getCurrentTimerEpic,
  getDefaultCostCentersEpic,
  updateDefaultCostCentersEpic,
  getWebShopSettingsEpic,
  updateWebShopSettingsEpic,
  getProjectsSettingsEpic,
  updateProjectsSettingsEpic,
  getLetterheadsEpic,
  updateLetterheadsEpic,
  removeLetterheadsEpic,
  getMaterialsSettingsEpic,
  updateMaterialsSettingsEpic,
  getMaterialListAllowanceEpic,
  updateMaterialListAllowanceEpic,
  getMaterialListOrientationDataEpic,
  addMaterialListOrientationClassEpic,
  removeMaterialListOrientationClassEpic,
  getUsersEpic,
  getUserEpic,
  addUserEpic,
  removeUserEpic,
  setUserStatusEpic,
  updateUserEpic,
  filterUsersEpic,
  getRolesEpic,
  getLogsEpic,
  filterLogsEpic,
  getProgressEpic,
  filterProgressEpic,
  updateProgressEpic,
  getInquiriesEpic,
  getInquiryEpic,
  createInquiryEpic,
  updateInquiryEpic,
  removeInquiryEpic,
  getRequestsEpic,
  approveRequestEpic,
  rejectRequestEpic,
  filterRequestsEpic
);
