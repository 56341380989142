import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_SUCCESS,
  GET_ALL_INVOICES_ERROR,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_ERROR,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  SET_INVOICE_STATUS,
  SET_INVOICE_STATUS_SUCCESS,
  SET_INVOICE_STATUS_ERROR,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_ERROR,
  PREVIEW_INVOICE,
  PREVIEW_INVOICE_SUCCESS,
  PREVIEW_INVOICE_ERROR,
  RESET_INVOICE_DATA,
} from '../actionTypes/invoicesActionTypes';

export const getInvoices = (order_id, searchTerms, filters, sortBy, sortByOrder) => {
  return {type: GET_INVOICES, payload: { order_id, searchTerms, filters, sortBy, sortByOrder }};
};

export const getInvoicesSuccess = (data) => {
  return {type: GET_INVOICES_SUCCESS, payload: data};
};

export const getInvoicesFailure = (error) => {
  return {type: GET_INVOICES_ERROR, payload: error};
};

export const getAllInvoices = (searchTerms, filters, sortBy, sortByOrder) => {
  return {type: GET_ALL_INVOICES, payload: { searchTerms, filters, sortBy, sortByOrder }};
};

export const getAllInvoicesSuccess = (data) => {
  return {type: GET_ALL_INVOICES_SUCCESS, payload: data};
};

export const getAllInvoicesFailure = (error) => {
  return {type: GET_ALL_INVOICES_ERROR, payload: error};
};

export const createInvoice = (orderId, data, searchTerms, filters, sortBy, sortByOrder) => {
  return {type: CREATE_INVOICE, payload: { orderId, data, loadListInfo: { searchTerms, filters, sortBy, sortByOrder } }};
};

export const createInvoiceSuccess = (data) => {
  return {type: CREATE_INVOICE_SUCCESS, payload: data};
};

export const createInvoiceFailure = (error) => {
  return {type: CREATE_INVOICE_ERROR, payload: error};
};

export const updateInvoice = (orderId, invoiceId, data, searchTerms, filters, sortBy, sortByOrder) => {
  return {type: UPDATE_INVOICE, payload: { orderId, invoiceId, data, loadListInfo: { searchTerms, filters, sortBy, sortByOrder } }};
};

export const updateInvoiceSuccess = (data) => {
  return {type: UPDATE_INVOICE_SUCCESS, payload: data};
};

export const updateInvoiceFailure = (error) => {
  return {type: UPDATE_INVOICE_ERROR, payload: error};
};

export const deleteInvoice = (invoiceId, orderId, searchTerms, filters, sortBy, sortByOrder) => {
  return {type: DELETE_INVOICE, payload: { invoiceId, orderId, loadListInfo: { searchTerms, filters, sortBy, sortByOrder } }};
};

export const deleteInvoiceSuccess = () => {
  return {type: DELETE_INVOICE_SUCCESS};
};

export const deleteInvoiceFailure = (error) => {
  return {type: DELETE_INVOICE_ERROR, payload: error};
};

export const setInvoiceStatus = (invoiceId, orderId, statusId, searchTerms, filters, sortBy, sortByOrder) => {
  return {type: SET_INVOICE_STATUS, payload: { invoiceId, orderId, statusId, loadListInfo: { searchTerms, filters, sortBy, sortByOrder } }};
};

export const setInvoiceStatusSuccess = (data) => {
  return {type: SET_INVOICE_STATUS_SUCCESS, payload: data};
};

export const setInvoiceStatusFailure = (error) => {
  return {type: SET_INVOICE_STATUS_ERROR, payload: error};
};

export const downloadInvoice = (id, format, filename) => {
  return {type: DOWNLOAD_INVOICE, payload: { id, format, filename }};
};

export const downloadInvoiceSuccess = (blob) => {
  return {type: DOWNLOAD_INVOICE_SUCCESS, payload: blob};
};

export const downloadInvoiceFailure = (error) => {
  return {type: DOWNLOAD_INVOICE_ERROR, payload: error};
};

export const previewInvoice = (data) => {
  return {type: PREVIEW_INVOICE, payload: { data }};
};

export const previewInvoiceSuccess = (blob) => {
  return {type: PREVIEW_INVOICE_SUCCESS, payload: blob};
};

export const previewInvoiceFailure = (error) => {
  return {type: PREVIEW_INVOICE_ERROR, payload: error};
};

export const resetInvoiceData = () => {
  return {type: RESET_INVOICE_DATA, payload: null};
};
