import React from "react";
import { withTranslation } from 'react-i18next';

import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';

class LocalStorageDialog extends React.Component {
  handleClose = () => {
    const {onClose} = this.props;
    localStorage.removeItem('orderData');
    onClose()
  }
  render() {
    const { isOpen, isOrder, onClose, onRestoreOrder, t } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="local-storage-dialog-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ isOrder ? t('restore_order') : t('restore_inquiry') }</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="file-import-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="local-storage-dialog-content">
          { isOrder ? t('have_not_completed_order') : t('have_not_completed_inquiry') }
        </div>
        <div className="local-storage-dialog-btn-container">
          <Button
            className="cancel-btn"
            onClick={() => this.handleClose()}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={onRestoreOrder}
          >
            { t('restore').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(LocalStorageDialog);
