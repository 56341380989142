import BaseAPIService from './Base';

export default class LocationsAPIService extends BaseAPIService {
  getCountries = async () => {
    return this.request('api/location/provider/countries').then((data) => {
      return data;
    });
  };

  getCities = async (countryId, searchTerm) => {
    return this.request(`api/location/provider/${countryId}/cities?term=${searchTerm}`).then((data) => {
      return data;
    });
  };
}
