import React from 'react';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import GridContainer from 'components/Grid/GridContainer';

import Cost from './Cost';

import './style.scss';

class CostCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  moveCard = (res) => {
    const {data, onCostMoveChange} = this.props;
    const newData = [...data];
    data.forEach((item, index) => {
      if (index === res.source.index) {
        newData[index].sort_no = res.destination.index  +   1
      }
      if (res.source.index < res.destination.index) {
        if (index > res.source.index && index <= res.destination.index) {
          newData[index].sort_no = newData[index].sort_no - 1
        }
      } else {
        if (index < res.source.index && index >= res.destination.index) {
          newData[index].sort_no = newData[index].sort_no + 1
        }
      }
    });
    onCostMoveChange(newData)
  }

  getCostErrors = (index) => {
    const { errors, specialErrors } = this.props;
    if (errors && errors[index])
      return errors[index];
    else if (specialErrors && specialErrors[index])
      return specialErrors[index];
    return null;
  }

  render() {
    const {
      data,
      costCenterFilter,
      costCenterUnits,
      onDataChange,
      onRemovePosition,
      currencySymbol,
    } = this.props;
    data.sort((a, b) => {return a.sort_no - b.sort_no});
    return (
      <GridContainer className="cost-center-container">
        <DragDropContext onDragEnd={this.moveCard}>
          <Droppable droppableId="droppable">
            { (provided, snapshot) => (
              <div
                className="cost-center-list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  data.length !== 0 && data.map((item, index) => {
                    switch (costCenterFilter) {
                      case 0:
                        return (
                          <Draggable
                            key={`item-${index}-${item.created_at}`}
                            draggableId={item.sort_no.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Cost
                                  errors={this.getCostErrors(index)}
                                  data={item}
                                  id={index}
                                  isCardOpen={index === 0}
                                  costCenterUnits={costCenterUnits}
                                  onRemovePosition={() => onRemovePosition(item.sort_no)}
                                  onChange={(data) => onDataChange(index, data)}
                                  currencySymbol={currencySymbol}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      case 1:
                        if (item.type === 1) {
                          return (
                            <Draggable
                              key={item.sort_no.toString()}
                              draggableId={item.sort_no.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Cost
                                    key={index}
                                    errors={this.getCostErrors(index)}
                                    isCardOpen={index === 0}
                                    data={item}
                                    costCenterUnits={costCenterUnits}
                                    onChange={(data) => onDataChange(index, data)}
                                    currencySymbol={currencySymbol}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        }
                        break;
                      case 2:
                        if (item.type === 2) {
                          return (
                            <Draggable
                              key={item.sort_no.toString()}
                              draggableId={item.sort_no.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Cost
                                    key={index}
                                    errors={this.getCostErrors(index)}
                                    isCardOpen={index === 0}
                                    data={item}
                                    costCenterUnits={costCenterUnits}
                                    onChange={(data) => onDataChange(index, data)}
                                    currencySymbol={currencySymbol}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        }
                        break;
                      default:
                        return null
                    }
                  })
                }
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </GridContainer>
    )
  }
}

export default CostCenter;
