import {
  DELETE_MANUFACTURER_AVAILABILITY,
  DELETE_MANUFACTURER_AVAILABILITY_SUCCESS,
  DELETE_MANUFACTURER_AVAILABILITY_ERROR,
  UPDATE_MANUFACTURER_AVAILABILITY,
  UPDATE_MANUFACTURER_AVAILABILITY_SUCCESS,
  UPDATE_MANUFACTURER_AVAILABILITY_ERROR
} from '../actionTypes/manufacturerAvailabilityActionTypes';

import NotificationService from '../../services/NotificationService';

export const deleteManufacturerAvailability = (id) => {
  return {type: DELETE_MANUFACTURER_AVAILABILITY, payload: id}
};

export const deleteManufacturerAvailabilitySuccess = () => {
  NotificationService.success('Manufacturer availability deleted successfully');
  return {type: DELETE_MANUFACTURER_AVAILABILITY_SUCCESS}
};

export const deleteManufacturerAvailabilityFailure = (error) => {
  NotificationService.error(error);
  return {type: DELETE_MANUFACTURER_AVAILABILITY_ERROR, payload: error}
};

export const updateManufacturerAvailability = ({id, data}) => {
  return {type: UPDATE_MANUFACTURER_AVAILABILITY, payload: {id, data}}
};

export const updateManufacturerAvailabilitySuccess = () => {
  NotificationService.success('Manufacturer availability updated successfully');
  return {type: UPDATE_MANUFACTURER_AVAILABILITY_SUCCESS}
};

export const updateManufacturerAvailabilityFailure = (error) => {
  NotificationService.error(error);
  return {type: UPDATE_MANUFACTURER_AVAILABILITY_ERROR, payload: error}
};
