import React from "react";
import { withTranslation } from 'react-i18next';

import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';

class ImportErrorDialog extends React.Component {
  render() {
    const { isOpen, error, onClose, t } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="import-error-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ t('file_import') }</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="import-error-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="import-error-content">
          {error && typeof error === 'string' ? error : error.file && error.file[0]}
        </div>
        <div className="import-error-btn-container">
          <Button
            className="ok-btn"
            onClick={() => onClose()}
          >
            { t('ok') }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ImportErrorDialog);
