import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ProjectTabs from 'components/ProjectTabs/ProjectTabs';

import { DayNames } from 'constants/constants';

import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import './style.scss';

class TimesheetsOverview extends React.Component {

  static PieChartColors = [
    "#f84141",
    "#0acf05",
    "#fdb136",
    "#3d73dd",
    "#c758d0",
    "#ff7300",
    "#d5f30b",
    "#7cdddd",
    "#8399eb",
  ];
  
  constructor(props) {
    super(props);
    this.state = {
      isTimerOpen: false,
      isManualTimerOpen: false,
      selectedPieChartTypeId: 0,
      barChartData: null,
      pieChartData: null,
    };
  }

  componentDidMount() {
    const stateUpdate = {
      pieChartData: [
        { name: 'A2', value: 300, color: 'green' },
        { name: 'B1', value: 100, color: 'blue' },
        { name: 'B2', value: 80, color: 'pink' },
        { name: 'B3', value: 40, color: 'yellow' },
        { name: 'B4', value: 30, color: 'grey' },
        { name: 'B5', value: 50, color: 'cyan' },
        { name: 'C1', value: 100, color: 'magenta' },
        { name: 'C2', value: 200, color: 'brown' },
        { name: 'D1', value: 150, color: 'purple' },
      ],
    };
    if (this.props.hoursBreakdown) {
      stateUpdate['barChartData'] = this.createBarChartEntries();
    }
    this.setState(stateUpdate);
  }

  createBarChartEntries = () => {
    let barChartEntries = null;
    if (this.props.hoursBreakdown.length == 7) {
      barChartEntries = this.props.hoursBreakdown.map((el, index) => {
        return {
          name: this.props.t(DayNames[index]),
          value: el.time_spent,
        };
      });
    } else {
      barChartEntries = this.props.hoursBreakdown.map(el => {
        return {
          name: parseInt(el.entry_date.split('-')[2], 10).toString(),
          value: el.time_spent,
        };
      });
    }
    return barChartEntries;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hoursBreakdown !== this.props.hoursBreakdown && this.props.hoursBreakdown && this.props.t) {
      this.setState({
        barChartData: this.createBarChartEntries(),
      });
    }
  }

  handleViewDetailsButton = () => {
    if (this.props.onViewDetails)
      this.props.onViewDetails();
  }

  onPieChartTypeChange = (id) => {
    this.setState({
      selectedPieChartTypeId: id,
    });
  }

  render() {
    const { t } = this.props;
    const { selectedPieChartTypeId } = this.state;
    const PieChartTypes = [
      { id: 0, value: t('orders') },
      { id: 1, value: t('tasks') },
    ];
    
    const pieChartLabelRenderer = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 1.675;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
      return (
        <>
          <text x={x} y={y - 7} textAnchor={'middle'} dominantBaseline="central">
            <tspan fontWeight="bold" fill={TimesheetsOverview.PieChartColors[index]}>{ `${this.state.pieChartData[index].name}` }</tspan>
          </text>
          <text x={x} y={y + 7} textAnchor={'middle'} dominantBaseline="central">
            <tspan fill="#505665">{ `${this.state.pieChartData[index].value}` }</tspan>
          </text>
        </>
      );
    }
    return (
      <GridContainer className="project-timesheets-container" direction="row">
        <GridItem className="project-timesheets-top" xs={12}>
          <GridItem xs={3}>

          </GridItem>
          <GridItem className="timesheets-top-right-bar">
            <Button
              className="add-time-btn"
              onClick={this.props.onLogTime}
            >
              + { t('log_time').toUpperCase() }
            </Button>
          </GridItem>
        </GridItem>
        <GridContainer
          className="timesheets-overview-content"
          direction="row"
        >
          <GridItem className="timesheets-chart">
            <GridContainer
              direction="row"
              className="timesheets-chart-header"
              xs={12}
            >
              <GridItem className="timesheets-title" xs={6}>{ t('total_hours_logged') }</GridItem>
              <GridItem className="timesheets-title" xs={6}>
                <Button
                  className="view-details-btn"
                  onClick={this.handleViewDetailsButton}
                >
                  { t('view_details') }
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer className="timesheets-chart-content">
              <ResponsiveContainer width="100%" height={350}>
                <BarChart
                  data={this.state.barChartData}
                  barCategoryGap={this.state.barChartData && this.state.barChartData.length == 7 ? '20%' : '10%'}
                  margin={{ left: -15, }}
                  isAnimationActive={false}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Bar dataKey="value" fill="#628ee3" />
                </BarChart>
              </ResponsiveContainer>
            </GridContainer>
          </GridItem>
          <GridItem className="timesheets-chart">
            <GridContainer
              direction="row"
              className="timesheets-chart-header"
              xs={12}
            >
              <GridItem className="timesheets-title" xs={6}>{ t('top_orders') }</GridItem>
              <GridItem className="timesheets-title" xs={6}>
                <ProjectTabs
                  tabs={PieChartTypes}
                  selectedTab={selectedPieChartTypeId}
                  onTabItemClick={this.onPieChartTypeChange}
                />
              </GridItem>
            </GridContainer>
            <GridContainer className="timesheets-chart-content">
              <ResponsiveContainer width="100%" height={350}>
                <PieChart>
                  <Pie
                    data={this.state.pieChartData}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={'61.5%'}
                    outerRadius={'82%'}
                    labelLine={false}
                    label={pieChartLabelRenderer}
                    isAnimationActive={false}
                  >
                    {this.state.pieChartData && this.state.pieChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={TimesheetsOverview.PieChartColors[index]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </GridContainer>
          </GridItem>
        </GridContainer>
        { /* if data is not yet ready
        <div className="ikt-ap_loader">
          <Loader />
        </div>
        */}
      </GridContainer>
    )
  }
}

const mapStateToProp = state => {
  return {
    hoursBreakdown: state.timeSheetsProviders.hoursBreakdown,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(TimesheetsOverview));
