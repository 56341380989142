import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { globalsAPI } from 'services/API';

import {
  GET_METRICS,
  GET_CURRENCY,
} from '../store/actionTypes/globalsActionTypes';

import {
  getMetricsSuccess,
  getMetricsFailure,
  getCurrencySuccess,
  getCurrencyFailure,
} from '../store/actions/globals';

export const getMetricsEpic = actions$ =>
  actions$.pipe(
    ofType(GET_METRICS),
    switchMap(() =>
      from(globalsAPI.getMetrics()).pipe(
        switchMap(response => {
          return of(getMetricsSuccess(response));
        }),
        catchError(err => {
          return of(getMetricsFailure(err));
        })
      )
    )
  );

export const getCurrencyEpic = actions$ =>
  actions$.pipe(
    ofType(GET_CURRENCY),
    switchMap(() =>
      from(globalsAPI.getCurrency()).pipe(
        switchMap(response => {
          return of(getCurrencySuccess(response));
        }),
        catchError(err => {
          return of(getCurrencyFailure(err));
        })
      )
    )
  );
