import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { mapTo, mergeMap, catchError, delay } from 'rxjs/operators';
import {
  getProjectsSuccessful,
  getProjectsFailed,
  getSingleProjectSuccessful,
  getSingleProjectFailed
} from 'store/actions/projects';
import { GET_PROJECTS, GET_SINGLE_PROJECT } from 'store/actionTypes/projects';
import { projects as dummyProjects } from './epicsData';
import { deepClone } from 'helpers/deepCloneHelper';

export const getProjects = actions$ => {
  return actions$.pipe(
    ofType(GET_PROJECTS),
    delay(2000),
    mapTo(getProjectsSuccessful(dummyProjects)),
    catchError((error, caught) => merge(of(getProjectsFailed(error)), caught))
  );
};

export const getSingleProject = actions$ => {
  return actions$.pipe(
    ofType(GET_SINGLE_PROJECT),
    delay(2000),
    mergeMap(({ payload }) => {
      const projectId = payload;
      let project = dummyProjects.find(currProject => {
        return currProject.id === projectId;
      });
      project = deepClone(project);
      return [getSingleProjectSuccessful(project)];
    }),
    catchError((error, caught) =>
      merge(of(getSingleProjectFailed(error)), caught)
    )
  );
};
