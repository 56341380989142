import React from 'react';
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import {
  getManufacturer,
  getManufacturerSettings, removeManufacturer, resetManufacturerData,
  setManufacturerStatus
} from 'store/actions/manufacturer';
import {getManufacturersStatuses} from "../../store/actions/manufacturerProviders";
import {forgotPassword, resetForgotPasswordError} from "../../store/actions/auth";

import './style.scss';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Loader from 'components/Loader/Loader';
import Button from "../../components/CustomButtons/Button";
import {Link} from "react-router-dom";
import ProjectTabs from "../../components/ProjectTabs/ProjectTabs";
import Calendar from "../../components/Calendar/index";
import ContactCard from "./ContactPersonCard";
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import ResetPasswordDialog from "../ViewUser/PasswordResetDialog";
import {config} from "../../Config";
import CreateManufacturer from "../CreateManufacturer";
import RemoveDialog from "../RemoveDialog";

import { formatNumericValue } from "utils/utils";

class ManufacturerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarSelectedTab: 0,
      isResetDialogOpen: false,
      isEditManufacturerOpen: false,
      isRemoveManufacturerOpen: false
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getManufacturer(this.props.match.params.id);
      this.props.getManufacturerSettings(this.props.match.params.id);
      this.props.getManufacturersStatuses();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isManufacturerDeleted === false && this.props.isManufacturerDeleted) {
      this.props.history.push("/admin/manufacturers")
    }
    if (prevProps.auth.isForgotPasswordReady === false && this.props.auth.isForgotPasswordReady) {
      this.setState(
        {isResetDialogOpen: true}
      )
    }
  }

  componentWillUnmount() {
    const {resetData} = this.props;
    resetData()
  }

  isDataReady = () => {
    const {
      manufacturer,
      manufacturerSettings,
      manufacturerStatuses
    } = this.props;
    return Object.keys(manufacturer).length !== 0 && manufacturerSettings.length !== 0 && manufacturerStatuses.length !== 0
  };

  handleCalendarTabItemClick = id => {
    this.setState({
      calendarSelectedTab: id
    });
  };

  handleResetPassword = () => {
    const {
      manufacturer,
      forgotPassword
    } = this.props;
    const data = {email: manufacturer.user ? manufacturer.user.email : ''};
    forgotPassword(JSON.stringify(data))
  };

  handleResetDialogClose = () => {
    const {resetForgotPasswordError} = this.props;
    resetForgotPasswordError();
    this.setState({
      isResetDialogOpen: false
    })
  };

  handleDeleteButton = () => {
    this.setState({
      isRemoveManufacturerOpen: true,
    });
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveManufacturerOpen: false,
    });
  };

  handleRemoveManufacturer = () => {
    const {
      manufacturer,
      removeManufacturer
    } = this.props;
    removeManufacturer(manufacturer.id, { page: 0, searchTerms: this.props.searchTerms });
    this.handleRemoveDialogClose()
  }

  handleEditManufacturerOpen = () => {
    const {
      getManufacturer,
      manufacturer
    } = this.props;
    getManufacturer(manufacturer.id)
    this.setState({
      isEditManufacturerOpen: true
    })
  }

  handleEditManufacturerClose = () => {
    this.setState({
      isEditManufacturerOpen: false
    })
  }

  render() {
    const {
      manufacturer,
      manufacturerSettings,
      manufacturerStatuses,
      t
    } = this.props;
    const {
      calendarSelectedTab,
      isResetDialogOpen,
      isEditManufacturerOpen,
      isRemoveManufacturerOpen
    } = this.state;
    let delivery = '';
    manufacturer.delivery && manufacturer.delivery.map((item, index) => (delivery + (index === 0 ? `${item}` : `, ${item}`)));
    let settings = {};
    manufacturerSettings.map(item => settings[item.key] = item);
    const machineHours = manufacturerSettings.length !== 0 ? +settings.corpus_machine_work_hours_per_m2.value + +settings.back_machine_work_hours_per_m2.value +  +settings.front_machine_work_hours_per_m2.value + +settings.drawers_machine_work_hours_per_m2.value : '';
    const machinePrice = manufacturerSettings.length !== 0 ? formatNumericValue(+machineHours * (+settings.corpus_machine_work_hourly_rate.value + +settings.back_machine_work_hourly_rate.value +  +settings.front_machine_work_hourly_rate.value + +settings.drawers_machine_work_hourly_rate.value), 2) : '';
    const manualHours = manufacturerSettings.length !== 0 ? +settings.corpus_manual_work_hours_per_m2.value + +settings.back_manual_work_hours_per_m2.value +  +settings.front_manual_work_hours_per_m2.value + +settings.drawers_manual_work_hours_per_m2.value : '';
    const manualPrice = manufacturerSettings.length !== 0 ? formatNumericValue(+manualHours * (+settings.corpus_manual_work_hourly_rate.value + +settings.back_manual_work_hourly_rate.value +  +settings.front_manual_work_hourly_rate.value + +settings.drawers_manual_work_hourly_rate.value), 2) : '';
    const extraCharges = manufacturerSettings.length !== 0 ? +settings.extra_charge_for_small_materials.value + +settings.extra_charge_for_materials.value : 0;
    return (
      <GridContainer className="manufacturer-details-container">
        {
          this.isDataReady()
            ? (
              <GridContainer>
                <GridItem className="manufacturer-details-top-container">
                  <GridItem className="manufacturer-details-top-left">
                    <div>{ t('ongoing_inquiries') }</div>
                    <div className="inquiries-count">{manufacturer.inquiries_count ? manufacturer.inquiries_count : 0}</div>
                  </GridItem>
                  <GridItem className="manufacturer-details-top-right">
                    <div className={`status-dropdown ${manufacturer.status === 0 ? 'red' : 'green'}`}>
                      <Dropdown
                        buttonText={manufacturer.status}
                        buttonIconType="white"
                        buttonProps={{className: 'dropdown-blue-style status-dropdown', round: true, size: "sm"}}
                        hoverColor="dark"
                        dropdownList={manufacturerStatuses}
                        onClick={data => this.props.setManufacturerStatus(manufacturer.id, {value: data.id})}
                      />
                    </div>
                    <Button
                      className="manufacturer-details-btn delete-manufacturer-btn"
                      onClick={this.handleDeleteButton}
                    >
                      { t('delete').toUpperCase() }
                    </Button>
                    <Button
                      className="manufacturer-details-btn"
                      onClick={this.handleEditManufacturerOpen}
                    >
                      { t('edit').toUpperCase() }
                    </Button>
                  </GridItem>
                </GridItem>
                <GridItem className="manufacturer-details-content">
                  <GridItem className="manufacturer-details-card">
                    <GridItem className="manufacturer-details-card-title" xs={12}>
                      <span>{ t('manufacturer_details') }</span>
                    </GridItem>
                    <GridItem className="manufacturer-details-card-content">
                      <div className="manufacturer-information">
                        <div className="company-info">
                          <div className="company-logo">
                            {manufacturer.logo ? <img src={`${config.apiBaseUrl}${manufacturer.logo.url}`}/> : <div/>}
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('company_name') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.name  ? manufacturer.name : ''}</GridItem>
                          </div>
                          {
                            manufacturer.contacts.length !== 0 &&
                            <ContactCard
                              data={manufacturer.contacts[0]}
                            />
                          }
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('website') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.website ? manufacturer.website : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('address') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.address ? manufacturer.address : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('city') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.city ? manufacturer.city : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('phone_number') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.phone_no ? manufacturer.phone_no : ''}</GridItem>
                          </div>
                        </div>
                        <div className="manufacturer-info">
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('account_email') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.user ? manufacturer.user.email : ''}</GridItem>
                          </div>
                          <div
                            className="reset-password"
                            onClick={this.handleResetPassword}
                          >
                            { t('reset_password').toUpperCase() }
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title bold">{ t('machine_work') }</GridItem>
                            <GridItem xs={7} />
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('hours_needed_per_m') }</GridItem>
                            <GridItem xs={7} className="info">{machineHours}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('price_per_m') }</GridItem>
                            <GridItem xs={7} className="info">{machinePrice}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title bold">{ t('manual_work') }</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('manual_work_hours_needed_per_m') }</GridItem>
                            <GridItem xs={7} className="info">{manualHours}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('price_per_m') }</GridItem>
                            <GridItem xs={7} className="info">{manualPrice}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('extra_charges') }</GridItem>
                            <GridItem xs={7} className="info">{ extraCharges }</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('delivery') }</GridItem>
                            <GridItem xs={7} className="info">{delivery}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('working_hours_monday_friday') }</GridItem>
                            <GridItem
                              className="info"
                              xs={7}
                            >
                              {`${manufacturer.schedule.time_from} - ${manufacturer.schedule.time_to}`}
                            </GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('legal_registration_name') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.legal_name ? manufacturer.legal_name : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('billing_address') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.billing_address ? manufacturer.billing_address : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('tax_number') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.tax_no ? manufacturer.tax_no : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('eu_vat_registration_number') }</GridItem>
                            <GridItem xs={7} className="info">{manufacturer.eu_vat_no ? manufacturer.eu_vat_no : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('risk_and_profit') }</GridItem>
                            <GridItem xs={7} className="info">{settings.material_profit ? ` ${settings.material_profit.value}%` : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('special_sales') }</GridItem>
                            <GridItem xs={7} className="info">{settings.special_sales ? ` ${settings.special_sales.value}%` : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('waste_material') }</GridItem>
                            <GridItem xs={7} className="info">{settings.waste ? ` ${settings.waste.value}%` : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('vat') }</GridItem>
                            <GridItem xs={7} className="info">{settings.vat ? ` ${settings.vat.value}%` : ''}</GridItem>
                          </div>
                          <div className="manufacturer-info-item">
                            <GridItem xs={5} className="info-title">{ t('discount') }</GridItem>
                            <GridItem xs={7} className="info">{settings.discount ? ` ${settings.discount.value}%` : ''}</GridItem>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridItem>
                 {/* <GridItem className="manufacturer-details-card">
                    <GridItem className="manufacturer-details-card-title" xs={12}>
                      <span>Availability Calendar</span>
                    </GridItem>
                    <GridItem className="manufacturer-details-card-content">
                      <GridItem className="calendar-top-container">
                        <ProjectTabs
                          tabs={
                            [{id: 0, value: 'Week'}, {id: 1, value: 'Month'}]
                          }
                          selectedTab={calendarSelectedTab}
                          onTabItemClick={this.handleCalendarTabItemClick}
                        />
                        <div>

                        </div>
                      </GridItem>
                      <GridItem className="manufacturer-calendar-container">
                        <Calendar/>
                      </GridItem>
                    </GridItem>
                  </GridItem>*/}
                </GridItem>
                {
                  isResetDialogOpen &&
                  <ResetPasswordDialog
                    isOpen={isResetDialogOpen}
                    email={manufacturer.user.email}
                    onClose={this.handleResetDialogClose}
                  />
                }
                {
                  isEditManufacturerOpen &&
                  <CreateManufacturer isOpen={isEditManufacturerOpen} isDetailsPage={true} onClose={this.handleEditManufacturerClose} />
                }
                {isRemoveManufacturerOpen && (
                  <RemoveDialog
                    isOpen={isRemoveManufacturerOpen}
                    title={t('remove_manufacturer')}
                    text={t('remove_manufacturer_confirmation')}
                    onClose={this.handleRemoveDialogClose}
                    onRemove={this.handleRemoveManufacturer}
                  />
                )}
              </GridContainer>
            )
            : (
              <div className="ikt-ap_loader">
                <Loader />
              </div>
            )
        }
      </GridContainer>
    )
  }
}

const mapStateToProp = state => {
  return {
    auth: state.auth,
    manufacturer: state.manufacturer.manufacturer,
    isManufacturerDeleted: state.manufacturer.isManufacturerDeleted,
    manufacturerSettings: state.manufacturer.manufacturerSettings,
    manufacturerStatuses: state.manufacturerProviders.manufacturerStatuses,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getManufacturer: (id) => dispatch(getManufacturer(id)),
    removeManufacturer: (id, loadListInfo) => dispatch(removeManufacturer(id, loadListInfo)),
    getManufacturerSettings: (id) => dispatch(getManufacturerSettings(id)),
    getManufacturersStatuses: () => dispatch(getManufacturersStatuses()),
    setManufacturerStatus: (id, data) => dispatch(setManufacturerStatus(id, data)),
    forgotPassword: data => dispatch(forgotPassword(data)),
    resetForgotPasswordError: () => dispatch(resetForgotPasswordError()),
    resetData: () => dispatch(resetManufacturerData())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ManufacturerDetails));
