import BaseAPIService from './Base';

export default class UsersAPIService extends BaseAPIService {
  createUser = async (data) => {
    return this.request('api/manufacturers/users', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  setUserStatus = async (id, value) => {
    return this.request(`api/manufacturers/users/${id}/status/${value}`, {
      method: 'PUT'
    });
  }

  updateUser = async (id, data) => {
    return this.request(`api/manufacturers/users/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  deleteUser = async (id) => {
    return this.request(`api/manufacturers/users/${id}`, {
      method: 'DELETE',
    });
  };

  getUser = async (id) => {
    return this.request(`api/manufacturers/users/${id}`).then((data) => {
      return data;
    });
  };

  getUsers = async (page, searchTerms) => {
    return this.request(`api/manufacturers/users?page=${page ? page : 1}${searchTerms ? ('&search-term=' + searchTerms) : ''}`).then((data) => {
      return data;
    });
  };

  filterUsers = async (filters, searchTerms) => {
    let filterRole = [];
    if (filters.role.length > 0 && filters.role[0] != -1) {
      for (let i in filters.role) {
        filterRole.push(`filter[role_id][${i}]=${filters.role[i]}`);
      }
    }
    const filterRolePart = filterRole.length > 0 ? `&${filterRole.join("&")}` : '';
    let url = `?${filters.page !== '' ? `page=${filters.page}` : '?'}${filters.order !== '' ? `&order=${filters.orderDir ? '' : '-'}${filters.order}` : ''}${filterRolePart}${filters.status !== '' ? `&filter[status]=${filters.status}` : ''}${searchTerms ? ('&search-term=' + searchTerms) : ''}`;
    return this.request(`api/manufacturers/users${url}`).then((data) => {
      return data;
    });
  };
}
