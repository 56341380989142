import BaseAPIService from './Base';

export default class ProvidersAPIService extends BaseAPIService {
  getOrderStatusList = async () => {
    const url = 'api/orders/provider/order-status';
    return this.request(url).then((data) => {
      return data;
    });
  };

  getInquiryStatusList = async () => {
    const url = 'api/orders/provider/inquire-status';
    return this.request(url).then((data) => {
      return data;
    });
  };

  getCoworkersList = async (id, term) => {
    if (!id) {
      id = "own";
    }
    return this.request(`api/manufacturers/provider/${id}/coworkers${term ? `?term=${term}` : ''}`).then((data) => {
      return data;
    });
  };

  getSelectedCustomers = async (ids) => {
    const queryPart = ids.map(el => (`selected_ids[]=${el}`)).join('&');
    return this.request(`api/manufacturers/provider/own/customers/only-selected?${queryPart}`).then((data) => {
      return data;
    });
  };

  getCustomersList = async (term, excludeSuppliers) => {
    let query = `?include-suppliers=${excludeSuppliers ? '0' : '1'}`;
    if (term) {
      query += `&term=${term}`;
    }
    return this.request(`api/manufacturers/provider/own/customers${query}`).then((data) => {
      return data;
    });
  };

  getSuppliersList = async (term) => {
    return this.request(`api/manufacturers/provider/own/suppliers${term ? `?term=${term}` : ''}`).then((data) => {
      return data;
    });
  };

  getMaterialsList = async (selectedItems) => {
    let items = [];
    if (selectedItems.length > 0) {
      for (let i in selectedItems) {
        selectedItems[i] && items.push(`selected_ids[]=${selectedItems[i]}`);
      }
    }
    const url = items.length > 0 ? `/only-selected?${items.join("&")}` : '';
    return this.request(`api/manufacturers/provider/own/materials${selectedItems.length !== 0 ? url : ''}`).then((data) => {
      return data;
    });
  };

  getTypeList = async () => {
    return this.request('api/orders/provider/origin-types').then((data) => {
      return data;
    });
  };

  getDeliveryTypeList = async () => {
    return this.request('api/orders/provider/delivery-type').then((data) => {
      return data;
    });
  };

  getPaymentTypeList = async () => {
    return this.request('api/orders/provider/payment-type').then((data) => {
      return data;
    });
  };

  getTaskUnitList = async () => {
    return this.request('api/orders/provider/task-units').then((data) => {
      return data;
    });
  };

  getCostCenterUnitList = async () => {
    return this.request('api/orders/provider/cost-center-units').then((data) => {
      return data;
    });
  };

  getCurrenciesList = async () => {
    return this.request('api/settings/provider/currencies').then((data) => {
      return data;
    });
  };
}
