import {ofType} from 'redux-observable';
import {from, of, concat} from 'rxjs';
import {switchMap, mergeMap, catchError} from 'rxjs/operators';
import {categoriesAPI} from 'services/API';
import {
  getCategoriesTypesSuccess,
  getCategoriesTypesFailure,
  getOwnContactsCategories,
  getOwnContactsCategoriesSuccess,
  getOwnContactsCategoriesFailure,
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailure,
  getParentCategoriesSuccess,
  getParentCategoriesFailure,
  getCategorySuccess,
  getCategoryFailure,
  addCategoryFailure,
  addCategorySuccess,
  updateCategorySuccess,
  updateCategoryFailure,
  deleteCategorySuccess,
  deleteCategoryFailure,
  getMaterialsCategoriesSuccess,
  getMaterialsCategoriesFailure,
  getMaterialsCategoriesProviderSuccess, getMaterialsCategoriesProviderFailure,
  getChildCategoriesSuccess, getChildCategoriesFailure,
  getOwnContactsCategoriesHierarchySuccess,
  getOwnContactsCategoriesHierarchyFailure,
} from '../store/actions/categories';

import {setContactsRoutes} from '../store/actions/routes';

import {
  ADD_CATEGORY,
  GET_CATEGORIES,
  GET_PARENT_CATEGORIES,
  GET_CATEGORIES_TYPES,
  GET_CATEGORY,
  GET_OWN_CONTACTS_CATEGORIES,
  GET_OWN_CONTACTS_CATEGORIES_HIERARCHY,
  UPDATE_CATEGORY,
  REMOVE_CATEGORY, GET_MATERIALS_CATEGORIES, GET_MATERIALS_CATEGORIES_PROVIDER,
  GET_CHILD_CATEGORIES
} from '../store/actionTypes/categoriesActionTypes';

export const getCategoriesEpic = (actions$) => actions$.pipe(
  ofType(GET_CATEGORIES),
  switchMap((action) => from(categoriesAPI.getCategories(action.payload)).pipe(
    switchMap((response) => {
      return of(getCategoriesSuccess(response))
    }),
    catchError( err => {
      return of(getCategoriesFailure(err))
    })
  ))
);

export const getContactsOwnCategoriesEpic = (actions$) => actions$.pipe(
  ofType(GET_OWN_CONTACTS_CATEGORIES),
  switchMap(({payload}) => from(categoriesAPI.getOwnCategories({page: null, type: 1, searchTerms: null, isGetAllData: true, rootOnly: payload.rootOnly})).pipe(
    switchMap((response) => concat(
      of(getOwnContactsCategoriesSuccess(response.data)),
      of(setContactsRoutes(response.data))
    )),
    catchError( err => {
      return of(getOwnContactsCategoriesFailure(err))
    })
  ))
);

export const getOwnContactsCategoriesHierarchyEpic = (actions$) => actions$.pipe(
  ofType(GET_OWN_CONTACTS_CATEGORIES_HIERARCHY),
  switchMap(() => from(categoriesAPI.getOwnContactCategoriesHierarchy()).pipe(
    switchMap((response) => of(getOwnContactsCategoriesHierarchySuccess(response.data))),
    catchError(err => {
      return of(getOwnContactsCategoriesHierarchyFailure(err))
    })
  ))
);

export const getParentCategoriesEpic = (actions$) => actions$.pipe(
  ofType(GET_PARENT_CATEGORIES),
  switchMap(({payload}) => from(categoriesAPI.getParentCategories(payload)).pipe(
    switchMap((response) => {
      return of(getParentCategoriesSuccess(response.data))
    }),
    catchError( err => {
      return of(getParentCategoriesFailure(err))
    })
  ))
);

export const getMaterialsCategoriesEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIALS_CATEGORIES),
  switchMap(() => from(categoriesAPI.getMaterialCategories()).pipe(
    switchMap((response) => {
      return of(getMaterialsCategoriesSuccess(response.data))
    }),
    catchError( err => {
      return of(getMaterialsCategoriesFailure(err))
    })
  ))
);

export const getMaterialsCategoriesProviderEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIALS_CATEGORIES_PROVIDER),
  switchMap(() => from(categoriesAPI.getMaterialCategoriesProvider()).pipe(
    switchMap((response) => {
      return of(getMaterialsCategoriesProviderSuccess(response.data))
    }),
    catchError( err => {
      return of(getMaterialsCategoriesProviderFailure(err))
    })
  ))
);

export const getCategoriesTypesEpic = (actions$) => actions$.pipe(
  ofType(GET_CATEGORIES_TYPES),
  switchMap(() => from(categoriesAPI.getCategoriesTypes()).pipe(
    switchMap((response) => {
      return of(getCategoriesTypesSuccess(response.data))
    }),
    catchError( err => {
      return of(getCategoriesTypesFailure(err))
    })
  ))
);

export const getCategoryEpic = (actions$) => actions$.pipe(
  ofType(GET_CATEGORY),
  switchMap(({payload}) => from(categoriesAPI.getCategory(payload)).pipe(
    switchMap((response) => {
      return of(getCategorySuccess(response.data))
    }),
    catchError( err => {
      return of(getCategoryFailure(err))
    })
  ))
);

export const addCategoryEpic = (actions$) => actions$.pipe(
  ofType(ADD_CATEGORY),
  switchMap(({payload}) => from(categoriesAPI.createCategory(payload.data)).pipe(
    switchMap((response) => concat(
      of(getCategories({page: payload.currentPage, type: payload.type, order: payload.order, searchTerms: payload.searchTerms, level1Only: payload.level1Only })),
      of(addCategorySuccess(response.data)),
      of(getOwnContactsCategories(true)),
    )),
    catchError( err => {
      return of(addCategoryFailure(err.responseBody ? err.responseBody.errors : err))
    })
  ))
);

export const updateCategoryEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_CATEGORY),
  mergeMap((action) => from(categoriesAPI.updateCategory(action.payload.id, action.payload.data)).pipe(
    switchMap((response) => concat(
      of(getCategories({page: action.payload.currentPage, type: action.payload.type, order: action.payload.order, searchTerms: action.payload.searchTerms, level1Only: action.payload.level1Only })),
      of(updateCategorySuccess(response.data)),
      of(getOwnContactsCategories(true)),
    )),
    catchError( err => {
      return of(updateCategoryFailure(err.responseBody ? err.responseBody.errors : err))
    })
  ))
);

export const removeCategoryEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_CATEGORY),
  mergeMap((action) => from(categoriesAPI.deleteCategory(action.payload.id)).pipe(
    switchMap((response) => concat(
      of(getCategories({page: action.payload.currentPage, type: action.payload.type, order: action.payload.order, searchTerms: action.payload.searchTerms, level1Only: action.payload.level1Only })),
      of(deleteCategorySuccess(response)),
      of(getOwnContactsCategories(true)),
    )),
    catchError( err => {
      return of(deleteCategoryFailure(err.responseBody ? err.responseBody.error : err))
    })
  ))
);

export const getChildCategoriesEpic = (actions$) => actions$.pipe(
  ofType(GET_CHILD_CATEGORIES),
  switchMap((action) => from(categoriesAPI.getChildCategories(action.payload.parentId)).pipe(
    switchMap((response) => {
      return of(getChildCategoriesSuccess(action.payload.parentId, response.data));
    }),
    catchError(err => {
      return of(getChildCategoriesFailure(action.payload.parentId, err));
    })
  ))
);
