import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR
} from "../actionTypes/rolesActionTypes";

import NotificationService from 'services/NotificationService';

export const getRoles = () => {
  return {type: GET_ROLES}
};

export const getRolesSuccess = (roles) => {
  return {type: GET_ROLES_SUCCESS, payload: roles}
};

export const getRolesFailure = (error) => {
  return {type: GET_ROLES_ERROR, payload: error}
};
