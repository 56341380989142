/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { authorize, login, resetLoginErrors } from 'store/actions/auth';
import CustomDropdownLanguage from 'components/CustomDropdownLanguage/CustomDropdownLanguage';
import i18n from 'i18next';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Input from 'components/Input/Input';

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import 'views/Auth/loginPageAP.scss';

class LoginPageAP extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden'
    };

    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
  }

  onLoginBtnClick = () => {
    const { login, resetLoginErrors } = this.props;
    const credentials = {
      email: this.emailRef.current.value,
      password: this.passwordRef.current.value
    };
    resetLoginErrors(['email', 'password', 'verification']);
    login(credentials);
  };

  onLoginInputChange = event => {
    const { resetLoginErrors } = this.props;
    resetLoginErrors(['email']);
  };

  onInputKeyDown = event => {
    if (event.key === 'Enter') {
      this.onLoginBtnClick();
    }
  };

  onPasswordInputChange = () => {
    const { resetLoginErrors } = this.props;
    resetLoginErrors(['password']);
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const { classes, auth, location, history, t } = this.props;
    if (auth.isAuthorized) {
      let { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    }

    return (
      <div className={`${classes.container} ikt-ap_login-container`}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card
                login
                className={`${
                  classes[this.state.cardAnimaton]
                } ikt-ap_login-card`}
              >
                <CardBody className="ikt-ap_login-card-body">
                  <GridItem className="login-card-header" xs={12}>
                    <span>{ t('login') }</span>
                  </GridItem>
                  <GridItem className="login-card-text" xs={12}>
                    <span>{ t('email') }</span>
                    <Input
                      className="ikt-ap_login-email-input"
                      ref={this.emailRef}
                      error={!!auth.loginErrors.email}
                      errorMsg={auth.loginErrors.email}
                      disableErrorPadding={true}
                      disableErrorFloat={true}
                      onChange={this.onLoginInputChange}
                      onKeyDown={this.onInputKeyDown}
                    />
                  </GridItem>
                  <GridItem className="login-card-text">
                    <span>{ t('password') }</span>
                    <Input
                      className="ikt-ap_login-email-password"
                      type="password"
                      ref={this.passwordRef}
                      error={!!auth.loginErrors.password}
                      errorMsg={auth.loginErrors.password}
                      disableErrorPadding={true}
                      onChange={this.onPasswordInputChange}
                      onKeyDown={this.onInputKeyDown}
                    />
                  </GridItem>
                  { auth.loginErrors.verification &&
                    <p className="error-text">{ t('verify_email') }</p> }
                  <GridItem>
                    <div className="login-register-user-btn">
                      <Button
                        className="login-btn"
                        onClick={this.onLoginBtnClick}
                        >
                        { t('login').toUpperCase() }
                      </Button>
                    </div>
                  </GridItem>
                  <GridItem>
                    <div className="login-register-user-btn">
                      <Link to={'/auth-ap/forgot-password'}>
                        <span className="link-text">{ t('forgot_your_password') }</span>
                      </Link>
                    </div>
                  </GridItem>
                </CardBody>
                <CardFooter
                  className={`${classes.justifyContentCenter} ikt-ap_login-card-footer`}
                >
                  <GridItem className="register-text" xs={12}>
                    <span>{ t('dont_have_an_account') }</span>
                  </GridItem>
                  <div className="login-register-user-btn">
                    <Link to={'/register/register-page'}>
                      <Button
                        className="register-btn"
                      >
                        { t('register_as_manufacturer').toUpperCase() }
                      </Button>
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        <div className="language-selector-container">
          <CustomDropdownLanguage
            buttonText={i18n.language}
            onClick={prop => {
              i18n.changeLanguage(prop.id);
            }}
          />
        </div>
      </div>
    );
  }
}

LoginPageAP.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authorize: isAuthorized => dispatch(authorize(isAuthorized)),
    login: credentials => dispatch(login(credentials)),
    resetLoginErrors: fields => dispatch(resetLoginErrors(fields))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(loginPageStyle)(withRouter(withTranslation()(LoginPageAP))));
