import React from "react";

import CardBlack from '../../assets/img/viewBtn/cards-b.svg';
import CardWhite from '../../assets/img/viewBtn/cards-w.svg';
import TableBlack from '../../assets/img/viewBtn/table-b.svg';
import TableWhite from '../../assets/img/viewBtn/table-w.svg';

import './style.scss';

class ViewButton extends React.Component {
  render() {
    const {className, isTable, onViewChange} = this.props;
    return (
      <div className={`view-btn ${className}`}>
        <div className={`table-view ${!isTable ? 'dark-table' : ''}`} onClick={() => onViewChange("table")}>
          {isTable ? <img alt="tab-black" src={TableBlack}/> : <img alt="tab-white" src={TableWhite}/>}
        </div>
        <div className={`card-view ${isTable ? 'dark-card' : ''}`} onClick={() => onViewChange("card")}>
          {!isTable ? <img alt="card-black" src={CardBlack}/> : <img alt="card-white" src={CardWhite}/>}
        </div>
      </div>
    );
  }
}

export default ViewButton;
