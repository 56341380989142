import reducer from './reducers/index';
import {createEpicMiddleware} from "redux-observable";
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {rootEpic} from "../epics";

const epicMiddleware = createEpicMiddleware();

export const store = createStore(reducer, compose(applyMiddleware(thunk, epicMiddleware)));

epicMiddleware.run(rootEpic);
