import React from 'react';
import { withTranslation } from 'react-i18next';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import DecimalInput from 'components/DecimalInput'
import Input from 'components/Input/Input';
import Checkbox from 'components/StyledCheckbox';
import IconButton from "@material-ui/core/IconButton";
import CloseButton from "../../../../assets/img/buttons/delete.svg";

import './style.scss';

class ClassRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleInputValueChange = (fieldName, value) => {
    const { data, onDataChange } = this.props;
    onDataChange({
      ...data,
      [fieldName]: value,
    });
  }

  resolveErrorForField = (field) => {
    const { errors } = this.props;
    if (errors && errors[field] && errors[field][0])
      return errors[field][0];
    else
      return null;
  }

  render() {
    const { data, onRemove, t } = this.props;
    return (
      <GridContainer className="position-info-header classrow-group">
        <GridItem className="alc-class">
          <Input
            value={data.cad_class_name}
            onChange={(e) => this.handleInputValueChange('cad_class_name', e.target.value)}
            error={this.resolveErrorForField('cad_class_name')}
          />
        </GridItem>
        <GridItem className="alc-left">
          <DecimalInput
            value={ data.left }
            onChange={value => this.handleInputValueChange('left', value)}
            error={this.resolveErrorForField('left')}
          />
        </GridItem>
        <GridItem className="alc-right">
          <DecimalInput
            value={ data.right }
            onChange={value => this.handleInputValueChange('right', value)}
            error={this.resolveErrorForField('right')}
          />
        </GridItem>
        <GridItem className="alc-top">
          <DecimalInput
            value={ data.top }
            onChange={value => this.handleInputValueChange('top', value)}
            error={this.resolveErrorForField('top')}
          />
        </GridItem>
        <GridItem className="alc-below">
          <DecimalInput
            value={ data.bottom }
            onChange={value => this.handleInputValueChange('bottom', value)}
            error={this.resolveErrorForField('bottom')}
          />
        </GridItem>
        <GridItem className="alc-cad-import">
          <Checkbox
            checked={data.cad_import}
            onChange={(e) => this.handleInputValueChange("cad_import", !data.cad_import)}
            color="primary"
          />
          <span className="checkbox-label">{ t('cad_import') }</span>
        </GridItem>
        <GridItem className="alc-action">
          <IconButton
            aria-label="close"
            className={`delete-btn`}
            onClick={() => onRemove()}
          >
            <img alt="orders-list-delete-order" src={CloseButton}/>
          </IconButton>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withTranslation()(ClassRow);
