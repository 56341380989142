/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
// core components
import InputWithIcon from "../InputWithIcon";

import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx';

import './style.scss';
import InputAdornment from "@material-ui/core/InputAdornment";
import UserIcon from "../../assets/img/inputIcons/user-icon.svg";
import propTypes from "react-table/lib/propTypes";

class CustomAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: '',
      dropdownList: []

    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
  }

  componentDidMount() {
    if(this.props.dropdownList) {
      const selectedItem = this.props.dropdownList.find(item => {
        const itemId = !item.id ? '' : item.id;
        const buttonText = !this.props.buttonText ? '' : this.props.buttonText;
        return itemId.toString() === buttonText.toString();
      });
      this.setState({
        selected: selectedItem ? selectedItem.value : '',
        dropdownList: this.props.dropdownList
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.buttonText !== this.props.buttonText) {
      const selectedItem = this.props.dropdownList.find(item => {
        const itemId = !item.id ? '' : item.id;
        const buttonText = !this.props.buttonText ? '' : this.props.buttonText;
        return itemId.toString() === buttonText.toString();
      });
      this.setState({
        selected: selectedItem ? selectedItem.value : '',
      })
    }
    if (prevProps.dropdownList.length !== this.props.dropdownList.length) {
      this.setState({
        dropdownList: this.props.dropdownList
      })
    }
  }

  handleClick = () => {
    this.setState(state => ({ open: !this.state.open}));
  };
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };
  handleCloseMenu(param) {
    const {isClearSelected, dropdownList} = this.props;
    this.setState({
      open: false,
      selected: !isClearSelected ? param.value : '',
      dropdownList: !isClearSelected ? this.state.dropdownList : dropdownList
    });
    if (this.props && this.props.onClick) {
      this.props.onClick(param);
    }
  }

  handleOnInputChange = (value) => {
    const {dropdownList} = this.props;
    let newList = [...dropdownList].filter(item => {
      const itemValue = item.value === null ? '' : item.value;
      return itemValue.toLowerCase().includes(value.toLowerCase())
    });
    value.length === 0 && this.props.onClick({id: ''});
    const doOpen = !!value.length;
    this.setState({
      selected: value,
      dropdownList: newList,
      open: doOpen
    })
  }

  render() {
    const { open, selected, dropdownList } = this.state;
    const {
      classes,
      className,
      buttonText,
      buttonProps,
      dropup,
      dropdownHeader,
      hoverColor,
      dropPlacement,
      id,
      rtlActive,
      noLiPadding,
      innerDropDown,
      navDropdown,
      error,
      icon,
      placeholder,
      discardIcon,
      key,
      t
    } = this.props;
    const caretClasses = classNames({
      [classes.caret]: true,
      [classes.caretDropup]: dropup && !open,
      [classes.caretActive]: open && !dropup,
      [classes.caretRTL]: rtlActive
    });
    const dropdownItem = classNames({
      [classes.dropdownItem]: true,
      [classes[hoverColor + 'Hover']]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive
    });
    const dropDownMenu = (
      <MenuList role="menu" className={classes.menuList}>
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => this.handleCloseMenu(dropdownHeader)}
            className={classes.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {dropdownList.length !== 0 ? dropdownList.map((prop, key) => {
            const isSelected = buttonText ?
              buttonText === prop.value ||
              (buttonText.props && buttonText.props.children === prop) : false;

            if (prop.divider) {
              return (
                <Divider
                  key={key}
                  onClick={() => this.handleCloseMenu('divider')}
                  className={classes.dropdownDividerItem}
                />
              );
            } else if (
              prop.props !== undefined &&
              prop.props['data-ref'] === 'multi'
            ) {
              return (
                <MenuItem
                  key={key}
                  className={`${dropdownItem}${
                    isSelected ? ` ikt-ap_custom-dropdown-item-selected` : ``
                  }`}
                  style={{ overflow: 'visible', padding: 0 }}
                >
                  {prop.value}
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={key}
                onClick={() => this.handleCloseMenu(prop)}
                className={`${dropdownItem}${
                  isSelected ? ` ikt-ap_custom-dropdown-item-selected` : ``
                }`}
              >
                {prop.value}
              </MenuItem>
            );
          }) :
          <MenuItem
            className={`${dropdownItem}`}
          >
            No Result
          </MenuItem>
        }
      </MenuList>
    );

    let buttonToggleClasses = `${buttonProps.className} ikt-ap_custom-dropdown-toggle-btn ${error ? 'dropdown-error' : ''}`;
    return (
      <div
        className={`ikt-ap_custom-autocomplete dropdown-blue ${className} ${
          innerDropDown ? classes.innerManager : classes.manager
        }`}
        key={key}
      >
        <div onClick={!buttonProps.disabled && this.handleClick} className={buttonText !== undefined ? '' : classes.target}>
          { !discardIcon ?
            <InputWithIcon
              aria-label="Notifications"
              aria-owns={open ? 'menu-list' : null}
              startAdornment={
                <InputAdornment position="start">
                  <img
                    alt="customer-autocomplete-img"
                    src={icon ? icon : UserIcon}
                  />
                </InputAdornment>
              }
              placeholder={placeholder ? placeholder : t('start_typing_name')}
              id={id}
              inputRef={node => {
                this.anchorEl = node;
              }}
              disabled={false/*buttonProps.disabled*/}
              readOnly={buttonProps.disabled}
              className={buttonToggleClasses}
              value={selected}
              onChange={e => this.handleOnInputChange(e.target.value)}
              inputProps={{
                autoComplete: 'off'
              }}
            />
            :
            <InputWithIcon
              aria-label="Notifications"
              aria-owns={open ? 'menu-list' : null}
              placeholder={placeholder ? placeholder : t('start_typing_name')}
              id={id}
              inputRef={node => {
                this.anchorEl = node;
              }}
              disabled={false/*buttonProps.disabled*/}
              readOnly={buttonProps.disabled}
              className={buttonToggleClasses}
              value={selected}
              onChange={e => this.handleOnInputChange(e.target.value)}
              inputProps={{
                autoComplete: 'off'
              }}
            />
          }
        </div>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true,
            [classes.popperNav]: open && navDropdown
          })}
        >
          {() => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: '0 100% 0' }
                  : { transformOrigin: '0 0 0' }
              }
            >
              <Paper
                className={`${classes.dropdown} ikt-ap_custom-dropdown-menu ${!discardIcon ? 'ikt-ap_custom-dropdown-menu-icon' : 'ikt-ap_custom-dropdown-menu-no-icon'}`}
              >
                {innerDropDown ? (
                  dropDownMenu
                ) : (
                  <ClickAwayListener onClickAway={this.handleClose}>
                    {dropDownMenu}
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CustomAutocomplete.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: 'primary'
};

CustomAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  className: PropTypes.string,
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.object,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  id: PropTypes.string,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    'bottom',
    'top',
    'right',
    'left',
    'bottom-start',
    'bottom-end',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end'
  ]),
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // This is a function that returns the clicked menu item
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  discardIcon: PropTypes.bool,
};

export default withStyles(customDropdownStyle)(withTranslation()(CustomAutocomplete));
