import { handleActions } from 'redux-actions';
import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_SUCCESS,
  GET_ALL_INVOICES_ERROR,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_ERROR,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  SET_INVOICE_STATUS,
  SET_INVOICE_STATUS_SUCCESS,
  SET_INVOICE_STATUS_ERROR,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_ERROR,
  PREVIEW_INVOICE,
  PREVIEW_INVOICE_SUCCESS,
  PREVIEW_INVOICE_ERROR,
  RESET_INVOICE_DATA,
} from '../actionTypes/invoicesActionTypes';

export const DEFAULT_INVOICES_STATE = {
  invoices: [],
  invoicesMetaData: {},
  invoicesBusy: false,
  isInvoiceValid: false,
  error: null,
  downloadContent: null,
  downloadFilename: null,
  downloadError: null,
  previewContent: null,
  previewError: null,
};

export const invoices = handleActions(
  {
    [GET_INVOICES]: (state) => ({
      ...state,
      invoicesBusy: true,
    }),
    [GET_INVOICES_SUCCESS]: (state, action) => ({
      ...state,
      invoicesBusy: false,
      invoices: action.payload.data,
      invoicesMetaData: action.payload.meta,
    }),
    [GET_INVOICES_ERROR]: (state) => ({
      ...state,
      invoicesBusy: false,
    }),
    [GET_ALL_INVOICES]: (state) => ({
      ...state,
      invoicesBusy: true,
    }),
    [GET_ALL_INVOICES_SUCCESS]: (state, action) => ({
      ...state,
      invoicesBusy: false,
      invoices: action.payload.data,
      invoicesMetaData: action.payload.meta,
    }),
    [GET_ALL_INVOICES_ERROR]: (state) => ({
      ...state,
      invoicesBusy: false,
    }),
    [CREATE_INVOICE]: (state) => ({
      ...state,
      invoicesBusy: true,
      isInvoiceValid: false,
    }),
    [CREATE_INVOICE_SUCCESS]: (state, action) => ({
      ...state,
      invoicesBusy: false,
      isInvoiceValid: true,
    }),
    [CREATE_INVOICE_ERROR]: (state, action) => ({
      ...state,
      invoicesBusy: false,
      error: action.payload,
    }),
    [UPDATE_INVOICE]: (state) => ({
      ...state,
      invoicesBusy: true,
      isInvoiceValid: false,
    }),
    [UPDATE_INVOICE_SUCCESS]: (state, action) => ({
      ...state,
      invoicesBusy: false,
      isInvoiceValid: true,
    }),
    [UPDATE_INVOICE_ERROR]: (state, action) => ({
      ...state,
      invoicesBusy: false,
      error: action.payload,
    }),
    [DELETE_INVOICE]: (state) => ({
      ...state,
      invoicesBusy: true,
    }),
    [DELETE_INVOICE_SUCCESS]: (state) => ({
      ...state,
      invoicesBusy: false,
    }),
    [DELETE_INVOICE_ERROR]: (state, action) => ({
      ...state,
      invoicesBusy: false,
      error: action.payload,
    }),
    [SET_INVOICE_STATUS]: (state) => ({
      ...state,
      invoicesBusy: true,
    }),
    [SET_INVOICE_STATUS_SUCCESS]: (state, action) => ({
      ...state,
      invoicesBusy: false,
    }),
    [SET_INVOICE_STATUS_ERROR]: (state, action) => ({
      ...state,
      invoicesBusy: false,
      error: action.payload,
    }),
    [DOWNLOAD_INVOICE]: (state, action) => ({
      ...state,
      downloadContent: null,
      downloadFilename: action.payload.filename,
      downloadError: null,
    }),
    [DOWNLOAD_INVOICE_SUCCESS]: (state, action) => ({
      ...state,
      downloadContent: action.payload,
      downloadError: null,
    }),
    [DOWNLOAD_INVOICE_ERROR]: (state, action) => ({
      ...state,
      downloadError: action.payload,
    }),
    [PREVIEW_INVOICE]: (state, action) => ({
      ...state,
      previewContent: null,
      previewError: null,
    }),
    [PREVIEW_INVOICE_SUCCESS]: (state, action) => ({
      ...state,
      previewContent: action.payload,
      previewError: null,
    }),
    [PREVIEW_INVOICE_ERROR]: (state, action) => ({
      ...state,
      previewError: action.payload,
    }),
    [RESET_INVOICE_DATA]: (state) => ({
      ...state,
      error: null,
      downloadError: null,
      previewError: null,
      isInvoiceValid: false,
    }),
  },
  DEFAULT_INVOICES_STATE
);
