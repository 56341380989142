import React from "react";
import { withTranslation } from 'react-i18next';

import './style.scss';
import {config} from "../../Config";

class UploadImage extends React.Component {
  id;

  constructor(props) {
    super(props);
    this.id = parseInt(Math.random() * 100000).toString();
  }

  handleInputChange = (event) => {
    const file = event.target.files[0];
    this.props.onChange(file);
  };

  render() {
    const {className, file, errorMsg, showErrorMessage, t, disableRemoveOption} = this.props;
    return (
      <div className={`logo-content ${className}`}>
        <div className={`logo-upload ${errorMsg ? 'error' : ''}`}>
          {file ? <img className="logo-img" src={file.url ? `${config.apiBaseUrl}${file.url}` : URL.createObjectURL(file)} alt="manufacturer-logo" /> : <div className="logo-container"/>}
          <div className="logo-upload-content">
            <span>{ t('192x112_px_min') }</span>
            <span>{ t('jpg_or_png_image') }</span>
            <input
              accept="image/x-png, image/jpeg"
              type="file"
              name={`file-${this.id}`}
              id={`file-${this.id}`}
              className="file-input"
              onChange={this.handleInputChange}
            />
            <label htmlFor={`file-${this.id}`}>{ t('upload').toUpperCase() }</label>
            { !disableRemoveOption && Boolean(file) && (
              <span className="remove-button" onClick={this.props.onChange ? () => { this.props.onChange() } : null}>{ t('remove').toUpperCase() }</span>
            ) }
          </div>
        </div>
        {showErrorMessage && errorMsg && <div className="error-msg-container">{errorMsg}</div>}
      </div>
    );
  }
}

export default withTranslation()(UploadImage);
