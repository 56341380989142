import { handleActions } from 'redux-actions';
import {
  GET_ORDER_STATUS_LIST_SUCCESS,
  GET_INQUIRY_STATUS_LIST_SUCCESS,
  GET_COWORKERS_LIST_SUCCESS,
  GET_CUSTOMERS_LIST_SUCCESS,
  GET_SELECTED_CUSTOMERS_SUCCESS,
  GET_SUPPLIERS_LIST_SUCCESS,
  GET_MATERIALS_LIST,
  GET_MATERIALS_LIST_SUCCESS,
  GET_MATERIALS_LIST_FAILURE,
  GET_TYPE_LIST_SUCCESS,
  GET_PAYMENT_TYPE_LIST_SUCCESS,
  GET_DELIVERY_TYPE_LIST_SUCCESS,
  GET_TASK_UNIT_LIST_SUCCESS,
  GET_COST_CENTER_UNIT_LIST_SUCCESS,
  GET_CURRENCIES_LIST_SUCCESS,
  RESET_PROVIDERS_DATA
} from '../actionTypes/providerActionTypes';

export const DEFAULT_PROVIDERS_STATE = {
  orderStatusList: [],
  inquiryStatusList: [],
  coworkersList: [],
  customersList: [],
  selectedCustomers: [],
  suppliersList: [],
  materialsList: [],
  isMaterialsBusy: false,
  typeList: [],
  paymentTypeList: [],
  deliveryTypeList: [],
  taskUnitList: [],
  costCenterUnitList: [],
  currenciesList: []
};

export const providers = handleActions(
  {
    [GET_ORDER_STATUS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      orderStatusList: action.payload,
    }),
    [GET_INQUIRY_STATUS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      inquiryStatusList: action.payload,
    }),
    [GET_TYPE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      typeList: action.payload,
    }),
    [GET_COWORKERS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      coworkersList: action.payload,
    }),
    [GET_CUSTOMERS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      customersList: action.payload,
    }),
    [GET_SELECTED_CUSTOMERS_SUCCESS]: (state, action) => ({
      ...state,
      selectedCustomers: action.payload,
    }),
    [GET_SUPPLIERS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      suppliersList: action.payload,
    }),
    [GET_MATERIALS_LIST]: (state, action) => ({
      ...state,
      isMaterialsBusy: true,
    }),
    [GET_MATERIALS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      materialsList: action.payload,
      isMaterialsBusy: false,
    }),
    [GET_MATERIALS_LIST_FAILURE]: (state, action) => ({
      ...state,
      isMaterialsBusy: false,
    }),
    [GET_PAYMENT_TYPE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      paymentTypeList: action.payload,
    }),
    [GET_DELIVERY_TYPE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      deliveryTypeList: action.payload,
    }),
    [GET_TASK_UNIT_LIST_SUCCESS]: (state, action) => ({
      ...state,
      taskUnitList: action.payload,
    }),
    [GET_COST_CENTER_UNIT_LIST_SUCCESS]: (state, action) => ({
      ...state,
      costCenterUnitList: action.payload,
    }),
    [GET_CURRENCIES_LIST_SUCCESS]: (state, action) => ({
      ...state,
      currenciesList: action.payload,
    }),
    [RESET_PROVIDERS_DATA]: (state, action) => ({
      ...state
    })
  },
  DEFAULT_PROVIDERS_STATE
);
