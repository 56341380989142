import React from "react";
import { withTranslation } from 'react-i18next';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {
  getCategories,
  getOwnContactsCategoriesHierarchy,
} from 'store/actions/categories';
import './style.scss';

import Button from '../../components/CustomButtons/Button';

import CloseButton from "../../assets/img/buttons/delete.svg";
import {CreateContactAllTabs} from "../../constants/constants";
import {getContactsErrorPage} from 'utils/utils';
import ProjectTabs from "../../components/ProjectTabs/ProjectTabs";

import ContactDetails from './ContactDetails';
import ShippingDetails from './ShippingDetails';
import FinanceDetails from './FinanceDetails';
import Note from './Note';
import {resetCustomerData, addCustomer, updateCustomer} from "../../store/actions/customers";
import {getCities, getCountries} from "../../store/actions/locations";
import {connect} from "react-redux";
import moment from "moment";

import CloseDialog from '../CloseDialog';
import RemoveDialog from '../RemoveDialog';

class CreateContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 0,
      contactDetails: {
        initials: '',
        salutation: '',
        title: '',
        first_name: '',
        last_name: '',
        date_of_birth: moment("1990-01-01").format('YYYY-MM-DD'),
        company: '',
        address: '',
        postal_code: '',
        city: '',
        country: '',
        directed_to: '',
        categories: props.category ? [ parseInt(props.category) ] : [],
        phone_private: '',
        phone_mobile: '',
        phone_company: '',
        phone_mobile2: '',
        fax: '',
        email: '',
        website: '',
        finances_iban: '',
        finances_bank: '',
        finances_tax_no: '',
        finances_bic: '',
        finances_eu_vat_no: '',
        cash_account: '',
        cash_account_period: '',
        credit_account_period: '',
        shipping_addresses: [],
        note_general: '',
        note_business: '',
        note_personal: '',
        note_activities: ''
      },
      isCloseDialogOpen: false,
      isRemoveAddressDialogOpen: false,
      haveEdits: false,
    };
  }

  componentDidMount() {
    // this.props.getCountries();
    // this.props.getCities(this.state.contactDetails.country_id)
    this.props.getCategories(null, [1]);
    this.props.getOwnContactsCategoriesHierarchy();
    if ((this.props.editCustomer && this.props.editCustomer.id) && this.props.isEditContact) {
      this.init()
    }
    if (this.props.customerData && Object.keys(this.props.customerData).length !== 0 && !this.props.isEditContact) {
      this.initCustomerData()
    }
    if (!this.props.editCustomer) {
      const newContactDetails = {...this.state.contactDetails, country: this.props.profileData.country};
      this.setState({
        contactDetails: newContactDetails
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isCustomerValid && this.props.isCustomerValid) {
      if (this.props.isEditContact && this.props.editCallback)
        this.props.editCallback();
      this.props.onClose();
    }
    if (prevProps.errors !== this.props.errors) {
      this.goToErrorPage()
    }
  }

  componentWillUnmount() {
    this.props.resetData()
  }

  initCustomerData = () => {
    const {customerData} = this.props;
    this.setState({
      contactDetails: {
        initials: customerData.initials ? customerData.initials : '',
        salutation: customerData.salutation ? customerData.salutation : '',
        title: customerData.title ? customerData.title : '',
        first_name: customerData.first_name ? customerData.first_name : '',
        last_name: customerData.last_name ? customerData.last_name : '',
        date_of_birth: customerData.date_of_birth ? customerData.date_of_birth : '',
        company: customerData.company ? customerData.company : '',
        address: customerData.address ? customerData.address : '',
        postal_code: customerData.postal_code ? customerData.postal_code : '',
        city: customerData.city ? customerData.city : '',
        country: customerData.country ? customerData.country : '',
        directed_to: customerData.directed_to ? customerData.directed_to : '',
        categories: customerData.categories ? customerData.categories : (this.props.category ? [ this.props.category ] : []),
        phone_private: customerData.phone_private ? customerData.phone_private : '',
        phone_mobile: customerData.phone_mobile ? customerData.phone_mobile : '',
        phone_company: customerData.phone_company ? customerData.phone_company : '',
        phone_mobile2: customerData.phone_mobile2 ? customerData.phone_mobile2 : '',
        fax: customerData.fax ? customerData.fax : '',
        email: customerData.email ? customerData.email : '',
        website: customerData.website ? customerData.website : '',
        finances_iban: customerData.finances_iban ? customerData.finances_iban : '',
        finances_bank: customerData.finances_bank ? customerData.finances_bank : '',
        finances_tax_no: customerData.finances_tax_no ? customerData.finances_tax_no : '',
        finances_bic: customerData.finances_bic ? customerData.finances_bic : '',
        finances_eu_vat_no: customerData.finances_eu_vat_no ? customerData.finances_eu_vat_no : '',
        cash_account: customerData.cash_account ? customerData.cash_account : '',
        cash_account_period: customerData.cash_account_period !== null && customerData.cash_account_period !== undefined ? customerData.cash_account_period : '',
        credit_account_period: customerData.credit_account_period !== null && customerData.credit_account_period !== undefined ? customerData.credit_account_period : '',
        shipping_addresses: customerData.shipping_addresses ? customerData.shipping_addresses : '',
        note_general: customerData.note_general ? customerData.note_general : '',
        note_business: customerData.note_business ? customerData.note_business : '',
        note_personal: customerData.note_personal ? customerData.note_personal : '',
        note_activities: customerData.note_activities ? customerData.note_activities : ''
      }
    });
  }
  
  init = () => {
    const {editCustomer} = this.props;
    this.setState({
      contactDetails: {
        initials: editCustomer.initials,
        salutation: editCustomer.salutation,
        title: editCustomer.title,
        first_name: editCustomer.first_name,
        last_name: editCustomer.last_name,
        date_of_birth: editCustomer.date_of_birth,
        company: editCustomer.company,
        address: editCustomer.address,
        postal_code: editCustomer.postal_code,
        city: editCustomer.city,
        country: editCustomer.country,
        directed_to: editCustomer.directed_to,
        categories: editCustomer.categories.map(el => el.id),
        phone_private: editCustomer.phone_private,
        phone_mobile: editCustomer.phone_mobile,
        phone_company: editCustomer.phone_company,
        phone_mobile2: editCustomer.phone_mobile2,
        fax: editCustomer.fax,
        email: editCustomer.email,
        website: editCustomer.website,
        finances_iban: editCustomer.finances_iban,
        finances_bank: editCustomer.finances_bank,
        finances_tax_no: editCustomer.finances_tax_no,
        finances_bic: editCustomer.finances_bic,
        finances_eu_vat_no: editCustomer.finances_eu_vat_no,
        cash_account: editCustomer.cash_account ? editCustomer.cash_account : '',
        cash_account_period: editCustomer.cash_account_period !== null && editCustomer.cash_account_period !== undefined ? editCustomer.cash_account_period : '',
        credit_account_period: editCustomer.credit_account_period !== null && editCustomer.credit_account_period !== undefined ? editCustomer.credit_account_period : '',
        shipping_addresses: editCustomer.shipping_addresses,
        note_general: editCustomer.note_general,
        note_business: editCustomer.note_business,
        note_personal: editCustomer.note_personal,
        note_activities: editCustomer.note_activities
      }
    })
  }

  goToErrorPage = () => {
    const {errors} = this.props;
    const errorPage = getContactsErrorPage(errors);
    this.onTabItemClick(errorPage)
  }

  onTabItemClick = id => {
    this.setState({
      selectedTabId: id
    });
  };

  handleCloseBtn = () => {
    const {onClose} = this.props;
    onClose();
  };

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleCloseBtn();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleCloseBtn();
    });
  }

  handleDataChange = (fieldName, value) => {
    const {contactDetails} = this.state;
    const newContactDetails = {...contactDetails };
    if (fieldName === 'cash_account_period' || fieldName === 'credit_account_period') {
      if (value === '' || /^\d+$/.test(value)) {
        newContactDetails[fieldName] = value;
      }
    } else {
      newContactDetails[fieldName] = value === 'Invalid date' ? '' : value;
    }
    this.setState({contactDetails: newContactDetails, haveEdits: true});
  };

  handleAddNewShippingAddress = () => {
    const {shipping_addresses} = this.state.contactDetails;
    const newShippingAddress = {
      first_name: '',
      last_name: '',
      phone: '',
      phone_mobile: '',
      email: '',
      address: '',
      postal_code: '',
      city: '',
      country: ''
    };
    const newShippingAddresses = [...shipping_addresses, newShippingAddress];
    this.handleDataChange('shipping_addresses', newShippingAddresses)
  };

  handleShippingAddressDataChange = (id, fieldName, value) => {
    const {shipping_addresses} = this.state.contactDetails;
    const newShippingAddresses = [...shipping_addresses];
    const newShippingAddress = newShippingAddresses[id];
    newShippingAddress[fieldName] = value;
    this.handleDataChange('shipping_addresses', newShippingAddresses)
  };

  handleShippingAddressDataRemove = (index) => {
      this.setState({
        addressToRemoveIndex: index,
        isRemoveAddressDialogOpen: true
      })
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveAddressDialogOpen: false,
    });
  }

  removeSelectedAddress = () => {
    const {addressToRemoveIndex} = this.state;
    const {shipping_addresses} = this.state.contactDetails;
    const newShippingAddresses = [...shipping_addresses];
    newShippingAddresses.splice(addressToRemoveIndex, 1);
    this.handleDataChange('shipping_addresses', newShippingAddresses);
    this.handleRemoveDialogClose();
  }

  handleCreateUpdateButton = () => {
    const {
      editCustomer,
      createCustomer,
      isEditContact,
      updateCustomer,
      customersMutBusy,
    } = this.props;
    if (customersMutBusy)
      return;
    const {contactDetails} = this.state;
    if (editCustomer && editCustomer.id && isEditContact) {
      let data = {};
      Object.keys(contactDetails).map(item => data[item] = contactDetails[item]);
      updateCustomer(editCustomer.id, data, { page: this.props.currentPage, filters: this.props.currentFilters, searchTerms: this.props.currentSearchTerms});
    } else {
      let data = {};
     Object.keys(contactDetails).map(item => data[item] = contactDetails[item]);
      createCustomer(data, { page: this.props.currentPage, filters: this.props.currentFilters, searchTerms: this.props.currentSearchTerms});
    }
  }

  render() {
    const {
      isOpen,
      isEditContact,
      onClose,
      countries,
      cities,
      categories,
      contactCategoriesHierarchy,
      errors,
      t
    } = this.props;
    const { selectedTabId, contactDetails,isRemoveAddressDialogOpen } = this.state;
    const categoriesArray = categories.map(category => ({id: category.id, value: category.name}))
    let selectedComponent;
    switch(selectedTabId) {
      case 0:
        selectedComponent =
          <ContactDetails
            cities={cities}
            countries={countries}
            data={contactDetails}
            errors={errors}
            onDataChange={this.handleDataChange}
            categories={[{id:'', value: t('none')},...categoriesArray]}
            contactCategoriesHierarchy={contactCategoriesHierarchy}
          />;
        break;
      case 1:
        selectedComponent =
          <ShippingDetails
            cities={cities}
            countries={countries}
            data={contactDetails.shipping_addresses}
            errors={errors || null}
            onAddNewAddress={this.handleAddNewShippingAddress}
            onDataChange={this.handleShippingAddressDataChange}
            onRemoveAddress={this.handleShippingAddressDataRemove}
          />;
        break;
      case 2:
        selectedComponent =
          <FinanceDetails
            data={contactDetails}
            errors={errors}
            onDataChange={this.handleDataChange}
          />;
        break;
      case 3:
        selectedComponent =
          <Note
            data={contactDetails}
            errors={errors}
            onDataChange={this.handleDataChange}
          />;
        break;
      default:
        return null;
    }
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="create-contact-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{isEditContact ? t('edit_contact') : t('create_contact')}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
              <img alt="close-create-contact-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="create-contact-content">
          <ProjectTabs
            className="info-title-item"
            tabs={CreateContactAllTabs}
            selectedTab={selectedTabId}
            onTabItemClick={this.onTabItemClick}
          />
          {selectedComponent}
        </div>
        <div className="contact-btn-container">
          <Button
            className="cancel-btn"
            onClick={this.handleCloseBtn}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={() => this.handleCreateUpdateButton()}
          >
            { isEditContact ? t('update_contact').toUpperCase() : t('create_contact').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('close_contact')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
        {isRemoveAddressDialogOpen && (
          <RemoveDialog
            isOpen={isRemoveAddressDialogOpen}
            title={t('remove_shipping_address')}
            text={t('remove_shipping_address_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.removeSelectedAddress}
          />
        )}
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    customer: state.customers.customer,
    customersMutBusy: state.customers.customersMutBusy,
    errors: state.customers.customerError,
    isCustomerValid: state.customers.isCustomerValid,
    cities: state.locations.cities,
    countries: state.locations.countries,
    categories: state.categories.categories,
    contactCategoriesHierarchy: state.categories.contactCategoriesHierarchy,
    profileData: state.auth.profileData,
    metaData: state.customers.metaData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(resetCustomerData()),
    getCategories: (page, type, order, searchTerms, level1Only) => dispatch(getCategories({page, type, order, searchTerms, level1Only})),
    getOwnContactsCategoriesHierarchy: () => dispatch(getOwnContactsCategoriesHierarchy()),
    createCustomer: (data, loadListInfo) => dispatch(addCustomer(data, loadListInfo)),
    updateCustomer: (id, data, loadListInfo) => dispatch(updateCustomer(id, data, loadListInfo)),
    getCountries: () => dispatch(getCountries()),
    getCities: (id) => dispatch(getCities(id))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateContact));
