import { handleActions } from 'redux-actions';
import {
  GET_PROJECT_STATUSES,
  GET_PROJECT_STATUSES_SUCCESS,
  GET_PROJECT_STATUSES_ERROR
} from 'store/actionTypes/projectStatuses';

export const DEFAULT_PROJECT_STATUSES_STATE = {
  projectStatuses: [],
  projectStatusesError: null,
  projectStatusesBusy: false
};

export const projectStatuses = handleActions(
  {
    [GET_PROJECT_STATUSES]: (state, action) => ({
      ...state,
      projectStatusesBusy: true
    }),
    [GET_PROJECT_STATUSES_SUCCESS]: (state, action) => ({
      ...state,
      projectStatuses: action.payload,
      projectStatusesError: null,
      projectStatusesBusy: false
    }),
    [GET_PROJECT_STATUSES_ERROR]: (state, action) => ({
      ...state,
      projectStatusesError: action.payload.message,
      projectStatusesBusy: false
    })
  },
  DEFAULT_PROJECT_STATUSES_STATE
);
