import React from 'react';
import { withTranslation } from 'react-i18next';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import SubMaterial from './SubMaterial';

import './style.scss';

class StandardSurcharges extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDataChange = (index, dataObj) => {
    const { data, onDataChange } = this.props;
    const newData = [...data];
    newData[index] = dataObj;
    onDataChange(newData);
  }

  matchErrorToMaterial = (material) => {
    const { errors } = this.props;
    const materialErrorsKeys = Object.keys(errors).filter(el => el.indexOf(material.name) == 0);
    const errorObj = {};
    if (materialErrorsKeys.length > 0) {
      materialErrorsKeys.forEach(el => {
        errorObj[el.substring(material.name.length + 1)] = errors[el];
      });
    }
    return errorObj;
  }

  render() {
    const {
      data,
      t
    } = this.props;
    return (
      <div className="position-content-standard-surcharges">
        <div className={`position-info ${true ? 'bottom-borders' : ''}`}>
          <GridItem className="position-info-container">
            <GridItem className="position-info-title">
              <div className="position-title">{ t('material_standard_surcharges') }</div>
            </GridItem>
          </GridItem>
        </div>
        <div className="position-info-content">
          {
            data.length !== 0 &&
              <GridContainer className="position-tasks">
                <GridContainer className="position-info-header">
                  <GridItem className="pihi-name"><span>{ t('name') }</span></GridItem>
                  <GridItem className="pihi-discount"><span>{ t('discount') }</span></GridItem>
                  <GridItem className="pihi-overheads"><span>{ t('overheads') }</span></GridItem>
                  <GridItem className="pihi-waste"><span>{ t('waste') }</span></GridItem>
                </GridContainer>
                {data.map((material, index) => (
                  <SubMaterial
                    key={index}
                    data={material}
                    onDataChange={(data) => this.handleDataChange(index, data)}
                    errors={this.matchErrorToMaterial(material)}
                  />
                ))}
              </GridContainer>
          }
        </div>
      </div>
    )
  }
}

export default withTranslation()(StandardSurcharges);
