import { handleActions } from 'redux-actions';
import {
  GET_PROJECT_MANUFACTURERS,
  GET_PROJECT_MANUFACTURERS_SUCCESS,
  GET_PROJECT_MANUFACTURERS_ERROR
} from 'store/actionTypes/projectManufacturers';

export const DEFAULT_PROJECT_MANUFACTURERS_STATE = {
  projectManufacturers: [],
  projectManufacturersError: null,
  projectManufacturersBusy: false
};

export const projectManufacturers = handleActions(
  {
    [GET_PROJECT_MANUFACTURERS]: (state, action) => ({
      ...state,
      projectCategoriesBusy: true
    }),
    [GET_PROJECT_MANUFACTURERS_SUCCESS]: (state, action) => ({
      ...state,
      projectManufacturers: action.payload,
      projectManufacturersError: null,
      projectManufacturersBusy: false
    }),
    [GET_PROJECT_MANUFACTURERS_ERROR]: (state, action) => ({
      ...state,
      projectManufacturersError: action.payload.message,
      projectManufacturersBusy: false
    })
  },
  DEFAULT_PROJECT_MANUFACTURERS_STATE
);
