import BaseAPIService from './Base';

export default class InvoicesProvidersAPIService extends BaseAPIService {
  getPreTextPresets = async () => {
    return this.request(`api/invoices/provider/templates/1`).then((data) => {
      return data;
    });
  };

  getEndTextPresets = async () => {
    return this.request(`api/invoices/provider/templates/2`).then((data) => {
      return data;
    });
  };

  getLastPreEndText = async () => {
    return this.request(`api/invoices/provider/cover-text`).then((data) => {
      return data;
    });
  };

  getInvoiceTypesPresets = async (typeId) => {
    return this.request(`api/invoices/provider/templates/${typeId}`).then((data) => {
      return data;
    });
  };
}
