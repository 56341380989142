import React from 'react';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import DecimalInput from 'components/DecimalInput'
import Input from 'components/Input/Input';

import './style.scss';

class SubMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleInputValueChange = (fieldName, value) => {
    const { data } = this.props;
    this.props.onDataChange({
      ...data,
      settings: {
        ...data.settings,
        [fieldName]: value,
      }
    });
  }

  resolveErrorForField = (field) => {
    const { errors } = this.props;
    if (errors && errors[field] && errors[field][0])
      return errors[field][0];
    else
      return null;
  }

  render() {
    const { data } = this.props;
    const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
    const doesFieldExist = field => !(field === null || field === undefined);
    return (
      <GridContainer className="position-info-header submaterial-group">
        <GridItem className="pihi-category">
          <Input
            value={capitalize(data.name)}
            disabled={true}
          />
        </GridItem>
        <GridItem className="pihi-length">
          { doesFieldExist(data.settings.length) ? 
            <DecimalInput
              value={ data.settings.length }
              maxValue={100}
              onChange={value => this.handleInputValueChange('length', value)}
              error={this.resolveErrorForField('length')}
            /> : 
            null
          }
          { doesFieldExist(data.settings.length_fixed_value) && doesFieldExist(data.settings.length_percentage) ? 
            <div className="multiple-decimal-inputs">
              <DecimalInput
                value={ data.settings.length_fixed_value }
                className={"first-decimal-input"}
                maxValue={100}
                onChange={value => this.handleInputValueChange("length_fixed_value", value)}
                error={this.resolveErrorForField('length_fixed_value')}
              />
              <DecimalInput
                value={ data.settings.length_percentage }
                adornment="%"
                correctAdornmentPadding={true}
                maxValue={100}
                onChange={value => this.handleInputValueChange("length_percentage", value)}
                error={this.resolveErrorForField('length_percentage')}
              />
            </div> : 
            null
          }
        </GridItem>
        <GridItem className="pihi-width">
          <DecimalInput
            value={ data.settings.width }
            maxValue={100}
            onChange={value => this.handleInputValueChange('width', value)}
            error={this.resolveErrorForField('width')}
          />
        </GridItem>
        <GridItem className="pihi-thickness">
          <DecimalInput
            value={ data.settings.thickness }
            maxValue={100}
            onChange={value => this.handleInputValueChange('thickness', value)}
            error={this.resolveErrorForField('thickness')}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

export default SubMaterial;
