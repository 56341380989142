import BaseAPIService from './Base';

export default class ManufacturerScheduleAPIService extends BaseAPIService {
  getNormalSchedule = async (day) => {
    return this.request(`api/manufacturers/${day}/schedule`).then((data) => {
      return data;
    });
  };

  updateNormalSchedule = async (day, data) => {
    return this.request(`api/manufacturers/${day}/schedule`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  getDateSchedule = async (day) => {
    return this.request(`api/manufacturers/${day}/schedule/${day}`).then((data) => {
      return data;
    });
  };

  updateDateSchedule = async (day, data) => {
    return this.request(`api/manufacturers/${day}/schedule/${day}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  deleteDateSchedule = async (day) => {
    return this.request(`api/manufacturers/${day}/schedule/${day}`, {
      method: 'DELETE',
    });
  };

  getMonthSchedule = async (day, month) => {
    return this.request(`api/manufacturers/${day}/schedule/${month}`).then((data) => {
      return data;
    });
  };
}
