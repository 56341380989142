import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {validateOrderPositionTask, resetPositionTaskValidationErrors} from "../../store/actions/orders";
import {extractNonnegativeNumberFromString} from "../../utils/utils";

import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import EdgesStructure from'./EdgesStructure';
import GridItem from "../../components/Grid/GridItem";
import HelpButton from "../../assets/img/buttons/btn-help.svg";
import IconButton from '@material-ui/core/IconButton';
import InputWithIcon from "../../components/InputWithIcon";
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from "../../components/Input/Input";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import TextArea from "../../components/CustomTextArea";
import Typography from '@material-ui/core/Typography';

import HelpDialog from '../CreateTaskHelpDialog';
import CloseDialog from '../CloseDialog';

import './style.scss';
import Autocomplete from "../../components/CustomAutocomplete";
import {
  getCoveringMaterialsList,
  getEdgeMaterialsList,
  getMainMaterialsList,
  getSurfaceMaterialsList
} from "../../store/actions/materialProviders";
import Loader from "../../components/Loader/Loader";

class CreateTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHelpDialogOpen: false,
      isCloseDialogOpen: false,
      haveEdits: false,

      name: '',
      description: '',
      task_data_type: 0,
      due_date: null,
      estimate: null,
      additional_cost: null,
      data: {
        material_id: '',
        amount: '',
        length: '',
        width: '',
        thickness: '',
        unit: 1,
        panel_type: 1,
        edge_left_material_id: '',
        edge_left_amount: '',
        edge_right_material_id: '',
        edge_right_amount: '',
        edge_top_material_id: '',
        edge_top_amount: '',
        edge_bottom_material_id: '',
        edge_bottom_amount: '',
        edge_left_top_corner: null,
        edge_right_top_corner: null,
        edge_left_bottom_corner: null,
        edge_right_bottom_corner: null,
        surface_top_material_id: '',
        surface_top_amount: '',
        surface_bottom_material_id: '',
        surface_bottom_amount: '',
        covering_top_material_id: '',
        covering_top_amount: '',
        covering_bottom_material_id: '',
        covering_bottom_amount: ''
      }
    };
  }

  componentDidMount() {
    if (this.props.taskData)
      this.setInitialData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isTaskValid && this.props.isTaskValid ) {
      this.handleSaveTaskData()
    }
  }

  setInitialData = () => {
    const { taskData } = this.props;
    this.setState({
      name: taskData.name,
      description: taskData.description,
      due_date: taskData.due_date,
      estimate: taskData.estimate,
      additional_cost: taskData.additional_cost,
      data: taskData.data
    });
  };

  handleCloseBtn = () => {
    const {onClose, resetErrors} = this.props;
    resetErrors();
    onClose();
  };

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleCloseBtn();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
      haveEdits: false,
    }, () => {
      this.handleCloseBtn();
    });
  }

  handleToggleHelpDialog = () => {
    const {isHelpDialogOpen} = this.state;
    this.setState({isHelpDialogOpen: !this.state.isHelpDialogOpen});
  }

  handleChange = (fieldName, value) => {
    this.props.resetErrors();
    this.setState({
      [fieldName]: value,
      haveEdits: true,
    })
  };

  handleDataChange = (fieldName, value) => {
    this.props.resetErrors();
    const {edgeMaterials, coveringMaterials} = this.props;
    const {data} = this.state;
    const newData = {...data, [fieldName]: value};
    let stateCallbackFn = null;
    if (fieldName == 'edge_left_material_id') {
      const edgeMaterial = edgeMaterials.find(em => em.id == value);
      if (edgeMaterial && edgeMaterial.thickness && edgeMaterial.thickness > 0) {
        stateCallbackFn = () => {
          this.handleEdgesLeftAmountChange(edgeMaterial.thickness);
        };
      }
    } else if (fieldName == 'edge_right_material_id') {
      const edgeMaterial = edgeMaterials.find(em => em.id == value);
      if (edgeMaterial && edgeMaterial.thickness && edgeMaterial.thickness > 0) {
        stateCallbackFn = () => {
          this.handleEdgesRightAmountChange(edgeMaterial.thickness);
        };
      }
    } else if (fieldName == 'edge_top_material_id') {
      const edgeMaterial = edgeMaterials.find(em => em.id == value);
      if (edgeMaterial && edgeMaterial.thickness && edgeMaterial.thickness > 0) {
        stateCallbackFn = () => {
          this.handleEdgesTopAmountChange(edgeMaterial.thickness);
        };
      }
    } else if (fieldName == 'edge_bottom_material_id') {
      const edgeMaterial = edgeMaterials.find(em => em.id == value);
      if (edgeMaterial && edgeMaterial.thickness && edgeMaterial.thickness > 0) {
        stateCallbackFn = () => {
          this.handleEdgesBottomAmountChange(edgeMaterial.thickness);
        };
      }
    } else if (fieldName == 'covering_top_material_id') {
      const additionalFieldName = 'covering_top_amount';
      const coveringMaterial = coveringMaterials.find(em => em.id == value);
      if (coveringMaterial && coveringMaterial.thickness && coveringMaterial.thickness > 0) {
        newData[additionalFieldName] = coveringMaterial.thickness;
      }
    } else if (fieldName == 'covering_bottom_material_id') {
      const additionalFieldName = 'covering_bottom_amount';
      const coveringMaterial = coveringMaterials.find(em => em.id == value);
      if (coveringMaterial && coveringMaterial.thickness && coveringMaterial.thickness > 0) {
        newData[additionalFieldName] = coveringMaterial.thickness;
      }
    }

    this.setState({ data: newData, haveEdits: true }, stateCallbackFn);
  };

  handleEdgesLeftAmountChange = (value) => {
    this.props.resetErrors();
    const {data} = this.state;
    const newData = edgesLeftAmountChange(data, value);
    this.setState({data: newData});
  };

  handleEdgesRightAmountChange = (value) => {
    this.props.resetErrors();
    const {data} = this.state;
    const newData = edgesRightAmountChange(data, value);
    this.setState({data: newData});
  };

  handleEdgesTopAmountChange = (value) => {
    this.props.resetErrors();
    const {data} = this.state;
    const newData = edgesTopAmountChange(data, value);
    this.setState({data: newData});
  };

  handleEdgesBottomAmountChange = (value) => {
    this.props.resetErrors();
    const {data} = this.state;
    const newData = edgesBottomAmountChange(data, value);
    this.setState({data: newData});
  };

  handleTaskValidationChange = () => {
    const {taskValidation} = this.props;
    const {name, description, task_data_type, due_date, estimate, additional_cost, data} = this.state;
    const newData = {};
    Object.keys(data).map(item => data[item] !== "" ? newData[item] = data[item] : null);
    taskValidation({name, description, task_data_type, due_date, estimate, additional_cost, data: newData});
  };

  handleSaveTaskData = () => {
    const {onTaskDataSave, onCreateNewTask, taskId, preventPersistId, taskData} = this.props;
    const {name, description, task_data_type, due_date, estimate, additional_cost, data} = this.state;
    this.handleCloseBtn();
    if (!taskData)  {
      const newData = {};
      Object.keys(data).map(item => data[item] !== '' ? newData[item] = data[item] : null);
      onCreateNewTask({name, description, task_data_type, due_date, estimate, additional_cost, data: newData});
    } else {
      const obj = {name, description, task_data_type, due_date, estimate, additional_cost, data};
      if (!preventPersistId)
        obj['id'] = taskId;
      onTaskDataSave(obj);
    }
  };

  render() {
    const {
      isOpen,
      isMaterialsLoading,
      onClose,
      getMainMaterialsList,
      getEdgeMaterialsList,
      getSurfaceMaterialsList,
      getCoveringMaterialsList,
      materials,
      mainMaterials,
      edgeMaterials,
      coveringMaterials,
      surfaceMaterials,
      unitsList,
      taskId,
      errors,
      currencySymbol,
      t
    } = this.props;
    const {
      name,
      description,
      estimate,
      additional_cost,
      data,
      isHelpDialogOpen
    } = this.state;
    const initialMaterial = data.material_id ? materials.find(item => item.id === data.material_id) : '';
    const initialLeftEdgeMaterial = data.edge_left_material_id ? materials.find(item => item.id === data.edge_left_material_id) : '';
    const initialRightEdgeMaterial = data.edge_right_material_id ? materials.find(item => item.id === data.edge_right_material_id) : '';
    const initialTopEdgeMaterial = data.edge_top_material_id ? materials.find(item => item.id === data.edge_top_material_id) : '';
    const initialBottomEdgeMaterial = data.edge_bottom_material_id ? materials.find(item => item.id === data.edge_bottom_material_id) : '';
    const initialTopSurfaceMaterial = data.surface_top_material_id ? materials.find(item => item.id === data.surface_top_material_id) : '';
    const initialBottomSurfaceMaterial = data.surface_bottom_material_id ? materials.find(item => item.id === data.surface_bottom_material_id) : '';
    const initialTopCoveringMaterial = data.covering_top_material_id ? materials.find(item => item.id === data.covering_top_material_id) : '';
    const initialBottomCoveringMaterial = data.covering_bottom_material_id ? materials.find(item => item.id === data.covering_bottom_material_id) : '';
    const mainMaterialsActual = mainMaterials.map(el => ({
      ...el,
      value: el.code,
    }));
    const edgeMaterialsActual = edgeMaterials.map(el => ({
      ...el,
      value: el.code,
    }));
    const surfaceMaterialsActual = surfaceMaterials.map(el => ({
      ...el,
      value: el.code,
    }));
    const coveringMaterialsActual = coveringMaterials.map(el => ({
      ...el,
      value: el.code,
    }));
    return (
      <Dialog
        maxWidth={false}
        open={isOpen && unitsList.length !== 0}
      >
        <MuiDialogTitle className="create-task-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ taskId === undefined || taskId === null ? t('add_item') : t('item_details') }</Typography>
          {onClose ? (
            <div>
              <IconButton aria-label="close" className="info-btn" onClick={this.handleToggleHelpDialog}>
                <img alt="help-btn" src={HelpButton}/>
              </IconButton>
              <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
                <img alt="close-task-btn" src={CloseButton}/>
              </IconButton>
            </div>
          ) : null}
        </MuiDialogTitle>
        <div className="create-task-content">
          {
            !isMaterialsLoading
              ?
                <>
                  <GridItem className="task-content-top">
                    <GridItem className="task-content-item task-name" xs={12}>
                      <span>{ t('item_name') }</span>
                      <Input
                        onChange={(e) => this.handleChange('name', e.target.value)}
                        value={name}
                        error={errors && errors.name}
                      />
                    </GridItem>
                    <GridItem className="task-material-amount-dimensions">
                      <GridItem className="task-content-item material" xs={4}>
                        <span>{ t('material') }</span>
                        <Autocomplete
                          buttonText={data.material_id ? data.material_id : ''}
                          buttonProps={{className: '', round: true, size: "sm"}}
                          dropdownList={mainMaterialsActual}
                          error={errors && errors['data.material_id']}
                          hoverColor="dark"
                          getData={getMainMaterialsList}
                          onClick={(value) => this.handleDataChange('material_id', value.id)}
                          placeholder={t('enter')}
                          initialText={initialMaterial ? initialMaterial.value : ''}
                          discardIcon={true}
                        />
                      </GridItem>
                      <GridItem className="task-content-item amount" xs={2}>
                        <span>{ t('amount') }</span>
                        <Input
                          onChange={(e) => this.handleDataChange('amount', e.target.value)}
                          value={data.amount}
                          error={errors && errors['data.amount']}
                        />
                      </GridItem>
                      <GridItem className="task-content-item metrics" xs={2}>
                        <span>{ t('metrics') }</span>
                        <Dropdown
                          buttonText={data.unit ? data.unit : ''}
                          buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                          dropdownList={unitsList}
                          error={errors && errors['data.unit']}
                          hoverColor="dark"
                          onClick={(value) => this.handleDataChange('unit', value.id)}
                        />
                      </GridItem>
                      <GridItem className="task-content-item dimensions" xs={4}>
                        <span>{ t('finished_dimensions') }</span>
                        <GridItem className="dimensions-inputs">
                          <InputWithIcon
                            className="edges-l"
                            onChange={(e) => this.handleDataChange('length', e.target.value)}
                            value={data.length}
                            error={errors && errors['data.length']}
                            endAdornment={<InputAdornment position="end">L</InputAdornment>}
                          />
                          <InputWithIcon
                            className="edges-r"
                            onChange={(e) => this.handleDataChange('width', e.target.value)}
                            value={data.width}
                            error={errors && errors['data.width']}
                            endAdornment={<InputAdornment position="end">W</InputAdornment>}
                          />
                          <InputWithIcon
                            className="edges-b"
                            onChange={(e) => this.handleDataChange('thickness', e.target.value)}
                            value={data.thickness}
                            error={errors && errors['data.thickness']}
                            endAdornment={<InputAdornment position="end">T</InputAdornment>}
                          />
                        </GridItem>
                      </GridItem>
                    </GridItem>
                  </GridItem>
                  <GridItem className="task-content-middle">
                    <GridItem className="task-content-middle-left">
                      <GridItem className="task-content-item">
                        <span>{ t('edges') }</span>
                        <GridItem className="edges-inputs">
                          < Autocomplete
                            buttonText={data.edge_left_material_id ? data.edge_left_material_id : ''}
                            initialText={initialLeftEdgeMaterial ? initialLeftEdgeMaterial.value : ''}
                            buttonProps={{className: '', round: true, size: "sm"}}
                            className={'edges-autocomplete'}
                            dropdownList={edgeMaterialsActual}
                            getData={getEdgeMaterialsList}
                            error={errors && errors['data.edge_left_material_id']}
                            hoverColor="dark"
                            onClick={(value) => this.handleDataChange('edge_left_material_id', value.id)}
                          />
                          <Autocomplete
                            buttonText={data.edge_right_material_id ? data.edge_right_material_id : ''}
                            initialText={initialRightEdgeMaterial ? initialRightEdgeMaterial.value : ''}
                            buttonProps={{className: '', round: true, size: "sm"}}
                            className={'edges-autocomplete'}
                            dropdownList={edgeMaterialsActual}
                            getData={getEdgeMaterialsList}
                            error={errors && errors['data.edge_right_material_id']}
                            hoverColor="dark"
                            onClick={(value) => this.handleDataChange('edge_right_material_id', value.id)}
                          />
                          <Autocomplete
                            buttonText={data.edge_top_material_id ? data.edge_top_material_id : ''}
                            initialText={initialTopEdgeMaterial ? initialTopEdgeMaterial.value : ''}
                            buttonProps={{className: '', round: true, size: "sm"}}
                            className={'edges-autocomplete'}
                            dropdownList={edgeMaterialsActual}
                            getData={getEdgeMaterialsList}
                            error={errors && errors['data.edge_top_material_id']}
                            hoverColor="dark"
                            onClick={(value) => this.handleDataChange('edge_top_material_id', value.id)}
                          />
                          <Autocomplete
                            buttonText={data.edge_bottom_material_id ? data.edge_bottom_material_id : ''}
                            initialText={initialBottomEdgeMaterial ? initialBottomEdgeMaterial.value : ''}
                            buttonProps={{className: '', round: true, size: "sm"}}
                            className={'edges-autocomplete'}
                            dropdownList={edgeMaterialsActual}
                            getData={getEdgeMaterialsList}
                            error={errors && errors['data.edge_bottom_material_id']}
                            hoverColor="dark"
                            onClick={(value) => this.handleDataChange('edge_bottom_material_id', value.id)}
                          />
                        </GridItem>
                        <GridItem className="edges-inputs">
                          <InputWithIcon
                            className="edges-l"
                            onChange={(e) => this.handleEdgesLeftAmountChange(e.target.value)}
                            value={data.edge_left_amount}
                            error={errors && errors['data.edge_left_amount']}
                            disableTabFocus={true}
                            endAdornment={<InputAdornment position="end">L</InputAdornment>}
                          />
                          <InputWithIcon
                            className="edges-r edges-r1"
                            onChange={(e) => this.handleEdgesRightAmountChange(e.target.value)}
                            value={data.edge_right_amount}
                            error={errors && errors['data.edge_right_amount']}
                            disableTabFocus={true}
                            endAdornment={<InputAdornment position="end">R</InputAdornment>}
                          />
                          <InputWithIcon
                            className="edges-r edges-r2"
                            onChange={(e) => this.handleEdgesTopAmountChange(e.target.value)}
                            value={data.edge_top_amount}
                            error={errors && errors['data.edge_top_amount']}
                            disableTabFocus={true}
                            endAdornment={<InputAdornment position="end">T</InputAdornment>}
                          />
                          <InputWithIcon
                            className="edges-b"
                            onChange={(e) => this.handleEdgesBottomAmountChange(e.target.value)}
                            value={data.edge_bottom_amount}
                            error={errors && errors['data.edge_bottom_amount']}
                            disableTabFocus={true}
                            endAdornment={<InputAdornment position="end">B</InputAdornment>}
                          />
                        </GridItem>
                      </GridItem>
                    </GridItem>
                    <GridItem className="edges-structure-img">
                      <EdgesStructure
                        edge_left_amount={data.edge_left_amount}
                        edge_right_amount={data.edge_right_amount}
                        edge_top_amount={data.edge_top_amount}
                        edge_bottom_amount={data.edge_bottom_amount}
                        edge_left_top_corner={data.edge_left_top_corner}
                        edge_right_top_corner={data.edge_right_top_corner}
                        edge_left_bottom_corner={data.edge_left_bottom_corner}
                        edge_right_bottom_corner={data.edge_right_bottom_corner}
                        panel_type={data.panel_type}
                        onChange={this.handleDataChange}
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem className="task-content-bottom" xs={11}>
                    <GridItem className="task-content-bottom-left" xs={6}>
                      <GridItem className="task-content-item surface-item">
                        <span>{ t('surface') }</span>
                        <GridItem className="surface-coverings-inputs surface-inputs">
                          <Autocomplete
                            buttonText={data.surface_top_material_id ? data.surface_top_material_id : ''}
                            initialText={initialTopSurfaceMaterial ? initialTopSurfaceMaterial.value : ''}
                            buttonProps={{className: '', round: true, size: "sm"}}
                            className= {'surface-left'}
                            dropdownList={surfaceMaterialsActual}
                            getData={getSurfaceMaterialsList}
                            hoverColor="dark"
                            disableTabFocus={true}
                            onClick={(value) => this.handleDataChange('surface_top_material_id', value.id)}
                            error={errors && errors['data.surface_top_material_id']}
                          />
                          <Autocomplete
                            buttonText={data.surface_bottom_material_id ? data.surface_bottom_material_id : ''}
                            initialText={initialBottomSurfaceMaterial ? initialBottomSurfaceMaterial.value : ''}
                            buttonProps={{className: '', round: true, size: "sm"}}
                            className={'surface-right'}
                            dropdownList={surfaceMaterialsActual}
                            getData={getSurfaceMaterialsList}
                            hoverColor="dark"
                            disableTabFocus={true}
                            onClick={(value) => this.handleDataChange('surface_bottom_material_id', value.id)}
                            error={errors && errors['data.surface_bottom_material_id']}
                          />
                        </GridItem>
                      </GridItem>
                    </GridItem>
                    <GridItem className="task-content-bottom-right" xs={6}>
                      <GridItem className="task-content-item coverings-item" >
                        <span>{ t('coverings') }</span>
                        <GridItem className="surface-coverings-inputs">
                          <Autocomplete
                            buttonText={data.covering_top_material_id ? data.covering_top_material_id : ''}
                            initialText={initialTopCoveringMaterial ? initialTopCoveringMaterial.value : ''}
                            buttonProps={{className: '', round: true, size: "sm"}}
                            className={'coverings-left'}
                            dropdownList={coveringMaterialsActual}
                            getData={getCoveringMaterialsList}
                            hoverColor="dark"
                            disableTabFocus={true}
                            onClick={(value) => this.handleDataChange('covering_top_material_id', value.id)}
                            error={errors && errors['data.covering_top_material_id']}
                          />
                          <Autocomplete
                            buttonText={data.covering_bottom_material_id ? data.covering_bottom_material_id : ''}
                            initialText={initialBottomCoveringMaterial ? initialBottomCoveringMaterial.value : ''}
                            buttonProps={{className: '', round: true, size: "sm"}}
                            dropdownList={coveringMaterialsActual}
                            getData={getCoveringMaterialsList}
                            hoverColor="dark"
                            disableTabFocus={true}
                            onClick={(value) => this.handleDataChange('covering_bottom_material_id', value.id)}
                            error={errors && errors['data.covering_bottom_material_id']}
                          />
                        </GridItem>
                        <GridItem className="surface-coverings-inputs" xs={12}>
                          <InputWithIcon
                            className="sur-coverings-t"
                            onChange={(e) => this.handleDataChange('covering_top_amount', e.target.value)}
                            value={data.covering_top_amount}
                            disableTabFocus={true}
                            error={errors && errors['data.covering_top_amount']}
                            endAdornment={<InputAdornment position="end">T</InputAdornment>}
                          />
                          <InputWithIcon
                            className="sur-coverings-b"
                            onChange={(e) => this.handleDataChange('covering_bottom_amount', e.target.value)}
                            value={data.covering_bottom_amount}
                            disableTabFocus={true}
                            error={errors && errors['data.covering_bottom_amount']}
                            endAdornment={<InputAdornment position="end">B</InputAdornment>}
                          />
                        </GridItem>
                      </GridItem>
                    </GridItem>
                  </GridItem>
                  <GridItem className="task-costs">
                    <GridItem className="task-content-item">
                      <span>{ t('additional_production_cost') }</span>
                      <InputWithIcon
                        onChange={(e) => this.handleChange('additional_cost', e.target.value)}
                        value={additional_cost}
                        error={errors.additional_cost && errors.additional_cost[0]}
                        endAdornment={<InputAdornment position="end">{currencySymbol}</InputAdornment>}
                      />
                    </GridItem>
                    <GridItem className="task-content-item">
                      <span>{ t('estimated_time') }</span>
                      <InputWithIcon
                        onChange={(e) => this.handleChange('estimate', e.target.value)}
                        value={estimate}
                        error={errors.estimate && errors.estimate[0]}
                        endAdornment={<InputAdornment position="end">H</InputAdornment>}
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem className="task-content-top">
                    <GridItem className="task-description" xs={12}>
                      <div className="description-area">
                        <span>{ t('task_description') }</span>
                        <div className="description-test-area">
                          <TextArea
                            value={description}
                            onChange={ (e) => this.handleChange('description', e.target.value)}
                            error={errors && errors['description']}
                          />
                        </div>
                      </div>
                    </GridItem>
                  </GridItem>
                </>
              :
                <div className="task-loader">
                  <Loader />
                </div>
          }
        </div>
        <div className="create-task-btn-container">
          <Button
            className="cancel-btn"
            onClick={onClose}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleSaveTaskData}
          >
            { taskId === undefined || taskId === null ? t('create_item').toUpperCase() : t('save').toUpperCase() }
          </Button>
        </div>
        {
          isHelpDialogOpen && <HelpDialog isOpen={isHelpDialogOpen} onClose={this.handleToggleHelpDialog}/>
        }
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('close_task')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    isMaterialsLoading: state.providers.isMaterialsBusy,
    unitsList: state.providers.taskUnitList,
    isTaskValid: state.orders.isOrderPositionTaskValid,
    errors: state.orders.orderPositionTaskValidationErrors,
    materials: state.providers.materialsList,
    mainMaterials: state.materialProviders.mainMaterials,
    edgeMaterials: state.materialProviders.edgeMaterials,
    coveringMaterials: state.materialProviders.coveringMaterials,
    surfaceMaterials: state.materialProviders.surfaceMaterials,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMainMaterialsList: (term) => dispatch(getMainMaterialsList(term)),
    getEdgeMaterialsList: (term) => dispatch(getEdgeMaterialsList(term)),
    getSurfaceMaterialsList: (term) => dispatch(getSurfaceMaterialsList(term)),
    getCoveringMaterialsList: (term) => dispatch(getCoveringMaterialsList(term)),
    taskValidation: (data) => dispatch(validateOrderPositionTask(data)),
    resetErrors: (data) => dispatch(resetPositionTaskValidationErrors(data)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateTask));

export function edgesLeftAmountChange(data, value) {
  const newData = {...data};
  if (
    value === '' && (data.edge_top_amount === '' || data.edge_top_amount === undefined) && (data.edge_bottom_amount === '' || data.edge_bottom_amount === undefined)
  ) {
    newData.edge_left_top_corner = null;
    newData.edge_left_bottom_corner = null
  }
  if (value === '' && (data.edge_top_amount !== '' && data.edge_top_amount !== undefined)) {
    newData.edge_left_top_corner = 2;
  }
  if (value === '' && (data.edge_bottom_amount !== '' && data.edge_bottom_amount !== undefined)) {
    newData.edge_left_bottom_corner = 2;
  }
  if (value === '' && (data.edge_top_amount === '' && data.edge_top_amount === undefined)) {
    newData.edge_left_top_corner = null;
  }
  if (value === '' && (data.edge_bottom_amount === '' || data.edge_bottom_amount === undefined)) {
    newData.edge_left_bottom_corner = null;
  }
  if (data.edge_left_top_corner === null) {
    newData.edge_left_top_corner = 0
  }
  if (data.edge_left_bottom_corner === null) {
    newData.edge_left_bottom_corner = 0
  }
  newData.edge_left_amount = value;
  return newData;
};

export function edgesRightAmountChange(data, value) {
  const newData = {...data};
  if (
    value === '' && (data.edge_top_amount === '' || data.edge_top_amount === undefined) && (data.edge_bottom_amount === '' || data.edge_bottom_amount === undefined)
  ) {
    newData.edge_right_top_corner = null;
    newData.edge_right_bottom_corner = null
  }
  if (value === '' && (data.edge_top_amount !== '' && data.edge_top_amount !== undefined)) {
    newData.edge_right_top_corner = 2;
  }
  if (value === '' && (data.edge_bottom_amount !== '' && data.edge_bottom_amount !== undefined)) {
    newData.edge_right_bottom_corner = 2;
  }
  if (value === '' && (data.edge_top_amount === '' || data.edge_top_amount === undefined)) {
    newData.edge_right_top_corner = null;
  }
  if (value === '' && (data.edge_bottom_amount === '' || data.edge_bottom_amount === undefined)) {
    newData.edge_right_bottom_corner = null;
  }
  if (data.edge_right_top_corner === null) {
    newData.edge_right_top_corner = 0
  }
  if (data.edge_right_bottom_corner === null) {
    newData.edge_right_bottom_corner = 0
  }
  newData.edge_right_amount = value;
  return newData;
}

export function edgesTopAmountChange(data, value) {
  const newData = {...data};
  if (
    value === '' && (data.edge_left_amount === '' || data.edge_left_amount === undefined) && (data.edge_right_amount === '' || data.edge_right_amount === undefined)
  ) {
    newData.edge_left_top_corner = null;
    newData.edge_right_top_corner = null
  }
  if (value === '' && (data.edge_left_amount !== '' && data.edge_left_amount !== undefined)) {
    newData.edge_left_top_corner = 0;
  }
  if (value === '' && (data.edge_right_amount !== '' && data.edge_right_amount !== undefined)) {
    newData.edge_right_top_corner = 0;
  }
  if (value === '' && (data.edge_left_amount === '' || data.edge_left_amount === undefined)) {
    newData.edge_left_top_corner = null;
  }
  if (value === '' && (data.edge_right_amount === '' || data.edge_right_amount === undefined)) {
    newData.edge_right_top_corner = null;
  }
  if (data.edge_left_top_corner === null) {
    newData.edge_left_top_corner = 2
  }
  if (data.edge_right_top_corner === null) {
    newData.edge_right_top_corner = 2
  }
  newData.edge_top_amount = value;
  return newData;
}

export function edgesBottomAmountChange(data, value) {
  const newData = {...data};
  if (
    value === '' && (data.edge_left_amount === '' || data.edge_left_amount === undefined) && (data.edge_right_amount === '' || data.edge_right_amount === undefined)
  ) {
    newData.edge_left_bottom_corner = null;
    newData.edge_right_bottom_corner = null
  }
  if (value === '' && (data.edge_left_amount !== '' && data.edge_left_amount !== undefined)) {
    newData.edge_left_bottom_corner = 0;
  }
  if (value === '' && (data.edge_right_amount !== '' && data.edge_right_amount !== undefined)) {
    newData.edge_right_bottom_corner = 0;
  }
  if (value === '' && (data.edge_left_amount === '' || data.edge_left_amount === undefined)) {
    newData.edge_left_bottom_corner = null;
  }
  if (value === '' && (data.edge_right_amount === '' || data.edge_right_amount === undefined)) {
    newData.edge_right_bottom_corner = null;
  }
  if (data.edge_left_bottom_corner === null) {
    newData.edge_left_bottom_corner = 2
  }
  if (data.edge_right_bottom_corner === null) {
    newData.edge_right_bottom_corner = 2
  }
  newData.edge_bottom_amount = value;
  return newData;
}
