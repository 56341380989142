import BaseAPIService from './Base';

export default class UserAPIService extends BaseAPIService {
  registerManufacturer = async (data) => {
    return this.request('api/registration/manufacturer', {
      method: 'POST',
      body: data
    });
  };

  forgotPassword = async (data) => {
    return this.request('api/auth/reset-password', {
      method: 'POST',
      body: data
    });
  };

  getUserOwn = async () => {
    return this.request('api/auth/me').then((data) => {
      return data;
    });
  };

  getProfileDetails = async () => {
    return this.request(`api/manufacturers/details`)/*.then((data) => {
      return data;
    })*/;
  };
}
