import { handleActions } from 'redux-actions';
import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR
} from '../actionTypes/rolesActionTypes';

export const DEFAULT_ROLES_STATE = {
  roles: [],
  rolesError: {},
  rolesBusy: false
};

export const roles = handleActions(
  {
    [GET_ROLES]: (state, action) => ({
      ...state,
      rolesBusy: true
    }),
    [GET_ROLES_SUCCESS]: (state, action) => ({
      ...state,
      roles: action.payload.data,
      rolesBusy: false
    }),
    [GET_ROLES_ERROR]: (state, action) => ({
      ...state,
      rolesError: action.payload.message,
      rolesBusy: false
    })
  },
  DEFAULT_ROLES_STATE
);
