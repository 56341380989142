/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import { withTranslation } from 'react-i18next';
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import SidebarPopper from './SidebarPopper/SidebarPopper';
import Icon from "@material-ui/core/Icon";
import { getPersistedSidebarState, persistSidebarState } from '../../utils/utils';
import "./styles.scss";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import Logo from "../../assets/img/logo/logo-icon.svg";
import Arrow from "../../assets/img/buttons/sidebar-arrow.svg";
import CollapseArrow from "../../assets/img/buttons/chevron-down.svg";
import { isUserAuthorized } from 'utils/utils';

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  mainPanel = React.createRef();

  popperAnchor;
  sidebarDrawer = React.createRef();
  popper = React.createRef();

  constructor(props) {
    super(props);

    // create local storage state
    let sidebarState = getPersistedSidebarState();
    if (props &&
      (!sidebarState || !sidebarState.routes || (Object.keys(sidebarState.routes).length !== props.routes.filter(el => Boolean(el.state)).length))) {
      // create default values - everything collapsed
      if (!sidebarState)
        sidebarState = {};
      sidebarState.routes = {};
      props.routes.map(el => {
        if (el.state)
          sidebarState.routes[el.state] = false;
        return null;
      });
      persistSidebarState(sidebarState);
    }

    this.state = {
      openAvatar: false,
      miniActive: true,
      popperShow: false,
      popperRoute: null,
      ...this.getCollapseStates(props.routes)
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleGlobalClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleGlobalClick);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const sidebarState = getPersistedSidebarState();
    const routes = this.props.routes;
    let haveMatch = false;
    for (let i in routes) {
      const routeState = routes[i].state;
      if (prevState[routeState] != this.state[routeState]) {
        sidebarState.routes[routeState] = this.state[routeState]; // lhs is guaranteed to exist by now
        haveMatch = true;
      }
    }
    if (haveMatch) {
      persistSidebarState(sidebarState);
    }
  }

  handleGlobalClick = (event) => {
    // hide popper if conditions are met
    if(this.state.popperShow) {
      const isMouseInsideElement = (event, element) => {
        const boundingRect = element.getBoundingClientRect();

        return (event.clientX >= boundingRect.left && event.clientX <= boundingRect.right &&
          event.clientY >= boundingRect.top && event.clientY <= boundingRect.bottom);
      };
      if ( (this.popper.current && !isMouseInsideElement(event, this.popper.current)) &&
        (this.sidebarDrawer.current && !isMouseInsideElement(event, this.sidebarDrawer.current.firstChild)) ) {
          const state = {
            ...this.state,
            popperRoute: null,
            popperShow: false
          };
          this.setState(state);
      }
    }
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};

    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });

    // read collapsed states from local storage, current route needs to be expanded by default
    let sidebarState = getPersistedSidebarState();
    Object.keys(initialState).map(key => {
      if (sidebarState.routes[key])
        initialState[key] = sidebarState.routes[key];
      return null;
    });
    const currentRoutes = routes.map(prop => {
      if (prop.collapse) {
        return this.getCollapseInitialState(prop.views);
      }
      return false;
    });
    const currentRouteIndex = currentRoutes.indexOf(true);
    if (currentRouteIndex != -1) {
      initialState[routes[currentRouteIndex].state] =  true;
    }

    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  openCollapse(collapse) {
    // unused method
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  };

  handleMinimizeSidebar = () => {
    const {sidebarMinimize, routes} = this.props;
    sidebarMinimize();
    const newState = this.getCollapseStates(routes);
    // how efficient are the following two lines?
    Object.keys(newState).map(item => this.setState({[item]: false}));
    this.setState({popperShow: false, popperRoute: null});
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes, notTopLevel) => {
    const { classes, color, rtlActive, t } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect || prop.isHidden || (prop.auth && !isUserAuthorized(prop.auth))) {
        return null;
      }
      
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            )
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive
          });
        let propName = rtlActive ? prop.rtlName : prop.name;
        if (!notTopLevel) {
          propName = t(propName.toLowerCase()).toUpperCase();
        } else {
          propName = t(propName);
          propName = propName.substring(0, 1).toUpperCase() + propName.substring(1);
        }
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.popperAnchor = e.target;
                st["popperShow"] = this.props.miniActive;
                st["popperRoute"] = prop;
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <div className={itemIcon}>
                    <svg fill={'white'} stroke={'#3d73dd'} width={'20px'} height={'20px'}  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <use xlinkHref={`${prop.icon}#${prop.iconId}`} />
                    </svg>
                  </div>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={propName}
                secondary={
                  <>
                    <span></span>
                    <img alt="caret-white" src={Arrow} className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    } />
                  </>
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={!this.props.miniActive && this.state[prop.state]} unmountOnExit classes={{wrapper: classes.collapseWrapper}}>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views, true)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive
        });
      let propName = rtlActive ? prop.rtlName : prop.name;
      if (!notTopLevel) {
        propName = t(propName.toLowerCase()).toUpperCase();
      } else {
        propName = t(propName);
        propName = propName.substring(0, 1).toUpperCase() + propName.substring(1);
      }
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={`${prop.layout}${prop.path}${prop.id ? `/${prop.id}` : ''}`}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <div className={itemIcon}>
                  <svg fill={'white'} stroke={'#3d73dd'} width={'20px'} height={'20px'}  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use xlinkHref={`${prop.icon}#${prop.iconId}`} />
                  </svg>
                </div>
              ) : (
                <div className={itemIcon}>
                  <svg fill={'red'} width={'16px'} height={'16px'} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use xlinkHref={`${prop.icon}#${prop.iconId}`} />
                  </svg>
                </div>
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={propName}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };
  createPopperLinks = routeViews => {
    const { t } = this.props;
    return routeViews.map((prop, key) => {
      if(prop.redirect || prop.isHidden || (prop.auth && !isUserAuthorized(prop.auth)))
        return null;

      let propName = t(prop.name);
      propName = propName.substring(0, 1).toUpperCase() + propName.substring(1);

      return (
        <ListItem
          className={cx(
            { [this.props.classes.item]: true }
          )}
          key={key}>
          <NavLink
            onClick={e => {
              e.stopPropagation();
              const newState = {
                ...this.state,
                popperRoute: null,
                popperShow: false
              };
              this.setState(newState);
            }}
            to={`${prop.layout}${prop.path}${prop.id ? `/${prop.id}` : ''}`}>
              <ListItemText
                primary={propName}
                disableTypography={true}
              />
          </NavLink>
        </ListItem>
      );
    });
  };

  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive
    } = this.props;

    const storageLogo = localStorage.getItem("authManufacturerLogo");
    const actualLogo = storageLogo && storageLogo !== "none" ? storageLogo : Logo;

    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive
      });
    var user = (
      <div></div>
    );
    var links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    var brand = (
      <div className={logoClasses + (this.props.miniActive ? " logo-container-add-mini" : " logo-container-add")}>
        <a
          target="_blank"
          className={logoMini + (this.props.miniActive ? " img-anchor-mini" : " img-anchor")}
        >
          <img src={actualLogo} alt="logo" className={classes.img} />
        </a>
      </div>
    );
    const bottomLogoClasses =
      classes.bottomLogo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    var bottomLogo = (
      <div className={bottomLogoClasses}>
        {
          !this.props.miniActive &&
          <span className={classes.bottomSpan}>POWERED BY</span>
        }
        <a
          target="_blank"
          className={logoMini}
        >
          <div className={classes.bottomLogoImg}>
            <img src={Logo} alt="logo" className={classes.bottomImg} />
            {
              !this.props.miniActive &&
              <div
                className={classes.bottomLogoText}
              >
                <div className={classes.bottomLogoBlackText}>
                  Elements
                </div>
                <div className={classes.bottomLogoBlueText}>
                  WEB
                </div>
              </div>
            }
          </div>
        </a>
        {
          !this.props.miniActive &&
            <div onClick={this.handleMinimizeSidebar}>
              <img alt="caret-white" src={CollapseArrow} className={classes.collapseArrow} />
            </div>
        }
        {
          this.props.miniActive &&
          <div className={classes.collapseOpenArrowContainer} onClick={this.props.sidebarMinimize}>
            <img alt="caret-white" src={CollapseArrow} className={classes.collapseOpenArrow} />
          </div>
        }
      </div>
    );

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

    return (
      <div ref={this.mainPanel}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {bottomLogo}
            {/*{
              this.props.miniActive &&
              <img alt="caret-white" src={Arrow} />
            }*/}
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            ref={this.sidebarDrawer}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {bottomLogo}
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <SidebarPopper ref={this.popper} open={this.props.miniActive && this.state.popperShow} anchorEl={this.popperAnchor} sidebar={this.sidebarDrawer.current ? this.sidebarDrawer.current.firstChild : null}>
          <List className={this.props.classes.list} style={{marginTop: "0"}}>
            { this.state.popperRoute && this.createPopperLinks(this.state.popperRoute.views)}
          </List>
        </SidebarPopper>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object
};

export default withStyles(sidebarStyle)(withTranslation()(Sidebar));
