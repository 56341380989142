import React from "react";

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ErrorTooltip, ErrorTooltipAlt } from '../../components/ErrorTooltip/ErrorTooltip';

import './style.scss';

import Button from '../../components/CustomButtons/Button';
import DecimalInput from 'components/DecimalInput'
import Dropdown from "../../components/CustomDropdown/CustomDropdown";

import CloseButton from "../../assets/img/buttons/delete.svg";

import { resetMaterialData, updateMultipleMaterialsPrice } from "../../store/actions/materials";
import WarningDialog from '../WarningDialog';
import CloseDialog from '../CloseDialog';

class UpdateMaterialPrice extends React.Component {
  static defaultState = {
    price_multiplier: 0,

    lastEdit: null,

    lastProcessedErrors: null,
    errors: {
      price_multiplier: null,
    },

    isCloseDialogOpen: false,
    isWarningDialogOpen: false,
    haveEdits: false,
  };

  constructor(props) {
    super(props);

    this.state = UpdateMaterialPrice.defaultState;
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};
    if (props.requestErrors && props.requestErrors != state.lastProcessedErrors) {
      const errorsString = props.requestErrors.toString();
      const needle = "Response body: ";
      const position = errorsString.indexOf(needle);
      if (position != -1) {
        const substring = errorsString.substring(position + needle.length);
        try {
          const errorsObj = JSON.parse(substring);
          if(errorsObj.errors) {
            const errors = {
              price_multiplier: errorsObj.errors['price_multiplier'],
            };
            newState = {
              ...newState,
              errors,
              lastProcessedErrors: props.requestErrors,
            };
          }
        } catch(e) {
        }
      }
    }
    return newState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isMultipleMaterialsUpdateValid && this.props.isMultipleMaterialsUpdateValid ) {
      this.handleOnClose();
    }
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  handleOnClose = () => {
    // reset the state
    this.setState(UpdateMaterialPrice.defaultState);
    this.props.resetData();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleOnClose();
    }
  }

  handleOnWarningPromptCancel = () => {
    this.setState({
      isWarningDialogOpen: false,
    });
  }

  handleOnWarningPromptConfirm = () => {
    this.setState({
      isWarningDialogOpen: false,
    }, () => {
      this.handleUpdate();
    });
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleOnClose();
    });
  }

  handleInputValueChange = (name, value) => {
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: false,
      },
      haveEdits: true,
    });
  }

  handleUpdate = () => {
    const { selectedMaterials } = this.props;
    const { price_multiplier } = this.state;
    this.props.updateMultipleMaterialsPrice(selectedMaterials, parseFloat(price_multiplier), { page: this.props.currentPage, filters: this.props.currentFilters, searchTerms: this.props.currentSearchTerms });
  }

  handleUpdateButton = () => {
    this.setState({
      isWarningDialogOpen: !this.state.isWarningDialogOpen,
    });
  }

  render() {
    const { isOpen, t } = this.props;
    const errors = this.state.errors;

    const sanitizeTooltipErrorTitle = error => (error ? error[0] : "");
    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'update-material-price-dialog',
          }
        }}>
        <MuiDialogTitle className="update-material-price-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{t('update_selected_materials_price')}</Typography>
          <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
          <img
            alt="update-material-price-close-btn"
            src={CloseButton}
          />
          </IconButton>
        </MuiDialogTitle>
        <div className="update-material-price-content">
          <div className="update-material-price-info">
            <div className="info-section">
              <span className="info-title">{t('purchasing_price_percentage')}:</span>
              <ErrorTooltip title={sanitizeTooltipErrorTitle(errors.price_multiplier)}>
                <div className="text-input">
                  <DecimalInput
                    value={ this.state.price_multiplier }
                    adornment="%"
                    onChange={value => this.handleInputValueChange('price_multiplier', value)}
                    error={errors.price_multiplier}
                  />
                </div>
              </ErrorTooltip>
            </div>
          </div>
        </div>
        <div className="update-material-price-btn-container">
          <Button
            className="cancel-btn"
            onClick={ this.handleOnClose }
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleUpdateButton}
            disabled={this.props.updateInProgress}
          >
            { t('update').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('update_selected_materials_price')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
        <WarningDialog
          isOpen={this.state.isWarningDialogOpen}
          title={t('confirm_update_selected_materials_price')}
          text={t('update_selected_materials_price_confirmation')}
          buttonText={ t('update').toUpperCase() }
          onCancel={this.handleOnWarningPromptCancel}
          onConfirm={this.handleOnWarningPromptConfirm}
        />
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    updateInProgress: state.materials.materialsBusy,
    requestErrors: state.materials.materialsError,
    isMultipleMaterialsUpdateValid: state.materials.isMultipleMaterialsUpdateValid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(resetMaterialData()),
    updateMultipleMaterialsPrice: (ids, data, loadListInfo) => dispatch(updateMultipleMaterialsPrice(ids, data, loadListInfo)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(UpdateMaterialPrice));
