import React from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {config} from "../../../Config";
import {
  setManufacturerStatus,
  filterManufacturers,
  getManufacturer,
  removeManufacturer
} from 'store/actions/manufacturer';
import {ManufacturersListSortByOptions} from 'constants/constants';
import {
  getManufacturersDeliveryTypes,
  getManufacturersStatuses,
  getManufacturersLocations
} from 'store/actions/manufacturerProviders';
import CreateManufacturer from '../../CreateManufacturer';
import Icon from '@material-ui/core/Icon';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import CustomMultiselect from 'components/CustomMultiselect/CustomMultiselect';
import ViewButton from 'components/ViewButton';
import IconButton from "@material-ui/core/IconButton";
import EditButton from 'assets/img/buttons/edit.svg';
import EyeButton from 'assets/img/buttons/eye.svg';
import Dropdown from 'components/CustomDropdown/CustomDropdown';
import CloseButton from 'assets/img/buttons/delete.svg';
import CustomPagination from 'components/CustomPagination';
import Loader from 'components/Loader/Loader';
import MaterialArrowUp from 'assets/img/material_arrow_up.svg';
import MaterialArrowDown from 'assets/img/material_arrow_down.svg';

import './style.scss';
import RemoveDialog from "../../RemoveDialog";

class Manufacturers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isViewTable: true,
      isCreateManufacturerOpen: false,
      isViewManufacturerOpen: false,
      isRemoveManufacturerOpen: false,
      filters: {
        page: 1,
        city: [-1],
        deliveryType: [-1],
        status: '',
        order: 'name',
        orderDir: true,
      }
    };
  }

  componentDidMount() {
    const {
      getManufacturersDeliveryTypes,
      getManufacturersStatuses,
      getManufacturersLocations
    } = this.props;
    getManufacturersDeliveryTypes();
    getManufacturersStatuses();
    getManufacturersLocations();

    this.reloadDataList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchTerms != prevProps.searchTerms) {
      this.reloadDataList(true);
    }
    /* sample code that triggers a "clear search", should the page require it
    setTimeout(() => {
      this.props.onSearchReset && this.props.onSearchReset();
    }, 2500);
    */
  }

  reloadDataList = (resetPage) => {
    const { filterManufacturers, searchTerms } = this.props;
    const { filters } = this.state;
    if (resetPage && filters.page != 1) {
      this.setState({
        filters: {
          ...filters,
          page: 1,
        },
      }, () => {
        filterManufacturers(this.state.filters, this.props.searchTerms);
      });
    } else {
      filterManufacturers(filters, searchTerms);
    }
  }

  isManufacturersHeaderDataReady = () => {
    const {
      manufacturerDeliveryTypes,
      manufacturerStatuses,
      manufacturerLocations,
    } = this.props;
    return manufacturerDeliveryTypes.length !== 0 && manufacturerStatuses.length !== 0 && manufacturerLocations.length !== 0;
  }

  isManufacturersDataReady = () => {
    const {
      manufacturersBusy
    } = this.props;
    return !manufacturersBusy;
  }

  handleViewChange = () => {
    this.setState({
      isViewTable: !this.state.isViewTable
    })
  };

  handleToggleCreateManufacturer = () => {
    this.setState({
      isCreateManufacturerOpen: !this.state.isCreateManufacturerOpen
    })
  };

  handleEditManufacturerOpen = (id) => {
    const {getManufacturer} = this.props;
    getManufacturer(id);
    this.setState({
      isViewManufacturerOpen: true
    })
  };

  handleViewManufacturerClose = () => {
    this.setState({
      isViewManufacturerOpen: false
    })
  };

  handleFilters = (fieldName, value) => {
    const {
      filterManufacturers,
      manufacturerDeliveryTypes,
      manufacturerStatuses,
      manufacturerLocations,
      searchTerms
    } = this.props;
    const {filters} = this.state;
    const newFilters = {...filters};
    switch(fieldName) {
      case 'status':
        newFilters[fieldName] = +value !== manufacturerStatuses.length ? value : '';
        break;
      case 'deliveryType':
        newFilters[fieldName] = value.length == 0 ? [-1] : value;
        break;
      case 'city':
        newFilters[fieldName] = value.length == 0 ? [-1] : value;
        break;
      case 'order':
        newFilters[fieldName] = value;
        newFilters['orderDir'] = true;
        break;
      default :
        newFilters[fieldName] = value;
    }
    filterManufacturers(newFilters, searchTerms);
    this.setState({
      filters: newFilters,
    });
  };

  handleSortByFlipOrder = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        orderDir: !this.state.filters.orderDir,
      },
    }, () => {
      this.reloadDataList(true);
    });
  };

  textWrapper = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return <span>{text}</span>;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  handlePageClick = (data) => {
    const {selected} = data;
    this.handleFilters('page', selected + 1);
  };

  handleDeleteButton = (id) => {
    this.setState({
      isRemoveManufacturerOpen: true,
      deletedManufacturerId: id
    });
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveManufacturerOpen: false,
    });
  };

  handleRemoveSelectedManufacturer = () => {
    const {removeManufacturer} = this.props;
    const {deletedManufacturerId, filters} = this.state;
    removeManufacturer(deletedManufacturerId, { filters: filters, searchTerms: this.props.searchTerms });
    this.handleRemoveDialogClose()
  }

  renderManufacturer = (manufacturer, index) => {
    const {manufacturerStatuses} = this.props;
    const {filters} = this.state;
    let delivery = '';
    manufacturer.delivery.map((item, index) => (delivery + (index === 0 ? `${item}` : `, ${item}`)));
    let contact = manufacturer.contacts && manufacturer.contacts.length !== 0 ? manufacturer.contacts[0] : {}
    return(
      <GridContainer
        className={`manufacturers-item ${ index % 2 === 0 ? 'dark' : '' } pr-gd-col-container`}
        key={index}
      >
        <GridItem className="manufacturers-item-title pr-gd-col-company-name company-info left-aligned">
          <div className="company-name">
            {this.textWrapper(manufacturer.name,30)}
          </div>
          <div className="company-logo">
            {manufacturer.logo ? <img src={`${config.apiBaseUrl}${manufacturer.logo.url}`}/> : <div/>}
          </div>
        </GridItem>
        <GridItem className="manufacturers-item-title pr-gd-col-contact company-info left-aligned">
          <div className="company-name">
            {`${contact.first_name ? contact.first_name : '' } ${contact.last_name ? contact.last_name : ''}`}
          </div>
          {
            contact.id &&
            <div className="contact-info">
              <span>{this.textWrapper(`E: ${contact.email ? contact.email : '-' }`,20)}</span>
              <span>{this.textWrapper(`P: ${contact.phone_1 ? contact.phone_1 : '-' }`,20)}</span>
              <span>{this.textWrapper(`F: ${contact.fax ? contact.fax : '-' }`,20)}</span>
            </div>
          }
        </GridItem>
        <GridItem className="manufacturers-item-title pr-gd-col-location left-aligned">{manufacturer.city}</GridItem>
        <GridItem className="manufacturers-item-title pr-gd-col-inquiries left-aligned">
          {manufacturer.inquiries_count}
        </GridItem>
        <GridItem className={`manufacturers-item-title pr-gd-col-status status-dropdown ${manufacturer.status === 0 ? 'red' : 'green'} left-aligned`}>
          <Dropdown
            buttonText={manufacturer.status}
            buttonIconType="white"
            buttonProps={{className: 'dropdown-blue-style status-dropdown', round: true, size: "sm"}}
            hoverColor="dark"
            dropdownList={manufacturerStatuses}
            onClick={data => this.props.setManufacturerStatus(manufacturer.id, {value: data.id, filters})}
          />
        </GridItem>
        <GridItem className="manufacturers-item-title pr-gd-col-delivery left-aligned">{delivery}</GridItem>
        <GridItem className="manufacturers-item-title pr-gd-col-actions order-action-container left-aligned">
          <GridContainer className="order-action-items">
            <GridItem className="action-grid-item">
              <IconButton
                aria-label="close"
                className="action-btn delete-order-btn"
                onClick={() => this.handleDeleteButton(manufacturer.id)}
              >
                <img alt="orders-list-delete-order" src={CloseButton}/>
              </IconButton>
            </GridItem>
            <GridItem className="action-grid-item">
              <IconButton
                aria-label="close"
                className="action-btn"
                onClick={() => this.handleEditManufacturerOpen(manufacturer.id)}
              >
                <img alt="orders-list-edit-order" src={EditButton}/>
              </IconButton>
            </GridItem>
            <GridItem className="action-grid-item">
              <Link
                to={`/admin/manufacturers/manufacturer-details/${manufacturer.id}`}
              >
                <IconButton aria-label="close" className="action-btn action-btn-view">
                  <img alt="orders-list-view-order" src={EyeButton}/>
                </IconButton>
              </Link>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  };

  renderManufacturerCard = (manufacturer, index) => {
    const {manufacturerStatuses, t} = this.props;
    const {filters} = this.state;
    let delivery = '';
    if (manufacturer.delivery_full_service) {
      delivery += t('delivery_full_service');
    }
    if (manufacturer.delivery_complete) {
      if (delivery.length > 0)
        delivery += ", ";
      delivery += t('delivery_complete');
    }
    if (manufacturer.delivery_parts) {
      if (delivery.length > 0)
        delivery += ", ";
      delivery += t('delivery_parts');
    }
    if (!delivery) {
      delivery = t('no_delivery_info');
    }
    let contact = manufacturer.contacts && manufacturer.contacts.length !== 0 ? manufacturer.contacts[0] : {}
    return (
      <GridContainer className="manufacturer-item-card" key={index}>
        <div className="manufacturer-info">
          { manufacturer.logo && (
            <div className="company-logo">
              <img src={`${config.apiBaseUrl}${manufacturer.logo.url}`} />
            </div>
          )}
          <div className="company-name">
            {manufacturer.name}
          </div>
        </div>
        <div className="manufacturer-contact">
          <div className="contact-name">
            { contact.id ? `${contact.last_name ? contact.last_name : ''} ${contact.first_name ? contact.first_name : '' }` : t('no_contact_info') }
          </div>
          {
            contact.id &&
            <div className="contact-info">
              <span>{`E: ${contact.email ? contact.email : '-' }`}</span>
              <span>{`P: ${contact.phone_1 ? contact.phone_1 : '-' }`}</span>
              <span>{`F: ${contact.fax ? contact.fax : '-' }`}</span>
            </div>
          }
        </div>
        <div className="manufacturer-location">
           <div>
             {manufacturer.city ? manufacturer.city : ''}
           </div>
           <div>
             {manufacturer.inquiries_count ? `${manufacturer.inquiries_count} Inquiries` : ''}
           </div>
        </div>
        <div className="manufacturer-delivery">
          {delivery}
        </div>
        <div className="manufacturer-actions">
          <div className={`card-status-dropdown ${manufacturer.status === 0 ? 'red' : 'green'}`}>
            <Dropdown
              buttonText={manufacturer.status}
              buttonIconType="white"
              buttonProps={{className: 'dropdown-blue-style status-dropdown', round: true, size: "sm"}}
              hoverColor="dark"
              dropdownList={manufacturerStatuses}
              onClick={data => this.props.setManufacturerStatus(manufacturer.id, {value: data.id, filters})}
            />
          </div>
          <div className="action-icons">
            <IconButton
              aria-label="close"
              className="action-btn delete-order-btn"
              onClick={() => this.handleDeleteButton(manufacturer.id)}
            >
              <img alt="orders-list-delete-order" src={CloseButton}/>
            </IconButton>
            <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() => this.handleEditManufacturerOpen(manufacturer.id)}
            >
              <img alt="orders-list-edit-order" src={EditButton}/>
            </IconButton>
            <Link to={`/admin/manufacturers/manufacturer-details/${manufacturer.id}`}>
              <IconButton aria-label="close" className="action-btn action-btn-view">
                <img alt="order-card-view-btn" src={EyeButton}/>
              </IconButton>
            </Link>
          </div>
        </div>
      </GridContainer>
    )
  }

  render() {
    const {
      manufacturers,
      manufacturerDeliveryTypes,
      manufacturerStatuses,
      manufacturerLocations,
      metaData,
      manufacturersBusy,
      searchTerms,
      t
    } = this.props;
    const {
      isViewTable,
      isCreateManufacturerOpen,
      isViewManufacturerOpen,
      isRemoveManufacturerOpen,
      selectedManufacturerId,
      filters
    } = this.state;
    const dropdownDeliveryTypesList = [{id: -1, value: t('all_delivery_options'), is_select_all: true}, ...manufacturerDeliveryTypes];
    const dropdownStatusesList = [{id: manufacturerStatuses.length, value: t('all_statuses')}, ...manufacturerStatuses];
    const dropdownLocationsList = [{id: -1, value: t('all_locations'), is_select_all: true}, ...manufacturerLocations];
    return (
      <div className="ikt-ap_manufacturers-list-container">
        {this.isManufacturersHeaderDataReady() ? (
          <GridContainer>
            <GridItem className="ikt-ap_manufacturers-top-wrapper" xs={12}>
              <GridContainer
                className="ikt-ap_manufacturers-top-container"
                direction="row"
                justify="flex-end"
              >
                <GridItem className="ikt-ap_manufacturers-top-right">
                  <CustomMultiselect
                    buttonValue={filters.city}
                    buttonPlaceholder={t('all_locations')}
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      round: true,
                      size: 'sm'
                    }}
                    menuWidthCorrection={true}
                    hoverColor="rose"
                    dropdownList={dropdownLocationsList}
                    onChange={value => this.handleFilters('city', value)}
                  />
                  <CustomDropdown
                    buttonText={filters.status}
                    buttonPlaceholder={t('all_statuses')}
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      round: true,
                      size: 'sm'
                    }}
                    menuWidthCorrection={true}
                    hoverColor="rose"
                    dropdownList={dropdownStatusesList}
                    onClick={value => this.handleFilters('status', value.id)}
                  />
                  <CustomMultiselect
                    buttonValue={filters.deliveryType}
                    buttonPlaceholder={t('all_delivery_options')}
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      size: 'sm'
                    }}
                    menuWidthCorrection={true}
                    hoverColor="rose"
                    dropdownList={dropdownDeliveryTypesList}
                    onChange={value => this.handleFilters('deliveryType', value)}
                  />
                  <CustomDropdown
                    buttonText={filters.order}
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      size: 'sm'
                    }}
                    menuWidthCorrection={true}
                    hoverColor="rose"
                    dropdownList={ManufacturersListSortByOptions}
                    translateValues={true}
                    onClick={value => this.handleFilters('order', value.id)}
                  />
                  <ViewButton
                    className="manufacturers-view-button"
                    isTable={isViewTable}
                    onViewChange={this.handleViewChange}
                  />
                  <Button
                    className="ikt-ap_manufacturers-create-btn"
                    onClick={this.handleToggleCreateManufacturer}
                  >
                    <Icon>add</Icon> { t('create').toUpperCase() }
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
            {isViewTable && <GridContainer className="manufacturers-table-header pr-gd-col-container" direction="row">
              <GridItem className={"manufacturers-header-title pr-gd-col-company-name left-aligned" + (filters.order === 'name' ? ' col-sort-active' : '')}>
                <span onClick={filters.order === 'name' ? this.handleSortByFlipOrder : null}>
                  { t('company_name') } { filters.order === 'name' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className="manufacturers-header-title pr-gd-col-contact left-aligned">{ t('contact_details') }</GridItem>
              <GridItem className={"manufacturers-header-title pr-gd-col-location left-aligned" + (filters.order === 'city.name' ? ' col-sort-active' : '')}>
                <span onClick={filters.order === 'city.name' ? this.handleSortByFlipOrder : null}>
                  { t('location') } { filters.order === 'city.name' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className="manufacturers-header-title pr-gd-col-inquiries left-aligned">{ t('inquiries') }</GridItem>
              <GridItem className={"manufacturers-header-title pr-gd-col-status left-aligned" + (filters.order === 'status' ? ' col-sort-active' : '')}>
                <span onClick={filters.order === 'status' ? this.handleSortByFlipOrder : null}>
                  { t('status') } { filters.order === 'status' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className="manufacturers-header-title pr-gd-col-delivery left-aligned">{ t('delivery') }</GridItem>
              <GridItem className="manufacturers-header-title pr-gd-col-actions">{ t('actions') }</GridItem>
            </GridContainer>}
            { this.isManufacturersDataReady() ?
            (<>
              <GridContainer className={`manufacturers-content ${!isViewTable ? 'manufacturers-card-view' : ''}`}>
                {manufacturers.length > 0 &&
                isViewTable
                  ? manufacturers.map((manufacturer, index) => this.renderManufacturer(manufacturer, index))
                  : manufacturers.map((manufacturer, index) => this.renderManufacturerCard(manufacturer, index))
                }
                {
                  manufacturers.length == 0 &&
                  <div className="no_data">{'No Manufacturers Found'}</div>
                }
              </GridContainer>
              <GridContainer className="pagination-container">
                {
                  metaData.last_page > 1 && (
                    <CustomPagination
                      pageCount={metaData.last_page}
                      forcePage={filters.page - 1}
                      handlePageClick={this.handlePageClick}
                    />
                  )
                }
              </GridContainer>
              </>) : (
                <div className="ikt-ap_loader">
                  <Loader />
                </div>
              )
            }
            {
              isCreateManufacturerOpen &&
                <CreateManufacturer
                  isOpen={isCreateManufacturerOpen}
                  onClose={this.handleToggleCreateManufacturer}
                  filters={filters}
                  searchTerms={searchTerms}
                />
            }
            {
              isViewManufacturerOpen && this.props.manufacturer.id &&
                <CreateManufacturer
                  isOpen={isViewManufacturerOpen}
                  onClose={this.handleViewManufacturerClose}
                  filters={filters}
                  searchTerms={searchTerms}
                />
            }
            {isRemoveManufacturerOpen && (
              <RemoveDialog
                isOpen={isRemoveManufacturerOpen}
                title={t('remove_manufacturer')}
                text={t('remove_manufacturer_confirmation')}
                onClose={this.handleRemoveDialogClose}
                onRemove={this.handleRemoveSelectedManufacturer}
              />
            )}
          </GridContainer>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    manufacturers: state.manufacturer.manufacturers,
    manufacturer: state.manufacturer.manufacturer,
    manufacturerDeliveryTypes: state.manufacturerProviders.manufacturerDeliveryTypes,
    manufacturerLocations: state.manufacturerProviders.manufacturerLocations,
    manufacturerStatuses: state.manufacturerProviders.manufacturerStatuses,
    metaData: state.manufacturer.metaData,
    manufacturersBusy: state.manufacturer.manufacturersBusy,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getManufacturer: (id) => dispatch(getManufacturer(id)),
    removeManufacturer: (id, loadListInfo) => dispatch(removeManufacturer(id, loadListInfo)),
    setManufacturerStatus: (id, data) => dispatch(setManufacturerStatus(id, data)),
    filterManufacturers: (filters, searchTerms) => dispatch(filterManufacturers(filters, searchTerms)),
    getManufacturersDeliveryTypes: () => dispatch(getManufacturersDeliveryTypes()),
    getManufacturersStatuses: () => dispatch(getManufacturersStatuses()),
    getManufacturersLocations: () => dispatch(getManufacturersLocations()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Manufacturers));
