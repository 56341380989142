import React from "react";
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CloseButton from "assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';

class RemoveDialog extends React.Component {

  cancelButtonRef = React.createRef();
  removeButtonRef = React.createRef();

  componentDidMount() {
    if (!this.props.focusRemoveButton) {
      this.focusCancelButton();
    } else {
      this.focusRemoveButton();
    }
  }

  focusCancelButton = () => {
    setTimeout(() => {
      if (this.cancelButtonRef && this.cancelButtonRef.current) {
        this.cancelButtonRef.current.focus();
      }
    }, 200);
  }

  focusRemoveButton = () => {
    setTimeout(() => {
      if (this.removeButtonRef && this.removeButtonRef.current) {
        this.removeButtonRef.current.focus();
      }
    }, 200);
  }

  render() {
    const {
      isOpen,
      text,
      title,
      onClose,
      onRemove,
      t
    } = this.props;

    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'remove-dialog',
          }
        }}
      >
        <MuiDialogTitle className="remove-position-title" disableTypography>
          <Typography variant="h6" className="dialog-title">
            { title }
          </Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="file-import-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="remove-position-content">
          { text }
        </div>
        <div className="remove-position-btn-container">
          <Button
            className="cancel-btn"
            onClick={onClose}
            ref={this.cancelButtonRef}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={onRemove}
            ref={this.removeButtonRef}
          >
            { t('delete').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(RemoveDialog);
