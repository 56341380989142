import React from 'react';
import moment from "moment";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import {getCustomer} from "../../store/actions/customers";
import {getCustomersList} from "../../store/actions/providers";
import {OrderDetailsPageElementsOrder} from 'constants/constants';

import Autocomplete from 'components/Autocomplete/index';
import CustomAutocomplete from 'components/CustomAutocomplete/index';
import CloseButton from 'assets/img/buttons/delete.svg';
import ViewButton from 'assets/img/buttons/eye.svg';
import CustomerInfo from '../CustomerInfoPage';
import DatePicker from 'components/CustomDatePicker';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Input from 'components/Input/Input';
import IconButton from '@material-ui/core/IconButton';
//import TextArea from 'components/CustomTextArea';
import RTEditor from 'components/RTEditor/RTEditor';
import ProjectOrderFileUpload from 'components/ProjectOrderFileUpload';
import ClientsInquiries from '../ClientsInquiries';

import './style.scss';
import {config} from "../../Config";

class ProjectDetailsPage extends React.Component {
  componentDidMount() {
    if (this.props.data.customer_id) {
      this.props.getCustomer(this.props.data.customer_id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.errors !== this.props.errors && Object.keys(this.props.errors).length !== 0) {
      const fieldId = Object.keys(this.props.errors).sort((a,b) => OrderDetailsPageElementsOrder[a] - OrderDetailsPageElementsOrder[b])[0];
      const element = document.getElementById(fieldId);
      if (element) element.focus();
    }
  }

  handleChangeFile = (file) => {
    const {support_files} = this.props;
    const newFiles = [...support_files, {file: file}];
    this.props.onSupportFileChange(newFiles)
  }

  handleDeleteFile =  (id) => {
    const {support_files} = this.props;
    const data = [...support_files].filter((item, index) => id !== index);
    this.props.onSupportFileChange(data)
  }

  renderUploadedFile = (file, index) => {
    return (
      <div
        className= {`project-order-uploaded-file${index=== 0 ? ` _first` : ``}`}
        key={index}
      >
        <div className="file-info">
          <span className="order-file-name">{file.name ? file.name.replace(/(.{35})..+/, "$1…") : file.file.name.replace(/(.{35})..+/, "$1…")}</span>
          {
            file.file.size
              ? <span className="file-size">{`${file.file.size / 1000} KB`}</span>
              : null
          }
        </div>
        <div className="file-delete-btn">
          <a href={file.file.url ? `${config.apiBaseUrl}${file.file.url}` : URL.createObjectURL(file.file)} target="_blank">
            <img
              alt="order-delete-file-btn"
              src={ViewButton}
            />
          </a>
          <IconButton aria-label="close" className="close-btn" onClick={() => this.handleDeleteFile(index)}>
            <img
              alt="order-delete-file-btn"
              src={CloseButton}
            />
          </IconButton>
        </div>
      </div>
    );
  }

  render() {
    const {
      data,
      support_files,
      errors,
      customerInfo,
      customers,
      coworkers,
      cities,
      manufacturerContacts,
      onDataChange,
      addInquiryMaterialListPositions,
      removeInquiryMaterialListPositions,
      linkedInquiries,
      addedInquiries,
      positions,
      getCustomer,
      getCustomers,
      orderStatusList,
      inquiryStatusList,
      deliveryTypeList,
      paymentTypeList,
      referentialOrderId,
      t
    } = this.props;
    const inquiryStatusListActual = !this.props.editing ? inquiryStatusList.filter(el => el.id !== '1') : inquiryStatusList;  // "!== '1'" is hardcoded from BE
    let manufacturerContactsData = [];
    manufacturerContacts.map(item => manufacturerContactsData.push({id: item.user_id, value: item.user.name}));
    const selectedCustomerId = data.customer_id !== '' && data.customer_id !== null && data.customer_id !== undefined ? data.customer_id : (data.customer ? data.customer.id : null);
    let actualCustomersList = customers;
    if (selectedCustomerId !== null && customerInfo && customerInfo.id == selectedCustomerId && !Boolean(customers.find(el => el.id == selectedCustomerId))) {
      actualCustomersList = [{ id: selectedCustomerId, value: `${customerInfo.last_name ? customerInfo.last_name : ''}${customerInfo.first_name ? ` ${customerInfo.first_name}` : ''}`, }, ...customers];
    }
    return (
      <GridContainer className="project-details-wrapper-container">
        <GridItem className="project-details-left-content" xs={8}>
          <GridItem className="project-details-left-top" xs={12}>
            <GridItem className="project-details-item" xs={6}>
              <span className="project-details-item-title" >{data.type === 0 ? t('order_title') : t('inquiry_title')}</span>
              <Input
                autofocus={true}
                value={data.name}
                onChange={e => onDataChange('name', e.target.value)}
                error={errors.name}
                errorMsg={errors.name && errors.name[0]}
                id="name"
              />
              <span className="project-details-item-title top-margin">{ t('type') }</span>
              <div className="project-type-container">
                <div
                  className={`project-type ${data.origin_type !== undefined && data.origin_type.toString() === '0' ? 'selected' : ''}`}
                  onClick={() => onDataChange('origin_type', 0)}
                >
                  <div>{data.type === 0 ? t('manual_order') : t('manual_inquiry')}</div>
                </div>
                <div
                  className={`project-type ${data.origin_type !== undefined && data.origin_type.toString() === '1' ? 'selected' : ''}`}
                  onClick={() => onDataChange('origin_type', 1)}
                >
                  <div>{ t('from_webshop') }</div>
                </div>
              </div>
              <span className="project-details-item-title top-margin">{ t('customer') }</span>
              <CustomAutocomplete
                buttonText={selectedCustomerId}
                error={errors.customer_id}
                buttonProps={{className: '', round: true, size: "sm"}}
                dropdownList={actualCustomersList}
                hoverColor="dark"
                getData={value => getCustomers(value)}
                onClick={
                  value => {
                    if (value.id !== null && value.id !== undefined && value.id !== '') {
                      getCustomer(value.id);
                    }
                    onDataChange('customer_id', value.id);
                  }
                }
              />
              <span className="project-details-item-title top-margin">{ t('deadline') }</span>
              <DatePicker
                handleChange={date => onDataChange('due_date', date ? moment(date).format('YYYY-MM-DD') : null)}
                selectedDate={data.due_date}
                error={errors.due_date}
              />
              <span className="project-details-item-title top-margin">{ t('payment_type') }</span>
              <Dropdown
                buttonText={data.payment_type !== null && data.payment_type !== undefined && data.payment_type !== '' ? data.payment_type.toString() : null}
                buttonProps={{className: 'dropdown-blue-style order-dropdown', round: true, size: "sm"}}
                error={errors.payment_type}
                dropdownList={[{id: '', value: t('none')},...paymentTypeList]}
                id="payment_type"
                hoverColor="dark"
                onClick={(value) => onDataChange('payment_type', value.id)}
              />
            </GridItem>
            <GridItem className="project-details-item" xs={6}>
              <span className="project-details-item-title">{ t('status') }</span>
              <Dropdown
                buttonText={data.status !== null && data.status !== undefined && data.status !== '' ? data.status.toString() : null}
                buttonProps={{className: 'dropdown-blue-style order-dropdown', round: true, size: "sm"}}
                error={errors.status}
                dropdownList={data.type === 0 ? [{id: '', value: t('none')},...orderStatusList] : [{id: '', value: t('none')},...inquiryStatusListActual]}
                id="status"
                hoverColor="dark"
                onClick={(value) => onDataChange('status', value.id)}
              />
              <span className="project-details-item-title top-margin">{ t('project_owner') }</span>
              <Autocomplete
                buttonText={data.coworker_id}
                error={errors.coworker_id}
                buttonProps={{className: '', round: true, size: "sm"}}
                dropdownList={coworkers}
                hoverColor="dark"
                onClick={value => onDataChange('coworker_id', value.id)}
              />
              <span className="project-details-item-title top-margin">{ t('contact_person') }</span>
              <Input
                value={data.contact_person}
                onChange={e => onDataChange('contact_person', e.target.value)}
                error={errors.contact_person}
                errorMsg={errors.contact_person && errors.contact_person[0]}
              />
              <span className="project-details-item-title top-margin">{ t('delivery_type') }</span>
              <Dropdown
                buttonText={data.delivery_type !== null && data.delivery_type !== undefined && data.delivery_type !== '' ? data.delivery_type.toString() : null}
                buttonProps={{className: 'dropdown-blue-style order-dropdown', round: true, size: "sm"}}
                error={errors.delivery_type}
                dropdownList={[{id: '', value: t('none')},...deliveryTypeList]}
                id="delivery_type"
                hoverColor="dark"
                onClick={(value) => onDataChange('delivery_type', value.id)}
              />
            </GridItem>
          </GridItem>
          <GridItem className="project-details-description-content" xs={12}>
            <GridItem className="project-details-item" xs={12}>
              <span className="project-details-item-title">{ t('description') }</span>
              <div
                className="description-test-area"
                id="description"
              >
                <RTEditor
                  value={data.description}
                  placeholder={t('enter')}
                  onChange={(newValue) => onDataChange('description', newValue)}
                  error={errors.description}
                />
              </div>
            </GridItem>
          </GridItem>
          {
            data.type === 0 && <GridItem className="project-details-files-content" xs={12}>
              <GridItem className="project-details-item" xs={12}>
                <span className="project-details-item-title">{`${data.type === 0 ? 'Order' : 'Inquiry'} Files`}</span>
                <div className="project-files-items">
                  <ProjectOrderFileUpload onChange={this.handleChangeFile}/>
                  <div className='upload-item-list-container'>
                  {support_files && support_files.length !== 0 && support_files.map((item, index) => this.renderUploadedFile(item, index))}
                  </div>
                </div>
              </GridItem>
            </GridItem>
          }
        </GridItem>
        <GridItem className="project-details-right-content" xs={4}>
          {selectedCustomerId && Object.keys(customerInfo).length > 0 && <CustomerInfo data={customerInfo} cities={cities}/>}
          {data.type === 1 && <GridItem className="inquiry-details-files-content">
            <GridItem className="inquiry-file-upload">
              <span className="inquiry-file-upload-title">{'Inquiry Files'}</span>
              <div className="inquiry-files-items">
                <ProjectOrderFileUpload onChange={this.handleChangeFile}/>
                <div className='upload-item-list-container'>
                {support_files && support_files.length !== 0 && support_files.map((item, index) => this.renderUploadedFile(item, index))}
                </div>
              </div>
            </GridItem>
          </GridItem>}
          { data.type === 0 && data.customer_id &&
            <ClientsInquiries
              positions={positions}
              onAddPositions={addInquiryMaterialListPositions}
              onRemovePositions={removeInquiryMaterialListPositions}
              linkedInquiries={linkedInquiries}
              addedInquiries={addedInquiries}
              referentialOrderId={referentialOrderId}
              clientId={data.customer_id}
            />
          }
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProp = state => {
  return {
    customerInfo: state.customers.customer,
    customers: state.providers.customersList,
    coworkers: state.providers.coworkersList,
    cities: state.locations.cities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomer: (id) => dispatch(getCustomer(id)),
    getCustomers: (term) => dispatch(getCustomersList(term)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ProjectDetailsPage));
