import React from "react";
import { withTranslation } from 'react-i18next';

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import TextArea from 'components/CustomTextArea';

import './style.scss';
import {contactsNotesPageItems} from "../../constants/constants";

class Note extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.errors !== this.props.errors && Object.keys(this.props.errors).length !== 0) {
      const fieldId = Object.keys(this.props.errors).sort((a,b) => contactsNotesPageItems[a] - contactsNotesPageItems[b])[0];
      if (document.getElementById(fieldId)) {
        document.getElementById(fieldId).focus();
      }
    }
  }

  render() {
    const {
      data,
      errors,
      onDataChange,
      t
    } = this.props;
    return(
      <div className="note-content">
        <GridContainer className="note-container">
          <GridItem className="note-item" xs={6}>
            <span className="note-item-title">{ t('general') }</span>
            <TextArea
              autofocus={true}
              value={data.note_general}
              onChange={ (e) => onDataChange('note_general', e.target.value)}
              error={errors.note_general}
              id="note_general"
            />
          </GridItem>
          <GridItem className="note-item" xs={6}>
            <span className="note-item-title">{ t('business') }</span>
            <TextArea
              value={data.note_business}
              onChange={ (e) => onDataChange('note_business', e.target.value)}
              error={errors.note_business}
              id="note_business"
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem className="note-item" xs={6}>
            <span className="note-item-title">{ t('personal') }</span>
            <TextArea
              value={data.note_personal}
              onChange={ (e) => onDataChange('note_personal', e.target.value)}
              error={errors.note_personal}
              id="note_personal"
            />
          </GridItem>
          <GridItem className="note-item" xs={6}>
            <span className="note-item-title">{ t('activities') }</span>
            <TextArea
              value={data.note_activities}
              onChange={ (e) => onDataChange('note_activities', e.target.value)}
              error={errors.note_activities}
              id="note_activities"
            />
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withTranslation()(Note);
