import {
  GET_REQUESTS_LIST,
  GET_REQUESTS_LIST_SUCCESS,
  GET_REQUESTS_LIST_ERROR,
  APPROVE_REQUEST,
  APPROVE_REQUEST_SUCCESS,
  APPROVE_REQUEST_ERROR,
  REJECT_REQUEST,
  REJECT_REQUEST_SUCCESS,
  REJECT_REQUEST_ERROR,
  FILTER_REQUESTS
} from "../actionTypes/requestsActionTypes";
import {FILTER_ORDERS} from "../actionTypes/ordersActionTypes";

export const getRequestsList = () => {
  return {type: GET_REQUESTS_LIST}
};

export const getRequestsListSuccess = (data) => {
  return {type: GET_REQUESTS_LIST_SUCCESS, payload: data}
};

export const getRequestsListFailure = (error) => {
  return {type: GET_REQUESTS_LIST_ERROR, payload: error}
};

export const approveRequest = () => {
  return {type: APPROVE_REQUEST}
};

export const approveRequestSuccess = (data) => {
  return {type: APPROVE_REQUEST_SUCCESS, payload: data}
};

export const approveRequestFailure = (error) => {
  return {type: APPROVE_REQUEST_ERROR, payload: error}
};

export const rejectRequest = () => {
  return {type: REJECT_REQUEST}
};

export const rejectRequestSuccess = (data) => {
  return {type: REJECT_REQUEST_SUCCESS, payload: data}
};

export const rejectRequestFailure = (error) => {
  return {type: REJECT_REQUEST_ERROR, payload: error}
};

export const filterRequests = (filters) => {
  return {
    type: FILTER_REQUESTS,
    payload: filters
  };
};

