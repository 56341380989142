import { handleActions } from 'redux-actions';
import dashRoutes from '../../routes';
import {SET_CONTACTS_ROUTES} from "../actionTypes/routesActionTypes";


export const DEFAULT_ROUTES_STATE = {
  routes: dashRoutes
};

export const routes = handleActions(
  {
    [SET_CONTACTS_ROUTES]: (state, action) => ({
      ...state,
      routes: action.payload
    }),
  },
  DEFAULT_ROUTES_STATE
);
