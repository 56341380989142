import React from "react";
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {deleteCategory, resetCategory} from "../../store/actions/categories";

import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import GridItem from '../../components/Grid/GridItem';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';
import RemoveDialog from "../RemoveDialog";

class ViewCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemoveCategoryOpen: false,
      deletedCategoryId: ''
    };
  }
  componentWillUnmount() {
    this.props.resetData()
  }

  handleDeleteButton = (id) => {
    this.setState({
      isRemoveCategoryOpen: true,
      deletedCategoryId: id
    })
  }

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveCategoryOpen: false,
    });
  }

  handleRemoveCategory = () => {
    const {
      deleteCategory,
      currentPage,
      currentType,
      onClose,
      searchTerms,
      level1Only
    } = this.props;
    const {deletedCategoryId} = this.state;
    deleteCategory({ id: deletedCategoryId, currentPage, type: currentType, searchTerms, level1Only });
    onClose();
  }

  render() {
    const {
      isOpen,
      onClose,
      onEditCategory,
      category,
      types,
      t
    } = this.props;
    const {isRemoveCategoryOpen} = this.state;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="view-category-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ t('view_category') }</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="view-category-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="view-category-content">
          <div className="category-title-container">
            <GridItem xs={6}>
              <div className="category-name">{category.name}</div>
            </GridItem>
            <GridItem xs={6}>
              <div className="view-category-btn">
                { category.can_delete &&
                <Button
                  className="cancel-btn"
                  onClick={() => this.handleDeleteButton(category.id)}
                >
                  { t('delete').toUpperCase() }
                </Button>
                }
                <Button
                  className="create-btn"
                  onClick={() => onEditCategory(category.id)}
                >
                  { t('edit').toUpperCase() }
                </Button>
              </div>
            </GridItem>
          </div>
          <div className="category-data-container">
            <div className="category-info-field">
              <div className="category-info-item">
                <GridItem xs={4} className="category-info-title">{ t('type') }</GridItem>
                <GridItem xs={3}>{types[category.type]}</GridItem>
              </div>
              <div className="category-info-item">
                <GridItem xs={4} className="category-info-title">{ t('parent_category') }</GridItem>
                <GridItem xs={3}>{category.parent ? category.parent.name : ''}</GridItem>
              </div>
              <div className="category-info-item">
                <GridItem xs={4} className="category-info-title">{ t('description') }</GridItem>
                <GridItem xs={8}>{category.description}</GridItem>
              </div>
            </div>
          </div>
        </div>
        {isRemoveCategoryOpen && (
          <RemoveDialog
            isOpen={isRemoveCategoryOpen}
            title={t('remove_category')}
            text={t('remove_category_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleRemoveCategory}
          />
        )}
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    category: state.categories.category,
    types: state.categories.categoriesTypes,
    metaData: state.categories.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteCategory: data => dispatch(deleteCategory(data)),
    resetData: () => dispatch(resetCategory())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ViewCategory));
