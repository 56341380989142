import React from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {orderPositionCalculation} from 'store/actions/orders';
import {getPositionMaterialsList} from 'store/actions/materialProviders';
import {getTaskUnitList} from "../../../store/actions/providers";

import AddTask from '../../CreateTask';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Task from './PositionTask';

import './style.scss';

class Position extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: false,
      isCreateTaskOpen: false,
      isWideView: false,
    };
  }

  componentDidMount() {
    this.props.getUnitsList();
    const { isCardOpen } = this.props;
    const stateUpdate = {};
    if (isCardOpen) {
      stateUpdate['isCardOpen'] = true;
    }
    const mediaMatcher = window.matchMedia("(min-width: 1150px)");
    stateUpdate['isWideView'] = mediaMatcher.matches;
    mediaMatcher.addListener(e => {
      this.setState({ isWideView: mediaMatcher.matches });
    });
    this.setState(stateUpdate);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const stateUpdate = {};
    if (!prevProps.isCardOpen && this.props.isCardOpen && !this.state.isCardOpen) {
      stateUpdate['isCardOpen'] = true;
    }
    if (Object.keys(stateUpdate).length > 0) {
      this.setState(stateUpdate);
    }
  }

  addTask = taskData => {
    const { data, onDataChange } = this.props;
    const newData = [...data, taskData];
    onDataChange(newData);
  };

  removeTask = idx => {
    const { data, onDataChange } = this.props;
    const newData = [...data].filter((item, index) => index !== idx);
    onDataChange(newData);
  };

  handleTaskDataChange = (idx, taskData) => {
    const { data, onDataChange } = this.props;
    let newData = [...data];
    newData[idx] = taskData;
    onDataChange(newData);
  };

  onCardClose = () => {
    this.setState({ isCardOpen: !this.state.isCardOpen });
  };

  handleAddTaskClose = () => {
    this.setState({ isCreateTaskOpen: !this.state.isCreateTaskOpen });
  };

  render() {
    const { data, modifiable, errors, numericName, title, t } = this.props;
    const {
      isCardOpen,
      isCreateTaskOpen,
    } = this.state;
    return (
      <div className="tasks-position-content">
        <div className={`tasks-position-info ${isCardOpen ? 'tasks-bottom-borders' : ''}`}>
          <div className="tasks-position-info-title">
            <div className="tasks-position-title">
              {`${numericName ? numericName : ''} ${title ? title : ''}`}
            </div>
          </div>
          <div className="tasks-card-btn-container">
            <div
              className={`card-btn ${isCardOpen ? 'tasks-bottom-borders' : ''}`}
              onClick={() => this.onCardClose()}
            >
              {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </div>
          </div>
        </div>
        {isCardOpen && (
          <div className="tasks-position-info-content">
            {data && data.length !== 0 && (
              <GridContainer className="position-tasks">
                <GridContainer className="position-info-header">
                  <GridItem xs={1} />
                  <GridItem xs={2} />
                  <GridItem xs={this.state.isWideView ? 1 : 2} />
                  <GridItem xs={this.state.isWideView ? 2 : 3}>{ t('finished_dimensions') }</GridItem>
                  <GridItem xs={this.state.isWideView ? 5 : 3}>
                    <GridItem xs={6}>{ t('edges') }</GridItem>
                    { this.state.isWideView && <>
                      <GridItem xs={3}>{ t('surface') }</GridItem>
                      <GridItem xs={3}>{ t('coverings') }</GridItem>
                    </> }
                  </GridItem>
                  <GridItem xs={1} />
                </GridContainer>
                {data.map((task, index) => {
                  return (
                    <Task
                      data={task}
                      errors={errors && errors.tasks && errors.tasks[index]}
                      id={index}
                      onDataChange={this.handleTaskDataChange}
                      onRemoveTask={() => this.removeTask(index)}
                      modifiable={modifiable}
                      key={index}
                    />
                  );
                })}
              </GridContainer>
            )}
            <GridContainer className="position-btn-container">
              <GridItem className="position-btn-part" xs={4}>
                <Button
                  className="position-btn blue"
                  disabled={!modifiable}
                  onClick={() => this.handleAddTaskClose()}
                >
                  + { t('item').toUpperCase() }
                </Button>
                <Button className="position-btn white" component="label" disabled={!modifiable}>
                { t('import_items_cad_file').toUpperCase() }
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.props.onFileChange}
                  />
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        )}
        {isCreateTaskOpen && (
          <AddTask
            isOpen={isCreateTaskOpen}
            onClose={this.handleAddTaskClose}
            onCreateNewTask={this.addTask}
          />
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    unitsList: state.providers.taskUnitList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUnitsList: () => dispatch(getTaskUnitList()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Position));
