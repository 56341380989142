import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ErrorTooltip, ErrorTooltipAlt } from '../../components/ErrorTooltip/ErrorTooltip';

import './style.scss';

import Button from '../../components/CustomButtons/Button';
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "../../components/Input/Input";
import InputWithIcon from "../../components/InputWithIcon";
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomAutocomplete from "components/CustomAutocomplete";

import CloseButton from "../../assets/img/buttons/delete.svg";

import { getFittingsMaterialsList } from 'store/actions/materialProviders';
import { calculateMaterialRetailPrice, formatNumericValue } from 'utils/utils';

import CloseDialog from '../CloseDialog';

class CreateFitting extends React.Component {
  static defaultState = {
    material_id: '',
    material: null,
    metric: '',
    amount: '',
    description: '',

    errors: {
      material_id: null,
      metric: null,
      amount: null,
      description: null,
    },

    isCloseDialogOpen: false,
    haveEdits: false,
  };

  constructor(props) {
    super(props);

    this.state = CreateFitting.defaultState;
  }

  init = () => {
    // init from edit prop
    const { edit, errors } = this.props;

    let stateUpdateObj = {
      material_id: edit.material_id,
      material: edit.material,
      metric: edit.metric,
      amount: edit.amount,
      description: edit.description,
      supplier_id: edit.supplier_id,
    };
    if (errors) {
      stateUpdateObj['errors'] = {
        material_id: errors.material_id,
        metric: errors.metric,
        amount: errors.amount,
        description: errors.description,
        supplier_id: errors.supplier_id,
      };
    }
    this.setState(stateUpdateObj);
  }

  componentDidMount() {
    if (this.props.edit) {
      this.init();
    } else {
      // setting the default metric
      const { metrics } = this.props;
      const metricsList = Object.keys(metrics).map(el => ({ id: el, value: metrics[el] })).filter(el => el.value == 'pcs');
      if (metricsList.length > 0) {
        this.setState({
          metric: metricsList[0].id
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

  handleOnClose = () => {
    // reset the state
    this.setState(CreateFitting.defaultState);

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleOnClose();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleOnClose();
    });
  }

  handleInputValueChange = (name, value) => {
    if (name == 'amount' && value < 0)
      return;
      
    let stateUpdates = {
      [name]: value ? value : '',
      errors: {
        ...this.state.errors,
        [name]: false,
      },
      haveEdits: true,
    };

    if (name == 'material_id') {
      const { edit } = this.props;
      let material = null;
      if (this.props.fittingsMaterials.find(el => el.id == value))
        material = this.props.fittingsMaterials.find(el => el.id == value);
      else if (edit && edit.material && edit.material.id == value) {
        material = edit.material;
      }
      stateUpdates['material'] = material;
    }

    this.setState(stateUpdates);
  }

  handleCreateEdit = () => {
    const { t } = this.props;
    let errors = {};
    if (!this.state.material_id && this.state.material_id !== 0) {
      errors.material_id = t('article_required');
    }
    if (this.state.amount === '' || this.state.amount <= 0) {
      errors.amount = t('amount_required');
    }
    if (this.state.metric === '') {
      errors.metric = t('metric_required');
    }
    if (Object.keys(errors).length > 0) {
      this.setState({errors});
      return;
    }

    if (this.props.onCommit) {
      this.props.onCommit({
        material_id: this.state.material_id,
        material: this.state.material,
        metric: this.state.metric,
        amount: this.state.amount,
        description: this.state.description,
        supplier_id: this.state.supplier_id,
      });
    }
    this.setState(CreateFitting.defaultState);
  }

  render() {
    const { isOpen, metrics, edit, getFittingsMaterialsList, fittingsMaterials, currencySymbol, t } = this.props;
    const { errors } = this.state;

    const metricsList = Object.keys(metrics).map(el => ({ id: el, value: metrics[el] })).filter(el => el.value == 'pcs');
    let selectedMetricOption = '';
    if (metricsList.length > 0) {
      selectedMetricOption = metricsList[0].id;
    }

    let fittingsMaterialsActual = fittingsMaterials.map(el => ({id: el.id, value: el.code, name: el.name, code: el.code}));
    if (this.state.material_id && !fittingsMaterialsActual.find(el => el.id === this.state.material_id) && this.state.material) {
      fittingsMaterialsActual.push({ id: this.state.material_id, value: this.state.material.code, name: this.state.material.name, code: this.state.material.code });
    }

    let materialPrice = '';
    let suppliers = [];
    let material = this.state.material;
    if (material) {
      let purchasingPrice = null;
      if (
        material.material_price &&
        material.material_price[0] &&
        !isNaN(material.material_price[0].price)
      )
        purchasingPrice = material.material_price[0].price;
      else
        purchasingPrice = material.default_price;
      materialPrice = calculateMaterialRetailPrice(purchasingPrice, parseFloat(material.discount_percentage), parseFloat(material.surcharge_percentage), parseFloat(material.waste_percentage));
      materialPrice = formatNumericValue(materialPrice, 2);
      
      suppliers = material.suppliers.map(el => `${el.first_name}${el.last_name ? (' ' + el.last_name) : ''}`);
    }

    const sanitizeTooltipErrorTitle = error => (error ? error : "");

    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'create-fitting-dialog',
          }
        }}>
        <MuiDialogTitle className="create-fitting-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{!edit ? t('add_fitting') : t('edit_fitting')}</Typography>
          <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
          <img
            alt="create-fitting-close-btn"
            src={CloseButton}
          />
          </IconButton>
        </MuiDialogTitle>
        <div className="create-fitting-content">
          <div className="fitting-row">
            <div className="fitting-item">
              <span className="info-title">{ t('article') }</span>
              <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.material_id)}>
                <div>
                  <CustomAutocomplete
                    buttonText={this.state.material_id ? this.state.material_id : ''}
                    buttonProps={{className: '', round: true, size: "sm"}}
                    className={'autocomplete-article'}
                    discardIcon={true}
                    dropdownList={fittingsMaterialsActual}
                    hoverColor="dark"
                    getData={(value) => getFittingsMaterialsList(value)}
                    onClick={(value) => this.handleInputValueChange('material_id', value.id)}
                    error={errors && errors.material_id}
                  />
                </div>
              </ErrorTooltipAlt>
            </div>
          </div>
          <div className="fitting-row three-col">
            <div className="fitting-item item-amount">
              <span className="info-title">{ t('amount') }</span>
              <ErrorTooltip title={sanitizeTooltipErrorTitle(this.state.errors.amount)}>
                <Input
                  value={ this.state.amount || '' }
                  onChange={e => this.handleInputValueChange('amount', e.target.value)}
                  type={'number'}
                  error={errors && errors.amount}
                />
              </ErrorTooltip>
            </div>
            <div className="fitting-item item-metrics">
              <span className="info-title">{ t('metrics') }</span>
              <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.metric)}>
                <Dropdown
                  buttonText={selectedMetricOption}
                  buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                  dropdownList={metricsList}
                  hoverColor="dark"
                  onClick={item => this.handleInputValueChange('unit', item.id)}
                  error={errors && errors.metric}
                />
              </ErrorTooltipAlt>
            </div>
            <div className="fitting-item item-price">
              <span className="info-title">{ t('price') }</span>
              <InputWithIcon
                value={materialPrice}
                readOnly={true}
                removePlaceholder={true}
                endAdornment={<InputAdornment position="end">{currencySymbol}</InputAdornment>}
              />
            </div>
          </div>
          <div className="fitting-row">
            <div className="fitting-item">
              <span className="info-title">{ t('description') }</span>
              <textarea
                rows="4"
                onChange={e => this.handleInputValueChange('description', e.target.value)}
                value={this.state.description}>
              </textarea>
            </div>
          </div>
          { suppliers.length > 0 &&
            <div className="fitting-row">
              <div className="fitting-item">
                <span className="info-title">{ t('suppliers') }</span>
                <div className="suppliers-container">
                  <textarea
                    rows="2"
                    readOnly={true}
                    value={suppliers.join(', ')}>
                  </textarea>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="fitting-btn-container">
          <Button
            className="cancel-btn"
            onClick={ this.handleOnClose }
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleCreateEdit}
          >
            { !edit ? t('create_fitting').toUpperCase() : t('edit_fitting_button').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('close_fitting')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    fittingsMaterials: state.materialProviders.fittingsMaterials,
    metrics: state.globals.metrics,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFittingsMaterialsList: (term) => dispatch(getFittingsMaterialsList(term)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateFitting));
