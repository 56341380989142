import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { mapTo, catchError, delay } from 'rxjs/operators';
import {
  getProjectManufacturersSuccessful,
  getProjectManufacturersFailed
} from 'store/actions/projectManufacturers';
import { GET_PROJECT_MANUFACTURERS } from 'store/actionTypes/projectManufacturers';
import { projectManufactures as dummyProjectManufactures } from './epicsData';

export const getProjectManufacturers = actions$ => {
  return actions$.pipe(
    ofType(GET_PROJECT_MANUFACTURERS),
    delay(2000),
    mapTo(getProjectManufacturersSuccessful(dummyProjectManufactures)),
    catchError((error, caught) =>
      merge(of(getProjectManufacturersFailed(error)), caught)
    )
  );
};
