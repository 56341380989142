import {
  GET_HOURS_LOGGED,
  GET_HOURS_LOGGED_SUCCESS,
  GET_HOURS_LOGGED_ERROR,
  GET_COST_CENTERS_ITEMS_LIST,
  GET_COST_CENTERS_ITEMS_LIST_SUCCESS,
  GET_COST_CENTERS_ITEMS_LIST_ERROR,
  GET_COST_CENTERS_LIST,
  GET_COST_CENTERS_LIST_SUCCESS,
  GET_COST_CENTERS_LIST_ERROR,
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_ERROR,
  GET_POSITIONS_LIST,
  GET_POSITIONS_LIST_SUCCESS,
  GET_POSITIONS_LIST_ERROR,
  GET_POSITION_COST_CENTERS_LIST,
  GET_POSITION_COST_CENTERS_LIST_SUCCESS,
  GET_POSITION_COST_CENTERS_LIST_ERROR,
  GET_POSITION_COST_CENTERS_ITEMS_LIST,
  GET_POSITION_COST_CENTERS_ITEMS_LIST_SUCCESS,
  GET_POSITION_COST_CENTERS_ITEMS_LIST_ERROR,
  GET_TASKS_LIST,
  GET_TASKS_LIST_SUCCESS,
  GET_TASKS_LIST_ERROR,
  RESET_TIME_SHEET_PROVIDERS,
} from '../actionTypes/timeSheetsProvidersActionType';

export const getHoursLogged = (dateFrom, dateTo) => {
  return {type: GET_HOURS_LOGGED, payload: { dateFrom, dateTo }};
}

export const getHoursLoggedSuccess = (data) => {
  return {type: GET_HOURS_LOGGED_SUCCESS, payload: data};
}

export const getHoursLoggedFailure = (err) => {
  return {type: GET_HOURS_LOGGED_ERROR, payload: err};
}

export const getCostCenterItemsList = (id) => {
  return {type: GET_COST_CENTERS_ITEMS_LIST, payload: id};
};

export const getCostCenterItemsListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_COST_CENTERS_ITEMS_LIST_SUCCESS, payload: selectData};
};

export const getCostCenterItemsListFailure = (error) => {
  return {type: GET_COST_CENTERS_ITEMS_LIST_ERROR, payload: error};
};

export const getCostCentersList = () => {
  return {type: GET_COST_CENTERS_LIST};
};

export const getCostCentersListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]});
  })
  return {type: GET_COST_CENTERS_LIST_SUCCESS, payload: selectData};
};

export const getCostCentersListFailure = (error) => {
  return {type: GET_COST_CENTERS_LIST_ERROR, payload: error};
};

export const getPositionCostCenterItemsList = (id) => {
  return {type: GET_POSITION_COST_CENTERS_ITEMS_LIST, payload: id};
};

export const getPositionCostCenterItemsListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_POSITION_COST_CENTERS_ITEMS_LIST_SUCCESS, payload: selectData};
};

export const getPositionCostCenterItemsListFailure = (error) => {
  return {type: GET_POSITION_COST_CENTERS_ITEMS_LIST_ERROR, payload: error};
};

export const getPositionCostCentersList = (id) => {
  return {type: GET_POSITION_COST_CENTERS_LIST, payload: id};
};

export const getPositionCostCentersListSuccess = (data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]});
  })
  return {type: GET_POSITION_COST_CENTERS_LIST_SUCCESS, payload: selectData};
};

export const getPositionCostCentersListFailure = (error) => {
  return {type: GET_POSITION_COST_CENTERS_LIST_ERROR, payload: error};
};

export const getOrdersList = (searchTerm) => {
  return {type: GET_ORDERS_LIST, data: searchTerm};
};

export const getOrdersListSuccess = (data) => {
  let selectData = data.map(el => ({
    id: el.id,
    value: el.name,
    order_no: el.order_no,
  }));
  return {type:GET_ORDERS_LIST_SUCCESS, payload: selectData};
};

export const getOrdersListFailure = (error) => {
  return {type: GET_ORDERS_LIST_ERROR, payload: error};
};

export const getPositionsList = (id, ) => {
  return {type: GET_POSITIONS_LIST, payload: id};
};

export const getPositionsListSuccess = (id, data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]});
  })
  return {type: GET_POSITIONS_LIST_SUCCESS, payload: { id, data: selectData }};
};

export const getPositionsListFailure = (error) => {
  return {type: GET_POSITIONS_LIST_ERROR, payload: error};
};

export const getTasksList = (id) => {
  return {type: GET_TASKS_LIST, payload: id};
};

export const getTasksListSuccess = (id, data) => {
  let selectData = [];
  Object.entries(data).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]});
  })
  return {type: GET_TASKS_LIST_SUCCESS, payload: {id, data: selectData} };
};

export const getTasksListFailure = (error) => {
  return {type: GET_TASKS_LIST_ERROR, payload: error};
};

export const resetProviders = () => {
  return {type: RESET_TIME_SHEET_PROVIDERS};
};
