import React from "react";
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CloseButton from "assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';

class MaterialImportSkippedDialog extends React.Component {

  downloadButtonRef = React.createRef();

  componentDidMount() {
    this.focusDownloadButton();
  }

  focusDownloadButton = () => {
    setTimeout(() => {
      if (this.downloadButtonRef && this.downloadButtonRef.current) {
        this.downloadButtonRef.current.focus();
      }
    }, 200);
  }

  render() {
    const {
      isOpen,
      onClose,
      onDownload,
      t
    } = this.props;
    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'mat-import-skipped-dialog',
          }
        }}
      >
        <MuiDialogTitle className="mat-import-skipped-position-title" disableTypography>
          <Typography variant="h6" className="dialog-title">
            { t('material_import_duplicates_title') }
          </Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="file-import-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="mat-import-skipped-position-content">
          { t('material_import_duplicates_description') }
        </div>
        <div className="mat-import-skipped-position-btn-container">
          <Button
            className="cancel-btn"
            onClick={onClose}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={onDownload}
            ref={this.downloadButtonRef}
          >
            { t('download_file').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(MaterialImportSkippedDialog);
