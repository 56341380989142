import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerManufacturer, resetRegisterData } from 'store/actions/auth';
import { withTranslation } from 'react-i18next';
import { getCountries, getCities } from 'store/actions/locations';
import { Link, withRouter } from 'react-router-dom';
import i18n from 'i18next';

import Welcome from './WelcomePage';
import CompanyInfo from './CompanyInfo';
import ManufacturerDetails from './ManufacturerDetails';
import ContactPerson from './ContactPersonPage';

import Button from '../../../components/CustomButtons/Button';
import Logo from '../../../assets/img/logo/elements-web-logo-white.svg';

import {timeData} from '../../../helpers/constants';

import withStyles from '@material-ui/core/styles/withStyles';

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import './registerPageAP.scss';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isUserDataValid: false,
      isManufacturerInfoValid: false,
      isManufacturerDetailsValid: false,
      isContactPageValid: false,
      user: {
        email: '',
        password: '',
        password_confirmation: '',
      },
      userDataErrors: {},
      manufacturerInfo: {
        name: '',
        address: '',
        city_id: '',
        country_id: '1',
        website: '',
        logo: '',
        email: '',
        phone_no: ''
      },
      manufacturersInfoErrors: {},
      manufacturerDetails: {
        legal_name: '',
        billing_address: '',
        tax_no: '',
        eu_vat_no: '',
        delivery_full_service: '1',
        delivery_complete: '0',
        delivery_parts: '0',
        time_from: '08:00 AM',
        time_to: '17:00 PM'
      },
      manufacturerDetailsErrors: {},
      contactPersons: [],
      contactPersonsErrors: [],
    };
    this.props.history.listen((location,action) => {
      this.setState({
        step: (this.props.history.location.state && this.props.history.location.state.step) ? this.props.history.location.state.step : 1,
      })
    });
  }

  componentDidMount() {
    this.props.getCountries();
    this.props.getCities(this.state.manufacturerInfo.country_id, '');
    this.props.history.push("/register/register-page",{'step': 1})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.isRegistered !== this.props.auth.isRegistered && this.props.auth.isRegistered === true) {
      this.props.history.push('/');
      this.props.reset()
    }
    if (prevProps.auth.registerErrors !== this.props.auth.registerErrors && Object.keys(this.props.auth.registerErrors).length !== 0) {
      this.setRegisterErrors();
    }
  }

  setRegisterErrors = () => {
    const { registerErrors } = this.props.auth;
    const { userDataErrors, manufacturersInfoErrors, manufacturerDetailsErrors, contactPersonsErrors } = this.state;
    let newUserDataErrors = {...userDataErrors};
    let newManufacturerInfoErrors = {...manufacturersInfoErrors};
    let newManufacturerDetailsErrors = {...manufacturerDetailsErrors};
    let newContactPersonsErrors = {...contactPersonsErrors};
    let errorTab = null;
    if (registerErrors.user) {
      Object.keys(registerErrors.user).map(item => newUserDataErrors[item] = {error: true, message: registerErrors.user[item][0]});
      errorTab = 1;
    } else if (registerErrors.manufacturer) {
      Object.keys(registerErrors.manufacturer).map(item => {
        if (item == 'name' || item == 'address' || item == 'city_id' || item == 'country_id' || item == 'website' || item == 'logo' || item == 'email' || item == 'phone_no')
          newManufacturerInfoErrors[item] = {error: true, message: registerErrors.manufacturer[item][0]}; 
        else
          newManufacturerDetailsErrors[item] = {error: true, message: registerErrors.manufacturer[item][0]}; 
      });
      errorTab = Object.keys(newManufacturerInfoErrors).length > Object.keys(manufacturersInfoErrors).length ? 2 : 3;
    } else if (registerErrors.contacts) {
      Object.keys(registerErrors.contacts).map(item => newContactPersonsErrors[item] = {error: true, message: registerErrors.contacts[item][0]});
      errorTab = 4;
    }
    this.props.history.push("/register/register-page", {'step':errorTab});
    this.setState({
      userDataErrors: newUserDataErrors,
      manufacturersInfoErrors: newManufacturerInfoErrors,
      manufacturerDetailsErrors: newManufacturerDetailsErrors,
      contactPErsonsErrors: newContactPersonsErrors,
    });
  };

  userDataValidation = () => {
    let isUserDataValid = true;
    let userDataErrors = {};
    const {
      email,
      password,
      password_confirmation
    } = this.state.user;
    if (email.length === 0) {
      isUserDataValid = false;
      userDataErrors.email = {error: true, message: "Email is required"};
    }
    if (! /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      isUserDataValid = false;
      userDataErrors.email = {error: true, message: "Invalid email address"};
    }
    if (password.replace(/\s/g,"") === "") {
      isUserDataValid = false;
      userDataErrors.password = {error: true, message: "Password is required"};
    }
    if (password.length < 8) {
      isUserDataValid = false;
      userDataErrors.password = {error: true, message: "Password must be at least 8 characters long"};
    }
    if (!(/.*[A-Z].*/).test(password)) {
      isUserDataValid = false;
      userDataErrors.password = {error: true, message: "Password should contain at least one uppercase character"};
    }
    if (!(/.*[a-z].*/).test(password)) {
      isUserDataValid = false;
      userDataErrors.password = {error: true, message: "Password should contain at least one lowercase character"};
    }
    if (!(/.*\d.*/).test(password)) {
      isUserDataValid = false;
      userDataErrors.password = {error: true, message: "Password should contain at least one number"};
    }
    if (password_confirmation.replace(/\s/g,"") === "") {
      isUserDataValid = false;
      userDataErrors.password_confirmation = {error: true, message: "Password is required"};
    }
    if (
      password.length !== 0 &&
      password_confirmation.length !== 0 &&
      password !== password_confirmation
    ) {
      isUserDataValid = false;
      userDataErrors.password = {error: true, message: "Password doesn't match"};
      userDataErrors.password_confirmation = {error: true, message: "Password doesn't match"};
    }
    if (isUserDataValid) {
      this.setState({isUserDataValid, userDataErrors: {}})
    } else {
      this.setState({isUserDataValid, userDataErrors})
    }
    return isUserDataValid
  };

  changeUserData = (fieldName, value) => {
    const {user} = this.state;
    const newUserData = {...user, [fieldName]: value};
    this.setState({user: newUserData, userDataErrors: {}});
  };

  companyInfoValidation = () => {
    let isManufacturerInfoValid = true;
    let manufacturersInfoErrors = {};
    const {
      name,
      address,
      email,
      phone_no,
      logo,
      city_id,
      country_id
    } = this.state.manufacturerInfo;
    if (name.replace(/\s/g,"") === "") {
      isManufacturerInfoValid = false;
      manufacturersInfoErrors.name = {error: true, message: 'Company Name is required'};
    }
    if (logo && logo.size && logo.size > 5120 * 1024) {
      isManufacturerInfoValid = false;
      manufacturersInfoErrors.logo = {error: true, message: 'The Logo may not be greater than 5120 kilobytes'};
    }
    if (address.replace(/\s/g,"") === "") {
      isManufacturerInfoValid = false;
      manufacturersInfoErrors.address = {error: true, message: 'Company Address is required'};
    }
    if (email.length !== 0 && ! /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      isManufacturerInfoValid = false;
      manufacturersInfoErrors.email = {error: true, message: 'Invalid email address.'};
    }
    if (!city_id) {
      isManufacturerInfoValid = false;
      manufacturersInfoErrors.city_id = {error: true, message: 'City is required'};
    }
    if (!country_id) {
      isManufacturerInfoValid = false;
      manufacturersInfoErrors.country_id = {error: true, message: 'Country is required'};
    }
    if (!Number.isInteger(+phone_no)) {
      isManufacturerInfoValid = false;
      manufacturersInfoErrors.phone_no = {error: true, message: 'Invalid phone number.'};
    }
    if (isManufacturerInfoValid) {
      this.setState({isManufacturerInfoValid, manufacturersInfoErrors: {}})
    } else {
      this.setState({isManufacturerInfoValid, manufacturersInfoErrors})
    }
    return isManufacturerInfoValid
  };

  changeManufacturerInfoData = (fieldName, value) => {
    const {manufacturerInfo} = this.state;
    const newManufacturerInfoData = {...manufacturerInfo, [fieldName]: value};
    if(fieldName === 'country_id') {
      this.props.getCities(value, '');
      newManufacturerInfoData.city_id = '';
    }
    this.setState({manufacturerInfo: newManufacturerInfoData, manufacturersInfoErrors: {}});
  };

  manufacturerDetailsValidation = () => {
    let isManufacturerDetailsValid = true;
    let manufacturerDetailsErrors = {};
    const {
      legal_name,
      billing_address,
    } = this.state.manufacturerDetails;
    if (legal_name.replace(/\s/g,"") === "") {
      isManufacturerDetailsValid = false;
      manufacturerDetailsErrors.legal_name = {error: true, message: 'Registration Name is required'};
    }
    if (billing_address.replace(/\s/g,"") === "") {
      isManufacturerDetailsValid = false;
      manufacturerDetailsErrors.billing_address = {error: true, message: 'Billing Address is required'};
    }
    if (isManufacturerDetailsValid) {
      this.setState({isManufacturerDetailsValid, manufacturerDetailsErrors: {}});
    } else {
      this.setState({isManufacturerDetailsValid, manufacturerDetailsErrors});
    }
    return isManufacturerDetailsValid;
  };

  changeManufacturersDetailsData = (fieldName, value) => {
    const {manufacturerDetails} = this.state;
    const newManufacturerDetailsData = {...manufacturerDetails, [fieldName]: value};
    this.setState({manufacturerDetails: newManufacturerDetailsData, manufacturerDetailsErrors: {}});
  };

  contactPageValidation = () => {
    let isContactPageValid = true;
    let contactPersonsErrors = [];
    let {contactPersons} = this.state;
    contactPersons.length !== 0 && contactPersons.forEach((contactPerson, index) => {
      let errors = {};
      if(contactPerson.first_name.replace(/\s/g,"") === "") {
        isContactPageValid = false;
        errors.first_name = {error: true, message: 'Name is required.'}
      }
      if(contactPerson.last_name.replace(/\s/g,"") === "") {
        isContactPageValid = false;
        errors.last_name = {error: true, message: 'Surname is required.'}
      }
      if(contactPerson.email.replace(/\s/g,"") === "") {
        isContactPageValid = false;
        errors.email = {error: true, message: 'Email is required.'}
      } else if (! /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(contactPerson.email)) {
        isContactPageValid = false;
        errors.email = {error: true, message: 'Invalid email address.'};
      }
      if(contactPerson.phone_1.replace(/\s/g,"") === "") {
        isContactPageValid = false;
        errors.phone_1 = {error: true, message: 'Phone number is required.'}
      } else if (!Number.isInteger(+contactPerson.phone_1)) {
        isContactPageValid = false;
        errors.phone_1 = {error: true, message: 'Invalid phone number.'};
      }
      contactPersonsErrors[index] = errors;
    });
    if (isContactPageValid) {
      this.setState({isContactPageValid, contactPersonsErrors: []});
    } else {
      this.setState({isContactPageValid, contactPersonsErrors});
    }
    return isContactPageValid;
  };

  addNewContactPerson = () => {
    const {contactPersons} = this.state;
    const newPerson = {id: contactPersons.length !== 0 ? contactPersons.length + 1 : 1, first_name: '', last_name: '', email: '', phone_1: '', fax: '', skype: '', viber: '', whatsapp: ''};
    const newContactPersons = [...contactPersons, newPerson];
    this.setState({contactPersons: newContactPersons})
  };

  removeContactPerson = (id) => {
    const {contactPersons} = this.state;
    const newContactPersons = [...contactPersons].filter((item,index) => index !== id);
    this.setState({
      contactPersons: newContactPersons
    })
  }

  changeContactPersonData = (data) => {
    const {contactPersons} = this.state;
    const newContactPersons = [...contactPersons];
    newContactPersons[data.id - 1] = data;
    this.setState({contactPersons: newContactPersons, contactPersonsErrors: {}});
  };

  registerManufacturer = () => {
    const {
      user,
      manufacturerInfo,
      manufacturerDetails,
      contactPersons
    } = this.state;
    const schedule = {
      time_from: manufacturerDetails.time_from.slice(0, -3),
      time_to: manufacturerDetails.time_to.slice(0, -3)
     };
    const manufacturer = {...manufacturerInfo,  ...manufacturerDetails};
    delete manufacturer.time_from;
    delete manufacturer.time_to;
    const formData = new FormData();
    Object.keys(user).map(item => formData.append(`user[${item}]`,user[item]));
    formData.append('user[selected_language]', i18n.language);
    Object.keys(manufacturer).map(item => formData.append(`manufacturer[${item}]`,manufacturer[item]));
    Object.keys(schedule).map(item => formData.append(`schedule[${item}]`,schedule[item]));
    contactPersons.map((item, index) => Object.keys(item).map(el => formData.append(`contacts[${index}][${el}]`, contactPersons[index][el])));
    this.props.registerManufacturer(formData);
  };

  onStepChange = selectedStep => {
    const {
      step,
      isUserDataValid,
      isManufacturerInfoValid,
      isManufacturerDetailsValid
    } = this.state;
    if (selectedStep === 2 || selectedStep === 1) {
      const userDataValid = this.userDataValidation();
      if (userDataValid) {
        if (this.state.user && this.state.user.email && !this.state.manufacturerInfo.email) {
          this.setState({
            manufacturerInfo: {
              ...this.state.manufacturerInfo,
              email: this.state.user.email,
            }
          });
        }
        this.props.history.push("/register/register-page",{'step':selectedStep});
      }
    } else if (selectedStep === 3) {
      const manufacturerInfoValid = this.companyInfoValidation();
      if (manufacturerInfoValid) {
        if (this.state.manufacturerInfo && this.state.manufacturerInfo.address && this.state.manufacturerInfo.country_id !== '' && this.state.manufacturerInfo.city_id !== '' && !this.state.manufacturerDetails.billing_address) {
          const countryName = this.props.countries.find(el => el.id === this.state.manufacturerInfo.country_id).value;
          const cityName = this.props.cities.find(el => el.id === this.state.manufacturerInfo.city_id).value;
          this.setState({
            manufacturerDetails: {
              ...this.state.manufacturerDetails,
              billing_address: `${this.state.manufacturerInfo.address}, ${cityName}, ${countryName}`,
            }
          });
        }
        this.props.history.push("/register/register-page",{'step':selectedStep});
      }
    } else if (selectedStep === 4 && step !== 4) {
      const manufacturerDetailsValid = this.manufacturerDetailsValidation();
      if (manufacturerDetailsValid) {
        this.props.history.push("/register/register-page",{'step':selectedStep});
      }
    } else if (selectedStep === 4 && step === 4) {
      const isContactPageValid = this.contactPageValidation();
      if (isUserDataValid && isManufacturerInfoValid && isManufacturerDetailsValid && isContactPageValid) {
        this.registerManufacturer()
      } else {
        this.setState({
          isContactPageValid
        })
      }
    }
  };

  handleLogoImg = () => {
    this.props.history.push('/');
  };

  render() {
    const {
      step,
      user,
      userDataErrors,
      manufacturerInfo,
      manufacturersInfoErrors,
      manufacturerDetails,
      manufacturerDetailsErrors,
      contactPersons,
      contactPersonsErrors,
      isUserDataValid,
      isManufacturerInfoValid,
      isManufacturerDetailsValid
    } = this.state;
    const {countries, cities, getCities, t} = this.props;
    let selectedComponent;
    switch(step) {
      case 1:
        selectedComponent =
          <Welcome
            data={user}
            errors={userDataErrors}
            onChange={this.changeUserData}
          />;
        break;
      case 2:
        selectedComponent =
          <CompanyInfo
            data={manufacturerInfo}
            countries={countries}
            cities={cities}
            getCities={(countryId, searchTerm) => getCities(countryId, searchTerm)}
            errors={manufacturersInfoErrors}
            onChange={this.changeManufacturerInfoData}
          />;
        break;
      case 3:
        selectedComponent =
          <ManufacturerDetails
            data={manufacturerDetails}
            timeData={timeData}
            errors={manufacturerDetailsErrors}
            onChange={this.changeManufacturersDetailsData}
          />;
        break;
      case 4:
        selectedComponent =
          <ContactPerson
            errors={contactPersonsErrors}
            contactPersons={contactPersons}
            addNewPerson={this.addNewContactPerson}
            removeContactPerson={this.removeContactPerson}
            onPersonDataChange={this.changeContactPersonData}
          />;
        break;
      default:
        return null;
    };
    return (
      <GridContainer className="register-container">
        <GridItem className="register-sidebar-container" xs={12} sm={6} md={4}>
          <img
            alt="register-logo-img"
            className="register-logo"
            onClick={this.handleLogoImg}
            src={Logo}
          />
          <div className="sidebar-title">
            <span>{ t('manufacturer_registration') }</span>
          </div>
          <div className="slider-btn-container">
            <div
              className={step === 1 ? 'selected' : isUserDataValid ? 'valid' : ''}
              onClick={() => this.onStepChange(1)}
            />
            <div
              className={step === 2 ? 'selected' : isManufacturerInfoValid ? 'valid' : ''}
              onClick={() => this.onStepChange(2)}
            />
            <div
              className={step === 3 ? 'selected' : isManufacturerDetailsValid ? 'valid' : ''}
              onClick={() => this.onStepChange(3)}
            />
            <div
              className={step === 4 ? 'selected' : ''}
              onClick={() => this.onStepChange(4)}
            />
          </div>
          <div className="bottom-text">
            <span>© { new Date().getFullYear() } edv &amp; cad group</span>
          </div>
        </GridItem>
        <GridItem className="content-container" >
          {selectedComponent}
          <div className="btn-container">
            <Link to={'/auth-ap/login-page'}>
              <Button
                className="cancel-btn"
                onClick={() => this.onStepChange(step !== 1 ? (step - 1) : step)}
              >
                { t('cancel').toUpperCase() }
              </Button>
            </Link>
            <div>
              {
                step !== 1 &&
                <Button
                  className="cancel-btn"
                  onClick={() => this.onStepChange(step !== 1 ? (step - 1) : step)}
                >
                  { t('back').toUpperCase() }
                </Button>
              }
              <Button
                className="next-btn"
                onClick={() => this.onStepChange(step !== 4 ? (step + 1) : step)}
              >
                {step === 4 ? t('finish').toUpperCase() : t('next').toUpperCase() }
              </Button>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    auth: state.auth,
    countries: state.locations.countries,
    cities: state.locations.cities,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    registerManufacturer: (data) => dispatch(registerManufacturer(data)),
    reset: () => dispatch(resetRegisterData()),
    getCountries: () => dispatch(getCountries()),
    getCities: (countryId, searchTerm) => dispatch(getCities(countryId, searchTerm)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(loginPageStyle)(withRouter(withTranslation()(RegisterPage))));
