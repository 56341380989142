import {ofType} from 'redux-observable';
import {from, of, concat, merge} from 'rxjs';
import {switchMap, mergeMap, catchError, endWith} from 'rxjs/operators';
import {settingsServiceApi} from 'services/API';
import {
  getDefaultCostCentersList,
  getDefaultCostCentersListSuccess,
  defaultCostCentersListFailure,
  getMaterialsSettings,
  getMaterialsSettingsSuccess,
  getMaterialsSettingsFailure,
  getMaterialListAllowance,
  getMaterialListAllowanceSuccess,
  getMaterialListAllowanceFailure,
  updateMaterialListAllowanceSuccess,
  updateMaterialListAllowanceFailure,
  getMaterialListOrientationData,
  getMaterialListOrientationDataSuccess,
  getMaterialListOrientationDataFailure,
  addMaterialListOrientationClassSuccess,
  addMaterialListOrientationClassFailure,
  removeMaterialListOrientationClassSuccess,
  removeMaterialListOrientationClassFailure,
  getWebShopSettings,
  getWebShopSettingsSuccess,
  getWebShopSettingsFailure,
  getProjectsSettings,
  getProjectsSettingsSuccess,
  getProjectsSettingsFailure,
  getLetterheads,
  getLetterheadsSuccess,
  getLetterheadsFailure,
  updateLetterheadsSuccess,
  updateLetterheadsFailure,
  removeLetterheadEntrySuccess,
  removeLetterheadEntryFailure,
  removeLetterheadsFinish,
} from'../store/actions/settings'
import {
  getCurrency,
} from'../store/actions/globals';
import {
  GET_DEFAULT_COST_CENTER_LIST,
  UPDATE_DEFAULT_COST_CENTERS,
  GET_MATERIALS_SETTINGS,
  UPDATE_MATERIALS_SETTINGS,
  GET_MATERIAL_LIST_ALLOWANCE,
  UPDATE_MATERIAL_LIST_ALLOWANCE,
  GET_MATERIAL_LIST_ORIENTATION_DATA,
  ADD_MATERIAL_LIST_ORIENTATION_CLASS,
  REMOVE_MATERIAL_LIST_ORIENTATION_CLASS,
  GET_WEB_SHOP_SETTINGS,
  UPDATE_WEB_SHOP_SETTINGS,
  GET_PROJECTS_SETTINGS,
  UPDATE_PROJECTS_SETTINGS,
  GET_LETTERHEADS,
  UPDATE_LETTERHEADS,
  REMOVE_LETTERHEADS,
} from '../store/actionTypes/settingsActionsTypes';
import NotificationService from "../services/NotificationService";

export const getDefaultCostCentersEpic = (actions$) => actions$.pipe(
  ofType(GET_DEFAULT_COST_CENTER_LIST),
  switchMap(() => from(settingsServiceApi.getCostCenterList()).pipe(
    switchMap((response) => {
      return of(getDefaultCostCentersListSuccess(response.data))
    }),
    catchError( err => {
        return of(defaultCostCentersListFailure(err))
      }
    )
  ))
);

export const updateDefaultCostCentersEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_DEFAULT_COST_CENTERS),
  switchMap((action) => from(settingsServiceApi.updateCostCenterList(action.payload)).pipe(
    switchMap((response) => {
      NotificationService.success('Cost Center settings successfully updated.')
      return of(getDefaultCostCentersList(response))
    }),
    catchError( err => {
        NotificationService.error(err.responseBody ? err.responseBody.message : err)
        return of(defaultCostCentersListFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const getMaterialsSettingsEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIALS_SETTINGS),
  switchMap(() => from(settingsServiceApi.getMaterialsSettings()).pipe(
    switchMap((response) => {
      return of(getMaterialsSettingsSuccess(response.data));
    }),
    catchError( err => {
      return of(getMaterialsSettingsFailure(err));
    })
  ))
);

export const updateMaterialsSettingsEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_MATERIALS_SETTINGS),
  switchMap((action) => from(settingsServiceApi.updateMaterialsSettings(action.payload)).pipe(
    switchMap((response) => {
      NotificationService.success('Materials settings successfully updated.');
      return of(getMaterialsSettings());
    }),
    catchError( err => {
      NotificationService.error(err.responseBody ? err.responseBody.message : err);
      return of(getMaterialsSettingsFailure(err.responseBody ? err.responseBody.errors : err));
    })
  ))
);

export const getMaterialListAllowanceEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIAL_LIST_ALLOWANCE),
  switchMap((action) => from(settingsServiceApi.getMaterialListAllowance()).pipe(
    switchMap((response) => {
      return of(getMaterialListAllowanceSuccess(response.data));
    }),
    catchError( err => {
      return of(getMaterialListAllowanceFailure(err));
    })
  ))
);

export const updateMaterialListAllowanceEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_MATERIAL_LIST_ALLOWANCE),
  switchMap((action) => from(settingsServiceApi.updateMaterialListAllowance(action.payload)).pipe(
    switchMap(() => {
      NotificationService.success('Material List settings successfully updated.');
      return concat(
        of(updateMaterialListAllowanceSuccess()),
        of(getMaterialListAllowance()),
      );
    }),
    catchError( err => {
      NotificationService.error(err.responseBody ? err.responseBody.message : err);
      return of(updateMaterialListAllowanceFailure(err.responseBody ? err.responseBody.errors : err));
    })
  ))
);

export const getMaterialListOrientationDataEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIAL_LIST_ORIENTATION_DATA),
  switchMap((action) => from(settingsServiceApi.getMaterialListOrientationData(action.payload.id, action.payload.term)).pipe(
    switchMap((response) => {
      return of(getMaterialListOrientationDataSuccess(response.data));
    }),
    catchError( err => {
      return of(getMaterialListOrientationDataFailure(err));
    })
  ))
);

export const addMaterialListOrientationClassEpic = (actions$) => actions$.pipe(
  ofType(ADD_MATERIAL_LIST_ORIENTATION_CLASS),
  switchMap((action) => from(settingsServiceApi.addMaterialListOrientationClass(action.payload.id, action.payload.name)).pipe(
    switchMap(() => {
      return concat(
        of(addMaterialListOrientationClassSuccess()),
        of(getMaterialListOrientationData(action.payload.id, action.payload.term)),
      );
    }),
    catchError( err => {
      return of(addMaterialListOrientationClassFailure(err));
    })
  ))
);

export const removeMaterialListOrientationClassEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_MATERIAL_LIST_ORIENTATION_CLASS),
  switchMap((action) => from(settingsServiceApi.removeMaterialListOrientationClass(action.payload.id)).pipe(
    switchMap(() => {
      return concat(
        of(removeMaterialListOrientationClassSuccess()),
        of(getMaterialListOrientationData(action.payload.typeId, action.payload.term)),
      );
    }),
    catchError( err => {
      return of(removeMaterialListOrientationClassFailure(err));
    })
  ))
);

export const getWebShopSettingsEpic = (actions$) => actions$.pipe(
  ofType(GET_WEB_SHOP_SETTINGS),
  switchMap(() => from(settingsServiceApi.getWebShopSettings()).pipe(
    switchMap((response) => {
      return of(getWebShopSettingsSuccess(response.data))
    }),
    catchError( err => {
        return of(getWebShopSettingsFailure(err))
      }
    )
  ))
);

export const updateWebShopSettingsEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_WEB_SHOP_SETTINGS),
  switchMap((action) => from(settingsServiceApi.updateWebShopSettings(action.payload)).pipe(
    switchMap((response) => {
      NotificationService.success('Webshop settings successfully updated.')
      return concat(
        of(getCurrency()),
        of(getWebShopSettings()),
      );
    }),
    catchError( err => {
        NotificationService.error(err.responseBody ? err.responseBody.message : err)
        return of(getWebShopSettingsFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const getProjectsSettingsEpic = (actions$) => actions$.pipe(
  ofType(GET_PROJECTS_SETTINGS),
  switchMap(() => from(settingsServiceApi.getProjectsSettings()).pipe(
    switchMap((response) => {
      return of(getProjectsSettingsSuccess(response.data))
    }),
    catchError( err => {
        return of(getProjectsSettingsFailure(err))
      }
    )
  ))
);

export const updateProjectsSettingsEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_PROJECTS_SETTINGS),
  switchMap((action) => from(settingsServiceApi.updateProjectsSettings(action.payload)).pipe(
    switchMap((response) => {
      NotificationService.success('Projects settings successfully updated.')
      return of(getProjectsSettings())
    }),
    catchError( err => {
        NotificationService.error(err.responseBody ? err.responseBody.message : err)
        return of(getProjectsSettingsFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const getLetterheadsEpic = (actions$) => actions$.pipe(
  ofType(GET_LETTERHEADS),
  switchMap(() => from(settingsServiceApi.getLetterheads()).pipe(
    switchMap((response) => {
      const data = response.data;
      const letterhead1 = data.find(el => el.type === 'invoice_primary_header');
      const letterhead2 = data.find(el => el.type === 'invoice_secondary_header');
      return of(getLetterheadsSuccess(letterhead1, letterhead2));
    }),
    catchError( err => {
        return of(getLetterheadsFailure(err))
      }
    )
  ))
);

export const updateLetterheadsEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_LETTERHEADS),
  switchMap((action) => from(settingsServiceApi.updateLetterheads(action.payload.letterhead1, action.payload.letterhead2)).pipe(
    switchMap((response) => {
      return concat(
        of(updateLetterheadsSuccess()),
        of(getLetterheads()),
      );
    }),
    catchError( err => {
        return of(updateLetterheadsFailure(err))
      }
    )
  ))
);

export const removeLetterheadsEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_LETTERHEADS),
  mergeMap(({payload}) => {
    let observables = [];

    for (var i = 0, len = payload.length; i < len; ++i) {
      const id = payload[i];
      observables.push(
        from(settingsServiceApi.removeLetterhead(id)).pipe(
          switchMap((response) => {
            return of(removeLetterheadEntrySuccess(id, response));
          }),
          catchError(err => {
            NotificationService.success("Failed removing: " + id);
            return of(removeLetterheadEntryFailure(id, err));
          })
        )
      );
    }

    return merge(
      ...observables
    ).pipe(endWith(
      removeLetterheadsFinish(),
      getLetterheads(),
    ));
  }
));
