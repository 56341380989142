import {ofType} from 'redux-observable';
import {from, of, concat, merge} from 'rxjs';
import {switchMap, mergeMap, catchError, endWith} from 'rxjs/operators';
import {invoicesProvidersServiceApi} from 'services/API';
import NotificationService from '../services/NotificationService';
import {
  getPreTextPresetsSuccess,
  getPreTextPresetsFailure,
  getEndTextPresetsSuccess,
  getEndTextPresetsFailure,
  getLastPreEndTextSuccess,
  getLastPreEndTextFailure,
  getInvoiceTypesPresetsSuccess,
  getInvoiceTypesPresetsFailure,
} from 'store/actions/invoicesProviders';
import {
  GET_PRETEXT_PRESETS,
  GET_ENDTEXT_PRESETS,
  GET_LAST_PRE_END_TEXT,
  GET_INVOICE_TYPES_PRESETS,
} from 'store/actionTypes/invoicesProvidersActionTypes';

export const getPreTextPresetsEpic = (actions$) => actions$.pipe(
  ofType(GET_PRETEXT_PRESETS),
  switchMap(() => from(invoicesProvidersServiceApi.getPreTextPresets()).pipe(
    switchMap((response) => {
      return of(getPreTextPresetsSuccess(response));
    }),
    catchError(err => {
      return of(getPreTextPresetsFailure(err));
    })
  ))
);

export const getEndTextPresetsEpic = (actions$) => actions$.pipe(
  ofType(GET_ENDTEXT_PRESETS),
  switchMap(() => from(invoicesProvidersServiceApi.getEndTextPresets()).pipe(
    switchMap((response) => {
      return of(getEndTextPresetsSuccess(response));
    }),
    catchError(err => {
      return of(getEndTextPresetsFailure(err));
    })
  ))
);

export const getLastPreEndTextEpic = (actions$) => actions$.pipe(
  ofType(GET_LAST_PRE_END_TEXT),
  switchMap(() => from(invoicesProvidersServiceApi.getLastPreEndText()).pipe(
    switchMap((response) => {
      return of(getLastPreEndTextSuccess(response));
    }),
    catchError(err => {
      return of(getLastPreEndTextFailure(err));
    })
  ))
);

export const getInvoiceTypesPresetsEpic = (actions$) => actions$.pipe(
  ofType(GET_INVOICE_TYPES_PRESETS),
  switchMap((action) => from(invoicesProvidersServiceApi.getInvoiceTypesPresets(action.payload)).pipe(
    switchMap((response) => {
      return of(getInvoiceTypesPresetsSuccess(response));
    }),
    catchError(err => {
      return of(getInvoiceTypesPresetsFailure(err));
    })
  ))
);
