import BaseAPIService from './Base';
import moment from 'moment';

export default class CoworkerTimeSheetAPIService extends BaseAPIService {
  getCoworkerTimeEntries = async () => {
    return this.request(`api/timesheet/own/entries/task`).then((data) => {
      return data;
    });
  };

  getCoworkerTimeEntry = async (id) => {
    return this.request(`api/timesheet/own/entries/${id}`).then((data) => {
      return data;
    });
  };

  addNewCoworkerTimeEntry = async (data) => {
    let type = null;
    if (data.type == 0)
      type = 'position_cost_center';
    else if (data.type == 1)
      type = 'position_cost_center_item';
    else if (data.type == 2)
      type = 'cost_center';
    else if (data.type == 3)
      type = 'cost_center_item';
    return this.request(`api/timesheet/own/entries/${type}`, {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  updateCoworkerTimeEntry = async (id, data) => {
    return this.request(`api/timesheet/own/entries/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  deleteCoworkerTimeEntry = async (id) => {
    return this.request(`api/timesheet/own/entries/${id}`, {
      method: 'DELETE',
    });
  };

  filterTimeSheets = async (filters, sortBy, sortByOrder, searchTerms) => {
    let datePart = '';
    if (filters.date && filters.date !== '') {
      datePart = `&filter[entry_date]=${filters.date}`;
    } else if (filters.dateFrom && filters.dateFrom !== '' || filters.dateTo && filters.dateTo !== '') {
      const dateFrom = filters.dateFrom ? (moment(filters.dateFrom).format('YYYY-MM-DD')) : null;
      const dateTo = filters.dateTo ? (moment(filters.dateTo).format('YYYY-MM-DD')) : null;
      if (dateFrom && dateTo) {
        datePart = `&filter[entry_date][from]=${dateFrom}&filter[entry_date][to]=${dateTo}`;
      } else if (dateFrom) {
        datePart = `&filter[entry_date][from]=${dateFrom}`;
      } else if (dateTo) {
        datePart = `&filter[entry_date][to]=${dateTo}`;
      }
    }
    const sortByPart = sortBy ? `&order=${sortByOrder ? '' : '-'}${sortBy}` : '';
    let url = `?${filters.page && filters.page !== '' ? `page=${filters.page}` : ''}${searchTerms ? `&search-term=${searchTerms}` : ''}${datePart}${sortByPart}`;
    if (filters.entry_type === 0) { // operational
      url += `&filter[operational]=${filters.entry_type}`;
      if (filters.order_id !== '' && !filters.position) {
        url += `&filter[order_id]=${filters.order_id}`;
      } else if (filters.position && !filters.position_cost_center) {
        url += `&filter[order_id]=${filters.order_id}&filter[position_id]=${filters.position}`;
      } else if (filters.position_cost_center && !filters.position_cost_center_item) {
        url += `&filter[position_cost_center_id]=${filters.position_cost_center}`;
      } else if (filters.position_cost_center_item) {
        url += `&filter[position_cost_center_item_id]=${filters.position_cost_center_item}`;
      }
    } else if (filters.entry_type === 1) {  // non-operational
      url += `&filter[operational]=${filters.entry_type}`;
      if (filters.cost_center && !filters.cost_center_item) {
        url += `&filter[cost_center_id]=${filters.cost_center}`;
      } else if (filters.cost_center_item) {
        url += `&filter[cost_center_item_id]=${filters.cost_center_item}`;
      }
    }
    return this.request(`api/timesheet/own/entries${url}`).then((data) => {
      return data;
    });
  };
}
