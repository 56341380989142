import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import ProjectTabs from '../../components/ProjectTabs/ProjectTabs';
import Loader from 'components/Loader/Loader';
import "react-circular-progressbar/dist/styles.css";

import {
  AllSettingsTabs,
  SettingsTab,
  SettingsCostCenterSortValues
} from 'constants/constants';

import './style.scss';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import CostCenter from './CostCenter';
import Materials from './Materials';
import MaterialList from './MaterialList';
import Webshop from './Webshop';
import Projects from './Projects';
import {
  getDefaultCostCentersList,
  updateCostCenters,
  getMaterialsSettings,
  updateMaterialsSettings,
  getMaterialListAllowance,
  updateMaterialListAllowance,
  getWebShopSettings,
  updateWebShopSettings,
  getProjectsSettings,
  updateProjectsSettings,
  resetSettingsErrors,
  getLetterheads,
  updateLetterheads,
  removeLetterheads,
} from "../../store/actions/settings";
import { getCoworkersList } from 'store/actions/providers';

import {getCostCenterUnitList, getCurrenciesList} from "store/actions/providers";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: SettingsTab.COST_CENTER,
      costCenterFilter: 0,
      costCenters: [],
      materialsStandardSurcharges: [],
      materialsRawAllowances: [],
      materialListAllowance: [],
      webShopSettings: {},
      projectsSettings: {},
      letterhead1File: null,
      letterhead1Removed: false,
      letterhead2File: null,
      letterhead2Removed: false,
      specialErrors: null,
    };
  }

  componentDidMount() {
    this.props.getCoworkers();
    this.props.getCurrenciesList();
    this.props.getCostCenterUnits();
    this.props.getCostCenters();
    this.props.getMaterialsSettings();
    this.props.getMaterialListAllowance();
    this.props.getWebShopSettings();
    this.props.getProjectsSettings();
    this.props.getLetterheads();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.costCenters !== this.props.costCenters) {
      const newCostCenters = [...this.props.costCenters];
      newCostCenters.sort((a, b) => {return a.sort_no - b.sort_no});
      this.setState({
        costCenters: newCostCenters
      })
    }
    if(prevProps.materialsStandardSurcharges !== this.props.materialsStandardSurcharges) {
      this.setState({
        materialsStandardSurcharges: this.props.materialsStandardSurcharges
      })
    }
    if(prevProps.materialsRawAllowances !== this.props.materialsRawAllowances) {
      this.setState({
        materialsRawAllowances: this.props.materialsRawAllowances
      })
    }
    if(prevProps.materialListAllowance !== this.props.materialListAllowance) {
      this.setState({
        materialListAllowance: this.props.materialListAllowance
      })
    }
    if(prevProps.webShopSettings !== this.props.webShopSettings) {
      this.setState({
        webShopSettings: this.props.webShopSettings
      })
    }
    if(prevProps.projectsSettings !== this.props.projectsSettings) {
      this.setState({
        projectsSettings: this.props.projectsSettings
      })
    }
  }

  isSettingsDataReady = () => {
    const {
      costCenterUnits,
      currenciesList
    } = this.props;
    return (
      costCenterUnits && costCenterUnits.length !== 0 &&
      currenciesList && currenciesList.length !== 0
    );
  }

  onTabItemClick = id => {
    this.setState({
      selectedTabId: id
    });
  };

  handleCostCenterFilter = (id) => {
    this.setState({
      costCenterFilter: id
    })
  }

  handleResetChanges = () => {
    const {
      costCenters,
      materialsStandardSurcharges,
      materialsRawAllowances,
      materialListAllowance,
      webShopSettings,
      projectsSettings
    } = this.props;
    const {selectedTabId} = this.state;
    switch(selectedTabId) {
      case 0:
        this.setState({
          costCenters: costCenters,
        });
        break;
      case 1:
        this.setState({
          materialsStandardSurcharges: materialsStandardSurcharges,
          materialsRawAllowances: materialsRawAllowances,
        });
        break;
      case 2:
        this.setState({
          materialListAllowance: materialListAllowance,
        });
        break;
      case 3:
        this.setState({
          webShopSettings: webShopSettings,
        });
        break;
      case 4:
        this.setState({
          projectsSettings: projectsSettings,
          letterhead1File: null,
          letterhead1Removed: false,
          letterhead2File: null,
          letterhead2Removed: false,
        });
        break;
      default:
        return null;
    }
  };

  handleSaveChanges = () => {
    const {
      letterheads
    } = this.props;
    const {
      selectedTabId,
      costCenters,
      materialsStandardSurcharges,
      materialsRawAllowances,
      materialListAllowance,
      webShopSettings,
      projectsSettings,
      letterhead1File,
      letterhead1Removed,
      letterhead2File,
      letterhead2Removed,
    } = this.state;
    this.props.resetErrors();
    const letterheadProc = () => {
      let update1 = null, update2 = null;
      let remove = [];
      if (letterhead1File && !letterhead1Removed) {
        update1 = letterhead1File;
      } else if (letterhead1Removed && letterheads && letterheads.invoice_primary_header) {
        remove.push(letterheads.invoice_primary_header.id);
      }
      if (letterhead2File && !letterhead2Removed) {
        update2 = letterhead2File;
      } else if (letterhead2Removed && letterheads && letterheads.invoice_secondary_header) {
        remove.push(letterheads.invoice_secondary_header.id);
      }
      if (update1 || update2) {
        this.props.updateLetterheads(update1, update2);
      }
      if (remove.length > 0) {
        this.props.removeLetterheads(remove);
      }
    };
    switch (selectedTabId) {
      case 0:
        this.props.updateCostCenters(costCenters);
        break;
      case 1:
        this.props.updateMaterialsSettings(materialsStandardSurcharges, materialsRawAllowances);
        break;
      case 2:
        this.props.updateMaterialListAllowance(materialListAllowance);
        break;
      case 3:
        this.props.updateWebShopSettings(webShopSettings);
        break;
      case 4:
        this.props.updateProjectsSettings(projectsSettings);
        letterheadProc();
        break;
      default:
        return null;
    }
  };

  handleAddCostCenterPosition = () => {
    const { t } = this.props;
    const { costCenters, specialErrors } = this.state;

    const addNewItem = () => {
      const costCenterItem = {
        title: '',
        type: 2,
        measurement: '',
        rate: '',
        sort_no: 1,
        items: [],
        created_at: moment().format('YYYY-MM-DD HH:mm:ss')
      };
      const newCostCenters = [costCenterItem, ...costCenters];
      newCostCenters.map((item, index) => item.sort_no = index + 1);
      this.setState({
        costCenters: newCostCenters,
      })
    };

    if (costCenters.length > 0) {
      const lastCostCenter = costCenters[0];
      const errors = {};
      if (!lastCostCenter.title)
        errors['title'] = [t('input_title')];
      if (!lastCostCenter.type)
        errors['type'] = [t('select_type')];
      if (!lastCostCenter.measurement)
        errors['measurement'] = [t('select_measurement')];
      if (!lastCostCenter.rate)
        errors['rate'] = [t('input_rate')];
      if (Object.keys(errors).length > 0) {
        this.setState({
          specialErrors: {
            0: errors,
          },
        });
      } else {
        if (specialErrors) {
          this.setState({
            specialErrors: null,
          });
        }
        addNewItem();
      }
    } else {
      if (specialErrors) {
        this.setState({
          specialErrors: null,
        });
      }
      addNewItem();
    }
  }

  handleRemoveCostCenterPosition = (sort_no) => {
    const { costCenters, specialErrors }= this.state;
    if (specialErrors && specialErrors[sort_no - 1]) {
      this.setState({
        specialErrors: null,
      });
    }
    const newCostCenters = [...costCenters].filter((item,index) => item.sort_no !== sort_no);
    newCostCenters.map((item, index) => item.sort_no =  index + 1);
    this.setState({
      costCenters: newCostCenters
    })
  }

  handleCostCenterItemChange = (id, data) => {
    const { costCenters, specialErrors } = this.state;
    if (specialErrors && specialErrors[id]) {
      const newSpecialError = specialErrors[id];
      if (data.title)
        newSpecialError['title'] = undefined;
      if (data.type)
        newSpecialError['type'] = undefined;
      if (data.measurement)
        newSpecialError['measurement'] = undefined;
      if (data.rate)
        newSpecialError['rate'] = undefined;
      this.setState({
        specialErrors: {
          [id]: newSpecialError,
        },
      });
    }
    const newCostCenters = [...costCenters];
    newCostCenters[id] = data;
    this.setState({
      costCenters: newCostCenters,
    });
  }

  handleCostCenterMoveCost = (data) => {
    this.setState({
      costCenters: data
    })
  }

  handleMaterialsStandardSurchargesChange = (data) => {
    this.setState({
      materialsStandardSurcharges: data,
    });
  }

  handleMaterialsRawAllowancesChange = (data) => {
    this.setState({
      materialsRawAllowances: data,
    });
  }

  handleMaterialListAllowanceChange = (data) => {
    this.setState({
      materialListAllowance: data,
    });
  }

  handleWebShopSettingsChange = (fieldName, value) => {
    const {webShopSettings} = this.state;
    let newWebShopSettings = {...webShopSettings};
    newWebShopSettings[fieldName] = value;
    this.setState({
      webShopSettings: newWebShopSettings
    })
  }

  handleProjectsSettingsChange = (fieldName, value) => {
    const {projectsSettings} = this.state;
    const newProjectsSettings = {...projectsSettings};
    newProjectsSettings[fieldName] = value;
    this.setState({
      projectsSettings: newProjectsSettings
    });
  }

  handleProjectsSettingsLetterheadChange = (fieldName, value) => {
    if (fieldName === 'letterhead1') {
      if (value) {
        this.setState({
          letterhead1File: value,
          letterhead1Removed: false,
        });
      } else {
        this.setState({
          letterhead1File: null,
          letterhead1Removed: true,
        });
      }
    } else if (fieldName === 'letterhead2') {
      if (value) {
        this.setState({
          letterhead2File: value,
          letterhead2Removed: false,
        });
      } else {
        this.setState({
          letterhead2File: null,
          letterhead2Removed: true,
        });
      }
    }
  }

  render() {
    const {
      coworkers,
      costCenterUnits,
      currenciesList,
      letterheads,
      materialListAllowanceErrors,
      webShopSettingsErrors,
      projectsSettingsErrors,
      materialsSettingsErrors,
      costCenterSettingsErrors,
      letterheadsErrors,
      selectedCurrency,
      selectedCurrencySymbol,
      t
    } = this.props;
    const {
      selectedTabId,
      costCenterFilter,
      costCenters,
      materialsStandardSurcharges,
      materialsRawAllowances,
      materialListAllowance,
      webShopSettings,
      projectsSettings,
      specialErrors,
      letterhead1File,
      letterhead2File,
      letterhead1Removed,
      letterhead2Removed,
    } = this.state;
    let selectedComponent;
    let letterhead1Value = null;
    if (!letterhead1Removed) {
      letterhead1Value = letterhead1File ? letterhead1File : (letterheads ? letterheads.invoice_primary_header : null);
    }
    let letterhead2Value = null;
    if (!letterhead2Removed) {
      letterhead2Value = letterhead2File ? letterhead2File : (letterheads ? letterheads.invoice_secondary_header : null);
    }
    const letterheadData = {
      letterhead1: letterhead1Value,
      letterhead2: letterhead2Value,
    };
    switch(selectedTabId) {
      case 0:
        selectedComponent =
          <CostCenter
            data={costCenters}
            errors={costCenterSettingsErrors}
            specialErrors={specialErrors}
            costCenterFilter={costCenterFilter}
            costCenterUnits={costCenterUnits}
            onDataChange={this.handleCostCenterItemChange}
            onRemovePosition={this.handleRemoveCostCenterPosition}
            onCostMoveChange={this.handleCostCenterMoveCost}
            currencySymbol={selectedCurrencySymbol}
          />;
        break;
      case 1:
        selectedComponent =
          <Materials
            data_standard_surcharges={materialsStandardSurcharges}
            data_raw_allowances={materialsRawAllowances}
            errors={materialsSettingsErrors}
            onDataStandardSurchargesChange={this.handleMaterialsStandardSurchargesChange}
            onDataRawAllowancesChange={this.handleMaterialsRawAllowancesChange}
          />;
        break;
      case 2:
        selectedComponent =
          <MaterialList
            dataAllowance={materialListAllowance}
            errorsAllowance={materialListAllowanceErrors}
            onDataAllowanceChange={this.handleMaterialListAllowanceChange}
          />;
        break;
      case 3:
        selectedComponent =
          <Webshop
            data={webShopSettings}
            errors={webShopSettingsErrors}
            onDataChange={this.handleWebShopSettingsChange}
            currenciesList={currenciesList}
            selectedCurrency={selectedCurrency}
            selectedCurrencySymbol={selectedCurrencySymbol}
          />;
        break;
      case 4:
        selectedComponent =
          <Projects
            data={projectsSettings}
            errors={projectsSettingsErrors}
            onDataChange={this.handleProjectsSettingsChange}
            letterheadData={letterheadData}
            letterheadErrors={letterheadsErrors}
            handleLetterheadChange={this.handleProjectsSettingsLetterheadChange}
            coworkers={coworkers}
          />;
        break;
      default:
        return null;
    }
    return (
      <div className="ikt-ap_settings-container">
        {this.isSettingsDataReady() ? (
          <GridContainer>
            <GridItem className="ikt-ap_settings-top-wrapper" xs={12}>
              <GridContainer
                className="ikt-ap_settings-top-container"
              >
                <GridItem className="ikt-ap_settings-top-left">
                  <ProjectTabs
                    tabs={AllSettingsTabs}
                    selectedTab={selectedTabId}
                    onTabItemClick={this.onTabItemClick}
                  />
                </GridItem>
                <GridItem className="ikt-ap_settings-top-right">
                    {selectedTabId === 0
                        ?   <CustomDropdown
                                buttonText={
                                  costCenterFilter
                                }
                                buttonProps={{
                                className: 'dropdown-blue-style',
                                color: 'transparent',
                                round: true,
                                size: 'sm'
                                }}
                                menuWidthCorrection={true}
                                hoverColor="rose"
                                dropdownList={SettingsCostCenterSortValues}
                                translateValues={true}
                                onClick={value => this.handleCostCenterFilter(value.id)}
                            />
                        :   null
                    }
                    { selectedTabId === 0
                        ? <Button className="ikt-ap_settings-create-btn"
                            onClick={this.handleAddCostCenterPosition}
                          >
                              <Icon>add</Icon> { t('add_cost').toUpperCase() }
                          </Button>
                        : null
                    }
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem className="ikt-ap_settings-content-container" xs={12}>
              {selectedComponent}
            </GridItem>
            <GridItem className="settings-bottom" xs={12}>
              <Button
                className="cancel-btn"
                onClick={this.handleResetChanges}
              >
                { t('cancel_changes').toUpperCase() }
              </Button>
              <Button
                className="next-btn"
                onClick={this.handleSaveChanges}
              >
                { t('save').toUpperCase() }
              </Button>
            </GridItem>
          </GridContainer>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    coworkers: state.providers.coworkersList,
    costCenters: state.settings.costCenterList,
    costCenterSettingsErrors: state.settings.costCenterSettingsError,
    costCenterUnits: state.providers.costCenterUnitList,
    materialsStandardSurcharges: state.settings.materialsStandardSurcharges,
    materialsRawAllowances: state.settings.materialsRawAllowances,
    materialsSettingsErrors: state.settings.materialsSettingsError,
    materialListAllowance: state.settings.materialListAllowance,
    materialListAllowanceErrors: state.settings.materialListAllowanceError,
    webShopSettings: state.settings.webShopSettings,
    webShopSettingsErrors: state.settings.webShopSettingsError,
    projectsSettings: state.settings.projectsSettings,
    projectsSettingsErrors: state.settings.projectsSettingsError,
    letterheads: state.settings.letterheads,
    letterheadsErrors: state.settings.letterheadsError,
    currenciesList: state.providers.currenciesList,
    selectedCurrency: state.globals.currency,
    selectedCurrencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCostCenters: () => dispatch(getDefaultCostCentersList()),
    updateCostCenters: (data) => dispatch(updateCostCenters(data)),
    getCostCenterUnits: () => dispatch(getCostCenterUnitList()),
    getCoworkers: () => dispatch(getCoworkersList()),
    getCurrenciesList: () => dispatch(getCurrenciesList()),
    getMaterialsSettings: () => dispatch(getMaterialsSettings()),
    updateMaterialsSettings: (standardSurcharges, rawAllowances) => dispatch(updateMaterialsSettings(standardSurcharges, rawAllowances)),
    getMaterialListAllowance : () => dispatch(getMaterialListAllowance()),
    updateMaterialListAllowance: (data) => dispatch(updateMaterialListAllowance(data)),
    getWebShopSettings: () => dispatch(getWebShopSettings()),
    updateWebShopSettings: (data) => dispatch(updateWebShopSettings(data)),
    getProjectsSettings: () => dispatch(getProjectsSettings()),
    updateProjectsSettings: (data) => dispatch(updateProjectsSettings(data)),
    getLetterheads: () => dispatch(getLetterheads()),
    updateLetterheads: (letterhead1, letterhead2) => dispatch(updateLetterheads(letterhead1, letterhead2)),
    removeLetterheads: (data) => dispatch(removeLetterheads(data)),
    resetErrors: () => dispatch(resetSettingsErrors())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(Settings)));
