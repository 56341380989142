/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from "../../components/StyledCheckbox";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
// core components
import Button from 'components/CustomButtons/Button.jsx';

import Arrow from '../../assets/img/buttons/chevron-down.svg';
import ArrowWhite from '../../assets/img/buttons/status-dropdown.svg';

import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx';

import './customMultiselect.scss';

class CustomMultiselect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedIndex: -1,
    };
  }

  generateDisplayText = () => {
    const { buttonValue, dropdownList } = this.props;
    const values = [];
    for (let i in buttonValue) {
      values.push(
        dropdownList.find(el => el.id == buttonValue[i]).value);
    }
    return values.join(', ').replace(/(.{14})..+/, "$1…");
  }

  getDefaultSelectedIndex = () => {
    // nothing is selected by default
    return -1;
  }

  handleClick = () => {
    const defaultSelectedIndex = this.getDefaultSelectedIndex();
    this.setState(state => ({
      open: !state.open,
      selectedIndex: defaultSelectedIndex,
    }));
  };

  handleClickAway = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleMenuItemClick = (item, index) => {
    const { buttonValue, dropdownList, onChange } = this.props;
    if (!item.is_select_all) {
      const selectAll = dropdownList.filter(el => el.is_select_all);
      const selectAllCount = selectAll.length;

      let newState = [];
      let selectAllSelected = buttonValue && dropdownList.filter(el => buttonValue.indexOf(el.id) != -1 && el.is_select_all).length > 0;
      if (selectAllSelected) {
        // deselect the menu item, keep all others selected
        newState = dropdownList
          .filter(el => !el.is_select_all && el.id != item.id)
          .map(el => el.id);
      } else {
        // check the menu item
        newState = dropdownList
          .filter(el => !el.is_select_all &&
            (el.id != item.id && buttonValue.indexOf(el.id) != -1) ||
            (el.id == item.id && buttonValue.indexOf(el.id) == -1))
          .map(el => el.id);
        /*for (let i in dropdownList) {
          const currentItem = dropdownList[i];
          if (!currentItem.is_select_all &&
            (currentItem.id != item.id && buttonValue.indexOf(currentItem.id) != -1) ||
            (currentItem.id == item.id && buttonValue.indexOf(currentItem.id) == -1)) {
            newState.push(currentItem.id);
          }
        }*/

        if (newState.length == 0 ||
          (newState.length == dropdownList.length - selectAllCount && selectAllCount > 0)) {
          newState = selectAll.map(el => el.id);
        }
      }
      onChange(newState);
    } else {
      // select the item and close the menu
      const newState = [item.id];
      onChange(newState);
      this.handleCloseMenu(item);
    }
  }

  handleCloseMenu = param => {
    this.setState({ open: false });
  }

  scrollSelectedMenuItemIntoView = () => {
    let selectedElements = document.getElementsByClassName("ikt-ap_custom-dropdown-item-selected");
    if (selectedElements && selectedElements[0])
      selectedElements[0].scrollIntoView({ block: "nearest" });
  }

  handleKeyDown = event => {
    const { dropdownList } = this.props;
    const { open, selectedIndex } = this.state;

    if (!open) {
      if (event.keyCode == 13 || event.keyCode == 32) {  // enter or space
        const defaultSelectedIndex = this.getDefaultSelectedIndex();
        event.preventDefault();
        this.setState({
          open: true,
          selectedIndex: defaultSelectedIndex,
        });
      }
    } else {
      if (event.keyCode == 40) {  // down arrow
        if (selectedIndex !== -1) {
          if (selectedIndex + 1 < dropdownList.length) {
            this.setState({
              selectedIndex: selectedIndex + 1,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        } else {
          if (dropdownList.length > 0) {
            this.setState({
              selectedIndex: 0,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        }
      } else if (event.keyCode == 38) {  // up arrow
        if (selectedIndex !== -1) {
          if (selectedIndex - 1 >= 0) {
            this.setState({
              selectedIndex: selectedIndex - 1,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        } else {
          if (dropdownList.length > 0) {
            this.setState({
              selectedIndex: dropdownList.length - 1,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        }
      } else if (event.keyCode == 13 || event.keyCode == 32) {  // enter or space
        if (selectedIndex !== -1) {
          event.preventDefault();
          this.handleMenuItemClick(dropdownList[selectedIndex], selectedIndex);
        }
      } else if (event.keyCode == 27) {  // escape
        this.setState({
          open: false,
          selectedIndex: -1,
        });
      }
    }
  }

  handleMouseItemEnter = (event, prop) => {
    const { dropdownList } = this.props;
    const selectedIndex = dropdownList.findIndex(el => el.id === prop.id);
    if (selectedIndex != -1) {
      this.setState({
        selectedIndex
      });
    }
  }

  render() {
    const { open, selectedIndex } = this.state;
    const {
      classes,
      buttonValue,
      buttonPlaceholder,
      buttonIconType,
      dropdownList,
      buttonProps,
      dropup,
      dropdownHeader,
      id,
      hoverColor,
      dropPlacement,
      rtlActive,
      menuWidthCorrection,
      noLiPadding,
      innerDropDown,
      navDropdown,
      translateValues,
      error,
      t
    } = this.props;
    const caretClasses = classNames({
      [classes.caret]: true,
      [classes.caretDropup]: dropup && !open,
      [classes.caretActive]: open && !dropup,
      [classes.caretRTL]: rtlActive
    });
    const dropdownItem = classNames({
      [classes.dropdownItem]: true,
      [classes[hoverColor + 'Hover']]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive
    });

    let hasSelectAllSelected = false;
    if (buttonValue)
      dropdownList.map(el => {
        if(el.is_select_all) {
          hasSelectAllSelected = hasSelectAllSelected || buttonValue.indexOf(el.id) != -1;
        }
      });

    const dropDownMenu = (
      <MenuList role="menu" className={classes.menuList}>
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => this.handleCloseMenu(dropdownHeader)}
            className={classes.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {dropdownList.map((prop, key) => {
          const isSelected = selectedIndex == key;
          const isChecked = !prop.is_select_all && ((buttonValue && buttonValue.indexOf(prop.id) != -1) || hasSelectAllSelected);
          const isExclusive = prop.is_select_all;
          if (prop.divider) {
            return (
              <Divider
                key={key}
                onClick={() => this.handleCloseMenu('divider')}
                className={classes.dropdownDividerItem}
              />
            );
          } else if (
            prop.props !== undefined &&
            prop.props['data-ref'] === 'multi'
          ) {
            return (
              <MenuItem
                key={key}
                className={`${dropdownItem}${
                  isSelected ? ` ikt-ap_custom-dropdown-item-selected` : ``
                }`}
                style={{ overflow: 'visible', padding: 0 }}
              >
                <span className="text-container">{ translateValues ? t(prop.value) : prop.value }</span>
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={key}
              onClick={() => this.handleMenuItemClick(prop, key)}
              onMouseEnter={(event) => this.handleMouseItemEnter(event, prop)}
              className={`${dropdownItem}${
                isSelected ? ` ikt-ap_custom-dropdown-item-selected` : ``
              }`}
            >
              { !isExclusive && <span className={'checkbox-container' + (isChecked ? ' checkbox-container-checked' : '')}>
                <Checkbox
                  checked={isChecked}
                  onChange={e => { } /* handled by onClick on MenuItem */}
                  color="primary"
                /></span> }
                <span className="text-container">{ translateValues ? t(prop.value) : prop.value }</span>
            </MenuItem>
          );
        })}
      </MenuList>
    );

    let buttonToggleClasses = `${buttonProps.className} ikt-ap_custom-dropdown-toggle-btn ${error ? 'dropdown-error' : ''}`;
    return (
      <div
        className={`ikt-ap_custom-dropdown-multiselect dropdown-blue ${
          innerDropDown ? classes.innerManager : classes.manager
        }`}
        onKeyDown={this.handleKeyDown}
      >
        <div className={buttonValue !== undefined ? '' : classes.target}>
          <Button
            aria-label="Notifications"
            id={id}
            aria-owns={open ? 'menu-list' : null}
            aria-haspopup="true"
            buttonRef={node => {
              this.anchorEl = node;
            }}
            {...buttonProps}
            className={buttonToggleClasses}
            onClick={this.handleClick}
          >
            {buttonValue === undefined || buttonValue.length === 0 || buttonValue === null ? (buttonPlaceholder ? buttonPlaceholder : t('please_select')) : this.generateDisplayText()}
            {buttonIconType === 'white'
              ? <img alt="dropdown-caret-white" src={ArrowWhite} className={caretClasses} />
              : <img alt="dropdown-caret-blue" src={Arrow} className={caretClasses} />}
          </Button>
        </div>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true,
            [classes.popperNav]: open && navDropdown
          })}
        >
          {() => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: '0 100% 0' }
                  : { transformOrigin: '0 0 0' }
              }
            >
              <Paper
                className={`${classes.dropdown} ikt-ap_custom-dropdown-menu ${menuWidthCorrection ? 'menu-corrected-width' : ''}`}
              >
                {innerDropDown ? (
                  dropDownMenu
                ) : (
                  <ClickAwayListener onClickAway={this.handleClickAway}>
                    {dropDownMenu}
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CustomMultiselect.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: 'primary'
};

CustomMultiselect.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  buttonValue: PropTypes.array,
  buttonIcon: PropTypes.object,
  id: PropTypes.string,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  translateValues: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    'bottom',
    'top',
    'right',
    'left',
    'bottom-start',
    'bottom-end',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end'
  ]),
  menuWidthCorrection: PropTypes.bool,
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // This is a function that returns the current selection state
  onChange: PropTypes.func,
};

export default withStyles(customDropdownStyle)(withTranslation()(CustomMultiselect));
