import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getCustomer,
  getCustomers,
  removeCustomer,
  removeCustomers,
  startCustomerFileImport
} from "../../store/actions/customers";

import Checkbox from 'components/StyledCheckbox';
import Button from "components/CustomButtons/Button.jsx";
import GridViewButton from "components/ViewButton";
import CreateContact from "../CreateContact";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Loader from "../../components/Loader/Loader";
import ViewContact from "../ViewContact";

import MaterialArrowUp from '../../assets/img/material_arrow_up.svg';
import MaterialArrowDown from '../../assets/img/material_arrow_down.svg';
import CustomMultiselectHierarchicalFilter from "../../components/CustomMultiselectHierarcicalFilter/index";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import DropdownButton from '../../components/CustomButtons/DropdownButton';
import CloseButton from "../../assets/img/buttons/delete.svg";
import EditButton from "../../assets/img/buttons/edit.svg";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ViewButton from "../../assets/img/buttons/eye.svg";

import './style.scss';
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomPagination from "../../components/CustomPagination";
import FileImportDialog from "../ContactsImportDialog";
import DelimiterDialog from "../DelimiterDialog";
import RemoveDialog from "../RemoveDialog";
import ImportErrorDialog from "../ImportErrorDialog";
import {
  getOwnContactsCategoriesHierarchy,
} from 'store/actions/categories';
import { checkArraySetEquality, transformContactCategoriesHierarchy, flattenContactCategoriesHierarchyList } from "utils/utils";

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewTable: true,
      isImportDialogOpen: false,
      isImportDelimiterDialogOpen: false,
      isImportErrorDialogOpen: false,
      isCreateContactOpen: false,
      isEditContactOpen: false,
      isViewContactOpen: false,
      isRemoveContactOpen: false,
      isRemoveSelectedOpen: false,
      editContactId: '',
      deletedContactId: '',
      contactId: '',
      currentSelection: [],
      currentPage: 1,
      filters: {
        categories: [],
        sortBy: 'last_name',
        sortByOrder: true,  // true is ascending
      },
      contactCategoriesHierarchy: [],
      contactCategoriesHierarchyTransformed: [],
    };
  }

  componentDidMount() {
    this.props.getOwnContactsCategoriesHierarchy();
    // this.reloadDataList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchTerms != prevProps.searchTerms) {
      this.reloadDataList(true);
    }

    if (this.props.customerImportValidationData.processID !== '' && prevProps.customerImportValidationData.processID !== this.props.customerImportValidationData.processID) {
      this.handleOpenImportDialog()
    }
    if (prevProps.customerImportedFileData.processID !== this.props.customerImportedFileData.processID) {
      this.reloadDataList();
    }
    /*if ((prevProps.customerImportValidationErrors !== this.props.customerImportValidationErrors) && Object.keys(this.props.customerImportValidationErrors).length !== 0) {
      this.handleToggleImportErrorDialog();
    }*/
    if ((prevProps.isValidationSuccess !== this.props.isValidationSuccess && prevProps.isValidationSuccess === false)) {
      this.handleToggleDelimiterDialog();
    }
    if ((prevProps.isStartSuccess !== this.props.isStartSuccess && prevProps.isStartSuccess === false)) {
      this.handleCloseImportDialog();
    }
    if (prevProps.contactCategoriesHierarchy != this.props.contactCategoriesHierarchy && this.props.contactCategoriesHierarchy != this.state.contactCategoriesHierarchy) {
      let transformedCategoriesHierarchy = transformContactCategoriesHierarchy(this.props.contactCategoriesHierarchy);
      if (this.props.match.params.id) {
        let selectedElement = null;
        for (let i in transformedCategoriesHierarchy) {
          const el = transformedCategoriesHierarchy[i];
          if (el.id == this.props.match.params.id) {
            selectedElement = el;
            break;
          }
        }
        if (selectedElement) {
          transformedCategoriesHierarchy = [selectedElement];
        } else {
          transformedCategoriesHierarchy = [];
        }
      }
      const flatList = flattenContactCategoriesHierarchyList(transformedCategoriesHierarchy);
      this.setState({
        contactCategoriesHierarchy: this.props.contactCategoriesHierarchy,
        contactCategoriesHierarchyTransformed: transformedCategoriesHierarchy,
        filters: {
          ...this.state.filters,
          categories: flatList.map(el => el.id).sort(),
        }
      }, () => {
        this.reloadDataList();
      });
    }
    /* sample code that triggers a "clear search", should the page require it
    setTimeout(() => {
      this.props.onSearchReset && this.props.onSearchReset();
    }, 2500);
    */
  }

  isContactsDataReady = () => {
    const { customersBusy } = this.props;
    return !customersBusy;
  };

  handleOpenImportDialog = () => {
    this.setState({
      isImportDialogOpen: true,
    })
  };

  handleCloseImportDialog = () => {
    this.setState({
      isImportDialogOpen: false,
    })
  };

  handleToggleImportErrorDialog = () => {
    this.setState({
      isImportErrorDialogOpen: !this.state.isImportErrorDialogOpen,
    })
  }

  handleToggleDelimiterDialog = () => {
    this.setState({
      isImportDelimiterDialogOpen: !this.state.isImportDelimiterDialogOpen
    })
  }

  handleStartButton = (has_header, custom_mapping, save_mapping) => {
    const {
      startCustomerFileImport,
      customerImportValidationData
    } = this.props;

    const categoryId = this.props.match.params.id;

    const data = {
      processId: customerImportValidationData.processID,
      has_header: has_header,
      custom_mapping: custom_mapping,
      save_mapping: save_mapping,
      category_id: categoryId
    };
    startCustomerFileImport(data);
  }

  handlePageClick = (data) => {
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    wrapper.scrollTop = 0;
    const {selected} = data;
    this.setState({
      currentPage: selected + 1,
      currentSelection: [],
    }, () => {
      this.reloadDataList();
    });
  };

  handleViewChange = () => {
    this.setState({
      isViewTable: !this.state.isViewTable
    })
  };

  handleToggleCreateContact = () => {
    this.setState({
      isCreateContactOpen:  !this.state.isCreateContactOpen
    })
  };

  handleShowViewContact = (id) => {
    this.props.getCustomer(id);
    this.setState({
      isViewContactOpen: true,
      contactId: id,
    })
  };

  handleCloseViewContact = () => {
    this.setState({
      isViewContactOpen: false,
    })
  };

  handleShowEditContact = (id) => {
    if (id) {
      this.props.getCustomer(id);
    }
    this.setState({
      isEditContactOpen: true,
      isViewContactOpen: false,
      editContactId: id
    })
  };

  handleCloseEditContact = () => {
    this.setState({
      isEditContactOpen: false,
    })
  };

  handleDeleteButton = (id) => {
    this.setState({
      isRemoveContactOpen: true,
      deletedContactId: id
    });
  };

  handleDeleteSelectedCustomersButton = () => {
    this.setState({
      isRemoveSelectedOpen: true,
    });
  }

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveContactOpen: false,
    });
  }

  handleRemoveSelectedDialogClose = () => {
    this.setState({
      isRemoveSelectedOpen: false,
    });
  }

  removeSelectedCustomer = () => {
    const {removeCustomer} = this.props;
    const {deletedContactId, filters} = this.state;
    removeCustomer(deletedContactId, { page: this.state.currentPage, filters, searchTerms: this.props.searchTerms });
    this.handleRemoveDialogClose()
  }

  handleDeleteSelectedCustomers = () => {
    const { customers, removeCustomers, searchTerms } = this.props;
    const { currentPage, currentSelection } = this.state;
    const removingCustomers = [];
    for (let i = 0, len = currentSelection.length; i < len; ++i) {
      const id = currentSelection[i];
      const customer = customers.find(c => c.id == id);
      if (customer) {
        removingCustomers.push(id);
      }
    }

    let newCurrentPage = currentPage;
    if (newCurrentPage > 1 && customers.length == removingCustomers.length) {
      // don't show empty page, instead go one page back
      newCurrentPage -= 1;
    }
    this.setState({
      currentSelection: currentSelection.filter(id => removingCustomers.indexOf(id) == -1),
      currentPage: newCurrentPage,
      isRemoveSelectedOpen: false,
    }, () => {
      removeCustomers(removingCustomers, { page: this.state.currentPage, filters: this.state.filters, searchTerms: searchTerms });
    });
  }

  handleSelectionToggleAllNone = () => {
    const { customers } = this.props;
    const { currentSelection } = this.state;
    if (customers.length !== currentSelection.length) {
      this.setState({
        currentSelection: customers.map(m => m.id),
      });
    } else {
      this.setState({
        currentSelection: [],
      });
    }
  }

  handleSelectionChange = (id) => {
    const { currentSelection } = this.state;
    const newCurrentSelection = [...currentSelection];
    const indexOfId = newCurrentSelection.indexOf(id);
    if (indexOfId != -1) {
      newCurrentSelection.splice(indexOfId, 1);
    } else {
      newCurrentSelection.push(id);
      newCurrentSelection.sort();
    }
    this.setState({
      currentSelection: newCurrentSelection
    });
  }

  reloadDataList = (resetPage) => {
    const { getCustomers } = this.props;
    if (!resetPage || this.state.currentPage == 1) {
      getCustomers(this.state.currentPage, this.state.filters, this.props.searchTerms);
    } else {
      this.setState({
        currentPage: 1,
      }, () => {
        getCustomers(this.state.currentPage, this.state.filters, this.props.searchTerms);
      });
    }
  };

  handleFilterChange = (name, value) => {
    const { filters, contactCategoriesHierarchyTransformed } = this.state;
    if (name == "categories") {
      if (value.length == 0) {
        const flatList = flattenContactCategoriesHierarchyList(contactCategoriesHierarchyTransformed);
        value = flatList.map(el => el.id).sort();
      }
    }
    filters[name] = value;
    this.setState({ filters, currentPage: 1 }, () => {
      this.reloadDataList();
    });
  };

  handleSortByChange = field => {
    this.setState({
      filters: {
        ...this.state.filters,
        sortBy: field,
        sortByOrder: true,
      },
    }, () => {
      this.reloadDataList();
    });
  };

  handleSortByFlipOrder = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        sortByOrder: !this.state.filters.sortByOrder,
      },
    }, () => {
      this.reloadDataList();
    });
  };

  renderSelectButton = () => {
    const { customers, t } = this.props;
    const { currentSelection } = this.state;

    if (!currentSelection || !customers || currentSelection.length == 0)
      return null;

    const deleteSelectedButton = (<Button
      onClick={this.handleDeleteSelectedCustomersButton}
      className="delete-customers-btn select-dropdown-button-child"
    >
      { t('delete_selected') }
    </Button>);

    return (<DropdownButton
      className="add-contact-btn-dropdown"
      buttonText={t('select')}
    >
      { deleteSelectedButton }
    </DropdownButton>);
  }

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return text;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  renderSwitch (url) {
    const { t } = this.props;
    switch (url) {
      case 'suppliers':
        return t('no_suppliers_found');
      case 'private_contacts':
        return t('no_private_contacts_found');
      case 'public_authorities':
        return t('no_public_authorities_found');
      case 'companies':
        return t('no_companies_found');
      default:
        return t('no_contacts_found');
    }
  }

  renderContact = (contact, index) => {
    const isEntrySelected = this.state.currentSelection && this.state.currentSelection.indexOf(contact.id) != -1;
    return(
      <GridContainer
        className={`contacts-item ${ index % 2 === 0 ? 'dark' : '' } ${ isEntrySelected ? 'contacts-item-selected' : ''}`}
        key={index}
      >
        <GridItem className="contact-item-title _select">
          <Checkbox
            checked={isEntrySelected}
            onChange={e => this.handleSelectionChange(contact.id)}
            color="primary"
          />
        </GridItem>
        <GridItem className="contact-item-title _salutation" xs={1}>
          {this.wrapText(contact.title, 6)}
        </GridItem>
        <GridItem className="contact-item-title bold" xs={2}>{this.wrapText(contact.first_name,15)}</GridItem>
        <GridItem className="contact-item-title bold _last-name" xs={2} lg={1}>{this.wrapText(contact.last_name,10)}</GridItem>
        <GridItem className="contact-item-title" xs={3} lg={2}>{this.wrapText(contact.company, 20)}</GridItem>
        <GridItem className="contact-item-title _private-phone" xs={1}>{this.wrapText(contact.phone_private,10)}</GridItem>
        <GridItem className="contact-item-title _mobile-phone" xs={3} lg={2}>{this.wrapText(contact.phone_mobile,25)}</GridItem>
        <GridItem className="contact-item-title _email" xs={2}>{this.wrapText(contact.email,12)}</GridItem>
        <GridItem className="contact-item-title action-btn-container _actions" xs={2} lg={1}>
          {
            contact.can_delete &&
            <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() => this.handleDeleteButton(contact.id)}
            >
              <img alt="remove-customer-btn" src={CloseButton}/>
            </IconButton>
          }
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleShowEditContact(contact.id)}
          >
            <img alt="edit-customer-btn" src={EditButton}/>
          </IconButton>
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleShowViewContact(contact.id)}
          >
            <img alt="view-customer-btn" src={ViewButton}/>
          </IconButton>
        </GridItem>
      </GridContainer>
    )
  };

  renderContactCard = (contact, index) => {
    const { t } = this.props;
    const renderName = (contact) => {
      let result = "";
      if (contact.title) {
        result += contact.title;
      }
      if (contact.first_name) {
        if (result.length > 0) {
          result += " ";
        }
        result += contact.first_name;
      }
      if (contact.last_name) {
        if (result.length > 0) {
          result += " ";
        }
        result += contact.last_name;
      }
      return result;
    }
    return (
      <GridContainer className="contacts-card-item" key={index}>
        <div className="contact-name">{renderName(contact)}</div>
        <div className="contact-company-name">{contact.company ? contact.company : ''}</div>
        <div className="contact-card-info">{this.wrapText(`${t('private_phone')}: ${contact.phone_private ? contact.phone_private : 'N/A'}`,40)}</div>
        <div className="contact-card-info">{this.wrapText(`${t('mobile_number')} 1: ${contact.phone_mobile ? contact.phone_mobile : 'N/A'}`,40)}</div>
        <div className="contact-card-info">{this.wrapText(`${t('email')}: ${contact.email ? contact.email : 'N/A'}`,32)}</div>
        <div className="contact-card-action-btn">
          {
            contact.can_delete && (
              <IconButton
                aria-label="close"
                className="action-btn"
                onClick={() => this.handleDeleteButton(contact.id)}
              >
                <img alt="remove-card-customer" src={CloseButton}/>
              </IconButton>
            )
          }
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleShowViewContact(contact.id)}
          >
            <img alt="view-card-customer" src={ViewButton}/>
          </IconButton>
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleShowEditContact(contact.id)}
          >
            <img alt="edit-card-customer" src={EditButton}/>
          </IconButton>
        </div>
      </GridContainer>
    )
  };

  getIsAllRowsOnPageSelected = () => {
    const { customers } = this.props;
    const { currentSelection } = this.state;
    
    if (customers && customers.length > 0 && currentSelection && currentSelection.length > 0) {
      const customerIds = customers.map(el => el.id);
      return checkArraySetEquality(currentSelection, customerIds);
    }
    return false;
  }

  render() {
    const {
      customers,
      customer,
      customerImportValidationErrors,
      metaData,
      t
    } = this.props;
    const {
      isViewTable,
      isImportDialogOpen,
      isImportDelimiterDialogOpen,
      isImportErrorDialogOpen,
      isCreateContactOpen,
      isEditContactOpen,
      isViewContactOpen,
      isRemoveContactOpen,
      isRemoveSelectedOpen,
      deletedContactId,
      contactId,
      filters,
      currentSelection,
      contactCategoriesHierarchyTransformed
    } = this.state;

    const sortArray = [
      { id: 0, field: 'first_name', value: t('sort_by_first_name') },
      { id: 1, field: 'last_name', value: t('sort_by_last_name') },
      { id: 2, field: 'email', value: t('sort_by_email') }
    ];
    const selectedSortById = filters.sortBy
      ? sortArray.find(el => el.field == filters.sortBy).id
      : 0;

    const isAllRowsOnPageSelected = this.getIsAllRowsOnPageSelected();

    const { sortBy, sortByOrder } = filters;
    return (
      <div className="ikt-ap_contacts-container">
        <GridContainer>
          <GridItem className="contacts-dropdown-content" xs={12}>
            <CustomMultiselectHierarchicalFilter
              buttonValue={ filters.categories }
              buttonProps={{
                className: 'contact-dropdown-style',
                round: true,
                size: 'sm'
              }}
              textSelectedEverything={ t('all_categories') }
              dropdownList={ contactCategoriesHierarchyTransformed }
              translateValues={true}
              hoverColor="dark"
              onChange={ids => this.handleFilterChange('categories', ids)}
            />
            <CustomDropdown
              buttonText={selectedSortById}
              buttonProps={{
                className: 'contact-dropdown-style',
                round: true,
                size: 'sm'
              }}
              menuWidthCorrection={true}
              dropdownList={sortArray}
              translateValues={true}
              onClick={item => this.handleSortByChange(item.field)}
            />
            <Button
              className="import add-contact-btn"
              onClick={this.handleToggleDelimiterDialog}
            >
              { t('import').toUpperCase() }
            </Button>
            <GridViewButton
              className="project-view-button"
              isTable={isViewTable}
              onViewChange={this.handleViewChange}
            />
            { this.renderSelectButton() }
            <Button onClick={() => this.handleToggleCreateContact()} size="sm" className="add-contact-btn">
              <Icon>add</Icon> { t('create').toUpperCase() }
            </Button>
          </GridItem>
          {
            isViewTable && <GridContainer className="contacts-header">
              <GridItem className={"contacts-header-title _select " + (isAllRowsOnPageSelected ? ' select-selected-all' : '')}>
                <Checkbox
                  checked={isAllRowsOnPageSelected}
                  onChange={e => this.handleSelectionToggleAllNone() }
                  color="primary"
                />
              </GridItem>
              <GridItem className="contacts-header-title _salutation" xs={1}>{ t('title') }</GridItem>
              <GridItem className={"contacts-header-title" + (sortBy === 'first_name' ? ' col-sort-active' : '')} xs={2}>
                <span onClick={sortBy === 'first_name' ? this.handleSortByFlipOrder : null}>
                { t('first_name') } { sortBy === 'first_name' && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className={"contacts-header-title" + (sortBy === 'last_name' ? ' col-sort-active' : '')} xs={2} lg={1}>
                <span onClick={sortBy === 'last_name' ? this.handleSortByFlipOrder : null}>
                { t('last_name') } { sortBy === 'last_name' && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className="contacts-header-title" xs={3} lg={2}>{ t('company') }</GridItem>
              <GridItem className="contacts-header-title _private-phone" xs={1}>{ t('private_phone') }</GridItem>
              <GridItem className="contacts-header-title" xs={3} lg={2}>{ t('mobile_number') } 1</GridItem>
              <GridItem className={"contacts-header-title _email" + (sortBy === 'email' ? ' col-sort-active' : '')} xs={2}>
                <span onClick={sortBy === 'email' ? this.handleSortByFlipOrder : null}>
                { t('email') } { sortBy === 'email' && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className="contacts-header-title _actions" xs={2} lg={1}>{ t('actions') }</GridItem>
            </GridContainer>
          }
          {
            this.isContactsDataReady() ?
              <GridContainer className={`contacts-content ${!isViewTable ? 'card-content' : ''}`}>
                {
                  customers && customers.length !== 0 &&
                  isViewTable
                    ? customers.map((contact, index) => this.renderContact(contact, index))
                    : customers.map((contact, index) => this.renderContactCard(contact, index))
                }
                {
                  customers && customers.length === 0 &&
                  <div className="no_customers">{this.props.match.url.split('/').length >= 2 ? this.renderSwitch(this.props.match.url.split('/')[2]) : t('no_contacts_found')}</div>
                }
              </GridContainer>
            :
              <div className="ikt-ap_loader">
                <Loader />
              </div>
          }
          {
            isImportDialogOpen &&
            <FileImportDialog
              isOpen={isImportDialogOpen}
              onClose={this.handleCloseImportDialog}
              onStartButton={this.handleStartButton}
            />
          }
          {
            isImportDelimiterDialogOpen &&
            <DelimiterDialog
              isOpen={isImportDelimiterDialogOpen}
              onClose={this.handleToggleDelimiterDialog}
            />
          }
          <ImportErrorDialog
            isOpen={isImportErrorDialogOpen}
            error={customerImportValidationErrors}
            onClose={this.handleToggleImportErrorDialog}
          />
        </GridContainer>
        <GridContainer className="pagination-container">
          {
            metaData.last_page > 1 &&
            <CustomPagination
              pageCount={metaData.last_page}
              forcePage={this.state.currentPage - 1}
              handlePageClick={this.handlePageClick}
            />
          }
        </GridContainer>
        {
          isCreateContactOpen &&
          <CreateContact
            isOpen={isCreateContactOpen}
            isEditContact={false}
            onClose={this.handleToggleCreateContact}
            currentPage={this.state.currentPage}
            currentFilters={this.state.filters}
            currentSearchTerms={this.props.searchTerms}
            category={this.props.match.params.id}
          />
        }
        {
          isEditContactOpen && Object.keys(customer).length !== 0 &&
          <CreateContact
            isOpen={isEditContactOpen}
            isEditContact={true}
            onClose={this.handleCloseEditContact}
            editCustomer={customers.find(customer => customer.id === this.state.editContactId)}
            currentPage={this.state.currentPage}
            currentFilters={this.state.filters}
            currentSearchTerms={this.props.searchTerms}
            category={this.props.match.params.id}
          />
        }
        {
          isViewContactOpen && Object.keys(customer).length !== 0 &&
          <ViewContact
            isOpen={isViewContactOpen}
            onClose={this.handleCloseViewContact}
            onEditContact={this.handleShowEditContact}
            id={contactId}
          />
        }
        {isRemoveContactOpen && (
          <RemoveDialog
            isOpen={isRemoveContactOpen}
            title={t('remove_contact')}
            text={t('remove_contact_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.removeSelectedCustomer}
          />
        )}
        {isRemoveSelectedOpen && (
          <RemoveDialog
            isOpen={isRemoveSelectedOpen}
            title={t('remove_selected_contacts')}
            text={t('remove_selected_contacts_confirmation')}
            onClose={this.handleRemoveSelectedDialogClose}
            onRemove={this.handleDeleteSelectedCustomers}
          />
        )}
      </div>
    );
  }
}

Contacts.propTypes = {
  classes: PropTypes.object.isRequired,
  searchTerms: PropTypes.string,
  onSearchReset: PropTypes.func
};

const mapStateToProp = state => {
  return {
    customers: state.customers.customers,
    customersBusy: state.customers.customersBusy,
    customer: state.customers.customer,
    customerImportValidationData: state.customers.customerImportValidationData,
    customerImportValidationErrors: state.customers.customerImportValidationErrors,
    customerImportedFileData: state.customers.customerImportedFileData,
    contactCategoriesHierarchy: state.categories.contactCategoriesHierarchy,
    isValidationSuccess: state.customers.isValidationSuccess,
    isStartSuccess: state.customers.isStartSuccess,
    metaData: state.customers.metaData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOwnContactsCategoriesHierarchy: () => dispatch(getOwnContactsCategoriesHierarchy()),
    getCustomers: (page, filters, searchTerms) => dispatch(getCustomers(page, filters, searchTerms)),
    getCustomer: (id) => dispatch(getCustomer(id)),
    startCustomerFileImport: (data) => dispatch(startCustomerFileImport(data)),
    removeCustomer: (id, loadListInfo) => dispatch(removeCustomer(id, loadListInfo)),
    removeCustomers: (data, loadListInfo) => dispatch(removeCustomers(data, loadListInfo))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(Contacts)));
