import React from 'react';
import { useTranslation } from 'react-i18next';

import Input from '@material-ui/core/Input';

import './style.scss';

const InputWithIcon = React.forwardRef((props, ref) => {
  const {className, placeholder, removePlaceholder, disableTabFocus, readOnly, error, id, ...rest} = props;
  const { t } = useTranslation();
  let containerClassName = `ikt-ap_input-with-icon${error ? '_error' : ''}${className ? ` ${className}` : ``}`;
  let placeholderText = placeholder ? placeholder : t('enter');
  const inputPropsObj = {};
  if (disableTabFocus)
    inputPropsObj['tabindex'] = '-1';
  return (
    <Input
      className={containerClassName}
      disableUnderline={true}
      placeholder={!removePlaceholder && placeholderText}
      id={id}
      readOnly={readOnly}
      {...rest}
      ref={ref}
      inputProps={inputPropsObj}
    />
  )
});

export default InputWithIcon;

