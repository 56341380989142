import { handleActions } from 'redux-actions';
import {
  GET_CURRENT_TIMER,
  GET_CURRENT_TIMER_SUCCESS,
  GET_CURRENT_TIMER_ERROR,
  START_TIMER,
  START_TIMER_SUCCESS,
  START_TIMER_ERROR,
  PAUSE_TIMER,
  PAUSE_TIMER_SUCCESS,
  PAUSE_TIMER_ERROR,
  RESUME_TIMER,
  RESUME_TIMER_SUCCESS,
  RESUME_TIMER_ERROR,
  UPDATE_TIMER_NOTE,
  UPDATE_TIMER_NOTE_SUCCESS,
  UPDATE_TIMER_NOTE_ERROR,
  FINALIZE_TIMER,
  FINALIZE_TIMER_SUCCESS,
  FINALIZE_TIMER_ERROR,
  DELETE_TIMER,
  DELETE_TIMER_SUCCESS,
  DELETE_TIMER_ERROR,
  RESET_ERROR,
} from '../actionTypes/timerActionTypes';

export const DEFAULT_TIMER_STATE = {
  isTimerStarted: false,
  isTimerPaused: false,
  isTimerFinalized: false,
  isTimerDeleted: false,
  currentTimer: null,
  requestSent: null,
  serverTimestamp: null,
  responseReceived: null,
  error: null,
};

export const timers = handleActions(
  {
    [GET_CURRENT_TIMER]: (state) => ({
      ...state,
      requestSent: Math.floor(Date.now() / 1000),
    }),
    [GET_CURRENT_TIMER_SUCCESS]: (state, action) => ({
      ...state,
      currentTimer: action.payload,
      isTimerStarted: Boolean(action.payload),
      isTimerPaused: action.payload ? Boolean(action.payload.paused_at) : false,
      serverTimestamp: action.payload ? action.payload.current_ts : null,
      responseReceived: Math.floor(Date.now() / 1000),
    }),
    [GET_CURRENT_TIMER_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [START_TIMER]: (state) => ({
      ...state,
      isTimerStarted: false,
      isTimerPaused: false,
      isTimerFinalized: false,
      isTimerDeleted: false,
      requestSent: Math.floor(Date.now() / 1000),
    }),
    [START_TIMER_SUCCESS]: (state, action) => ({
      ...state,
      isTimerStarted: true,
      isTimerPaused: Boolean(action.payload.paused_at),
      currentTimer: action.payload,
      serverTimestamp: action.payload.current_ts,
      responseReceived: Math.floor(Date.now() / 1000),
    }),
    [START_TIMER_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [PAUSE_TIMER]: (state) => ({
      ...state,
    }),
    [PAUSE_TIMER_SUCCESS]: (state, action) => ({
      ...state,
      isTimerPaused: true,
      currentTimer: action.payload,
    }),
    [PAUSE_TIMER_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [RESUME_TIMER]: (state) => ({
      ...state,
      requestSent: Math.floor(Date.now() / 1000),
    }),
    [RESUME_TIMER_SUCCESS]: (state, action) => ({
      ...state,
      isTimerPaused: false,
      isTimerStarted: true,
      currentTimer: action.payload,
      serverTimestamp: action.payload ? action.payload.current_ts : null,
      responseReceived: Math.floor(Date.now() / 1000),
    }),
    [RESUME_TIMER_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [UPDATE_TIMER_NOTE]: (state) => ({
      ...state,
    }),
    [UPDATE_TIMER_NOTE_SUCCESS]: (state) => ({
      ...state,
    }),
    [UPDATE_TIMER_NOTE_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [FINALIZE_TIMER]: (state, action) => ({
      ...state,
    }),
    [FINALIZE_TIMER_SUCCESS]: (state) => ({
      ...state,
      isTimerStarted: false,
      isTimerPaused: false,
      isTimerFinalized: true,
      isTimerDeleted: false,
      currentTimer: null,
    }),
    [FINALIZE_TIMER_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [DELETE_TIMER]: (state) => ({
      ...state,
    }),
    [DELETE_TIMER_SUCCESS]: (state) => ({
      ...state,
      isTimerStarted: false,
      isTimerPaused: false,
      isTimerFinalized: false,
      isTimerDeleted: true,
      currentTimer: null,
    }),
    [DELETE_TIMER_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [RESET_ERROR]: (state) => ({
      ...state,
      error: null,
    }),
  },
  DEFAULT_TIMER_STATE,
);
