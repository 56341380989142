import React from 'react';

import GridItem from 'components/Grid/GridItem';

import './style.scss';

class ContactCard extends React.Component {
  render() {
    const {data} = this.props;
    return (
      <GridItem className="contact-person-card">
        <div className="card-title">Contact person 1</div>
        <div className="contact-person-item">
          <GridItem className="item-title" xs={5}>Email</GridItem>
          <GridItem xs={7}>{data.email ? data.email : ''}</GridItem>
        </div>
        <div className="contact-person-item">
          <GridItem className="item-title" xs={5}>Phone</GridItem>
          <GridItem xs={7}>{data.phone_1 ? data.phone_1 : ''}</GridItem>
        </div>
        <div className="contact-person-item">
          <GridItem className="item-title" xs={5}>Fax</GridItem>
          <GridItem xs={7}>{data.fax ? data.fax : ''}</GridItem>
        </div>
        <div className="contact-person-item">
          <GridItem className="item-title" xs={5}>Skype</GridItem>
          <GridItem xs={7}>{data.skype ? data.skype : ''}</GridItem>
        </div>
        <div className="contact-person-item">
          <GridItem className="item-title" xs={5}>Viber</GridItem>
          <GridItem xs={7}>{data.viber ? data.viber : ''}</GridItem>
        </div>
        <div className="contact-person-item">
          <GridItem className="item-title" xs={5}>WhatsApp</GridItem>
          <GridItem xs={7}>{data.whatsapp ? data.whatsapp : ''}</GridItem>
        </div>
      </GridItem>
    )
  }
}

export default ContactCard;
