/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
// core components
import Button from 'components/CustomButtons/Button.jsx';

import Arrow from '../../assets/img/buttons/chevron-down.svg';
import ArrowWhite from '../../assets/img/buttons/status-dropdown.svg';

import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx';

import './customDropdown.scss';

class CustomDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedIndex: -1,
    };
  }

  getDefaultSelectedIndex = () => {
    if (this.props) {
      const { buttonText, dropdownList } = this.props;
      let selectedPropIndex = dropdownList.findIndex(el => el.id == buttonText);
      if (selectedPropIndex != -1) {
        return selectedPropIndex;
      } else if (buttonText && buttonText.props && buttonText.props.children && buttonText.props.children.id) {
        selectedPropIndex = dropdownList.findIndex(el => el.id == buttonText.props.children.id);
        return selectedPropIndex;
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  }

  handleClick = () => {
    if (this.props.disabled)
      return;
    const defaultSelectedIndex = this.getDefaultSelectedIndex();
    this.setState(state => ({
      open: !state.open,
      selectedIndex: defaultSelectedIndex,
    }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleCloseMenu = param => {
    this.setState({ open: false });
    if (this.props.onClick)
      this.props.onClick(param);
  }

  scrollSelectedMenuItemIntoView = () => {
    let selectedElements = document.getElementsByClassName("ikt-ap_custom-dropdown-item-selected");
    if (selectedElements && selectedElements[0])
      selectedElements[0].scrollIntoView({ block: "nearest" });
  }

  handleKeyDown = event => {
    const { dropdownList } = this.props;
    const { open, selectedIndex } = this.state;

    if (!open) {
      if ((event.keyCode == 13 || event.keyCode == 32) && !this.props.disabled) {  // enter or space
        const defaultSelectedIndex = this.getDefaultSelectedIndex();
        event.preventDefault();
        this.setState({
          open: true,
          selectedIndex: defaultSelectedIndex,
        });
      }
    } else {
      if (event.keyCode == 40) {  // down arrow
        if (selectedIndex !== -1) {
          if (selectedIndex + 1 < dropdownList.length) {
            this.setState({
              selectedIndex: selectedIndex + 1,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        } else {
          if (dropdownList.length > 0) {
            this.setState({
              selectedIndex: 0,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        }
      } else if (event.keyCode == 38) {  // up arrow
        if (selectedIndex !== -1) {
          if (selectedIndex - 1 >= 0) {
            this.setState({
              selectedIndex: selectedIndex - 1,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        } else {
          if (dropdownList.length > 0) {
            this.setState({
              selectedIndex: dropdownList.length - 1,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        }
      } else if (event.keyCode == 13 || event.keyCode == 32) {  // enter or space
        if (selectedIndex !== -1) {
          event.preventDefault();
          this.handleCloseMenu(dropdownList[selectedIndex]);
        }
      } else if (event.keyCode == 27) {  // escape
        this.setState({
          open: false,
          selectedIndex: -1,
        });
      }
    }
  }

  handleMouseItemEnter = (event, prop) => {
    const { dropdownList } = this.props;
    const selectedIndex = dropdownList.findIndex(el => el.id === prop.id);
    if (selectedIndex != -1) {
      this.setState({
        selectedIndex
      });
    }
  }

  render() {
    const { open, selectedIndex } = this.state;
    const {
      classes,
      buttonText,
      buttonPlaceholder,
      buttonIconType,
      dropdownList,
      buttonProps,
      dropup,
      dropdownHeader,
      id,
      hoverColor,
      dropPlacement,
      rtlActive,
      menuWidthCorrection,
      noLiPadding,
      innerDropDown,
      navDropdown,
      error,
      customClass,
      className,
      disabled,
      translateValues,
      t
    } = this.props;
    const caretClasses = classNames({
      [classes.caret]: true,
      [classes.caretDropup]: dropup && !open,
      [classes.caretActive]: open && !dropup,
      [classes.caretRTL]: rtlActive
    });
    const dropdownItem = classNames({
      [classes.dropdownItem]: true,
      [classes[hoverColor + 'Hover']]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive
    });
    const renderIcon = prop => {
      if (prop && prop.icon) {
        return (
          <img
            src={prop.icon}
            alt={`flag-${prop.id}`}
            className="menu-item-icon"
          />
        );
      }
      return null;
    };
    const dropDownMenu = (
      <MenuList role="menu" className={`${classes.menuList} ${customClass ? customClass : ''}`}>
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => this.handleCloseMenu(dropdownHeader)}
            className={classes.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {dropdownList.map((prop, key) => {
          const isSelected = selectedIndex == key;
          if (prop.divider) {
            return (
              <Divider
                key={key}
                onClick={() => this.handleCloseMenu('divider')}
                className={classes.dropdownDividerItem}
              />
            );
          } else if (
            prop.props !== undefined &&
            prop.props['data-ref'] === 'multi'
          ) {
            return (
              <MenuItem
                key={key}
                className={`${dropdownItem}${
                  isSelected ? ` ikt-ap_custom-dropdown-item-selected` : ``
                } custom-dropdown-item`}
                style={{ overflow: 'visible', padding: 0 }}
              >
                { renderIcon(prop) } { translateValues ? t(prop.value) : prop.value}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={key}
              onClick={() => this.handleCloseMenu(prop)}
              onMouseEnter={(event) => this.handleMouseItemEnter(event, prop)}
              className={`${dropdownItem}${
                isSelected ? ` ikt-ap_custom-dropdown-item-selected` : ``
              } custom-dropdown-item`}
            >
              { renderIcon(prop) } { translateValues ? t(prop.value) : prop.value}
            </MenuItem>
          );
        })}
      </MenuList>
    );

    let buttonToggleClasses = `${buttonProps.className} ikt-ap_custom-dropdown-toggle-btn ${error ? 'dropdown-error' : ''}`;
    let buttonValue = '';
    let selectedDropdownItem = null;
    if (buttonText === undefined || buttonText === '' || buttonText === null) {
        if (buttonPlaceholder) {
          buttonValue = buttonPlaceholder;
        } else {
          buttonValue = t('please_select');
        }
    } else {
      selectedDropdownItem = dropdownList.find(item => item.id == buttonText);
      if (selectedDropdownItem) {
        buttonValue = translateValues ? t(selectedDropdownItem.value) : selectedDropdownItem.value;
      } else {
        buttonValue = t('please_select');
      }
    }
    return (
      <div
        className={`ikt-ap_custom-dropdown dropdown-blue ${
          innerDropDown ? classes.innerManager : classes.manager
        } ${
          className ? className : ''
        }`}
        onKeyDown={this.handleKeyDown}
      >
        <div className={buttonText !== undefined ? '' : classes.target}>
          <Button
            aria-label="Notifications"
            id={id}
            aria-owns={open ? 'menu-list' : null}
            aria-haspopup="true"
            buttonRef={node => {
              this.anchorEl = node;
            }}
            {...buttonProps}
            className={buttonToggleClasses + (selectedDropdownItem && selectedDropdownItem.icon ? ' cdl-restyle-button' : '')}
            onClick={this.handleClick}
          >
            { renderIcon(selectedDropdownItem) }
            { buttonValue }
            { !disabled && ( buttonIconType === 'white'
              ? <img alt="dropdown-caret-white" src={ArrowWhite} className={caretClasses} />
              : <img alt="dropdown-caret-blue" src={Arrow} className={caretClasses} /> ) }
          </Button>
        </div>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true,
            [classes.popperNav]: open && navDropdown
          })}
        >
          {() => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: '0 100% 0' }
                  : { transformOrigin: '0 0 0' }
              }
            >
              <Paper
                className={`${classes.dropdown} ikt-ap_custom-dropdown-menu ${menuWidthCorrection ? 'menu-corrected-width' : ''}`}
              >
                {innerDropDown ? (
                  dropDownMenu
                ) : (
                  <ClickAwayListener onClickAway={this.handleClose}>
                    {dropDownMenu}
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CustomDropdown.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: 'primary'
};

CustomDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.object,
  id: PropTypes.string,
  dropdownList: PropTypes.array,
  translateValues: PropTypes.bool,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    'bottom',
    'top',
    'right',
    'left',
    'bottom-start',
    'bottom-end',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end'
  ]),
  menuWidthCorrection: PropTypes.bool,
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // This is a function that returns the clicked menu item
  onClick: PropTypes.func
};

export default withStyles(customDropdownStyle)(withTranslation()(CustomDropdown));
