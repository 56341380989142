import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import {
  getMaterials,
  getMaterialsInBundles,
  duplicateMaterial,
  updateCompoundMaterial,
  setMaterialAvailability,
  startMaterialFileImport,
  downloadMaterialFileImportSkipped,
  removeMaterial,
  restoreMaterials,
  removeMaterials,
  restoreMaterial,
} from '../../store/actions/materials';
import {getMaterialsCategories, getMaterialsCategoriesProvider} from '../../store/actions/categories';

import Checkbox from 'components/StyledCheckbox';
import Button from 'components/CustomButtons/Button.jsx';
import DropdownButton from '../../components/CustomButtons/DropdownButton';
import CreateMaterial from '../CreateMaterial';
import UpdateMaterialCategoryDialog from '../UpdateMaterialCategory';
import UpdateMaterialPriceDialog from '../UpdateMaterialPrice';
import CreateCompoundMaterial from '../CreateCompoundMaterial';
import ViewCompoundMaterial from '../ViewCompoundMaterial';
import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CustomMultiselect from 'components/CustomMultiselect/CustomMultiselect';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ViewButton from '../../components/ViewButton';
import ViewMaterial from '../ViewMaterial';
import MaterialCategoriesDialog from '../MaterialCategoriesDialog';
import MaterialImportSkippedDialog from '../MaterialImportSkippedDialog';
import Loader from '../../components/Loader/Loader';

import MaterialArrowUp from '../../assets/img/material_arrow_up.svg';
import MaterialArrowDown from '../../assets/img/material_arrow_down.svg';
import MaterialArrowUpBlue from '../../assets/img/material_arrow_up_blue.svg';
import MaterialArrowDownBlue from '../../assets/img/material_arrow_down_blue.svg';
import IconRestore from '../../assets/img/icon-restore.svg';
import DeleteIcon from '../../assets/img/buttons/delete.svg';
import DuplicateIcon from '../../assets/img/buttons/btn-copy.svg';
import EditIcon from '../../assets/img/buttons/edit.svg';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '../../assets/img/buttons/eye.svg';

import './styles.scss';
import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import RemoveDialog from '../RemoveDialog';
import WarningDialog from '../WarningDialog';
import CustomPagination from '../../components/CustomPagination';
import { convertToFormData, calculateMaterialRetailPrice, checkArraySetEquality, formatNumericValue } from '../../utils/utils';
import ConfirmDialog from "../ConfirmDialog";

class Materials extends React.Component {
  static AvailabilityOptions = [
    { id: 0, value: 'in_stock' },
    { id: 1, value: 'out_of_stock' }
  ];

  constructor(props) {
    super(props);
    this.state = {
      isTableView: true,
      isCreateMaterialOpen: false,
      isCreateCompoundMaterialOpen: false,
      isViewMaterialOpen: false,
      isImportMaterialCategoryOpen: false,
      isImportMaterialDuplicatesOpen: false,
      isUpdateMaterialCategoryOpen: false,
      isUpdateMaterialPriceOpen: false,
      isRemoveMaterialOpen: false,
      isRemoveSelectedOpen: false,
      isRestoreSelectedOpen: false,
      isViewCompoundMaterialOpen: false,
      isDuplicateMaterialOpen: false,
      removeDialogTitle: '',
      removeDialogText: '',
      deletedMaterialId: '',
      restoreMaterialId: '',
      editMaterial: null,
      viewMaterial: null,
      viewBundle: null,
      viewBundleMaterials: [],
      editCompoundMaterial: null,
      selectedFile: '',
      duplicatedMaterialId: '',
      filters: {
        category: [-1],
        in_stock: null,
        active: true,
        sortBy: 'code',
        sortByOrder: true,  // true is ascending
      },
      compoundStates: {},
      currentPage: 1,
      currentSelection: [],
      isUpdateByCategory: true,
    };
  }

  componentDidMount() {
    this.reloadDataList();
    this.props.getCategories();
    this.props.getCategoriesFilterList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchTerms != prevProps.searchTerms) {
      this.reloadDataList(true);
    }
    if (prevProps.materialImportedFileData.processID !== this.props.materialImportedFileData.processID && Boolean(this.props.materialImportedFileData.processID)) {
      this.handleCloseMaterialCategoriesDialog();
      this.props.getCategoriesFilterList();
      this.reloadDataList();

      if (!prevProps.materialImportedFileData.skipped && Boolean(this.props.materialImportedFileData.skipped)) {
        this.setState({
          isImportMaterialDuplicatesOpen: true,
        });
      }
    }
    if (prevProps.materialImportedFileSkippedBlob !== this.props.materialImportedFileSkippedBlob && Boolean(this.props.materialImportedFileSkippedBlob)) {
      FileSaver.saveAs(this.props.materialImportedFileSkippedBlob, this.props.materialImportedFileSkippedFilename);
    }
    if (!prevProps.isMultipleMaterialsUpdateValid && this.props.isMultipleMaterialsUpdateValid) {
      this.setState({
        currentSelection: [],
      });
    }
    if (!prevProps.isRemoveMaterialValid && this.props.isRemoveMaterialValid) {
      const wrapper = document.getElementById('ikt-admin-mainPanel');
      if (wrapper)
        wrapper.scrollTop = 0;
    }
    if ((!prevProps.isMaterialValid && this.props.isMaterialValid) || (!prevProps.isRemoveMaterialValid && this.props.isRemoveMaterialValid)) {
      this.props.getCategoriesFilterList();
    }
    /* sample code that triggers a "clear search", should the page require it
    setTimeout(() => {
      this.props.onSearchReset && this.props.onSearchReset();
    }, 2500);
    */
  }

  static getDerivedStateFromProps(props, state) {
    const { compoundStates } = state;
    const { materials, materialsBundles } = props;

    // states for compound materials
    const compoundStatesKeys = Object.keys(compoundStates);
    for (let i in compoundStatesKeys) {
      const key = parseInt(compoundStatesKeys[i]);
      if (!materialsBundles.successes[key] && materialsBundles.ids.indexOf(key) == -1)
        delete compoundStates[key];
    }

    for (let i in materials) {
      const material = materials[i];
      if (material.material_type == 1 && !compoundStates[material.id]) {
        compoundStates[material.id] = {
          data: false,
          open: false,
          subcomponentsOpen: compoundStates[material.id] && compoundStates[material.id].subcomponentsOpen ? compoundStates[material.id].subcomponentsOpen : {},
        };
      }
    }
    const materialsBundlesIds = Object.keys(materialsBundles.successes);
    for (let i in materialsBundlesIds) {
      const key = materialsBundlesIds[i];
      compoundStates[parseInt(key)].data = true;
    }

    // preserve view material
    let newViewMaterial = state.viewMaterial
      ? materials.find(el => el.id == state.viewMaterial.id)
      : null;

    return { compoundStates, viewMaterial: newViewMaterial };
  }

  reloadDataList = (resetPage) => {
    if (!resetPage || this.state.currentPage == 1) {
      this.props.getMaterials(
        this.state.currentPage,
        this.state.filters,
        this.props.searchTerms
      );
    } else {
      this.setState({
        currentPage: 1,
      }, () => {
        this.props.getMaterials(
          this.state.currentPage,
          this.state.filters,
          this.props.searchTerms
        );
      });
    }
  };

  isMaterialsDataReady = () => {
    const { materialsBusy, materialsMutBusy } = this.props;
    return !materialsBusy && !materialsMutBusy;
  };

  handlePageClick = data => {
    const { selected } = data;
    this.setState({
      currentPage: selected + 1,
      compoundStates: {},
      currentSelection: [],
    }, () => {
      this.reloadDataList();
    });
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    if (wrapper)
      wrapper.scrollTop = 0;
  };

  handleFileChange = e => {
    this.setState({
      selectedFile: e.target.files[0],
      isImportMaterialCategoryOpen: true
    });
    e.target.value = '';
  };

  handleCloseMaterialCategoriesDialog = () => {
    this.setState({
      selectedFile: '',
      isImportMaterialCategoryOpen: false
    });
  };

  handleCloseImportMaterialDuplicatesDialog = () => {
    this.setState({
      isImportMaterialDuplicatesOpen: false,
    });
  };

  handleImportMaterialDuplicatesDownload = () => {
    const processID = this.props.materialImportedFileData.processID;
    this.props.importDownloadSkippedMaterials(processID);
    this.handleCloseImportMaterialDuplicatesDialog();
  }

  handleCloseUpdateMaterialCategoryDialog = () => {
    this.setState({
      isUpdateMaterialCategoryOpen: false,
    });
  }

  handleCloseUpdateMaterialPriceDialog = () => {
    this.setState({
      isUpdateMaterialPriceOpen: false,
    });
  }

  handleImportMaterial = (categoryId, replace) => {
    const { startImport } = this.props;
    const { selectedFile } = this.state;
    let formData = new FormData();
    formData.append('background', '0');
    formData.append('config[parse_only]', '0');
    formData.append('config[has_header]', '1');
    formData.append('config[category_id]', categoryId);
    formData.append('config[replace]', replace);  // 0 is no, 1 is yes
    formData.append('file', selectedFile);
    startImport(formData);
  };

  updateMaterialAvailability = (id, value) => {
    const { setMaterialAvailability, metaData } = this.props;
    const { compoundStates } = this.state;
    const loadCompoundMaterials = Object.keys(compoundStates);
    const loadCompoundMaterialsArg =
      loadCompoundMaterials.length > 0 ? loadCompoundMaterials : null;
    setMaterialAvailability({ page: this.state.currentPage, filters: this.state.filters, searchTerms: this.props.searchTerms, loadCompoundMaterials: loadCompoundMaterialsArg }, { id, value });
  };

  handleViewChange = value => {
    if (value === 'table') {
      this.setState({ isTableView: true });
    } else if (value === 'card') {
      this.setState({ isTableView: false });
    }
  };

  handleMaterialClose = () => {
    if (this.state.isCreateMaterialOpen) {
      this.setState({ isCreateMaterialOpen: false, editMaterial: null });
    }
  };

  handleToggleMaterialChange = () => {
    if (this.state.isCreateMaterialOpen) {
      this.setState({ isCreateMaterialOpen: false, editMaterial: null });
    } else {
      this.setState({ isCreateMaterialOpen: true });
    }
  };

  handleCompoundMaterialClose = () => {
    if (this.state.isCreateCompoundMaterialOpen) {
      this.setState({ isCreateCompoundMaterialOpen: false, editCompoundMaterial: null });
    }
  };

  handleToggleCompoundMaterialChange = () => {
    if (this.state.isCreateCompoundMaterialOpen) {
      this.setState({ isCreateCompoundMaterialOpen: false, editCompoundMaterial: null });
    } else {
      this.setState({ isCreateCompoundMaterialOpen: true });
    }
  };

  handleToggleViewMaterial = () => {
    if (this.state.isViewMaterialOpen) {
      this.setState({ isViewMaterialOpen: false, viewMaterial: null });
    } else {
      this.setState({ isViewMaterialOpen: true });
    }
  };

  handleToggleViewCompoundMaterial = () => {
    this.setState({
      isViewCompoundMaterialOpen: !this.state.isViewCompoundMaterialOpen
    });
  };

  handleEditMaterial = material => {
    this.setState(
      {
        editMaterial: material
      },
      () => {
        this.handleToggleMaterialChange();
      }
    );
  };

  handleEditCompoundMaterial = material => {
    this.setState(
      {
        editCompoundMaterial: material
      },
      () => {
        this.handleToggleCompoundMaterialChange();
      }
    );
  };

  handleViewMaterial = material => {
    this.setState(
      {
        viewMaterial: material
      },
      () => {
        this.handleToggleViewMaterial();
      }
    );
  };

  handleViewCompoundMaterial = material => {
    // this.props.getMaterialsInBundles([material.id], true);
    // const compoundListData = this.props.materialsBundles.successes[material.id];
    this.setState(
      {
        viewBundle: material
      },
      () => {
        this.handleToggleViewCompoundMaterial();
      }
    );
  };

  handleDeleteButton = id => {
    const { t } = this.props;
    this.setState({
      isRemoveMaterialOpen: true,
      removeDialogTitle: t('remove_material'),
      removeDialogText: t('remove_material_confirmation'),
      deletedMaterialId: id
    });
  };

  handleRestoreMaterialButton = id => {
    this.setState({
      isRestoreMaterialOpen: true,
      restoreMaterialId: id
    });
  }

  handleRestoreSelectedMaterialsButton = () => {
    this.setState({
      isRestoreSelectedOpen: true,
    });
  };

  handleDeleteSelectedMaterialsButton = () => {
    this.setState({
      isRemoveSelectedOpen: true,
    });
  };

  handleUpdateSelectedMaterialsButton = () => {
    this.setState({
      isUpdateMaterialCategoryOpen: true,
      isUpdateByCategory: false,
    });
  }

  handleUpdateSelectedMaterialsPriceButton = () => {
    this.setState({
      isUpdateMaterialPriceOpen: true,
    });
  }

  handleRemoveCompoundMaterial = (compoundMaterial, bundleIdToRemove) => {
    const { t } = this.props;
    this.setState({
      isRemoveMaterialOpen: true,
      removeDialogTitle: t('remove_compound_material'),
      removeDialogText: t('remove_compound_material_confirmation'),
      deletedMaterialId: {
        compoundMaterial: compoundMaterial,
        bundleMaterialId: bundleIdToRemove
      }
    });
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveMaterialOpen: false
    });
  };

  handleRestoreDialogClose = () => {
    this.setState({
      isRestoreMaterialOpen: false
    });
  };

  handleRestoreSelectedDialogClose = () => {
    this.setState({
      isRestoreSelectedOpen: false
    });
  };


  handleRemoveSelectedDialogClose = () => {
    this.setState({
      isRemoveSelectedOpen: false
    });
  };

  handleRemoveMaterial = () => {
    const { removeMaterial } = this.props;
    const { deletedMaterialId, currentPage, compoundStates } = this.state;
    const loadCompoundMaterials = Object.keys(compoundStates);
    let loadCompoundMaterialsArg =
      loadCompoundMaterials.length > 0 ? loadCompoundMaterials : null;
    if (loadCompoundMaterialsArg && !isNaN(deletedMaterialId)) {
      loadCompoundMaterialsArg = loadCompoundMaterialsArg.filter(cm => cm != deletedMaterialId);
      if (loadCompoundMaterialsArg.length == 0)
        loadCompoundMaterialsArg = null;
    }
    if (!isNaN(deletedMaterialId)) {
      removeMaterial(deletedMaterialId, !this.state.filters.active, { page: currentPage, filters: this.state.filters, searchTerms: this.props.searchTerms, loadCompoundMaterials: loadCompoundMaterialsArg });
      this.setState({ viewMaterial: null, isViewMaterialOpen: false });
    } else {
      const { materialsBundles, updateCompoundMaterial } = this.props;
      const materialBundle = [
        ...materialsBundles.successes[deletedMaterialId.compoundMaterial.id]
      ];
      const bundleToRemove = materialBundle.find(
        el => el.id == deletedMaterialId.bundleMaterialId
      );
      const indexOfBundleToRemove = materialBundle.indexOf(bundleToRemove);
      materialBundle.splice(indexOfBundleToRemove, 1);
      for (let i in materialBundle) {
        materialBundle[i].value = materialBundle[i].usage;
      }

      const data = {
        ...deletedMaterialId.compoundMaterial,
        category_id:
          deletedMaterialId.compoundMaterial.category.id,
        material_meta: deletedMaterialId.compoundMaterial.material_meta
          ? deletedMaterialId.compoundMaterial.material_meta[0]
          : null,
        materials: materialBundle
      };
      const formData = convertToFormData(data);

      updateCompoundMaterial({ page: currentPage, filters: this.state.filters, searchTerms: this.props.searchTerms, loadCompoundMaterials: loadCompoundMaterialsArg }, { id: deletedMaterialId.compoundMaterial.id, body: formData });
    }
    this.handleRemoveDialogClose();
  };

  handleRestoreMaterial = () => {
    const { restoreMaterial } = this.props;
    const { restoreMaterialId, compoundStates } = this.state;
    const loadCompoundMaterials = Object.keys(compoundStates);
    let loadCompoundMaterialsArg =
      loadCompoundMaterials.length > 0 ? loadCompoundMaterials : null;
    if (loadCompoundMaterialsArg && !isNaN(restoreMaterialId)) {
      loadCompoundMaterialsArg = loadCompoundMaterialsArg.filter(cm => cm != restoreMaterialId);
      if (loadCompoundMaterialsArg.length == 0)
        loadCompoundMaterialsArg = null;
    }
    restoreMaterial(restoreMaterialId, { page: this.state.currentPage, filters: this.state.filters, searchTerms: this.props.searchTerms, loadCompoundMaterials: loadCompoundMaterialsArg });
    this.handleRestoreDialogClose();
  }

  handleFilterChange = (name, value) => {
    const filters = this.state.filters;
    if (name == 'category') {
      if (value.length == 0) {
        filters[name] = [-1];
      } else {
        filters[name] = value;
      }
    } else {
      if (value == -1) {
        filters[name] = null;
      } else {
        filters[name] = value;
      }
    }
    this.setState({ filters, currentPage: 1 }, () => {
      this.reloadDataList();
    });
  };

  handleSortByChange = field => {
    this.setState({
      filters: {
        ...this.state.filters,
        sortBy: field,
        sortByOrder: true,
      },
    }, () => {
      this.reloadDataList();
    });
  };

  handleSortByFlipOrder = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        sortByOrder: !this.state.filters.sortByOrder,
      },
    }, () => {
      this.reloadDataList();
    });
  };

  getMaterialRetailPrice = material => {
    const { currencySymbol } = this.props;
    const formatPrice = price => {
      return formatNumericValue(Math.round((price + Number.EPSILON) * 1000) / 1000);
    };
    let purchasingPrice = null;
    if (
      material.material_price &&
      material.material_price[0] &&
      !isNaN(material.material_price[0].price)
    )
      purchasingPrice = material.material_price[0].price;
    else purchasingPrice = material.default_price;

    let retailPrice = null;
    if (purchasingPrice && !isNaN(purchasingPrice)) {
      retailPrice = calculateMaterialRetailPrice(purchasingPrice, parseFloat(material.discount_percentage), parseFloat(material.surcharge_percentage), parseFloat(material.waste_percentage));
    }
    if (retailPrice && !isNaN(retailPrice)) {
      return `${currencySymbol} ${formatPrice(retailPrice)}`;
    } else {
      return 'N/A';
    }
  };

  handleCompoundMaterialMenuButton = (material, path = []) => {
    if (material.material_type == 1) {
      const { compoundStates } = this.state;
      const compoundState = compoundStates[material.id];
      const newCompoundStates = {
        ...compoundStates,
      };
      if (path.length > 0) {
        let pointer = newCompoundStates;
        for (let i = 0, len = path.length; i < len; ++i) {
          const pathSegment = path[i];
          if (!pointer[pathSegment]) {
            pointer[pathSegment] = {
              open: false,
              subcomponentsOpen: {},
            };
          }
          pointer = pointer[pathSegment];
          if (!pointer.subcomponentsOpen) {
            pointer.subcomponentsOpen = {};
          }
          pointer = pointer.subcomponentsOpen;
        }
        pointer[material.id] = {
          ...pointer[material.id],
          open: pointer[material.id] ? !pointer[material.id].open : true,
        };
      } else {
        newCompoundStates[material.id] = {
          ...compoundStates[material.id],
          open: !compoundState.open,
        };
      }
      this.setState({ compoundStates: newCompoundStates });

      if (!compoundState.data) {
        // fetch data item and expand menu
        this.props.getMaterialsInBundles([material.id], true);
      }
    }
  };

  handleViewMaterialEdit = () => {
    this.setState({
      isViewMaterialOpen: false,
      viewMaterial: null,
      editMaterial: this.state.viewMaterial,
      isCreateMaterialOpen: true
    });
  };

  handleViewCompoundMaterialEdit = () => {
    this.setState({
      isViewCompoundMaterialOpen: false,
      editCompoundMaterial: this.state.viewBundle,
      isCreateCompoundMaterialOpen: true
    });
  };

  handleViewMaterialDelete = () => {
    const { viewMaterial } = this.state;
    this.handleDeleteButton(viewMaterial.id);
  };

  handleUpdateCategoryButton = () => {
    this.setState({
      isUpdateMaterialCategoryOpen: true,
      isUpdateByCategory: true,
    });
  }

  handleRestoreSelectedMaterials = () => {
    const { materials, restoreMaterials } = this.props;
    const { currentPage, compoundStates, currentSelection } = this.state;
    const loadCompoundMaterials = Object.keys(compoundStates);

    const restoringMaterials = [];
    for (let i = 0, len = currentSelection.length; i < len; ++i) {
      const id = currentSelection[i];
      const material = materials.find(m => m.id == id);
      if (material /*&& material.can_delete*/) {
        restoringMaterials.push(id);
      }
    }

    let loadCompoundMaterialsArg = loadCompoundMaterials.length > 0 ? loadCompoundMaterials : null;
    if (loadCompoundMaterialsArg) {
      loadCompoundMaterialsArg = loadCompoundMaterialsArg.filter(cm => restoringMaterials.indexOf(parseInt(cm)) == -1);
      if (loadCompoundMaterialsArg.length == 0)
        loadCompoundMaterialsArg = null;
    }

    let newCurrentPage = currentPage;
    if (newCurrentPage > 1 && materials.length == restoringMaterials.length) {
      // don't show empty page, instead go one page back
      newCurrentPage -= 1;
    }

    this.setState({
      currentSelection: currentSelection.filter(id => restoringMaterials.indexOf(id) == -1),
      currentPage: newCurrentPage,
      isRestoreSelectedOpen: false,
    }, () => {
      restoreMaterials(restoringMaterials, { page: this.state.currentPage, filters: this.state.filters, searchTerms: this.props.searchTerms, loadCompoundMaterials: loadCompoundMaterialsArg });
    });
  }

  handleDeleteSelectedMaterials = () => {
    const { materials, removeMaterials } = this.props;
    const { currentPage, compoundStates, currentSelection } = this.state;
    const loadCompoundMaterials = Object.keys(compoundStates);

    const removingMaterials = [];
    for (let i = 0, len = currentSelection.length; i < len; ++i) {
      const id = currentSelection[i];
      const material = materials.find(m => m.id == id);
      if (material /*&& material.can_delete*/) {
        removingMaterials.push(id);
      }
    }

    let loadCompoundMaterialsArg = loadCompoundMaterials.length > 0 ? loadCompoundMaterials : null;
    if (loadCompoundMaterialsArg) {
      loadCompoundMaterialsArg = loadCompoundMaterialsArg.filter(cm => removingMaterials.indexOf(parseInt(cm)) == -1);
      if (loadCompoundMaterialsArg.length == 0)
        loadCompoundMaterialsArg = null;
    }

    let newCurrentPage = currentPage;
    if (newCurrentPage > 1 && materials.length == removingMaterials.length) {
      // don't show empty page, instead go one page back
      newCurrentPage -= 1;
    }

    this.setState({
      currentSelection: currentSelection.filter(id => removingMaterials.indexOf(id) == -1),
      currentPage: newCurrentPage,
      isRemoveSelectedOpen: false,
    }, () => {
      removeMaterials(removingMaterials, !this.state.filters.active, { page: this.state.currentPage, filters: this.state.filters, searchTerms: this.props.searchTerms, loadCompoundMaterials: loadCompoundMaterialsArg });
    });
  }

  handleSelectionToggleAllNone = () => {
    const { materials } = this.props;
    const { currentSelection } = this.state;
    if (materials.length !== currentSelection.length) {
      this.setState({
        currentSelection: materials.map(m => m.id),
      });
    } else {
      this.setState({
        currentSelection: [],
      });
    }
  }

  handleSelectionChange = (id) => {
    const { currentSelection } = this.state;
    const newCurrentSelection = [...currentSelection];
    const indexOfId = newCurrentSelection.indexOf(id);
    if (indexOfId != -1) {
      newCurrentSelection.splice(indexOfId, 1);
    } else {
      newCurrentSelection.push(id);
      newCurrentSelection.sort();
    }
    this.setState({
      currentSelection: newCurrentSelection
    });
  }

  handleDuplicateButton = (id) => {
    this.setState({
      isDuplicateMaterialOpen: true,
      duplicatedMaterialId: id
    });
  }

  handleDuplicateDialogClose = () => {
    this.setState({
      isDuplicateMaterialOpen: false,
    });
  }

  handleDuplicateMaterial = () => {
    const {
      duplicateMaterial,
      searchTerms
    } = this.props;
    const {
      currentPage,
      filters
    } = this.state;
    const {duplicatedMaterialId} = this.state;
    duplicateMaterial(duplicatedMaterialId, {page: this.state.currentPage, filters: this.state.filters, searchTerms: this.props.searchTerms});
    this.handleDuplicateDialogClose()
  }

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return {
          text: text,
          title: null,
        };
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return {
          text: abbr,
          title: text,
        };
      }
    }
    return null;
  };

  textWrapper = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return <span>{text}</span>;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  renderMaterialTableItem = (material, index, compoundMaterial = null, compoundPath = []) => {
    const { compoundStates, currentSelection, filters } = this.state;
    const { materialsBundles } = this.props;
    let openCondition = false;
    if (compoundPath.length > 0) {
      let pointer = compoundStates[compoundPath[0]];
      for (let i = 1, len = compoundPath.length; i < len; ++i) {
        if (!pointer.subcomponentsOpen || !pointer.subcomponentsOpen[compoundPath[i]]) {
          break;
        }
        pointer = pointer.subcomponentsOpen[compoundPath[i]];
      }
      openCondition = pointer.subcomponentsOpen && pointer.subcomponentsOpen[material.id] ? Boolean(pointer.subcomponentsOpen[material.id].open) : false;
    } else {
      openCondition = compoundStates[material.id] && compoundStates[material.id].open;
    }
    const renderCompoundMaterials =
      material.material_type == 1 &&
      compoundStates[material.id].data &&
      materialsBundles.successes[material.id] &&
      openCondition;

    let brands = null;
    if (material.brands) {
      brands = material.brands.map((el, index) => (
        <span key={index} className="brands-item">{el}</span>
      ));
    }
    let suppliers = null;
    if (material.suppliers) {
      let suppliersArray = material.suppliers;
      if (suppliersArray.length > 3) {
        suppliersArray = suppliersArray.slice(0, 3);
      }
      suppliers = suppliersArray.map((el, index) => (
        <span key={index} className="suppliers-item">{`${el.first_name}${el.last_name ? (' ' + el.last_name) : ''}`}</span>
      ));
    }
    const handleMaterialCode = value => {
      if (!value) value = '';
      if (value.length > 12) return <span title={value}>{value}</span>;
      else return <span>{value}</span>;
    };

    const isEntrySelected = currentSelection && currentSelection.indexOf(material.id) != -1;
    const handleMaterialCheckbox = () => {
      if (!compoundMaterial) {
        return (<Checkbox
          checked={isEntrySelected}
          onChange={e => this.handleSelectionChange(material.id) }
        />);
      } else {
        return null;
      }
    };
    const handleMaterialCategoryName = () => {
      if (material.material_type === 1) {
        const { compoundStates } = this.state;
        let isOpen = false;
        if (compoundPath.length > 0) {
          let pointer = compoundStates[compoundPath[0]];
          for (let i = 1, len = compoundPath.length; i < len; ++i) {
            if (!pointer.subcomponentsOpen || !pointer.subcomponentsOpen[compoundPath[i]]) {
              break;
            }
            pointer = pointer.subcomponentsOpen[compoundPath[i]];
          }
          isOpen = pointer.subcomponentsOpen && pointer.subcomponentsOpen[material.id] ? Boolean(pointer.subcomponentsOpen[material.id].open) : false;
        } else {
          isOpen = compoundStates[material.id].open;
        }
        const icon = isOpen ? <img alt="arrow-up" src={MaterialArrowUpBlue} /> : <img alt="arrow-down" src={MaterialArrowDownBlue} />;
        const categoryName = material.category ? material.category.name : "";
        const textWrapResult = this.wrapText(categoryName, 8);
        return (
          <span title={textWrapResult && textWrapResult.title ? textWrapResult.title : ''} className="compound-material-category-name" onClick={() => this.handleCompoundMaterialMenuButton(material, compoundPath)}>
            {textWrapResult && textWrapResult.text ? textWrapResult.text : ''} {icon}
          </span>
        );
      } else {
        return (<span>{material.category && material.category.name ? material.category.name : ""}</span>);
      }
    };

    const mainEntry = (
      <GridContainer
        className={`material-item ${isEntrySelected ? ' material-item-selected' : ''}`}
        key={!renderCompoundMaterials ? index : 0}
      >
        <GridItem className={"material-item-title mat-grid-col-category" + (material.material_type === 1 ? " mat-grid-col-category-compound" : "")}>
          { handleMaterialCheckbox() }
          { handleMaterialCategoryName() }
        </GridItem>
        <GridItem className="material-item-title mat-grid-col-sku">
          {handleMaterialCode(material.code)}
        </GridItem>
        <GridItem className="material-item-title mat-grid-col-name bold">
          {this.textWrapper(material.name,25)}
        </GridItem>
        <GridItem className="material-item-title mat-grid-col-brand">
          {brands}
        </GridItem>
        <GridItem className="material-item-title mat-grid-col-price">
          {this.getMaterialRetailPrice(material)}
        </GridItem>
        <GridItem
          className={
            'material-item-title availability-dropdown mat-grid-col-availability ' +
            (material.in_stock == 1 ? 'dropdown-green' : 'dropdown-red')
          }
        >
          <Dropdown
            buttonText={material.in_stock == 1 ? 0 : 1}
            buttonIconType="white"
            buttonProps={{
              className: 'dropdown-blue-style status-dropdown',
              round: true,
              size: 'sm'
            }}
            hoverColor="dark"
            dropdownList={Materials.AvailabilityOptions}
            translateValues={true}
            disabled={!this.state.filters.active}
            onClick={data =>
              this.updateMaterialAvailability(material.id, data.id == 0 ? 1 : 0)
            }
          />
        </GridItem>
        <GridItem className="material-item-title mat-grid-col-supplier bold">
          {suppliers}
        </GridItem>
        <GridItem className="material-item-title mat-grid-col-actions">
          {
            !filters.active && <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() => this.handleRestoreMaterialButton(material.id)}
            >
              <img alt="material-restore-btn" src={IconRestore} />
            </IconButton>
          }
          {
            (material.can_delete || Boolean(compoundMaterial)) && <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() =>
                !compoundMaterial
                  ? this.handleDeleteButton(material.id)
                  : this.handleRemoveCompoundMaterial(
                      compoundMaterial,
                      material.id
                    )
              }
            >
              <img alt="material-delete-btn" src={DeleteIcon} />
            </IconButton>
          }
          { filters.active && <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() => this.handleDuplicateButton(material.id)}
            >
              <img alt="material-delete-btn" src={DuplicateIcon} />
            </IconButton>
          }
          { filters.active && <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() =>
                material.material_type == 0
                  ? this.handleEditMaterial(material)
                  : this.handleEditCompoundMaterial(material)
              }
            >
              <img alt="material-edit-btn" src={EditIcon} />
            </IconButton>
          }
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() =>
              material.material_type == 0
                ? this.handleViewMaterial(material)
                : this.handleViewCompoundMaterial(material)
            }
          >
            <img alt="material-view-btn" src={ViewIcon} />
          </IconButton>
        </GridItem>
      </GridContainer>
    );
    if (!renderCompoundMaterials) {
      return mainEntry;
    } else {
      const compoundListData = materialsBundles.successes[material.id];
      const compoundMaterials = compoundListData.map((bundled, index) =>
        this.renderMaterialTableItem(bundled, index + 1, material, [...compoundPath, material.id])
      );
      return (
        <React.Fragment key={index}>
          {mainEntry}
          {compoundMaterials}
        </React.Fragment>
      );
    }
  };

  getIsAllRowsOnPageSelected = () => {
    const { materials } = this.props;
    const { currentSelection } = this.state;

    if (materials && materials.length > 0 && currentSelection && currentSelection.length > 0) {
      const materialsIds = materials.map(el => el.id);
      return checkArraySetEquality(currentSelection, materialsIds);
    }
    return false;
  }

  renderTableView = () => {
    const { materials, t } = this.props;
    const { filters } = this.state;
    const { sortBy, sortByOrder } = filters;
    const isAllRowsOnPageSelected = this.getIsAllRowsOnPageSelected();
    const dataReady = this.isMaterialsDataReady();
    return (<>
      <GridContainer className="materials-header">
        <GridItem className={"materials-header-title mat-grid-col-category" + (sortBy === 'category.name' ? ' col-sort-active' : '') + (isAllRowsOnPageSelected ? ' mat-grid-col-category-selected-all' : '')}>
          <Checkbox
            checked={isAllRowsOnPageSelected}
            onChange={e => this.handleSelectionToggleAllNone() }
          />
          <span onClick={sortBy === 'category.name' ? this.handleSortByFlipOrder : null}>
            { t('category') } { sortBy === 'category.name' && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
          </span>
        </GridItem>
        <GridItem className={"materials-header-title mat-grid-col-sku" + (sortBy === 'code' ? ' col-sort-active' : '')}>
          <span onClick={sortBy === 'code' ? this.handleSortByFlipOrder : null}>
          { t('material_short_name') } { sortBy === 'code' && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
          </span>
        </GridItem>
        <GridItem className={"materials-header-title mat-grid-col-name" + (sortBy === 'name' ? ' col-sort-active' : '')}>
          <span onClick={sortBy === 'name' ? this.handleSortByFlipOrder : null}>
          { t('name') } { sortBy === 'name' && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
          </span>
        </GridItem>
        <GridItem className="materials-header-title mat-grid-col-brand">
          { t('brand') }
        </GridItem>
        <GridItem className="materials-header-title mat-grid-col-price">
          { t('cost') }
        </GridItem>
        <GridItem className="materials-header-title mat-grid-col-availability">
          { t('availability') }
        </GridItem>
        <GridItem className="materials-header-title mat-grid-col-supplier">
          { t('supplier') }
        </GridItem>
        <GridItem className="materials-header-title mat-grid-col-actions">
          { t('actions') }
        </GridItem>
      </GridContainer>
      <GridContainer className="materials-content">
        { dataReady && materials && materials.length > 0 &&
          materials.map((material, index) =>
            this.renderMaterialTableItem(material, index)
          )}
        { dataReady && materials && materials.length === 0 && (
          <div className="no_materials">{ t('no_materials_found') }</div>
        )}
        { !dataReady && (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
      </GridContainer>
    </>);
  };

  renderMaterialCardItem = (material, index) => {
    const { t } = this.props;
    const handleMaterialName = name => {
      const maxLength = 15;
      if (name.length > maxLength) {
        const abbr = name.substring(0, maxLength) + '...';
        return (
          <div className="material-item bold name" title={name}>
            {abbr}
          </div>
        );
      } else {
        return <div className="material-item bold name">{name}</div>;
      }
    };
    /* unused
    const sanitizeMaterialNum = materialNum => {
      if (materialNum && materialNum.length <= 6) {
        return materialNum;
      } else {
        const abbr = `${materialNum.substring(0, 2)}...${materialNum.substring(
          materialNum.length - 4
        )}`;
        return <span title={materialNum}>{abbr}</span>;
      }
    };*/
    const handleBrands = material => {
      if (
        material.material_meta &&
        material.material_meta.brands &&
        material.material_meta.brands.length > 0
      ) {
        return `${t('by')} ${material.material_meta.brands.join(', ')}`;
      } else {
        return '\u00A0';
      }
    };
    const handleSuppliers = material => {
      if (
        material.material_meta &&
        material.material_meta.suppliers &&
        material.material_meta.suppliers.length > 0
      ) {
        const result = [];
        for (
          let i = 0, len = Math.min(2, material.material_meta.suppliers.length);
          i < len;
          ++i
        ) {
          let supplier = material.material_meta.suppliers[i];
          if (supplier.length > 18) {
            result.push(
              <span title={supplier}>{supplier.substring(0, 15) + '...'}</span>
            );
          } else {
            result.push(<span>{supplier}</span>);
          }
        }
        return result;
      }
      return null;
    };
    return (
      <GridContainer className="material-item-card" key={index}>
        <div className="material-item-left">
          <div className="material-item category">
            { material.category ? material.category.name : "" }
          </div>
          {handleMaterialName(material.name)}
          <div className="material-item brand">{handleBrands(material)}</div>
          <div className="material-item supplier">
            {handleSuppliers(material)}
          </div>
          <div
            className={
              'card-availability-dropdown ' +
              (material.in_stock == 1 ? 'dropdown-green' : 'dropdown-red')
            }
          >
            <Dropdown
              buttonText={material.in_stock == 1 ? 0 : 1}
              buttonIconType="white"
              buttonProps={{
                className: 'dropdown-blue-style status-dropdown',
                round: true,
                size: 'sm'
              }}
              hoverColor="dark"
              dropdownList={Materials.AvailabilityOptions}
              onClick={data =>
                this.updateMaterialAvailability(
                  material.id,
                  data.id == 0 ? 1 : 0
                )
              }
            />
          </div>
        </div>
        <div className="material-item-right">
          <div className="material-item id">{material.code}</div>
          <div className="material-item price">
            {this.getMaterialRetailPrice(material)}
          </div>
          <div className="action-icons">
            {material.can_delete &&
            <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() => this.handleDeleteButton(material.id)}
            >
              <img alt="material-delete-btn" src={DeleteIcon} />
            </IconButton>
            }
            { this.state.filters.active && <IconButton
                aria-label="close"
                className="action-btn duplicate-action-btn"
                onClick={() => this.handleDuplicateButton(material.id)}
              >
                <img alt="material-delete-btn" src={DuplicateIcon} />
              </IconButton>
            }
            <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() =>
                material.material_type == 0
                  ? this.handleEditMaterial(material)
                  : this.handleEditCompoundMaterial(material)
              }
            >
              <img alt="material-edit-btn" src={EditIcon} />
            </IconButton>
            <IconButton
              aria-label="close"
              className="action-btn view-action-btn"
              onClick={() =>
                material.material_type == 0
                  ? this.handleViewMaterial(material)
                  : this.handleViewCompoundMaterial(material)
              }
            >
              <img alt="material-view-btn" src={ViewIcon} />
            </IconButton>
          </div>
        </div>
      </GridContainer>
    );
  };

  renderCardView = () => {
    const { materials, t } = this.props;
    if (!this.isMaterialsDataReady()) {
      return (
        <div className="ikt-ap_loader">
          <Loader />
        </div>
      );
    } else if (materials.length === 0) {
      return (
        <div className="no_materials">{ t('no_materials_found') }</div>
      );
    }
    return (
      <GridContainer className="material-card-view">
        {materials &&
          materials.map((material, index) =>
            this.renderMaterialCardItem(material, index)
          )}
      </GridContainer>
    );
  };

  render() {
    const {
      categories,
      filterCategories,
      materials,
      materialsBundles,
      metaData,
      materialsBusy,
      t
    } = this.props;
    const {
      isTableView,
      isCreateMaterialOpen,
      isCreateCompoundMaterialOpen,
      isViewMaterialOpen,
      isViewCompoundMaterialOpen,
      isRemoveMaterialOpen,
      isRestoreSelectedOpen,
      isRemoveSelectedOpen,
      isRestoreMaterialOpen,
      isImportMaterialCategoryOpen,
      isImportMaterialDuplicatesOpen,
      isUpdateMaterialCategoryOpen,
      isUpdateMaterialPriceOpen,
      isDuplicateMaterialOpen,
      deletedMaterialId,
      editCompoundMaterial,
      viewBundle,
      viewBundleMaterials,
      filters,
      currentPage,
      compoundStates,
      currentSelection,
      isUpdateByCategory
    } = this.state;
    const categoriesArray = [{ id: -1, value: t('all_categories'), is_select_all: true }];
    filterCategories.forEach(element =>
      categoriesArray.push({ id: element.id, value: element.name })
    );
    const availabilitiesArray = [
      { id: -1, value: t('all_availability') },
      { id: 1, value: t('in_stock') },
      { id: 0, value: t('out_of_stock') }
    ];
    const selectedAvailabilityId =
      filters.in_stock || filters.in_stock === 0 ? filters.in_stock : -1;
    const activitiesArray = [
      { id: 0, value: t('active') },
      { id: 1, value: t('deleted') },
    ];
    const selectedActivityId = filters.active ? 0 : 1;
    const sortArray = [
      { id: 0, field: 'code', value: t('sort_by_material_short_name') },
      { id: 1, field: 'name', value: t('sort_by_name') },
      { id: 2, field: 'category.name', value: t('sort_by_category') }
    ];
    const selectedSortById = filters.sortBy
      ? sortArray.find(el => el.field == filters.sortBy).id
      : 0;
    const loadCompoundMaterials = Object.keys(compoundStates);
    const loadCompoundMaterialsArg =
      loadCompoundMaterials.length > 0 ? loadCompoundMaterials : null;

    return (
      <div
        className="ikt-ap_materials-container create-material-basic"
      >
        <GridContainer>
          <GridItem
            className="materials-dropdown-content"
            xs={12}
          >
            <div className="materials-dropdown-content-inner">
              <CustomMultiselect
                buttonValue={filters.category}
                buttonProps={{
                  className: 'material-dropdown-style',
                  round: true,
                  size: 'sm'
                }}
                menuWidthCorrection={true}
                dropdownList={categoriesArray}
                hoverColor="dark"
                onChange={item =>
                  this.handleFilterChange('category', item)
                }
              />
              <Dropdown
                buttonText={selectedAvailabilityId}
                buttonProps={{
                  className: 'material-dropdown-style',
                  round: true,
                  size: 'sm'
                }}
                menuWidthCorrection={true}
                dropdownList={availabilitiesArray}
                translateValues={true}
                onClick={item => this.handleFilterChange('in_stock', item.id)}
              />
              <Dropdown
                buttonText={selectedActivityId}
                buttonProps={{
                  className: 'material-dropdown-style',
                  round: true,
                  size: 'sm'
                }}
                menuWidthCorrection={true}
                dropdownList={activitiesArray}
                translateValues={true}
                onClick={item => this.handleFilterChange('active', item.id == 0)}
              />
              <Dropdown
                buttonText={selectedSortById}
                buttonProps={{
                  className: 'material-dropdown-style',
                  round: true,
                  size: 'sm'
                }}
                menuWidthCorrection={true}
                dropdownList={sortArray}
                translateValues={true}
                onClick={item => this.handleSortByChange(item.field)}
              />
            </div>
            <div className="materials-dropdown-content-inner">
              <Button
                className="create-material-btn import-btn"
                component="label"
              >
                { t('import').toUpperCase() }
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={this.handleFileChange}
                />
              </Button>
              <ViewButton
                isTable={isTableView}
                onViewChange={this.handleViewChange}
              />
              { currentSelection && materials && currentSelection.length > 0 && (
                <DropdownButton
                  className="select-material-btn-dropdown"
                  buttonText={t('select')}
                >
                  { filters.active && (
                    <Button
                      onClick={this.handleUpdateSelectedMaterialsButton}
                      className="create-material-btn select-dropdown-button-child"
                    >
                      { t('update_selected') }
                    </Button>
                  ) }
                  { filters.active && (
                    <Button
                      onClick={this.handleUpdateSelectedMaterialsPriceButton}
                      className="create-material-btn select-dropdown-button-child"
                    >
                      { t('update_price') }
                    </Button>
                  ) }
                  { !filters.active && (
                    <Button
                      onClick={this.handleRestoreSelectedMaterialsButton}
                      className="create-material-btn select-dropdown-button-child"
                    >
                      { t('restore_selected') }
                    </Button>
                  )
                  }
                  <Button
                    onClick={this.handleDeleteSelectedMaterialsButton}
                    className="delete-material-btn select-dropdown-button-child"
                  >
                    { t('delete_selected') }
                  </Button>
                </DropdownButton>
              )}
              <Button
                onClick={this.handleUpdateCategoryButton}
                className="create-material-btn update-category-button">
                { t('update_category') }
              </Button>
              <DropdownButton
                className="create-material-btn-dropdown"
                buttonText={`+ ${t('create').toUpperCase()}`}
              >
                <Button
                  onClick={this.handleToggleMaterialChange}
                  className="create-material-btn create-dropdown-button-child"
                >
                  { t('material').toUpperCase() }
                </Button>
                <Button
                  onClick={this.handleToggleCompoundMaterialChange}
                  className="create-material-btn create-dropdown-button-child"
                >
                  { t('compound_material').toUpperCase() }
                </Button>
              </DropdownButton>
            </div>
          </GridItem>
          {
            isTableView
              ? this.renderTableView()
              : this.renderCardView()
          }
        </GridContainer>
        <GridContainer className="pagination-container">
              {metaData.last_page > 1 && (
                <CustomPagination
                  pageCount={metaData.last_page}
                  forcePage={this.state.currentPage - 1}
                  handlePageClick={this.handlePageClick}
                />
              )}
        </GridContainer>
        {isCreateMaterialOpen &&
          <CreateMaterial
            isOpen={isCreateMaterialOpen}
            onClose={this.handleMaterialClose}
            currentPage={currentPage}
            currentFilters={this.state.filters}
            currentSearchTerms={this.props.searchTerms}
            currentCompoundMaterials={loadCompoundMaterialsArg}
            edit={this.state.editMaterial}
            categories={this.props.categories}
          />
        }
        {isCreateCompoundMaterialOpen &&
          <CreateCompoundMaterial
            isOpen={isCreateCompoundMaterialOpen}
            onClose={this.handleCompoundMaterialClose}
            currentPage={currentPage}
            currentFilters={this.state.filters}
            currentSearchTerms={this.props.searchTerms}
            currentCompoundMaterials={loadCompoundMaterialsArg}
            categories={this.props.categories}
            materials={materials}
            editMaterial={editCompoundMaterial}
          />
        }
        {isViewMaterialOpen &&
          <ViewMaterial
            isOpen={isViewMaterialOpen}
            onClose={this.handleToggleViewMaterial}
            onDelete={this.handleViewMaterialDelete}
            onDuplicate={this.handleDuplicateButton}
            onEdit={this.handleViewMaterialEdit}
            currentPage={currentPage}
            currentFilters={this.state.filters}
            currentSearchTerms={this.props.searchTerms}
            currentCompoundMaterials={loadCompoundMaterialsArg}
            purge={!this.state.filters.active}
            material={this.state.viewMaterial}
            availabilityOptions={Materials.AvailabilityOptions}
          />
        }
        {isViewCompoundMaterialOpen && (
          <ViewCompoundMaterial
            isOpen={isViewCompoundMaterialOpen}
            onClose={this.handleToggleViewCompoundMaterial}
            onDuplicate={this.handleDuplicateButton}
            onEdit={this.handleViewCompoundMaterialEdit}
            bundleMaterial={viewBundle}
            materials={materials}
            currentPage={currentPage}
            currentFilters={this.state.filters}
            currentSearchTerms={this.props.searchTerms}
            currentCompoundMaterials={loadCompoundMaterialsArg}
            purge={!this.state.filters.active}
            categories={this.props.categories}
          />
        )}
        {isRemoveMaterialOpen && (
          <RemoveDialog
            isOpen={isRemoveMaterialOpen}
            title={this.state.removeDialogTitle}
            text={this.state.removeDialogText}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleRemoveMaterial}
          />
        )}
        {isRestoreSelectedOpen && (
          <WarningDialog
            isOpen={isRestoreSelectedOpen}
            title={t('restore_selected_materials')}
            text={t('restore_selected_materials_confirmation')}
            buttonText={t('restore')}
            onClose={this.handleRestoreSelectedDialogClose}
            onCancel={this.handleRestoreSelectedDialogClose}
            onConfirm={this.handleRestoreSelectedMaterials}
          />
        )}
        {isRemoveSelectedOpen && (
          <RemoveDialog
            isOpen={isRemoveSelectedOpen}
            title={t('remove_selected_materials')}
            text={t('remove_selected_materials_confirmation')}
            onClose={this.handleRemoveSelectedDialogClose}
            onRemove={this.handleDeleteSelectedMaterials}
          />
        )}
        {isRestoreMaterialOpen && (
          <WarningDialog
            isOpen={isRestoreMaterialOpen}
            title={t('restore_material')}
            text={t('restore_material_confirmation')}
            buttonText={t('restore')}
            onClose={this.handleRestoreDialogClose}
            onCancel={this.handleRestoreDialogClose}
            onConfirm={this.handleRestoreMaterial}
          />
        )}
        {isImportMaterialCategoryOpen && (
          <MaterialCategoriesDialog
            isOpen={isImportMaterialCategoryOpen}
            categories={categories}
            onClose={this.handleCloseMaterialCategoriesDialog}
            onSubmit={this.handleImportMaterial}
          />
        )}
        {isImportMaterialDuplicatesOpen && (
          <MaterialImportSkippedDialog
            isOpen={isImportMaterialDuplicatesOpen}
            onClose={this.handleCloseImportMaterialDuplicatesDialog}
            onDownload={this.handleImportMaterialDuplicatesDownload}
          />
        )}
        {isUpdateMaterialCategoryOpen &&
          <UpdateMaterialCategoryDialog
            isOpen={isUpdateMaterialCategoryOpen}
            onClose={this.handleCloseUpdateMaterialCategoryDialog}
            currentPage={currentPage}
            currentFilters={this.state.filters}
            currentSearchTerms={this.props.searchTerms}
            currentCompoundMaterials={loadCompoundMaterialsArg}
            categories={this.props.categories}
            selectedMaterials={currentSelection}
            byCategory={isUpdateByCategory}
          />
        }
        {isUpdateMaterialPriceOpen &&
          <UpdateMaterialPriceDialog
            isOpen={isUpdateMaterialPriceOpen}
            onClose={this.handleCloseUpdateMaterialPriceDialog}
            currentPage={currentPage}
            currentFilters={this.state.filters}
            currentSearchTerms={this.props.searchTerms}
            currentCompoundMaterials={loadCompoundMaterialsArg}
            selectedMaterials={currentSelection}
          />
        }
        {isDuplicateMaterialOpen && (
          <ConfirmDialog
            isOpen={isDuplicateMaterialOpen}
            title={t('duplicate_material')}
            text={t('duplicate_material_confirmation')}
            onClose={this.handleDuplicateDialogClose}
            onConfirm={this.handleDuplicateMaterial}
          />
        )}
      </div>
    );
  }
}

Materials.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    materials: state.materials.materials,
    materialsBundles: state.materials.materialsBundles,
    materialsBusy: state.materials.materialsBusy,
    materialsMutBusy: state.materials.materialsMutBusy,
    currencySymbol: state.globals.currencySymbol,
    isMaterialValid: state.materials.isMaterialValid,
    isRemoveMaterialValid: state.materials.isRemoveMaterialValid,
    isMultipleMaterialsUpdateValid : state.materials.isMultipleMaterialsUpdateValid,
    materialImportedFileData: state.materials.materialImportedFileData,
    materialImportedFileSkippedBlob: state.materials.materialImportedFileSkippedBlob,
    materialImportedFileSkippedFilename: state.materials.materialImportedFileSkippedFilename,
    metaData: state.materials.metaData,
    categories: state.categories.materialsCategories,
    filterCategories: state.categories.filterMaterialsCategories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMaterials: (page, filters, searchTerms) =>
      dispatch(getMaterials(page, filters, searchTerms)),
    getMaterialsInBundles: (ids, keepCurrent) =>
      dispatch(getMaterialsInBundles(ids, keepCurrent)),
    updateCompoundMaterial: (page, data) =>
      dispatch(updateCompoundMaterial(page, data)),
    duplicateMaterial: (page, data) =>
      dispatch(duplicateMaterial(page, data)),
    getCategories: () =>
      dispatch(getMaterialsCategories()),
    getCategoriesFilterList: () =>
      dispatch(getMaterialsCategoriesProvider()),
    setMaterialAvailability: (loadListInfo, data) =>
      dispatch(setMaterialAvailability(loadListInfo, data)),
    startImport: (data) =>
      dispatch(startMaterialFileImport(data)),
    importDownloadSkippedMaterials: (data) =>
      dispatch(downloadMaterialFileImportSkipped(data)),
    removeMaterial: (data, purge, loadListInfo) =>
      dispatch(removeMaterial(data, purge, loadListInfo)),
    restoreMaterials: (data, loadListInfo) =>
      dispatch(restoreMaterials(data, loadListInfo)),
    removeMaterials: (data, purge, loadListInfo) =>
      dispatch(removeMaterials(data, purge, loadListInfo)),
    restoreMaterial: (id, loadListInfo) =>
      dispatch(restoreMaterial(id, loadListInfo)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(Materials)));
