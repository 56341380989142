import React from 'react';
import { withTranslation } from 'react-i18next';
import GridItem from 'components/Grid/GridItem';

import './style.scss';
import ViewContact from "../ViewContact";
import EditContact from "../CreateContact";
import {getCustomer } from "../../store/actions/customers";
import {connect} from "react-redux";
import CreateContact from "../CreateContact";

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewContactOpen: false,
      isEditContactOpen: false
    };
  }

  handleShowViewContact = (id) => {
    this.props.getCustomer(id);
    this.setState({
      isViewContactOpen: true,
    })
  };

  handleCloseViewContact = () => {
    this.setState({
      isViewContactOpen: false,
    })
  }

  handleShowEditContact = (id) => {
    if (id) {
      this.props.getCustomer(id);
    }
    this.setState({
      isEditContactOpen: true,
      isViewContactOpen: false,
    })
  }

  handleCloseEditContact = () => {
    this.setState({
      isEditContactOpen: false,
    })
  };

  render() {
    const { data, cities, customer, t } = this.props;
    const {isViewContactOpen, isEditContactOpen} = this.state;
    //const city = cities.length !== 0 && data.city_id ? cities.find(item => item.id === data.city_id.toString()).value : ''; // data.city_id does not exist
    return (
      <div className="customer-contact-details">
        <GridItem className="customer-contact-details-title" xs={12}>
          <span>{ t('customer_contact_details') }</span>
          <div onClick={() => this.handleShowViewContact(data.id)}>
            <span className="customer-details-link">{ t('details') }</span>
          </div>
        </GridItem>
        <GridItem className="customer-contact-details-content" xs={12}>
          <GridItem className="customer-details-info-title" xs={4}>
            <span>{ t('name') }:</span>
            <span>{ t('surname') }:</span>
            <span>{ t('address') }:</span>
            <span>{ t('postal_code') }:</span>
            <span>{ t('city') }:</span>
            <span>{ t('country') }:</span>
            <span>{ t('phone') }:</span>
            <span>{ t('email') }:</span>
            <span>{ t('website') }:</span>
          </GridItem>
          <GridItem className="customer-details-info-data" xs={8}>
            <span>{data.first_name ? data.first_name : '\u00A0'}</span>
            <span className="customer-details-info-data-item">{data.last_name ? data.last_name : '\u00A0'}</span>
            <span className="customer-details-info-data-item">{data.address ? data.address : '\u00A0'}</span>
            <span>{data.postal_code ? data.postal_code : '\u00A0'}</span>
            <span className="customer-details-info-data-item">{data.city ? data.city : '\u00A0'}</span>
            <span className="customer-details-info-data-item">{data.country ? data.country : '\u00A0'}</span>
            <span className="customer-details-info-data-item">{data.phone_mobile ? data.phone_mobile : '\u00A0'}</span>
            <span className="customer-details-info-data-item">{data.email ? data.email : '\u00A0'}</span>
            <span className="customer-details-info-data-item">{data.website ? data.website : '\u00A0'}</span>
          </GridItem>
        </GridItem>
        {
          isEditContactOpen && Object.keys(customer).length !== 0 &&
          <CreateContact
            isOpen={isEditContactOpen}
            isEditContact={true}
            onClose={this.handleCloseEditContact}
            editCustomer={customer}
          />
        }
        {
          isViewContactOpen && Object.keys(customer).length !== 0 &&
          <ViewContact
            isOpen={isViewContactOpen}
            onClose={this.handleCloseViewContact}
            onEditContact={this.handleShowEditContact}
            id={data.id}
          />
        }
      </div>
    )
  }
}

const mapStateToProp = state => {
  return {
    customer: state.customers.customer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomer: (id) => dispatch(getCustomer(id)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CustomerInfo));
