import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { resetUserData, addUser, updateUser } from 'store/actions/usersData';

import Dialog from '@material-ui/core/Dialog';
import GridContainer from 'components/Grid/GridContainer';
import Checkbox from "../../components/StyledCheckbox";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import Autocomplete from 'components/CustomAutocomplete/index';
import { ErrorTooltip, ErrorTooltipAlt } from '../../components/ErrorTooltip/ErrorTooltip';
import ConfirmDialog from '../ConfirmDialog';

import InputAdornment from '@material-ui/core/InputAdornment';
import UserIcon from '../../assets/img/inputIcons/user-icon.svg';
import InputWithIcon from '../../components/InputWithIcon';
import TextField from '@material-ui/core/TextField';
import Button from '../../components/CustomButtons/Button';
import Input from '../../components/Input/Input';
import GridItem from '../../components/Grid/GridItem';
import { materialsUnits } from '../../constants/constants';
import CloseButton from '../../assets/img/buttons/delete.svg';
import Icon from '@material-ui/core/Icon';
import { materialsAPI } from '../../services/API';
import './style.scss';
import TextArea from '../../components/CustomTextArea';
import { convertToFormData } from '../../utils/utils';

import CloseDialog from '../CloseDialog';

import {
  addCompoundMaterial,
  getMaterialsInBundles, resetMaterialData,
  updateCompoundMaterialBase
} from '../../store/actions/materials';
import {getAllMaterials} from "../../store/actions/materialProviders";

class CreateCompundMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      code: null,
      category_id: null,
      material_type: 1,
      description: null,
      selectedMaterial: null,
      selectedMaterials: [],
      over_spray: 0,
      total: 0,
      unit: null,
      in_stock: 1,
      dropdownMaterials: [],

      showAllUnits: false,
      isCloseDialogOpen: false,
      haveEdits: false,
      confirmDialogOpen: false,
      selectedCategory: '',
    };
  }

  componentDidMount() {
    const {getMaterials} = this.props;
    if (this.props.editMaterial) {
      const { editMaterial } = this.props;
      this.setState({
        ...this.state,
        name: editMaterial.name,
        code: editMaterial.code,
        //category_id: editMaterial.category.id,
        material_type: 1,
        description: editMaterial.description,
        over_spray: editMaterial.over_spray,
        unit: editMaterial.metric,
        in_stock: editMaterial.in_stock
      });
      this.props.getMaterialsInBundles([this.props.editMaterial.id], true);
    }
    getMaterials();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editMaterial !== this.props.editMaterial) {
      //get bundle materials
      this.props.getMaterialsInBundles([this.props.editMaterial.id], true);
      const { editMaterial } = this.props;
      this.setState({
        ...this.state,
        name: editMaterial.name,
        code: editMaterial.code,
        material_type: 1,
        description: editMaterial.description,
        over_spray: editMaterial.over_spray,
        unit: editMaterial.metric,
        in_stock: editMaterial.in_stock
      });
    }
    if (
      prevProps.materialsBundles.successes !==
      this.props.materialsBundles.successes
    ) {
      if (this.props.editMaterial) {
        const bundleMats = this.props.materialsBundles.successes[
          this.props.editMaterial.id
        ];
        if(bundleMats) {
          let total = 0;
          const bundleMaterials = bundleMats.map(mat => {
            total += mat.usage;
            return { ...mat, amount: mat.usage };
          });
          const newSelectedMats =
            bundleMaterials && bundleMaterials.length !== 0
              ? [...bundleMaterials]
              : this.state.selectedMaterials;
          this.setState({
            selectedMaterials: newSelectedMats,
            total: total,
          });
        }
      }
    }
    if (!prevProps.isMaterialValid && this.props.isMaterialValid ) {
      this.handleCloseBtn();
    }
    // update dropdownMaterialsList
    if (prevProps.materials !== this.props.materials) {
      this.setState({
        dropdownMaterials: this.props.materials.map(el => ({
          ...el,
          value: el.name,
        })),
      });
    }
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  init = () => {
    const { editMaterial } = this.props;
    this.setState({
      ...editMaterial
    });
  };

  handleInputValueChange = (name, value) => {
    const { materials } = this.props;
    const mat = materials.find(material => material.id === value.id);
    const val = name === 'selectedMaterial' ? mat : value;
    let errorsName = name;
    if (name == "over_spray") {
      errorsName = "materials_meta.over_spray";
    }
    this.setState({
      [name]: val,
      haveEdits: true,
      errors: {
        ...this.state.errors,
        [errorsName]: false
      }
    });
  };

  resetStateData = () => {
    this.setState({
      name: null,
      code: null,
      material_type: 1,
      description: null,
      selectedMaterial: null,
      selectedMaterials: [],
      over_spray: 0,
      total: 0,
      unit: null,
      in_stock: 1,

      isCloseDialogOpen: false,
      haveEdits: false,
    });
  };

  handleCloseBtn = () => {
    const { onClose } = this.props;
    onClose();
    this.resetStateData();
  };

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleCloseBtn();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleCloseBtn();
    });
  }

  addMaterial = () => {
    const { selectedMaterial, selectedMaterials, total } = this.state;
    if (selectedMaterial && selectedMaterial.id && !Boolean(selectedMaterials.find(el => el.id === selectedMaterial.id))) {
        this.setState({
          selectedMaterials: [
            ...selectedMaterials,
            { id: selectedMaterial.id, name: selectedMaterial.name, code: selectedMaterial.code, metric: selectedMaterial.metric, description: selectedMaterial.description, amount: '' }
          ],
        });
    }
  };

  removeMaterial = (selectedMaterial,index) => {
    const { selectedMaterials, total } = this.state;
    const newTotal = +total - selectedMaterial.amount;
    const newSelectedMaterials = [...selectedMaterials];
    newSelectedMaterials.splice(index,1);
    this.setState({
      selectedMaterials: newSelectedMaterials,
      total: Math.round((newTotal + Number.EPSILON) *100)/100
    });
  };

  onMaterialAmountChange = (selectedMaterial, index, value) => {
    if(value >= 0) {
    const { selectedMaterials, total } = this.state;
    const selectedMaterialsNew = [...selectedMaterials];
    const newTotal = +total - +selectedMaterial.amount + +value;
    selectedMaterialsNew[index].amount = value;
    this.setState({
      selectedMaterials: selectedMaterialsNew,
      total: Math.round((newTotal + Number.EPSILON) *100)/100
    });
  }
  };

  handleCreate = () => {
    const {
      currentPage,
      currentFilters,
      currentSearchTerms,
      currentCompoundMaterials,
      materialsMutBusy,
    } = this.props;
    if (materialsMutBusy)
      return;
    const {
      name,
      code,
      description,
      selectedMaterials,
      over_spray,
      unit
    } = this.state;
    let materialsList = [];
    selectedMaterials.forEach((material, i) => {
      const obj = {
        id: material.id,
        value: material.amount
      };
      materialsList.push(obj);
    });
    const data = {
      name,
      code,
      description,
      metric: unit,
      materials: [...materialsList],
      material_meta: {
        in_stock: 1,
        over_spray: over_spray
      }
    };
    if (this.props.editMaterial) {
      this.props.updateCompoundMaterialBase(this.props.editMaterial.id, data, { page: currentPage, filters: currentFilters, searchTerms: currentSearchTerms, loadCompoundMaterials: currentCompoundMaterials });
    } else {
      this.props.addCompoundMaterial(data, { page: currentPage, filters: currentFilters, searchTerms: currentSearchTerms, loadCompoundMaterials: currentCompoundMaterials });
    }
  };
  
  parseErrors = errors => {
    const errorsString = errors;
    const needle = 'Response body: ';
    const position = errorsString.indexOf(needle);
    if (position != -1) {
      const substring = errorsString.substring(position + needle.length);
      try {
        const errorsObj = JSON.parse(substring);
        return errorsObj.errors;
      } catch (e) {}
    }
    return null;
  };

  render() {
    const {
      isOpen,
      onClose,
      getMaterials,
      materialErrors,
      metrics,
      editMaterial,
      t
    } = this.props;
    const {
      name,
      code,
      dropdownMaterials,
      over_spray,
      selectedMaterials,
      unit,
      total,
      description,
      confirmDialogOpen,
      showAllUnits
    } = this.state;
    const unitOptions = Object.keys(metrics).map(el => ({ id: el, value: metrics[el] }));
    const errors = materialErrors ? this.parseErrors(materialErrors) : false;
    const sanitizeTooltipErrorTitle = error => (error ? error[0] : "");
    const dropdownMaterialsActual = dropdownMaterials
      .filter(el => !Boolean(selectedMaterials.find(el2 => el2.id === el.id)) && (!editMaterial || el.id !== editMaterial.id))
      .map(el => ({
        ...el,
        value: el.code,
      }));
    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'create-compound-material-dialog',
          }
        }}>
        <MuiDialogTitle className="create-material-title" disableTypography>
          <Typography variant="h6" className="dialog-title">
            { this.props.editMaterial ? t('edit_compound_material') : t('create_compound_material') }
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={this.handleOnClosePrompt}
            >
              <img alt="create-user-close-btn" src={CloseButton} />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="create-material-content">
          <div className="user-info-item">
            <GridItem xs={4} className="material-info-header">
              <span className="item-title">{ t('material_name') }</span>
              <Input
                autofocus={true}
                error={errors && errors.name}
                errorMsg={errors && errors.name}
                value={name}
                onChange={e =>
                  this.handleInputValueChange('name', e.target.value)
                }
              />
            </GridItem>
            <GridItem xs={4} className="material-info-header">
              <span className="item-title">{ t('material_short_name') }</span>
              <Input
                error={errors && errors.code}
                errorMsg={errors && errors.code}
                value={code}
                onChange={e =>
                  this.handleInputValueChange('code', e.target.value)
                }
              />
            </GridItem>
            <GridItem xs={4} className="material-info-header">
              <span className="item-title">{ t('metrics') }</span>
              <CustomDropdown
                buttonText={unit}
                dropdownList={unitOptions}
                buttonProps={{
                  className: 'dropdown-blue-style material-dropdown',
                  color: 'transparent',
                  round: true,
                  size: 'sm'
                }}
                hoverColor="dark"
                onClick={item => this.handleInputValueChange('unit', item.id)}
                error={errors && errors.unit}
                errorMsg={errors && errors.unit}
              />
            </GridItem>
          </div>
          <div className="user-info-item">
            <GridItem className="user-info description" xs={12}>
              <span className="item-title">{ t('description') }</span>
              <TextArea
                value={description}
                onChange={e =>
                  this.handleInputValueChange('description', e.target.value)
                }
                error={errors && errors.description}
                errorMsg={errors && errors.description}
              />
            </GridItem>
          </div>
          <div className="user-info-item">
            <GridContainer className="materials-list-header">
              <Autocomplete
                buttonText={''}
                buttonProps={{className: '', round: true, size: "sm"}}
                dropdownList={dropdownMaterialsActual}
                error={errors && errors.materials}
                hoverColor="dark"
                getData={value => getMaterials(value, !showAllUnits ? unit : null)}
                onClick={value => {
                  this.handleInputValueChange('selectedMaterial', value);
                }}
                placeholder={t('enter')}
                discardIcon={true}
              />
              <Button className="add-material-btn" onClick={this.addMaterial}>
                { t('add_material') }
              </Button>
              <FormControl
                className="form-control-show-all-units"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.showAllUnits}
                      onChange={e => this.handleInputValueChange('showAllUnits', e.target.checked) }
                      color="primary"
                    />
                  }
                  label={t('show_all_units')}
                  className={"units-checkbox " + (this.state.showAllUnits ? "units-checkbox-checked" : "")}
                />
              </FormControl>
              <GridContainer className="material-info-header">
                <GridItem className="header-item" xs={3}>
                  <span className="item-title">{ t('components') }</span>
                </GridItem>
                <GridItem className="header-item" xs={4}>
                  <span className="item-title">{ t('description') }</span>
                </GridItem>
                <GridItem className="header-item" xs={2}>
                  <span className="item-title">{ t('metric') }</span>
                </GridItem>
                <GridItem className="header-item" xs={2}>
                  <span className="item-title">{ t('amount') }</span>
                </GridItem>
              </GridContainer>
              <div className="material-component-list">
                {selectedMaterials &&
                  selectedMaterials.map( (material, index) => {
                    return (
                      <GridContainer className="sub-cost-container">
                        <GridItem className="material-component-item" xs={3}>
                          <InputWithIcon
                            className="material-input-styled"
                            disabled={true}
                            value={` ${material.name} `}
                            startAdornment={
                              <InputAdornment position="start">
                                <img
                                  className="material-input-icon"
                                  alt="customer-autocomplete-img"
                                  src={material.image || UserIcon}
                                />
                              </InputAdornment>
                            }
                          />
                        </GridItem>
                        <GridItem className="material-component-item" xs={4}>
                          <Input
                            value={material.description}
                            placeholder={''}
                            disabled={true}
                          />
                        </GridItem>
                        <GridItem className="material-component-item" xs={2}>
                          <Input
                            value={metrics[material.metric]}
                            placeholder={''}
                            disabled={true}
                          />
                        </GridItem>
                        <GridItem className="material-component-item" xs={2}>
                        <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(errors[`materials.${index}.value`])}>
                          <Input
                            value={material.amount}
                            type={"number"}
                            error={errors && errors[`materials.${index}.value`]}
                            onChange={e =>
                              this.onMaterialAmountChange(material,index, e.target.value)
                            }
                          />
                          </ErrorTooltipAlt>
                        </GridItem>
                        <GridItem className="material-component-item" xs={1}>
                          <IconButton
                            aria-label="close"
                            className="material-component-remove-btn"
                            onClick={() => this.removeMaterial(material, index)}
                          >
                            <img
                              alt="material-component-remove-btn"
                              src={CloseButton}
                            />
                          </IconButton>
                        </GridItem>
                      </GridContainer>
                    );
                  })}
              </div>
            </GridContainer>
          </div>
          <div className="compound-footer-container">
            <div className="footer-info">
              <div className="footer-info-item">
                <GridItem className="footer-info-title">
                  { t('total') } ({ unit !== null && unit !== undefined && metrics[unit] ? metrics[unit] : 'm2' })
                </GridItem>
                <GridItem className="total"> {total || 0}</GridItem>
              </div>
              <div className="footer-info-item">
                <GridItem className="footer-info-title">
                  { t('overspray') }
                </GridItem>
                <div title={errors && errors["material_meta.over_spray"] ? errors["material_meta.over_spray"][0] : ''}> {/* space will always be too short to contain this full error text */}
                  <Input
                    error={errors && errors["material_meta.over_spray"]}
                    errorMsg={errors && errors["material_meta.over_spray"]}
                    value={over_spray}
                    onChange={e =>
                      this.handleInputValueChange('over_spray', e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user-btn-container">
          <Button className="cancel-btn" onClick={this.handleCloseBtn}>
            { t('cancel').toUpperCase() }
          </Button>
          <Button className="create-btn" onClick={this.handleCreate}>
          { this.props.editMaterial ? t('edit_compound_material_button').toUpperCase() : t('create_material').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('close_compound_material')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
        {confirmDialogOpen &&
          <ConfirmDialog
            title={t('confirm_change_category')}
            text={t('change_category_confirmation')}
            onClose={this.onConfirmDialogClose}
            onConfirm={this.handleCategoryUpdate}
            isOpen={confirmDialogOpen}
          />
        }
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    materials: state.materialProviders.allMaterials,
    materialsBundles: state.materials.materialsBundles,
    materialErrors: state.materials.compoundMaterialError,
    isMaterialValid: state.materials.isMaterialValid,
    materialsMutBusy: state.materials.materialsMutBusy,
    metrics: state.globals.metrics
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMaterials: (term, metric) => dispatch(getAllMaterials(term, metric)),
    updateCompoundMaterialBase: (id, data, loadListInfo) =>
      dispatch(updateCompoundMaterialBase(id, data, loadListInfo)),
    addCompoundMaterial: (data, loadListInfo) => dispatch(addCompoundMaterial(data, loadListInfo)),
    getMaterialsInBundles: (ids, keepCurrent) =>
      dispatch(getMaterialsInBundles(ids, keepCurrent)),
    resetData: () => dispatch(resetMaterialData())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateCompundMaterial));
