import { combineReducers } from 'redux';

import { auth } from 'store/reducers/auth';
import { categories } from './categories';
import { coworkers } from './coworkers';
import { customers } from './customers';
import { globals } from 'store/reducers/globals';
import { providers } from './providers';
import { orders } from './orders';
import { projectStatuses } from 'store/reducers/projectStatuses';
import { projectCategories } from 'store/reducers/projectCategories';
import { projectManufacturers } from 'store/reducers/projectManufacturers';
import { projects } from 'store/reducers/projects';
import { materials } from 'store/reducers/materials';
import { materialProviders } from 'store/reducers/materialProviders';
import { materialCategories } from 'store/reducers/materialCategory';
import { manufacturer } from 'store/reducers/manufacturer';
import { manufacturerProviders} from 'store/reducers/manufacturerProviders';
import { locations } from 'store/reducers/locations';
import { timeSheets } from 'store/reducers/timeSheets';
import { invoices } from 'store/reducers/invoices';
import { invoicesProviders } from 'store/reducers/invoicesProviders';
import { timeSheetsProviders } from 'store/reducers/timeSheetsProviders';
import { settings } from 'store/reducers/settings';
import { users } from 'store/reducers/usersData';
import { roles } from 'store/reducers/roles';
import { requests } from 'store/reducers/requests';
import { routes } from 'store/reducers/routes';
import { logs } from 'store/reducers/logs';
import { progress } from 'store/reducers/progress';
import { timers } from 'store/reducers/timers';

export default combineReducers({
  auth,
  categories,
  coworkers,
  customers,
  providers,
  orders,
  globals,
  projectStatuses,
  projectCategories,
  projectManufacturers,
  projects,
  materials,
  materialProviders,
  materialCategories,
  manufacturer,
  manufacturerProviders,
  locations,
  timeSheets,
  timeSheetsProviders,
  invoices,
  invoicesProviders,
  settings,
  users,
  roles,
  routes,
  requests,
  logs,
  progress,
  timers,
});
