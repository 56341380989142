import dot from "dot-object";

import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_CLIENT_INQUIRIES,
  GET_CLIENT_INQUIRIES_SUCCESS,
  GET_CLIENT_INQUIRIES_ERROR,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  GET_ORDER_SETTINGS,
  GET_ORDER_SETTINGS_SUCCESS,
  GET_ORDER_SETTINGS_ERROR,
  UPDATE_ORDER_SETTINGS,
  UPDATE_ORDER_SETTINGS_SUCCESS,
  UPDATE_ORDER_SETTINGS_ERROR,
  GET_ORDER_TOTAL_COST,
  GET_ORDER_TOTAL_COST_SUCCESS,
  GET_ORDER_TOTAL_COST_ERROR,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  DUPLICATE_ORDER,
  DUPLICATE_ORDER_SUCCESS,
  DUPLICATE_ORDER_ERROR,
  REMOVE_ORDER,
  REMOVE_ORDER_SUCCESS,
  REMOVE_ORDER_ERROR,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  VALIDATE_ORDER_DETAILS,
  VALIDATE_ORDER_DETAILS_SUCCESS,
  VALIDATE_ORDER_DETAILS_FAILURE,
  RESET_ORDER_DETAILS_DATA,
  VALIDATE_ORDER_POSITIONS,
  VALIDATE_ORDER_POSITIONS_SUCCESS,
  VALIDATE_ORDER_POSITIONS_FAILURE,
  RESET_ORDER_POSITIONS_DATA,
  VALIDATE_POSITIONS_TASK,
  VALIDATE_POSITIONS_TASK_SUCCESS,
  VALIDATE_POSITIONS_TASK_FAILURE,
  RESET_POSITIONS_TASK_ERRORS,
  VALIDATE_IMPORTED_POSITION_FILE,
  VALIDATE_IMPORTED_POSITION_FILE_SUCCESS,
  VALIDATE_IMPORTED_POSITION_FILE_FAILURE,
  START_POSITION_FILE_IMPORT,
  START_POSITION_FILE_IMPORT_SUCCESS,
  START_POSITION_FILE_IMPORT_FAILURE,
  START_TASK_FILE_IMPORT,
  START_TASK_FILE_IMPORT_SUCCESS,
  START_TASK_FILE_IMPORT_FAILURE,
  VALIDATE_ORDER_FITTINGS,
  VALIDATE_ORDER_FITTINGS_SUCCESS,
  VALIDATE_ORDER_FITTINGS_FAILURE,
  RESET_FITTINGS_DATA,
  VALIDATE_ORDER_COST_CENTER,
  VALIDATE_ORDER_COST_CENTER_SUCCESS,
  VALIDATE_ORDER_COST_CENTER_FAILURE,
  GET_ORDER_POSITION_DEFAULT_COST_CENTER,
  GET_ORDER_POSITION_DEFAULT_COST_CENTER_SUCCESS,
  GET_ORDER_POSITION_DEFAULT_COST_CENTER_FAILURE,
  RESET_COST_CENTER_DATA,
  VALIDATE_ORDER_COST_CENTER_ITEM,
  VALIDATE_ORDER_COST_CENTER_ITEM_SUCCESS,
  VALIDATE_ORDER_COST_CENTER_ITEM_FAILURE,
  ORDER_POSITION_CALCULATION,
  ORDER_POSITION_CALCULATION_SUCCESS,
  ORDER_POSITION_CALCULATION_FAILURE,
  ORDER_COST_CENTER_CALCULATION,
  ORDER_COST_CENTER_CALCULATION_SUCCESS,
  ORDER_COST_CENTER_CALCULATION_FAILURE,
  SET_ORDER_STARRED,
  SET_ORDER_STATUS,
  SET_ORDER_STATUS_SUCCESS,
  SET_ORDER_STATUS_FAILURE,
  SET_ORDER_DEADLINE,
  FILTER_ORDERS,
  RESET_ORDER_DATA,
  RESET_FILE_IMPORT_DATA,
  RESET_DUPLICATE_DATA
} from '../actionTypes/ordersActionTypes';

import NotificationService from '../../services/NotificationService';

export const getOrders = (isOrder, searchTerms) => {
  return { type: GET_ORDERS, payload: {isOrder, searchTerms} };
};

export const getOrdersSuccess = orders => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: orders
  };
};

export const getOrdersFailure = error => {
  return { type: GET_ORDERS_ERROR, payload: error };
};

export const getClientInquiries = (data) => {
  return { type: GET_CLIENT_INQUIRIES, payload: data };
};

export const getClientInquiriesSuccess = inquiries => {
  return {
    type: GET_CLIENT_INQUIRIES_SUCCESS,
    payload: inquiries
  };
};

export const getClientInquiriesFailure = error => {
  return { type: GET_CLIENT_INQUIRIES_ERROR, payload: error };
};

export const getOrder = (id) => {
  return { type: GET_ORDER, payload: id };
};

export const getOrderSuccess = orderData => {
  return {
    type: GET_ORDER_SUCCESS,
    payload: orderData
  };
};

export const getOrderFailure = error => {
  return { type: GET_ORDER_ERROR, payload: error };
};

export const getOrderSettings = (id) => {
  return { type: GET_ORDER_SETTINGS, payload: id };
};

export const getOrderSettingsSuccess = data => {
  return {
    type: GET_ORDER_SETTINGS_SUCCESS,
    payload: data
  };
};

export const getOrderSettingsFailure = error => {
  return { type: GET_ORDER_SETTINGS_ERROR, payload: error };
};

export const updateOrderSettings = (id, data) => {
  return { type: UPDATE_ORDER_SETTINGS, payload: {id, data}};
};

export const updateOrderSettingsSuccess = () => {
  return {type: UPDATE_ORDER_SETTINGS_SUCCESS};
};

export const updateOrderSettingsFailure = error => {
  return { type: UPDATE_ORDER_SETTINGS_ERROR, payload: error };
};

export const getOrderTotalCost = (id) => {
  return { type: GET_ORDER_TOTAL_COST, payload: id };
};

export const getOrderTotalCostSuccess = orderTotalCostData => {
  return {
    type: GET_ORDER_TOTAL_COST_SUCCESS,
    payload: orderTotalCostData
  };
};

export const getOrderTotalCostFailure = error => {
  return { type: GET_ORDER_TOTAL_COST_ERROR, payload: error };
};

export const createOrder = data => {
  return { type: CREATE_ORDER, payload: data };
};

export const createOrderSuccess = (isOrder) =>  {
  NotificationService.success(`New ${isOrder ? 'Order' : 'Inquiry'} Created`);
  return {
    type: CREATE_ORDER_SUCCESS,
  };
};

export const createOrderFailure = error => {
  return { type: CREATE_ORDER_ERROR, payload: error };
};

export const duplicateOrder = data => {
  return { type: DUPLICATE_ORDER, payload: data };
};

export const duplicateOrderSuccess = (isOrder) =>  {
  NotificationService.success(`New ${isOrder ? 'Order' : 'Inquiry'} Duplicate`);
  return {
    type: DUPLICATE_ORDER_SUCCESS,
  };
};

export const duplicateOrderFailure = error => {
  return { type: DUPLICATE_ORDER_ERROR, payload: error };
};

export const deleteOrder = (id, isOrder, filters, searchTerms, preventFetch) => {
  return { type: REMOVE_ORDER, payload: { id, isOrder, filters, searchTerms, preventFetch } };
};

export const deleteOrderSuccess = (isOrder) =>  {
  NotificationService.success(`${isOrder ? 'Order' : 'Inquiry'} Deleted`);
  return {
    type: REMOVE_ORDER_SUCCESS,
  };
};

export const deleteOrderFailure = error => {
  return { type: REMOVE_ORDER_ERROR, payload: error };
};

export const updateOrder = (data) => {
  return { type: UPDATE_ORDER, payload: data };
};

export const updateOrderSuccess = (isOrder, redirectToOrderId) => {
  NotificationService.success(`${isOrder ? 'Order' : 'Inquiry'} Updated`);
  return {type: UPDATE_ORDER_SUCCESS, payload: redirectToOrderId };
};

export const updateOrderFailure = error => {
  return { type: UPDATE_ORDER_ERROR, payload: error };
};

export const validateOrderDetails = data => {
  return {
    type: VALIDATE_ORDER_DETAILS,
    payload: data
  };
};

export const validateOrderDetailsSuccess = data => {
  return {
    type: VALIDATE_ORDER_DETAILS_SUCCESS,
    payload: data
  };
};

export const validateOrderDetailsFailure = error => {
  const errorData = dot.object(error);
  return {
    type: VALIDATE_ORDER_DETAILS_FAILURE,
    payload: errorData
  };
};

export const resetOrderDetailsData = () => {
  return {type: RESET_ORDER_DETAILS_DATA};
};

export const validateOrderPositions = data => {
  return {
    type: VALIDATE_ORDER_POSITIONS,
    payload: data
  };
};

export const validateOrderPositionsSuccess = data => {
  return {
    type: VALIDATE_ORDER_POSITIONS_SUCCESS,
    payload: data
  };
};

export const validateOrderPositionsFailure = error => {
  const errorData = dot.object(error);
  return {
    type: VALIDATE_ORDER_POSITIONS_FAILURE,
    payload: errorData
  };
};

export const resetOrderPositionsData = error => {
  return {
    type: RESET_ORDER_POSITIONS_DATA
  };
};

export const validateOrderPositionTask = data => {
  return {
    type: VALIDATE_POSITIONS_TASK,
    payload: data
  };
};

export const validateOrderPositionTaskSuccess = data => {
  return {
    type: VALIDATE_POSITIONS_TASK_SUCCESS,
    payload: data
  };
};

export const validateOrderPositionTaskFailure = error => {
  return {
    type: VALIDATE_POSITIONS_TASK_FAILURE,
    payload: error
  };
};

export const resetPositionTaskValidationErrors = () => {
  return {
    type: RESET_POSITIONS_TASK_ERRORS,
  };
};

export const validateOrderPositionImportFile = data => {
  return {
    type: VALIDATE_IMPORTED_POSITION_FILE,
    payload: data
  };
};

export const validateOrderPositionImportFileSuccess = data => {
  return {
    type: VALIDATE_IMPORTED_POSITION_FILE_SUCCESS,
    payload: data
  };
};

export const validateOrderPositionImportFileFailure = error => {
  const errorData = dot.object(error);
  return {
    type: VALIDATE_IMPORTED_POSITION_FILE_FAILURE,
    payload: errorData
  };
};

export const startPositionFileImport = data => {
  return {
    type: START_POSITION_FILE_IMPORT,
    payload: data
  };
};

export const startPositionFileImportSuccess = data => {
  return {
    type: START_POSITION_FILE_IMPORT_SUCCESS,
    payload: data
  };
};

export const startPositionFileImportFailure = error => {
  return {
    type: START_POSITION_FILE_IMPORT_FAILURE,
    payload: error
  };
};

export const startTaskFileImport = data => {
  return {
    type: START_TASK_FILE_IMPORT,
    payload: data
  };
};

export const startTaskFileImportSuccess = data => {
  return {
    type: START_TASK_FILE_IMPORT_SUCCESS,
    payload: data
  };
};

export const startTaskFileImportFailure = error => {
  return {
    type: START_TASK_FILE_IMPORT_FAILURE,
    payload: error
  };
};

export const validateOrderFittings = data => {
  return {
    type: VALIDATE_ORDER_FITTINGS,
    payload: data
  };
};

export const validateOrderFittingsSuccess = data => {
  return {
    type: VALIDATE_ORDER_FITTINGS_SUCCESS,
    payload: data
  };
};

export const validateOrderFittingsFailure = error => {
  const errorData = dot.object(error);
  return {
    type: VALIDATE_ORDER_FITTINGS_FAILURE,
    payload: errorData
  };
};

export const resetOrderFittingsData = error => {
  return {
    type: RESET_FITTINGS_DATA
  };
};

export const validateOrderCostCenter = data => {
  return {
    type: VALIDATE_ORDER_COST_CENTER,
    payload: data
  };
};

export const validateOrderCostCenterSuccess = data => {
  return {
    type: VALIDATE_ORDER_COST_CENTER_SUCCESS,
    payload: data
  };
};

export const validateOrderCostCenterFailure = error => {
  const errorData = dot.object(error);
  return {
    type: VALIDATE_ORDER_COST_CENTER_FAILURE,
    payload: errorData
  };
};

export const resetOrderCostCenterData = error => {
  return {
    type: RESET_COST_CENTER_DATA
  };
};

export const validateOrderCostCenterItem = data => {
  return {
    type: VALIDATE_ORDER_COST_CENTER_ITEM,
    payload: data
  };
};

export const validateOrderCostCenterItemSuccess = data => {
  return {
    type: VALIDATE_ORDER_COST_CENTER_ITEM_SUCCESS,
    payload: data
  };
};

export const validateOrderCostCenterItemFailure = error => {
  return {
    type: VALIDATE_ORDER_COST_CENTER_ITEM_FAILURE,
    payload: error
  };
};

export const orderPositionCalculation = data => {
  return {
    type: ORDER_POSITION_CALCULATION,
    payload: data
  };
};

export const orderPositionCalculationSuccess = data => {
  return {
    type: ORDER_POSITION_CALCULATION_SUCCESS,
    payload: data
  };
};

export const orderPositionCalculationFailure = error => {
  return {
    type: ORDER_POSITION_CALCULATION_FAILURE,
    payload: error
  };
};

export const orderCostCenterCalculation = data => {
  return {
    type: ORDER_COST_CENTER_CALCULATION,
    payload: data
  };
};

export const orderCostCenterCalculationSuccess = data => {
  return {
    type: ORDER_COST_CENTER_CALCULATION_SUCCESS,
    payload: data
  };
};

export const orderCostCenterCalculationFailure = error => {
  return {
    type: ORDER_COST_CENTER_CALCULATION_FAILURE,
    payload: error
  };
};

export const setOrderStarred = (isOrder, data) => {
  return {
    type: SET_ORDER_STARRED,
    payload: {isOrder, data}
  };
};

export const setOrderStatus = (isOrder, data) => {
  return {
    type: SET_ORDER_STATUS,
    payload: {isOrder, data}
  };
};

export const setOrderStatusSuccess = (newOrderId) => {
  return {
    type: SET_ORDER_STATUS_SUCCESS,
    payload: newOrderId,
  }
}

export const setOrderStatusFailure = (error) => {
  return {
    type: SET_ORDER_STATUS_FAILURE,
    payload: error,
  }
}

export const setOrderDeadLine = data => {
  return {
    type: SET_ORDER_DEADLINE,
    payload: data
  };
};

export const filterOrders = (filters, searchTerms) => {
  return {
    type: FILTER_ORDERS,
    payload: { filters, searchTerms }
  };
};

export const resetOrderData = () => {
  return {type: RESET_ORDER_DATA}
};

export const resetImportData = () => {
  return {type: RESET_FILE_IMPORT_DATA}
};

export const getOrderPositionDefaultCostCenters = () => {
  return {type: GET_ORDER_POSITION_DEFAULT_COST_CENTER}
};

export const getOrderPositionDefaultCostCentersSuccess = (data) => {
  return {type: GET_ORDER_POSITION_DEFAULT_COST_CENTER_SUCCESS, payload: data}
};

export const getOrderPositionDefaultCostCentersFailure = (error) => {
  return {
    type: GET_ORDER_POSITION_DEFAULT_COST_CENTER_SUCCESS,
    payload: error
  }
};

