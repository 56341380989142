import React from "react";
import { withTranslation } from 'react-i18next';

import CustomMultiselectHierarchical from "../../components/CustomMultiselectHierarcical/index";
import GridItem from "../../components/Grid/GridItem";
import Input from "../../components/Input/Input";
import { transformContactCategoriesHierarchy } from "../../utils/utils";

import './style.scss';
import moment from "moment";
import DatePicker from "../../components/CustomDatePicker";
import {contactsDetailsPageItems} from "../../constants/constants";

class ContactDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactCategoriesHierarchy: [],
      contactCategoriesHierarchyTransformed: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.errors !== this.props.errors && Object.keys(this.props.errors).length !== 0) {
      const fieldId = Object.keys(this.props.errors).sort((a,b) => contactsDetailsPageItems[a] - contactsDetailsPageItems[b])[0];
      if (document.getElementById(fieldId)) {
        document.getElementById(fieldId).focus();
      }
    }

    if (this.props.contactCategoriesHierarchy != this.state.contactCategoriesHierarchy) {
      this.setState({
        contactCategoriesHierarchy: this.props.contactCategoriesHierarchy,
        contactCategoriesHierarchyTransformed: transformContactCategoriesHierarchy(this.props.contactCategoriesHierarchy),
      });
    }
  }

  render() {
    const {
      countries,
      cities,
      data,
      onDataChange,
      categories,
      t
    } = this.props;
    const { contactCategoriesHierarchyTransformed } = this.state;
    const errors = this.props.errors ? this.props.errors : {};  // fixes a bug
    return(
      <>
        <div className="contact-info-item">
          <GridItem className="info-item" xs={4}>
            <span>{ t('abbreviation_initials') }</span>
            <Input
              autofocus={true}
              value={data.initials}
              onChange={ (e) => onDataChange('initials', e.target.value)}
              error={errors.initials}
              errorMsg={errors.initials && errors.initials[0]}
              disableErrorPadding={true}
              id="initials"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('salutation') }</span>
            <Input
              value={data.salutation}
              onChange={ (e) => onDataChange('salutation', e.target.value)}
              error={errors.salutation}
              errorMsg={errors.salutation && errors.salutation[0]}
              disableErrorPadding={true}
              id="salutation"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('title') }</span>
            <Input
              value={data.title}
              onChange={ (e) => onDataChange('title', e.target.value)}
              error={errors.title}
              errorMsg={errors.title && errors.title[0]}
              disableErrorPadding={true}
              id="title"
            />
          </GridItem>
        </div>
        <div className="contact-info-item">
          <GridItem className="info-item" xs={4}>
            <span>{ t('first_name') }</span>
            <Input
              value={data.first_name}
              onChange={ (e) => onDataChange('first_name', e.target.value)}
              error={errors.first_name}
              errorMsg={errors.first_name && errors.first_name[0]}
              disableErrorPadding={true}
              id="first_name"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('last_name') }</span>
            <Input
              value={data.last_name}
              onChange={ (e) => onDataChange('last_name', e.target.value)}
              error={errors.last_name}
              errorMsg={errors.last_name && errors.last_name[0]}
              disableErrorPadding={true}
              id="last_name"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('date_of_birth') }</span>
            <DatePicker handleChange={date => onDataChange('date_of_birth', moment(date).format('YYYY-MM-DD'))} selectedDate={data.date_of_birth} />
          </GridItem>
        </div>
        <div className="contact-info-item">
          <GridItem xs={12} className="company-info-input info-item">
            <span>{ t('company') }</span>
            <Input
              value={data.company}
              onChange={ (e) => onDataChange('company', e.target.value)}
              error={errors.company}
              errorMsg={errors.company && errors.company[0]}
              disableErrorPadding={true}
              id="company"
            />
          </GridItem>
        </div>
        <div className="contact-info-item">
          <GridItem className="info-item" xs={4}>
            <span>{ t('address') }</span>
            <Input
              value={data.address}
              onChange={ (e) => onDataChange('address', e.target.value)}
              error={errors.address}
              errorMsg={errors.address && errors.address[0]}
              disableErrorPadding={true}
              id="address"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('post_code') }</span>
            <Input
              value={data.postal_code}
              onChange={ (e) => onDataChange('postal_code', e.target.value)}
              error={errors.postal_code}
              errorMsg={errors.postal_code && errors.postal_code[0]}
              disableErrorPadding={true}
              id="postal_code"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('city') }</span>
            <Input
              value={data.city}
              onChange={ (e) => onDataChange('city', e.target.value)}
              error={errors.city}
              errorMsg={errors.city && errors.city[0]}
              disableErrorPadding={true}
              id="city"
            />
          </GridItem>
        </div>
        <div className="contact-info-item">
          <GridItem className="info-item" xs={4}>
            <span>{ t('country') }</span>
            <Input
              value={data.country}
              onChange={ (e) => onDataChange('country', e.target.value)}
              error={errors.country}
              errorMsg={errors.country && errors.country[0]}
              disableErrorPadding={true}
              id="country"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('title_directed_to') }</span>
            <Input
              value={data.directed_to}
              onChange={ (e) => onDataChange('directed_to', e.target.value)}
              error={errors.directed_to}
              errorMsg={errors.directed_to && errors.directed_to[0]}
              disableErrorPadding={true}
              id="directed_to"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('categories') }</span>
            <div>
              <CustomMultiselectHierarchical
                buttonText={ data.categories }
                buttonProps={{className: '', round: true, size: "sm"}}
                dropdownList={ contactCategoriesHierarchyTransformed }
                translateValues={true}
                hoverColor="dark"
                onChange={ids => onDataChange('categories', ids)}
                error={ errors.categories }
                errorMsg={ errors.categories && errors.categories[0] }
              />
            </div>
          </GridItem>
        </div>
        <div className="contact-info-item">
        <GridItem className="info-item" xs={4}>
            <span>{ t('company_phone') }</span>
            <Input
              value={data.phone_company}
              onChange={ (e) => onDataChange('phone_company', e.target.value)}
              error={errors.phone_company}
              errorMsg={errors.phone_company && errors.phone_company[0]}
              disableErrorPadding={true}
              id="phone_company"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('mobile_number') } 1</span>
            <Input
              value={data.phone_mobile}
              onChange={ (e) => onDataChange('phone_mobile', e.target.value)}
              error={errors.phone_mobile}
              errorMsg={errors.phone_mobile && errors.phone_mobile[0]}
              disableErrorPadding={true}
              id="phone_mobile"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('private_phone') }</span>
            <Input
              value={data.phone_private}
              onChange={ (e) => onDataChange('phone_private', e.target.value)}
              error={errors.phone_private}
              errorMsg={errors.phone_private && errors.phone_private[0]}
              disableErrorPadding={true}
              id="phone_private"
            />
          </GridItem>
        </div>
        <div className="contact-info-item">
          <GridItem className="info-item" xs={4}>
            <span>{ t('mobile_number') } 2</span>
            <Input
              value={data.phone_mobile2}
              onChange={ (e) => onDataChange('phone_mobile2', e.target.value)}
              error={errors.phone_mobile2}
              errorMsg={errors.phone_mobile2 && errors.phone_mobile2[0]}
              disableErrorPadding={true}
              id="phone_mobile2"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('fax') }</span>
            <Input
              value={data.fax}
              onChange={ (e) => onDataChange('fax', e.target.value)}
              error={errors.fax}
              errorMsg={errors.fax && errors.fax[0]}
              disableErrorPadding={true}
              id="fax"
            />
          </GridItem>
          <GridItem className="info-item" xs={4}>
            <span>{ t('email') }</span>
            <Input
              value={data.email}
              onChange={ (e) => onDataChange('email', e.target.value)}
              error={errors.email}
              errorMsg={errors.email && errors.email[0]}
              disableErrorPadding={true}
              id="email"
            />
          </GridItem>
        </div>
        <div className="contact-info-item">
          <GridItem  className="info-item" xs={4}>
            <span>{ t('website') }</span>
            <Input
              value={data.website}
              onChange={ (e) => onDataChange('website', e.target.value)}
              error={errors.website}
              errorMsg={errors.website && errors.website[0]}
              disableErrorPadding={true}
              id="website"
            />
          </GridItem>
        </div>
      </>
    )
  }
}

export default withTranslation()(ContactDetails);
