import React from "react";

import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import './style.scss';

class Day extends React.Component {
  render() {
    const {data} = this.props;
    return (
      <div
        className={
          `day-container ${data.isWorkingDay ? data.isAvailable ? "available-day" : "unavailable-day" : ''}`
        }
      >
        <div className="date">{data.date}</div>
        {data.isWorkingDay
          ?
            <div className={data.isAvailable ? "available-day-icon" : "unavailable-day-icon"}>
              {data.isAvailable ? <Check className="check-icon" /> : <Clear className="clear-icon"/>}
            </div>
          :
            !data.anotherMonth
              ?
                <div className="day-off">DAY OFF</div>
              :
                null
        }
      </div>
    );
  }
}

export default Day;