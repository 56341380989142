import React from "react";

import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';

import Button from '../../components/CustomButtons/Button';
import Input from "../../components/Input/Input";
import Dropdown from "../../components/CustomDropdown/CustomDropdown";

import CloseButton from "../../assets/img/buttons/delete.svg";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import {RadioGroup} from "@material-ui/core";
import StyledRadio from "../../components/StyledRadioBtn";
import StyledCheckbox from "../../components/StyledCheckbox";

class CreateMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="create-notification-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{`Create Notification`}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img
                alt="create-notification-close-btn"
                src={CloseButton}
              />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="create-notification-content">
          <GridItem className="notification-item" xs={12}>
            <span className="item-title">Notification Name</span>
            <Input/>
          </GridItem>
          <GridItem className="notification-item" xs={12}>
            <span className="item-title">Frequency</span>
              <FormControl>
                <RadioGroup className="notification-radio-container">
                  <FormControlLabel value="superAdmin" control={<StyledRadio />} label="At an Event" />
                  <FormControlLabel value="admin" control={<StyledRadio />} label="Weekly" />
                  <FormControlLabel value="adminOfficer" control={<StyledRadio />} label="Monthly" />
                </RadioGroup>
              </FormControl>
          </GridItem>
          <GridContainer>
            <GridItem xs={4}></GridItem>
            <GridItem className="notification-item" xs={4}>
              <span className="item-title">Send Every</span>
              <Dropdown
                buttonText={""}
                buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                dropdownList={[{id: 0, value: "Sort by Name"}]}
              />
            </GridItem>
            <GridItem className="notification-item" xs={4}>
              <span className="item-title">Send Every</span>
              <Dropdown
                buttonText={"Sort by Name"}
                buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                dropdownList={[{id:  0, value: "Sort by Name"}]}
              />
            </GridItem>
          </GridContainer>
          <GridItem className="notification-item">
            <span className="item-title">Frequency</span>
            <GridContainer>
              <GridItem xs={6}>
                <div><StyledCheckbox /> <span>All Users</span></div>
                <div><StyledCheckbox /> <span>Software Service Providers</span></div>
                <div className="notification-checkbox"><StyledCheckbox /> <span>Only with pending projects</span></div>
                <div><StyledCheckbox /> <span>Manufacturers</span></div>
                <div className="notification-checkbox"><StyledCheckbox /> <span>Only with pending projects</span></div>
              </GridItem>
              <GridItem xs={6}>
                <div><StyledCheckbox /> <span>Project Managers</span></div>
                <div><StyledCheckbox /> <span>All Staff</span></div>
                <div><StyledCheckbox /> <span>All with overdue tasks</span></div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem className="notification-item" xs={12}>
            <span className="item-title">Email Text</span>
            <Input/>
          </GridItem>
        </div>
        <div className="notification-btn-container">
          <Button
            className="cancel-btn"
          >
            CANCEL
          </Button>
          <Button
            className="create-btn"
          >
            SAVE
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default CreateMaterial
