/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Categories from "views/Categories";
import Materials from 'views/Materials';
import Manufacturers from 'views/ManufacturersInquiries';
import ManufacturerDetails from 'views/ManufacturerDetails';
import Roles from "views/Roles";
import LoginPageAP from 'views/Auth/LoginPageAP.jsx';
import ForgotPasswordPageAP from 'views/Auth/ForgotPassword/index';
import RegisterManufacturerPage from 'views/Auth/RegisterManufacturer/RegisterPageAP.jsx';
import Contacts from 'views/Contacts';
import Products from 'views/Products';
import Orders from 'views/Orders';
import CreateOrder from 'views/CreateOrder';
import OrderDetails from 'views/OrderDetails';
import Users from './views/Users';
import Requests from './views/Requests';
import Settings from './views/Settings';
import Notifications from './views/Notifications';
import TimesheetsList from './views/TimesheetsList';
import InvoiceList from './views/InvoiceList';

import ProjectIcon from './assets/img/sidebar/projects-b.svg';
import MaterialIcon from './assets/img/sidebar/materials-b.svg';
import ContactsIcon from './assets/img/sidebar/contacts-b.svg';
import UserIcon from './assets/img/sidebar/users-b.svg';
import PaymentsIcon from './assets/img/sidebar/payments-b.svg';
import SettingIcon from './assets/img/sidebar/Settings.svg';

const defaultRouteAuth = ['super-admin', 'admin', 'manufacturer', 'coworker', 'customer'];

const dashRoutes = [
  {
    collapse: true,
    name: 'PROJECTS',
    icon: ProjectIcon,
    iconId: 'Sidebar/projects-b',
    state: 'projectCollapse',
    views: [
      {
        path: '/inquiries',
        name: 'inquiries',
        component: Orders,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/inquiries/create-inquiry',
        isHidden: true,
        name: 'create_inquiry',
        component: CreateOrder,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/inquiries/edit-inquiry/:id',
        isHidden: true,
        name: 'edit_inquiry',
        component: CreateOrder,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/inquiries/inquiry-details/:id',
        isHidden: true,
        name: 'inquiry_details',
        component: OrderDetails,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/orders',
        name: 'orders',
        component: Orders,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/orders/create-order',
        isHidden: true,
        name: 'create_order',
        component: CreateOrder,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/orders/edit-order/:id',
        isHidden: true,
        name: 'edit_order',
        component: CreateOrder,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/orders/order-details/:id',
        isHidden: true,
        name: 'order_details',
        component: OrderDetails,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
    ]
  },
  {
    collapse: true,
    name: 'ARTICLES',
    icon: MaterialIcon,
    iconId: 'Sidebar/materials-b',
    state: 'databaseCollapse',
    views: [
      {
        path: '/materials',
        name: 'materials',
        component: Materials,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/manufacturers',
        name: 'manufacturers',
        component: Manufacturers,
        layout: '/admin',
        auth: ['super-admin', 'admin'],
      },
      {
        path: '/manufacturers/manufacturer-details/:id',
        isHidden: true,
        name: 'manufacturer_details',
        component: ManufacturerDetails,
        layout: '/admin',
        auth: defaultRouteAuth,
      }
      /*{
        path: '/products',
        name: 'Products',
        component: Products,
        layout: '/admin'
      },*/
    ]
  },
  {
    collapse: true,
    name: 'CONTACTS',
    icon: ContactsIcon,
    iconId: 'Sidebar/contacts-b',
    state: 'contactsCollapse',
    views: [{
      path: '/contacts/:id',
      name: 'contacts',
      component: Contacts,
      layout: '/admin',
      auth: defaultRouteAuth,
    }]
  },
  {
    collapse: true,
    name: 'FINANCES',
    icon: PaymentsIcon,
    iconId: 'Development',
    state: 'financesCollapse',
    views: [
      /*{
        path: '/payments',
        name: 'Payments',
        component: Categories,
        layout: '/admin'
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Categories,
        layout: '/admin'
      },*/
      {
        path: '/timesheets',
        name: 'timesheets',
        component: TimesheetsList,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      {
        path: '/invoices',
        name: 'documents',
        component: InvoiceList,
        layout: '/admin',
        auth: defaultRouteAuth,
      }
    ]
  },
  {
    collapse: true,
    name: 'SETTINGS',
    icon: SettingIcon,
    iconId: 'Icons/Settings',
    state: 'settingsCollapse',
    views: [
      {
        path: '/categories',
        name: 'categories',
        component: Categories,
        layout: '/admin',
        auth: defaultRouteAuth,
      },
      /*{
        path: "/roles",
        name: "Roles",
        component: Roles,
        layout: "/admin"
      },*/
      {
        path: '/users',
        name: 'users',
        component: Users,
        layout: '/admin',
        auth: ['super-admin', 'admin', 'manufacturer'],
      },
      {
        path: '/requests',
        name: 'requests',
        component: Requests,
        layout: '/admin',
        auth: ['super-admin', 'admin'],
      },
      /*{
        path: "/requests",
        name: "Requests",
        component: Requests,
        layout: "/admin"
      },*/
      /* {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
        layout: "/admin"
      },*/
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        layout: '/admin',
        auth: ['super-admin', 'admin', 'manufacturer'],
      },
    ]
  },
  /*{
    path: '/manufacturers',
    name: 'Manufacturers',
    rtlName: 'لوحة القيادة',
    icon: OrderIcon,
    iconId: 'Sidebar/orders-b',
    component: Manufacturers,
    layout: '/admin'
  },
  {
    path: '/material_categories',
    name: 'Materials Categories',
    rtlName: 'لوحة القيادة',
    icon: MaterialIcon,
    component: MaterialsCategories,
    layout: '/admin'
  },
  {
    path: '/material_categories/:id',
    name: 'Material Categories Details',
    isHidden: true,
    component: AddMaterialCategory,
    layout: '/admin'
  },
  {
    path: '/material_category',
    name: 'Create Material Category',
    isHidden: true,
    component: AddMaterialCategory,
    layout: '/admin'
  },
  {
    path: '/coworkers',
    name: 'Coworkers',
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Coworkers,
    layout: '/admin'
  },
  {
    path: '/coworker/:id',
    name: 'Coworker Details',
    isHidden: true,
    component: AddCoworkers,
    layout: '/admin'
  },
  {
    path: '/coworker',
    name: 'Create Coworker',
    isHidden: true,
    component: AddCoworkers,
    layout: '/admin'
  },
  {
    path: '/categories',
    name: 'Categories',
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Categories,
    layout: '/admin'
  },
  {
    path: '/categories/:id',
    name: 'Category Details',
    isHidden: true,
    component: AddCategories,
    layout: '/admin'
  },
  {
    path: '/category',
    name: 'Create Category',
    isHidden: true,
    component: AddCategories,
    layout: '/admin'
  },*/
  {
    path: '/login-page',
    name: 'Login Page',
    isHidden: true,
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: LoginPageAP,
    layout: '/auth-ap'
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    isHidden: true,
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: ForgotPasswordPageAP,
    layout: '/auth-ap'
  },
  {
    path: '/register-page',
    name: 'Register Page',
    isHidden: true,
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: RegisterManufacturerPage,
    layout: '/register'
  },
  /*,
   {
     collapse: true,
     name: 'Pages',
     rtlName: 'صفحات',
     icon: Image,
     state: 'pageCollapse',
     views: [
       {
         path: '/pricing-page',
         name: 'Pricing Page',
         rtlName: 'عالتسعير',
         mini: 'PP',
         rtlMini: 'ع',
         component: PricingPage,
         layout: '/auth'
       },
       {
         path: '/rtl-support-page',
         name: 'RTL Support',
         rtlName: 'صودعم رتل',
         mini: 'RS',
         rtlMini: 'صو',
         component: RTLSupport,
         layout: '/rtl'
       },
       {
         path: '/timeline-page',
         name: 'Timeline Page',
         rtlName: 'تيالجدول الزمني',
         mini: 'T',
         rtlMini: 'تي',
         component: TimelinePage,
         layout: '/admin'
       },
       {
         path: '/login-page-old',
         name: 'Login Page',
         rtlName: 'هعذاتسجيل الدخول',
         mini: 'L',
         rtlMini: 'هعذا',
         component: LoginPage,
         layout: '/auth'
       },
       {
         path: '/register-page',
         name: 'Register Page',
         rtlName: 'تسجيل',
         mini: 'R',
         rtlMini: 'صع',
         component: RegisterPage,
         layout: '/auth'
       },
       {
         path: '/lock-screen-page',
         name: 'Lock Screen Page',
         rtlName: 'اقفل الشاشة',
         mini: 'LS',
         rtlMini: 'هذاع',
         component: LockScreenPage,
         layout: '/auth'
       },
       {
         path: '/user-page',
         name: 'Users Profile',
         rtlName: 'ملف تعريفي للمستخدم',
         mini: 'UP',
         rtlMini: 'شع',
         component: UserProfile,
         layout: '/admin'
       },
       {
         path: '/error-page',
         name: 'Error Page',
         rtlName: 'صفحة الخطأ',
         mini: 'E',
         rtlMini: 'البريد',
         component: ErrorPage,
         layout: '/auth'
       }
     ]
   },
   {
     collapse: true,
     name: 'Components',
     rtlName: 'المكونات',
     icon: Apps,
     state: 'componentsCollapse',
     views: [
       {
         collapse: true,
         name: 'Multi Level Collapse',
         rtlName: 'انهيار متعدد المستويات',
         mini: 'MC',
         rtlMini: 'ر',
         state: 'multiCollapse',
         views: [
           {
             path: '/buttons',
             name: 'Buttons',
             rtlName: 'وصفت',
             mini: 'B',
             rtlMini: 'ب',
             component: Buttons,
             layout: '/admin'
           }
         ]
       },
       {
         path: '/buttons',
         name: 'Buttons',
         rtlName: 'وصفت',
         mini: 'B',
         rtlMini: 'ب',
         component: Buttons,
         layout: '/admin'
       },
       {
         path: '/grid-system',
         name: 'Grid System',
         rtlName: 'نظام الشبكة',
         mini: 'GS',
         rtlMini: 'زو',
         component: GridSystem,
         layout: '/admin'
       },
       {
         path: '/panels',
         name: 'Panels',
         rtlName: 'لوحات',
         mini: 'P',
         rtlMini: 'ع',
         component: Panels,
         layout: '/admin'
       },
       {
         path: '/sweet-alert',
         name: 'Sweet Alert',
         rtlName: 'الحلو تنبيه',
         mini: 'SA',
         rtlMini: 'ومن',
         component: SweetAlert,
         layout: '/admin'
       },
       {
         path: '/notifications',
         name: 'Notifications',
         rtlName: 'إخطارات',
         mini: 'N',
         rtlMini: 'ن',
         component: Notifications,
         layout: '/admin'
       },
       {
         path: '/icons',
         name: 'Icons',
         rtlName: 'الرموز',
         mini: 'I',
         rtlMini: 'و',
         component: Icons,
         layout: '/admin'
       },
       {
         path: '/typography',
         name: 'Typography',
         rtlName: 'طباعة',
         mini: 'T',
         rtlMini: 'ر',
         component: Typography,
         layout: '/admin'
       }
     ]
   },
   {
     collapse: true,
     name: 'Forms',
     rtlName: 'إستمارات',
     icon: 'content_paste',
     state: 'formsCollapse',
     views: [
       {
         path: '/regular-forms',
         name: 'Regular Forms',
         rtlName: 'أشكال عادية',
         mini: 'RF',
         rtlMini: 'صو',
         component: RegularForms,
         layout: '/admin'
       },
       {
         path: '/extended-forms',
         name: 'Extended Forms',
         rtlName: 'نماذج موسعة',
         mini: 'EF',
         rtlMini: 'هوو',
         component: ExtendedForms,
         layout: '/admin'
       },
       {
         path: '/validation-forms',
         name: 'Validation Forms',
         rtlName: 'نماذج التحقق من الصحة',
         mini: 'VF',
         rtlMini: 'تو',
         component: ValidationForms,
         layout: '/admin'
       },
       {
         path: '/wizard',
         name: 'Wizard',
         rtlName: 'ساحر',
         mini: 'W',
         rtlMini: 'ث',
         component: Wizard,
         layout: '/admin'
       }
     ]
   },
   {
     collapse: true,
     name: 'Tables',
     rtlName: 'الجداول',
     icon: GridOn,
     state: 'tablesCollapse',
     views: [
       {
         path: '/regular-tables',
         name: 'Regular Tables',
         rtlName: 'طاولات عادية',
         mini: 'RT',
         rtlMini: 'صر',
         component: RegularTables,
         layout: '/admin'
       },
       {
         path: '/extended-tables',
         name: 'Extended Tables',
         rtlName: 'جداول ممتدة',
         mini: 'ET',
         rtlMini: 'هور',
         component: ExtendedTables,
         layout: '/admin'
       },
       {
         path: '/react-tables',
         name: 'React Tables',
         rtlName: 'رد فعل الطاولة',
         mini: 'RT',
         rtlMini: 'در',
         component: ReactTables,
         layout: '/admin'
       }
     ]
   },
   {
     collapse: true,
     name: 'Maps',
     rtlName: 'خرائط',
     icon: Place,
     state: 'mapsCollapse',
     views: [
       {
         path: '/google-maps',
         name: 'Google Maps',
         rtlName: 'خرائط جوجل',
         mini: 'GM',
         rtlMini: 'زم',
         component: GoogleMaps,
         layout: '/admin'
       },
       {
         path: '/full-screen-maps',
         name: 'Full Screen Map',
         rtlName: 'خريطة كاملة الشاشة',
         mini: 'FSM',
         rtlMini: 'ووم',
         component: FullScreenMap,
         layout: '/admin'
       },
       {
         path: '/vector-maps',
         name: 'Vector Map',
         rtlName: 'خريطة المتجه',
         mini: 'VM',
         rtlMini: 'تم',
         component: VectorMap,
         layout: '/admin'
       }
     ]
   },
   {
     path: '/widgets',
     name: 'Widgets',
     rtlName: 'الحاجيات',
     icon: WidgetsIcon,
     component: Widgets,
     layout: '/admin'
   },
   {
     path: '/charts',
     name: 'Charts',
     rtlName: 'الرسوم البيانية',
     icon: Timeline,
     component: Charts,
     layout: '/admin'
   },
   {
     path: '/calendar',
     name: 'Calendar',
     rtlName: 'التقويم',
     icon: DateRange,
     component: Calendar,
     layout: '/admin'
   }*/
];

export default dashRoutes;
