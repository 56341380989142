import React from "react";
import { withTranslation } from 'react-i18next';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { setMaterialAvailability, resetMaterialDuplicateData } from '../../store/actions/materials';

import './style.scss';

import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";

import CloseButton from "../../assets/img/buttons/delete.svg";
import { config } from "../../Config";
import { calculateMaterialRetailPrice } from "utils/utils";

class ViewMaterial extends React.Component {

  static EmptyMaterial = {
    category: {},
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isMaterialDuplicated !== this.props.isMaterialDuplicated && this.props.isMaterialDuplicated) {
      this.props.onClose()
    }
  }

  componentWillUnmount() {
    this.props.resetMaterialDuplicateData()
  }

  updateMaterialAvailability = (id, value) => {
    const { setMaterialAvailability, currentPage, currentFilters, currentSearchTerms, currentCompoundMaterials } = this.props;
    setMaterialAvailability({ page: currentPage, filters: currentFilters, searchTerms: currentSearchTerms, loadCompoundMaterials: currentCompoundMaterials } , { id, value });
  };

  render() {
    const { isOpen, onClose, availabilityOptions, metrics, currencySymbol, t } = this.props;
    const material = this.props.material ? this.props.material : ViewMaterial.EmptyMaterial;
    const suppliers = material.suppliers ? material.suppliers.map((el, index) => ({ id: index, name: `${el.last_name ? el.last_name : ''} ${el.first_name ? el.first_name : ''}`.trim() })) : [];

    const renderSupplier = (supplier, index) => {
      return (
        <div key={index} className="supplier">
          <span>{supplier.name}</span>
        </div>
      )
    };

    const renderBrands = (material) => {
      return material.brands ? material.brands.join(", ") : "";
    };

    const handlePrice = (material) => {
      if (material.material_price && material.material_price[0])
        return material.material_price[0].price;
      else
        return material.default_price;
    };

    const calculateDiscount = material => {
      const basePrice = handlePrice(material);
      const discount = material.discount_percentage / 100.0;
      return Math.round(basePrice * discount);
    };

    const calculateSurcharges = material => {
      const basePrice = handlePrice(material);
      const discount = calculateDiscount(material);
      const surcharge = material.surcharge_percentage / 100.0;
      return Math.round((basePrice - discount) * surcharge);
    };

    const calculateWaste = material => {
      const basePrice = handlePrice(material);
      const discount = calculateDiscount(material);
      const surcharges = calculateSurcharges(material);
      const waste = material.waste_percentage / 100.0;
      return Math.round((basePrice - discount + surcharges) * waste);
    };

    const calculateRetailPrice = material => {
      const basePrice = handlePrice(material);
      const result = calculateMaterialRetailPrice(basePrice, material.discount_percentage, material.surcharge_percentage, material.waste_percentage);
      return Math.round(result);
    };

    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="view-material-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ t('view_material') }</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img
                alt="view-material-close-btn"
                src={CloseButton}
              />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="view-material-content">
          <GridItem className="view-material-info" xs={8}>
            <div className="material-name">{ material.name }</div>
            <div className="material-info-item">
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('material_short_name') }</span>
                </GridItem>
                <GridItem xs={6}>
                  <span>{ material.code }</span>
                </GridItem>
              </div>
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('category') }</span>
                </GridItem>
                <GridItem xs={6}>
                  <span>{ material.category && material.category.name ? material.category.name : '' }</span>
                </GridItem>
              </div>
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('brand') }</span>
                </GridItem>
                <GridItem xs={6}>
                  <span>{ renderBrands(material) }</span>
                </GridItem>
              </div>
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('suppliers') }</span>
                </GridItem>
                <GridItem xs={6}>
                  {suppliers.map((supplier, index) => renderSupplier(supplier, index))}
                </GridItem>
              </div>
            </div>
            <div className="material-info-item">
              <div className="material-info">
                <GridItem xs={9} className="material-info-title">
                  <span>{ t('purchasing_price') }</span>
                </GridItem>
                <GridItem xs={3}>
                  <span className="bold">{ `${handlePrice(material)} ${currencySymbol}` }</span>
                </GridItem>
              </div>
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('discount') }</span>
                </GridItem>
                <GridItem xs={3}>
                  <span>{ `${material.discount_percentage}%` }</span>
                </GridItem>
                <GridItem xs={3}>
                  <span>{ `${calculateDiscount(material)} ${currencySymbol}` }</span>
                </GridItem>
              </div>
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('surcharges') }</span>
                </GridItem>
                <GridItem xs={3}>
                  <span>{`${material.surcharge_percentage}%`}</span>
                </GridItem>
                <GridItem xs={3}>
                  <span>{ `${calculateSurcharges(material)} ${currencySymbol}` }</span>
                </GridItem>
              </div>
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('waste') }</span>
                </GridItem>
                <GridItem xs={3}>
                  <span>{`${material.waste_percentage}%`}</span>
                </GridItem>
                <GridItem xs={3}>
                  <span>{ `${calculateWaste(material)} ${currencySymbol}` }</span>
                </GridItem>
              </div>
              <div className="material-info calculateRetailPricemargin">
                <GridItem xs={9} className="material-info-title">
                  <span className="bold">{ t('retail_price') }</span>
                </GridItem>
                <GridItem xs={3} className="material-info-price">
                  <span>{ `${calculateRetailPrice(material)}${currencySymbol}` }</span>
                </GridItem>
              </div>
            </div>
            <div className="material-info-item">
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('can_be_used_as_edge') }</span>
                </GridItem>
                <GridItem xs={6}>
                  <span>{ material.is_edge_material ? t('yes') : t('no') }</span>
                </GridItem>
              </div>
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('thickness') }</span>
                </GridItem>
                <GridItem xs={6}>
                  <span>{`${material.thickness} mm`}</span>
                </GridItem>
              </div>
              <div className="material-info">
                <GridItem xs={6} className="material-info-title">
                  <span>{ t('unit') }</span>
                </GridItem>
                <GridItem xs={6}>
                  <span>{ metrics[material.metric] }</span>
                </GridItem>
              </div>
            </div>
          </GridItem>
          <GridItem className="view-material-description" xs={4}>
            <div className="view-material-btn">
              {material.can_delete &&
              <Button
                className="cancel-btn"
                onClick={this.props.onDelete ? this.props.onDelete : null}
              >
                { t('delete').toUpperCase() }
              </Button>
              }
              <Button
                className="duplicate-btn"
                onClick={this.props.onDuplicate ? () => this.props.onDuplicate(material.id) : null}
                disabled={this.props.purge}
              >
                { t('duplicate').toUpperCase() }
              </Button>
              <Button
                className="create-btn"
                onClick={this.props.onEdit ? this.props.onEdit : null}
                disabled={this.props.purge}
              >
                { t('edit').toUpperCase() }
              </Button>
            </div>
            <div className={"material-img-container " + (material.in_stock == 1 ? "dropdown-green" : "dropdown-red")}>
              <Dropdown
                buttonText={material.in_stock == 1 ? 0 : 1}
                buttonIconType="white"
                buttonProps={{className: 'dropdown-blue-style stock-dropdown', round: true, size: "sm"}}
                dropdownList={availabilityOptions}
                translateValues={true}
                hoverColor="dark"
                disabled={this.props.purge}
                onClick={data =>
                  this.updateMaterialAvailability(material.id, data.id == 0 ? 1 : 0)
                }
              />
              <div className="material-img">
                <span>{ t('image') }</span>
                <div className={"img-container" + (!material.logo || !material.logo.url ? " img-container-empty" : "")}>
                {
                  material.logo && material.logo.url &&
                  <img alt="material-logo" src={`${config.apiBaseUrl}${material.logo.url}`} />
                }
                </div>
              </div>
              <span className="info-title">{ t('description') }</span>
              <div className="description-text">{ material.description ? material.description : t('none').toLowerCase() }</div>
            </div>
          </GridItem>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    metrics: state.globals.metrics,
    currencySymbol: state.globals.currencySymbol,
    isMaterialDuplicated: state.materials.isMaterialDuplicated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMaterialAvailability: (page, data) =>
      dispatch(setMaterialAvailability(page, data)),
    resetMaterialDuplicateData: () => dispatch(resetMaterialDuplicateData()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ViewMaterial));

