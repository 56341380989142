import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import StandardSurcharges from './StandardSurcharges';
import RawAllowances from './RawAllowances';

import './style.scss';

class Materials extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data_standard_surcharges, data_raw_allowances, errors, onDataStandardSurchargesChange, onDataRawAllowancesChange } = this.props;
    return (
      <GridContainer className="materials-positions-outer-container">
        <GridItem className="materials-positions-container" xs={6}>
          <StandardSurcharges
            data={data_standard_surcharges}
            errors={errors}
            onDataChange={onDataStandardSurchargesChange}
          />
        </GridItem>
        <GridItem className="materials-positions-container" xs={6}>
          <RawAllowances
            data={data_raw_allowances}
            errors={errors}
            onDataChange={onDataRawAllowancesChange}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

export default Materials;
