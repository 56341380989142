
class TimerUtil {
    static subscribers = [];

    static isRunning = false;
    static isPaused = false;
    static timeResidue = 0;
    static time = null;
    static start = null;
    static timer = null;

    static TIMER_INTERVAL = 1000;

    static timerCallback = () => {
        if (TimerUtil.isRunning)
            TimerUtil.time = TimerUtil.timeResidue + (Date.now() - TimerUtil.start);
        else
            TimerUtil.time = TimerUtil.timeResidue;
        TimerUtil.broadcast();
    };

    static startTimer = (timerStart, timeResidue, isPaused=false) => {
        if (TimerUtil.timer) {
            clearInterval(TimerUtil.timer);
        }
        TimerUtil.isRunning = !isPaused;
        TimerUtil.isPaused = isPaused;
        TimerUtil.time = 0;
        TimerUtil.timeResidue = timeResidue;
        if (!isPaused) {
            TimerUtil.start = timerStart;
            TimerUtil.timer = setInterval(TimerUtil.timerCallback, TimerUtil.TIMER_INTERVAL);
        } else {
            TimerUtil.start = null;
            TimerUtil.timer = null;
            TimerUtil.time = TimerUtil.timeResidue;
        }
        TimerUtil.broadcast();
    };

    static pauseTimer = (timeResidue) => {
        TimerUtil.isRunning = false;
        TimerUtil.isPaused = true;
        clearInterval(TimerUtil.timer);
        TimerUtil.timer = null;
        TimerUtil.start = null;
        TimerUtil.timeResidue = timeResidue ? timeResidue : TimerUtil.time;
        TimerUtil.broadcast();
    };

    static resumeTimer = (timerStart, timeResidue) => {
        if (TimerUtil.timer) {
            clearInterval(TimerUtil.timer);
        }
        TimerUtil.isRunning = true;
        TimerUtil.isPaused = false;
        if (timeResidue !== null && timeResidue !== undefined)
            TimerUtil.timeResidue = timeResidue;
        TimerUtil.start = timerStart;
        TimerUtil.timer = setInterval(TimerUtil.timerCallback, TimerUtil.TIMER_INTERVAL);
        TimerUtil.broadcast();
    };

    static resetTimer = () => {
        clearInterval(TimerUtil.timer);
        TimerUtil.isRunning = false;
        TimerUtil.isPaused = false;
        TimerUtil.time = null;
        TimerUtil.start = null;
        TimerUtil.timeResidue = 0;
        TimerUtil.timer = null;
        TimerUtil.broadcast();
    };

    static msToTime = (s) => {
        // pad to 2 or 3 digits, default is 2
        function pad(n, z) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }

        const ms = s % 1000;
        s = (s - ms) / 1000;
        const secs = s % 60;
        s = (s - secs) / 60;
        const mins = s % 60;
        const hrs = (s - mins) / 60;

        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
    };

    static subscribe = (obj) => {
        TimerUtil.subscribers = [ ...TimerUtil.subscribers, obj ];
    };

    static unsubscribe = (obj) => {
        TimerUtil.subscribers = TimerUtil.subscribers.filter(el => el !== obj);
    };

    static broadcast = () => {
        for (let t in TimerUtil.subscribers) {
            const subscriber = TimerUtil.subscribers[t];
            subscriber && subscriber.onTimerChange && subscriber.onTimerChange(TimerUtil.time);
        }
    };
}

export default TimerUtil;