import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  ADD_USER,
  SET_USER_STATUS,
  SET_USER_STATUS_SUCCESS,
  SET_USER_STATUS_FAILURE,
  UPDATE_USER,
  REMOVE_USER,
  RESET_USERS_DATA,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER_ERROR,
  FILTER_USERS, UPDATE_USER_SUCCESS
} from "../actionTypes/usersDataActionTypes";

import NotificationService from 'services/NotificationService';

export const getUsers = (page, searchTerms) => {
  return {type: GET_USERS, payload: { page, searchTerms }}
};

export const getUsersSuccess = (users) => {
  return {type: GET_USERS_SUCCESS, payload: users}
};

export const getUsersFailure = (error) => {
  return {type: GET_USERS_ERROR, payload: error}
};

export const getUser = (id) => {
  return {type: GET_USER, payload: id}
};

export const getUserSuccess = (user) => {
  return {type: GET_USER_SUCCESS, payload: user}
};

export const resetUserData = () => {
  return {type: RESET_USERS_DATA}
};

export const addUser = (data) => {
  return {type: ADD_USER, payload: data}
};

export const addUserSuccess = () => {
  NotificationService.success('User successfully created.');
  return {type: ADD_USER_SUCCESS}
};

export const addUserFailure = (error) => {
  NotificationService.error(error.message);
  return {type: ADD_USER_ERROR, payload: error.errors}
};

export const setUserStatus = (id, value, filters, searchTerms) => {
  return {type: SET_USER_STATUS, payload: {id, value, filters, searchTerms}}
};

export const setUserStatusSuccess = () => {
  return {type: SET_USER_STATUS_SUCCESS};
};

export const setUserStatusFailure = (err) => {
  return {type: SET_USER_STATUS_FAILURE, payload: err};
};

export const updateUser = (data) => {
  return {type: UPDATE_USER, payload: data}
};

export const updateUserSuccess = (data) => {
  NotificationService.success('User was updated');
  return {type: UPDATE_USER_SUCCESS, payload: data}
};

export const updateUserFailure = (error) => {
  NotificationService.error(error.message);
  return {type: UPDATE_USER_ERROR, payload: error.errors}
};

export const removeUser = (data) => {
  return {type: REMOVE_USER, payload: data}
};

export const filterUsers = (filters, searchTerms) => {
  return {
    type: FILTER_USERS,
    payload: { filters, searchTerms }
  };
};
