import {ofType} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {rolesServiceApi} from 'services/API';
import NotificationService from '../services/NotificationService';
import {
  getRolesSuccess,
  getRolesFailure
} from '../store/actions/roles';
import {
  GET_ROLES
} from '../store/actionTypes/rolesActionTypes';

export const getRolesEpic = (actions$) => actions$.pipe(
  ofType(GET_ROLES),
  switchMap((action) => from(rolesServiceApi.getRoles(action.payload)).pipe(
    switchMap((response) => {
      return of(getRolesSuccess(response))
    }),
    catchError( err => {
        return of(getRolesFailure(err))
      }
    )
  ))
);
