export const manufacturerTableColumnNames = {
  MANUFACTURER: 0,
  DELIVERY_DATE: 1,
  DELIVERY_PRICE: 2,
  CABINET_PRICE: 3,
  TOTAL_PRICE: 4
};

export const manufacturerTableColumnTypes = {
  STRING: 0,
  NUMBER: 1,
  DATE: 2
};

export const manufacturerTableOrderTypes = {
  ASCENDING: 0,
  DESCENDING: 1
};

export const manufacturerTableColumns = {
  [manufacturerTableColumnNames.MANUFACTURER]:
  manufacturerTableColumnTypes.STRING,
  [manufacturerTableColumnNames.DELIVERY_DATE]:
  manufacturerTableColumnTypes.DATE,
  [manufacturerTableColumnNames.DELIVERY_PRICE]:
  manufacturerTableColumnTypes.NUMBER,
  [manufacturerTableColumnNames.CABINET_PRICE]:
  manufacturerTableColumnTypes.NUMBER,
  [manufacturerTableColumnNames.TOTAL_PRICE]:
  manufacturerTableColumnTypes.NUMBER
};

export const ALL_PROJECT_STATUSES_OPTION_ID = 0;

export const ALL_PROJECT_CATEGORIES_OPTION_ID = 0;

export const ALL_PROJECT_MANUFACTURERS_OPTION_ID = 0;

export const ProjectIssueImportanceLevel = {
  NONE: 0,
  LOW: 1,
  HIGH: 2
};

export const Tab = {
  MY_PROJECTS: 0,
  STARRED: 1,
  WITH_ISSUES: 2
};

export const SettingsTab = {
  COST_CENTER: 0,
  MATERIALS: 1,
  MATERIAL_LIST: 2,
  WEBSHOP: 3,
  PROJECTS: 4,
};

export const TimeSheetTab = {
  OVERVIEW: 0,
  LIST: 1
};

export const TimeSheetDateTab = {
  WEEK: 0,
  MONTH: 1
};

export const CreateProjectTab = {
  PROJECT_DETAILS: 0,
  MATERIAL_LIST: 1,
  COST_CENTER: 2
};

export const CreateOrderTab = {
  ORDER_DETAILS: 0,
  POSITIONS: 1,
  TASKS: 2,
  FITTINGS: 3,
  TIME_CALCULATION: 4
};

export const CreateInquiryTab = {
  INQUIRY_DETAILS: 0,
  POSITIONS: 1,
  TASKS: 2,
  FITTINGS: 3,
  TIME_CALCULATION: 4
};

export const ProjectDetailsTab = {
  INFORMATION: 0,
  PROGRESS: 1,
  TIMESHEETS: 2,
  INVOICE: 3,
  LOG: 4
};

export const InquiryDetailsTab = {
  INFORMATION: 0,
  PROGRESS: 1,
  INVOICE: 2,
  LOG: 3,
};

export const OrderTab = {
  MY_ORDERS: 0,
  STARRED: 1,
  COMPLETED: 2
};

export const InquiriesTab = {
  MY_INQUIRIES: 0,
  STARRED: 1,
  ACCEPTED: 2
};

export const CreateContactTab = {
  DETAILS: 0,
  SHIPPING: 1,
  FINANCES: 2,
  NOTES: 3
};

export const ManufacturersInquiriesTab = {
  LIST: 0,
  INQUIRIES: 1
};

export const TabVals = {
  [Tab.MY_PROJECTS]: 'my_projects',
  [Tab.STARRED]: 'starred',
  [Tab.WITH_ISSUES]: 'with_issues'
};

export const SettingsTabVals = {
  [SettingsTab.COST_CENTER]: 'cost_centers',
  [SettingsTab.MATERIALS]: 'materials',
  [SettingsTab.MATERIAL_LIST]: 'material_list',
  [SettingsTab.WEBSHOP]: 'webshop',
  [SettingsTab.PROJECTS]: 'projects'
};

export const TimeSheetTabVals = {
  [TimeSheetTab.OVERVIEW]: 'overview',
  [TimeSheetTab.LIST]: 'list'
};

export const TimeSheetDateTabVals = {
  [TimeSheetDateTab.WEEK]: 'week',
  [TimeSheetDateTab.MONTH]: 'month'
};

export const CreateProjectTabVals = {
  [CreateProjectTab.PROJECT_DETAILS]: 'project_details',
  [CreateProjectTab.MATERIAL_LIST]: 'material_list',
  [CreateProjectTab.COST_CENTER]: 'cost_center'
};

export const CreateOrderTabVals = {
  [CreateOrderTab.ORDER_DETAILS]: 'order_details',
  [CreateOrderTab.POSITIONS]: 'positions',
  [CreateOrderTab.TASKS]: 'items',
  [CreateOrderTab.FITTINGS]: 'fittings',
  [CreateOrderTab.TIME_CALCULATION]: 'time_calculation'
};

export const CreateInquiryTabVals = {
  [CreateInquiryTab.INQUIRY_DETAILS]: 'inquiry_details',
  [CreateInquiryTab.POSITIONS]: 'positions',
  [CreateInquiryTab.TASKS]: 'items',
  [CreateInquiryTab.FITTINGS]: 'fittings',
  [CreateInquiryTab.TIME_CALCULATION]: 'time_calculation'
};

export const ProjectDetailsTabVals = {
  [ProjectDetailsTab.INFORMATION]: 'information',
  [ProjectDetailsTab.PROGRESS]: 'progress',
  [ProjectDetailsTab.TIMESHEETS]: 'timesheets',
  [ProjectDetailsTab.INVOICE]: 'print',
  [ProjectDetailsTab.LOG]: 'log'
};

export const InquiryDetailsTabVals = {
  [InquiryDetailsTab.INFORMATION]: 'information',
  [InquiryDetailsTab.PROGRESS]: 'progress',
  [InquiryDetailsTab.INVOICE]: 'print',
  [InquiryDetailsTab.LOG]: 'log',
};

export const OrderTabVals = {
  [OrderTab.MY_ORDERS]: 'my_orders',
  [OrderTab.STARRED]: 'starred',
  [OrderTab.COMPLETED]: 'completed'
};

export const InquiriesTabVals = {
  [InquiriesTab.MY_INQUIRIES]: 'my_inquiries',
  [InquiriesTab.STARRED]: 'starred',
  [InquiriesTab.ACCEPTED]: 'accepted'
};

export const CreateContactTabVals = {
  [CreateContactTab.DETAILS]: 'details',
  [CreateContactTab.SHIPPING]: 'shipping',
  [CreateContactTab.FINANCES]: 'finances',
  [CreateContactTab.NOTES]: 'notes'
};

export const ManufacturersInquiriesTabVals = {
  [ManufacturersInquiriesTab.LIST]: 'list',
  [ManufacturersInquiriesTab.INQUIRIES]: 'inquiries'
};

export const AllTabs = [
  {
    id: Tab.MY_PROJECTS,
    value: TabVals[Tab.MY_PROJECTS]
  },
  {
    id: Tab.STARRED,
    value: TabVals[Tab.STARRED]
  },
  {
    id: Tab.WITH_ISSUES,
    value: TabVals[Tab.WITH_ISSUES]
  }
];

export const AllSettingsTabs = [
  {
    id: SettingsTab.COST_CENTER,
    value: SettingsTabVals[SettingsTab.COST_CENTER]
  },
  {
    id: SettingsTab.MATERIALS,
    value: SettingsTabVals[SettingsTab.MATERIALS]
  },
  {
    id: SettingsTab.MATERIAL_LIST,
    value: SettingsTabVals[SettingsTab.MATERIAL_LIST]
  },
  {
    id: SettingsTab.WEBSHOP,
    value: SettingsTabVals[SettingsTab.WEBSHOP]
  },
  {
    id: SettingsTab.PROJECTS,
    value: SettingsTabVals[SettingsTab.PROJECTS]
  }
];

export const AllTimeSheetTabs = [
  {
    id: TimeSheetTab.OVERVIEW,
    value: TimeSheetTabVals[TimeSheetTab.OVERVIEW]
  },
  {
    id: TimeSheetTab.LIST,
    value: TimeSheetTabVals[TimeSheetTab.LIST]
  }
];

export const AllTimeSheetDateTabs = [
  {
    id: TimeSheetDateTab.WEEK,
    value: TimeSheetDateTabVals[TimeSheetDateTab.WEEK]
  },
  {
    id: TimeSheetDateTab.MONTH,
    value: TimeSheetDateTabVals[TimeSheetDateTab.MONTH]
  }
];

export const CreateProjectAllTabs = [
  {
    id: CreateProjectTab.PROJECT_DETAILS,
    value: CreateProjectTabVals[CreateProjectTab.PROJECT_DETAILS]
  },
  {
    id: CreateProjectTab.MATERIAL_LIST,
    value: CreateProjectTabVals[CreateProjectTab.MATERIAL_LIST]
  },
  {
    id: CreateProjectTab.COST_CENTER,
    value: CreateProjectTabVals[CreateProjectTab.COST_CENTER]
  }
];

export const CreateOrderAllTabs = [
  {
    id: CreateOrderTab.ORDER_DETAILS,
    value: CreateOrderTabVals[CreateOrderTab.ORDER_DETAILS]
  },
  {
    id: CreateOrderTab.POSITIONS,
    value: CreateOrderTabVals[CreateOrderTab.POSITIONS]
  },
  {
    id: CreateOrderTab.TASKS,
    value: CreateOrderTabVals[CreateOrderTab.TASKS]
  },
  {
    id: CreateOrderTab.FITTINGS,
    value: CreateOrderTabVals[CreateOrderTab.FITTINGS]
  },
  {
    id: CreateOrderTab.TIME_CALCULATION,
    value: CreateOrderTabVals[CreateOrderTab.TIME_CALCULATION]
  }
];

export const CreateInquiryAllTabs = [
  {
    id: CreateInquiryTab.INQUIRY_DETAILS,
    value: CreateInquiryTabVals[CreateInquiryTab.INQUIRY_DETAILS]
  },
  {
    id: CreateInquiryTab.POSITIONS,
    value: CreateInquiryTabVals[CreateInquiryTab.POSITIONS]
  },
  {
    id: CreateInquiryTab.TASKS,
    value: CreateInquiryTabVals[CreateInquiryTab.TASKS]
  },
  {
    id: CreateInquiryTab.FITTINGS,
    value: CreateInquiryTabVals[CreateInquiryTab.FITTINGS]
  },
  {
    id: CreateInquiryTab.TIME_CALCULATION,
    value: CreateInquiryTabVals[CreateInquiryTab.TIME_CALCULATION]
  }
];

export const ProjectDetailsAllTabs = [
  {
    id: ProjectDetailsTab.INFORMATION,
    value: ProjectDetailsTabVals[ProjectDetailsTab.INFORMATION]
  },
  {
    id: ProjectDetailsTab.PROGRESS,
    value: ProjectDetailsTabVals[ProjectDetailsTab.PROGRESS]
  },
  {
    id: ProjectDetailsTab.TIMESHEETS,
    value: ProjectDetailsTabVals[ProjectDetailsTab.TIMESHEETS]
  },
  {
    id: ProjectDetailsTab.INVOICE,
    value: ProjectDetailsTabVals[ProjectDetailsTab.INVOICE]
  },
  {
    id: ProjectDetailsTab.LOG,
    value: ProjectDetailsTabVals[ProjectDetailsTab.LOG]
  }
];

export const InquiryDetailsAllTabs = [
  {
    id: InquiryDetailsTab.INFORMATION,
    value: InquiryDetailsTabVals[InquiryDetailsTab.INFORMATION]
  },
  {
    id: InquiryDetailsTab.PROGRESS,
    value: InquiryDetailsTabVals[InquiryDetailsTab.PROGRESS]
  },
  {
    id: InquiryDetailsTab.INVOICE,
    value: InquiryDetailsTabVals[InquiryDetailsTab.INVOICE]
  },
  {
    id: InquiryDetailsTab.LOG,
    value: InquiryDetailsTabVals[InquiryDetailsTab.LOG]
  },
];

export const OrderAllTabs = [
  {
    id: OrderTab.MY_ORDERS,
    value: OrderTabVals[OrderTab.MY_ORDERS]
  },
  {
    id: OrderTab.STARRED,
    value: OrderTabVals[OrderTab.STARRED]
  },
  {
    id: OrderTab.COMPLETED,
    value: OrderTabVals[OrderTab.COMPLETED]
  }
];

export const InquiriesAllTabs = [
  {
    id: InquiriesTab.MY_INQUIRIES,
    value: InquiriesTabVals[InquiriesTab.MY_INQUIRIES]
  },
  {
    id: InquiriesTab.STARRED,
    value: InquiriesTabVals[InquiriesTab.STARRED]
  },
  {
    id: InquiriesTab.ACCEPTED,
    value: InquiriesTabVals[InquiriesTab.ACCEPTED]
  }
];

export const CreateContactAllTabs = [
  {
    id: CreateContactTab.DETAILS,
    value: CreateContactTabVals[CreateContactTab.DETAILS]
  },
  {
    id: CreateContactTab.SHIPPING,
    value: CreateContactTabVals[CreateContactTab.SHIPPING]
  },
  {
    id: CreateContactTab.FINANCES,
    value: CreateContactTabVals[CreateContactTab.FINANCES]
  },
  {
    id: CreateContactTab.NOTES,
    value: CreateContactTabVals[CreateContactTab.NOTES]
  }
];

export const ManufacturersInquiriesAllTabs = [
  {
    id: ManufacturersInquiriesTab.LIST,
    value: ManufacturersInquiriesTabVals[ManufacturersInquiriesTab.LIST]
  }
  /*{
    id: ManufacturersInquiriesTab.INQUIRIES,
    value: ManufacturersInquiriesTabVals[ManufacturersInquiriesTab.INQUIRIES]
  }*/
];

export const OrderListSortByOptions = [
  { id: 'id', value: 'sort_by_order_id' },
  { id: 'origin_type', value: 'sort_by_type' },
  { id: 'status', value: 'sort_by_status' },
];

export const InquiryListSortByOptions = [
  { id: 'id', value: 'sort_by_inquiry_id' },
  { id: 'origin_type', value: 'sort_by_type' },
  { id: 'status', value: 'sort_by_status' },
];

export const ManufacturersListSortByOptions = [
  { id: 'name', value: 'sort_by_name' },
  { id: 'city.name', value: 'sort_by_location' },
  { id: 'status', value: 'sort_by_status' }
];

export const UsersListSortByOptions = [
  { id: 'id', value: 'sort_by_id' },
  { id: 'last_name', value: 'sort_by_name' },
  { id: 'role', value: 'sort_by_role' }
];

export const ProgressListSortByOptions = [
  { id: 'name', value: 'sort_by_name' },
  { id: 'coworker.first_name', value: 'sort_by_assignee' },
  { id: 'progress', value: 'sort_by_progress' },
  { id: 'due_date', value: 'sort_by_deadline' }
];

export const LogsListSortByOptions = [
  { id: 'user.first_name', value: 'sort_by_name' },
  { id: 'created_at', value: 'sort_by_date' },
  // { id: 'user_id', value: 'sort_by_user_id' },
  { id: 'action.name', value: 'sort_by_action' }
];

export const CategoriesSortByOptions = [
  { id: 'name', value: 'sort_by_name' },
  { id: 'type', value: 'sort_by_type' }
];

export const ProjectsSortByOption = {
  NONE: 0,
  NAME_ASC: 1,
  NAME_DESC: 2,
  PRICE_ASC: 3,
  PRICE_DESC: 4
};

export const ProjectsSortByVal = {
  [ProjectsSortByOption.NONE]: 'none',
  [ProjectsSortByOption.NAME_ASC]: 'name_ascending',
  [ProjectsSortByOption.NAME_DESC]: 'name_descending',
  [ProjectsSortByOption.PRICE_ASC]: 'price_ascending',
  [ProjectsSortByOption.PRICE_DESC]: 'price_descending'
};

export const ProjectsAllSortByOptions = [
  ProjectsSortByVal[ProjectsSortByOption.NONE],
  ProjectsSortByVal[ProjectsSortByOption.NAME_ASC],
  ProjectsSortByVal[ProjectsSortByOption.NAME_DESC],
  ProjectsSortByVal[ProjectsSortByOption.PRICE_ASC],
  ProjectsSortByVal[ProjectsSortByOption.PRICE_DESC]
];

export const CustomerDetailsResidenceAddress = {
  SameAsDeliveryAddress: 0,
  DifferentAddress: 1
};

export const SettingsCostCenterSortValues = [
  { id: 0, value: 'both_types' },
  { id: 2, value: 'operational' },
  { id: 1, value: 'non_operational' }
];

export const OrderDetailsPageElementsOrder = {
  'name': 1,
  'status': 2,
  'contact_person': 3,
  'customer_id': 4,
  'coworker_id': 5,
  'delivery_type': 6,
  'payment_type': 7,
  'description': 8
};

export const contactsDetailsPageItems = [
  'initials',
  'salutation',
  'title',
  'first_name',
  'last_name',
  'date_of_birth',
  'company',
  'address',
  'postal_code',
  'city',
  'country',
  'directed_to',
  'phone_private',
  'phone_mobile',
  'phone_company',
  'phone_mobile2',
  'fax',
  'email',
  'website'
];

export const contactsShippingPageItems = [
  'email',
  'first_name',
  'last_name',
  'phone',
  'phone_mobile',
  'address',
  'postal_code',
  'city',
  'country'
];

export const contactsFinancePageItems = [
  'finances_iban',
  'finances_bank',
  'finances_tax_no',
  'finances_bic',
  'finances_eu_vat_no'
];

export const contactsNotesPageItems = [
  'note_general',
  'note_business',
  'note_personal',
  'note_activities'
];

export const materialsUnits = [
  'm2',
  'm3',
  'St.',
  'lfm',
  'ltr.'
];

export const InvoiceStatusList = [
  { id: 0, value: 'open' },
  { id: 1, value: 'partially_paid' },
  { id: 2, value: 'paid' },
];

export const InvoiceTypes = {
  'offer': 3,
  'order_confirmation': 4,
  'delivery_bill': 5,
  'invoice': 6,
  'letter': 7,
};

export const translationSupportedLanguages = [
  {
    id: 'en',
    value: 'English',
  },
  {
    id: 'de',
    value: 'German',
  },
];

export const MonthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
export const DayNames = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
