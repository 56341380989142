import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getRequestsList,
  filterRequests,
  approveRequest,
  rejectRequest
} from '../../store/actions/requests';

import Button from "components/CustomButtons/Button.jsx";
import Dropdown from "components/CustomDropdown/CustomDropdown.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Loader from "../../components/Loader/Loader";

import CloseButton from "../../assets/img/buttons/delete.svg";
import EditButton from "../../assets/img/buttons/edit.svg";
import IconButton from "@material-ui/core/IconButton";
import ViewButton from "../../assets/img/buttons/eye.svg";

import './style.scss';
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomPagination from "../../components/CustomPagination";

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        page: '1'
      }
    };
  }

  componentDidMount() {
    const {getRequests} = this.props;
    getRequests()
  }

  handleFilters = (fieldName, value) => {
    const {
      filterRequests
    } = this.props;
    const { filters } = this.state;
    const newFilters = { ...filters };
    newFilters[fieldName] = value;
    filterRequests(newFilters);
    this.setState({
      filters: newFilters
    });
  };

  handlePageClick = (data) => {
    const {selected} = data;
    this.handleFilters('page', selected + 1)
  };

  isRequestsDataReady = () => {
    const {
      isDataBusy,
      t
    } = this.props;
    return !isDataBusy;
  }

  renderRequest = (request, index) => {
    const { t } = this.props;
    return(
      <GridContainer
        className={`requests-item ${ index % 2 === 0 ? 'dark' : '' }`}
      >
        <GridItem className="requests-item-title" xs={2}>{ t('date_time') }</GridItem>
        <GridItem className="requests-item-title" xs={1}>{ t('type') }</GridItem>
        <GridItem className="requests-item-title" xs={5}>{ t('action') }</GridItem>
        <GridItem className="requests-item-title" xs={2}>{ t('requested_user') }</GridItem>
        <GridItem className="requests-item-title" xs={2}>
          <IconButton aria-label="close" className="action-btn">
            <img
              alt="request-remove-btn"
              src={CloseButton}
            />
          </IconButton>
          <IconButton aria-label="close" className="action-btn">
            <img
              alt="request-view-btn"
              src={ViewButton}
            />
          </IconButton>
          <IconButton aria-label="close" className="action-btn">
            <img
              alt="request-edit-btn"
              src={EditButton}
            />
          </IconButton>
        </GridItem>
      </GridContainer>
    )
  };

  render() {
    const {requestsList, metaData, isDataBusy, t} = this.props;
    return (
      <div className="ikt-ap_requests-container">
        {this.isRequestsDataReady() ? (
          <>
          <GridContainer>
            <GridItem className="requests-dropdown-content" xs={12}>
              <Dropdown
                buttonText={""}
                buttonProps={{className: 'requests-dropdown-style', round: true, size: "sm"}}
                menuWidthCorrection={true}
                dropdownList={[{id: 0, value: t('all_users')}]}
              />
              <Dropdown
                buttonText={""}
                buttonProps={{className: 'requests-dropdown-style', round: true, size: "sm"}}
                menuWidthCorrection={true}
                dropdownList={[{id: 0, value: t('all_users')}]}
              />
              <Dropdown
                buttonText={""}
                buttonProps={{className: 'requests-dropdown-style', round: true, size: "sm"}}
                menuWidthCorrection={true}
                dropdownList={[{id: 0, value: t('filter_dates')}]}
              />
              <Button
                className="approve-request-btn"
              >
                { t('approve_all').toUpperCase() }
              </Button>
            </GridItem>
            <GridContainer className="requests-header">
              <GridItem className="requests-header-title" xs={2}>{ t('date_time') }</GridItem>
              <GridItem className="requests-header-title" xs={1}>{ t('type') }</GridItem>
              <GridItem className="requests-header-title" xs={5}>{ t('action') }</GridItem>
              <GridItem className="requests-header-title" xs={2}>{ t('requested_user') }</GridItem>
              <GridItem className="requests-header-title" xs={2}>{ t('actions') }</GridItem>
            </GridContainer>
            <GridContainer className="requests-content">
              {requestsList.length !== 0 && requestsList.map((request, index) => this.renderRequest(request, index))}
              {
                !isDataBusy &&
                requestsList.length === 0 &&
                <div className="no_requests">{ t('no_requests_found') }</div>
              }
            </GridContainer>
          </GridContainer>
          <GridContainer className="pagination-container">
            {
              metaData.last_page > 1 &&
              <CustomPagination
                pageCount={metaData.last_page}
                handlePageClick={this.handlePageClick}
              />
            }
          </GridContainer>
          </>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

Requests.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    requestsList: state.requests.requests,
    metaData: state.requests.requestsMetaData,
    isDataBusy: state.requests.requestsBusy
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRequests: page => dispatch(getRequestsList(page)),
    filterRequests: filters => dispatch(filterRequests(filters)),
    approveRequest: data => dispatch(approveRequest(data)),
    rejectRequest: data => dispatch(rejectRequest(data))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(Requests)));
