import React from "react";
import { withTranslation } from 'react-i18next';

import './style.scss';
import GridItem from "../../components/Grid/GridItem";

class ContactDetailsView extends React.Component {
  render() {
    const {data, categories, t} = this.props;
    const category = categories ? categories.find(category => category.id == data.category_id) : '';
    return(
      <>
        <div className="contact-data-container">
          <div className="contact-info-field">
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('customer_id') }</GridItem>
              <GridItem xs={8}>{data.id}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('abbreviation_initials') }</GridItem>
              <GridItem xs={8}>{data.initials}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('title') }</GridItem>
              <GridItem xs={8}>{data.title}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('date_of_birth') }</GridItem>
              <GridItem xs={8}>{data.date_of_birth}</GridItem>
            </div>
          </div>
          <div className="contact-info-field">
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('company') }</GridItem>
              <GridItem xs={8}>{data.company}</GridItem>
            </div>
          </div>
          <div className="contact-info-field">
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('address') }</GridItem>
              <GridItem xs={8}>{data.address}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('post_code') }</GridItem>
              <GridItem xs={8}>{data.postal_code}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('city') }</GridItem>
              <GridItem xs={8}>{data.city}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('country') }</GridItem>
              <GridItem xs={8}>{data.country}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('title_directed_to') }</GridItem>
              <GridItem xs={8}>{data.directed_to}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('category') }</GridItem>
              <GridItem xs={8}>{category ? category.name  : ''}</GridItem>
            </div>
          </div>
          <div className="contact-info-field">
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('company_phone') }</GridItem>
              <GridItem xs={8}>{data.phone_company}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('mobile_number') } 1</GridItem>
              <GridItem xs={8}>{data.phone_mobile}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('private_phone') }</GridItem>
              <GridItem xs={8}>{data.phone_private}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('mobile_number') } 2</GridItem>
              <GridItem xs={8}>{data.phone_mobile2}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('fac') }</GridItem>
              <GridItem xs={8}>{data.fax}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('email') }</GridItem>
              <GridItem xs={8}>{data.email}</GridItem>
            </div>
            <div className="contact-info-item">
              <GridItem xs={4} className="contact-info-title">{ t('website') }</GridItem>
              <GridItem xs={8}>{data.website}</GridItem>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(ContactDetailsView);
