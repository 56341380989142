import { handleActions } from 'redux-actions';
import {
    GET_COWORKERS,
    GET_COWORKERS_SUCCESS,
    GET_COWORKERS_ERROR,
    GET_COWORKER,
    GET_COWORKER_SUCCESS,
} from '../actionTypes/coworkersActionTypes';

export const DEFAULT_COWORKERS_STATE = {
    coworkers: [],
    coworker: {},
    coworkersError: null,
    coworkersBusy: false
};

export const coworkers = handleActions(
    {
        [GET_COWORKERS]: (state, action) => ({
            ...state,
            coworkersBusy: true
        }),
        [GET_COWORKERS_SUCCESS]: (state, action) => ({
            ...state,
            coworkers: action.payload,
            coworkersBusy: false
        }),
        [GET_COWORKERS_ERROR]: (state, action) => ({
            ...state,
            coworkersError: action.payload.message,
            coworkersBusy: false
        }),
        [GET_COWORKER]: (state, action) => ({
            ...state,
            coworkersBusy: true
        }),
        [GET_COWORKER_SUCCESS]: (state, action) => ({
            ...state,
            coworker: action.payload,
            coworkersBusy: false
        }),
    },
    DEFAULT_COWORKERS_STATE
);