import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorTooltip } from '../ErrorTooltip/ErrorTooltip';
import './input.scss';

const CustomInput = React.forwardRef((props, ref) => {
  const {className, containerClassName, error, errorMsg, onKeyPress, autofocus, disableErrorPadding, disableErrorFloat, placeholder, errorTooltip, readOnly, ...rest} = props;
  const { t } = useTranslation();
  let inputClassName = `ikt-ap_input${error ? '_error' : ''}${className ? ` ${className}` : ``}`;

  const inputField = (
    <input
      autoFocus={autofocus}
      className={inputClassName}
      placeholder={placeholder !== null && placeholder !== undefined ? placeholder : t('enter')}
      onKeyPress={onKeyPress}
      readOnly={readOnly}
      {...rest}
      ref={ref}
    />
  );

  return (
    <div className={`ikt-ap_input-container ${containerClassName ? containerClassName : ''}`}>
      { errorTooltip && error && (
        <ErrorTooltip title={errorMsg}>
          { inputField }
        </ErrorTooltip>
      )}
      { (!errorTooltip || !error) && inputField }
      { !errorTooltip && errorMsg && <span className={`ikt-ap_input-error-msg ${disableErrorFloat ? 'error-msg-no-float' : 'error-msg-float'} ${disableErrorPadding ? 'error-msg-no-padding' : 'error-msg-padding'}`}>{errorMsg}</span>}
    </div>
  )
});

export default CustomInput;

