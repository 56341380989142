import React from 'react';
import ReactDOM from 'react-dom';
import RIEBase from './RIEBase';
import Input from '../Input/Input';

const debug = require('debug')('RIEStatefulBase');

export default class RIEStatefulBase extends RIEBase {
    constructor(props){
        super(props);
    }

    startEditing = () => {
        debug('startEditing')
        if (this.props.beforeStart) this.props.beforeStart();
        if (this.props.isDisabled) return;
        this.setState({editing: true});
        if (this.props.afterStart) this.props.afterStart();
    };

    finishEditing = () => {
        debug('finishEditing')
        if (this.props.beforeFinish) this.props.beforeFinish();
        let newValue = ReactDOM.findDOMNode(this.refs.input).value;
        const result = this.doValidations(newValue);
        if(result && this.props.value !== newValue) {
            this.commit(newValue);
        }
        if(!result && this.props.handleValidationFail) {
            this.props.handleValidationFail(result, newValue, () => this.cancelEditing());
        } else {
            this.cancelEditing();
        }
        if (this.props.afterFinish) this.props.afterFinish();
    };

    cancelEditing = () => {
        debug('cancelEditing')
        this.setState({editing: false, invalid: false});
    };

    keyDown = (event) => {
        debug('keyDown(${event.keyCode})')
        if(event.keyCode === 13) { this.finishEditing() }           // Enter
        else if (event.keyCode === 27) { this.cancelEditing() }     // Escape
    };

    textChanged = (event) => {
        debug('textChanged(${event.target.value})')
        this.doValidations(event.target.value.trim());
    };

    componentDidUpdate = (prevProps, prevState) => {
        debug(`componentDidUpdate(${prevProps}, ${prevState})`)
        var inputElem = ReactDOM.findDOMNode(this.refs.input);
        debug(inputElem)
        if (this.state.editing && !prevState.editing) {
            debug('entering edit mode')
            inputElem.focus();
            this.selectInputText(inputElem);
        } else if (this.state.editing && prevProps.text != this.props.text) {
            debug('not editing && text not equal previous props -- finishing editing')
            this.finishEditing();
        }
    };

    renderEditingComponent = () => {
        debug('renderEditingComponent()')
        return <Input
            disabled={this.state.loading}
            containerClassName={this.makeClassString()}
            defaultValue={this.props.value}
            onInput={this.textChanged}
            onBlur={this.elementBlur}
            ref="input"
            onKeyDown={this.keyDown}
            error={this.props.error}
            {...this.props.editProps} />;
    };

    renderNormalComponent = () => {
        debug('renderNormalComponent');
        const styleObj = {};
        if (this.props.error) {
            styleObj['borderBottom'] = "1px solid red";
        }
        return <span
            tabIndex="0"
            className={this.makeClassString()}
            onFocus={this.startEditing}
            onClick={this.startEditing}
            style={styleObj}
            {...this.props.defaultProps}>{/*this.state.newValue ||*/ this.props.previewValue ? this.props.previewValue : this.props.value}</span>; /* we will always show the provided value */
    };

    elementBlur = (event) => {
        debug(`elementBlur(${event})`)
        this.finishEditing();
    };

    elementClick = (event) => {
        debug(`elementClick(${event})`)
        this.startEditing();
        event.target.element.focus();
    };

    render = () => {
        debug('render()')
        if(this.state.editing) {
            return this.renderEditingComponent();
        } else {
            return this.renderNormalComponent();
        }
    };
}
