import moment from 'moment';

import BaseAPIService from './Base';

export default class OrdersAPIService extends BaseAPIService {
  getOrders = async ({isOrder, searchTerms}) => {
    return this.request(`api/orders/own${isOrder ? '?filter[type]=0' : '?filter[type]=1'}${searchTerms ? ('&search-term=' + searchTerms) : ''}`).then((data) => {
      return data;
    });
  };

  getClientInquiries = async ({customer_id}) => {
    return this.request(`api/orders/own?filter[type]=1${customer_id !== '' ? `&filter[customer_id]=${customer_id}` : ''}`).then((data) => {
      return data;
    });
  };

  getOrder = async (id) => {
    return this.request(`api/orders/own/${id}`).then((data) => {
      return data;
    });
  };

  getOrderSettings = async (id) => {
    return this.request(`api/order-settings/${id}`).then((data) => {
      return data;
    });
  };

  updateOrderSettings = async (id, data) => {
    return await this.request(`api/order-settings/${id}/${data.name}`, {
      method: 'PUT',
      body: JSON.stringify({value: data.value})
    });
  };

  getOrderTotalCost = async (id) => {
    return await this.request('api/total-cost', {
      method: 'POST',
      body: JSON.stringify({order_id: id})
    });
  };

  createOrder = async (data) => {
    return await this.request('api/orders/own', {
      method: 'POST',
      body: data
    });
  };

  duplicateOrder = async (id) => {
    return await this.request(`api/orders/${id}/duplicate`, {
      method: 'PUT',
    });
  };

  deleteOrder = async (id) => {
    return await this.request(`api/orders/${id}`, {
      method: 'DELETE',
    });
  };

  updateOrder = async (id, data) => {
    return await this.request(`api/orders/own/${id}`, {
      method: 'POST',
      body: data
    });
  };

  validateOrders = async (data) => {
    return await this.request('api/orders/own/validate', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  validatePositions = async (data) => {
    return await this.request('api/orders/own/positions/validate', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  validateImportedPositionFile = async (data) => {
    return this.request('api/import/positions/validate', {
      method: 'POST',
      body: data
    });
  }

  startImportOrderPositionFile = async (data) => {
    const configData = {
      background: 0,
      config: {
        parse_only: 1
      }
    };
    return await this.request(`api/import/positions/start/${data.processId}`, {
      method: 'POST',
      body: JSON.stringify(configData)
    });
  }

  validateOrderPositionTask = async (data) => {
    return await this.request('api/orders/own/position/task/validate', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  validateFittings = async (data) => {
    return await this.request('api/orders/own/positions/fittings/validate', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  validateCostCenters = async (data) => {
    return await this.request('api/orders/own/positions/cost-centers/validate', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  getOrderPositionDefaultCostCenters = async () => {
    return await this.request('api/manufacturers/provider/own/default-cost-centers?type=1').then((data) => {
      return data;
    });
  };

  orderPositionCalculation = async (data) => {
    return await this.request('api/orders/own/calculate/position', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  orderCostCenterCalculation = async (data) => {
    return await this.request('api/orders/own/calculate/position-cost-centers', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  setOrderStarred = async (id, starredValue) => {
    return await this.request(`api/orders/own/${id}/starred/${starredValue}`, {
      method: 'PUT'
    });
  };

  setOrderStatus = async (id, value) => {
    return await this.request(`api/orders/own/${id}/status/${value}`, {
      method: 'PUT'
    });
  };

  setOrderDeadLine = async (id, value) => {
    return await this.request(`api/orders/own/${id}/due-date/${value}`, {
      method: 'PUT'
    });
  };

  filterOrders = async (filters, searchTerms) => {
    let filterStatus = [];
    if (filters.status.length > 0 && filters.status[0] != -1) {
      for (let i in filters.status) {
        filterStatus.push(`filter[status][${i}]=${filters.status[i]}`);
      }
    }
    const filterStatusPart = filterStatus.length > 0 ? `&${filterStatus.join("&")}` : '';
    let filtersCreatedDatePart = '';
    const createdDateFrom = filters.created_from ? (moment(filters.created_from).format('YYYY-MM-DD') + " 00:00:00"): null;
    const createdDateTo = filters.created_to ? (moment(filters.created_to).format('YYYY-MM-DD') + " 23:59:59"): null;
    if (createdDateFrom && createdDateTo) {
      filtersCreatedDatePart += `&filter[created_at][0]=>${createdDateFrom}&filter[created_at][1]=<${createdDateTo}`;
    } else if (createdDateFrom) {
      filtersCreatedDatePart += `&filter[created_at]=>${createdDateFrom}`;
    } else if (createdDateTo) {
      filtersCreatedDatePart += `&filter[created_at]=<${createdDateTo}`;
    }
    let filtersModifiedDatePart = '';
    const modifiedDateFrom = filters.modified_from ? (moment(filters.modified_from).format('YYYY-MM-DD') + " 00:00:00"): null;
    const modifiedDateTo = filters.modified_to ? (moment(filters.modified_to).format('YYYY-MM-DD') + " 23:59:59"): null;
    if (modifiedDateFrom && modifiedDateTo) {
      filtersModifiedDatePart += `&filter[updated_at][0]=>${modifiedDateFrom}&filter[updated_at][1]=<${modifiedDateTo}`;
    } else if (modifiedDateFrom) {
      filtersModifiedDatePart += `&filter[updated_at]=>${modifiedDateFrom}`;
    } else if (modifiedDateTo) {
      filtersModifiedDatePart += `&filter[updated_at]=<${modifiedDateTo}`;
    }
    let url = `?${filters.page && filters.page !== '' ? `page=${filters.page}` : '?'}${filters.order && filters.order !== '' ? `&order=${!filters.orderDir ? '-' : ''}${filters.order}` : ''}${(filters.type !== undefined && filters.type !== null && filters.type !== '') ? `&filter[type]=${filters.type}` : ''}${filters.customer_id && filters.customer_id !== '' ? `&filter[customer_id]=${filters.customer_id}` : ''}${filters.origin_type && filters.origin_type !== '' ? `&filter[origin_type]=${filters.origin_type}` : ''}${filterStatusPart}${filters.starred && filters.starred !== '' ? `&filter[is_starred]=${filters.starred}` : ''}${filtersCreatedDatePart}${filtersModifiedDatePart}${searchTerms ? ('&search-term=' + searchTerms) : ''}`;
    return this.request(`api/orders/own${url}`).then((data) => {
      return data;
    });
  };
}
