import {ofType} from 'redux-observable';
import {from, of, concat, merge} from 'rxjs';
import {switchMap, mergeMap, catchError, endWith} from 'rxjs/operators';
import {invoicesServiceApi} from 'services/API';
import NotificationService from '../services/NotificationService';
import {
  getInvoices,
  getInvoicesSuccess,
  getInvoicesFailure,
  getAllInvoices,
  getAllInvoicesSuccess,
  getAllInvoicesFailure,
  createInvoiceSuccess,
  createInvoiceFailure,
  updateInvoiceSuccess,
  updateInvoiceFailure,
  deleteInvoiceSuccess,
  deleteInvoiceFailure,
  setInvoiceStatusSuccess,
  setInvoiceStatusFailure,
  downloadInvoiceSuccess,
  downloadInvoiceFailure,
  previewInvoiceSuccess,
  previewInvoiceFailure,
} from 'store/actions/invoices';
import { getOrder } from 'store/actions/orders';
import {
  GET_INVOICES,
  GET_ALL_INVOICES,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  DELETE_INVOICE,
  SET_INVOICE_STATUS,
  DOWNLOAD_INVOICE,
  PREVIEW_INVOICE,
} from 'store/actionTypes/invoicesActionTypes';

export const getInvoicesEpic = (actions$) => actions$.pipe(
  ofType(GET_INVOICES),
  switchMap((action) => from(invoicesServiceApi.getInvoices(action.payload.order_id, action.payload.searchTerms, action.payload.filters, action.payload.sortBy, action.payload.sortByOrder)).pipe(
    switchMap((response) => {
      return of(getInvoicesSuccess(response));
    }),
    catchError(err => {
      return of(getInvoicesFailure(err.responseBody));
    })
  ))
);

export const getAllInvoicesEpic = (actions$) => actions$.pipe(
  ofType(GET_ALL_INVOICES),
  switchMap((action) => from(invoicesServiceApi.getAllInvoices(action.payload.searchTerms, action.payload.filters, action.payload.sortBy, action.payload.sortByOrder)).pipe(
    switchMap((response) => {
      return of(getAllInvoicesSuccess(response));
    }),
    catchError(err => {
      return of(getAllInvoicesFailure(err.responseBody));
    })
  ))
);

export const createInvoiceEpic = (actions$) => actions$.pipe(
  ofType(CREATE_INVOICE),
  switchMap((action) => from(invoicesServiceApi.createInvoice(action.payload.orderId, action.payload.data)).pipe(
    switchMap((response) => {
      NotificationService.success('Invoice was created.');
      return concat(
        of(getOrder(action.payload.orderId)),
        of(getInvoices(action.payload.orderId, action.payload.loadListInfo.searchTerms, action.payload.loadListInfo.filters, action.payload.loadListInfo.sortBy, action.payload.loadListInfo.sortByOrder)),
        of(createInvoiceSuccess(response)),
      );
    }),
    catchError(err => {
      if (err.responseBody && typeof err.responseBody.error === "string") {
        NotificationService.error(err.responseBody.error);
      } else if (Object.keys(err.responseBody.errors).length === 1 && Boolean(err.responseBody.errors.items)){
        NotificationService.error(err.responseBody.errors.items[0]);
      }
      return of(createInvoiceFailure(err.responseBody));
    })
  ))
);

export const updateInvoiceEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_INVOICE),
  switchMap((action) => from(invoicesServiceApi.updateInvoice(action.payload.invoiceId, action.payload.data)).pipe(
    switchMap((response) => {
      NotificationService.success('Invoice was successfully updated.');
      return action.payload.orderId !== null && action.payload.orderId !== undefined ?
        concat(
          of(getOrder(action.payload.orderId)),
          of(getInvoices(action.payload.orderId, action.payload.loadListInfo.searchTerms, action.payload.loadListInfo.filters, action.payload.loadListInfo.sortBy, action.payload.loadListInfo.sortByOrder)),
          of(updateInvoiceSuccess(response)),
        ) :
        concat(
          of(getAllInvoices(action.payload.loadListInfo.searchTerms, action.payload.loadListInfo.filters, action.payload.loadListInfo.sortBy, action.payload.loadListInfo.sortByOrder)),
          of(updateInvoiceSuccess(response)),
        );
    }),
    catchError(err => {
      if (err.responseBody && typeof err.responseBody.error === "string") {
        NotificationService.error(err.responseBody.error);
      } else if (Object.keys(err.responseBody.errors).length === 1 && Boolean(err.responseBody.errors.items)){
        NotificationService.error(err.responseBody.errors.items[0]);
      }
      return of(updateInvoiceFailure(err.responseBody));
    })
  ))
);

export const deleteInvoiceEpic = (actions$) => actions$.pipe(
  ofType(DELETE_INVOICE),
  switchMap((action) => from(invoicesServiceApi.deleteInvoice(action.payload.invoiceId)).pipe(
    switchMap((response) => {
      return action.payload.orderId !== null && action.payload.orderId !== undefined ?
        concat(
          of(getOrder(action.payload.orderId)),
          of(getInvoices(action.payload.orderId, action.payload.loadListInfo.searchTerms, action.payload.loadListInfo.filters, action.payload.loadListInfo.sortBy, action.payload.loadListInfo.sortByOrder)),
          of(deleteInvoiceSuccess()),
        ) :
        concat(
          of(getAllInvoices(action.payload.loadListInfo.searchTerms, action.payload.loadListInfo.filters, action.payload.loadListInfo.sortBy, action.payload.loadListInfo.sortByOrder)),
          of(deleteInvoiceSuccess()),
        );
    }),
    catchError(err => {
      return of(deleteInvoiceFailure(err.responseBody));
    })
  ))
);

export const setInvoiceStatusEpic = (actions$) => actions$.pipe(
  ofType(SET_INVOICE_STATUS),
  switchMap((action) => from(invoicesServiceApi.setInvoiceStatus(action.payload.invoiceId, action.payload.statusId)).pipe(
    switchMap((response) => {
      return concat(
        action.payload.orderId !== null && action.payload.orderId !== undefined ?
          of(getInvoices(action.payload.orderId, action.payload.loadListInfo.searchTerms, action.payload.loadListInfo.filters, action.payload.loadListInfo.sortBy, action.payload.loadListInfo.sortByOrder)) :
          of(getAllInvoices(action.payload.loadListInfo.searchTerms, action.payload.loadListInfo.filters, action.payload.loadListInfo.sortBy, action.payload.loadListInfo.sortByOrder)),
        of(setInvoiceStatusSuccess(response)),
      );
    }),
    catchError(err => {
      return of(setInvoiceStatusFailure(err.responseBody));
    })
  ))
);

export const downloadInvoiceEpic = (actions$) => actions$.pipe(
  ofType(DOWNLOAD_INVOICE),
  switchMap((action) => {
    let service = null;
    if (action.payload.format == 1) {
      service = invoicesServiceApi.downloadInvoicePDF(action.payload.id);
    } else if (action.payload.format == 2) {
      service = invoicesServiceApi.downloadInvoiceOpenDocument(action.payload.id);
    } else if (action.payload.format == 3) {
      service = invoicesServiceApi.downloadInvoiceOTF(action.payload.id);
    }
    if (service) {
      return from(service).pipe(
        switchMap((response) => {
          return of(downloadInvoiceSuccess(response));
        }),
        catchError(err => {
          return of(downloadInvoiceFailure(err.responseBody));
        })
      );
    }
    return null;
  })
);

export const previewInvoiceEpic = (actions$) => actions$.pipe(
  ofType(PREVIEW_INVOICE),
  switchMap((action) => {
    return from(invoicesServiceApi.previewInvoice(action.payload.data)).pipe(
      switchMap((response) => {
        return of(previewInvoiceSuccess(response));
      }),
      catchError(err => {
        return of(previewInvoiceFailure(err.responseBody));
      })
    );
  })
);
