import React from 'react';
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import {orderPositionCalculation} from 'store/actions/orders';
import {getPositionMaterialsList} from 'store/actions/materialProviders';
import {getTaskUnitList} from "../../../store/actions/providers";

import RefreshIcon from '../../../assets/img/icon-restore.svg';
import IconButton from "@material-ui/core/IconButton";
import Checkbox from 'components/StyledCheckbox';
import Button from 'components/CustomButtons/Button.jsx';
import DatePicker from "../../../components/CustomDatePicker";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Input from 'components/Input/Input';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RemoveDialog from '../../RemoveDialog';
import Description from '../../EditDescription/Description';
import DecimalInput from "../../../components/DecimalInput";
import Dropdown from "../../../components/CustomDropdown/CustomDropdown";
import ConfirmDialog from '../../ConfirmDialog';
import RIEInput from '../../../components/RIEInput';

import './style.scss';
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputWithIcon from "../../../components/InputWithIcon";
import DescriptionIcon from "../../../assets/img/inputIcons/btn-edit-textinput.svg";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
import Autocomplete from "../../../components/Autocomplete";

import { formatNumericValue } from "utils/utils";

import {debounce} from 'lodash';

class Position extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: false,
      isRemovePositionOpen: false,
      isDescriptionOpen: false,
      isManualPriceOpen: false,
      overrideConfirmed: false,
      priceOverriden: false,
      manualPrice: '',
      costsData: {},
      isWideView: false,
    };
  }

  componentDidMount() {
    this.props.getUnitsList();
    const { id, isCardOpen, data, fittings, orderPositionCalculation } = this.props;
    const stateUpdate = {};
    if (isCardOpen) {
      stateUpdate['isCardOpen'] = true;
    }
    const mediaMatcher = window.matchMedia("(min-width: 1150px)");
    stateUpdate['isWideView'] = mediaMatcher.matches;
    mediaMatcher.addListener(e => {
      this.setState({ isWideView: mediaMatcher.matches });
    });
    if (!data.unit_price){
      orderPositionCalculation({ id: id, data: { tasks: data.tasks, fittings: fittings, amount: data.amount, metric: data.metric }  });
    } else {
      if (data.tasks && data.tasks.length > 0) {
        stateUpdate['overrideConfirmed'] = true;
        stateUpdate['priceOverriden'] = true;
      }
      this.manualPositionCalculation(data);
    }
    this.setState(stateUpdate);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const stateUpdate = {};
    if (!prevProps.isCardOpen && this.props.isCardOpen && !this.state.isCardOpen) {
      stateUpdate['isCardOpen'] = true;
    }
    if (prevProps.data !== this.props.data) {
      const data = this.props.data;
      if ((!data.tasks || data.tasks.length === 0) && this.state.priceOverriden) {
        stateUpdate['overrideConfirmed'] = false;
        stateUpdate['priceOverriden'] = false;
      }
    }

    if (Object.keys(stateUpdate).length > 0) {
      this.setState(stateUpdate);
    }
  }

  handleInputChange = (fieldName, value) => {
    const { id, data, fittings, onDataChange, orderPositionCalculation } = this.props;
    const newData = { ...data };
    newData[fieldName] = value;
    if (fieldName === 'type') {
      if (value === 0 && data['type'] !== 0) {
        newData['material_id'] = null;
        newData['name'] = '';
        newData['unit_price'] = '';
      } else if (value === 1) {
        newData['material_id'] = null;
      }
    }
    onDataChange(newData);

    const handleOverridenPriceChange = () => {
      if(data.tasks && data.tasks.length !== 0 && !this.state.overrideConfirmed) {
        this.toggleManualWarningWindow(newData.unit_price);
      } else {
        this.manualPositionCalculation(newData);
      }
    };
    if (fieldName === 'unit_price' && newData.unit_price) {
      handleOverridenPriceChange();
    } else if (fieldName === 'amount') {
      if (newData.unit_price) {
        handleOverridenPriceChange();
      } else {
        orderPositionCalculation({ id: id, data: { tasks: data.tasks, fittings: fittings, amount: newData.amount, metric: newData.metric } });
      }
    }
  };

  handleInputChangeMultiple = (obj) => {
    // careful with usages of this; don't want to bypass some behavior of the original
    const { data, onDataChange } = this.props;
    const newData = { ...data, ...obj };
    onDataChange(newData);
  };

  toggleManualWarningWindow = (manualPrice) => {
    this.toggleConfirmManualOverride(manualPrice);
  }

  toggleConfirmManualOverride = debounce( (manualPrice) => {
      this.setState({
        isManualPriceOpen: true,
        manualPrice: manualPrice
      })
  }, 250);

  closeManualPriceConfirmationDialog = () => {
      this.setState({
        isManualPriceOpen: false,
        overrideConfirmed: false
      })
  }

  handlePriceOverride = () => {
    const {data} = this.props;
    this.manualPositionCalculation(data);
    this.setState({
      isManualPriceOpen: false,
      overrideConfirmed: true,
      priceOverriden: true,
    })
  }

  handleAmountInputChange = (name, value) => {
    if(value >= 0) {
      this.handleInputChange(name, value)
  }
  };

  handleRefreshPrice = () => {
    const { id, data, fittings, onDataChange, orderPositionCalculation } = this.props;
    const objectData = {
      tasks: data.tasks,
      fittings: fittings,
    };
    if (data.amount) {
      objectData.amount = data.amount;
    }
    if (data.metric) {
      objectData.metric = data.metric;
    }
    this.setState({
      overrideConfirmed: false,
      priceOverriden: false,
    });
    const newData = {
      ...data,
      unit_price: '',
    };
    onDataChange(newData);
    orderPositionCalculation({ id: id, data: objectData });
  }

  manualPositionCalculation = (data) => {
    const {id, fittings, orderPositionCalculation } = this.props;
    if (data.unit_price) {
      const objectData = {
        amount: data.amount, 
        unit_price: data.unit_price, 
        tasks: data.tasks,
        fittings: fittings,
      };
      if (data.metric) {
        objectData.metric = data.metric;
      }
      orderPositionCalculation({ id: id, data: objectData });
    }
  }

  addNewPositionTask = taskData => {
    const { id, data, fittings, orderPositionCalculation } = this.props;
    const newTasks = [...data.tasks, taskData];
    const objectData = {
      tasks: newTasks,
      fittings: fittings,
    }
    if (data.amount) {
      objectData.amount = data.amount;
    }
    if (data.metric) {
      objectData.metric = data.metric;
    }
    if (!data.unit_price) {
      orderPositionCalculation({ id: id, data: objectData });
    } else if (!this.state.priceOverriden) {
      this.setState({
        priceOverriden: true,
      });
    }
    this.handleInputChange('tasks', newTasks);
  };

  removePositionTask = idx => {
    const { id, data, fittings, orderPositionCalculation } = this.props;
    const newTasks = [...data.tasks].filter((item, index) => index !== idx);
    const objectData = {
      tasks: newTasks,
      fittings: fittings,
    }
    if (data.amount) {
      objectData.amount = data.amount;
    }
    if (data.metric) {
      objectData.metric = data.metric;
    }
    orderPositionCalculation({ id: id, data: objectData});
    this.handleInputChange('tasks', newTasks);
  };

  handleTaskDataChange = (idx, taskData) => {
    const { id, data, fittings, orderPositionCalculation } = this.props;
    let newTasks = [...data.tasks];
    newTasks[idx] = taskData;
    const objectData = {
      tasks: newTasks,
      fittings: fittings
    }
    if (data.amount) {
      objectData.amount = data.amount;
    }
    if (data.metric) {
      objectData.metric = data.metric;
    }
    if(!data.unit_price){
      orderPositionCalculation({ id: id, data: objectData });
    }
    this.handleInputChange('tasks', newTasks);
  };

  onCardClose = () => {
    this.setState({ isCardOpen: !this.state.isCardOpen });
  };

  handleAddSubposition = () => {
    this.props.onAddSubposition(this.props.id);
  }

  handleRemoveDialogOpen = () => {
    this.setState({ isRemovePositionOpen: true });
  };

  handleRemoveDialogClose = () => {
    this.setState({ isRemovePositionOpen: false });
  };

  handleToggleDescription = () => {
    this.setState({ isDescriptionOpen: !this.state.isDescriptionOpen });
  };

  handleDescriptionKeyDown = (event) => {
    if (event.keyCode == 16 || event.keyCode == 9) { // ignore shift and tab keys
      return;
    } else {
      this.handleOpenDescription();
    }
  }

  handleOpenDescription = () => {
    if (!this.state.isDescriptionOpen)
      this.setState({ isDescriptionOpen: true });
  }

  render() {
    const { data, errors, coworkers, costs, positionMaterials, isSelected, onSelectionChange, onRearrangeSubposition, getPositionMaterialsList, id, currencySymbol, t } = this.props;
    const {
      isCardOpen,
      isRemovePositionOpen,
      isDescriptionOpen,
      isManualPriceOpen,
      priceOverriden,
    } = this.state;
    const coveringsCost =
      costs[id] && costs[id].coverings_cost
        ? costs[id].coverings_cost
        : 0;
    const edgesCost =
      costs[id] && costs[id].edges_cost
        ? costs[id].edges_cost
        : 0;
    const surfaceCost =
      costs[id] && costs[id].surfaces_cost
        ? costs[id].surfaces_cost
        : 0;
    let totalPrice = costs[id] && costs[id].material_total ? costs[id].material_total : data.unit_price ? data.unit_price : 0;
    const positionTypeDropdown = [{id: 0, value: t('position')}, {id: 1, value: t('article')}];
    let positionMaterialsActual = positionMaterials.map(el => ({
      id: el.id,
      name: el.name,
      value: el.code,
      code: el.code,
      price: el.price,
      metric: el.metric,
      description: el.description
    }));
    if (data.type === 1 && data.material_id && !positionMaterialsActual.find(el => el.id === data.material_id)) {
      positionMaterialsActual.push({ id: data.material_id, value: data.code });
    }
    let numericName = data.numeric_name;
    if (!numericName)
      numericName = '';
    if (numericName.length > 8) {
      numericName = numericName.substring(0, 5);
      numericName += (numericName[numericName.length - 1] != '.') ? '...' : '..';
    }
    const validateNumericName = (value) => {
      if (value) {
        return /^\d+(\.\d*)*$/.test(value);
      } else {
        return true;
      }
    };
    return (
      <div
        className="material-list-position-content"
      >
        <div
          className={`material-list-position-info ${
            isCardOpen ? 'bottom-borders' : ''
          } ${
            data.is_alternative ? 'alternative-position' : ''
          }`}
        >
          <div className="material-list-position-info-container">
            <div className="material-list-position-info-title">
              <div className="position-title">
                <Checkbox
                  checked={isSelected}
                  onChange={data.can_edit ? onSelectionChange : null}
                  color="primary"
                />
                <RIEInput
                  value={data.numeric_name ? data.numeric_name : ''}
                  previewValue={numericName ? numericName : '\u00A0'}
                  className='rie-preview'
                  classEditing='rie-input-edit'
                  change={onRearrangeSubposition}
                  propName='value'
                  validate={validateNumericName}
                  defaultProps={numericName !== data.numeric_name ? { title: data.numeric_name } : null}
                  isDisabled={!data.can_edit}
                  editProps={{ disabled: false }}
                  error={errors && errors['numeric_name']}
                />
              </div>
              <div className="position-inputs">
                <div className='position-input-item title-outer-container'>
                  <Input
                    onChange={e =>
                      this.handleInputChange('title', e.target.value)
                    }
                    value={data.title ? data.title : ''}
                    readOnly={!data.can_edit}
                    error={errors && errors['title']}
                  />
                </div>
                <div className='position-input-item'>
                  <Dropdown
                    buttonText={data.type}
                    buttonProps={{className: 'dropdown-blue-style position-type-dropdown', round: true, size: "sm"}}
                    dropdownList={positionTypeDropdown}
                    hoverColor="dark"
                    disabled={!data.can_edit}
                    onClick={(value) => this.handleInputChange('type', value.id)}
                  />
                </div>
                <div className='position-input-item alternative-checkbox-container'>
                  <Checkbox
                    checked={data.is_alternative === 1}
                    onChange={e => data.can_edit ? this.handleInputChange('is_alternative', data.is_alternative ? 0 : 1) : null }
                    color="primary"
                  />{ t('alternative') }
                </div>
              </div>
              <div className="position-total-price">
                <div className="price">
                  <span className='price-title'>{ t('edges') }</span>
                  <span className="price-value">{`${formatNumericValue(edgesCost, 2)} ${currencySymbol}`}</span>
                </div>
                <div className="price">
                  <span className='price-title'>{ t('surface') }</span>
                  <span className="price-value">{`${formatNumericValue(surfaceCost, 2)} ${currencySymbol}`}</span>
                </div>
                <div className="price">
                  <span className='price-title'>{ t('coverings') }</span>
                  <span className="price-value">{`${formatNumericValue(coveringsCost, 2)} ${currencySymbol}`}</span>
                </div>
                <div className="price">
                  <span className='price-title'>{ t('total_price') }</span>
                    <span className={`price-total ${priceOverriden ? 'price-override' : ''}`}>{`${formatNumericValue(parseFloat(totalPrice), 2)} ${currencySymbol}`}</span>
                </div>
                <IconButton
                    aria-label="close"
                    className="action-btn refresh-price-btn"
                    disabled={!data.can_edit}
                    onClick={() => this.handleRefreshPrice()}
                  >
                      <img className='refresh-icon' alt="refresh-price-btn" src={RefreshIcon}/>
                  </IconButton>
              </div>
            </div>
            <div className="position-info-inputs">
                { data.type == 0 ?
                  (<div className="position-input-item name-input">
                    <span>{ t('name') }</span>
                    <Input
                      className={'position-name-input'}
                      onChange={e =>
                        this.handleInputChange('name', e.target.value)
                      }
                      value={data.name}
                      readOnly={!data.can_edit}
                      error={errors && errors['name']}
                    />
                  </div>) :
                  (<div className="position-input-item material-input">
                    <span>{ t('material') }</span>
                    <CustomAutocomplete
                      buttonText={data.material_id ? data.material_id : ''}
                      buttonProps={{className: '', round: true, size: "sm", disabled: !data.can_edit}}
                      className={'autocomplete-material'}
                      discardIcon={true}
                      dropdownList={positionMaterialsActual}
                      hoverColor="dark"
                      error={errors && (errors['material_id'] || errors['name'])}
                      getData={(value) => getPositionMaterialsList(value)}
                      onClick={(value) => {
                        if (value.id) {
                          const updatePrice = positionMaterials.find(el => el.id === value.id).price;
                          const metricId = this.props.unitsList.find(el => el.value == value.metric).id;
                          const updateData = {
                            'material_id': value.id,
                            'name': value.value,
                            'unit_price': updatePrice,
                            'metric': metricId,
                            'description': value.description ? value.description : '',
                          }
                          this.handleInputChangeMultiple(updateData);
                        }
                      }}
                    />
                  </div>)
                }
                <div className='amount-input'>
                <span className='position-input-span'>{ t('amount') }</span>
                  <Input
                    className={'position-name-input'}
                    onChange={e =>
                      this.handleAmountInputChange('amount', e.target.value)
                    }
                    value={data.amount || ''}
                    readOnly={!data.can_edit}
                    type={'number'}
                    error={errors && errors['amount']}
                  />
                </div>
                <div className='metrics-input'>
                <span className='position-input-span'>{ t('metrics') }</span>
                  <Dropdown
                    buttonText={data.metric || ''}
                    buttonProps={{className: 'dropdown-blue-style position-metrics-dropdown', round: true, size: "sm"}}
                    dropdownList={this.props.unitsList}
                    error={errors && errors['data.metrics']}
                    disabled={!data.can_edit}
                    hoverColor="dark"
                    onClick={(value) => this.handleInputChange('metric', value.id)}
                  />
                  </div>
                  <div className='price-input'>
                  <span className="item-title position-input-span">{ t('price') }</span>
                  {/* <InputWithIcon
                     className="position-rate"
                     error={errors && errors.rate}
                     value={data.unit_price}
                     onChange={(value) => this.handleInputChange('unit_price',value)}
                    type={'number'} 
                    endAdornment={<InputAdornment position="end">{ currencySymbol }</InputAdornment>}
                  /> */}
                  <DecimalInput
                    className="position-rate"
                    error={errors && errors.rate}
                    placeholder={t('enter')}
                    disabled={!data.can_edit}
                    value={data.unit_price}
                    onChange={(value) => this.handleInputChange('unit_price',value)}
                    adornment={currencySymbol}
                  />
                </div>
                <div className="position-input-item assignee-input">
                  <span className='position-input-span'>{ t('assignee') }</span>
                  <Autocomplete
                    buttonText={data.coworker_id ? data.coworker_id : ''}
                    error={errors && errors.coworker_id}
                    buttonProps={{ className: '', round: true, size: 'sm', disabled: !data.can_edit }}
                    dropdownList={coworkers}
                    hoverColor="dark"
                    onClick={(value) => this.handleInputChange('coworker_id', value.id)}
                  />
                </div>
                <div className="position-input-item position-date-picker">
                  <span className='position-input-span'>{ t('deadline') }</span>
                  <DatePicker
                    readOnly={!data.can_edit}
                    handleChange={date =>
                      this.handleInputChange(
                        'due_date',
                        moment(date).format('YYYY-MM-DD')
                      )
                    }
                    selectedDate={data.due_date}
                  />
                </div>
                <div
                  className="position-input-item description-input"
                  onKeyDown={data.can_edit ? this.handleDescriptionKeyDown : null}
                  onClick={data.can_edit ? this.handleOpenDescription : null}
                >
                  <span className='position-input-span'>{ t('description') }</span>
                  <InputWithIcon
                    value={data.description || ''}
                    error={errors && errors['description']}
                    readOnly={!data.can_edit}
                    endAdornment={
                      <InputAdornment position="end">
                        <img alt="description-img" src={DescriptionIcon} />
                      </InputAdornment>
                    }
                  />
                </div>
            </div>
          </div>
          <div className="card-btn-container">
            <div
              className={`card-btn ${isCardOpen ? 'bottom-borders' : ''}`}
              onClick={() => this.onCardClose()}
            >
              {isCardOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
          </div>
        </div>
        {isCardOpen && (
          <div className="position-info-content">
            <GridContainer className="position-btn-container">
              <GridItem className="position-btn-part" xs={4}>
                <Button
                  className="position-btn blue"
                  onClick={() => this.handleAddSubposition()}
                >
                  + { t('subposition').toUpperCase() }
                </Button>
              </GridItem>
              <GridItem className="position-btn-part delete-position-btn right-aligned" xs={4}>
                <Button
                  className="position-btn blue"
                  disabled={!data.can_delete}
                  onClick={this.handleRemoveDialogOpen}
                >
                  { t('delete_position').toUpperCase() } { `${numericName}` }
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        )}
        {isRemovePositionOpen && (
          <RemoveDialog
            isOpen={isRemovePositionOpen}
            title={t('remove_position')}
            text={t('remove_position_confirmation').replace('{position-no}', data.numeric_name)}
            onClose={this.handleRemoveDialogClose}
            onRemove={() => { this.props.onRemovePosition(); this.handleRemoveDialogClose(); }}
          />
        )}
        {isManualPriceOpen && (
         <ConfirmDialog
          title={t('add_price')}
          text={t('manual_pricing_overwrite')}
          onClose={this.closeManualPriceConfirmationDialog}
          onConfirm={this.handlePriceOverride}
          isOpen={isManualPriceOpen}
          />
        )
        }
        {isDescriptionOpen && (
          <Description
            isOpen={isDescriptionOpen}
            onClose={this.handleToggleDescription}
            value={data.description}
            onChange={(value) => this.handleInputChange('description', value) }
          />
        )}
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    costs: state.orders.orderPositionCosts,
    positionCost: state.orders.currentPositionCosts,
    unitsList: state.providers.taskUnitList,
    positionMaterials: state.materialProviders.positionMaterials,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUnitsList: () => dispatch(getTaskUnitList()),
    orderPositionCalculation: (data) => dispatch(orderPositionCalculation(data)),
    getPositionMaterialsList: (term) => dispatch(getPositionMaterialsList(term)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Position));
