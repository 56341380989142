import { toast } from 'react-toastify';
import {toastDuration, toastDurationLong} from 'Config';

/**
 * Notification Service is for showing the toasts on the right top corner of the screen
 */

export default class NotificationService {
    static setup() {

    }

    static success(message, long) {
        toast.success(message, {autoClose: !long ? toastDuration : toastDurationLong});
    }

    static warn(message, long) {
        toast.warn(message, {autoClose: !long ? toastDuration : toastDurationLong});
    }

    static error(message, long) {
        toast.error(message, {autoClose: !long ? toastDuration : toastDurationLong});
    }
}

NotificationService.setup();
