export const GET_INVOICES = 'invoices/GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'invoices/GET_INVOICES_SUCCESS';
export const GET_INVOICES_ERROR = 'invoices/GET_INVOICES_ERROR';

export const GET_ALL_INVOICES = 'invoices/GET_ALL_INVOICES';
export const GET_ALL_INVOICES_SUCCESS = 'invoices/GET_ALL_INVOICES_SUCCESS';
export const GET_ALL_INVOICES_ERROR = 'invoices/GET_ALL_INVOICES_ERROR';

export const CREATE_INVOICE = 'invoices/CREATE_INVOICE';
export const CREATE_INVOICE_SUCCESS = 'invoices/CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_ERROR = 'invoices/CREATE_INVOICE_ERROR';

export const UPDATE_INVOICE = 'invoices/UPDATE_INVOICE';
export const UPDATE_INVOICE_SUCCESS = 'invoices/UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_ERROR = 'invoices/UPDATE_INVOICE_ERROR';

export const DELETE_INVOICE = 'invoices/DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'invoices/DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'invoices/DELETE_INVOICE_ERROR';

export const SET_INVOICE_STATUS = 'invoices/SET_INVOICE_STATUS';
export const SET_INVOICE_STATUS_SUCCESS = 'invoices/SET_INVOICE_STATUS_SUCCESS';
export const SET_INVOICE_STATUS_ERROR = 'invoices/SET_INVOICE_STATUS_ERROR';

export const DOWNLOAD_INVOICE = 'invoices/DOWNLOAD_INVOICE';
export const DOWNLOAD_INVOICE_SUCCESS = 'invoices/DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_ERROR = 'invoices/DOWNLOAD_INVOICE_ERROR';

export const PREVIEW_INVOICE = 'invoices/PREVIEW_INVOICE';
export const PREVIEW_INVOICE_SUCCESS = 'invoices/PREVIEW_INVOICE_SUCCESS';
export const PREVIEW_INVOICE_ERROR = 'invoices/PREVIEW_INVOICE_ERROR';

export const RESET_INVOICE_DATA = 'invoices/RESET_INVOICE_DATA';
