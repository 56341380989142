export const GET_MANUFACTURERS_LOCATIONS = 'manufacturerProvidersEpic/GET_MANUFACTURERS_LOCATIONS';
export const GET_MANUFACTURERS_LOCATIONS_SUCCESS = 'manufacturerProvidersEpic/GET_MANUFACTURERS_LOCATIONS_SUCCESS';
export const GET_MANUFACTURERS_LOCATIONS_ERROR = 'manufacturerProvidersEpic/GET_MANUFACTURERS_LOCATIONS_ERROR';

export const GET_MANUFACTURERS_DELIVERY_TYPES = 'manufacturerProvidersEpic/GET_MANUFACTURERS_DELIVERY_TYPES';
export const GET_MANUFACTURERS_DELIVERY_TYPES_SUCCESS = 'manufacturerProvidersEpic/GET_MANUFACTURERS_DELIVERY_TYPES_SUCCESS';
export const GET_MANUFACTURERS_DELIVERY_TYPES_ERROR = 'manufacturerProvidersEpic/GET_MANUFACTURERS_DELIVERY_TYPES_ERROR';

export const GET_MANUFACTURERS_STATUSES = 'manufacturerProvidersEpic/GET_MANUFACTURERS_STATUSES';
export const GET_MANUFACTURERS_STATUSES_SUCCESS = 'manufacturerProvidersEpic/GET_MANUFACTURERS_STATUSES_SUCCESS';
export const GET_MANUFACTURERS_STATUSES_ERROR = 'manufacturerProvidersEpic/GET_MANUFACTURERS_STATUSES_ERROR';
