import BaseAPIService from './Base';

export default class GlobalsAPIService extends BaseAPIService {
  getMetrics = async () => {
    return this.request('api/global/metrics').then(data => {
      return data.data;
    });
  };

  getCurrency = async () => {
    return this.request('api/manufacturer-settings/own/currency').then(response => {
      return response.currency;
    });
  };
}
