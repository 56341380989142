import BaseAPIService from './Base';

export default class CoworkersAPIService extends BaseAPIService {
    createCoworker = async (data) => {
        return this.request('api/coworkers', {
            method: 'POST',
            body: JSON.stringify(data)
        });
    };

    updateCoworker = async (id, data) => {
        return this.request(`api/coworkers/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        });
    };

    deleteCoworker = async (id) => {
        return this.request(`api/coworkers/${id}`, {
            method: 'DELETE',
        });
    };

    getCoworker = async (id) => {
        return this.request(`api/coworkers/${id}`).then((data) => {
            return data;
        });
    };

    getCoworkers = async (page) => {
        return this.request(`api/coworkers?page=${page}`).then((data) => {
            return data;
        });
    };
}
