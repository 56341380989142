import React from 'react';
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CloseButton from 'assets/img/buttons/delete.svg';
import Dropdown from 'components/CustomDropdown/CustomDropdown';
import GridItem from 'components/Grid/GridItem';
import Input from 'components/Input/Input';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import DecimalInput from 'components/DecimalInput';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './style.scss';

class Position extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: true,
      isCreateTaskOpen: false,
      specialErrors: null,
    };
  }

  onCardClose = () => {
    this.setState({isCardOpen: !this.state.isCardOpen})
  };

  handleAddTaskClose = () => {
    this.setState({isCreateTaskOpen: !this.state.isCreateTaskOpen})
  };

  handleDataChange = (fieldName, value) => {
    const {data, onChange} = this.props;
    const newData = {...data};
    newData[fieldName] = value;
    onChange(newData)
  }

  handleItemsChange = (index, fieldName, value)  => {
    const { items } = this.props.data;
    const { specialErrors } = this.state;
    const newItems = [...items];
    newItems[index][fieldName] =  value;

    if (specialErrors && specialErrors[index]) {
      const newSpecialError = specialErrors[index];
      if (fieldName === 'title')
        newSpecialError['title'] = undefined;
      if (fieldName === 'amount')
        newSpecialError['amount'] = undefined;
      if (fieldName === 'measurement')
        newSpecialError['measurement'] = undefined;
      if (fieldName === 'rate')
        newSpecialError['rate'] = undefined;
      this.setState({
        specialErrors: {
          [index]: newSpecialError,
        },
      });
    }

    this.handleDataChange('items', newItems)
  };

  handleAddNewItem = () => {
    const { t } = this.props;
    const { items } = this.props.data;
    const { specialErrors } = this.state;

    if (items.length > 0) {
      const lastItem = items[items.length - 1];
      const errors = {};
      if (!lastItem.title)
        errors['title'] = [t('input_type')];
      if (!lastItem.amount)
        errors['amount'] = [t('input_amount')];
      if (!lastItem.measurement)
        errors['measurement'] = [t('select_measurement')];
      if (!lastItem.rate)
        errors['rate'] = [t('input_rate')];
      if (Object.keys(errors).length > 0) {
        this.setState({
          specialErrors: {
            [items.length - 1]: errors,
          },
        });
        return;
      } else {
        if (specialErrors) {
          this.setState({
            specialErrors: null,
          });
        }
      }
    } else if (specialErrors) {
      this.setState({
        specialErrors: null,
      });
    }

    const newItems = [...items, {title: '', amount: '', measurement: '', sort_no: items.length + 1, rate: ''}];
    this.handleDataChange('items', newItems)
  };

  handleRemoveItem = (id) => {
    const {items} = this.props.data;
    const { specialErrors } = this.state;
    const data = [...items].filter((item, index) => index !== id );
    if (specialErrors && specialErrors[id]) {
      this.setState({
        specialErrors: null,
      });
    }
    this.handleDataChange('items', data);
  };

  renderType = (type, index) => {
    const { errors, units, modifiable, currencySymbol, t } = this.props;
    const { specialErrors } = this.state;
    let typeErrors = errors && errors.items && errors.items[index];
    if (!typeErrors && specialErrors && specialErrors[index])
      typeErrors = specialErrors[index];
    return (
      <GridItem
        className="cost-position-type-inputs"
        key={index}
      >
        <GridItem className="cost-position-type-input-container" xs={6}>
          <Input
            className="without-right-borders"
            value={type.title}
            onChange={(e) => this.handleItemsChange(index,'title', e.target.value)}
            error={typeErrors && typeErrors['title']}
            readOnly={!modifiable}
          />
        </GridItem>
        <GridItem className="cost-position-type-input-container" xs={2}>
          <Input
            className="without-borders"
            value={type.amount}
            onChange={(e) => this.handleItemsChange(index,'amount', e.target.value)}
            error={typeErrors && typeErrors['amount']}
            readOnly={!modifiable}
          />
        </GridItem>
        <GridItem className="cost-position-type-input-container" xs={2}>
          <Dropdown
            buttonText={units.length !== 0 ? type.measurement : ''}
            buttonProps={{className: 'dropdown-blue-style dropdown-without-borders', round: true, size: "sm"}}
            dropdownList={units}
            hoverColor="dark"
            onClick={(value) => this.handleItemsChange(index,'measurement', value.id)}
            error={typeErrors && typeErrors['measurement']}
            readOnly={!modifiable}
          />
        </GridItem>
        <GridItem className="cost-position-type-input-container" xs={2}>
          <DecimalInput
            className="without-borders"
            value={type.rate}
            onChange={(value) => this.handleItemsChange(index,'rate', value)}
            error={typeErrors && typeErrors['rate']}
            disabled={!modifiable}
            adornment={currencySymbol}
          />
        </GridItem>
        <GridItem className="cost-position-type-input-container" xs={2}>
          <DecimalInput
            className="without-left-borders"
            value={type.amount && type.rate ? (type.amount * type.rate).toFixed(2) : ''}
            disabled={!modifiable}
            adornment={currencySymbol}
          />
        </GridItem>
        { modifiable && (
          <IconButton
            aria-label="close"
            className="type-delete-btn"
            disabled={!modifiable}
            onClick={() => this.handleRemoveItem(index)}
          >
            <img
              src={CloseButton}
              alt='cost-type-delete-btn'
            />
          </IconButton>
        )}
      </GridItem>
    )
  };

  render() {
    const {
      data,
      units,
      errors,
      removeCostCenter,
      modifiable,
      currencySymbol,
      t
    } = this.props;
    const {isCardOpen} = this.state;
    return (
      <div className="cost-position-type-content">
        <div className="cost-position-type-container">
          <GridItem className="cost-position-types">
            <GridItem className="cost-position-type-titles">
              <GridItem xs={6}><span>{ t('type') }</span></GridItem>
              <GridItem xs={2}><span>{ t('amount') }</span></GridItem>
              <GridItem xs={2}><span>{ t('me') }</span></GridItem>
              <GridItem xs={2}><span>{ t('rate') }</span></GridItem>
              <GridItem xs={2}><span>{ t('total') }</span></GridItem>
            </GridItem>
            <GridItem className="cost-position-type-inputs">
              <GridItem className="cost-position-type-input-container" xs={6}>
                <Input
                  autofocus={true}
                  className="without-right-borders"
                  value={data.title}
                  onChange={(e) => this.handleDataChange('title', e.target.value)}
                  readOnly={!modifiable}
                  error={errors && errors['title']}
                />
              </GridItem>
              <GridItem className="cost-position-type-input-container" xs={2}>
                <Input
                  className="without-borders"
                  value={data.amount}
                  onChange={(e) => this.handleDataChange('amount', e.target.value)}
                  readOnly={!modifiable}
                  error={errors && errors['amount']}
                />
              </GridItem>
              <GridItem className="cost-position-type-input-container" xs={2}>
                <Dropdown
                  buttonText={units.length !== 0 ? data.measurement : ''}
                  buttonProps={{className: 'dropdown-blue-style dropdown-without-borders', round: true, size: "sm"}}
                  dropdownList={units}
                  hoverColor="dark"
                  onClick={(value) => this.handleDataChange('measurement', value.id)}
                  error={errors && errors['measurement']}
                  disabled={!modifiable}
                />
              </GridItem>
              <GridItem className="cost-position-type-input-container" xs={2}>
                <DecimalInput
                  className="without-borders"
                  value={data.rate}
                  onChange={(value) => this.handleDataChange('rate', value)}
                  error={errors && errors['rate']}
                  disabled={!modifiable}
                  adornment={currencySymbol}
                />
              </GridItem>
              <GridItem className="cost-position-type-input-container" xs={2}>
                <DecimalInput
                  className="without-left-borders"
                  value={data.amount && data.rate ? data.amount * data.rate : ''}
                  adornment={currencySymbol}
                  disabled={true}
                />
              </GridItem>
              { data.items.length === 0 && modifiable ? (
                  <IconButton
                    aria-label="close"
                    className="type-delete-btn"
                    disabled={!modifiable}
                    onClick={removeCostCenter}
                  >
                    <img
                      src={CloseButton}
                      alt='cost-center-delete-btn'
                    />
                  </IconButton>
                ) : (
                  <div className="type-delete-btn-div"/>
                )
              }
            </GridItem>
          </GridItem>
          {
            isCardOpen && data.items.length !== 0 &&
            <GridItem className="cost-position-types">
              <GridItem className="cost-position-type-titles">
                <GridItem xs={6}><span>{ t('type') }</span></GridItem>
                <GridItem xs={2}><span>{ t('amount') }</span></GridItem>
                <GridItem xs={2}><span>{ t('me') }</span></GridItem>
                <GridItem xs={2}><span>{ t('rate') }</span></GridItem>
                <GridItem xs={2}><span>{ t('total') }</span></GridItem>
              </GridItem>
              {data.items.length !== 0 && data.items.map((type,index) => {return this.renderType(type,index)})}
            </GridItem>
          }
          <div className="create-cost-type-item">
            <Button
              className="create-cost-btn"
              disabled={!modifiable}
              onClick={this.handleAddNewItem}
            >
              + { t('add_sub-cost_type').toUpperCase() }
            </Button>
          </div>
        </div>
        <div className="cost-type-card-btn-container">
          <div
            className={`card-btn ${isCardOpen ? 'cost-bottom-borders' : ''}`}
            onClick={() => this.onCardClose()}
          >
            {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Position);
