import React from 'react';
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button.jsx';
import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Input from 'components/Input/Input';
import SubCost from './SubCost';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './style.scss';
import CloseButton from "../../../../assets/img/buttons/delete.svg";
import IconButton from "@material-ui/core/IconButton";
import RemoveDialog from '../../../RemoveDialog';
import InputWithIcon from "../../../../components/DecimalInput";

class Cost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: false,
      isRemoveCostOpen: false,
      specialErrors: null,
    };
  }

  componentDidMount() {
    if (this.props.isCardOpen) {
      this.setState({
        isCardOpen: true
      })
    }
  }

  onCardClose = () => {
    this.setState({isCardOpen: !this.state.isCardOpen})
  };

  handleInputChange = (fieldName, value) => {
    const {data, onChange} = this.props;
    const newData = {...data};
    newData[fieldName] = value;
    onChange(newData)
  };

  handleSubCostChange = (id, fieldName, value) => {
    const { data, onChange } = this.props;
    const { specialErrors } = this.state;

    let newData = {...data};
    let newItem = newData.items[id];
    newItem[fieldName] = value;

    if (specialErrors && specialErrors[id]) {
      const newSpecialError = specialErrors[id];
      if (fieldName == 'title')
        newSpecialError['title'] = undefined;
      if (fieldName == 'measurement')
        newSpecialError['measurement'] = undefined;
      if (fieldName == 'rate')
        newSpecialError['rate'] = undefined;
      this.setState({
        specialErrors: {
          [id]: newSpecialError,
        },
      });
    }
    /*if (fieldName === 'measurement') {
      newData.measurement = ''
    }
    if (fieldName === 'rate') {
      newData.rate = ''
    }*/
    onChange(newData)
  }

  handleAddNewSubCost = () => {
    const { data, t } = this.props;
    const { specialErrors } = this.state;
    const addNewItem = () => {
      const subCost = { title: '', amount: '', measurement: '', rate: '' };
      const newItems = [...data.items, subCost];
      this.handleInputChange('items', newItems);
    };
    if (data.items.length > 0) {
      const lastItem = data.items[data.items.length - 1];
      const errors = {};
      if (!lastItem.title)
        errors['title'] = [t('input_title')];
      if (!lastItem.measurement)
        errors['measurement'] = [t('select_measurement')];
      if (!lastItem.rate)
        errors['rate'] = [t('input_rate')];
      if (Object.keys(errors).length > 0) {
        this.setState({
          specialErrors: {
            [data.items.length - 1]: errors,
          },
        });
      } else {
        if (specialErrors) {
          this.setState({
            specialErrors: null,
          });
        }
        addNewItem();
      }
    } else {
      if (specialErrors) {
        this.setState({
          specialErrors: null,
        });
      }
      addNewItem();
    }
  }

  handleDeleteSubCost = (id) => {
    const {data} = this.props;
    const { specialErrors } = this.state;
    if (specialErrors && specialErrors[id]) {
      this.setState({
        specialErrors: null,
      });
    }
    const newItems = [...data.items].filter((item, index) => index !== id);
    this.handleInputChange('items', newItems)
  }

  getSubCostError = (index) => {
    const { errors } = this.props;
    const { specialErrors } = this.state;
    if (errors && errors.items && errors.items[index])
      return errors.items[index];
    else if (specialErrors && specialErrors[index])
      return specialErrors[index];
    return null;
  }

  handleRemoveDialogClose = () => {
    this.setState({ isRemoveCostOpen: !this.state.isRemoveCostOpen });
  }

  handleCostRateChange = (value) => {
    const {data, onChange} = this.props;
    const newData = {...data};
    /*newData.items.forEach(item => item.rate = '');*/
    newData.rate = value;
    onChange(newData)
  }

  handleCostMetricsChange = (value) => {
    const {data, onChange} = this.props;
    const newData = {...data};
    /*newData.items.forEach(item => item.measurement = '');*/
    newData.measurement = value;
    onChange(newData);
  }

  render() {
    const {
      data,
      errors,
      onRemovePosition,
      costCenterUnits,
      currencySymbol,
      t
    } = this.props;
    const {isCardOpen, isRemoveCostOpen} = this.state;
    return (
      <div className="position-content">
        <div className={`position-info ${isCardOpen ? 'bottom-borders' : ''}`}>
          <div className="position-info-container" >
            <GridItem className="position-info-title" xs={12}>
              <div className="position-title">
                {`${t('cost_center')}`}
              </div>
              <div className="position-total-price">
                <IconButton aria-label="close" className="close-btn position-delete-btn" onClick={this.handleRemoveDialogClose}>
                  <img alt="close-task-btn" src={CloseButton}/>
                </IconButton>
              </div>
            </GridItem>
            <div className="position-info-inputs">
              <GridItem xs={5} className="position-input-item">
                <span className="item-title">{ t('name') }</span>
                <Input
                  className="position-name"
                  error={errors && errors.title}
                  value={data.title}
                  onChange={e => this.handleInputChange('title', e.target.value)}
                />
              </GridItem>
              <GridItem xs={3} className="position-input-item">
                <span className="item-title">{ t('type') }</span>
                <div className="position-type-container">
                  <div
                    className={`project-type ${ data.type === 1 ? 'selected' : ''} ${errors && errors.type ? ' errors' : ''}`}
                    onClick={() => this.handleInputChange('type', 1)}
                  >
                    <div>{ t('operational') }</div>
                  </div>
                  <div
                    className={`project-type ${ data.type === 2 ? 'selected' : ''} ${errors && errors.type ? ' errors' : ''}`}
                    onClick={() => this.handleInputChange('type', 2)}
                  >
                    <div>{ t('non-operational') }</div>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={2} className="position-input-item">
                <span className="item-title">{ t('me') }</span>
                <CustomDropdown
                  buttonText={
                    costCenterUnits.length !== 0 ? data.measurement !== null ? data.measurement : ''  : ''
                  }
                  buttonProps={{
                    className: 'dropdown-blue-style',
                    color: 'transparent',
                    round: true,
                    size: 'sm'
                  }}
                  hoverColor="rose"
                  dropdownList={costCenterUnits}
                  error={errors && errors.measurement}
                  onClick={value => this.handleCostMetricsChange(value.id)}
                />
              </GridItem>
              <GridItem xs={2} className="position-input-item">
                <span className="item-title">{ t('rate') }</span>
                <InputWithIcon
                  className="position-rate"
                  error={errors && errors.rate}
                  value={data.rate}
                  onChange={(value) => this.handleCostRateChange(value)}
                  adornment={currencySymbol}
                />
              </GridItem>
            </div>
          </div>
          <div className="card-btn-container"
          >
            <div
              className={`card-btn ${isCardOpen ? 'bottom-borders' : ''}`}
              onClick={() => this.onCardClose()}
            >
              {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </div>
          </div>
        </div>
        {isCardOpen &&
          <div className="sub-cost-center-content">
            {
              data.items.length !== 0 &&
                <GridContainer className="position-tasks">
                  <GridContainer className="position-info-header">
                    <GridItem className="header-item" xs={7}><span>{ t('name') }</span></GridItem>
                    <GridItem className="header-item" xs={2}><span>{ t('me_metrics') }</span></GridItem>
                    <GridItem className="header-item" xs={2}><span>{ t('rate') }</span></GridItem>
                  </GridContainer>
                  {data.items.map((subCost, index) => {
                    return <SubCost
                             data={subCost}
                             errors={this.getSubCostError(index)}
                             costCenterUnits={costCenterUnits}
                             key={index}
                             onDataChange={(fieldName, value) => this.handleSubCostChange(index, fieldName, value)}
                             onDeleteSubCost={() => this.handleDeleteSubCost(index)}
                             currencySymbol={currencySymbol}
                           />
                  })}
                </GridContainer>
            }
            <GridContainer className="sub-cost-center-btn-container">
                <Button
                  className="position-btn blue"
                  onClick={() => this.handleAddNewSubCost()}
                >
                  + { t('add_to_subcost').toUpperCase() }
                </Button>
            </GridContainer>
          </div>
        }
        {isRemoveCostOpen && (
          <RemoveDialog
            isOpen={isRemoveCostOpen}
            title={t('remove_cost')}
            text={t('remove_cost_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={
              () => {this.handleRemoveDialogClose();
              onRemovePosition()}}
          />
        )}
      </div>
    )
  }
}

export default withTranslation()(Cost);
