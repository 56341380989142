import React from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import {ProgressListSortByOptions} from 'constants/constants';
import {filterProgress} from 'store/actions/progress';

import Button from 'components/CustomButtons/Button.jsx';
import Checkbox from 'components/StyledCheckbox';
import CustomPagination from "../../components/CustomPagination";
import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ProgressPosition from './ProgressPositions';

import './style.scss';

class ProjectProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        page: '',
        coworker_id: '',
        sort_by: 'name',
        sort_by_order: 'asc',
        show_completed: false
      }
    };
  }

  handleFilters = (fieldName, value) => {
    const {
      coworkers,
      filterProgress,
      order
    } = this.props;
    const { filters } = this.state;
    const newFilters = { ...filters };
    if (fieldName === 'coworker_id')
      newFilters[fieldName] = +value !== -1 ? value : '';
    else
      newFilters[fieldName] = value;
    filterProgress({id: order.id, filters: newFilters});
    this.setState({
      filters: newFilters
    });
  };

  handleAddPositionButton = () => {
    const {order} = this.props;
    const url = order.type === 0 ? `/admin/orders/edit-order/${order.id}` : `/admin/inquiries/edit-inquiry/${order.id}`;
    this.props.history.push({pathname: url, state: {'isCreatePosition': true, 'tabId': 1 }})
  }

  handlePageClick = (data) => {
    const {selected} = data;
    this.handleFilters('page', selected + 1)
  };

  render() {
    const {
      coworkers,
      progress,
      metaData,
      order,
      t
    } = this.props;
    const {
      filters
    } = this.state;
    let coworkersData = [];
    coworkersData.push({id: -1, value: t('all_assignees')});
    progress.map(item => item.coworker_id && item.coworker && coworkersData.push({id: item.coworker_id, value: `${item.coworker.first_name} ${item.coworker.last_name}`}));
    return (
      <>
     <GridContainer className="project-progress-container">
       <GridItem className="project-progress-top" xs={12}>
         <GridItem className="progress-positions" xs={3}>
           <span>{progress.length !== 0 ? (progress.length + ' ' + t('positions')) : ''}</span>
         </GridItem>
         <GridItem className="progress-top-right-bar">
           <Checkbox
            checked={filters.show_completed}
            onChange={() => this.handleFilters('show_completed', !filters.show_completed)}
           />
           <span className="checkbox-title">{ t('show_completed') }</span>
           <Dropdown
             buttonText={filters.coworker_id}
             buttonPlaceholder={t('all_assignees')}
             buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
             dropdownList={coworkersData}
             onClick={value => this.handleFilters('coworker_id', value.id)}
           />
           <Dropdown
             buttonText={filters.sort_by}
             buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
             dropdownList={ProgressListSortByOptions}
             translateValues={true}
             onClick={value => this.handleFilters('sort_by', value.id)}
           />
           { order.can_edit && 
            <Button
              className="ikt-ap_order-add-position-btn"
              onClick={this.handleAddPositionButton}
            >
              + { t('position').toUpperCase() }
            </Button>
           }
         </GridItem>
       </GridItem>
       {
         <GridItem className="progress-positions-header">
           <GridItem className="pp-col-name">{ t('name') }</GridItem>
           <GridItem className="pp-col-assignee">{ t('assignee') }</GridItem>
           <GridItem className="pp-col-progress">{ t('progress') }</GridItem>
           <GridItem className="pp-col-deadline">{ t('deadline') }</GridItem>
           <GridItem className="pp-col-actions">{ t('actions') }</GridItem>
         </GridItem>
       }
       {
         progress && progress.length !== 0 &&
          progress.map((progressItem, index) => <ProgressPosition order={order} coworkers={coworkers} filters={filters} key={index} data={progressItem} />)
       }
       {
         progress && progress.length === 0 &&
         <div className='progress-content-no-records'>
           <span className='no-records'>{ t('no_records_found') }</span>
        </div>
       }
     </GridContainer>
     <GridContainer className="pagination-container">
        {
          metaData.last_page > 1 &&
          <CustomPagination
              pageCount={metaData.last_page}
              handlePageClick={this.handlePageClick}
          />
        }
      </GridContainer>
      </>
    )
  }
}


const mapStateToProp = state => {
  return {
    progress: state.progress.progress,
    coworkers: state.coworkers.coworkers,
    metaData: state.progress.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    filterProgress: (data) => dispatch(filterProgress(data))
  };
};

export default withRouter(connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ProjectProgress)));
