import { ofType } from 'redux-observable';
import { from, of, concat, merge } from 'rxjs';
import { switchMap, mergeMap, catchError, endWith } from 'rxjs/operators';
import {materialsAPI} from 'services/API';
import {
  getMaterials,
  getMaterialsSuccess,
  getMaterialsFailure,
  getMaterialsForCategory,
  getMaterialsForCategorySuccess,
  getMaterialsForCategoryFailure,
  getMaterialsInBundles,
  getMaterialsInBundleSuccess,
  getMaterialsInBundleError,
  getMaterialsInBundlesFinish,
  getMaterialSuccess,
  getMaterialFailure,
  addMaterialSuccess,
  addMaterialFailure,
  addCompoundMaterialSuccess,
  addCompoundMaterialFailure,
  updateMaterialSuccess,
  updateMaterialFailure,
  updateCompoundMaterialBaseSuccess,
  updateCompoundMaterialBaseError,
  addMaterialRequestSuccess,
  addMaterialRequestFailure,
  updateMaterialRequestSuccess,
  updateMaterialRequestFailure,
  approveMaterialSuccess,
  approveMaterialFailure,
  startMaterialFileImportSuccess,
  startMaterialFileImportFailure,
  downloadMaterialFileImportSkippedSuccess,
  downloadMaterialFileImportSkippedFailure,
  removeCompoundMaterialSuccess,
  removeCompoundMaterialFailure,
  removeMaterialSuccess,
  removeMaterialFailure,
  restoreMaterialsSingleSuccess,
  restoreMaterialsSingleFailure,
  restoreMaterialsFinish,
  removeMaterialsSingleSuccess,
  removeMaterialsSingleFailure,
  removeMaterialsFinish,
  restoreMaterialSuccess,
  restoreMaterialFailure,
  updateMaterialCategoryValuesSuccess,
  updateMaterialCategoryValuesFailure,
  updateMultipleMaterialsValuesSuccess,
  updateMultipleMaterialsValuesFailure,
  updateMultipleMaterialsPriceSuccess,
  updateMultipleMaterialsPriceFailure,
  duplicateMaterialFailure,
  duplicateMaterialSuccess,
} from '../store/actions/materials';
import {
  ADD_MATERIAL,
  ADD_COMPOUND_MATERIAL,
  UPDATE_MATERIAL,
  ADD_MATERIAL_REQUEST,
  UPDATE_MATERIAL_REQUEST,
  APPROVE_MATERIAL,
  GET_MATERIALS,
  GET_MATERIALS_FOR_CATEGORY,
  GET_MATERIALS_IN_BUNDLES,
  UPDATE_COMPOUND_MATERIAL,
  UPDATE_COMPOUND_MATERIAL_BASE,
  GET_MATERIAL,
  SET_MATERIAL_AVAILABILITY,
  START_MATERIAL_FILE_IMPORT,
  DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED,
  REMOVE_MATERIAL,
  REMOVE_COMPOUND_MATERIAL,
  RESTORE_MATERIALS,
  RESTORE_MATERIALS_FINISH,
  REMOVE_MATERIALS,
  REMOVE_MATERIALS_FINISH,
  UPDATE_MATERIAL_CATEGORY_VALUES,
  UPDATE_MULTIPLE_MATERIALS_VALUES,
  UPDATE_MULTIPLE_MATERIALS_PRICE,
  RESTORE_MATERIAL,
  DUPLICATE_MATERIAL,
} from '../store/actionTypes/materialsActionTypes';
import NotificationService from '../services/NotificationService';

export const getMaterialsEpic = actions$ =>
  actions$.pipe(
    ofType(GET_MATERIALS),
    switchMap(action =>
      from(
        materialsAPI.getMaterials(
          action.payload.page,
          action.payload.filters,
          action.payload.searchTerms
        )
      ).pipe(
        switchMap(response => {
          return of(getMaterialsSuccess(response));
        }),
        catchError(err => {
          return of(getMaterialsFailure(err));
        })
      )
    )
  );
  export const getMaterialsForCategoryEpic = actions$ =>
  actions$.pipe(
    ofType(GET_MATERIALS_FOR_CATEGORY),
    switchMap(action =>
      from(
        materialsAPI.getMaterials(
          action.payload.page,
          action.payload.filters,
          action.payload.searchTerms
        )
      ).pipe(
        switchMap(response => {
          return of(getMaterialsForCategorySuccess(response));
        }),
        catchError(err => {
          return of(getMaterialsForCategoryFailure(err));
        })
      )
    )
  );

export const getMaterialEpic = actions$ =>
  actions$.pipe(
    ofType(GET_MATERIAL),
    switchMap(({ payload }) =>
      from(materialsAPI.getMaterial(payload)).pipe(
        switchMap(response => {
          return of(getMaterialSuccess(response.data));
        }),
        catchError(err => {
          return of(getMaterialFailure(err));
        })
      )
    )
  );

export const addMaterialEpic = actions$ =>
  actions$.pipe(
    ofType(ADD_MATERIAL),
    switchMap(({ payload }) =>
      from(materialsAPI.addMaterial(payload)).pipe(
        switchMap(response =>
          concat(
            of(getMaterials()),
            of(addMaterialSuccess(response.data))
          )
        ),
        catchError(err => {
          return of(addMaterialFailure(err));
        })
      )
    )
  );

export const getMaterialsInBundlesEpic = actions$ =>
  actions$.pipe(
    ofType(GET_MATERIALS_IN_BUNDLES),
    mergeMap(action => {
      const observables = action.payload.ids.map(id =>
        from(materialsAPI.getMaterialsInBundle(id)).pipe(
          switchMap(response => {
            return of(getMaterialsInBundleSuccess(id, response.data));
          }),
          catchError(err => {
            return of(getMaterialsInBundleError(id, err));
          })
        )
      );
      return merge(...observables).pipe(endWith(getMaterialsInBundlesFinish()));
    })
  );

export const updateCompoundMaterialBaseEpic = actions$ =>
  actions$.pipe(
    ofType(UPDATE_COMPOUND_MATERIAL_BASE),
    mergeMap(action =>
      from(materialsAPI.updateCompoundMaterialBase(action.payload.id, action.payload.data)).pipe(
        switchMap(response => {
          const loadListInfo = action.payload.loadListInfo;
          if (loadListInfo) {
            return loadListInfo.loadCompoundMaterials ?
              concat(
                of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials)),
                of(updateCompoundMaterialBaseSuccess(response))
              ) :
              concat(
                of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                of(updateCompoundMaterialBaseSuccess(response))
              );
          } else {
            return of(updateCompoundMaterialBaseSuccess(response));
          }
        }),
        catchError(err => {
          return of(updateCompoundMaterialBaseError(err));
        })
      )
    )
  );

export const updateCompoundMaterialEpic = actions$ =>
  actions$.pipe(
    ofType(UPDATE_COMPOUND_MATERIAL),
    mergeMap(action =>
      from(materialsAPI.updateCompoundMaterial(action.payload.data.id, action.payload.data.body)).pipe(
        switchMap(() => {
          const loadListInfo = action.payload.loadListInfo;
          return loadListInfo.loadCompoundMaterials ?
            concat(
              of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
              of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials))
            ) :
            of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms));
        }),
        catchError(err => {
          return of(getMaterialsFailure(err));
        })
      )
    )
  );

export const addCompoundMaterialEpic = actions$ => {
  return actions$.pipe(
    ofType(ADD_COMPOUND_MATERIAL),
    switchMap(({ payload }) =>
      from(materialsAPI.addCompoundMaterial(payload.data)).pipe(
        switchMap(response => {
          const loadListInfo = payload.loadListInfo;
          if (loadListInfo) {
            return loadListInfo.loadCompoundMaterials
            ? concat(
                of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials)),
                of(addCompoundMaterialSuccess(response.data))
              )
            : concat(
                of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                of(addCompoundMaterialSuccess(response.data))
              );
          } else {
            return of(addCompoundMaterialSuccess(response.data));
          }
        }),
        catchError(err => {
          return of(addCompoundMaterialFailure(err));
        })
      )
    )
  );
};

export const updateMaterialEpic = actions$ =>
  actions$.pipe(
    ofType(UPDATE_MATERIAL),
    mergeMap(action =>
      from(
        materialsAPI.updateMaterial(action.payload.id, action.payload.data)
      ).pipe(
        switchMap(response => {
          return of(updateMaterialSuccess(response));
        }),
        catchError(err => {
          return of(updateMaterialFailure(err));
        })
      )
    )
  );

export const addMaterialRequestEpic = actions$ =>
  actions$.pipe(
    ofType(ADD_MATERIAL_REQUEST),
    switchMap(action =>
      from(materialsAPI.addMaterialRequest(action.payload.body)).pipe(
        switchMap(response => {
          const loadListInfo = action.payload.loadListInfo;
          if (loadListInfo) {
            return loadListInfo.loadCompoundMaterials
            ? concat(
                of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials)),
                of(addMaterialRequestSuccess(response.data))
              )
            : concat(
                of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                of(addMaterialRequestSuccess(response.data))
              );
          } else {
            return of(addMaterialRequestSuccess(response.data));
          }
        }),
        catchError(err => {
          return of(addMaterialRequestFailure(err));
        })
      )
    )
  );

export const updateMaterialRequestEpic = actions$ =>
  actions$.pipe(
    ofType(UPDATE_MATERIAL_REQUEST),
    mergeMap(action =>
      from(materialsAPI.updateMaterialRequest(action.payload.id, action.payload.body)).pipe(
        switchMap(response => {
          const loadListInfo = action.payload.loadListInfo;
          if (loadListInfo) {
            return loadListInfo.loadCompoundMaterials
              ? concat(
                  of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                  of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials)),
                  of(updateMaterialRequestSuccess(response))
                )
              : concat(
                  of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                  of(updateMaterialRequestSuccess(response))
                );
          } else {
            return of(updateMaterialRequestSuccess(response));
          }
        }),
        catchError(err => {
          return of(updateMaterialRequestFailure(err));
        })
      )
    )
  );

export const duplicateMaterialEpic = (actions$) => actions$.pipe(
  ofType(DUPLICATE_MATERIAL),
  switchMap((action) => from(materialsAPI.duplicateMaterial(action.payload.id)).pipe(
    switchMap((response) =>
      concat(
        of(duplicateMaterialSuccess(response)),
        of(getMaterials(action.payload.loadListInfo.page, action.payload.loadListInfo.filters, action.payload.loadListInfo.searchTerms))
      )
    ),
    catchError( err => {
        return of(duplicateMaterialFailure(err))
      }
    )
  ))
);

export const approveMaterialEpic = actions$ =>
  actions$.pipe(
    ofType(APPROVE_MATERIAL),
    mergeMap(action =>
      from(materialsAPI.approveMaterial(action.payload.id)).pipe(
        switchMap(response => {
          return of(approveMaterialSuccess(response));
        }),
        catchError(err => {
          return of(approveMaterialFailure(err));
        })
      )
    )
  );

export const setMaterialAvailabilityEpic = actions$ =>
  actions$.pipe(
    ofType(SET_MATERIAL_AVAILABILITY),
    mergeMap(action =>
      from(materialsAPI.setMaterialAvailability(action.payload.data.id, action.payload.data.value)).pipe(
        switchMap(() => {
          const loadListInfo = action.payload.loadListInfo;
          return loadListInfo.loadCompoundMaterials
            ? concat(
                of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials))
              )
            : of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms));
        }),
        catchError(err => {
          return of(getMaterialsFailure(err));
        })
      )
    )
  );

export const startMaterialImportFileEpic = actions$ =>
  actions$.pipe(
    ofType(START_MATERIAL_FILE_IMPORT),
    mergeMap(action =>
      from(materialsAPI.startImportMaterialFile(action.payload)).pipe(
        switchMap(response => {
          return of(startMaterialFileImportSuccess(response.data));
        }),
        catchError(err => {
          return of(startMaterialFileImportFailure(err));
        })
      )
    )
  );

export const downloadMaterialImportSkippedEpic = actions$ =>
  actions$.pipe(
    ofType(DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED),
    mergeMap(action =>
      from(materialsAPI.downloadMaterialFileImportSkipped(action.payload)).pipe(
        switchMap(response => {
          return of(downloadMaterialFileImportSkippedSuccess(response, `skipped_materials_${action.payload}.csv`));
        }),
        catchError(err => {
          return of(downloadMaterialFileImportSkippedFailure(err));
        })
      )
    )
  );

export const removeMaterialEpic = actions$ =>
  actions$.pipe(
    ofType(REMOVE_MATERIAL),
    mergeMap(action =>
      from(materialsAPI.removeMaterial(action.payload.id, action.payload.purge)).pipe(
        switchMap(response => {
          if (action.payload.loadListInfo) {
            const listInfo = action.payload.loadListInfo;
            if (listInfo.loadCompoundMaterials) {
              return concat(
                of(getMaterials(listInfo.page, listInfo.filters, listInfo.searchTerms)),
                of(getMaterialsInBundles(listInfo.loadCompoundMaterials)),
                of(removeMaterialSuccess(response))
              );
            } else {
              return concat(
                of(getMaterials(listInfo.page, listInfo.filters, listInfo.searchTerms)),
                of(removeMaterialSuccess(response))
              );
            }
          } else {
            return of(removeMaterialSuccess(response));
          }
        }),
        catchError(err => {
          return of(removeMaterialFailure(err));
        })
      )
    )
  );

export const restoreMaterialEpic = actions$ =>
  actions$.pipe(
    ofType(RESTORE_MATERIAL),
    mergeMap(action =>
      from(materialsAPI.restoreMaterial(action.payload.id)).pipe(
        switchMap(response => {
          if (action.payload.loadListInfo) {
            const listInfo = action.payload.loadListInfo;
            if (listInfo.loadCompoundMaterials) {
              return concat(
                of(getMaterials(listInfo.page, listInfo.filters, listInfo.searchTerms)),
                of(getMaterialsInBundles(listInfo.loadCompoundMaterials)),
                of(restoreMaterialSuccess(response))
              );
            } else {
              return concat(
                of(getMaterials(listInfo.page, listInfo.filters, listInfo.searchTerms)),
                of(restoreMaterialSuccess(response))
              );
            }
          } else {
            return of(restoreMaterialSuccess(response));
          }
        }),
        catchError(err => {
          return of(restoreMaterialFailure(err));
        })
      )
    )
  );

export const removeCompoundMaterialEpic = actions$ =>
  actions$.pipe(
    ofType(REMOVE_COMPOUND_MATERIAL),
    mergeMap(action =>
      from(materialsAPI.removeCompoundMaterial(action.payload)).pipe(
        switchMap(response =>
          concat(
            of(getMaterials()),
            of(removeCompoundMaterialSuccess(response))
          )
        ),
        catchError(err => {
          return of(removeCompoundMaterialFailure(err));
        })
      )
    )
  );

export const restoreMaterialsEpic = (actions$) => actions$.pipe(
  ofType(RESTORE_MATERIALS),
  mergeMap(({payload}) => {
    const observables = payload.data.map(id => from(materialsAPI.restoreMaterial(id)).pipe(
      switchMap((response) => {
        return of(restoreMaterialsSingleSuccess(id));
      }),
      catchError(err => {
        return of(restoreMaterialsSingleFailure(id, err));
      })
    ));
    return merge(
      ...observables
    ).pipe(endWith(restoreMaterialsFinish(payload.loadListInfo)));
  })
);
  
export const restoreMaterialsFinishEpic = (actions$, state$) => actions$.pipe(
  ofType(RESTORE_MATERIALS_FINISH),
  switchMap((action) => {
    const statePart = state$.value.materials;
    const errors = statePart.batchRestoreState.errors;
    if (Object.keys(errors).length == 0) {
      NotificationService.success('Materials were restored');
    } else {
      let errorMessage = 'There were errors restoring materials:';
      const keys = Object.keys(errors);
      for(let i = 0, len = keys.length; i < len; ++i) {
        const key = keys[i];
        const value = errors[key];
        errorMessage += `${i > 0 ? ', ' : ''} ${key} - ${value[0].responseBody.message}`;
      }
      NotificationService.error(errorMessage, true);
    }
    return action.payload.loadCompoundMaterials
      ? concat(
          of(getMaterials(action.payload.page, action.payload.filters, action.payload.searchTerms)),
          of(getMaterialsInBundles(action.payload.loadCompoundMaterials))
        )
      : of(getMaterials(action.payload.page, action.payload.filters, action.payload.searchTerms));
  })
);

export const removeMaterialsEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_MATERIALS),
  mergeMap(({payload}) => {
    const observables = payload.data.map(id => from(materialsAPI.removeMaterial(id, payload.purge)).pipe(
      switchMap((response) => {
        return of(removeMaterialsSingleSuccess(id));
      }),
      catchError(err => {
        return of(removeMaterialsSingleFailure(id, err));
      })
    ));
    return merge(
      ...observables
    ).pipe(endWith(removeMaterialsFinish(payload.loadListInfo)));
  })
);

export const removeMaterialsFinishEpic = (actions$, state$) => actions$.pipe(
  ofType(REMOVE_MATERIALS_FINISH),
  switchMap((action) => {
    const statePart = state$.value.materials;
    const errors = statePart.batchRemoveState.errors;
    if (Object.keys(errors).length == 0) {
      NotificationService.success('Materials were removed');
    } else {
      let errorMessage = 'There were errors removing materials:';
      const keys = Object.keys(errors);
      for(let i = 0, len = keys.length; i < len; ++i) {
        const key = keys[i];
        const value = errors[key];
        errorMessage += `${i > 0 ? ', ' : ''} ${key} - ${value[0].responseBody.message}`;
      }
      NotificationService.error(errorMessage, true);
    }
    return action.payload.loadCompoundMaterials
      ? concat(
          of(getMaterials(action.payload.page, action.payload.filters, action.payload.searchTerms)),
          of(getMaterialsInBundles(action.payload.loadCompoundMaterials))
        )
      : of(getMaterials(action.payload.page, action.payload.filters, action.payload.searchTerms));
  })
);

export const updateMaterialCategoryValuesEpic = actions$ =>
  actions$.pipe(
    ofType(UPDATE_MATERIAL_CATEGORY_VALUES),
    mergeMap(action =>
      from(
        materialsAPI.updateMaterialCategoryValues(action.payload.id, action.payload.data)
      ).pipe(
        switchMap(response => {
          const loadListInfo = action.payload.loadListInfo;
          if (loadListInfo) {
            return loadListInfo.loadCompoundMaterials
              ? concat(
                  of(updateMaterialCategoryValuesSuccess(response)),
                  of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                  of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials))
                )
              : concat(
                  of(updateMaterialCategoryValuesSuccess(response)),
                  of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms))
              );
          } else {
            return of(updateMaterialCategoryValuesSuccess(response));
          }
        }),
        catchError(err => {
          return of(updateMaterialCategoryValuesFailure(err));
        })
      )
    )
  );

export const updateMultipleMaterialsValuesEpic = actions$ =>
  actions$.pipe(
    ofType(UPDATE_MULTIPLE_MATERIALS_VALUES),
    mergeMap(action =>
      from(
        materialsAPI.updateMultipleMaterialsValues(action.payload.ids, action.payload.data)
      ).pipe(
        switchMap(response => {
          const loadListInfo = action.payload.loadListInfo;
          if (loadListInfo) {
            return loadListInfo.loadCompoundMaterials
              ? concat(
                  of(updateMultipleMaterialsValuesSuccess(response)),
                  of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                  of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials))
                )
              : concat(
                  of(updateMultipleMaterialsValuesSuccess(response)),
                  of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms))
              );
          } else {
            return of(updateMultipleMaterialsValuesSuccess(response));
          }
        }),
        catchError(err => {
          return of(updateMultipleMaterialsValuesFailure(err));
        })
      )
    )
  );

export const updateMultipleMaterialsPriceEpic = actions$ =>
  actions$.pipe(
    ofType(UPDATE_MULTIPLE_MATERIALS_PRICE),
    mergeMap(action =>
      from(
        materialsAPI.updateMultipleMaterialsPrice(action.payload.ids, action.payload.data)
      ).pipe(
        switchMap(response => {
          const loadListInfo = action.payload.loadListInfo;
          if (loadListInfo) {
            return loadListInfo.loadCompoundMaterials
              ? concat(
                  of(updateMultipleMaterialsPriceSuccess(response)),
                  of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms)),
                  of(getMaterialsInBundles(loadListInfo.loadCompoundMaterials))
                )
              : concat(
                  of(updateMultipleMaterialsPriceSuccess(response)),
                  of(getMaterials(loadListInfo.page, loadListInfo.filters, loadListInfo.searchTerms))
              );
          } else {
            return of(updateMultipleMaterialsPriceSuccess(response));
          }
        }),
        catchError(err => {
          return of(updateMultipleMaterialsPriceFailure(err));
        })
      )
    )
  );