import dot from "dot-object";

import {
  GET_INQUIRIES,
  GET_INQUIRIES_SUCCESS,
  GET_INQUIRIES_ERROR,
  GET_INQUIRY,
  GET_INQUIRY_SUCCESS,
  GET_INQUIRY_ERROR,
  CREATE_INQUIRY,
  CREATE_INQUIRY_SUCCESS,
  CREATE_INQUIRY_ERROR,
  REMOVE_INQUIRY,
  REMOVE_INQUIRY_SUCCESS,
  REMOVE_INQUIRY_ERROR,
  UPDATE_INQUIRY,
  UPDATE_INQUIRY_SUCCESS,
  UPDATE_INQUIRY_ERROR,
} from '../actionTypes/inquiriesActionTypes';

import NotificationService from '../../services/NotificationService';

export const getInquiries = () => {
  return { type: GET_INQUIRIES };
};

export const getInquiriesSuccess = (inquiries) => {
  return {
    type: GET_INQUIRIES_SUCCESS,
    payload: inquiries
  };
};

export const getInquiriesFailure = (error) => {
  return { type: GET_INQUIRIES_ERROR, payload: error };
};

export const getInquiry = (id) => {
  return { type: GET_INQUIRY, payload: id };
};

export const getInquirySuccess = (inquiryData) => {
  return {
    type: GET_INQUIRY_SUCCESS,
    payload: inquiryData
  };
};

export const getInquiryFailure = error => {
  return { type: GET_INQUIRY_ERROR, payload: error };
};

export const createInquiry = data => {
  return { type: CREATE_INQUIRY, payload: data };
};

export const createInquirySuccess = () =>  {
  NotificationService.success('New Inquiry Created');
  return {
    type: CREATE_INQUIRY_SUCCESS,
  };
};

export const createInquiryFailure = error => {
  return { type: CREATE_INQUIRY_ERROR, payload: error };
};

export const deleteInquiry = id => {
  return { type: REMOVE_INQUIRY, payload: id };
};

export const deleteInquirySuccess = () =>  {
  NotificationService.success('Inquiry Deleted');
  return {
    type: REMOVE_INQUIRY_SUCCESS,
  };
};

export const deleteInquiryFailure = error => {
  return { type: REMOVE_INQUIRY_ERROR, payload: error };
};

export const updateInquiry = (data) => {
  return { type: UPDATE_INQUIRY, payload: data };
};

export const updateInquirySuccess = () => {
  NotificationService.success('Inquiry Updated');
  return {type: UPDATE_INQUIRY_SUCCESS};
};

export const updateInquiryFailure = error => {
  return { type: UPDATE_INQUIRY_ERROR, payload: error };
};
