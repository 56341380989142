export const GET_CATEGORIES_TYPES = 'categoriesEpic/GET_CATEGORIES_TYPES';
export const GET_CATEGORIES_TYPES_SUCCESS = 'categoriesEpic/GET_CATEGORIES_TYPES_SUCCESS';
export const GET_CATEGORIES_TYPES_ERROR = 'categoriesEpic/GET_CATEGORIES_TYPES_ERROR';

export const GET_OWN_CONTACTS_CATEGORIES = 'categoriesEpic/GET_OWN_CONTACTS_CATEGORIES';
export const GET_OWN_CONTACTS_CATEGORIES_SUCCESS = 'categoriesEpic/GET_OWN_CONTACTS_CATEGORIES_SUCCESS';
export const GET_OWN_CONTACTS_CATEGORIES_ERROR = 'categoriesEpic/GET_OWN_CONTACTS_CATEGORIES_ERROR';

export const GET_OWN_CONTACTS_CATEGORIES_HIERARCHY = 'categoriesEpic/GET_OWN_CONTACTS_CATEGORIES_HIERARCHY';
export const GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_SUCCESS = 'categoriesEpic/GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_ERROR';
export const GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_ERROR = 'categoriesEpic/GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_ERROR';

export const GET_PARENT_CATEGORIES = 'categoriesEpic/GET_PARENT_CATEGORIES';
export const GET_PARENT_CATEGORIES_SUCCESS = 'categoriesEpic/GET_PARENT_CATEGORIES_SUCCESS';
export const GET_PARENT_CATEGORIES_ERROR = 'categoriesEpic/GET_PARENT_CATEGORIES_ERROR';

export const GET_MATERIALS_CATEGORIES = 'categoriesEpic/GET_MATERIALS_CATEGORIES';
export const GET_MATERIALS_CATEGORIES_SUCCESS = 'categoriesEpic/GET_MATERIALS_CATEGORIES_SUCCESS';
export const GET_MATERIALS_CATEGORIES_ERROR = 'categoriesEpic/GET_MATERIALS_CATEGORIES_ERROR';

export const GET_MATERIALS_CATEGORIES_PROVIDER = 'categoriesEpic/GET_MATERIALS_CATEGORIES_PROVIDER';
export const GET_MATERIALS_CATEGORIES_PROVIDER_SUCCESS = 'categoriesEpic/GET_MATERIALS_CATEGORIES_PROVIDER_SUCCESS';
export const GET_MATERIALS_CATEGORIES_PROVIDER_ERROR = 'categoriesEpic/GET_MATERIALS_CATEGORIES_PROVIDER_ERROR';

export const GET_CATEGORIES = 'categoriesEpic/GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'categoriesEpic/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'categoriesEpic/GET_CATEGORIES_ERROR';

export const GET_CATEGORY = 'categoriesEpic/GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = 'categoriesEpic/GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'categoriesEpic/GET_CATEGORIES_ERROR';

export const ADD_CATEGORY = 'categoriesEpic/ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'categoriesEpic/ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'categoriesEpic/ADD_CATEGORY_ERROR';

export const UPDATE_CATEGORY = 'categoriesEpic/UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'categoriesEpic/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'categoriesEpic/UPDATE_CATEGORY_ERROR';

export const REMOVE_CATEGORY = 'categoriesEpic/REMOVE_CATEGORY';
export const REMOVE_CATEGORY_SUCCESS = 'categoriesEpic/REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_ERROR = 'categoriesEpic/REMOVE_CATEGORY_ERROR';

export const RESET_CATEGORY = 'categoriesEpic/RESET_CATEGORY';

export const GET_CHILD_CATEGORIES = 'categoriesEpic/GET_CHILD_CATEGORIES';
export const GET_CHILD_CATEGORIES_SUCCESS = 'categoriesEpic/GET_CHILD_CATEGORIES_SUCCESS';
export const GET_CHILD_CATEGORIES_ERROR = 'categoriesEpic/GET_CHILD_CATEGORIES_ERROR';
