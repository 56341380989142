export class APIError extends Error {
    responseStatus = 0;
    responseBody = {};
    validationErrors = null;

    constructor(response, data) {
        super();

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, APIError);
        }

        this.responseStatus = response.status;
        this.responseBody = data;
        this.message = `The API request failed! Status code: ${response.status}. Status text: ${response.statusText}.`;
        if (data) {
            this.message += ' Response body: ' + JSON.stringify(data);
            // See if the API returned data validation errors. If so, parse them and save to validationErrors.
            this.validationErrors = this.parseValidationErrors(data);
        }
    }

    parseValidationErrors = (data) => {
        if (!data || data.isSuccess !== undefined) {
            return null;
        }

        const errors = {};
        let empty = true;

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const obj = data[key];
                if (Array.isArray(obj)) {
                    errors[key] = obj.reduce((prev, cur) => {
                        let curCopy = cur.trim();
                        if (!curCopy) { return prev; }  // Current string is empty.
                        if (!curCopy.endsWith('.')) {
                            curCopy += '.';               // Make sure a string ends with a dot.
                        }
                        if (prev) {
                            curCopy = ' ' + curCopy;      // Separate strings with a space.
                        }
                        return prev + curCopy;
                    }, '');
                    empty = false;
                }
            }
        }
        return empty ? null : errors;
    }
}

export class FetchError extends Error {
    fetchFailure = true;

    constructor(message) {
        super('The API request failed due to fetch exception' + (message ? `: ${message}.` : '.'));
    }
}

export const getErrorMessage = (e, defaultError = 'Something went wrong. Please try again later.') => {
    if (!e) {
        return '';
    } else if (e.responseBody && e.responseBody.errorMessage) {
        return e.responseBody.errorMessage;
    } else if (e.fetchFailure === true) {
        return 'You appear to be offline. Please check your connection.';
    } else {
        return defaultError;
    }
};
