export const GET_COWORKER_TIME_ENTRIES = 'coworkerTimeSheetEpic/GET_COWORKER_TIME_ENTRIES';
export const GET_COWORKER_TIME_ENTRIES_SUCCESS = 'coworkerTimeSheetEpic/GET_COWORKER_TIME_ENTRIES_SUCCESS';
export const GET_COWORKER_TIME_ENTRIES_ERROR = 'coworkerTimeSheetEpic/GET_COWORKER_TIME_ENTRIES_ERROR';

export const CREATE_COWORKER_TIME_ENTRY = 'coworkerTimeSheetEpic/CREATE_COWORKER_TIME_ENTRY';
export const CREATE_COWORKER_TIME_ENTRY_SUCCESS = 'coworkerTimeSheetEpic/CREATE_COWORKER_TIME_ENTRY_SUCCESS';
export const CREATE_COWORKER_TIME_ENTRY_ERROR = 'coworkerTimeSheetEpic/CREATE_COWORKER_TIME_ENTRY_ERROR';
export const CREATE_COWORKER_TIME_ENTRY_FINISH = 'coworkerTimeSheetEpic/CREATE_COWORKER_TIME_ENTRY_FINISH';

export const UPDATE_COWORKER_TIME_ENTRY = 'coworkerTimeSheetEpic/UPDATE_COWORKER_TIME_ENTRY';
export const UPDATE_COWORKER_TIME_ENTRY_SUCCESS = 'coworkerTimeSheetEpic/UPDATE_COWORKER_TIME_ENTRY_SUCCESS';
export const UPDATE_COWORKER_TIME_ENTRY_ERROR = 'coworkerTimeSheetEpic/UPDATE_COWORKER_TIME_ENTRY_ERROR';

export const GET_COWORKER_TIME_ENTRY = 'coworkerTimeSheetEpic/GET_COWORKER_TIME_ENTRY';
export const GET_COWORKER_TIME_ENTRY_SUCCESS = 'coworkerTimeSheetEpic/GET_COWORKER_TIME_ENTRY_SUCCESS';
export const GET_COWORKER_TIME_ENTRY_ERROR = 'coworkerTimeSheetEpic/GET_COWORKER_TIME_ENTRY_ERROR';

export const DELETE_COWORKER_TIME_ENTRY = 'coworkerTimeSheetEpic/DELETE_COWORKER_TIME_ENTRY';
export const DELETE_COWORKER_TIME_ENTRY_SUCCESS = 'coworkerTimeSheetEpic/DELETE_COWORKER_TIME_ENTRY_SUCCESS';
export const DELETE_COWORKER_TIME_ENTRY_ERROR = 'coworkerTimeSheetEpic/DELETE_COWORKER_TIME_ENTRY_ERROR';

export const FILTER_COWORKER_TIME_SHEETS = 'coworkerTimeSheetEpic/FILTER_COWORKER_TIME_SHEETS';

export const RESET_LAST_FINISHED_EDIT = 'coworkerTimeSheetEpic/RESET_LAST_FINISHED_EDIT';

export const RESET_TIME_ENTRY_DATA = 'coworkerTimeSheetEpic/RESET_TIME_ENTRY_DATA';
