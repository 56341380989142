import {
  GET_ADMIN_TIME_ENTRIES,
  GET_ADMIN_TIME_ENTRIES_SUCCESS,
  GET_ADMIN_TIME_ENTRIES_ERROR,
  GET_ADMIN_TIME_ENTRY,
  GET_ADMIN_TIME_ENTRY_SUCCESS,
  GET_ADMIN_TIME_ENTRY_ERROR,
  CREATE_ADMIN_TIME_ENTRY,
  CREATE_ADMIN_TIME_ENTRY_SUCCESS,
  CREATE_ADMIN_TIME_ENTRY_ERROR,
  CREATE_ADMIN_TIME_ENTRY_FINISH,
  UPDATE_ADMIN_TIME_ENTRY,
  UPDATE_ADMIN_TIME_ENTRY_SUCCESS,
  UPDATE_ADMIN_TIME_ENTRY_ERROR,
  DELETE_ADMIN_TIME_ENTRY,
  DELETE_ADMIN_TIME_ENTRY_SUCCESS,
  DELETE_ADMIN_TIME_ENTRY_ERROR,
  FILTER_ADMIN_TIME_SHEETS
} from '../actionTypes/adminTimeSheetActionTypes';

export const getAdminTimeEntries = () => {
  return {type: GET_ADMIN_TIME_ENTRIES}
};

export const getAdminTimeEntriesSuccess = (entries) => {
  return {type: GET_ADMIN_TIME_ENTRIES_SUCCESS, payload: entries}
};

export const getAdminTimeEntriesFailure = (error) => {
  return {type: GET_ADMIN_TIME_ENTRIES_ERROR, payload: error}
};

export const getAdminTimeEntry = (id) => {
  return {type: GET_ADMIN_TIME_ENTRY, payload: id}
};

export const getAdminTimeEntrySuccess = (entry) => {
  return {type: GET_ADMIN_TIME_ENTRY_SUCCESS, payload: entry}
};

export const getAdminTimeEntryFailure = (error) => {
  return {type: GET_ADMIN_TIME_ENTRY_ERROR, payload: error}
};

export const createAdminTimeEntry = (entries, filters, sort, sortOrder) => {
  return {type: CREATE_ADMIN_TIME_ENTRY, payload: { entries, filters, sort, sortOrder }}
};

export const createAdminTimeEntrySuccess = (uid, data) => {
  return {type: CREATE_ADMIN_TIME_ENTRY_SUCCESS, payload: { uid, data }}
};

export const createAdminTimeEntryFailure = (uid, error) => {
  return {type: CREATE_ADMIN_TIME_ENTRY_ERROR, payload: { uid, error }}
};

export const createAdminTimeEntryFinish = (filters, sort, sortOrder) => {
  return {type: CREATE_ADMIN_TIME_ENTRY_FINISH, payload: { filters, sort, sortOrder }}
};

export const updateAdminTimeEntry = (id, data, filters, sort, sortOrder) => {
  return {type: UPDATE_ADMIN_TIME_ENTRY, payload: { id, data, filters, sort, sortOrder }}
};

export const updateAdminTimeEntrySuccess = (id, data) => {
  return {type: UPDATE_ADMIN_TIME_ENTRY_SUCCESS, payload: {id, data}}
};

export const updateAdminTimeEntryFailure = (id, error) => {
  return {type: UPDATE_ADMIN_TIME_ENTRY_ERROR, payload: {id, error}}
};

export const deleteAdminTimeEntry = (id, filters, sort, sortOrder) => {
  return {type: DELETE_ADMIN_TIME_ENTRY, payload: { id, filters, sort, sortOrder }}
};

export const deleteAdminTimeEntrySuccess = (data) => {
  return {type: DELETE_ADMIN_TIME_ENTRY_SUCCESS, payload: data}
};

export const deleteAdminTimeEntryFailure = (error) => {
  return {type: DELETE_ADMIN_TIME_ENTRY_ERROR, payload: error}
};

export const filterAdminTimeSheets = (filters, sort, sortOrder) => {
  return {type: FILTER_ADMIN_TIME_SHEETS, payload: { filters, sort, sortOrder }};
};
