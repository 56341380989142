import { handleActions } from 'redux-actions';
import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  RESET_USERS_DATA,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR
} from '../actionTypes/usersDataActionTypes';

export const DEFAULT_USERS_STATE = {
  users: [],
  usersError: null,
  metaData: {},
  user: {},
  isUserValid: false,
  userError: {},
  usersBusy: false
};

export const users = handleActions(
  {
    [GET_USERS]: (state) => ({
      ...state,
      usersBusy: true
    }),
    [GET_USERS_SUCCESS]: (state, action) => ({
      ...state,
      users: action.payload.data,
      metaData: action.payload.meta,
      usersBusy: false
    }),
    [GET_USERS_ERROR]: (state, action) => ({
      ...state,
      usersError: action.payload.message,
      usersBusy: false
    }),
    [GET_USER]: (state) => ({
      ...state,
    }),
    [GET_USER_SUCCESS]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [ADD_USER]: (state) => ({
      ...state
    }),
    [ADD_USER_SUCCESS]: (state) => ({
      ...state,
      isUserValid: true
    }),
    [ADD_USER_ERROR]: (state, action) => ({
      ...state,
      isUserValid: false,
      userError: action.payload,
      usersError: true
    }),
    [UPDATE_USER]: (state) => ({
      ...state,
    }),
    [UPDATE_USER_SUCCESS]: (state) => ({
      ...state,
      isUserValid: true,
    }),
    [UPDATE_USER_ERROR]: (state, action) => ({
      ...state,
      isUserValid: false,
      userError: action.payload,
      usersError: true,
    }),
    [RESET_USERS_DATA]: (state, action) => ({
      ...state,
      user: {},
      isUserValid: false,
      userError: {},
      usersError: false
    }),
  },
  DEFAULT_USERS_STATE
);
