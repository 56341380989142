import React from 'react';
import { withTranslation } from 'react-i18next';

import Input from '../../../../components/Input/Input';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './style.scss';
import GridItem from "../../../../components/Grid/GridItem";
import CloseButton from "../../../../assets/img/buttons/delete.svg";
import IconButton from "@material-ui/core/IconButton";

class ContactPersonCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: true
    };
  }

  onCardClose = () => {
    this.setState({isCardOpen: !this.state.isCardOpen})
  };

  onInputChange = (fieldName, value) => {
    const {data, onChange} = this.props;
    const newData = {...data};
    newData[fieldName] = value;
    onChange(newData);
  };

  handlePhoneNumberChange = (phone) => {
    if(Number.isInteger(+phone)) {
      this.onInputChange('phone_1', phone)
    }
  };

  handleFaxNumberChange = (phone) => {
    if(Number.isInteger(+phone)) {
      this.onInputChange('fax', phone)
    }
  }

  handleViberNumberChange = (phone) => {
    if(Number.isInteger(+phone)) {
      this.onInputChange('viber', phone)
    }
  }

  handleWhatsAppNumberChange = (phone) => {
    if(Number.isInteger(+phone)) {
      this.onInputChange('whatsapp', phone)
    }
  }

  handleRemoveContactPerson = () => {
    const {removeContact, id} = this.props;
    removeContact(id)
  }

  render() {
    const {
      data,
      errors,
      errorTooltip,
      t
    } = this.props;
    const { isCardOpen } = this.state;
    return (
      <div className="contact-person-content">
        <GridItem className="contact-person-info">
          <div className="contact-person-header">
            <span className="person-title">{`${t('contact_person')} ${data.id}`}</span>
            <IconButton aria-label="close" className="close-btn" onClick={this.handleRemoveContactPerson}>
              <img alt="close-task-btn" src={CloseButton}/>
            </IconButton>
          </div>
          {isCardOpen &&
            <div className="contact-person-info-content">
              <GridItem className="contact-info-item">
                <GridItem className="contact-info contact-info-col2">
                  <span>{ t('name') }</span>
                  <div className="input-container">
                    <Input
                      value={data.first_name}
                      onChange={e => this.onInputChange('first_name', e.target.value)}
                      error={errors.first_name && errors.first_name.error}
                      errorMsg={errors.first_name && errors.first_name.message}
                      errorTooltip={errorTooltip}
                    />
                  </div>
                </GridItem>
                <GridItem className="contact-info contact-info-col2">
                  <span>{ t('surname') }</span>
                  <div className="input-container">
                    <Input
                      value={data.last_name}
                      onChange={e => this.onInputChange('last_name', e.target.value)}
                      error={errors.last_name && errors.last_name.error}
                      errorMsg={errors.last_name && errors.last_name.message}
                      errorTooltip={errorTooltip}
                    />
                  </div>
                </GridItem>
              </GridItem>
              <GridItem className="contact-info-item">
                <GridItem className="contact-info contact-info-col2">
                  <span>{ t('email') }</span>
                  <div className="input-container">
                    <Input
                      value={data.email}
                      onChange={e => this.onInputChange('email', e.target.value)}
                      error={errors.email && errors.email.error}
                      errorMsg={errors.email && errors.email.message}
                      errorTooltip={errorTooltip}
                    />
                  </div>
                </GridItem>
                <GridItem className="contact-info contact-info-col2">
                  <span>{ t('phone') }</span>
                  <div className="input-container">
                    <Input
                      value={data.phone_1}
                      onChange={e => this.handlePhoneNumberChange(e.target.value)}
                      error={errors.phone_1 && errors.phone_1.error}
                      errorMsg={errors.phone_1 && errors.phone_1.message}
                      errorTooltip={errorTooltip}
                    />
                  </div>
                </GridItem>
              </GridItem>
              <GridItem className="contact-info-item">
                <GridItem className="contact-info contact-info-col2">
                  <span>{ t('fax') }</span>
                  <div className="input-container">
                    <Input
                      value={data.fax}
                      onChange={e => this.handleFaxNumberChange(e.target.value)}
                      error={errors.fax && errors.fax.error}
                      errorMsg={errors.fax && errors.fax.message}
                      errorTooltip={errorTooltip}
                    />
                  </div>
                </GridItem>
                <GridItem className="contact-info contact-info-col2">
                  <span>{ t('skype') }</span>
                  <div className="input-container">
                    <Input
                      value={data.skype}
                      onChange={e => this.onInputChange('skype', e.target.value)}
                      error={errors.skype && errors.skype.error}
                      errorMsg={errors.skype && errors.skype.message}
                      errorTooltip={errorTooltip}
                    />
                  </div>
                </GridItem>
              </GridItem>
              <GridItem className="contact-info-item">
                <GridItem className="contact-info contact-info-col2">
                  <span>{ t('viber') }</span>
                  <div className="input-container">
                    <Input
                      value={data.viber}
                      onChange={e => this.handleViberNumberChange(e.target.value)}
                      error={errors.viber && errors.viber.error}
                      errorMsg={errors.viber && errors.viber.message}
                      errorTooltip={errorTooltip}
                    />
                  </div>
                </GridItem>
                <GridItem className="contact-info contact-info-col2">
                  <span>{ t('whatsapp') }</span>
                  <div className="input-container">
                    <Input
                      value={data.whatsapp}
                      onChange={e => this.handleWhatsAppNumberChange(e.target.value)}
                      error={errors.whatsapp && errors.whatsapp.error}
                      errorMsg={errors.whatsapp && errors.whatsapp.message}
                      errorTooltip={errorTooltip}
                    />
                  </div>
                </GridItem>
              </GridItem>
            </div>
          }
        </GridItem>
        <div
          className="card-btn"
          onClick={() => this.onCardClose()}
        >
          <div>
            {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ContactPersonCard);
