import React from "react";
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {getCities, getCountries} from "../../store/actions/locations";
import {
  addManufacturer,
  updateManufacturer,
  resetManufacturerData
} from "../../store/actions/manufacturer";
import {timeData} from '../../helpers/constants';
import {convertToFormData} from "../../utils/utils";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '../../components/CustomButtons/Button';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import GridItem from "../../components/Grid/GridItem";
import Input from "../../components/Input/Input";
import CloseButton from "../../assets/img/buttons/delete.svg";
import TextArea from "../../components/CustomTextArea";
import UploadImage from "../../components/UploadImg";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ContactPerson from "../Auth/RegisterManufacturer/ContactPersonPage/ContactPersonCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomAutocomplete from "../../components/CustomAutocomplete";

import CloseDialog from '../CloseDialog';

import './style.scss';

class CreateManufacturer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        logo: null,
        status: '1',
        name: '',
        website: '',
        address: '',
        city_id: '1',
        country_id: '1',
        email: '',
        delivery_full_service: 1,
        delivery_complete: 0,
        delivery_parts: 0,
        eu_vat_no: '',
        legal_name: '',
        billing_address: '',
        tax_no: '',
        contacts: [],
        schedule: {
          time_from: "09:00",
          time_to: "17:00"
        },
      },
      isExistingEmail: '0',
      userEmail: '',
      isCloseDialogOpen: false,
      haveEdits: false,
    }
  }

  componentDidMount() {
    this.props.getCountries();
    this.props.getCities(this.state.data.country_id, '');
    if (this.props.manufacturer.id) {
      this.init()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isManufacturerValid && this.props.isManufacturerValid) {
      this.props.onClose()
    }
  }

  componentWillUnmount() {
    const {resetData, isDetailsPage} = this.props;
    if (!isDetailsPage) {
      resetData()
    }
  }

  init = () => {
    const {
      manufacturer
    } = this.props;
    this.setState({
      data: manufacturer
    })
  }

  isDataReady = () => {
    const {
      countries,
      cities
    } = this.props;
    return countries.length !== 0; /*&& cities.length !== 0*/
  }

  handleExistingEmailChange = () => {
    this.setState({
      isExistingEmail: !this.state.isExistingEmail
    })
  }

  handleUserEmailChange = (value) => {
    this.setState({
      userEmail: value
    })
  }

  handleDataChange = (fieldName, value) => {
    const {data} = this.state;
    let newData = {...data, [fieldName]: value};
    if (fieldName === 'country_id') {
      this.props.getCities(value, '');
      newData.city_id = '';
    }
    this.setState({
      data: newData,
      haveEdits: true,
    })
  }

  handleScheduleTimeChange = (fieldName, value) => {
    const {data} = this.state;
    let newSchedule = {...data.schedule};
    newSchedule[fieldName] = value;
    this.setState({
      data: {
        ...data,
        schedule: newSchedule
      }
    })
  };

  addNewContactPerson = () => {
    const {data} = this.state;
    const newPerson = {id: data.contacts.length !== 0 ?  data.contacts.length + 1 : 1, email: '', phone_1: '', fax: '', skype: '', viber: '', whatsapp: ''};
    const newContactPersons = [...data.contacts, newPerson];
    const newData = {...data, contacts: newContactPersons}
    this.setState({
      data: newData,
      haveEdits: true
    })
  };

  removeContactPerson = (id) => {
    const {data} = this.state;
    const newContactPersons = [...data.contacts].filter((item,index) => index !== id);
    const newData = {...data, contacts: newContactPersons}
    this.setState({
      data: newData,
      haveEdits: true,
    })
  };

  handleContactPersonDataChange = (customerData) => {
    const {data} = this.state;
    const newContactPersons = [...data.contacts];
    newContactPersons[customerData.id - 1] = customerData;
    const newData = {...data, contacts: newContactPersons}
    this.setState({
      data: newData,
      haveEdits: true,
    })
  };

  handleOnClose = () => {
    if (this.props.onClose)
      this.props.onClose();
  };

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleOnClose();
    }
  };

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleOnClose();
    });
  };

  handleCreateUpdateButton = () => {
    const {
      createManufacturer,
      updateManufacturer,
      manufacturer,
      filters,
      searchTerms
    } = this.props;
    const {
      isExistingEmail,
      userEmail,
      data
    } = this.state;
    if (manufacturer.id) {
      const manufacturerData = convertToFormData({manufacturer: {
        ...data,
        logo: (data.logo && data.logo instanceof File) ? data.logo : null,
      }, _method:'put'});
      updateManufacturer({id: manufacturer.id, data: manufacturerData, filters, searchTerms})
    } else {
      let user = {};
      isExistingEmail ? user.existing_email = userEmail : user.email = userEmail;
      user.selected_language = i18n.language;
      const manufacturerData = convertToFormData({manufacturer: {
        ...data,
        logo: (data.logo && data.logo instanceof File) ? data.logo : null,
      }, user});
      createManufacturer({data: manufacturerData, filters, searchTerms})
    }
  }

  render() {
    const {
      isOpen,
      onClose,
      manufacturer,
      manufacturerStatuses,
      cities,
      countries,
      errors,
      t
    } = this.props;
    const {
      data,
      isExistingEmail,
      userEmail
    } = this.state;
    const manufacturerErrors = errors.manufacturer ? errors.manufacturer : {};
    const userErrors = errors.user ? errors.user : {};
    const emailError = userErrors.email ? userErrors.email : [];
    const existingEmailError = userErrors.existing_email ? userErrors.existing_email : [];
    const contactsErrors = errors.manufacturer && errors.manufacturer.contacts ? errors.manufacturer.contacts : [];
    return (
      <Dialog maxWidth={false} open={isOpen && this.isDataReady()}>
        <MuiDialogTitle className="create-manufacturer-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{manufacturer.id ? t('edit_manufacturer') : t('create_manufacturer')}</Typography>
          <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
            <img alt="create-manufacturer-close-btn" src={CloseButton}/>
          </IconButton>
        </MuiDialogTitle>
        <div className="create-manufacturer-content">
          <div className="create-manufacturer-container">
            <div className="manufacturer-logo">
              <span className="logo-title">{ t('logo') }</span>
              <UploadImage
                className="company-info-upload"
                errorMsg={manufacturerErrors.logo && manufacturerErrors.logo[0]}
                showErrorMessage={false}
                file={data.logo}
                onChange={(value) => this.handleDataChange('logo', value)}
                disableRemoveOption={true}
              />
            </div>
            {
              !manufacturer.id && (<>
                <div className="manufacturer-data-item checkbox-item">
                  <div className="delivery-options existing-email">
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isExistingEmail}
                            onChange={this.handleExistingEmailChange}
                          />
                        }
                        label={ t('existing_email') }
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="manufacturer-data-item">
                  <GridItem xs={12}>
                    <span className="item-title">{ t('registration_email') }</span>
                    <Input
                      value={userEmail}
                      error={
                        isExistingEmail ? existingEmailError.length !== 0 : emailError.length !== 0
                      }
                      errorMsg={isExistingEmail ? existingEmailError[0] : emailError[0]}
                      onChange={(e) => this.handleUserEmailChange(e.target.value)}
                      errorTooltip={true}
                    />
                  </GridItem>
                </div>
              </>)
            }
            <div className="manufacturer-data-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('company_name') }</span>
                <Input
                  autofocus={true}
                  value={data.name}
                  error={manufacturerErrors.name}
                  errorMsg={manufacturerErrors.name && manufacturerErrors.name[0]}
                  onChange={(e) => this.handleDataChange('name', e.target.value)}
                  errorTooltip={true}
                />
              </GridItem>
            </div>
            <div className="manufacturer-data-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('email') }</span>
                <Input
                  value={data.email}
                  error={manufacturerErrors.email}
                  errorMsg={manufacturerErrors.email && manufacturerErrors.email[0]}
                  onChange={(e) => this.handleDataChange('email', e.target.value)}
                  errorTooltip={true}
                />
              </GridItem>
            </div>
            <div className="manufacturer-data-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('website') }</span>
                <Input
                  value={data.website}
                  error={manufacturerErrors.website}
                  errorMsg={manufacturerErrors.website && manufacturerErrors.website[0]}
                  onChange={(e) => this.handleDataChange('website', e.target.value)}
                  errorTooltip={true}
                />
              </GridItem>
            </div>
            <div className="manufacturer-data-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('address') }</span>
                <Input
                  value={data.address}
                  error={manufacturerErrors.address}
                  errorMsg={manufacturerErrors.address && manufacturerErrors.address[0]}
                  onChange={(e) => this.handleDataChange('address', e.target.value)}
                  errorTooltip={true}
                />
              </GridItem>
            </div>
            <div className="manufacturer-data-row-container">
              <div className="manufacturer-data-item">
                <GridItem xs={12}>
                  <span className="item-title">{ t('city') }</span>
                  <CustomAutocomplete
                    buttonText={data.city_id ? data.city_id : ''}
                    buttonProps={{className: '', round: true, size: "sm"}}
                    discardIcon={true}
                    dropdownList={cities ? cities : []}
                    hoverColor="dark"
                    className={this.props.manufacturer && this.props.manufacturer.id ? 'autocomplete-size-edit' : 'autocomplete-size-create'}
                    getData={(value) => data.country_id ? this.props.getCities(data.country_id, value) : null}
                    onClick={(value) => this.handleDataChange('city_id', value.id)}
                  />
                </GridItem>
              </div>
              <div className="manufacturer-data-item">
                <GridItem xs={12}>
                  <span className="item-title">{ t('country') }</span>
                  <Dropdown
                    buttonText={data.country_id ? data.country_id : ''}
                    buttonProps={{className: 'dropdown-style', round: true, size: "sm"}}
                    dropdownList={countries ? countries : []}
                    className={this.props.manufacturer && this.props.manufacturer.id ? 'dropdown-size-edit' : 'dropdown-size-create'}
                    hoverColor="dark"
                    onClick={(value) => this.handleDataChange('country_id', value.id)}
                  />
                </GridItem>
              </div>
            </div>
            <div className="manufacturer-data-item checkbox-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('delivery_options') }</span>
                <div className="delivery-options">
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.delivery_full_service === 1}
                            onChange={() => this.handleDataChange('delivery_full_service', data.delivery_full_service === 1 ? 0 : 1)}
                          />
                        }
                        label={ t('full_service') }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.delivery_complete === 1}
                            onChange={() => this.handleDataChange('delivery_complete', data.delivery_complete === 1 ? 0 : 1)}
                          />
                        }
                        label={ t('complete_delivery') }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.delivery_parts === 1}
                            onChange={() => this.handleDataChange('delivery_parts', data.delivery_parts === 1 ? 0 : 1)}
                          />
                        }
                        label={ t('parts_delivery') }
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </GridItem>
            </div>
            <div className="manufacturer-data-item checkbox-item">
              <GridItem xs={12} className="working-hours-container">
                <span className="item-title">{ t('working_hours_monday_friday') }</span>
                <div className="time-selection">
                  <Dropdown
                    buttonText={data.schedule.time_from}
                    buttonProps={{className: 'dropdown-style', round: true, size: "sm"}}
                    dropdownList={timeData}
                    hoverColor="dark"
                    onClick={(data) => this.handleScheduleTimeChange('time_from', data.id)}
                  />
                  <span className="time-divider">-</span>
                  <Dropdown
                    buttonText={data.schedule.time_to}
                    buttonProps={{className: 'dropdown-style', round: true, size: "sm"}}
                    dropdownList={timeData}
                    hoverColor="dark"
                    onClick={(data) => this.handleScheduleTimeChange('time_to', data.id)}
                  />
                </div>
              </GridItem>
            </div>
          </div>
          <div className="create-manufacturer-container">
            <div className="status-container">
              <div className={`status-dropdown ${data.status.toString() === '0' ? 'red' : 'green'}`}>
                <Dropdown
                  buttonText={data.status.toString()}
                  buttonIconType="white"
                  buttonProps={{className: 'dropdown-blue-style status-dropdown', round: true, size: "sm"}}
                  hoverColor="dark"
                  dropdownList={manufacturerStatuses}
                  onClick={data => this.handleDataChange('status', data.id)}
                />
              </div>
            </div>
            <div className="manufacturer-data-row-container">
              <div className="manufacturer-data-item discount-item">
                <GridItem xs={12}>
                  <span className="item-title">{ t('discount') }</span>
                  <Input
                    value={data.discount}
                    error={manufacturerErrors.discount}
                    errorMsg={manufacturerErrors.discount && manufacturerErrors.discount[0]}
                    onChange={(e) => this.handleDataChange('discount', e.target.value)}
                    errorTooltip={true}
                  />
                </GridItem>
              </div>
            </div>
            <div className="manufacturer-data-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('legal_registration_name') }</span>
                <Input
                  value={data.legal_name}
                  error={manufacturerErrors.legal_name}
                  errorMsg={manufacturerErrors.legal_name && manufacturerErrors.legal_name[0]}
                  onChange={(e) => this.handleDataChange('legal_name', e.target.value)}
                  errorTooltip={true}
                />
              </GridItem>
            </div>
            <div className="manufacturer-data-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('billing_address') }</span>
                <TextArea
                  className="billing-address"
                  value={data.billing_address}
                  onChange={(e) => this.handleDataChange('billing_address', e.target.value)}
                  error={manufacturerErrors.billing_address}
                />
              </GridItem>
            </div>
            <div className="manufacturer-data-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('tax_number') }</span>
                <Input
                  value={data.tax_no}
                  error={manufacturerErrors.tax_no}
                  errorMsg={manufacturerErrors.tax_no && manufacturerErrors.tax_no[0]}
                  onChange={(e) => this.handleDataChange('tax_no', e.target.value)}
                  errorTooltip={true}
                />
              </GridItem>
            </div>
            <div className="manufacturer-data-item">
              <GridItem xs={12}>
                <span className="item-title">{ t('eu_vat_registration_number') }</span>
                <Input
                  value={data.eu_vat_no}
                  error={manufacturerErrors.eu_vat_no}
                  errorMsg={manufacturerErrors.eu_vat_no && manufacturerErrors.eu_vat_no[0]}
                  onChange={(e) => this.handleDataChange('eu_vat_no', e.target.value)}
                  errorTooltip={true}
                />
              </GridItem>
            </div>
            <PerfectScrollbar  className="contact-persons-content">
              {data && data.contacts && data.contacts.length !== 0 &&
                data.contacts.map((item, index) => {
                  let errorsObj = {};
                  if (contactsErrors[index]) {
                    errorsObj = { ...contactsErrors[index] };
                    const errorsKeys = Object.keys(errorsObj);
                    for (let i = 0, len = errorsKeys.length; i < len; ++i) {
                      const errorsKey = errorsKeys[i];
                      const errors = errorsObj[errorsKey];
                      errors['message'] = errors[0];
                      errors['error'] = true;
                      delete errors[0];
                    }
                  }
                  return (
                    <ContactPerson
                      key={index}
                      id={index}
                      data={item}
                      errors={errorsObj}
                      removeContact={this.removeContactPerson}
                      onChange={this.handleContactPersonDataChange}
                      errorTooltip={true}
                    />
                  );
                })
              }
            </PerfectScrollbar>
            <div className="add-person-btn">
              <Button
                onClick={() => this.addNewContactPerson()}
              >
                + { t('add_contact_person').toUpperCase() }
              </Button>
            </div>
          </div>
        </div>
        <div className="manufacturer-btn-container">
          <Button
            className="cancel-btn"
            onClick={onClose}
          >
            { t('cancel') }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleCreateUpdateButton}
            disabled={this.props.operationInProgress}
          >
            {this.props.manufacturer.id ? t('update').toUpperCase() : t('save').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('close_manufacturer')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    manufacturer: state.manufacturer.manufacturer,
    operationInProgress: state.manufacturer.manufacturersMutBusy,
    isManufacturerValid: state.manufacturer.isManufacturerValid,
    errors: state.manufacturer.manufacturerError,
    manufacturerStatuses: state.manufacturerProviders.manufacturerStatuses,
    countries: state.locations.countries,
    cities: state.locations.cities,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createManufacturer: (data) => dispatch(addManufacturer(data)),
    updateManufacturer: (data) => dispatch(updateManufacturer(data)),
    resetData: () => dispatch(resetManufacturerData()),
    getCountries: () => dispatch(getCountries()),
    getCities: (countryId, searchTerm) => dispatch(getCities(countryId, searchTerm))
  }
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateManufacturer));
