import { handleActions } from 'redux-actions';
import {
  GET_MANUFACTURERS_DELIVERY_TYPES_SUCCESS,
  GET_MANUFACTURERS_DELIVERY_TYPES_ERROR,
  GET_MANUFACTURERS_STATUSES_SUCCESS,
  GET_MANUFACTURERS_STATUSES_ERROR,
  GET_MANUFACTURERS_LOCATIONS_SUCCESS,
  GET_MANUFACTURERS_LOCATIONS_ERROR
} from '../actionTypes/manufacturerProvidersActionTypes';

export const DEFAULT_MANUFACTURER_PROVIDERS_STATE = {
  manufacturerDeliveryTypes: [],
  manufacturerLocations: [],
  manufacturerStatuses: []
};

export const manufacturerProviders = handleActions(
  {
    [GET_MANUFACTURERS_DELIVERY_TYPES_SUCCESS]: (state, action) => ({
      ...state,
      manufacturerDeliveryTypes: action.payload
    }),
    [GET_MANUFACTURERS_STATUSES_SUCCESS]: (state, action) => ({
      ...state,
      manufacturerStatuses: action.payload
    }),
    [GET_MANUFACTURERS_LOCATIONS_SUCCESS]: (state, action) => ({
      ...state,
      manufacturerLocations: action.payload
    }),
  },
  DEFAULT_MANUFACTURER_PROVIDERS_STATE
);
