import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {logsServiceApi} from 'services/API';
import {
  getLogsSuccess,
  getLogsFailure
} from '../store/actions/logs';
import { GET_LOGS, FILTER_LOGS } from '../store/actionTypes/logsActionTypes';

export const getLogsEpic = (actions$) => actions$.pipe(
  ofType(GET_LOGS),
  switchMap((action) => from(logsServiceApi.getLogs(action.payload.id, action.payload.page)).pipe(
    switchMap((response) => {
      return of(getLogsSuccess(response))
    }),
    catchError( err => {
        return of(getLogsFailure(err))
      }
    )
  ))
);


export const filterLogsEpic = (actions$) => actions$.pipe(
  ofType(FILTER_LOGS),
  switchMap((action) => from(logsServiceApi.filterLogs(action.payload.id, action.payload.filters)).pipe(
    switchMap((response) => {
      return of(getLogsSuccess(response))
    }),
    catchError( err => {
        return of(getLogsFailure(err))
      }
    )
  ))
);
