export const GET_HOURS_LOGGED = 'timeSheetProvidersEpic/GET_HOURS_LOGGED';
export const GET_HOURS_LOGGED_SUCCESS = 'timeSheetProvidersEpic/GET_HOURS_LOGGED_SUCCESS';
export const GET_HOURS_LOGGED_ERROR = 'timeSheetProvidersEpic/GET_HOURS_LOGGED_ERROR';

export const GET_ORDERS_LIST = 'timeSheetProvidersEpic/GET_ORDERS_LIST';
export const GET_ORDERS_LIST_SUCCESS = 'timeSheetProvidersEpic/GET_ORDERS_LIST_SUCCESS';
export const GET_ORDERS_LIST_ERROR = 'timeSheetProvidersEpic/GET_ORDERS_LIST_ERROR';

export const GET_POSITIONS_LIST = 'timeSheetProvidersEpic/GET_POSITIONS_LIST';
export const GET_POSITIONS_LIST_SUCCESS = 'timeSheetProvidersEpic/GET_POSITIONS_LIST_SUCCESS';
export const GET_POSITIONS_LIST_ERROR = 'timeSheetProvidersEpic/GET_POSITIONS_LIST_ERROR';

export const GET_TASKS_LIST = 'timeSheetProvidersEpic/GET_TASKS_LIST';
export const GET_TASKS_LIST_SUCCESS = 'timeSheetProvidersEpic/GET_TASKS_LIST_SUCCESS';
export const GET_TASKS_LIST_ERROR = 'timeSheetProvidersEpic/GET_TASKS_LIST_ERROR';

export const GET_COST_CENTERS_LIST = 'timeSheetProvidersEpic/GET_COST_CENTERS_LIST';
export const GET_COST_CENTERS_LIST_SUCCESS = 'timeSheetProvidersEpic/GET_COST_CENTERS_LIST_SUCCESS';
export const GET_COST_CENTERS_LIST_ERROR = 'timeSheetProvidersEpic/GET_COST_CENTERS_LIST_ERROR';

export const GET_COST_CENTERS_ITEMS_LIST = 'timeSheetProvidersEpic/GET_COST_CENTERS_ITEMS_LIST';
export const GET_COST_CENTERS_ITEMS_LIST_SUCCESS = 'timeSheetProvidersEpic/GET_COST_CENTERS_ITEMS_LIST_SUCCESS';
export const GET_COST_CENTERS_ITEMS_LIST_ERROR = 'timeSheetProvidersEpic/GET_COST_CENTERS_ITEMS_LIST_ERROR';

export const GET_POSITION_COST_CENTERS_LIST = 'timeSheetProvidersEpic/GET_POSITION_COST_CENTERS_LIST';
export const GET_POSITION_COST_CENTERS_LIST_SUCCESS = 'timeSheetProvidersEpic/GET_POSITION_COST_CENTERS_LIST_SUCCESS';
export const GET_POSITION_COST_CENTERS_LIST_ERROR = 'timeSheetProvidersEpic/GET_POSITION_COST_CENTERS_LIST_ERROR';

export const GET_POSITION_COST_CENTERS_ITEMS_LIST = 'timeSheetProvidersEpic/GET_POSITION_COST_CENTERS_ITEMS_LIST';
export const GET_POSITION_COST_CENTERS_ITEMS_LIST_SUCCESS = 'timeSheetProvidersEpic/GET_POSITION_COST_CENTERS_ITEMS_LIST_SUCCESS';
export const GET_POSITION_COST_CENTERS_ITEMS_LIST_ERROR = 'timeSheetProvidersEpic/GET_POSITION_COST_CENTERS_ITEMS_LIST_ERROR';

export const RESET_TIME_SHEET_PROVIDERS = 'timeSheetProvidersEpic/RESET_TIME_SHEET_PROVIDERS';
