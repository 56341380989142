import React from "react";
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {resetCustomerData, removeCustomer} from '../../store/actions/customers';
import {
  getCategories,
} from 'store/actions/categories';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseButton from "../../assets/img/buttons/delete.svg";

import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';


import {CreateContactAllTabs, CreateContactTab} from "../../constants/constants";
import ProjectTabs from "../../components/ProjectTabs/ProjectTabs";

import ContactDetailsView from './ContactDetailsView';
import ShippingAddressView from "./ShippingAddressView";
import FinanceView from "./FinanceView";
import NotesView from "./NotesView";

import './style.scss';

class ViewContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: CreateContactTab.DETAILS,
    };
  }

  componentDidMount() {
    this.props.getCategories(null, [1]);
  }
  componentWillUnmount() {
    this.props.resetData()
  }

  onTabItemClick = id => {
    this.setState({
      selectedTabId: id
    });
  };

  handleRemoveButton = id => {
    const {removeCustomer, onClose} = this.props;
    removeCustomer(id);
    onClose()
  }

  render() {
    const {
      isOpen,
      onClose,
      customer,
      allowDelete,
      onEditContact,
      id,
      t
    } = this.props;
    const {selectedTabId} = this.state;
    let selectedComponent;
    switch(selectedTabId) {
      case 0:
        selectedComponent =
          <ContactDetailsView
            data={customer}
            categories={this.props.categories}
          />;
        break;
      case 1:
        selectedComponent =
          <ShippingAddressView
            data={customer.shipping_addresses}
          />;
        break;
      case 2:
        selectedComponent =
          <FinanceView
            data={customer}
          />;
        break;
      case 3:
        selectedComponent =
          <NotesView
            data={customer}
          />;
        break;
      default:
        return null;
    };
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="view-contact-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ t('view_contact') }</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="view-contact-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="view-contact-content">
          <div className="contact-title-container">
            <GridItem xs={6}>
              <div className="contact-name">{`${customer.first_name} ${customer.last_name ? customer.last_name : ''}`}</div>
            </GridItem>
            <GridItem xs={6}>
              <div className="view-contact-btn">
                <Button
                  className={ "cancel-btn" + (!allowDelete ? " hidden" : "") } 
                  onClick={() => this.handleRemoveButton(id)}
                >
                  { t('delete').toUpperCase() }
                </Button>
                <Button
                  className="create-btn"
                  onClick={() => onEditContact(id)}
                >
                  { t('edit').toUpperCase() }
                </Button>
              </div>
            </GridItem>
          </div>
          <ProjectTabs
            tabs={CreateContactAllTabs}
            selectedTab={selectedTabId}
            onTabItemClick={this.onTabItemClick}
          />
          {Object.keys(customer).length !== 0 && selectedComponent}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    customer: state.customers.customer,
    categories: state.categories.categories,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(resetCustomerData()),
    removeCustomer: (id) =>  dispatch(removeCustomer(id)),
    getCategories: (page, type, order, searchTerms, level1Only) =>
    dispatch(getCategories({page, type, order, searchTerms, level1Only})),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ViewContact));
