import React from "react";
import Day from './DayComponent';

import './style.scss';

const calendarData = [
  {date: '29', anotherMonth: true, isWorkingDay: false, isAvailable: false},
  {date: '30', anotherMonth: true, isWorkingDay: false, isAvailable: false},
  {date: '01', isWorkingDay: false, isAvailable: false},
  {date: '02', isWorkingDay: true, isAvailable: false},
  {date: '03', isWorkingDay: true, isAvailable: true},
  {date: '04', isWorkingDay: false, isAvailable: true},
  {date: '05', isWorkingDay: false, isAvailable: true},
  {date: '06', isWorkingDay: true, isAvailable: true},
  {date: '07', isWorkingDay: true, isAvailable: true},
  {date: '08', isWorkingDay: true, isAvailable: true},
  {date: '09', isWorkingDay: true, isAvailable: false},
  {date: '10', isWorkingDay: true, isAvailable: true},
  {date: '11', isWorkingDay: false, isAvailable: true},
  {date: '12', isWorkingDay: false, isAvailable: true},
  {date: '13', isWorkingDay: true, isAvailable: true},
  {date: '14', isWorkingDay: true, isAvailable: true},
  {date: '15', isWorkingDay: true, isAvailable: true},
  {date: '16', isWorkingDay: true, isAvailable: true},
  {date: '17', isWorkingDay: true, isAvailable: false},
  {date: '18', isWorkingDay: false, isAvailable: false},
  {date: '19', isWorkingDay: false, isAvailable: false},
  {date: '20', isWorkingDay: true, isAvailable: true},
  {date: '21', isWorkingDay: true, isAvailable: true},
  {date: '22', isWorkingDay: true, isAvailable: true},
  {date: '23', isWorkingDay: true, isAvailable: true},
  {date: '24', isWorkingDay: true, isAvailable: true},
  {date: '25', isWorkingDay: false, isAvailable: true},
  {date: '26', isWorkingDay: false, isAvailable: true},
  {date: '27', isWorkingDay: true, isAvailable: true},
  {date: '28', isWorkingDay: true, isAvailable: true},
  {date: '29', isWorkingDay: true, isAvailable: true},
  {date: '30', isWorkingDay: true, isAvailable: false},
  {date: '31', isWorkingDay: false, isAvailable: false},

  ];

class Calendar extends React.Component {
  getWeekDays = () => {
    let data = [];
    calendarData.forEach((item, index) => {
      const diff = index % 7;
      const arrNum = Math.floor(index / 7);
      switch (diff) {
        case 0:
          data.push([item]);
          break;
        default:
          data[arrNum].push(item);
          break;
    }});
    return data;
  };
  render() {
    const data = this.getWeekDays();
    return (
      <div className="calendar-container">
        <div className="week-days-container">
          <span>Monday</span>
          <span>Tuesday</span>
          <span>Wednesday</span>
          <span>Thursday</span>
          <span>Friday</span>
          <span>Saturday</span>
          <span>Sunday</span>
        </div>
        {data.map((item, index) => {
          return <div className="week-container" id={index}>{item.map((el, index) => <Day key={index} data={el} />)}</div>
        })}
      </div>
    );
  }
}

export default Calendar;