import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { mapTo, catchError, delay } from 'rxjs/operators';
import {
  getProjectStatusesSuccessful,
  getProjectStatusesFailed
} from 'store/actions/projectStatuses';
import { GET_PROJECT_STATUSES } from 'store/actionTypes/projectStatuses';
import { projectStatuses as dummyProjectStatuses } from './epicsData';

export const getProjectStatuses = actions$ => {
  return actions$.pipe(
    ofType(GET_PROJECT_STATUSES),
    delay(2000),
    mapTo(getProjectStatusesSuccessful(dummyProjectStatuses)),
    catchError((error, caught) =>
      merge(of(getProjectStatusesFailed(error)), caught)
    )
  );
};
