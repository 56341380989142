import React from 'react';
import { withTranslation } from 'react-i18next';
import './style.scss';
import { DropzoneArea } from 'material-ui-dropzone';

class ProjectOrderFileUpload extends React.Component {
  handleFileChange = files => {
    const file = files[files.length - 1];
    this.props.onChange(file);
  };

  render() {
    const { t } = this.props;
    return (
      <div className="files-drop-zone-container">
        <DropzoneArea
          onChange={this.handleFileChange}
          showPreviewsInDropzone={false}
          showAlerts={false}
          filesLimit={100}
          maxFileSize={100 * 1024 * 1024}  /* 100 MB max */
          dropzoneText={
            <>
            { t('drag_and_drop_files_here_or') }
            <div className="drop-zone-browse-btn">{ t('browse') }...</div>
            </>
          }
        />
      </div>
    );
  }
}

export default withTranslation()(ProjectOrderFileUpload);
