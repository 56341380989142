import {
    GET_COWORKERS,
    GET_COWORKERS_SUCCESS,
    GET_COWORKERS_ERROR,
    GET_COWORKER,
    GET_COWORKER_SUCCESS,
    ADD_COWORKER,
    UPDATE_COWORKER,
    REMOVE_COWORKER
} from '../actionTypes/coworkersActionTypes';

export const getCoworkers = (page) => {
    return {type: GET_COWORKERS, payload: page}
};

export const getCoworkersSuccess = (coworkers) => {
  return {type: GET_COWORKERS_SUCCESS, payload: coworkers}
};

export const getCoworkersFailure = (error) => {
    return {type: GET_COWORKERS_ERROR, payload: error}
};

export const getCoworker = (id) => {
    return {type: GET_COWORKER, payload: id}
};

export const getCoworkerSuccess = (coworker) => {
    return {type: GET_COWORKER_SUCCESS, payload: coworker}
};

export const addCoworker = (data) => {
    return {type: ADD_COWORKER, payload: data}
};

export const updateCoworker = ({id, data}) => {
    return {type: UPDATE_COWORKER, payload: {id, data}}
};

export const removeCoworker = (id) => {
    return {type: REMOVE_COWORKER, payload: id}
};
