import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';

import RemoveDialog from '../RemoveDialog';
import DropdownButton from '../../components/CustomButtons/DropdownButton';
import Button from 'components/CustomButtons/Button.jsx';
import Position from './Position';

import './style.scss';

class MaterialList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      specialErrors: null,
      selectedPositions: [],
      isRemoveDialogOpen: false,
      expandFirstCard: true,
    };
  }

  componentDidMount() {
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    wrapper.scrollTop = 0;
    /*if (this.props.positions.length === 0) {
      this.props.onAddPosition()
    }*/
    if (this.props.history.location.state && this.props.history.location.state.isCreatePosition) {
      this.props.onAddPosition()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.positions.length < prevProps.positions.length) {
      const wrapper = document.getElementById('ikt-admin-mainPanel');
      wrapper.scrollTop = 0;
    }
    if (this.state.expandFirstCard) {
      this.setState({
        expandFirstCard: false,
      });
    }
  }

  handleAddPosition = (isArticle=false) => {
    const { onAddPosition, positions } = this.props;
    const { specialErrors } = this.state;
    if (positions.length > 0) {
      const errors = {};
      if (Object.keys(errors).length > 0) {
        this.setState({
          specialErrors: {
            [positions.length - 1]: errors,
          },
        });
        onAddPosition(isArticle);
      } else {
        if (specialErrors) {
          this.setState({
            specialErrors: null,
          });
        }
        onAddPosition(isArticle);
      }
    } else {
      if (specialErrors) {
        this.setState({
          specialErrors: null,
        });
      }
      onAddPosition(isArticle);
    }
  }

  getPositionErrors = (index) => {
    const { errors } = this.props;
    const { specialErrors } = this.state;
    if (errors && errors[index])
      return errors[index];
    else if (specialErrors && specialErrors[index])
      return specialErrors[index];
    else
      return null;
  }

  handlePositionDataChange = (index, data) => {
    const { specialErrors } = this.state;
    if (specialErrors && specialErrors[index]) {
      const newSpecialError = specialErrors[index];
      if (data.name)
        newSpecialError['name'] = undefined;
      this.setState({
        specialErrors: {
          [index]: newSpecialError,
        },
      });
    }
    this.props.onDataChange(index, data);
  }

  handlePositionSelectionChange = (index) => {
    const { selectedPositions } = this.state;
    const newSelectedPositions = [...selectedPositions];

    const indexOfIndex = selectedPositions.indexOf(index);
    if (indexOfIndex != -1) {
      newSelectedPositions.splice(indexOfIndex, 1);
    } else {
      newSelectedPositions.push(index);
      newSelectedPositions.sort();
    }
    this.setState({
      selectedPositions: newSelectedPositions,
    });
  }

  handleSelectAllButton = () => {
    const { positions } = this.props;
    this.setState({
      selectedPositions: positions.map((el, i) => (i)),
    });
  }

  handleDeselectAllButton = () => {
    this.setState({
      selectedPositions: [],
    });
  }

  handleDeleteSelectedPositionsButton = () => {
    this.setState({
      isRemoveDialogOpen: true,
    });
  }

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveDialogOpen: false,
    });
  }

  handleRemoveSelectedPositions = () => {
    const { onRemovePositions } = this.props;
    const { selectedPositions } = this.state;
    onRemovePositions(selectedPositions);
    this.setState({
      selectedPositions: [],
      isRemoveDialogOpen: false,
    });
  }

  renderSelectButton = () => {
    const { selectedPositions } = this.state;
    const { t } = this.props;
    const selectAllButton = (<Button
      onClick={this.handleSelectAllButton}
      className="create-btn select-dropdown-button-child"
    >
      { t('select_all') }
    </Button>);
    const deselectAllButton = (<Button
      onClick={this.handleDeselectAllButton}
      className="create-btn select-dropdown-button-child"
    >
      { t('deselect_all') }
    </Button>);
    const deleteSelectedButton = selectedPositions.length > 0 ? (<Button
      onClick={this.handleDeleteSelectedPositionsButton}
      className="delete-btn select-dropdown-button-child"
    >
      { t('delete_selected') }
    </Button>) : null;

    return (<DropdownButton
      className="btn-dropdown"
      buttonText="Select"
    >
      { selectAllButton }
      { deselectAllButton }
      { deleteSelectedButton }
    </DropdownButton>);
  }

  render() {
    const {
      onDataChange,
      onAddPosition,
      onAddSubposition,
      onRearrangeSubposition,
      onRemovePosition,
      onFileChange,
      positions,
      fittingsPositions,
      errors,
      coworkers,
      isNewPositionCreated,
      newSubpositionIndex,
      t
    } = this.props;
    const {
      selectedPositions,
      isRemoveDialogOpen,
      expandFirstCard,
    } = this.state;
    return (
      <div className="material-list-positions-container">
        <div className="material-list-positions-header">
          { this.renderSelectButton() }
        </div>
        <div className="materials-list-positions-content" >
          {
            positions && positions.length !== 0
              && positions.map((position, index) =>
                {
                  return (
                    <Position
                      onDataChange={(data) => this.handlePositionDataChange(index, data)}
                      onRemovePosition={() => onRemovePosition(index)}
                      onAddSubposition={onAddSubposition}
                      onRearrangeSubposition={(value) => onRearrangeSubposition(index, value.value)}
                      onFileChange={e => onFileChange(e, index)}
                      onSelectionChange={() => this.handlePositionSelectionChange(index)}
                      key={index}
                      isSelected={selectedPositions.indexOf(index) != -1}
                      isCardOpen={(expandFirstCard && index === 0) || (isNewPositionCreated && index === positions.length - 1) || (newSubpositionIndex === index)}
                      data={position}
                      fittings={fittingsPositions[index].fittings}
                      errors={this.getPositionErrors(index)}
                      coworkers={coworkers}
                      id={index}
                    />
                  );
                }
              )
          }
        </div>
        <div className="material-list-add-buttons" xs={2}>
          <Button
            className="material-list-add-position-btn"
            onClick={() => this.handleAddPosition()}
          >
            + { t('position').toUpperCase() }
          </Button>
          <Button
            className="material-list-add-article-btn"
            onClick={() => this.handleAddPosition(true)}
          >
            + { t('article').toUpperCase() }
          </Button>
        </div>
        <div className="list-space-holder">{ '\u00A0' }</div>
        {isRemoveDialogOpen && (
          <RemoveDialog
            isOpen={isRemoveDialogOpen}
            title={t('multiple_positions')}
            text={t('multiple_positions_along_associated_tasks')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleRemoveSelectedPositions}
          />
        )}
      </div>
    )
  }
}

const mapStateToProp = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(withTranslation()(MaterialList)));
