import React from "react";
import { withTranslation } from 'react-i18next';
import TimerUtil from './TimerUtil';

import Button from "../../components/CustomButtons/Button";

class Timer extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    TimerUtil.subscribe(this);
  }

  componentWillUnmount() {
    TimerUtil.unsubscribe(this);
  }

  onTimerChange = (time) => {
    this.forceUpdate();
  }

  render() {
    const { t, onTimerStart, onTimerPause, onTimerResume } = this.props;
    const currentTime = (TimerUtil.time / 1000 / 3600);
    return (
      <div className="timer-start">
        <span className="timer-time">
          <div className="timer-wrapper">
            { TimerUtil.msToTime(TimerUtil.time) }
          </div>
          <div className="hours-wrapper">
            <span>{ currentTime.toFixed(2) } {t('hrs')}</span>
          </div>
        </span>
        { !TimerUtil.isRunning && !TimerUtil.isPaused && <Button className="timer-btn control-btn" onClick={onTimerStart}>{ t('start').toUpperCase() }</Button> }
        { TimerUtil.isRunning && !TimerUtil.isPaused && <Button className="timer-btn control-btn" onClick={onTimerPause}>{ t('stop').toUpperCase() }</Button> }
        { !TimerUtil.isRunning && TimerUtil.isPaused && <Button className="timer-btn control-btn" onClick={onTimerResume}>{ t('resume').toUpperCase() }</Button> }
      </div>
    );
  }
}

export default withTranslation()(Timer);
