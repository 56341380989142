export const GET_NORMAL_SCHEDULE = 'manufacturerEpic/GET_NORMAL_SCHEDULE';
export const GET_NORMAL_SCHEDULE_SUCCESS = 'manufacturerEpic/GET_NORMAL_SCHEDULE_SUCCESS';
export const GET_NORMAL_SCHEDULE_ERROR = 'manufacturerEpic/GET_NORMAL_SCHEDULE_ERROR';

export const UPDATE_NORMAL_SCHEDULE = 'manufacturerEpic/UPDATE_NORMAL_SCHEDULE';
export const UPDATE_NORMAL_SCHEDULE_SUCCESS = 'manufacturerEpic/UPDATE_NORMAL_SCHEDULE_SUCCESS';
export const UPDATE_NORMAL_SCHEDULE_ERROR = 'manufacturerEpic/UPDATE_NORMAL_SCHEDULE_ERROR';

export const GET_DATE_SCHEDULE = 'manufacturerEpic/GET_DATE_SCHEDULE';
export const GET_DATE_SCHEDULE_SUCCESS = 'manufacturerEpic/GET_DATE_SCHEDULE_SUCCESS';
export const GET_DATE_SCHEDULE_ERROR = 'manufacturerEpic/GET_DATE_SCHEDULE_ERROR';

export const UPDATE_DATE_SCHEDULE = 'manufacturerEpic/UPDATE_DATE_SCHEDULE';
export const UPDATE_DATE_SCHEDULE_SUCCESS = 'manufacturerEpic/UPDATE_DATE_SCHEDULE_SUCCESS';
export const UPDATE_DATE_SCHEDULE_ERROR = 'manufacturerEpic/UPDATE_DATE_SCHEDULE_ERROR';

export const DELETE_DATE_SCHEDULE = 'manufacturerEpic/DELETE_DATE_SCHEDULE';
export const DELETE_DATE_SCHEDULE_SUCCESS = 'manufacturerEpic/DELETE_DATE_SCHEDULE_SUCCESS';
export const DELETE_DATE_SCHEDULE_ERROR = 'manufacturerEpic/DELETE_DATE_SCHEDULE_ERROR';

export const GET_MONTH_SCHEDULE = 'manufacturerEpic/GET_MONTH_SCHEDULE';
export const GET_MONTH_SCHEDULE_SUCCESS = 'manufacturerEpic/GET_MONTH_SCHEDULE_SUCCESS';
export const GET_MONTH_SCHEDULE_ERROR = 'manufacturerEpic/GET_MONTH_SCHEDULE_ERROR';
