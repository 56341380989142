export const START_TIMER = 'timersEpic/START_TIMER';
export const START_TIMER_SUCCESS = 'timersEpic/START_TIMER_SUCCESS';
export const START_TIMER_ERROR = 'timersEpic/START_TIMER_ERROR';

export const PAUSE_TIMER = 'timersEpic/PAUSE_TIMER';
export const PAUSE_TIMER_SUCCESS = 'timersEpic/PAUSE_TIMER_SUCCESS';
export const PAUSE_TIMER_ERROR = 'timersEpic/PAUSE_TIMER_ERROR';

export const RESUME_TIMER = 'timersEpic/RESUME_TIMER';
export const RESUME_TIMER_SUCCESS = 'timersEpic/RESUME_TIMER_SUCCESS';
export const RESUME_TIMER_ERROR = 'timersEpic/RESUME_TIMER_ERROR';

export const UPDATE_TIMER_NOTE = 'timersEpic/UPDATE_TIMER_NOTE';
export const UPDATE_TIMER_NOTE_SUCCESS = 'timersEpic/UPDATE_TIMER_NOTE_SUCCESS';
export const UPDATE_TIMER_NOTE_ERROR = 'timersEpic/UPDATE_TIMER_NOTE_ERROR';

export const FINALIZE_TIMER = 'timersEpic/FINALIZE_TIMER';
export const FINALIZE_TIMER_SUCCESS = 'timersEpic/FINALIZE_TIMER_SUCCESS';
export const FINALIZE_TIMER_ERROR = 'timersEpic/FINALIZE_TIMER_ERROR';

export const DELETE_TIMER = 'timersEpic/DELETE_TIMER';
export const DELETE_TIMER_SUCCESS = 'timersEpic/DELETE_TIMER_SUCCESS';
export const DELETE_TIMER_ERROR = 'timersEpic/DELETE_TIMER_ERROR';

export const GET_CURRENT_TIMER = 'timersEpic/GET_CURRENT_TIMER';
export const GET_CURRENT_TIMER_SUCCESS = 'timersEpic/GET_CURRENT_TIMER_SUCCESS';
export const GET_CURRENT_TIMER_ERROR = 'timersEpic/GET_CURRENT_TIMER_ERROR';

export const RESET_ERROR = 'timersEpic/RESET_ERROR';
