export const GET_ORDER_STATUS_LIST = 'filtersEpic/GET_ORDER_STATUS_LIST';
export const GET_ORDER_STATUS_LIST_SUCCESS = 'filtersEpic/GET_ORDER_STATUS_LIST_SUCCESS';
export const GET_ORDER_STATUS_LIST_FAILURE = 'filtersEpic/GET_ORDER_STATUS_LIST_FAILURE';

export const GET_INQUIRY_STATUS_LIST = 'filtersEpic/GET_INQUIRY_STATUS_LIST';
export const GET_INQUIRY_STATUS_LIST_SUCCESS = 'filtersEpic/GET_INQUIRY_STATUS_LIST_SUCCESS';
export const GET_INQUIRY_STATUS_LIST_FAILURE = 'filtersEpic/GET_INQUIRY_STATUS_LIST_FAILURE';

export const GET_TYPE_LIST = 'filtersEpic/GET_TYPE_LIST';
export const GET_TYPE_LIST_SUCCESS = 'filtersEpic/GET_TYPE_LIST_SUCCESS';
export const GET_TYPE_LIST_FAILURE = 'filtersEpic/GET_TYPE_LIST_FAILURE';

export const GET_MATERIALS_LIST = 'filtersEpic/GET_MATERIALS_LIST';
export const GET_MATERIALS_LIST_SUCCESS = 'filtersEpic/GET_MATERIALS_LIST_SUCCESS';
export const GET_MATERIALS_LIST_FAILURE = 'filtersEpic/GET_MATERIALS_LIST_FAILURE';

export const GET_PAYMENT_TYPE_LIST = 'filtersEpic/GET_PAYMENT_TYPE_LIST';
export const GET_PAYMENT_TYPE_LIST_SUCCESS = 'filtersEpic/GET_PAYMENT_TYPE_LIST_SUCCESS';
export const GET_PAYMENT_TYPE_LIST_FAILURE = 'filtersEpic/GET_PAYMENT_TYPE_LIST_FAILURE';

export const GET_DELIVERY_TYPE_LIST = 'filtersEpic/GET_DELIVERY_TYPE_LIST';
export const GET_DELIVERY_TYPE_LIST_SUCCESS = 'filtersEpic/GET_DELIVERY_TYPE_LIST_SUCCESS';
export const GET_DELIVERY_TYPE_LIST_FAILURE = 'filtersEpic/GET_DELIVERY_TYPE_LIST_FAILURE';

export const GET_TASK_UNIT_LIST = 'filtersEpic/GET_TASK_UNIT_LIST';
export const GET_TASK_UNIT_LIST_SUCCESS = 'filtersEpic/GET_TASK_UNIT_LIST_SUCCESS';
export const GET_TASK_UNIT_LIST_FAILURE = 'filtersEpic/GET_TASK_UNIT_LIST_FAILURE';

export const GET_COST_CENTER_UNIT_LIST = 'filtersEpic/GET_COST_CENTER_UNIT_LIST';
export const GET_COST_CENTER_UNIT_LIST_SUCCESS = 'filtersEpic/GET_COST_CENTER_UNIT_LIST_SUCCESS';
export const GET_COST_CENTER_UNIT_LIST_FAILURE = 'filtersEpic/GET_COST_CENTER_UNIT_LIST_FAILURE';

export const GET_CURRENCIES_LIST = 'filtersEpic/GET_CURRENCIES_LIST';
export const GET_CURRENCIES_LIST_SUCCESS = 'filtersEpic/GET_CURRENCIES_LIST_SUCCESS';
export const GET_CURRENCIES_LIST_FAILURE = 'filtersEpic/GET_CURRENCIES_LIST_FAILURE';

export const GET_COWORKERS_LIST = 'filtersEpic/GET_COWORKERS_LIST';
export const GET_COWORKERS_LIST_SUCCESS = 'filtersEpic/GET_COWORKERS_LIST_SUCCESS';
export const GET_COWORKERS_LIST_FAILURE = 'filtersEpic/GET_COWORKERS_LIST_FAILURE';

export const GET_CUSTOMERS_LIST = 'filtersEpic/GET_CUSTOMERS_LIST';
export const GET_CUSTOMERS_LIST_SUCCESS = 'filtersEpic/GET_CUSTOMERS_LIST_SUCCESS';
export const GET_CUSTOMERS_LIST_FAILURE = 'filtersEpic/GET_CUSTOMERS_LIST_FAILURE';

export const GET_SELECTED_CUSTOMERS = 'filtersEpic/GET_SELECTED_CUSTOMERS';
export const GET_SELECTED_CUSTOMERS_SUCCESS = 'filtersEpic/GET_SELECTED_CUSTOMERS_SUCCESS';
export const GET_SELECTED_CUSTOMERS_FAILURE = 'filtersEpic/GET_SELECTED_CUSTOMERS_FAILURE';

export const GET_SUPPLIERS_LIST = 'filtersEpic/GET_SUPPLIERS_LIST';
export const GET_SUPPLIERS_LIST_SUCCESS = 'filtersEpic/GET_SUPPLIERS_LIST_SUCCESS';
export const GET_SUPPLIERS_LIST_FAILURE = 'filtersEpic/GET_SUPPLIERS_LIST_FAILURE';

export const RESET_PROVIDERS_DATA = 'filterEpic/RESET_PROVIDERS_DATA'
