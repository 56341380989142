import {ofType} from 'redux-observable';
import {from, of, concat} from 'rxjs';
import {switchMap, mergeMap, catchError} from 'rxjs/operators';
import {materialCategoriesAPI} from 'services/API';
import {
  getMaterialCategories,
  getMaterialCategoriesSuccess,
  getMaterialCategoriesFailure,
  getMaterialCategoriesParentsSuccess,
  getMaterialCategoriesParentsFailure,
  getMaterialCategorySuccess,
  getMaterialCategoryFailure,
  addMaterialCategoryFailure,
  addMaterialCategorySuccess,
  updateMaterialCategorySuccess,
  updateMaterialCategoryFailure,
  deleteMaterialCategorySuccess,
  deleteMaterialCategoryFailure,
  getMaterialCategorySettingsSuccess,
  getMaterialCategorySettingsFailure,
} from '../store/actions/materialCategories';
import {
  ADD_MATERIAL_CATEGORY,
  GET_MATERIAL_CATEGORIES,
  GET_MATERIAL_CATEGORIES_PARENTS,
  GET_MATERIAL_CATEGORY,
  UPDATE_MATERIAL_CATEGORY,
  REMOVE_MATERIAL_CATEGORY,
  GET_MATERIAL_CATEGORY_SETTINGS
} from '../store/actionTypes/materialCategoriesActionTypes';

export const getMaterialCategoriesEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIAL_CATEGORIES),
  switchMap((action) => from(materialCategoriesAPI.getMaterialCategories(action.payload.page, action.payload.searchTerms, action.payload.isGetAllData)).pipe(
      switchMap((response) => {
          return of(getMaterialCategoriesSuccess(response))
      }),
      catchError( err => {
        return of(getMaterialCategoriesFailure(err))
      })
  ))
);

export const getMaterialCategoriesParentsEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIAL_CATEGORIES_PARENTS),
  switchMap((action) => from(materialCategoriesAPI.getMaterialCategoriesParents(action.payload)).pipe(
      switchMap((response) => {
          return of(getMaterialCategoriesParentsSuccess(response.data))
      }),
      catchError( err => {
        return of(getMaterialCategoriesParentsFailure(err))
      })
  ))
);

export const getMaterialCategoryEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIAL_CATEGORY),
  switchMap(({payload}) => from(materialCategoriesAPI.getMaterialCategory(payload)).pipe(
      switchMap((response) => {
          return of(getMaterialCategorySuccess(response.data))
      }),
      catchError( err => {
        return of(getMaterialCategoryFailure(err))
      })
  ))
);

export const addMaterialCategoryEpic = (actions$) => actions$.pipe(
  ofType(ADD_MATERIAL_CATEGORY),
  switchMap((action) => from(materialCategoriesAPI.createMaterialCategory(action.payload.data)).pipe(
      switchMap((response) => concat(
          of(getMaterialCategories(action.payload.currentPage)),
          of(addMaterialCategorySuccess(response.data))
      )),
      catchError( err => {
          return of(addMaterialCategoryFailure(err.responseBody))
      })
  ))
);

export const updateMaterialCategoryEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_MATERIAL_CATEGORY),
  mergeMap((action) => from(materialCategoriesAPI.updateMaterialCategory(action.payload.id, action.payload.data)).pipe(
      switchMap((response) => concat(
        of(getMaterialCategories(action.payload.currentPage)),
        of(updateMaterialCategorySuccess(response.data))
      )),
      catchError( err => {
          return of(updateMaterialCategoryFailure(err.responseBody))
      })
  ))
);

export const removeMaterialCategoryEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_MATERIAL_CATEGORY),
  mergeMap((action) => from(materialCategoriesAPI.deleteMaterialCategory(action.payload.id)).pipe(
      switchMap((response) => concat(
          of(getMaterialCategories(action.payload.currentPage)),
          of(deleteMaterialCategorySuccess(response)),
      )),
      catchError( err => {
        return of(deleteMaterialCategoryFailure(err))
      })
  ))
);

export const getMaterialCategorySettingsEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIAL_CATEGORY_SETTINGS),
  switchMap((action) => from(materialCategoriesAPI.getMaterialCategorySettings()).pipe(
    switchMap((response) => {
      return of(getMaterialCategorySettingsSuccess(response.data));
    }),
    catchError( err => {
      return of(getMaterialCategorySettingsFailure(err));
    })
  ))
);
