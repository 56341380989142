import {ofType} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {usersServiceApi} from 'services/API';
import NotificationService from '../services/NotificationService';
import {
  getUsersSuccess,
  getUsersFailure,
  getUsers,
  filterUsers,
  getUserSuccess,
  addUserSuccess,
  addUserFailure,
  updateUserSuccess,
  updateUserFailure,
  setUserStatusSuccess,
  setUserStatusFailure,
} from '../store/actions/usersData';
import {
  ADD_USER,
  GET_USERS,
  GET_USER,
  SET_USER_STATUS,
  UPDATE_USER,
  REMOVE_USER,
  FILTER_USERS,
} from '../store/actionTypes/usersDataActionTypes';

export const getUsersEpic = (actions$) => actions$.pipe(
  ofType(GET_USERS),
  switchMap((action) => from(usersServiceApi.getUsers(action.payload.page, action.payload.searchTerms)).pipe(
    switchMap((response) => {
      return of(getUsersSuccess(response))
    }),
    catchError( err => {
        return of(getUsersFailure(err))
      }
    )
  ))
);

export const getUserEpic = (actions$) => actions$.pipe(
  ofType(GET_USER),
  switchMap(({payload}) => from(usersServiceApi.getUser(payload)).pipe(
    switchMap((response) => {
      return of(getUserSuccess(response.data))
    }),
    catchError( err => {
        return of(getUsersFailure(err))
      }
    )
  ))
);

export const addUserEpic = (actions$) => actions$.pipe(
  ofType(ADD_USER),
  switchMap((action) => from(usersServiceApi.createUser(action.payload.data)).pipe(
    switchMap(
      (response) => concat(
        of(filterUsers(action.payload.filters, action.payload.searchTerms)),
        of(addUserSuccess(response)),
      )),
    catchError( err => {
        return of(addUserFailure(err.responseBody))
      }
    )
  ))
);

export const setUserStatusEpic = (actions$) => actions$.pipe(
  ofType(SET_USER_STATUS),
  switchMap((action) => from(usersServiceApi.setUserStatus(action.payload.id, action.payload.value)).pipe(
    switchMap(() => {
      return concat(
        of(filterUsers(action.payload.filters, action.payload.searchTerms)),
        of(setUserStatusSuccess()),
      );
    }),
    catchError(err => {
      NotificationService.error('User status update failed!');
      return of(setUserStatusFailure(err));
    })
  ))
);

export const updateUserEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_USER),
  switchMap((action) => from(usersServiceApi.updateUser(action.payload.id, action.payload.data)).pipe(
    switchMap(
      (response) => concat(
        of(filterUsers(action.payload.filters, action.payload.searchTerms)),
        of(updateUserSuccess(response)),
      )),
    catchError( err => {
        return of(updateUserFailure(err.responseBody))
      }
    )
  ))
);

export const removeUserEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_USER),
  switchMap((action) => from(usersServiceApi.deleteUser(action.payload.id)).pipe(
    switchMap(() => {
      NotificationService.success('User was removed');
      return of(filterUsers(action.payload.filters, action.payload.searchTerms))
    }),
    catchError( err => {
        return of(getUsersFailure(err))
      }
    )
  ))
);

export const filterUsersEpic = (actions$) => actions$.pipe(
  ofType(FILTER_USERS),
  switchMap((action) => from(usersServiceApi.filterUsers(action.payload.filters, action.payload.searchTerms)).pipe(
    switchMap((response) => {
      return of(getUsersSuccess(response))
    }),
    catchError( err => {
        return of(getUsersFailure(err))
      }
    )
  ))
);
