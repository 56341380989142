import {ofType} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {switchMap, catchError, mergeMap} from 'rxjs/operators';
import {orderServiceApi} from 'services/API';
import {
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
  getClientInquiriesSuccess,
  getClientInquiriesFailure,
  filterOrders,
  getOrder,
  getOrderSuccess,
  getOrderFailure,
  getOrderTotalCost,
  getOrderTotalCostSuccess,
  getOrderTotalCostFailure,
  updateOrderSettingsFailure,
  createOrderSuccess,
  createOrderFailure,
  duplicateOrderSuccess,
  duplicateOrderFailure,
  updateOrderSuccess,
  updateOrderFailure,
  deleteOrderSuccess,
  setOrderStatusSuccess,
  setOrderStatusFailure,
  validateOrderDetailsSuccess,
  validateOrderDetailsFailure,
  validateOrderPositionsSuccess,
  validateOrderPositionsFailure,
  validateOrderPositionImportFileSuccess,
  validateOrderPositionImportFileFailure,
  startPositionFileImportSuccess,
  startPositionFileImportFailure,
  startTaskFileImportSuccess,
  startTaskFileImportFailure,
  validateOrderPositionTaskSuccess,
  validateOrderPositionTaskFailure,
  validateOrderFittingsSuccess,
  validateOrderFittingsFailure,
  validateOrderCostCenterSuccess,
  validateOrderCostCenterFailure,
  orderPositionCalculationSuccess,
  orderPositionCalculationFailure,
  orderCostCenterCalculationSuccess,
  orderCostCenterCalculationFailure,
  getOrderPositionDefaultCostCentersSuccess,
  getOrderPositionDefaultCostCentersFailure,
  getOrderSettingsSuccess,
  getOrderSettingsFailure,
  getOrderSettings,
} from '../store/actions/orders';
import {
  GET_ORDERS,
  GET_CLIENT_INQUIRIES,
  GET_ORDER,
  GET_ORDER_TOTAL_COST,
  CREATE_ORDER,
  DUPLICATE_ORDER,
  REMOVE_ORDER,
  UPDATE_ORDER,
  VALIDATE_ORDER_DETAILS,
  VALIDATE_ORDER_POSITIONS,
  VALIDATE_IMPORTED_POSITION_FILE,
  START_POSITION_FILE_IMPORT,
  START_TASK_FILE_IMPORT,
  VALIDATE_POSITIONS_TASK,
  VALIDATE_ORDER_FITTINGS,
  VALIDATE_ORDER_COST_CENTER,
  ORDER_POSITION_CALCULATION,
  ORDER_COST_CENTER_CALCULATION,
  SET_ORDER_STARRED,
  SET_ORDER_STATUS,
  SET_ORDER_DEADLINE,
  FILTER_ORDERS,
  GET_ORDER_POSITION_DEFAULT_COST_CENTER, GET_ORDER_SETTINGS, UPDATE_ORDER_SETTINGS
} from '../store/actionTypes/ordersActionTypes';

export const getOrdersEpic = (actions$) => actions$.pipe(
  ofType(GET_ORDERS),
  switchMap((action) => from(orderServiceApi.getOrders(action.payload)).pipe(
    switchMap((response) => {
      return of(getOrdersSuccess(response))
    }),
    catchError( err => {
        return of(getOrdersFailure(err))
      }
    )
  ))
);

export const getClientInquiriesEpic = (actions$) => actions$.pipe(
  ofType(GET_CLIENT_INQUIRIES),
  switchMap((action) => from(orderServiceApi.getClientInquiries(action.payload)).pipe(
    switchMap((response) => {
      return of(getClientInquiriesSuccess(response.data))
    }),
    catchError( err => {
        return of(getClientInquiriesFailure(err))
      }
    )
  ))
);

export const getOrderEpic = (actions$) => actions$.pipe(
  ofType(GET_ORDER),
  switchMap(({payload}) => from(orderServiceApi.getOrder(payload)).pipe(
    switchMap((response) => {
      return of(getOrderSuccess(response.data))
    }),
    catchError( err => {
        return of(getOrderFailure(err))
      }
    )
  ))
);

export const getOrderSettingsEpic = (actions$) => actions$.pipe(
  ofType(GET_ORDER_SETTINGS),
  switchMap(({payload}) => from(orderServiceApi.getOrderSettings(payload)).pipe(
    switchMap((response) => {
      return of(getOrderSettingsSuccess(response.data))
    }),
    catchError( err => {
        return of(getOrderSettingsFailure(err))
      }
    )
  ))
);

export const updateOrderSettingsEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_ORDER_SETTINGS),
  mergeMap((action) => from(orderServiceApi.updateOrderSettings(action.payload.id, action.payload.data)).pipe(
    switchMap(() => concat(
      of(getOrderTotalCost(action.payload.id)),
      of(getOrderSettings(action.payload.id))
    )),
    catchError( err => {
        return of(updateOrderSettingsFailure(err))
      }
    )
  ))
);

export const getOrderTotalCostEpic = (actions$) => actions$.pipe(
  ofType(GET_ORDER_TOTAL_COST),
  switchMap((action) => from(orderServiceApi.getOrderTotalCost(action.payload)).pipe(
    switchMap((response) => {
      return of(getOrderTotalCostSuccess(response))
    }),
    catchError( err => {
        return of(getOrderTotalCostFailure(err))
      }
    )
  ))
);

export const createOrderEpic = (actions$) => actions$.pipe(
  ofType(CREATE_ORDER),
  switchMap((action) => from(orderServiceApi.createOrder(action.payload.data)).pipe(
    switchMap((response) => {
      return of(createOrderSuccess(action.payload.isOrder))
    }),
    catchError( err => {
        return of(createOrderFailure(err))
      }
    )
  ))
);

export const duplicateOrderEpic = (actions$) => actions$.pipe(
  ofType(DUPLICATE_ORDER),
  switchMap((action) => from(orderServiceApi.duplicateOrder(action.payload.id)).pipe(
    switchMap((response) =>
      !action.payload.preventFetch ?
        concat(
          of(duplicateOrderSuccess(action.payload.isOrder)),
          of(filterOrders(action.payload.filters, action.payload.searchTerms))
        ) :
        of(duplicateOrderSuccess(action.payload.isOrder)),
    ),
    catchError( err => {
        return of(duplicateOrderFailure(err))
      }
    )
  ))
);

export const removeOrderEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_ORDER),
  switchMap((action) => from(orderServiceApi.deleteOrder(action.payload.id)).pipe(
    switchMap((response) =>
      !action.payload.preventFetch ?
      concat(
        of(deleteOrderSuccess(action.payload.isOrder)),
        of(filterOrders(action.payload.filters, action.payload.searchTerms))
      ) :
      of(deleteOrderSuccess(action.payload.isOrder)),
    ),
    catchError( err => {
        return of(getOrdersFailure(err))
      }
    )
  ))
);

export const updateOrderEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_ORDER),
  mergeMap(({payload}) => from(orderServiceApi.updateOrder(payload.id, payload.data)).pipe(
    switchMap((response) => {
      return of(updateOrderSuccess(payload.isOrder, response && response.data && response.data.new_order ? response.data.new_order.id : null));
    }),
    catchError( err => {
        return of(updateOrderFailure(err))
      }
    )
  ))
);

export const orderDetailsValidationEpic = (actions$) => actions$.pipe(
  ofType(VALIDATE_ORDER_DETAILS),
  mergeMap((action) => from(orderServiceApi.validateOrders(action.payload)).pipe(
    switchMap((response) => {
      return of(validateOrderDetailsSuccess(response))
    }),
    catchError( err => {
        return of(validateOrderDetailsFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const orderPositionsValidationEpic = (actions$) => actions$.pipe(
  ofType(VALIDATE_ORDER_POSITIONS),
  mergeMap((action) => from(orderServiceApi.validatePositions(action.payload)).pipe(
    switchMap((response) => {
      return of(validateOrderPositionsSuccess(response))
    }),
    catchError( err => {
        return of(validateOrderPositionsFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const orderImportedPositionFileValidationEpic = (actions$) => actions$.pipe(
  ofType(VALIDATE_IMPORTED_POSITION_FILE),
  mergeMap((action) => from(orderServiceApi.validateImportedPositionFile(action.payload)).pipe(
    switchMap((response) => {
      return of(validateOrderPositionImportFileSuccess(response.data))
    }),
    catchError( err => {
        return of(validateOrderPositionImportFileFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const startImportPositionFileEpic = (actions$) => actions$.pipe(
  ofType(START_POSITION_FILE_IMPORT),
  mergeMap((action) => from(orderServiceApi.startImportOrderPositionFile(action.payload)).pipe(
    switchMap((response) => {
      return of(startPositionFileImportSuccess(response.data))
    }),
    catchError( err => {
        return of(startPositionFileImportFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const startImportTaskFileEpic = (actions$) => actions$.pipe(
  ofType(START_TASK_FILE_IMPORT),
  mergeMap((action) => from(orderServiceApi.startImportOrderPositionFile(action.payload.data)).pipe(
    switchMap((response) => {
      return of(startTaskFileImportSuccess({id: action.payload.id, data: response.data}))
    }),
    catchError( err => {
        return of(startTaskFileImportFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const orderPositionTaskValidationEpic = (actions$) => actions$.pipe(
  ofType(VALIDATE_POSITIONS_TASK),
  mergeMap((action) => from(orderServiceApi.validateOrderPositionTask(action.payload)).pipe(
    switchMap((response) => {
      return of(validateOrderPositionTaskSuccess(response))
    }),
    catchError( err => {
        return of(validateOrderPositionTaskFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const orderFittingsValidationEpic = (actions$) => actions$.pipe(
  ofType(VALIDATE_ORDER_FITTINGS),
  mergeMap((action) => from(orderServiceApi.validateFittings(action.payload)).pipe(
    switchMap((response) => {
      return of(validateOrderFittingsSuccess(response))
    }),
    catchError( err => {
        return of(validateOrderFittingsFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const orderCostCenterValidationEpic = (actions$) => actions$.pipe(
  ofType(VALIDATE_ORDER_COST_CENTER),
  mergeMap((action) => from(orderServiceApi.validateCostCenters(action.payload)).pipe(
    switchMap((response) => {
      return of(validateOrderCostCenterSuccess(response))
    }),
    catchError( err => {
        return of(validateOrderCostCenterFailure(err.responseBody ? err.responseBody.errors : err))
      }
    )
  ))
);

export const orderPositionCalculationEpic = (actions$) => actions$.pipe(
  ofType(ORDER_POSITION_CALCULATION),
  mergeMap((action) => from(orderServiceApi.orderPositionCalculation(action.payload.data)).pipe(
    switchMap((response) => {
      return of(orderPositionCalculationSuccess({id: action.payload.id, data: response}))
    }),
    catchError( err => {
        return of(orderPositionCalculationFailure(err))
      }
    )
  ))
);

export const orderCostCenterCalculationEpic = (actions$) => actions$.pipe(
  ofType(ORDER_COST_CENTER_CALCULATION),
  mergeMap((action) => from(orderServiceApi.orderCostCenterCalculation(action.payload.data)).pipe(
    switchMap((response) => {
      return of(orderCostCenterCalculationSuccess({id: action.payload.id, data: response}))
    }),
    catchError( err => {
        return of(orderCostCenterCalculationFailure({id: action.payload.id, error: err.responseBody ? err.responseBody.errors : err}))
      }
    )
  ))
);

export const setOrderStarredEpic = (actions$) => actions$.pipe(
  ofType(SET_ORDER_STARRED),
  mergeMap((action) => from(orderServiceApi.setOrderStarred(action.payload.data.id, action.payload.data.value)).pipe(
    switchMap(() => {
      return of(filterOrders(action.payload.data.filters, action.payload.data.searchTerms))
    }),
    catchError( err => {
        return of(getOrdersFailure(err))
      }
    )
  ))
);

export const setOrderStatusEpic = (actions$) => actions$.pipe(
  ofType(SET_ORDER_STATUS),
  mergeMap((action) => from(orderServiceApi.setOrderStatus(action.payload.data.id, action.payload.data.value)).pipe(
    switchMap((response) => {
      return concat(
        of(setOrderStatusSuccess(response.data && response.data.id !== null && response.data.id !== undefined ? response.data.id : null)),
        of(filterOrders(action.payload.data.filters, action.payload.data.searchTerms))
      );
    }),
    catchError( err => {
        return concat(
          of(setOrderStatusFailure(err)),
          of(getOrdersFailure(err))
        );
      }
    )
  ))
);

export const setOrderDeadLineEpic = (actions$) => actions$.pipe(
  ofType(SET_ORDER_DEADLINE),
  mergeMap((action) => from(orderServiceApi.setOrderDeadLine(action.payload.id, action.payload.value)).pipe(
    switchMap((response) => {
      return of(getOrder(action.payload.id))
    }),
    catchError( err => {
        return of(getOrderFailure(err))
      }
    )
  ))
);

export const filterOrdersEpic = (actions$) => actions$.pipe(
  ofType(FILTER_ORDERS),
  switchMap((action) => from(orderServiceApi.filterOrders(action.payload.filters, action.payload.searchTerms)).pipe(
    switchMap((response) => {
      return of(getOrdersSuccess(response));
    }),
    catchError(err => {
      return of(getOrdersFailure(err))
    }
    )
  ))
);

export const getOrderPositionDefaultCostCentersEpic = (actions$) => actions$.pipe(
  ofType(GET_ORDER_POSITION_DEFAULT_COST_CENTER),
  switchMap(({payload}) => from(orderServiceApi.getOrderPositionDefaultCostCenters()).pipe(
    switchMap((response) => {
      return of(getOrderPositionDefaultCostCentersSuccess(response.data))
    }),
    catchError( err => {
        return of(getOrderPositionDefaultCostCentersFailure(err))
      }
    )
  ))
);

