import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {manufacturerOrderPositionAPI} from 'services/API';
import {
  addManufacturerOrderPositionSuccess,
  addManufacturerOrderPositionFailure,
  updateManufacturerOrderPositionSuccess,
  updateManufacturerOrderPositionFailure,
  getManufacturerOrderPositionSuccess,
  getManufacturerOrderPositionFailure,
  deleteManufacturerOrderPositionSuccess,
  deleteManufacturerOrderPositionFailure,
  getOrderPositionIndexSuccess,
  getOrderPositionIndexFailure
} from '../store/actions/manufacturerOrderPosition';
import {
  ADD_MANUFACTURER_ORDER_POSITION,
  UPDATE_MANUFACTURER_ORDER_POSITION,
  GET_MANUFACTURER_ORDER_POSITION,
  DELETE_MANUFACTURER_ORDER_POSITION,
  GET_ORDER_POSITION_INDEX
} from '../store/actionTypes/manufacturerOrderPositionActionTypes';

export const getOrderPositionIndexEpic = (actions$) => actions$.pipe(
  ofType(GET_ORDER_POSITION_INDEX),
  switchMap(() => from(manufacturerOrderPositionAPI.getManufacturerOrderPositionIndex()).pipe(
    switchMap((response) => {
      return of(getOrderPositionIndexSuccess(response.data))
    }),
    catchError( err => {
        return of(getOrderPositionIndexFailure(err))
      }
    )
  ))
);

export const getManufacturerOrderPositionEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURER_ORDER_POSITION),
  switchMap(({payload}) => from(manufacturerOrderPositionAPI.getManufacturerOrderPosition(payload)).pipe(
    switchMap((response) => {
      return of(getManufacturerOrderPositionSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturerOrderPositionFailure(err))
      }
    )
  ))
);

export const addManufacturerOrderPositionEpic = (actions$) => actions$.pipe(
  ofType(ADD_MANUFACTURER_ORDER_POSITION),
  switchMap(({payload}) => from(manufacturerOrderPositionAPI.createManufacturerOrderPosition(payload)).pipe(
    switchMap((response) => {
      return of(addManufacturerOrderPositionSuccess(response))
    }),
    catchError( err => {
        return of(addManufacturerOrderPositionFailure(err))
      }
    )
  ))
);

export const updateManufacturerOrderPositionEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_MANUFACTURER_ORDER_POSITION),
  switchMap(({payload}) => from(manufacturerOrderPositionAPI.updateManufacturerOrderPosition(payload.id, payload.data)).pipe(
    switchMap((response) => {
      return of(updateManufacturerOrderPositionSuccess(response))
    }),
    catchError( err => {
        return of(updateManufacturerOrderPositionFailure(err))
      }
    )
  ))
);

export const removeManufacturerOrderPositionEpic = (actions$) => actions$.pipe(
  ofType(DELETE_MANUFACTURER_ORDER_POSITION),
  switchMap(({payload}) => from(manufacturerOrderPositionAPI.deleteManufacturerOrderPosition(payload.id)).pipe(
    switchMap((response) => {
      return of(deleteManufacturerOrderPositionSuccess(response))
    }),
    catchError( err => {
        return of(deleteManufacturerOrderPositionFailure(err))
      }
    )
  ))
);

