import { KeyboardArrowDownSharp } from '@material-ui/icons';
import BaseAPIService from './Base';

export default class SettingsAPIService extends BaseAPIService {
  getCostCenterList = async () => {
    return this.request('api/cost-center').then((data) => {
      return data;
    });
  };

  updateCostCenterList = async (data) => {
    await this.request('api/cost-center', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  getMaterialsSettings = async () => {
    return this.request('api/manufacturer-material-settings').then((data) => {
      return data;
    });
  };

  updateMaterialsSettings = async (data) => {
    let transformedStandardSurcharges = data.standardSurcharges.flatMap(material => {
      return Object.keys(material.settings).map(key => ({ [`${material.name.split(" ").join("_")}_${key}`] : material.settings[key]}));
    });
    let updatedStandardSurcharges = [];
    transformedStandardSurcharges.forEach(item => {
      const key = Object.keys(item)[0];
      updatedStandardSurcharges.push({key, value: item[key]});
    });

    let transformedRawAllowances = data.rawAllowances.flatMap(material => {
      return Object.keys(material.settings).map(key => ({ [`${material.name.split(" ").join("_")}_${key}`] : material.settings[key]}));
    });
    let updatedRawAllowances = [];
    const rawAllowancesKeyPrefix = "material_raw_dimension_allowance_settings_";
    transformedRawAllowances.forEach(item => {
      const key = Object.keys(item)[0];
      updatedRawAllowances.push({key: rawAllowancesKeyPrefix + key, value: item[key]});
    });
    await this.request('api/manufacturer-material-settings', {
      method: 'PUT',
      body: JSON.stringify([
        ...updatedStandardSurcharges,
        ...updatedRawAllowances,
      ]),
    });
  };

  getMaterialListAllowance = async () => {
    return this.request('api/manufacturers/own/cad-allowance').then((data) => {
      return data;
    });
  }

  updateMaterialListAllowance = async (data) => {
    await this.request('api/manufacturers/own/cad-allowance', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  getMaterialListOrientationData = async (id, term) => {
    return this.request(`api/manufacturers/own/cad-import-orientations?type=${id}${term ? ('&term=' + term) : ''}`).then((data) => {
      return data;
    });
  }

  addMaterialListOrientationClass = async (id, name) => {
    await this.request('api/manufacturers/own/cad-import-orientations', {
      method: 'POST',
      body: JSON.stringify({
        keyword: name,
        type: id,
      }),
    });
  }

  removeMaterialListOrientationClass = async (id) => {
    return this.request(`api/manufacturers/own/cad-import-orientations/${id}`, {
      method: 'DELETE'
    });
  }

  getWebShopSettings = async () => {
    return this.request('api/manufacturer-settings/own').then((data) => {
      return data;
    });
  };

  updateWebShopSettings = async (data) => {
    let updatedSettings = [];
    Object.keys(data).forEach(item => updatedSettings.push({key: item, value: data[item]}));
    await this.request('api/manufacturer-settings/own', {
      method: 'POST',
      body: JSON.stringify({'settings' : updatedSettings})
    });
  };

  getProjectsSettings = async () => {
    return this.request('api/manufacturer-project-settings').then((data) => {
      return data;
    });
  };

  updateProjectsSettings = async (data) => {
    let updatedSettings = [];
    Object.keys(data).forEach(item => updatedSettings.push({key: item, value: data[item]}));
    await this.request('api/manufacturer-project-settings', {
      method: 'PUT',
      body: JSON.stringify(updatedSettings)
    });
  };

  getLetterheads = async () => {
    return this.request('api/manufacturer-settings/invoice-header').then((response) => {
      return response;
    });
  };

  updateLetterheads = async (letterhead1, letterhead2) => {
    const formData = new FormData();
    if (letterhead1) {
      formData.append('invoice_primary_header', letterhead1);
    }
    if (letterhead2) {
      formData.append('invoice_secondary_header', letterhead2);
    }
    return this.request('api/manufacturer-settings/invoice-header', {
      method: 'POST',
      body: formData,
    });
  };

  removeLetterhead = async (id) => {
    return this.request(`api/manufacturer-settings/invoice-header/${id}`, {
      method: 'DELETE',
    });
  };
}
