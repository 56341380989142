import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {providersServiceApi} from 'services/API';
import {
  getOrderStatusListSuccess,
  getOrderStatusListFailure,
  getInquiryStatusListSuccess,
  getInquiryStatusListFailure,
  getCoworkersListSuccess,
  getCoworkersListFailure,
  getCustomersListSuccess,
  getCustomersListFailure,
  getSelectedCustomersSuccess,
  getSelectedCustomersFailure,
  getMaterialsListSuccess,
  getMaterialsListFailure,
  getTypeListSuccess,
  getTypeListFailure,
  getDeliveryTypeListSuccess,
  getDeliveryTypeListFailure,
  getPaymentTypeListSuccess,
  getPaymentTypeListFailure,
  getTaskUnitListSuccess,
  getTaskUnitListFailure,
  getCostCenterUnitListSuccess,
  getCostCenterUnitListFailure,
  getCurrenciesListSuccess,
  getCurrenciesListFailure, getSuppliersListSuccess, getSuppliersListFailure
} from 'store/actions/providers';
import {
  GET_ORDER_STATUS_LIST,
  GET_INQUIRY_STATUS_LIST,
  GET_COWORKERS_LIST,
  GET_CUSTOMERS_LIST,
  GET_SELECTED_CUSTOMERS,
  GET_SUPPLIERS_LIST,
  GET_MATERIALS_LIST,
  GET_TYPE_LIST,
  GET_DELIVERY_TYPE_LIST,
  GET_PAYMENT_TYPE_LIST,
  GET_TASK_UNIT_LIST,
  GET_COST_CENTER_UNIT_LIST,
  GET_CURRENCIES_LIST
} from 'store/actionTypes/providerActionTypes';

export const getOrderStatusListEpic = (actions$) => actions$.pipe(
  ofType(GET_ORDER_STATUS_LIST),
  switchMap(() => from(providersServiceApi.getOrderStatusList()).pipe(
    switchMap((response) => {
      return of(getOrderStatusListSuccess(response.data))
    }),
    catchError( err => {
        return of(getOrderStatusListFailure(err))
      }
    )
  ))
);

export const getInquiryStatusListEpic = (actions$) => actions$.pipe(
  ofType(GET_INQUIRY_STATUS_LIST),
  switchMap(() => from(providersServiceApi.getInquiryStatusList()).pipe(
    switchMap((response) => {
      return of(getInquiryStatusListSuccess(response.data))
    }),
    catchError( err => {
        return of(getInquiryStatusListFailure(err))
      }
    )
  ))
);

export const getCoworkersListEpic = (actions$) => actions$.pipe(
  ofType(GET_COWORKERS_LIST),
  switchMap((action) => from(providersServiceApi.getCoworkersList(action.payload)).pipe(
    switchMap((response) => {
      return of(getCoworkersListSuccess(response.data))
    }),
    catchError( err => {
        return of(getCoworkersListFailure(err))
      }
    )
  ))
);

export const getCustomersListEpic = (actions$) => actions$.pipe(
  ofType(GET_CUSTOMERS_LIST),
  switchMap((action) => from(providersServiceApi.getCustomersList(action.payload.term, action.payload.excludeSuppliers)).pipe(
    switchMap((response) => {
      return of(getCustomersListSuccess(response.data));
    }),
    catchError(err => {
      return of(getCustomersListFailure(err));
    })
  ))
);

export const getSelectedCustomersEpic = (actions$) => actions$.pipe(
  ofType(GET_SELECTED_CUSTOMERS),
  switchMap((action) => from(providersServiceApi.getSelectedCustomers(action.payload)).pipe(
    switchMap((response) => {
      return of(getSelectedCustomersSuccess(response.data));
    }),
    catchError(err => {
      return of(getSelectedCustomersFailure(err));
    })
  ))
);

export const getSuppliersListEpic = (actions$) => actions$.pipe(
  ofType(GET_SUPPLIERS_LIST),
  switchMap((action) => from(providersServiceApi.getSuppliersList(action.payload)).pipe(
    switchMap((response) => {
      return of(getSuppliersListSuccess(response.data))
    }),
    catchError( err => {
        return of(getSuppliersListFailure(err))
      }
    )
  ))
);

export const getMaterialsListEpic = (actions$) => actions$.pipe(
  ofType(GET_MATERIALS_LIST),
  switchMap((action) => from(providersServiceApi.getMaterialsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getMaterialsListSuccess(response.data))
    }),
    catchError( err => {
        return of(getMaterialsListFailure(err))
      }
    )
  ))
);

export const getTypeListEpic = (actions$) => actions$.pipe(
  ofType(GET_TYPE_LIST),
  switchMap(() => from(providersServiceApi.getTypeList()).pipe(
    switchMap((response) => {
      return of(getTypeListSuccess(response.data))
    }),
    catchError( err => {
        return of(getTypeListFailure(err))
      }
    )
  ))
);

export const getDeliveryTypeListEpic = (actions$) => actions$.pipe(
  ofType(GET_DELIVERY_TYPE_LIST),
  switchMap(() => from(providersServiceApi.getDeliveryTypeList()).pipe(
    switchMap((response) => {
      return of(getDeliveryTypeListSuccess(response.data))
    }),
    catchError( err => {
        return of(getDeliveryTypeListFailure(err))
      }
    )
  ))
);

export const getPaymentListEpic = (actions$) => actions$.pipe(
  ofType(GET_PAYMENT_TYPE_LIST),
  switchMap(() => from(providersServiceApi.getPaymentTypeList()).pipe(
    switchMap((response) => {
      return of(getPaymentTypeListSuccess(response.data))
    }),
    catchError( err => {
        return of(getPaymentTypeListFailure(err))
      }
    )
  ))
);

export const getTaskUnitListEpic = (actions$) => actions$.pipe(
  ofType(GET_TASK_UNIT_LIST),
  switchMap(() => from(providersServiceApi.getTaskUnitList()).pipe(
    switchMap((response) => {
      return of(getTaskUnitListSuccess(response.data))
    }),
    catchError( err => {
        return of(getTaskUnitListFailure(err))
      }
    )
  ))
);

export const getCostCenterUnitListEpic = (actions$) => actions$.pipe(
  ofType(GET_COST_CENTER_UNIT_LIST),
  switchMap(() => from(providersServiceApi.getCostCenterUnitList()).pipe(
    switchMap((response) => {
      return of(getCostCenterUnitListSuccess(response.data))
    }),
    catchError( err => {
        return of(getCostCenterUnitListFailure(err))
      }
    )
  ))
);

export const getCurrenciesListEpic = (actions$) => actions$.pipe(
  ofType(GET_CURRENCIES_LIST),
  switchMap(() => from(providersServiceApi.getCurrenciesList()).pipe(
    switchMap((response) => {
      return of(getCurrenciesListSuccess(response.data))
    }),
    catchError( err => {
        return of(getCurrenciesListFailure(err))
      }
    )
  ))
);
