import {
  GET_PRETEXT_PRESETS,
  GET_PRETEXT_PRESETS_SUCCESS,
  GET_PRETEXT_PRESETS_ERROR,
  GET_ENDTEXT_PRESETS,
  GET_ENDTEXT_PRESETS_SUCCESS,
  GET_ENDTEXT_PRESETS_ERROR,
  GET_LAST_PRE_END_TEXT,
  GET_LAST_PRE_END_TEXT_SUCCESS,
  GET_LAST_PRE_END_TEXT_ERROR,
  GET_INVOICE_TYPES_PRESETS,
  GET_INVOICE_TYPES_PRESETS_SUCCESS,
  GET_INVOICE_TYPES_PRESETS_ERROR,
} from '../actionTypes/invoicesProvidersActionTypes';

export const getPreTextPresets = () => {
  return {type: GET_PRETEXT_PRESETS, payload: {}};
};

export const getPreTextPresetsSuccess = (data) => {
  return {type: GET_PRETEXT_PRESETS_SUCCESS, payload: data};
};

export const getPreTextPresetsFailure = (error) => {
  return {type: GET_PRETEXT_PRESETS_ERROR, payload: error};
};

export const getEndTextPresets = () => {
  return {type: GET_ENDTEXT_PRESETS, payload: {}};
};

export const getEndTextPresetsSuccess = (data) => {
  return {type: GET_ENDTEXT_PRESETS_SUCCESS, payload: data};
};

export const getEndTextPresetsFailure = (error) => {
  return {type: GET_ENDTEXT_PRESETS_ERROR, payload: error};
};

export const getLastPreEndText = () => {
  return {type: GET_LAST_PRE_END_TEXT, payload: {}};
};

export const getLastPreEndTextSuccess = (data) => {
  return {type: GET_LAST_PRE_END_TEXT_SUCCESS, payload: data};
};

export const getLastPreEndTextFailure = (error) => {
  return {type: GET_LAST_PRE_END_TEXT_ERROR, payload: error};
};

export const getInvoiceTypesPresets = (typeId) => {
  return {type: GET_INVOICE_TYPES_PRESETS, payload: typeId};
};

export const getInvoiceTypesPresetsSuccess = (data) => {
  return {type: GET_INVOICE_TYPES_PRESETS_SUCCESS, payload: data};
};

export const getInvoiceTypesPresetsFailure = (error) => {
  return {type: GET_INVOICE_TYPES_PRESETS_ERROR, payload: error};
};

