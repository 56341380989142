export const AUTHORIZE = 'auth/AUTHORIZE';
export const LOG_OUT = 'auth/LOG_OUT';
export const SET_LOGIN_ERRORS = 'auth/SET_LOGIN_ERRORS';
export const RESET_LOGIN_ERRORS = 'auth/RESET_LOGIN_ERRORS';
export const CHECK_CURRENT_AUTH_STATUS = 'auth/CHECK_CURRENT_AUTH_STATUS';
export const LOGIN = 'auth/LOGIN';
export const FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'auth/FORGOT_PASSWORD_ERROR';
export const RESET_FORGOT_PASSWORD_ERROR = 'auth/RESET_FORGOT_PASSWORD_ERROR';


