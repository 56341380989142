import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import CreateMaterial from "../CreateMaterial";
import ViewMaterial from "../ViewMaterial";

import {getMaterials} from "../../store/actions/materials";

import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import Button from "components/CustomButtons/Button.jsx";
import Dropdown from "components/CustomDropdown/CustomDropdown.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ViewButton from '../../components/ViewButton';

import Add from "@material-ui/icons/Add";

import './style.scss';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "../../assets/img/buttons/delete.svg";
import EditIcon from "../../assets/img/buttons/edit.svg";
import ViewIcon from "../../assets/img/buttons/eye.svg";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableView: true,
      isCreateMaterialOpen: false,
      isViewMaterialOpen: false
    };
  }

  componentDidMount() {
    this.props.getMaterials();
  }

  handleViewChange = (value) => {
    if (value === 'table') {
      this.setState({isTableView : true})
    } else {
      this.setState({isTableView : false})
    }
  };

  handleMaterialChangeOpen = () => {
    this.setState({isCreateMaterialOpen: true})
  };

  handleMaterialChangeClose = () => {
    this.setState({isCreateMaterialOpen: false})
  };

  handleViewMaterialOpen = () => {
    this.setState({isViewMaterialOpen: true})
  };

  handleViewMaterialClose = () => {
    this.setState({isViewMaterialOpen: false})
  };

  renderProductsItem = (product, index) => {
    return(
      <GridContainer
        className={`products-item ${ index % 2 === 0 ? 'dark' : '' }`}
        direction="row"
        xs={12}
      >
        <GridItem className="products-item-title product-img" xs={1}>{product.id}</GridItem>
        <GridItem className="products-item-title bold" xs={1}>
          {'Published'}
        </GridItem>
        <GridItem className="products-item-title flex" xs={2}>
          <span className={`product-color ${product.color}`}/>
        </GridItem>
        <GridItem className="products-item-title" xs={1}>{'Apple'}</GridItem>
        <GridItem className="products-item-title" xs={1}>{'Cabinet'}</GridItem>
        <GridItem className="products-item-title availability" xs={2}>
          <Dropdown
            buttonText={""}
            buttonIconType="white"
            buttonProps={{className: `dropdown-blue-style stock-dropdown`, round: true, size: "sm"}}
            dropdownList={[{id: 0, value: "In Stock"}, {id: 1, value: "Unavailable"}]}
            hoverColor="dark"
          />
        </GridItem>
        <GridItem className="products-item-title" xs={1}>{product.default_min_price}</GridItem>
        <GridItem className="products-item-title" xs={1}>{product.created_by}</GridItem>
        <GridItem className="products-item-title" xs={2}>
          <IconButton aria-label="close" className="action-btn">
            <img
              alt="products-delete-btn"
              src={DeleteIcon}
            />
          </IconButton>
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleViewMaterialOpen()}>
            <img
              alt="products-view-btn"
              src={ViewIcon}
            />
          </IconButton>
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleMaterialChangeOpen()}>
            <img
              alt="products-edit-btn"
              src={EditIcon}
            />
          </IconButton>
        </GridItem>
      </GridContainer>
    )
  };

  render() {
    const { materials } = this.props;
    const { isTableView, isCreateMaterialOpen, isViewMaterialOpen } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem className="products-dropdowns-grid-item" direction="row" justify="flex-end" xs={12}>
            <Dropdown
              buttonText={""}
              buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
              dropdownList={[{id: 0, value: "All Categories"}]}
              hoverColor="dark"
            />
            <Dropdown
              buttonText={""}
              buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
              dropdownList={[{id: 0, value: "All Brands"}]}
            />
            <Dropdown
              buttonText={""}
              buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
              dropdownList={[{id: 0, value: "All Suppliers"}]}
            />
            <Dropdown
              buttonText={""}
              buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
              dropdownList={[{id: 0, value: "All Availability"}]}
            />
            <Dropdown
              buttonText={""}
              buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
              dropdownList={[{id: 0, value: "Filter Prices"}]}
            />
            <ViewButton isTable={isTableView} onViewChange={this.handleViewChange}/>
            <Button  onClick={this.handleMaterialChangeOpen} size="sm" color="rose" round className="add-product-btn">
              <Add /> CREATE
            </Button>
          </GridItem>
          <GridContainer className="products-header" direction="row" xs={12}>
            <GridItem className="products-header-title" xs={1}></GridItem>
            <GridItem className="products-header-title" xs={1}>Webshop Status</GridItem>
            <GridItem className="products-header-title" xs={2}>Color, Name, Size</GridItem>
            <GridItem className="products-header-title" xs={1}>Brand</GridItem>
            <GridItem className="products-header-title" xs={1}>Category</GridItem>
            <GridItem className="products-header-title" xs={2}>Availability</GridItem>
            <GridItem className="products-header-title" xs={1}>Price</GridItem>
            <GridItem className="products-header-title" xs={1}>Supplier</GridItem>
            <GridItem className="products-header-title" xs={2}>Actions</GridItem>
          </GridContainer>
          <GridContainer className="products-content">
            {materials && materials.map((product, index) => this.renderProductsItem(product, index))}
          </GridContainer>
        </GridContainer>
        <CreateMaterial isOpen={isCreateMaterialOpen} onClose={this.handleMaterialChangeClose} />
        <ViewMaterial isOpen={isViewMaterialOpen} onClose={this.handleViewMaterialClose} />
      </div>
    );
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    materials: state.materials.materials,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMaterials: () => dispatch(getMaterials()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Products));
