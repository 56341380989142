import React from 'react';
import { withTranslation } from 'react-i18next';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import SubMaterial from './SubMaterial';

import './style.scss';

class RawAllowances extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDataChange = (index, dataObj) => {
    const { data, onDataChange } = this.props;
    const newData = [...data];
    newData[index] = dataObj;
    onDataChange(newData);
  }

  matchErrorToMaterial = (material) => {
    const { errors } = this.props;
    const rawAllowancesKeyPrefix = "material_raw_dimension_allowance_settings_";
    const materialErrorsKeys = Object.keys(errors).filter(el => el.indexOf(`${rawAllowancesKeyPrefix}${material.name.split(" ").join("_")}`) == 0);
    const errorObj = {};
    if (materialErrorsKeys.length > 0) {
      materialErrorsKeys.forEach(el => {
        errorObj[el.substring(rawAllowancesKeyPrefix.length + material.name.length + 1)] = errors[el];
      });
    }
    return errorObj;
  }

  render() {
    const {
      data,
      t,
    } = this.props;
    return (
      <div className="position-content-raw-allowances">
        <div className={`position-info ${true ? 'bottom-borders' : ''}`}>
          <GridItem className="position-info-container">
            <GridItem className="position-info-title">
              <div className="position-title">{ t('raw_dimensions_allowances') }</div>
            </GridItem>
          </GridItem>
        </div>
        <div className="position-info-content">
          {
            data.length !== 0 &&
              <GridContainer className="position-tasks">
                <GridContainer className="position-info-header">
                  <GridItem className="pihi-category"><span>{ t('category') }</span></GridItem>
                  <GridItem className="pihi-length"><span>{ t('length') }</span></GridItem>
                  <GridItem className="pihi-width"><span>{ t('width') }</span></GridItem>
                  <GridItem className="pihi-thickness"><span>{ t('thickness') }</span></GridItem>
                </GridContainer>
                {data.map((material, index) => (
                  <SubMaterial
                    key={index}
                    data={material}
                    onDataChange={(data) => this.handleDataChange(index, data)}
                    errors={this.matchErrorToMaterial(material)}
                  />
                ))}
              </GridContainer>
          }
        </div>
      </div>
    )
  }
}

export default withTranslation()(RawAllowances);
