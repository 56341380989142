import React from "react";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';

import {
  resetCustomerImportData,
} from "../../store/actions/customers";

import Autocomplete from 'components/Autocomplete';
import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Loader from "../../components/Loader/Loader";

import './style.scss';

class ContactsImportDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      custom_mapping: [],
      has_header: '1',
      save_mapping: '0'
    };
  }

  componentDidMount() {
    if (this.props.customerImportValidationData.default_mapping && this.props.customerImportValidationData.default_mapping.length !== 0) {
      this.setState({
        custom_mapping: this.props.customerImportValidationData.default_mapping
      })
    }
  }

  handleTitleDropdownClick = (index, value) => {
    const {custom_mapping} = this.state;
    let new_custom_mapping = [...custom_mapping];
    if (value !== -1) {
      new_custom_mapping[index] = value;
    } else {
      delete new_custom_mapping[index];
    }
    this.setState({
      custom_mapping: new_custom_mapping
    })
  }

  handleHeaderChange = (value) => {
    this.setState({
      has_header: value
    })
  };

  handleMappingChange = (value) => {
    this.setState({
      save_mapping: value
    })
  };

  renderTitleDropdown = (index) => {
    const {fields, t} = this.props;
    const {custom_mapping} = this.state;

    // filter input fields so that a value that is selected elsewhere is cannot be selected twice
    let importFields = [{id: -1, value: t('none')}, ...fields];
    const excludeValues = [];
    const customMappingKeys = Object.keys(custom_mapping);
    for (let i = 0, len = customMappingKeys.length; i < len; ++i) {
      const customMappingKey = customMappingKeys[i];
      if (customMappingKey != index) {
        excludeValues.push(custom_mapping[customMappingKey]);
      }
    }
    importFields = importFields.filter(o => excludeValues.indexOf(o.id) == -1);
    
    return (
      <Autocomplete
        key={index}
        buttonText={custom_mapping[index] ? custom_mapping[index] : ''}
        buttonProps={{className: 'dropdown-blue-style order-dropdown', round: true, size: "sm"}}
        dropdownList={importFields}
        hoverColor="dark"
        placeholder={t('enter')}
        discardIcon={true}
        onClick={(value) => this.handleTitleDropdownClick(index, value.id)}
      />
    )
  }

  handleCloseBtn = () => {
    const {onClose, resetCustomerImportData} = this.props;
    onClose();
    resetCustomerImportData()
  }

  handleStartButton = () => {
    const {onStartButton} = this.props;
    const {
      has_header,
      save_mapping,
      custom_mapping
    } = this.state;
    onStartButton(has_header, custom_mapping, save_mapping)
  }

  render() {
    const {
      isOpen,
      isStartedFileImport,
      customerImportValidationData,
      fields,
      customerImportedFileData,
      t
    } = this.props;
    const {
      has_header,
      save_mapping
    } = this.state;
    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'contacts-import-dialog',
          }
        }}>
        <MuiDialogTitle className="contact-file-import-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{t('file_import')}</Typography>
          <IconButton aria-label="close" className="close-btn" onClick={this.handleCloseBtn}>
            <img alt="file-import-close-btn" src={CloseButton}/>
          </IconButton>
        </MuiDialogTitle>
        <div className="contact-file-import-content">
          {
            !isStartedFileImport
              ?
                <>
                  <div className="contact-import-checkbox">
                    <Checkbox
                      checked={has_header === '1'}
                      onChange={() => this.handleHeaderChange(has_header === '1' ? '0' : '1')}
                    />
                    <span className="checkbox-label">{ t('header_in_file_checkbox') }</span>
                  </div>
                  <div className="contact-import-checkbox">
                    <Checkbox
                      checked={save_mapping === '1'}
                      onChange={() => this.handleMappingChange(save_mapping === '1' ? '0' : '1')}
                    />
                    <span className="checkbox-label">{ t('cache_current_mapping') }</span>
                  </div>
                  <div className="file-import-text">
                    { t('select_fields_dropdowns_column') }:
                  </div>
                  <div className="file-fields-title-container">
                    <div className="contacts-table-container">
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            {
                              customerImportValidationData && customerImportValidationData.preview && customerImportValidationData.preview[0].map((item, index) => (
                                <TableRow>
                                  <TableCell>{this.renderTitleDropdown(index)}</TableCell>
                                  {
                                    customerImportValidationData && customerImportValidationData.preview && customerImportValidationData.preview.map((row, key) => (
                                      (key < 2) && <TableCell className="table-data-row-item" key={key}>{row[index].replace(/(.{25})..+/, "$1…")}</TableCell>
                                    ))
                                  }
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </>
              :
                <div className="import-loader">
                  <Loader />
                </div>
          }
        </div>
        <div className="contact-file-import-btn-container">
          <Button
            className="cancel-btn"
            onClick={this.handleCloseBtn}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleStartButton}
            disabled={isStartedFileImport}
          >
            { t('import_file').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    customerImportValidationData: state.customers.customerImportValidationData,
    fields: state.customers.fields,
    customerImportValidationErrors: state.customers.customerImportValidationErrors,
    customerImportedFileData: state.customers.customerImportedFileData,
    isStartedFileImport: state.customers.isStartedFileImport
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetCustomerImportData: () => dispatch(resetCustomerImportData())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ContactsImportDialog));
