import React from 'react';
import { withTranslation } from 'react-i18next';

import Input from '../../../../components/Input/Input';
import Dropdown from '../../../../components/CustomDropdown/CustomDropdown';
import UploadImage from '../../../../components/UploadImg';
import CustomAutocomplete from "../../../../components/CustomAutocomplete";

import './style.scss';

class CompanyInfoPage extends React.Component {
  fileInputChange = (data) => {
    this.props.onChange('logo', data)
  };

  handlePhoneNumberChange = (phone) => {
    if(Number.isInteger(+phone)) {
      this.props.onChange('phone_no', phone)
    }
  }

  render() {
    const {
      data,
      countries,
      cities,
      errors,
      onChange,
      t
    } = this.props;
    return (
      <div className="company-info-page">
        <div className="company-info-page-title">
          <span>{ t('company_general_information') }</span>
        </div>
        <div></div>
        <div className="company-info-content">
          <div className="company-info">
            <span className="company-info-title">{ t('company_name') }</span>
            <div className="input-container">
              <Input
                value={data.name}
                onChange={e => onChange('name', e.target.value)}
                error={errors.name && errors.name.error}
                errorMsg={errors.name && errors.name.message}
              />
            </div>
            <span className="company-info-title">{ t('address') }</span>
            <div className="input-container">
              <Input
                value={data.address}
                onChange={e => onChange('address', e.target.value)}
                error={errors.address && errors.address.error}
                errorMsg={errors.address && errors.address.message}
              />
            </div>
            <div className="company-info-field-container">
              <span className="company-info-title">{ t('city') }</span>
              <CustomAutocomplete
                buttonText={data.city_id ? data.city_id : ''}
                buttonProps={{className: '', round: true, size: "sm"}}
                className={'autocomplete-size'}
                discardIcon={true}
                dropdownList={cities ? cities : []}
                hoverColor="dark"
                error={errors.city_id && errors.city_id.message}
                getData={(value) => data.country_id ? this.props.getCities(data.country_id, value) : null}
                onClick={(value) => onChange('city_id', value.id)}
              />
              { errors.city_id && errors.city_id.message ? <span className="error-msg">{ errors.city_id.message }</span> : null }
            </div>
            <div className="company-info-field-container">
              <span className="company-info-title">{ t('country') }</span>
              <Dropdown
                buttonText={data.country_id}
                buttonProps={{className: 'dropdown-style', round: true, size: "sm"}}
                dropdownList={countries}
                hoverColor="dark"
                error={errors.country_id && errors.country_id.message}
                onClick={(value) => onChange('country_id', value.id)}
                customClass={'dropdown-size'}
              />
              { errors.country_id && errors.country_id.message ? <span className="error-msg">{ errors.country_id.message }</span> : null }
            </div>
            <span className="company-info-title">{ t('website') }</span>
            <div className="input-container">
              <Input
                value={data.website}
                onChange={e => onChange('website', e.target.value)}
                error={errors.website && errors.website.error}
                errorMsg={errors.website && errors.website.message}
              />
            </div>
          </div>
          <div className="company-info">
            <span className="company-info-title">{ t('logo') }</span>
            <UploadImage
              className="company-info-upload"
              errorMsg={errors.logo && errors.logo.message}
              showErrorMessage={true}
              file={data.logo}
              onChange={this.fileInputChange}
            />
            <span className="company-info-title">{ t('email') }</span>
            <div className="input-container">
              <Input
                value={data.email}
                onChange={e => onChange('email', e.target.value)}
                error={errors.email && errors.email.error}
                errorMsg={errors.email && errors.email.message}
              />
            </div>
            <span className="company-info-title">{ t('phone') }</span>
            <div className="input-container">
              <Input
                value={data.phone_no}
                onChange={e => this.handlePhoneNumberChange(e.target.value)}
                error={errors.phone_no && errors.phone_no.error}
                errorMsg={errors.phone_no && errors.phone_no.message}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(CompanyInfoPage);
