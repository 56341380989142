import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { mapTo, catchError, delay } from 'rxjs/operators';
import {
  getProjectCategoriesSuccessful,
  getProjectCategoriesFailed
} from 'store/actions/projectCategories';
import { GET_PROJECT_CATEGORIES } from 'store/actionTypes/projectCategories';
import { projectCategories as dummyProjectCategories } from './epicsData';

export const getProjectCategories = actions$ => {
  return actions$.pipe(
    ofType(GET_PROJECT_CATEGORIES),
    delay(2000),
    mapTo(getProjectCategoriesSuccessful(dummyProjectCategories)),
    catchError((error, caught) =>
      merge(of(getProjectCategoriesFailed(error)), caught)
    )
  );
};
