import React from "react";
import { withTranslation } from 'react-i18next';

import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';

class ConfirmDialog extends React.Component {
  render() {
    const { title, text, onClose, onConfirm, isOpen, t } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="confirm-dialog-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{`${title}`}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="file-import-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="confirm-dialog-content">
          {`${text}`}
        </div>
        <div className="confirm-dialog-btn-container">
          <Button
            className="cancel-btn"
            onClick={onClose}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={onConfirm}
          >
            { t('confirm').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ConfirmDialog);
