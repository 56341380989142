import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Allowance from './Allowance';
import Orientation from './Orientation';

import './style.scss';

class MaterialList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dataAllowance, errorsAllowance, onDataAllowanceChange } = this.props;
    return (
      <GridContainer className="materials-list-positions-outer-container">
        <GridItem className="materials-list-positions-container" xs={8}>
          <Allowance
            data={dataAllowance}
            errors={errorsAllowance}
            onDataChange={onDataAllowanceChange}
          />
        </GridItem>
        <GridItem className="materials-list-positions-container" xs={4}>
          <Orientation />
        </GridItem>
      </GridContainer>
    )
  }
}

export default MaterialList;
