import React from "react";
import { withTranslation } from 'react-i18next';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Button from '../../components/CustomButtons/Button';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import GridItem from "../../components/Grid/GridItem";
import Input from "../../components/Input/Input";

import CloseButton from "../../assets/img/buttons/delete.svg";

import './style.scss';

import {addCategory, getParentCategories, deleteCategory, resetCategory, updateCategory, getChildCategories} from 'store/actions/categories'
import {connect} from "react-redux";
import TextArea from "../../components/CustomTextArea";
import CloseDialog from '../CloseDialog';

class CreateCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        name: '',
        type: '',
        description: '',
        parent_id: '',
        child_id: '',
      },

      isCloseDialogOpen: false,
      haveEdits: false,
    }
  }

  componentDidMount() {
    if (this.props.currentCategory.id) {
      this.init()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isCategoryValid && this.props.isCategoryValid ) {
      this.props.onClose()
    }
  }

  componentWillUnmount() {
    this.props.resetData()
  }

  init = () => {
    const {
      currentCategory,
      getParentCategories,
      getChildCategories,
      categoriesBundles,
    } = this.props;
    getParentCategories({type: currentCategory.type});
    const newCurrentCategory = { ...currentCategory };
    if (newCurrentCategory.level == 3) {
      newCurrentCategory.child_id = newCurrentCategory.parent_id;
      newCurrentCategory.parent_id = newCurrentCategory.parent.parent_id;
      if (categoriesBundles.parentIds.indexOf(newCurrentCategory.parent_id) == -1 || !categoriesBundles.successes[newCurrentCategory.parent_id])
        getChildCategories(newCurrentCategory.parent_id, true);
    }
    this.setState({
      category: newCurrentCategory
    })
  }

  isDataReady = () => {
    const {
      types
    } = this.props;
    return (types.length !== 0)
  }

  handleOnClose = () => {
    if (this.props.onClose)
      this.props.onClose();
  }

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleOnClose();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleOnClose();
    });
  }

  handledDataChange = (fieldName, value) => {
    const {getParentCategories, getChildCategories, parentCategories, categoriesBundles} = this.props;
    const {category} = this.state;
    let newCategory = {...category, [fieldName]: value};
    if (fieldName === 'type') {
      getParentCategories({type: value});
      newCategory.parent_id = '';
      newCategory.child_id = '';
    } else if (fieldName === 'parent_id' && value) {
      if (value != -1) {
        const parentCategory = parentCategories.find(el => el.id == newCategory.parent_id);
        newCategory.child_id = '';
        if ((categoriesBundles.parentIds.indexOf(value) == -1 || !categoriesBundles.successes[value]) && parentCategory) {
          getChildCategories(value, true);
        }
      } else {
        newCategory.parent_id = '';
        newCategory.child_id = '';
      }
    } else if (fieldName === 'child_id' && value == -1) {
      newCategory.child_id = '';
    }
    this.setState({
      category: newCategory,
      haveEdits: true,
    })
  }

  handleCreateUpdateButton = () => {
    const {
      currentCategory,
      addCategory,
      updateCategory,
      metaData,
      type,
      order
    } = this.props;
    const { category } = this.state;
    const sendCategory = {...category};
    if (sendCategory.child_id) {
      sendCategory.parent_id = sendCategory.child_id;
      sendCategory.child_id = undefined;
    }

    if (currentCategory.id) {
      updateCategory({id: currentCategory.id, data: sendCategory, currentPage: metaData.current_page, order: order, searchTerms: this.props.searchTerms, level1Only: this.props.level1Only, type });
    } else {
      addCategory({data: sendCategory, currentPage: metaData.current_page, order: order, searchTerms: this.props.searchTerms, level1Only: this.props.level1Only, type });
    }
  }

  render() {
    const {
      isOpen,
      onClose,
      types,
      parentCategories,
      errors,
      categoriesBundles,
      currentCategory,
      t
    } = this.props;
    const {
      category,
    } = this.state;
    let categoriesTypes = [];
    types.map((item, index) => categoriesTypes.push({id: index, value: item.toLowerCase()}));

    let parentCategoriesActual = [{id: -1, value: t('none')}, ...parentCategories];
    let parentCategoriesTranslate = false;
    if (category.type) {
      const selectedTypeValue = categoriesTypes.find(el => el.id == category.type);
      if (selectedTypeValue && selectedTypeValue.value == "customer") {
        parentCategoriesActual = [...parentCategories].map(el => ({
          ...el,
          value: el.value.toLowerCase(),
        }));
        parentCategoriesTranslate = true;
      }
    }
    if (category.id) {
      parentCategoriesActual = parentCategoriesActual.filter(el => el.id != category.id); // cannot select self as parent
    }
    const showParentCategories = category.parent_id && parentCategories.length !== 0;
    const lcategories = categoriesBundles.successes[category.parent_id] ?
      categoriesBundles.successes[category.parent_id].map(el => ({ id: el.id, value: el.name })) : [];
    let lcategoriesActual = [{id: -1, value: t('none')}, ...lcategories];
    if (category.id) {
      lcategoriesActual = lcategoriesActual.filter(el => el.id != category.id); // cannot select self as parent
    }
    const showLSelection = category.parent_id && parentCategories.length !== 0 && lcategories && lcategoriesActual.length > 1;
    return (
      this.isDataReady() &&
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'create-category-dialog',
          }
        }}>
        <MuiDialogTitle className="create-category-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{this.props.currentCategory.id ? t('edit_category') : t('create_category')}</Typography>
          <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
            <img alt="create-category-close-btn" src={CloseButton}/>
          </IconButton>
        </MuiDialogTitle>
        <div className="create-category-content">
          <div className="category-info-item">
            <GridItem xs={12}>
              <span className="category-info-item-title">{ t('name') }</span>
              <Input
                autofocus={true}
                value={category.name}
                error={errors.name}
                errorMsg={errors.name && errors.name[0]}
                onChange={(e) => this.handledDataChange('name', e.target.value)}
              />
            </GridItem>
          </div>
          <div className="category-info-item">
            <GridItem xs={12}>
              <span className="category-info-item-title">{ t('type') }</span>
              <Dropdown
                buttonText={category.type}
                error={errors.type}
                buttonProps={{className: 'dropdown-style', round: true, size: "sm"}}
                dropdownList={categoriesTypes}
                translateValues={true}
                hoverColor="dark"
                onClick={data => this.handledDataChange('type', data.id)}
              />
            </GridItem>
          </div>
          <div className="category-info-item">
            <GridItem xs={12}>
              <span className="category-info-item-title">{ t('parent_category') }</span>
              <Dropdown
                buttonText={showParentCategories ? (category.parent_id ? category.parent_id : -1) : -1}
                buttonProps={{className: 'dropdown-style', disabled: parentCategories.length === 0, round: true, size: "sm"}}
                dropdownList={parentCategoriesActual}
                translateValues={parentCategoriesTranslate}
                error={!showLSelection? errors.parent_id : null}
                hoverColor="dark"
                onClick={data => this.handledDataChange('parent_id', data.id)}
                customClass={'parent-category-dropdown'}
              />
              <div className="category-l-selection-container" style={{display: showLSelection ? "block" : "none"}}>
                <Dropdown
                  buttonText={showLSelection ? (category.child_id ? category.child_id : -1) : -1}
                  buttonProps={{className: 'dropdown-style', disabled: !lcategories || lcategories.length === 0, round: true, size: "sm"}}
                  dropdownList={lcategoriesActual}
                  error={errors.parent_id}
                  hoverColor="dark"
                  onClick={data => this.handledDataChange('child_id', data.id)}
                />
              </div>
            </GridItem>
          </div>
          <div className="category-info-item">
            <GridItem xs={12}>
              <span className="category-info-item-title">{ t('description') }</span>
              <TextArea
                value={category.description}
                onChange={(e) => this.handledDataChange('description', e.target.value)}
                /*error={errors.description}*/
              />
            </GridItem>
          </div>
        </div>
        <div className="category-btn-container">
          <Button
            className="cancel-btn"
            onClick={this.handleOnClose}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleCreateUpdateButton}
          >
            { this.props.currentCategory.id ? t('update_category').toUpperCase() : t('create_category').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('close_category')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    types: state.categories.categoriesTypes,
    parentCategories: state.categories.parentCategories,
    currentCategory: state.categories.category,
    isCategoryValid: state.categories.isCategoryValid,
    categoriesBundles: state.categories.categoriesBundles,
    metaData: state.categories.metaData,
    errors: state.categories.categoryError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCategory: (data) => dispatch(addCategory(data)),
    getParentCategories: (data) => dispatch(getParentCategories(data)),
    getChildCategories: (id, keepCurrent) => dispatch(getChildCategories(id, keepCurrent)),
    resetData: () => dispatch(resetCategory()),
    updateCategory: (data) => dispatch(updateCategory(data))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateCategory));
