import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { checkCurrentAuthStatus } from 'store/actions/auth';
import AuthLayout from 'layouts/Auth';
import RtlLayout from 'layouts/RTL';
import AdminLayout from 'layouts/Admin';
import AuthAPLayout from 'layouts/AuthAP';
import RegisterLayout from 'layouts/RegisterAP';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import 'scss/app.scss';

class App extends Component {
  constructor() {
    super();
    this.hist = createBrowserHistory();
  }

  componentDidMount() {
    this.props.checkCurrentAuthStatus()
  }

  render() {
    const {auth} = this.props;
    return (
      <Router history={this.hist}>
        <ToastContainer />
        <Switch>
          <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/auth-ap" component={AuthAPLayout} />
          <Route path="/register" component={RegisterLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Redirect from="/" to="/admin/orders" />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProp = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkCurrentAuthStatus: () => dispatch(checkCurrentAuthStatus())
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(App);
