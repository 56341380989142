import React from 'react';
import { withTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '../../../../components/Input/Input';
import Dropdown from '../../../../components/CustomDropdown/CustomDropdown';

import './style.scss';

class CompanyDetailsPage extends React.Component {
  render() {
    const {
      data,
      timeData,
      errors,
      onChange,
      t
    } = this.props;
    const timeFrom = timeData.find(item => item.value === data.time_from).id;
    const timeTo = timeData.find(item => item.value === data.time_to).id;
    return (
      <div className="company-details-page">
        <div className="company-details-page-title">
          <span>{ t('company_registration_details') }</span>
        </div>
        <div></div>
        <div className="company-details-content">
          <div className="company-details">
            <span className="input-title">{ t('legal_registration_name') }</span>
            <div className="input-container">
              <Input
                value={data.legal_name}
                onChange={e => onChange('legal_name', e.target.value)}
                error={errors.legal_name && errors.legal_name.error}
                errorMsg={errors.legal_name && errors.legal_name.message}
              />
            </div>
            <span className="input-title">{ t('billing_address') }</span>
            <div className="input-container">
              <Input
                value={data.billing_address}
                onChange={e => onChange('billing_address', e.target.value)}
                error={errors.billing_address && errors.billing_address.error}
                errorMsg={errors.billing_address && errors.billing_address.message}
              />
            </div>
            <span className="input-title">{ t('tax_number') }</span>
            <div className="input-container">
              <Input
                value={data.tax_no}
                onChange={e => onChange('tax_no', e.target.value)}
                error={errors.tax_no && errors.tax_no.error}
                errorMsg={errors.tax_no && errors.tax_no.message}
              />
            </div>
            <span className="input-title">{ t('eu_vat_registration_number') }</span>
            <div className="input-container">
              <Input
                value={data.eu_vat_no}
                onChange={e => onChange('eu_vat_no', e.target.value)}
                error={errors.eu_vat_no && errors.eu_vat_no.error}
                errorMsg={errors.eu_vat_no && errors.eu_vat_no.message}
              />
            </div>
          </div>
          <div className="company-details">
            <span className="input-title">{ t('delivery_options') }</span>
            <div className="delivery-options">
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.delivery_full_service === '1'}
                        onChange={() => onChange('delivery_full_service', data.delivery_full_service === '1' ? '0' : '1')}
                      />
                    }
                    label={t('full_service')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.delivery_complete === '1'}
                        onChange={() => onChange('delivery_complete', data.delivery_complete === '1' ? '0' : '1')}
                      />
                    }
                    label={t('complete_delivery')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.delivery_parts === '1'}
                        onChange={() => onChange('delivery_parts', data.delivery_parts === '1' ? '0' : '1')}
                      />
                    }
                    label={t('parts_delivery')}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <span className="dropdown-title">{ t('working_hours_monday_friday') }</span>
            <div className="time-selection">
              <Dropdown
                buttonText={timeFrom}
                buttonProps={{className: 'dropdown-style', round: true, size: "sm"}}
                dropdownList={timeData}
                hoverColor="dark"
                onClick={(data) => onChange('time_from', data.value)}
              />
              <span className="time-divider">-</span>
              <Dropdown
                buttonText={timeTo}
                buttonProps={{className: 'dropdown-style', round: true, size: "sm"}}
                dropdownList={timeData}
                hoverColor="dark"
                onClick={(data) => onChange('time_to', data.value)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(CompanyDetailsPage);
