import {
  GET_METRICS,
  GET_METRICS_SUCCESS,
  GET_METRICS_ERROR,
  GET_CURRENCY,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_ERROR,
} from '../actionTypes/globalsActionTypes';

import NotificationService from '../../services/NotificationService';

export const getMetrics = () => {
  return { type: GET_METRICS, payload: null };
};

export const getMetricsSuccess = data => {
  return { type: GET_METRICS_SUCCESS, payload: data };
};

export const getMetricsFailure = error => {
  NotificationService.error('Failed to retrieve metrics.');
  return { type: GET_METRICS_ERROR, payload: error };
};

export const getCurrency = () => {
  return { type: GET_CURRENCY, payload: null };
};

export const getCurrencySuccess = data => {
  return { type: GET_CURRENCY_SUCCESS, payload: data };
};

export const getCurrencyFailure = error => {
  NotificationService.error('Failed to retrieve currency.');
  return { type: GET_CURRENCY_ERROR, payload: error };
};
