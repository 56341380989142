import { handleActions } from 'redux-actions';
import {
  GET_MATERIALS,
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS_ERROR,
  GET_MATERIALS_FOR_CATEGORY_SUCCESS,
  GET_MATERIALS_IN_BUNDLES,
  GET_MATERIALS_IN_BUNDLE_SUCCESS,
  GET_MATERIALS_IN_BUNDLE_ERROR,
  GET_MATERIALS_IN_BUNDLES_FINISH,
  GET_MATERIAL,
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_ERROR,
  ADD_MATERIAL,
  ADD_MATERIAL_SUCCESS,
  ADD_MATERIAL_ERROR,
  DUPLICATE_MATERIAL_SUCCESS,
  REMOVE_MATERIAL,
  REMOVE_MATERIAL_SUCCESS,
  REMOVE_MATERIAL_ERROR,
  RESTORE_MATERIALS,
  RESTORE_MATERIALS_SINGLE_SUCCESS,
  RESTORE_MATERIALS_SINGLE_FAILURE,
  RESTORE_MATERIALS_FINISH,
  REMOVE_MATERIALS,
  REMOVE_MATERIALS_SINGLE_SUCCESS,
  REMOVE_MATERIALS_SINGLE_FAILURE,
  REMOVE_MATERIALS_FINISH,
  UPDATE_MATERIAL_REQUEST,
  UPDATE_MATERIAL_REQUEST_SUCCESS,
  UPDATE_MATERIAL_REQUEST_ERROR,
  UPDATE_COMPOUND_MATERIAL_BASE,
  UPDATE_COMPOUND_MATERIAL_BASE_SUCCESS,
  UPDATE_COMPOUND_MATERIAL_BASE_ERROR,
  ADD_COMPOUND_MATERIAL,
  ADD_COMPOUND_MATERIAL_SUCCESS,
  ADD_COMPOUND_MATERIAL_ERROR,
  ADD_MATERIAL_REQUEST,
  ADD_MATERIAL_REQUEST_SUCCESS,
  ADD_MATERIAL_REQUEST_ERROR,
  START_MATERIAL_FILE_IMPORT,
  START_MATERIAL_FILE_IMPORT_SUCCESS,
  START_MATERIAL_FILE_IMPORT_FAILURE,
  DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED,
  DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_SUCCESS,
  DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_ERROR,
  RESET_MATERIAL_DATA,
  RESET_MATERIAL_DUPLICATE_DATA,
  UPDATE_MATERIAL_CATEGORY_VALUES,
  UPDATE_MATERIAL_CATEGORY_VALUES_SUCCESS,
  UPDATE_MATERIAL_CATEGORY_VALUES_ERROR,
  UPDATE_MULTIPLE_MATERIALS_VALUES,
  UPDATE_MULTIPLE_MATERIALS_VALUES_SUCCESS,
  UPDATE_MULTIPLE_MATERIALS_VALUES_ERROR,
  UPDATE_MULTIPLE_MATERIALS_PRICE,
  UPDATE_MULTIPLE_MATERIALS_PRICE_SUCCESS,
  UPDATE_MULTIPLE_MATERIALS_PRICE_ERROR,
} from '../actionTypes/materialsActionTypes';

export const DEFAULT_MATERIALS_STATE = {
  materials: [],
  materialsForCategory: [],
  isMaterialValid: false,
  isRemoveMaterialValid: false,
  isRestoreMaterialValid: false,
  isMultipleMaterialsUpdateValid: false,
  isMaterialDuplicated: false,
  materialsBundles: {
    ids: [],
    successes: {},
    errors: {}
  },
  metaData: {},
  bodyMaterials: [],
  skeletonMaterials: [],
  handlesMaterials: [],
  materialsError: null,
  compoundMaterialError: null,
  materialsBusy: false,
  materialsBundlesBusy: false,
  materialImportedFileData: {
    processID: '',
    skipped: 0,
  },
  materialImportedFileSkippedBlob: null,
  materialImportedFileSkippedFilename: null,
  materialImportedFileErrors: {},
  batchRestoreBusy: false,
  batchRestoreState: {
    ids: [],
    errors: {},
  },
  batchRemoveBusy: false,
  batchRemoveState: {
    ids: [],
    errors: {},
  },
};

export const materials = handleActions(
  {
    [GET_MATERIALS]: (state, action) => ({
      ...state,
      materialsBundles: {
        ids: [],
        successes: {},
        errors: {}
      },
      materialsBusy: true
    }),
    [GET_MATERIALS_SUCCESS]: (state, action) => ({
      ...state,
      materials: action.payload.data,
      metaData: action.payload.meta,
      bodyMaterials: action.payload.bodyMaterials,
      skeletonMaterials: action.payload.skeletonMaterials,
      handlesMaterials: action.payload.handlesMaterials,
      materialsError: null,
      materialsBusy: false
    }),
    [GET_MATERIALS_ERROR]: (state, action) => ({
      ...state,
      materialsError: action.payload.message,
      materialsBusy: false
    }),
    [DUPLICATE_MATERIAL_SUCCESS]: (state) => ({
      ...state,
      isMaterialDuplicated: true
    }),
    // [GET_MATERIALS_FOR_CATEGORY]: (state, action) => ({
    //   ...state,
    //   materialsBundles: {
    //     ids: [],
    //     successes: {},
    //     errors: {}
    //   },
    //   materialsBusy: true
    // }),
    [GET_MATERIALS_FOR_CATEGORY_SUCCESS]: (state, action) => ({
      ...state,
      materialsForCategory: action.payload.data,
    }),
    // [GET_MATERIALS_FOR_CATEGORY_ERROR]: (state, action) => ({
    //   ...state,
    //   materialsError: action.payload.message,
    //   materialsBusy: false
    // }),
    [GET_MATERIALS_IN_BUNDLES]: (state, action) => ({
      ...state,
      materialsBundles: {
        ids: action.payload.keepCurrent
          ? [...state.materialsBundles.ids, ...action.payload.ids]
          : action.payload.ids,
        successes: action.payload.keepCurrent
          ? state.materialsBundles.successes
          : {},
        errors: action.payload.keepCurrent ? state.materialsBundles.errors : {}
      },
      materialsBundlesBusy: true
    }),
    [GET_MATERIALS_IN_BUNDLE_SUCCESS]: (state, action) => ({
      ...state,
      materialsBundles: {
        ...state.materialsBundles,
        successes: {
          ...state.materialsBundles.successes,
          [action.payload.id]: action.payload.data
        }
      }
    }),
    [GET_MATERIALS_IN_BUNDLE_ERROR]: (state, action) => ({
      ...state,
      materialsBundles: {
        ...state.materialsBundles,
        errors: {
          ...state.materialsBundles.errors,
          [action.payload.id]: action.payload.error
        }
      }
    }),
    [GET_MATERIALS_IN_BUNDLES_FINISH]: (state, action) => ({
      ...state,
      materialsBundlesBusy: false
    }),
    [GET_MATERIAL]: (state, action) => ({
      ...state,
      materialsBusy: false
    }),
    [GET_MATERIAL_SUCCESS]: (state, action) => ({
      ...state,
      material: action.payload,
      materialsBusy: false
    }),
    [GET_MATERIAL_ERROR]: (state, action) => ({
      ...state,
      materialsBusy: false,
      materialsError: action.payload.message
    }),
    [ADD_MATERIAL]: (state, action) => ({
      ...state,
      materialsMutBusy: true
    }),
    [ADD_MATERIAL_SUCCESS]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      isMaterialValid: true
    }),
    [ADD_MATERIAL_ERROR]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      materialsError: action.payload.message
    }),
    [ADD_COMPOUND_MATERIAL]: (state, action) => ({
      ...state,
      materialsMutBusy: true
    }),
    [ADD_COMPOUND_MATERIAL_SUCCESS]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      isMaterialValid: true
    }),
    [ADD_COMPOUND_MATERIAL_ERROR]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      compoundMaterialError: action.payload.message
    }),
    [ADD_MATERIAL_REQUEST]: (state, action) => ({
      ...state,
      materialsMutBusy: true
    }),
    [ADD_MATERIAL_REQUEST_SUCCESS]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      isMaterialValid: true,
    }),
    [ADD_MATERIAL_REQUEST_ERROR]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      materialsError: action.payload.message
    }),
    [UPDATE_MATERIAL_REQUEST]: (state, action) => ({
      ...state,
      materialsMutBusy: true,
      materialsError: null,
    }),
    [UPDATE_MATERIAL_REQUEST_SUCCESS]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      isMaterialValid: true
    }),
    [UPDATE_MATERIAL_REQUEST_ERROR]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      materialsError: action.payload.message
    }),
    [UPDATE_COMPOUND_MATERIAL_BASE]: (state, action) => ({
      ...state,
      materialsMutBusy: true,
      materialsError: null,
    }),
    [UPDATE_COMPOUND_MATERIAL_BASE_SUCCESS]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      isMaterialValid: true
    }),
    [UPDATE_COMPOUND_MATERIAL_BASE_ERROR]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      compoundMaterialError: action.payload.message
    }),
    [START_MATERIAL_FILE_IMPORT]: (state, action) => ({
      ...state,
      materialImportedFileData: {
        processID: '',
        skipped: 0
      }
    }),
    [START_MATERIAL_FILE_IMPORT_SUCCESS]: (state, action) => ({
      ...state,
      materialImportedFileData: action.payload
    }),
    [START_MATERIAL_FILE_IMPORT_FAILURE]: (state, action) => ({
      ...state,
      materialImportedFileErrors: action.payload
    }),
    [DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED]: (state, action) => ({
      ...state,
      materialImportedFileSkippedBlob: null,
      materialImportedFileSkippedFilename: null,
    }),
    [DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_SUCCESS]: (state, action) => ({
      ...state,
      materialImportedFileSkippedBlob: action.payload.blob,
      materialImportedFileSkippedFilename: action.payload.filename,
    }),
    [DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_ERROR]: (state, action) => ({
      ...state,
      materialImportedFileSkippedBlob: null,
      materialImportedFileSkippedFilename: null,
    }),
    [REMOVE_MATERIAL]: (state, action) => ({
      ...state,
      materialsMutBusy: true,
      isRemoveMaterialValid: false,
    }),
    [REMOVE_MATERIAL_SUCCESS]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      isRemoveMaterialValid: true,
    }),
    [REMOVE_MATERIAL_ERROR]: (state, action) => ({
      ...state,
      isRemoveMaterialValid: false,
      materialsMutBusy: false,
      materialsError: action.payload,
    }),
    [RESTORE_MATERIALS]: (state, action) => ({
      ...state,
      batchRestoreBusy: true,
      isRestoreMaterialValid: false,
      batchRestoreState: {
        ids: action.payload.data,
        errors: {},
      },
    }),
    [RESTORE_MATERIALS_SINGLE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [RESTORE_MATERIALS_SINGLE_FAILURE]: (state, action) => ({
      ...state,
      batchRestoreState: {
        ...state.batchRestoreState,
        errors: {
          ...state.batchRestoreState.errors,
          [action.payload.id]: [action.payload.error],
        },
      },
    }),
    [RESTORE_MATERIALS_FINISH]: (state, action) => ({
      ...state,
      isRestoreMaterialValid: true,
      batchRestoreBusy: false,
    }),
    [REMOVE_MATERIALS]: (state, action) => ({
      ...state,
      batchRemoveBusy: true,
      isRemoveMaterialValid: false,
      batchRemoveState: {
        ids: action.payload.data,
        errors: {},
      },
    }),
    [REMOVE_MATERIALS_SINGLE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [REMOVE_MATERIALS_SINGLE_FAILURE]: (state, action) => ({
      ...state,
      batchRemoveState: {
        ...state.batchRemoveState,
        errors: {
          ...state.batchRemoveState.errors,
          [action.payload.id]: [action.payload.error],
        },
      },
    }),
    [REMOVE_MATERIALS_FINISH]: (state, action) => ({
      ...state,
      isRemoveMaterialValid: true,
      batchRemoveBusy: false,
    }),
    [RESET_MATERIAL_DATA]: (state) => ({
      ...state,
      material: {},
      materialsError: null,
      compoundMaterialError: null,
      materialsBundles: {
        ids: [],
        successes: {},
        errors: {}
      },
      isMaterialValid: false
    }),
    [RESET_MATERIAL_DUPLICATE_DATA]: (state) => ({
      ...state,
      isMaterialDuplicated: false
    }),
    [UPDATE_MATERIAL_CATEGORY_VALUES]: (state, action) => ({
      ...state,
      materialsMutBusy: true,
      materialsError: null,
      isMultipleMaterialsUpdateValid: false,
    }),
    [UPDATE_MATERIAL_CATEGORY_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      isMultipleMaterialsUpdateValid: true,
    }),
    [UPDATE_MATERIAL_CATEGORY_VALUES_ERROR]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      materialsError: action.payload,
    }),
    [UPDATE_MULTIPLE_MATERIALS_VALUES]: (state, action) => ({
      ...state,
      materialsMutBusy: true,
      materialsError: null,
      isMultipleMaterialsUpdateValid: false,
    }),
    [UPDATE_MULTIPLE_MATERIALS_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      isMultipleMaterialsUpdateValid: true,
    }),
    [UPDATE_MULTIPLE_MATERIALS_VALUES_ERROR]: (state, action) => ({
      ...state,
      materialsMutBusy: false,
      materialsError: action.payload,
    }),
    [UPDATE_MULTIPLE_MATERIALS_PRICE]: (state, action) => ({
      ...state,
      materialsBusy: true,
      materialsError: null,
      isMultipleMaterialsUpdateValid: false,
    }),
    [UPDATE_MULTIPLE_MATERIALS_PRICE_SUCCESS]: (state, action) => ({
      ...state,
      materialsBusy: false,
      isMultipleMaterialsUpdateValid: true,
    }),
    [UPDATE_MULTIPLE_MATERIALS_PRICE_ERROR]: (state, action) => ({
      ...state,
      materialsBusy: false,
      materialsError: action.payload,
    }),
  },
  DEFAULT_MATERIALS_STATE
);
