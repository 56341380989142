import React from "react";
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from "react-perfect-scrollbar";

import ShippingCard from "./ShippingAddressItem";

import './style.scss';

class ShippingAddressView extends React.Component {
  render() {
    const {data, t} = this.props;
    return(
      <div className="shipping-address-content">
        <PerfectScrollbar className="shipping-address-content-scrollbar">
          {data.length !== 0 ? 
            data.map((item, index) => <ShippingCard data={item} key={index} id={index} />)
            :
            <div className='not-available-msg-container'> 
            <div className='not-available-msg'>{ t('unavailable') }</div> 
            </div>
          }
        </PerfectScrollbar>
      </div>
    )
  }
}

export default withTranslation()(ShippingAddressView);
