import { handleActions } from 'redux-actions';
import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  RESET_CUSTOMER_DATA,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_ERROR,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMER_SUCCESS,
  REMOVE_CUSTOMER_FAILURE,
  REMOVE_CUSTOMERS,
  REMOVE_CUSTOMERS_SINGLE_SUCCESS,
  REMOVE_CUSTOMERS_SINGLE_FAILURE,
  REMOVE_CUSTOMERS_FINISH,
  VALIDATE_IMPORTED_CUSTOMER_FILE,
  VALIDATE_IMPORTED_CUSTOMER_FILE_SUCCESS,
  VALIDATE_IMPORTED_CUSTOMER_FILE_FAILURE,
  START_CUSTOMER_FILE_IMPORT,
  START_CUSTOMER_FILE_IMPORT_SUCCESS,
  START_CUSTOMER_FILE_IMPORT_FAILURE,
  RESET_CUSTOMER_IMPORT_DATA,
  RESET_CUSTOMER_IMPORT_VALIDATION
} from '../actionTypes/customerActionTypes';

export const DEFAULT_CUSTOMERS_STATE = {
  customers: [],
  customersError: null,
  customersMutBusy: false,
  metaData: {},
  customer: {},
  createdCustomer: {},
  isCustomerValid: false,
  customerError: {},
  customersBusy: false,
  customerImportValidationData: {
    processID: ''
  },
  fields:  [],
  isStartedValidation: false,
  isValidationSuccess: false,
  isStartSuccess: false,
  isStartedFileImport: false,
  customerImportValidationErrors: {},
  customerImportedFileData: {},
  customerImportedFileErrors: {},
  batchRemoveBusy: false,
  batchRemoveState: {
    ids: [],
    errors: {},
  },
};

export const customers = handleActions(
  {
    [GET_CUSTOMERS]: (state, action) => ({
      ...state,
      customersBusy: true
    }),
    [GET_CUSTOMERS_SUCCESS]: (state, action) => (
      {
      ...state,
      customers: action.payload.data,
      metaData: action.payload.meta,
      customersBusy: false
    }),
    [GET_CUSTOMERS_ERROR]: (state, action) => ({
      ...state,
      customersError: action.payload.message,
      customersBusy: false
    }),
    [GET_CUSTOMER]: (state, action) => ({
      ...state
    }),
    [GET_CUSTOMER_SUCCESS]: (state, action) => ({
      ...state,
      customer: action.payload,
    }),
    [ADD_CUSTOMER]: (state, action) => ({
      ...state,
      customersMutBusy: true,
    }),
    [ADD_CUSTOMER_SUCCESS]: (state, action) => ({
      ...state,
      isCustomerValid: true,
      customersMutBusy: false,
    }),
    [ADD_CUSTOMER_ERROR]: (state, action) => ({
      ...state,
      isCustomerValid: false,
      customerError: action.payload,
      customersError: true,
      customersMutBusy: false,
    }),
    [UPDATE_CUSTOMER]: (state, action) => ({
      ...state,
      customersMutBusy: true,
    }),
    [UPDATE_CUSTOMER_SUCCESS]: (state, action) => ({
      ...state,
      isCustomerValid: true,
      customersMutBusy: false,
    }),
    [UPDATE_CUSTOMER_ERROR]: (state, action) => ({
      ...state,
      isCustomerValid: false,
      customerError: action.payload,
      customersError: true,
      customersMutBusy: false,
    }),
    [REMOVE_CUSTOMER]: (state, action) => ({
      ...state,
      customerError: null,
      customersMutBusy: true,
    }),
    [REMOVE_CUSTOMER_SUCCESS]: (state, action) => ({
      ...state,
      customersMutBusy: false,
    }),
    [REMOVE_CUSTOMER_FAILURE]: (state, action) => ({
      ...state,
      customerError: action.payload,
      customersMutBusy: false,
    }),
    [REMOVE_CUSTOMERS]: (state, action) => ({
      ...state,
      batchRemoveBusy: true,
      batchRemoveState: {
        ids: action.payload.data,
        errors: {},
      },
    }),
    [REMOVE_CUSTOMERS_SINGLE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [REMOVE_CUSTOMERS_SINGLE_FAILURE]: (state, action) => ({
      ...state,
      batchRemoveState: {
        ...state.batchRemoveState,
        errors: {
          ...state.batchRemoveState.errors,
          [action.payload.id]: [action.payload.error],
        },
      },
    }),
    [REMOVE_CUSTOMERS_FINISH]: (state, action) => ({
      ...state,
      batchRemoveBusy: false,
    }),
    [VALIDATE_IMPORTED_CUSTOMER_FILE]: (state, action) => ({
      ...state,
      isStartedValidation: true
    }),
    [VALIDATE_IMPORTED_CUSTOMER_FILE_SUCCESS]: (state, action) => ({
      ...state,
      isStartedValidation: false,
      isValidationSuccess: true,
      customerImportValidationData: action.payload.data,
      fields: action.payload.fieldsArray
    }),
    [VALIDATE_IMPORTED_CUSTOMER_FILE_FAILURE]: (state, action) => ({
      ...state,
      isStartedValidation: false,
      isValidationSuccess: false,
      customerImportValidationErrors: action.payload
    }),

    [START_CUSTOMER_FILE_IMPORT]: (state, action) => ({
      ...state,
      isStartedFileImport: true
    }),
    [START_CUSTOMER_FILE_IMPORT_SUCCESS]: (state, action) => ({
      ...state,
      customerImportedFileData: action.payload,
      isStartSuccess: true,
      isStartedFileImport: false,
      customerImportValidationData: {
        processID: ''
      },
    }),
    [START_CUSTOMER_FILE_IMPORT_FAILURE]: (state, action) => ({
      ...state,
      isStartSuccess: false,
      isStartedFileImport: false,
      customerImportedFileErrors: action.payload
    }),
    [RESET_CUSTOMER_DATA]: (state) => ({
      ...state,
      isCustomerValid: false,
      customerError: {},
      customersError: false
    }),
    [RESET_CUSTOMER_IMPORT_DATA]: (state) => ({
      ...state,
      isValidationSuccess: false,
      isStartSuccess: false,
      customerImportValidationData: {
        processID: ''
      },
    }),
    [RESET_CUSTOMER_IMPORT_VALIDATION]: (state) => ({
      ...state,
      isValidationSuccess: false
    })
  },
  DEFAULT_CUSTOMERS_STATE
);
