import {ofType} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {switchMap, catchError, mergeMap} from 'rxjs/operators';
import {requestsServiceApi} from 'services/API';
import {
  getRequestsList,
  getRequestsListSuccess,
  getRequestsListFailure,
  approveRequestSuccess,
  approveRequestFailure,
  rejectRequestSuccess,
  rejectRequestFailure,
} from '../store/actions/requests';
import {
  GET_REQUESTS_LIST,
  APPROVE_REQUEST,
  REJECT_REQUEST,
  FILTER_REQUESTS
} from '../store/actionTypes/requestsActionTypes';

export const getRequestsEpic = (actions$) => actions$.pipe(
  ofType(GET_REQUESTS_LIST),
  switchMap((action) => from(requestsServiceApi.getRequests(action.payload)).pipe(
    switchMap((response) => {
      return of(getRequestsListSuccess(response))
    }),
    catchError( err => {
        return of(getRequestsListFailure(err))
      }
    )
  ))
);

export const filterRequestsEpic = (actions$) => actions$.pipe(
  ofType(FILTER_REQUESTS),
  switchMap((action) => from(requestsServiceApi.filterRequests(action.payload)).pipe(
    switchMap((response) => {
      return of(getRequestsListSuccess(response))
    }),
    catchError( err => {
        return of(getRequestsListFailure(err))
      }
    )
  ))
);

export const approveRequestEpic = (actions$) => actions$.pipe(
  ofType(APPROVE_REQUEST),
  mergeMap((action) => from(requestsServiceApi.approveRequest(action.payload.id)).pipe(
    switchMap(() => concat(
      of(approveRequestSuccess()),
      of(getRequestsList(action.payload.page))
    )),
    catchError( err => {
        return of(approveRequestFailure(err))
      }
    )
  ))
);

export const rejectRequestEpic = (actions$) => actions$.pipe(
  ofType(REJECT_REQUEST),
  mergeMap((action) => from(requestsServiceApi.rejectRequest(action.payload.id)).pipe(
    switchMap(() => concat(
      of(rejectRequestSuccess()),
      of(getRequestsList(action.payload.page))
    )),
    catchError( err => {
        return of(rejectRequestFailure(err))
      }
    )
  ))
);
