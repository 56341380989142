import { handleActions } from 'redux-actions';
import {
  GET_ALL_MATERIALS_SUCCESS,
  GET_MAIN_MATERIALS_LIST_SUCCESS,
  GET_POSITION_MATERIALS_LIST_SUCCESS,
  GET_FITTINGS_MATERIALS_LIST_SUCCESS,
  GET_EDGE_MATERIALS_LIST_SUCCESS,
  GET_COVERING_MATERIALS_LIST_SUCCESS,
  GET_SURFACE_MATERIALS_LIST_SUCCESS,
  GET_SELECTED_MATERIALS_SUCCESS,
} from '../actionTypes/materialProvidersActionTypes';

export const DEFAULT_MATERIAL_PROVIDERS_STATE = {
  allMaterials: [],
  mainMaterials: [],
  positionMaterials: [],
  fittingsMaterials: [],
  edgeMaterials: [],
  coveringMaterials: [],
  surfaceMaterials: [],
  selectedMaterials: [],
};

export const materialProviders = handleActions(
  {
    [GET_ALL_MATERIALS_SUCCESS]: (state, action) => ({
      ...state,
      allMaterials: action.payload,
    }),
    [GET_MAIN_MATERIALS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      mainMaterials: action.payload,
    }),
    [GET_POSITION_MATERIALS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      positionMaterials: action.payload,
    }),
    [GET_FITTINGS_MATERIALS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      fittingsMaterials: action.payload,
    }),
    [GET_EDGE_MATERIALS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      edgeMaterials: action.payload,
    }),
    [GET_COVERING_MATERIALS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      coveringMaterials: action.payload,
    }),
    [GET_SURFACE_MATERIALS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      surfaceMaterials: action.payload,
    }),
    [GET_SELECTED_MATERIALS_SUCCESS]: (state, action) => ({
      ...state,
      selectedMaterials: action.payload,
    })
  },
  DEFAULT_MATERIAL_PROVIDERS_STATE
);
