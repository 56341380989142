import { handleActions } from 'redux-actions';
import {
  GET_PRETEXT_PRESETS_SUCCESS,
  GET_PRETEXT_PRESETS_ERROR,
  GET_ENDTEXT_PRESETS_SUCCESS,
  GET_ENDTEXT_PRESETS_ERROR,
  GET_LAST_PRE_END_TEXT,
  GET_LAST_PRE_END_TEXT_SUCCESS,
  GET_LAST_PRE_END_TEXT_ERROR,
  GET_INVOICE_TYPES_PRESETS_SUCCESS,
  GET_INVOICE_TYPES_PRESETS_ERROR,
} from '../actionTypes/invoicesProvidersActionTypes';

export const DEFAULT_INVOICES_PROVIDERS_STATE = {
  preTextPresets: [],
  endTextPresets: [],
  lastPreTextValue: '',
  lastEndTextValue: '',
  haveLastPreEndText: false,
  invoiceTypesPresets: [],
  error: {},
};

export const invoicesProviders = handleActions(
  {
    [GET_PRETEXT_PRESETS_SUCCESS]: (state, action) => ({
      ...state,
      preTextPresets: action.payload.data,
    }),
    [GET_PRETEXT_PRESETS_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [GET_ENDTEXT_PRESETS_SUCCESS]: (state, action) => ({
      ...state,
      endTextPresets: action.payload.data,
    }),
    [GET_ENDTEXT_PRESETS_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [GET_LAST_PRE_END_TEXT]: (state, action) => ({
      ...state,
      lastPreTextValue: '',
      lastEndTextValue: '',
      haveLastPreEndText: false,
    }),
    [GET_LAST_PRE_END_TEXT_SUCCESS]: (state, action) => ({
      ...state,
      lastPreTextValue: action.payload.data.text_before,
      lastEndTextValue: action.payload.data.text_after,
      haveLastPreEndText: true,
    }),
    [GET_LAST_PRE_END_TEXT_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [GET_INVOICE_TYPES_PRESETS_SUCCESS]: (state, action) => ({
      ...state,
      invoiceTypesPresets: action.payload.data,
    }),
    [GET_INVOICE_TYPES_PRESETS_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
  DEFAULT_INVOICES_PROVIDERS_STATE
);
