import React from "react";

import './edgesStyle.scss';

class EdgesStructure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panel_type: 1,
    };
  }

  componentDidMount() {
    const {panel_type} = this.props;
    this.setState({ panel_type });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.panel_type !== this.props.panel_type) {
      this.setState({ panel_type: this.props.panel_type });
    }
  }

  handlePanelType = () => {
    const {panel_type} = this.state;
    const newPanelType = panel_type + 1 > 3 ? 1 : panel_type + 1;
    this.props.onChange('panel_type', newPanelType);
    this.setState({
      panel_type: newPanelType,
    });
  }

  handleCornerClick = (corner) => {
    const value = this.props[corner];
    let newValue = value + 1;
    if (newValue === 3) {
      newValue = 0;
    }
    this.props.onChange(corner, newValue);
  }

  render() {
    const {
      edge_left_top_corner,
      edge_right_top_corner,
      edge_left_bottom_corner,
      edge_right_bottom_corner,
      edge_left_amount,
      edge_right_amount,
      edge_top_amount,
      edge_bottom_amount,
    } = this.props;
    const {panel_type} = this.state;
    return (
      <div className="edges-structure-container">
        <div className="structure-top-container">
          <div
            className={`structure-button${edge_left_top_corner === 0 ? " top-left-full" : ""}${edge_left_top_corner === 1 ? " top-left-diagonal" : ""}${edge_left_top_corner === 2 ? " top-left-empty" : ""}`}
            onClick={() => {
              ((edge_left_amount !== '' && edge_left_amount !== null && edge_left_amount !== undefined) && (edge_top_amount !== '' && edge_top_amount !== null && edge_top_amount !== undefined))
                && this.handleCornerClick("edge_left_top_corner");
            }}
          >
            { edge_left_top_corner === 1 && (
              <div className="top-left-diagonal-line" />
            ) }
          </div>
          <div className={`side ${edge_top_amount !== '' && edge_top_amount !== null && edge_top_amount !== undefined ? 'top selected' : ''}`}/>
          <div
            className={`structure-button${edge_right_top_corner === 0 ? " top-right-full" : ""}${edge_right_top_corner === 1 ? " top-right-diagonal" : ""}${edge_right_top_corner === 2 ? " top-right-empty" : ""}`}
            onClick={() => {
              ((edge_right_amount !== '' && edge_right_amount !== null && edge_right_amount !== undefined) && (edge_top_amount !== '' && edge_top_amount !== null && edge_top_amount !== undefined))
                && this.handleCornerClick("edge_right_top_corner");
            }}
          >
            { edge_right_top_corner === 1 && (
              <div className="top-right-diagonal-line" />
            ) }
          </div>
        </div>
        <div className="structure-middle-container">
          <div className={`side${edge_left_amount !== '' && edge_left_amount !== null && edge_left_amount !== undefined ? ' left-side selected' : ''}`} />
          <div
            className="side-title"
            onClick={this.handlePanelType}
          >
            { panel_type === 1 ? 'F' : (panel_type === 2 ? 'S' : 'B') }
          </div>
          <div className={`side${edge_right_amount !== '' && edge_right_amount !== null && edge_right_amount !== undefined ? ' right-side selected' : ''}`} />
        </div>
        <div className="structure-bottom-container">
          <div
            className={`structure-button${edge_left_bottom_corner === 0 ? " bottom-left-full" : ""}${edge_left_bottom_corner === 1 ? " bottom-left-diagonal" : ""}${edge_left_bottom_corner === 2 ? " bottom-left-empty" : ""}`}
            onClick={() => {
              ((edge_left_amount !== '' && edge_left_amount !== null && edge_left_amount !== undefined) && (edge_bottom_amount !== '' && edge_bottom_amount !== null && edge_bottom_amount !== undefined))
                && this.handleCornerClick("edge_left_bottom_corner");
            }}
          >
            { edge_left_bottom_corner === 1 && (
              <div className="bottom-left-diagonal-line"/>
            ) }
          </div>
          <div className={`side${edge_bottom_amount !== '' && edge_bottom_amount !== null && edge_bottom_amount !== undefined ? ' bottom selected' : ''}`} />
          <div
            className={`structure-button${edge_right_bottom_corner === 0 ? " bottom-right-full" : ""}${edge_right_bottom_corner === 1 ? " bottom-right-diagonal" : ""}${edge_right_bottom_corner === 2 ? " bottom-right-empty" : ""}`}
            onClick={() => {
              ((edge_right_amount !== '' && edge_right_amount !== null && edge_right_amount !== undefined) && (edge_bottom_amount !== '' && edge_bottom_amount !== null && edge_bottom_amount !== undefined))
                && this.handleCornerClick("edge_right_bottom_corner");
            }}
          >
            { edge_right_bottom_corner === 1 && (
              <div className="bottom-right-diagonal-line"/>
            ) }
          </div>
        </div>
      </div>
    );
  }
}

export default EdgesStructure;
