export const GET_MATERIAL_CATEGORIES = 'materialCategoriesEpic/GET_MATERIAL_CATEGORIES';
export const GET_MATERIAL_CATEGORIES_SUCCESS = 'materialCategoriesEpic/GET_MATERIAL_CATEGORIES_SUCCESS';
export const GET_MATERIAL_CATEGORIES_ERROR = 'materialCategoriesEpic/GET_MATERIAL_CATEGORIES_ERROR';

export const GET_MATERIAL_CATEGORIES_PARENTS = 'materialCategoriesEpic/GET_MATERIAL_CATEGORIES_PARENTS';
export const GET_MATERIAL_CATEGORIES_PARENTS_SUCCESS = 'materialCategoriesEpic/GET_MATERIAL_CATEGORIES_PARENTS_SUCCESS';
export const GET_MATERIAL_CATEGORIES_PARENTS_ERROR = 'materialCategoriesEpic/GET_MATERIAL_CATEGORIES_PARENTS_ERROR';

export const GET_MATERIAL_CATEGORY = 'materialCategoriesEpic/GET_MATERIAL_CATEGORY';
export const GET_MATERIAL_CATEGORY_SUCCESS = 'materialCategoriesEpic/GET_MATERIAL_CATEGORY_SUCCESS';
export const GET_MATERIAL_CATEGORY_ERROR = 'materialCategoriesEpic/GET_MATERIAL_CATEGORIES_ERROR';

export const ADD_MATERIAL_CATEGORY = 'materialCategoriesEpic/ADD_MATERIAL_CATEGORY';
export const ADD_MATERIAL_CATEGORY_SUCCESS = 'materialCategoriesEpic/ADD_MATERIAL_CATEGORY_SUCCESS';
export const ADD_MATERIAL_CATEGORY_ERROR = 'materialCategoriesEpic/ADD_MATERIAL_CATEGORY_ERROR';

export const UPDATE_MATERIAL_CATEGORY = 'materialCategoriesEpic/UPDATE_MATERIAL_CATEGORY';
export const UPDATE_MATERIAL_CATEGORY_SUCCESS = 'materialCategoriesEpic/UPDATE_MATERIAL_CATEGORY_SUCCESS';
export const UPDATE_MATERIAL_CATEGORY_ERROR = 'materialCategoriesEpic/UPDATE_MATERIAL_CATEGORY_ERROR';

export const REMOVE_MATERIAL_CATEGORY = 'materialCategoriesEpic/REMOVE_MATERIAL_CATEGORY';
export const REMOVE_MATERIAL_CATEGORY_SUCCESS = 'materialCategoriesEpic/REMOVE_MATERIAL_CATEGORY_SUCCESS';
export const REMOVE_MATERIAL_CATEGORY_ERROR = 'materialCategoriesEpic/REMOVE_MATERIAL_CATEGORY_ERROR';

export const GET_MATERIAL_CATEGORY_SETTINGS = 'materialCategoriesEpic/GET_MATERIAL_CATEGORY_SETTINGS';
export const GET_MATERIAL_CATEGORY_SETTINGS_SUCCESS = 'materialCategoriesEpic/GET_MATERIAL_CATEGORY_SETTINGS_SUCCESS';
export const GET_MATERIAL_CATEGORY_SETTINGS_ERROR = 'materialCategoriesEpic/GET_MATERIAL_CATEGORY_SETTINGS_ERROR';

export const RESET_MATERIAL_CATEGORY = 'materialCategoriesEpic/RESET_MATERIAL_CATEGORY';
