import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import Button from 'components/CustomButtons/Button.jsx';
import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CustomDateRangePickerDropdown from "components/CustomDateRangePickerDropdown";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import IconButton from '@material-ui/core/IconButton';
import CloseButton from 'assets/img/buttons/delete.svg';
import EditButton from 'assets/img/buttons/edit.svg';
import MaterialArrowUp from 'assets/img/material_arrow_up.svg';
import MaterialArrowDown from 'assets/img/material_arrow_down.svg';

import ManualTimer from '../ManualTimer';
import RemoveDialog from "views/RemoveDialog";

import './style.scss';

import {
  getCoworkerTimeEntry,
  deleteCoworkerTimeEntry,
  filterCoworkerTimeSheets,
  resetLastFinishedEdit,
} from 'store/actions/coworkerTimeSheet';

import {
  getPositionsList,
  getPositionCostCentersList,
  getPositionCostCenterItemsList,
  getCostCentersList,
  getCostCenterItemsList,
} from 'store/actions/timeSheetsProviders';

import Loader from "components/Loader/Loader";
import CustomPagination from "components/CustomPagination";
import { isCurrentUserManufacturer } from 'utils/utils';

class ProjectTimesheets extends React.Component {

  static SortByList = [{ id: 0, data: 'entry_date', value: 'sort_by_date' }];

  constructor(props) {
    super(props);
    this.state = {
      isTimerOpen: false,
      isManualTimerOpen: false,
      isRemoveDialogOpen: false,
      deleteEntryId: null,
      filters: {
        page: '1',
        entry_type: '',
        order_id: '',
        position: '',
        position_cost_center: '',
        position_cost_center_item: '',
        cost_center: '',
        cost_center_item: '',
        coworker: '',
        dateFrom: props.timePeriodStart,
        dateTo: props.timePeriodEnd,
      },
      sortBy: 0,
      sortByOrder: true,
      editing: null,
    };
  }

  componentDidMount() {
    this.reloadDataList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.editing && this.state.editing.id === this.props.lastFinishedEditId && !this.props.editError) {
      // if editing finished, close the dialog
      this.closeManualTimer();
      this.props.resetLastFinishedEdit();
    }

    const stateUpdateObj = {};
    if (this.props.timePeriodStart && prevProps.timePeriodStart != this.props.timePeriodStart) {
      if(!stateUpdateObj['filters']) {
        stateUpdateObj['filters'] = { ...this.state.filters };
      }
      stateUpdateObj['filters']['dateFrom'] = this.props.timePeriodStart;
    }
    if (this.props.timePeriodEnd && prevProps.timePeriodEnd != this.props.timePeriodEnd) {
      if(!stateUpdateObj['filters']) {
        stateUpdateObj['filters'] = { ...this.state.filters };
      }
      stateUpdateObj['filters']['dateTo'] = this.props.timePeriodEnd;
    }
    if (Object.keys(stateUpdateObj).length > 0) {
      this.setState(stateUpdateObj, () => {
        this.reloadDataList();
      });
    }
  }

  reloadDataList = () => {
    const { filterCoworkerTimeSheets, searchTerms } = this.props;
    const { filters, sortBy, sortByOrder } = this.state;
    filterCoworkerTimeSheets(filters, ProjectTimesheets.SortByList[sortBy].data, sortByOrder, searchTerms);
  }

  isTimeSheetsDataReady = () => {
    const {
      timeSheetBusy,
    } = this.props;
    return !timeSheetBusy;
  };

  handleFiltersChange = (fieldName, value) => {
    const { filters } = this.state;
    if (filters[fieldName] !== value) {
      const newFilters = { ...filters };
      newFilters[fieldName] = value;
      if (fieldName == "entry_type") {
        newFilters["order_id"] = '';
        newFilters["position"] = '';
        newFilters["position_cost_center"] = '';
        newFilters["position_cost_center_item"] = '';
        newFilters["cost_center"] = '';
        newFilters["cost_center_item"] = '';
      } else if (fieldName == "order_id") {
        newFilters["position"] = '';
        newFilters["position_cost_center"] = '';
        newFilters["position_cost_center_item"] = '';
      } else if (fieldName == "position") {
        newFilters["position_cost_center"] = '';
        newFilters["position_cost_center_item"] = '';
      } else if (fieldName == "cost_center") {
        newFilters["cost_center_item"] = '';
      }
      this.setState({
        filters: newFilters,
      }, () => {
        this.reloadDataList();
      });
    }
  };

  handleSortChange = (id) => {
    this.setState({
      sortBy: id,
    }, () => {
      this.reloadDataList();
    });
  }

  handleSortFlipOrder = () => {
    this.setState({
      sortByOrder: !this.state.sortByOrder,
    }, () => {
      this.reloadDataList();
    });
  };

  handleCoworkerFilterChange = (id) => {
    this.handleFiltersChange("coworker", id != -1 ? id : '');
  }

  handleDateFilterChange = (fieldName, value) => {
    const { timePeriodStart, timePeriodEnd } = this.props;
    let date = null;
    if (value) {
      date = moment(value).format('YYYY-MM-DD'); 
    } else {
      if (fieldName == "dateFrom") {
        date = timePeriodStart;
      } else {
        date = timePeriodEnd;
      }
    }
    this.handleFiltersChange(fieldName, date);
  }

  handleEntryTypeDropdownChange = id => {
    this.handleFiltersChange("entry_type", id != -1 ? id : '');
  };

  handleOrderDropdownChange = id => {
    if (id != -1)
      this.props.getPositionsList(id);
    this.handleFiltersChange("order_id", id != -1 ? id : '');
  };

  handlePositionDropdownChange = id => {
    if (id != -1)
      this.props.getPositionCostCentersList(id);
    this.handleFiltersChange("position", id != -1 ? id : '');
  };

  handlePositionCostCentersDropdownChange = id => {
    if (id != -1)
      this.props.getPositionCostCenterItemsList(id);
    this.handleFiltersChange("position_cost_center", id != -1 ? id : '');
  }

  handlePositionCostCenterItemsDropdownChange = id => {
    this.handleFiltersChange("position_cost_center_item", id != -1 ? id : '');
  }

  handleCostCentersDropdownChange = id => {
    if (id != -1)
      this.props.getCostCenterItemsList(id);
    this.handleFiltersChange("cost_center", id != -1 ? id : '');
  }

  handleCostCenterItemsDropdownChange = id => {
    this.handleFiltersChange("cost_center_item", id != -1 ? id : '');
  }

  handlePageClick = data => {
    const { selected } = data;
    this.handleFiltersChange('page', selected + 1);
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    if (wrapper)
      wrapper.scrollTop = 0;
  };

  handleLogTime = () => {
    this.setState({
      isManualTimerOpen: !this.state.isManualTimerOpen,
      editing: null,
    });
  };

  closeManualTimer = () => {
    this.setState({
      isManualTimerOpen: false,
      editing: null,
    });
  }
  
  handleDeleteEntryClick = (entry) => {
    this.setState({
      isRemoveDialogOpen: !this.state.isRemoveDialogOpen,
      deleteEntryId: entry.id,
    });
  }

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveDialogOpen: !this.state.isRemoveDialogOpen,
      deleteEntryId: null,
    });
  }

  handleDeleteEntry = () => {
    const { searchTerms } = this.props;
    const { deleteEntryId, filters, sortBy, sortByOrder } = this.state;
    this.props.deleteCoworkerTimeEntry(deleteEntryId, filters, ProjectTimesheets.SortByList[sortBy].data, sortByOrder, searchTerms);
    this.handleRemoveDialogClose();
  }

  handleEditEntryClick = (index, entry, positionId) => {
    let type;
    if (entry.loggable_type == "App\\Models\\PositionCostCenter") {
      type = 0;
    } else if (entry.loggable_type == "App\\Models\\PositionCostCenterItem") {
      type = 1;
    } else if (entry.loggable_type == "App\\Models\\CostCenter") {
      type = 2;
    } else if (entry.loggable_type == "App\\Models\\CostCenterItem") {
      type = 3;
    }
    const data = { ...entry, position_id: positionId, type };
    this.setState({
      isManualTimerOpen: !this.state.isManualTimerOpen,
      editing: data,
    });
  }

  shouldDisableDateFromPicking = (date) => {
    const { timePeriodStart, timePeriodEnd } = this.props;
    return moment(date).isBefore(timePeriodStart) || moment(date).isAfter(timePeriodEnd);
  }

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return text;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  renderTimeSheet = (timeSheet, index) => {
    const { coworkers } = this.props;
    
    const timeFromHmi = time => {
      const parts = time.split(":");
      const decimal = parseInt(parts[0], 10) + parseInt(parts[1], 10) / 60 + parseInt(parts[2], 10) / 3600;
      return decimal.toFixed(1);
    };

    const getOrderColValue = () => {
      if (timeSheet.loggable_type === "App\\Models\\PositionCostCenter" || timeSheet.loggable_type == "App\\Models\\PositionCostCenterItem") {
        if (timeSheet.loggable_meta && timeSheet.loggable_meta.order_name) {
          return this.wrapText(timeSheet.loggable_meta.order_name, 26);
        } else {
          return 'N/A';
        }
      }
      return 'N/A';
    }, getPositionColValue = () => {
      if (timeSheet.loggable_type === "App\\Models\\PositionCostCenter" || timeSheet.loggable_type == "App\\Models\\PositionCostCenterItem") {
        if (timeSheet.loggable_meta && timeSheet.loggable_meta.position_name) {
          return this.wrapText(timeSheet.loggable_meta.position_name, 26);
        } else {
          return 'N/A';
        }
      }
      return 'N/A';
    }, getCostCenterColValue = () => {
      let attributeName = '';
      if (timeSheet.loggable_type === "App\\Models\\PositionCostCenter" || timeSheet.loggable_type === "App\\Models\\PositionCostCenterItem") {
        attributeName = 'position_cost_center_name';
      } else if (timeSheet.loggable_type === "App\\Models\\CostCenter" || timeSheet.loggable_type === "App\\Models\\CostCenterItem") {
        attributeName = 'cost_center_name';
      }
      if (timeSheet.loggable_meta && timeSheet.loggable_meta[attributeName]) {
        return this.wrapText(timeSheet.loggable_meta[attributeName], 26);
      } else {
        return 'N/A';
      }
    }, getCostCenterItemColValue = () => {
      let attributeName = '';
      if (timeSheet.loggable_type === "App\\Models\\PositionCostCenterItem") {
        attributeName = 'position_cost_center_item_name';
      } else if (timeSheet.loggable_type === "App\\Models\\CostCenterItem") {
        attributeName = 'cost_center_item_name';
      } 
      if (attributeName && timeSheet.loggable_meta && timeSheet.loggable_meta[attributeName]) {
        return this.wrapText(timeSheet.loggable_meta[attributeName], 26);
      } else {
        return 'N/A';
      }
    };

    const coworker = coworkers.find(item => item.id === timeSheet.coworker_id.toString());
    return (
      <GridContainer
        className={`timesheets-item ${index % 2 === 0 ? 'dark' : ''}`}
        direction="row"
        key={index}
      >
        <GridItem className="timesheets-item-title ts-col-date">
          {timeSheet.entry_date}
        </GridItem>
        <GridItem className='timesheets-item-title ts-col-order bold'>
          { getOrderColValue() }
        </GridItem>
        <GridItem className="timesheets-item-title ts-col-position">
          { getPositionColValue() }
        </GridItem>
        <GridItem className="timesheets-item-title ts-col-cost-center">
          { getCostCenterColValue() }
        </GridItem>
        <GridItem className="timesheets-item-title ts-col-cost-center-item">
          { getCostCenterItemColValue() }
        </GridItem>
        <GridItem className="timesheets-item-title bold blue ts-col-coworker">
          { this.wrapText(coworker.value, 16) }
        </GridItem>
        <GridItem className="timesheets-item-title ts-col-description">
          {this.wrapText(timeSheet.description, 24)}
        </GridItem>
        <GridItem className="timesheets-item-title bold right-aligned ts-col-time-spent">
          { timeFromHmi(timeSheet.time_spent) }
        </GridItem>
        <GridItem className="timesheets-item-title time-sheet-action-btn right-aligned ts-col-action-btn">
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleDeleteEntryClick(timeSheet)}>
            <img alt="time-sheet-delete-btn" src={CloseButton} />
          </IconButton>
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleEditEntryClick(index, timeSheet, timeSheet.loggable_meta && timeSheet.loggable_meta.position_id ? timeSheet.loggable_meta.position_id : null)}>
            <img alt="time-sheet-edit-btn" src={EditButton} />
          </IconButton>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const {
      timeSheets,
      metaData,
      coworkers,
      ordersList,
      positionsList,
      positionCostCentersList,
      positionCostCenterItemsList,
      costCentersList,
      costCenterItemsList,
      t,
    } = this.props;
    const { isRemoveDialogOpen, isManualTimerOpen, filters, sortBy, sortByOrder, editing } = this.state;
    const entryTypesActual = [{id: -1, value: t('all_types')}, {id: 0, value: t('operational')}, {id: 1, value: t('non_operational')}];
    const ordersListPre = [...ordersList];
    ordersListPre.sort((el1, el2) => {
      if (el1.id === el2.id) {
        return 0;
      } else if (el1.id <= el2.id) {
        return 1;
      } else {
        return -1;
      }
    });
    const ordersListActual = [{id: -1, value: t('all_orders')}, ...ordersListPre];
    const positionsListPre = positionsList[filters.order_id] ? positionsList[filters.order_id] : [];
    const positionsListActual = [{id: -1, value: t('all_positions')}, ...positionsListPre];
    const positionCostCentersListActual = [{id: -1, value: t('all_cost_centers')}, ...positionCostCentersList];
    const positionCostCenterItemsListPre = positionCostCenterItemsList ? positionCostCenterItemsList : [];
    const positionCostCenterItemsListActual = [{id: -1, value: t('all_cost_center_items')}, ...positionCostCenterItemsListPre];
    const costCentersListActual = [{id: -1, value: t('all_cost_centers')}, ...costCentersList];
    const costCenterItemsListPre = costCenterItemsList ? costCenterItemsList : [];
    const costCenterItemsListActual = [{id: -1, value: t('all_cost_center_items')}, ...costCenterItemsListPre];
    const coworkersListActual = [{id: -1, value: t('all_employees')}, ...coworkers];
    const sortByList = ProjectTimesheets.SortByList.map(el => ({
      ...el,
      value: t(el.value),
    }));
    return (
      <GridContainer className="project-timesheets-container">
        <GridItem className="project-timesheets-top" xs={12}>
          <GridItem xs={3}>

          </GridItem>
          <GridItem className="timesheets-top-right-bar">
            <CustomDateRangePickerDropdown
              dateFrom={filters.dateFrom}
              dateTo={filters.dateTo}
              dateFromPlaceholder={t('from') + "..."}
              dateToPlaceholder={t('up_to') + "..."}
              buttonPlaceholder={t('filter_dates') + "..."}
              buttonProps={{
                className: 'dropdown-blue-style',
                color: 'transparent',
                round: true,
                size: 'sm'
              }}
              shouldDisableDateFrom={this.shouldDisableDateFromPicking}
              shouldDisableDateTo={this.shouldDisableDateFromPicking}
              hoverColor="rose"
              onChange={(code, date) => this.handleDateFilterChange(code == 0 ? 'dateFrom' : 'dateTo', date)}
            />
            { isCurrentUserManufacturer() ? 
              <Dropdown
                buttonText={filters.coworker ? filters.coworker : -1}
                buttonProps={{
                  className: 'dropdown-blue-style',
                  round: true,
                  size: 'sm'
                }}
                dropdownList={coworkersListActual}
                onClick={data => this.handleCoworkerFilterChange(data.id)}
              />
              : null
            }
            <Dropdown
              buttonText={filters.entry_type !== null && filters.entry_type !== undefined && filters.entry_type !== '' ? filters.entry_type : -1}
              buttonProps={{
                className: 'dropdown-blue-style timer-dropdown',
                round: true,
                size: 'sm'
              }}
              dropdownList={entryTypesActual}
              onClick={value => this.handleEntryTypeDropdownChange(value.id)}
            />
            { filters.entry_type === 0 &&
              <>
                <Dropdown
                  buttonText={filters.order_id ? filters.order_id : -1}
                  buttonProps={{
                    className: 'dropdown-blue-style timer-dropdown',
                    round: true,
                    size: 'sm'
                  }}
                  dropdownList={ordersListActual}
                  onClick={value => this.handleOrderDropdownChange(value.id)}
                />
                <Dropdown
                  buttonText={filters.position ? filters.position : -1}
                  buttonProps={{
                    className: 'dropdown-blue-style timer-dropdown',
                    round: true,
                    size: 'sm',
                    disabled: !filters.order_id,
                  }}
                  dropdownList={positionsListActual}
                  onClick={value => this.handlePositionDropdownChange(value.id)}
                />
                <Dropdown
                  buttonText={filters.position_cost_center ? filters.position_cost_center : -1}
                  buttonProps={{
                    className: 'dropdown-blue-style timer-dropdown',
                    round: true,
                    size: 'sm',
                    disabled: !filters.order_id || !filters.position,
                  }}
                  dropdownList={positionCostCentersListActual}
                  onClick={value => this.handlePositionCostCentersDropdownChange(value.id)}
                />
                <Dropdown
                  buttonText={filters.position_cost_center_item ? filters.position_cost_center_item : -1}
                  buttonProps={{
                    className: 'dropdown-blue-style timer-dropdown',
                    round: true,
                    size: 'sm',
                    disabled: !filters.order_id || !filters.position || !filters.position_cost_center,
                  }}
                  dropdownList={positionCostCenterItemsListActual}
                  onClick={value => this.handlePositionCostCenterItemsDropdownChange(value.id)}
                />
              </>
            }
            { filters.entry_type === 1 &&
              <>
                <Dropdown
                  buttonText={filters.cost_center ? filters.cost_center : -1}
                  buttonProps={{
                    className: 'dropdown-blue-style timer-dropdown',
                    round: true,
                    size: 'sm',
                    disabled: costCentersList.length === 0,
                  }}
                  dropdownList={costCentersListActual}
                  onClick={value => this.handleCostCentersDropdownChange(value.id)}
                />
                <Dropdown
                  buttonText={filters.cost_center_item ? filters.cost_center_item : -1}
                  buttonProps={{
                    className: 'dropdown-blue-style timer-dropdown',
                    round: true,
                    size: 'sm',
                    disabled: !filters.cost_center,
                  }}
                  dropdownList={costCenterItemsListActual}
                  onClick={value => this.handleCostCenterItemsDropdownChange(value.id)}
                />
              </>
            }
            <Dropdown
              buttonText={sortBy}
              buttonProps={{
                className: 'dropdown-blue-style',
                round: true,
                size: 'sm'
              }}
              dropdownList={sortByList}
              onClick={data => this.handleSortChange(data.id)}
            />
            <Button
              className="add-time-btn"
              onClick={this.handleLogTime}
            >
              + { t('log_time').toUpperCase() }
            </Button>
          </GridItem>
        </GridItem>
        <GridContainer
          className="timesheets-header"
          direction="row"
        >
          <GridItem className={`timesheets-header-title ts-col-date${sortBy == 0 ? ' col-sort-active' : ''}`}>
            <span onClick={sortBy == 0 ? this.handleSortFlipOrder : null}>
              { t('date') } { sortBy == 0 && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
            </span>
          </GridItem>
          <GridItem className="timesheets-header-title ts-col-order">
            { t('order') }
          </GridItem>
          <GridItem className="timesheets-header-title ts-col-position">
            { t('position') }
          </GridItem>
          <GridItem className="timesheets-header-title ts-col-cost-center">
            { t('cost_center') }
          </GridItem>
          <GridItem className="timesheets-header-title ts-col-cost-center-item">
            { t('cost_center_item') }
          </GridItem>
          <GridItem className="timesheets-header-title ts-col-coworker">
            { t('employee') }
          </GridItem>
          <GridItem className="timesheets-header-title ts-col-description">
            { t('note') }
          </GridItem>
          <GridItem className="timesheets-header-title right-aligned ts-col-time-spent">
            { t('time') }
          </GridItem>
          <GridItem className="timesheets-header-title right-aligned ts-col-action-btn">
            { t('actions') }
          </GridItem>
        </GridContainer>
        { this.isTimeSheetsDataReady() ? (
          <>
            <GridContainer className="timesheets-content">
            { timeSheets && timeSheets.length !== 0 ?
                timeSheets.map((timeSheet, index) =>
                  this.renderTimeSheet(timeSheet, index)
                ) :
                <div className='timesheets-content-no-records'>
                  <span className='no-records'>{ t('no_records_found') }</span>
                </div>
            }
            </GridContainer>
            <GridContainer className="pagination-container">
              {
                metaData.last_page > 1 &&
                <CustomPagination
                  pageCount={metaData.last_page}
                  handlePageClick={this.handlePageClick}
                />
              }
            </GridContainer>
          </>
          ) : (
            <div className="ikt-ap_loader">
              <Loader />
            </div>
          )
        }
        { isManualTimerOpen &&
          <ManualTimer
            isOpen={isManualTimerOpen}
            currentFilters={this.state.filters}
            currentSort={ProjectTimesheets.SortByList[this.state.sortBy].data}
            currentSortOrder={this.state.sortByOrder}
            currentSearchTerms={this.props.searchTerms}
            onClose={this.closeManualTimer}
            edit={editing}
          />
        }
        {isRemoveDialogOpen && (
          <RemoveDialog
            isOpen={isRemoveDialogOpen}
            title={ t('remove_timesheet_entry') }
            text={ t('remove_timesheet_entry_confirmation') }
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleDeleteEntry}
          />
        )}
      </GridContainer>
    );
  }
}

const mapStateToProp = state => {
  return {
    timeSheets: state.timeSheets.timeEntries,
    timeSheetBusy: state.timeSheets.timeSheetBusy,
    metaData: state.timeSheets.timeEntriesMetaData,
    coworkers: state.providers.coworkersList,
    ordersList: state.timeSheetsProviders.ordersList,
    positionsList: state.timeSheetsProviders.positionsList,
    positionCostCentersList: state.timeSheetsProviders.positionCostCentersList,
    positionCostCenterItemsList: state.timeSheetsProviders.positionCostCenterItemsList,
    costCentersList: state.timeSheetsProviders.costCentersList,
    costCenterItemsList: state.timeSheetsProviders.costCentersItemsList,
    editError: state.timeSheets.editError,
    lastFinishedEditId: state.timeSheets.lastFinishedEditId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCoworkerTimeEntry: id => dispatch(getCoworkerTimeEntry(id)),
    deleteCoworkerTimeEntry: (id, filters, sortBy, sortByOrder, searchTerms) => dispatch(deleteCoworkerTimeEntry(id, filters, sortBy, sortByOrder, searchTerms)),
    filterCoworkerTimeSheets: (data, sortBy, sortByOrder, searchTerms) => dispatch(filterCoworkerTimeSheets(data, sortBy, sortByOrder, searchTerms)),
    getPositionsList: id => dispatch(getPositionsList(id)),
    getPositionCostCentersList: id => dispatch(getPositionCostCentersList(id)),
    getPositionCostCenterItemsList: id => dispatch(getPositionCostCenterItemsList(id)),
    getCostCentersList: () => dispatch(getCostCentersList()),
    getCostCenterItemsList: id => dispatch(getCostCenterItemsList(id)),
    resetLastFinishedEdit: () => dispatch(resetLastFinishedEdit()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ProjectTimesheets));
