import React from 'react';
import ReactPaginate from 'react-paginate';

import './style.scss';

function CustomPagination({ ...props }) {
  const { pageCount, handlePageClick, forcePage } = props;
  const forcePageSanitized = forcePage ? forcePage : null;
  return (
    <ReactPaginate
      previousLabel={'PREV'}
      nextLabel={'NEXT'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pageCount}
      marginPagesDisplayed={3}
      pageRangeDisplayed={2}
      onPageChange={handlePageClick}
      containerClassName={'custom-page-ination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
      forcePage={forcePageSanitized}
    />
  );
}

export default CustomPagination;
