import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, mergeMap, catchError} from 'rxjs/operators';
import {manufacturerAvailabilityAPI} from 'services/API';
import {
  updateManufacturerAvailabilitySuccess,
  updateManufacturerAvailabilityFailure,
  deleteManufacturerAvailabilitySuccess,
  deleteManufacturerAvailabilityFailure
} from '../store/actions/manufacturerAvailability';
import {
  UPDATE_MANUFACTURER_AVAILABILITY,
  DELETE_MANUFACTURER_AVAILABILITY
} from '../store/actionTypes/manufacturerAvailabilityActionTypes';

export const updateAvailabilityEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_MANUFACTURER_AVAILABILITY),
  mergeMap((action) => from(manufacturerAvailabilityAPI.updateDateAvailability(action.payload.id, action.payload.data)).pipe(
    switchMap((response) => {
      return of(updateManufacturerAvailabilitySuccess(response))
    }),
    catchError( err => {
        return of(updateManufacturerAvailabilityFailure(err))
      }
    )
  ))
);

export const removeAvailabilityEpic = (actions$) => actions$.pipe(
  ofType(DELETE_MANUFACTURER_AVAILABILITY),
  switchMap(({payload}) => from(manufacturerAvailabilityAPI.deleteDateAvailability(payload.id)).pipe(
    switchMap((response) => {
      return of(deleteManufacturerAvailabilitySuccess(response))
    }),
    catchError( err => {
        return of(deleteManufacturerAvailabilityFailure(err))
      }
    )
  ))
);
