import React from 'react';
import { connect } from 'react-redux';
import { updateOrderSettings } from 'store/actions/orders';

import GridItem from 'components/Grid/GridItem';

import './style.scss';
import Input from '../../components/Input/Input';
import { formatNumericValue } from 'utils/utils';

class CostCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      material_profit: 0,
      discount: 0,
      vat: 0,
      special_sales: 0
    };
  }

  roundFloat = (num) => {
    return num === NaN ? 0 : parseFloat(num.toFixed(6));  // six decimal places ought to suffice
  };

  updateStateFromOrderSettings = () => {
    const { orderSettings } = this.props;
    if (orderSettings.length !== 0) {
      const material_profit = orderSettings.find(item => item.key === 'material_profit');
      const discount = orderSettings.find(item => item.key === 'discount');
      const vat = orderSettings.find(item => item.key === 'vat');
      const special_sales = orderSettings.find(item => item.key === 'special_sales');
      this.setState({
        material_profit: this.roundFloat(Number(material_profit.value)),
        discount: this.roundFloat(Number(discount.value)),
        vat: this.roundFloat(Number(vat.value)),
        special_sales: this.roundFloat(Number(special_sales.value))
      });
    }
  }

  componentDidMount() {
    this.updateStateFromOrderSettings();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.orderSettings !== this.props.orderSettings && this.props.orderSettings) {
      this.updateStateFromOrderSettings();
    }
  }

  handleSettingsUpdateChange = (name, value) => {
    const { order, updateSetting } = this.props;
    updateSetting(order.id, { name, value: this.roundFloat(Number(value)) });
    this.setState({
      [name]: value
    });
  };

  render() {
    const {
      order,
      orderTotalCost,
      currencySymbol,
      canEdit,
    } = this.props;
    const {
      material_profit,
      discount,
      vat,
      special_sales
    } = this.state;
    const materialCost = orderTotalCost.material_total_cost ? orderTotalCost.material_total_cost : 0;
    const productionCost = orderTotalCost.production_cost ? orderTotalCost.production_cost : 0;
    const primeCost = orderTotalCost.sub_total ? orderTotalCost.sub_total : 0;
    const riskAndProfit = orderTotalCost.risk_and_profit ? orderTotalCost.risk_and_profit : 0;
    const specialSales = orderTotalCost.special_sales ? orderTotalCost.special_sales : 0;
    const nettoPrice = orderTotalCost.net_price ? orderTotalCost.net_price : 0;
    const costVat = orderTotalCost.pre_discount_vat ? orderTotalCost.pre_discount_vat : 0;
    const bruttoPrice = orderTotalCost.pre_gross_price ? orderTotalCost.pre_gross_price : 0;
    const costDiscount = orderTotalCost.discount ? orderTotalCost.discount : 0;
    const netRetailPrice = orderTotalCost.net_retail_price ? orderTotalCost.net_retail_price : 0;
    const retailPrice = orderTotalCost.retail_price ? orderTotalCost.retail_price : 0;
    const vat_19 = orderTotalCost.post_discount_vat ? orderTotalCost.post_discount_vat : 0;
    const finalRetailPrice = orderTotalCost.final_price ? orderTotalCost.final_price : 0;
    return (
      <div className="order-inquiry-details-cost-card">
        <GridItem className="card-title" xs={12}>
          <span>{order.type === 0 ? 'Order Cost' : 'Inquiry Cost'}</span>
        </GridItem>
        <GridItem className='card-content'>
          <div className="card-data-field with-border">
            <div className="card-data-item item-title">
              <GridItem xs={6}>Items</GridItem>
              <GridItem xs={3} className="right-aligned">Cost</GridItem>
              <GridItem xs={3} className="right-aligned">Ratio</GridItem>
            </div>
            <div className="card-data-item">
              <GridItem xs={6} className="card-data-title">Material Cost</GridItem>
              <GridItem xs={3} className="right-aligned">
                {`${formatNumericValue(parseFloat(materialCost), 2)}${currencySymbol}`}
              </GridItem>
              <GridItem xs={3}
                        className="right-aligned">{primeCost !== 0 ? (materialCost / primeCost * 100).toFixed(2) : 0}%</GridItem>
            </div>
            <div className="card-data-item">
              <GridItem xs={6} className="card-data-title">Production Cost</GridItem>
              <GridItem xs={3} className="right-aligned">
                {`${formatNumericValue(parseFloat(productionCost), 2)}${currencySymbol}`}
              </GridItem>
              <GridItem xs={3}
                        className="right-aligned">{primeCost !== 0 ? (productionCost / primeCost * 100).toFixed(2) : 0}%</GridItem>
            </div>
          </div>
          <div className="card-data-field-total">
            <GridItem xs={5}/>
            <GridItem xs={4} className="right-aligned">Prime Cost</GridItem>
            <GridItem xs={3} className="right-aligned total-value">
              {`${formatNumericValue(parseFloat(primeCost), 2)}${currencySymbol}`}
            </GridItem>
          </div>
          <div className="card-data-field with-border">
            <div className="card-data-item item-title">
              <GridItem xs={6}>Item</GridItem>
              <GridItem xs={3}/>
              <GridItem xs={3} className="right-aligned">Total</GridItem>
            </div>
            <div className="card-data-item">
              <GridItem xs={6} className="card-data-title">Risk and Profit</GridItem>
              <GridItem xs={3}>
                <Input
                  value={material_profit}
                  onChange={e => this.handleSettingsUpdateChange('material_profit', e.target.value)}
                  readOnly={!canEdit}
                  error={false}
                  errorMsg={''}
                />
              </GridItem>
              <GridItem xs={3} className="right-aligned">{`${formatNumericValue(parseFloat(riskAndProfit), 2)}${currencySymbol}`}</GridItem>
            </div>
            <div className="card-data-item">
              <GridItem xs={6} className="card-data-title">Special sales</GridItem>
              <GridItem xs={3}>
                <Input
                  value={special_sales}
                  onChange={e => this.handleSettingsUpdateChange('special_sales', e.target.value)}
                  readOnly={!canEdit}
                  error={false}
                  errorMsg={''}
                />
              </GridItem>
              <GridItem xs={3} className="right-aligned">{`${formatNumericValue(parseFloat(specialSales), 2)}${currencySymbol}`}</GridItem>
            </div>
            <div className="card-data-field-total">
              <GridItem xs={5}/>
              <GridItem xs={4} className="right-aligned">Netto Price</GridItem>
              <GridItem xs={3} className="right-aligned total-value">
                {`${formatNumericValue(parseFloat(nettoPrice), 2)}${currencySymbol}`}
              </GridItem>
            </div>
          </div>
          <div className="card-data-field with-border">
            <div className="card-data-item item-title">
              <GridItem xs={6}>VAT</GridItem>
              <GridItem xs={3}>
                <Input
                  value={vat}
                  onChange={e => this.handleSettingsUpdateChange('vat', e.target.value)}
                  readOnly={!canEdit}
                  error={false}
                  errorMsg={''}
                />
              </GridItem>
              <GridItem xs={3} className="right-aligned">{`${formatNumericValue(parseFloat(costVat), 2)}${currencySymbol}`}</GridItem>
            </div>
          </div>
          <div className="card-data-field with-border">
            <div className="card-data-field-total">
              <GridItem xs={5}/>
              <GridItem xs={4} className="right-aligned">Brutto Price</GridItem>
              <GridItem xs={3} className="right-aligned total-value">
                {`${formatNumericValue(parseFloat(bruttoPrice), 2)}${currencySymbol}`}
              </GridItem>
            </div>
          </div>
          <div className="card-data-field">
            <div className="card-data-item item-title">
              <GridItem xs={6}>Discount</GridItem>
              <GridItem xs={3}>
                <Input
                  value={discount}
                  onChange={e => this.handleSettingsUpdateChange('discount', e.target.value)}
                  readOnly={!canEdit}
                  error={false}
                  errorMsg={''}
                />
              </GridItem>
              <GridItem xs={3} className="right-aligned">
                {`${formatNumericValue(parseFloat(costDiscount), 2)}${currencySymbol}`}
              </GridItem>
            </div>
          </div>
          <div className="card-data-field">
            <div className="card-data-item item-title total-prices">
              <GridItem xs={9}>Retail Price</GridItem>
              <GridItem xs={3} className="right-aligned total-price semi-bold">
                {`${formatNumericValue(parseFloat(retailPrice), 2)}${currencySymbol}`}
              </GridItem>
            </div>
            <div className="card-data-item total-prices">
              <GridItem xs={9} className="card-data-title">Neto Retail Price after discount</GridItem>
              <GridItem xs={3} className="right-aligned total-price">{formatNumericValue(parseFloat(netRetailPrice), 2)} {currencySymbol}</GridItem>
            </div>
            <div className="card-data-item total-prices">
              <GridItem xs={9} className="card-data-title">VAT {vat}%</GridItem>
              <GridItem xs={3} className="right-aligned total-price">
                {`${formatNumericValue(parseFloat(vat_19), 2)}${currencySymbol}`}
              </GridItem>
            </div>
            <div className="card-data-item item-title">
              <GridItem xs={9}>Final Retail Price</GridItem>
              <GridItem xs={3} className="right-aligned total-price semi-bold">
                {`${formatNumericValue(parseFloat(finalRetailPrice), 2)}${currencySymbol}`}
              </GridItem>
            </div>
          </div>
        </GridItem>
      </div>

    );
  }
}

const mapStateToProp = state => {
  return {
    order: state.orders.currentOrder,
    orderSettings: state.orders.orderSettings,
    orderTotalCost: state.orders.orderTotalCost,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSetting: (id, data) => dispatch(updateOrderSettings(id, data))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(CostCard);
