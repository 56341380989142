import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Checkbox from "../../components/StyledCheckbox";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ErrorTooltip, ErrorTooltipAlt } from '../../components/ErrorTooltip/ErrorTooltip';
import AppsGridIcon from '../../assets/img/inputIcons/apps-grid.svg';

import './style.scss';

import Button from '../../components/CustomButtons/Button';
import Input from "../../components/Input/Input";
import DecimalInput from 'components/DecimalInput'
import InputAdornment from '@material-ui/core/InputAdornment';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import Tags from "../../components/Tags";
import CustomTags from "../../components/CustomTags";
import UploadImage from "../../components/UploadImg";

import CloseButton from "../../assets/img/buttons/delete.svg";

import { addMaterialRequest, updateMaterialRequest, resetMaterialData } from "../../store/actions/materials";
import { getMaterialCategorySettings } from "../../store/actions/materialCategories";
import { getSuppliersList } from "../../store/actions/providers";

import { convertToFormData, calculateMaterialRetailPrice, formatNumericValue } from "../../utils/utils";
import InputWithIcon from "components/InputWithIcon";
import CloseDialog from '../CloseDialog';

class CreateMaterial extends React.Component {
  static defaultState = {
    name: '',
    sku: '',
    category_id: null,
    thickness: '',
    unit: 1,
    purchasingPrice: '',
    discount: '',
    surcharges: '',
    waste: '',
    in_stock: 0,
    description: '',
    suppliers: [],
    brands: [],
    image: null,
    is_edge: false,
    logo_removed: false,

    lastEdit: null,

    lastProcessedErrors: null,
    errors: {
      name: null,
      sku: null,
      category_id: null,
      thickness: null,
      purchasingPrice: null,
      discount: null,
      surcharges: null,
      waste: null,
      logo: null
    },

    isCloseDialogOpen: false,
    haveEdits: false,
  };

  constructor(props) {
    super(props);

    this.state = CreateMaterial.defaultState;
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};
    if (props.requestErrors && props.requestErrors != state.lastProcessedErrors) {
      const errorsString = props.requestErrors;
      const needle = "Response body: ";
      const position = errorsString.indexOf(needle);
      if (position != -1) {
        const substring = errorsString.substring(position + needle.length);
        try {
          const errorsObj = JSON.parse(substring);
          if(errorsObj.errors) {
            const errors = {
              name: errorsObj.errors.name,
              sku: errorsObj.errors.code,
              category_id: errorsObj.errors.category_id,
              thickness: errorsObj.errors.thickness,
              purchasingPrice: errorsObj.errors.default_price,
              discount: errorsObj.errors['material_meta.discount_percentage'],
              surcharges: errorsObj.errors['material_meta.surcharge_percentage'],
              waste: errorsObj.errors['material_meta.waste_percentage'],
              logo: errorsObj.errors.logo
            };
            newState = {
              ...newState,
              errors,
              lastProcessedErrors: props.requestErrors,
            };
          }
        } catch(e) {
        }
      }
    }

    if (props.edit != state.lastEdit) {
      if (props.edit) {
        const material = props.edit;
        const newSuppliers = [];
        material.suppliers && material.suppliers.map(item => newSuppliers.push({ id: item.id.toString(), value: `${item.first_name} ${item.last_name}` }));
        newState = {
          ...newState,
          name: material.name,
          sku: material.code,
          category_id: material.category && material.category.id ? material.category.id : null,
          thickness: material.thickness ? material.thickness.toString() : '',
          unit: material.metric,
          purchasingPrice: (material.material_price && material.material_price[0] ? material.material_price[0].price : material.default_price).toString(),
          discount: material.discount_percentage ? material.discount_percentage.toString() : '',
          surcharges: material.surcharge_percentage ? material.surcharge_percentage.toString() : '',
          waste: material.waste_percentage ? material.waste_percentage.toString() : '',
          in_stock: material.in_stock == 1 ? 0 : 1,
          description: material.description ? material.description : "",
          suppliers: newSuppliers,
          brands: material.brands ? material.brands : [],
          image: material.logo,
          is_edge: material.is_edge_material == 1,
        };
      } else {
        newState = {
          ...newState,
          name: '',
          sku: '',
          category_id: null,
          thickness: '',
          unit: 0,
          purchasingPrice: '',
          discount: '',
          surcharges: '',
          waste: '',
          in_stock: 0,
          description: '',
          suppliers: [],
          brands: [],
          image: null,
          is_edge: true,
        };
      }

      newState = {
        ...newState,

        lastEdit: props.edit,
        haveEdits: false,
      };
    }

    return newState;
  }

  componentDidMount() {
    this.props.getMaterialCategorySettings();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isMaterialValid && this.props.isMaterialValid ) {
      this.handleOnClose()
    }
  }

  componentWillUnmount() {
    this.props.resetData()
  }

  handleOnClose = () => {
    // reset the state
    this.setState(CreateMaterial.defaultState);
    this.props.resetData();

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleOnClose();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleOnClose();
    });
  }

  handleInputValueChange = (name, value) => {
    let stateUpdates = {
      [name]: value !== null && value !== undefined ? value : '',
      errors: {
        ...this.state.errors,
        [name]: false,
      },
      haveEdits: true,
    };
    if (name == "category_id" && value && value != this.state.category_id) {
      const { materialCategorySettings, categories } = this.props;
      const selectedCategory = categories.find(el => el.id == value);
      if (selectedCategory) {
        let root_category_id = 0;
        if (selectedCategory.level == 1) {
          root_category_id = value;
        } else if (selectedCategory.level == 2) {
          root_category_id = selectedCategory.parent_id;
        } else {  // selectedCategory.level == 3
          root_category_id = selectedCategory.parent.parent_id;
        }
        const defaultSettings = materialCategorySettings[root_category_id];
        if (defaultSettings) {
          stateUpdates.discount = defaultSettings.discount;
          stateUpdates.surcharges = defaultSettings.surcharge;
          stateUpdates.waste = defaultSettings.waste;
        }
      }
    } else if (name == "image") {
      stateUpdates.logo_removed = !Boolean(value);
    }
    this.setState(stateUpdates);
  }

  handleRemoveLogo = () => {
    this.setState({
      logo_removed: true,
    });
  }

  handleCreateEdit = () => {
    const { edit, currentPage, currentFilters, currentSearchTerms, currentCompoundMaterials } = this.props;
    const { name, sku, category_id, thickness, unit, purchasingPrice, discount, surcharges, waste, in_stock, image, description, suppliers, brands, is_edge, logo_removed } = this.state;
    let data = {};

    if (edit) {
      data = {
        ...edit
      };
    }

    data = {
      ...data,
      name: name,
      code: sku,
      category_id: category_id !== null ? +category_id : null,
      thickness: +thickness,
      metric: unit,
      default_price: purchasingPrice !== '' ? +purchasingPrice : null,
      suppliers: suppliers.map(el => el.id),
      brands: brands,
      is_edge_material: is_edge ? 1 : 0,
      is_webshop_material: 0,
      logo: (image && image instanceof File) ? image : null,
      description: description,
      logo_removed: !logo_removed ? 0 : 1,
      'material_meta[discount_percentage]': discount !== '' ? discount : null,
      'material_meta[surcharge_percentage]': surcharges !== '' ? surcharges : null,
      'material_meta[waste_percentage]': waste !== '' ? waste : null,
      'material_meta[in_stock]': in_stock == 0 ? 1 : 0,
      'material_meta[suppliers]': suppliers.map(el => el.id),
      'material_meta[brands]': brands,
      'material_meta': null,
    };

    let formData = convertToFormData(data);
    if (!edit) {
      this.props.addMaterialRequest(formData, { page: currentPage, filters: currentFilters, searchTerms: currentSearchTerms, loadCompoundMaterials: currentCompoundMaterials });
    } else {
      this.props.updateMaterialRequest(edit.id, formData, { page: currentPage, filters: currentFilters, searchTerms: currentSearchTerms, loadCompoundMaterials: currentCompoundMaterials });
    }
  }

  handleAddSupplier = (value) => {
    const {suppliers} = this.state;
    const isSupplierAdded = suppliers.find(item => item === value.id);
    if (value.id !== '' && !isSupplierAdded) {
      const newSuppliers = suppliers.slice();
      newSuppliers.push(value);
      this.setState({suppliers: newSuppliers});
    }
  }

  handleRemoveSupplier = (index) => {
    const newSuppliers = this.state.suppliers.slice();
    newSuppliers.splice(index, 1);
    this.setState({ suppliers: newSuppliers });
  }

  handleAddBrand = (name) => {
    const brands = this.state.brands.slice();
    brands.push(name);
    this.setState({ brands });
  }

  handleRemoveBrand = (index) => {
    const brands = this.state.brands.slice();
    brands.splice(index, 1);
    this.setState({ brands });
  }

  render() {
    const { isOpen, onClose, metrics, edit, suppliersList, currencySymbol, t } = this.props;
    const { unit } = this.state;
    const unitOptions = Object.keys(metrics).map(el => ({ id: el, value: metrics[el] }));
    const stockOptions = [{id: 0, value: t('in_stock')}, {id: 1, value: t('out_of_stock')}];
    const selectedInStockOptionResult = stockOptions.find(item => item.id == this.state.in_stock);
    const selectedInStockOption = selectedInStockOptionResult ? selectedInStockOptionResult.id : stockOptions[0].id;
    const categoriesArray = this.props.categories.map(cat => ({ id: cat.id, value: cat.name }));
    const selectedCategoryId = this.state.category_id ? this.state.category_id : "";

    const errors = this.state.errors;
    const purchasingPrice = this.state.purchasingPrice && (this.state.purchasingPrice.length > 0) ? +this.state.purchasingPrice : NaN;
    let discountAmount = this.state.discount && (this.state.discount.length > 0) ? +this.state.discount : NaN;
    let surchargesAmount = this.state.surcharges && this.state.surcharges.length > 0 ? +this.state.surcharges : NaN;
    let wasteAmount =  this.state.waste && (this.state.waste.length > 0)  ? +this.state.waste : NaN;

    let retailPriceAmount = purchasingPrice;
    if (!isNaN(purchasingPrice)) {
      if (!isNaN(discountAmount) && !isNaN(surchargesAmount) && !isNaN(wasteAmount)) {
        retailPriceAmount = calculateMaterialRetailPrice(purchasingPrice, discountAmount, surchargesAmount, wasteAmount);
      }
      if (!isNaN(discountAmount)) {
        discountAmount = (discountAmount / 100.0) * purchasingPrice;
      }
      if (!isNaN(discountAmount) && !isNaN(surchargesAmount)) {
        surchargesAmount = (surchargesAmount / 100.0) * (purchasingPrice - discountAmount);
      }
      if (!isNaN(discountAmount) && !isNaN(surchargesAmount) && !isNaN(wasteAmount)) {
        wasteAmount = (wasteAmount / 100.0) * (purchasingPrice - discountAmount + surchargesAmount);
      }
    } else {
      discountAmount = NaN;
      surchargesAmount = NaN;
      wasteAmount = NaN;
    }
    const discountPreview = !isNaN(discountAmount) ? discountAmount.toFixed(2) : "N/A";
    const surchargesPreview = !isNaN(surchargesAmount) ? surchargesAmount.toFixed(2) : "N/A";
    const wastePreview = !isNaN(wasteAmount) ? wasteAmount.toFixed(2) : "N/A";
    const retailPricePreview = !isNaN(retailPriceAmount) ? formatNumericValue(Math.round((retailPriceAmount + Number.EPSILON) * 1000) / 1000) : "N/A ";
    const sanitizeTooltipErrorTitle = error => (error ? error[0] : "");

    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'create-material-dialog',
          }
        }}>
        <MuiDialogTitle className="create-material-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{!edit ? t('create_material') : t('edit_material')}</Typography>
          <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
          <img
            alt="create-material-close-btn"
            src={CloseButton}
          />
          </IconButton>
        </MuiDialogTitle>
        <div className="create-material-content">
          <div className="material-info">
            <div className="material-name">
              <span className="info-title">{ t('material_name') }</span>
              <ErrorTooltip title={sanitizeTooltipErrorTitle(this.state.errors.name)}>
                <Input
                  autofocus={true}
                  value={ this.state.name }
                  onChange={e => this.handleInputValueChange('name', e.target.value)}
                  error={errors.name}
                />
              </ErrorTooltip>
              <span className="info-title">{ t('material_short_name') }</span>
              <ErrorTooltip title={sanitizeTooltipErrorTitle(this.state.errors.sku)}>
                <Input
                  value={ this.state.sku }
                  onChange={e => this.handleInputValueChange('sku', e.target.value)}
                  error={errors.sku}
                />
              </ErrorTooltip>
              <span className="info-title">{ t('category') }</span>
              <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.category_id)}>
                <div className="category-dropdown-container">
                  <Dropdown
                    buttonText={ selectedCategoryId }
                    buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                    dropdownList={[{id:'', value: t('none')},...categoriesArray]}
                    hoverColor="dark"
                    onClick={item => this.handleInputValueChange('category_id', item.id)}
                    error={errors.category_id}
                  />
                </div>
              </ErrorTooltipAlt>
              <div className="material-thickness-unit">
                <div className="material-thickness thickness-input">
                  <span className="info-title">{ t('thickness') }</span>
                  <ErrorTooltip title={sanitizeTooltipErrorTitle(this.state.errors.thickness)}>
                    <InputWithIcon
                      className="input-with-icon-special"
                      value={ this.state.thickness }
                      onChange={e => this.handleInputValueChange('thickness', e.target.value)}
                      error={errors.thickness}
                      endAdornment={<InputAdornment position="end">mm</InputAdornment>}
                    />
                  </ErrorTooltip>
                </div>
                <div className="material-unit">
                  <span className="info-title">{ t('unit') }</span>
                  <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.metric)}>
                    <Dropdown
                      buttonText={unit}
                      buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                      dropdownList={unitOptions}
                      hoverColor="dark"
                      onClick={item => this.handleInputValueChange('unit', item.id)}
                    />
                  </ErrorTooltipAlt>
                </div>
              </div>
              <FormControl
                className="form-control-edge"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.is_edge}
                      onChange={e => this.handleInputValueChange('is_edge', e.target.checked) }
                      color="primary"
                    />
                  }
                  label={t('can_be_used_as_edge')}
                  className={"edge-checkbox " + (this.state.is_edge ? "edge-checkbox-checked" : "")}
                />
              </FormControl>
            </div>
            <div className="material-price">
              <span className="info-title">{ t('purchasing_price') }</span>
              <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.purchasingPrice)}>
                <div>
                  <DecimalInput
                    className="purchasing-price-input"
                    adornment={currencySymbol}
                    value={ this.state.purchasingPrice }
                    onChange={ value => this.handleInputValueChange('purchasingPrice', value)}
                    error={errors.purchasingPrice}
                  />
                </div>
              </ErrorTooltipAlt>
              <span className="info-title">{ t('discount') }</span>
              <div className="text-input">
                <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.discount)}>
                  <div>
                    <DecimalInput
                      value={ this.state.discount }
                      adornment="%"
                      maxValue={100}
                      onChange={value => this.handleInputValueChange('discount', value)}
                      error={errors.discount}
                    />
                  </div>
                </ErrorTooltipAlt>
                <span>{ discountPreview } {currencySymbol}</span>
              </div>
              <span className="info-title">{ t('surcharges') }</span>
              <div className="text-input">
                <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.surcharges)}>
                  <div>
                    <DecimalInput
                      value={ this.state.surcharges }
                      adornment="%"
                      maxValue={100}
                      onChange={value => this.handleInputValueChange('surcharges', value)}
                      error={errors.surcharges}
                    />
                  </div>
                </ErrorTooltipAlt>
                <span>{ surchargesPreview } {currencySymbol}</span>
              </div>
              <span className="info-title">{ t('waste') }</span>
              <div className="text-input">
                <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.waste)}>
                  <div>
                    <DecimalInput
                      value={ this.state.waste }
                      adornment="%"
                      maxValue={100}
                      onChange={value => this.handleInputValueChange('waste', value)}
                      error={errors.waste}
                    />
                  </div>
                </ErrorTooltipAlt>
                <span>{ wastePreview } {currencySymbol}</span>
              </div>
              <div className="retail-price">
                <span>{ t('retail_price') }</span>
                <span className="price">{retailPricePreview}{currencySymbol}</span>
              </div>
            </div>
            <div className="material-img">
              <Dropdown
                buttonText={selectedInStockOption}
                buttonIconType="white"
                buttonProps={{className: 'dropdown-blue-style stock-dropdown' + (selectedInStockOption == 1 ? ' out-of-stock' : ''), round: true, size: "sm"}}
                dropdownList={stockOptions}
                hoverColor="dark"
                onClick={item => this.handleInputValueChange('in_stock', item.id)}
              />
              <span className="info-title">{ t('image') }</span>
              <ErrorTooltipAlt title={sanitizeTooltipErrorTitle(this.state.errors.logo)}>
                <div>
              <UploadImage
                className="upload-img"
                file={!this.state.logo_removed ? this.state.image : null}
                onChange={file => this.handleInputValueChange('image', file)}
                errorMsg={errors.logo}
              />
              </div>
              </ErrorTooltipAlt>
              <div className="description-container">
                <span className="info-title">{ t('description') }</span>
                <textarea
                  rows="4"
                  onChange={e => this.handleInputValueChange('description', e.target.value)}
                  value={this.state.description}>
                </textarea>
              </div>
            </div>
          </div>
          <GridContainer
            className="tags-grid"
            direction="row"
            xs={12}
          >
            <GridItem className="tags-grid-item" xs={6}>
              <CustomTags
                containerClassName="tags-container"
                inputIcon={AppsGridIcon}
                maxPreviewHeight="60px"
                title={ t('suppliers') }
                data={this.state.suppliers}
                dataList={suppliersList}
                getData={(value) => this.props.getSuppliersList(value)}
                onAddTag={this.handleAddSupplier}
                onRemoveTag={this.handleRemoveSupplier}
              />
            </GridItem>
            <GridItem className="tags-grid-item" xs={6}>
              <Tags
                containerClassName="tags-container"
                inputIcon={AppsGridIcon}
                title={ t('brands') }
                data={this.state.brands}
                onAddTag={this.handleAddBrand}
                onRemoveTag={this.handleRemoveBrand}
              />
            </GridItem>
          </GridContainer>
        </div>
        <div className="material-btn-container">
          <Button
            className="cancel-btn"
            onClick={ this.handleOnClose }
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleCreateEdit}
            disabled={this.props.createInProgress}
          >
            { !edit ? t('create_material').toUpperCase() : t('edit_material_button').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('close_material')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    createInProgress: state.materials.materialsMutBusy,
    requestErrors: state.materials.materialsError,
    isMaterialValid: state.materials.isMaterialValid,
    metrics: state.globals.metrics,
    currencySymbol: state.globals.currencySymbol,
    suppliersList: state.providers.suppliersList,
    materialCategorySettings: state.materialCategories.materialCategorySettings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addMaterialRequest: (data, page, loadCompoundMaterials) => dispatch(addMaterialRequest(data, page, loadCompoundMaterials)),
    updateMaterialRequest: (id, data, page, loadCompoundMaterials) => dispatch(updateMaterialRequest(id, data, page, loadCompoundMaterials)),
    getSuppliersList: (term) => dispatch(getSuppliersList(term)),
    getMaterialCategorySettings: (id) => dispatch(getMaterialCategorySettings(id)),
    resetData: () => dispatch(resetMaterialData()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateMaterial));
