/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  defaultFont,
  dangerColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import custosmropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx";

const adminNavbarLinksStyle = theme => ({
  ...custosmropdownStyle(theme),
  search: {
    margin: "0",
    paddingTop: "7px",
    paddingBottom: "7px",
    [theme.breakpoints.down("xs")]: {
      margin: "10px 15px",
      float: "none !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      padding: "10px 15px",
      width: "auto"
    }
  },
  searchInput: {
    paddingTop: "2px"
  },
  searchRTL: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "18px !important"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "12px"
    }
  },
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0!important",
    textTransform: "none"
  },
  adminPerson: {
    border: "2px solid #3d73dd",
    width: "46px !important",
    height: "46px !important",
    borderRadius: "50%",
    marginRight: "5px",
    padding: "5px"
  },
  buttonLink: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      margin: "5px 15px 0",
      width: "auto",
      height: "auto",
      "& svg": {
        width: "30px",
        height: "24px",
        marginRight: "19px",
        marginLeft: "3px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        width: "30px",
        fontSize: "24px",
        lineHeight: "30px",
        marginRight: "19px",
        marginLeft: "3px"
      }
    }
  },
  searchButton: {
    [theme.breakpoints.down("xs")]: {
      top: "-50px !important",
      marginRight: "38px",
      float: "right"
    }
  },
  top: {
    zIndex: "4"
  },
  searchIcon: {
    width: "17px",
    zIndex: "4"
  },
  links: {
    width: "20px",
    height: "20px",
    zIndex: "4",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "30px",
      height: "30px",
      color: "inherit",
      opacity: "0.8",
      marginRight: "16px",
      marginLeft: "-5px"
    }
  },
  notifications: {
    zIndex: "4",
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "11px",
      border: "1px solid " + whiteColor,
      right: "11px",
      fontSize: "9px",
      background: dangerColor[0],
      color: whiteColor,
      minWidth: "8px",
      height: "8px",
      borderRadius: "50%",
      textAlign: "center",
      lineHeight: "14px",
      verticalAlign: "middle",
      display: "block"
    },
    [theme.breakpoints.down("xs")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px"
    }
  },
  wrapper: {
    position: "relative",
  },
  wrapperRTL: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "0px"
    }
  },
  buttonLinkRTL: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      justifyContent: "flex-end",
      width: "-webkit-fill-available",
      margin: "10px 15px 0",
      padding: "10px 15px",
      display: "block",
      position: "relative"
    }
  },
  labelRTL: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row-reverse",
      justifyContent: "initial",
      display: "flex"
    }
  },
  linksRTL: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "-5px !important",
      marginLeft: "16px !important"
    }
  },
  managerClasses: {
    [theme.breakpoints.up("sm")]: {
      display: "inline-block"
    }
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important"
  },
  timerBtn: {
    alignItems: "center",
    display: "flex",
    position: "absolute",
    justifyContent: "space-between",
    top: "20px",
    left: "-240px",
    width: "222px",
    height: "40px",
    padding: "0 15px",
    borderRadius: "10px",
    backgroundColor: "#b4c6d9",
  },
  timerBtnActive: {
    backgroundColor: "#3d73dd",
  },
  openTimerBtn: {
    cursor: 'pointer',
  },
  timerTitle: {
    color: "rgba(255, 255, 255, 0.6)",
    fontWeight: "600",
    fontSize: "20px",
  },
  timerTime: {
    backgroundColor: "#0acf05",
    borderRadius: "5px",
    color: "white",
    display: "flex",
    alignItems: "center",
    height: "17px",
    padding: "3px",
    marginRight: "5px",
    marginTop: "3px",
  },
  inactiveBtn: {
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "3px",
    color: "#ffffff",
    display: "flex",
    padding: "0 7px",
    fontSize: "14px",
    height: "17px",
    width: "81px",
  },
});

export default adminNavbarLinksStyle;
