import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {forgotPassword, resetForgotPasswordError} from 'store/actions/auth';
import CheckMarkIcon from 'assets/img/checkmark-large.svg';
import CustomDropdownLanguage from 'components/CustomDropdownLanguage/CustomDropdownLanguage';
import i18n from 'i18next';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Input from 'components/Input/Input';

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import './style.scss';

class LoginPageAP extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      isMailSent: false
    };

    this.emailRef = React.createRef();
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.auth.isForgotPasswordReady === false && this.props.auth.isForgotPasswordReady) {
      this.setState({
        isMailSent: true
      })
    }
  }

  componentWillUnmount() {
    /*const { resetForgotPasswordError } = this.props;
    resetForgotPasswordError();*/
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  onResetBtnClick = () => {
    const { forgotPassword } = this.props;
    const credentials = {
      email: this.emailRef.current.value,
    };
    forgotPassword(JSON.stringify(credentials));
  };

  onEmailInputChange = event => {
    const { resetForgotPasswordError } = this.props;
    resetForgotPasswordError();
  };

  onInputKeyDown = event => {
    if (event.key === 'Enter') {
      this.onResetBtnClick();
    }
  };

  render() {
    const { classes, auth, location, history, t } = this.props;
    const { isMailSent } = this.state;
    if (auth.isAuthorized) {
      let { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    }

    return (
      <div className={`${classes.container} ikt-ap_reset-password-container`}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card
                login
                className={`${
                  classes[this.state.cardAnimaton]
                } ikt-ap_reset-password-card`}
              >
                {
                  isMailSent
                    ?
                      <CardBody className="ikt-ap_reset-password-card-body">
                        <GridItem className="reset-password-checkmark-icon">
                          <img src={CheckMarkIcon}/>
                        </GridItem>
                        <GridItem className="reset-password-information" xs={12}>
                          <span>{`${t('reset_password_email_sent_pre')} ${this.emailRef.current.value} ${t('reset_password_email_sent_post')}`}</span>
                        </GridItem>
                        <GridItem>
                          <div className="login-reset-password-btn">
                            <Link className="login-back-link" to={'/auth-ap/login-page'}>
                              <Button
                                className="restore-btn"
                              >
                                { t('back_to_login').toUpperCase() }
                              </Button>
                            </Link>
                          </div>
                        </GridItem>
                      </CardBody>
                    :
                      <>
                        <CardBody className="ikt-ap_reset-password-card-body">
                          <GridItem className="reset-password-card-header" xs={12}>
                            <span>{ t('restore_password')} </span>
                          </GridItem>
                          <GridItem className="reset-password-card-text" xs={12}>
                            <span>{ t('email') }</span>
                            <div className="input-container">
                              <Input
                                className="ikt-ap_reset-password-email-input"
                                ref={this.emailRef}
                                error={auth.forgotPasswordErrors.email}
                                errorMsg={auth.forgotPasswordErrors.email && auth.forgotPasswordErrors.email[0]}
                                disableErrorPadding={true}
                                onChange={this.onEmailInputChange}
                                onKeyDown={this.onInputKeyDown}
                              />
                            </div>
                          </GridItem>
                          <GridItem>
                            <div className="login-reset-password-btn">
                              <Button
                                className="restore-btn"
                                onClick={this.onResetBtnClick}
                              >
                                { t('restore_password').toUpperCase() }
                              </Button>
                            </div>
                          </GridItem>
                        </CardBody>
                        <CardFooter
                          className={`${classes.justifyContentCenter} ikt-ap_reset-password-card-footer`}
                        >
                          <div className="login-reset-password-btn">
                            <Link className="login-back-link" to={'/auth-ap/login-page'}>
                              <Button
                                className="back-btn"
                              >
                                { t('back_to_login').toUpperCase() }
                              </Button>
                            </Link>
                          </div>
                        </CardFooter>
                      </>
                }
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        <div className="language-selector-container">
          <CustomDropdownLanguage
            buttonText={i18n.language}
            onClick={prop => {
              i18n.changeLanguage(prop.id);
            }}
          />
        </div>
      </div>
    );
  }
}

LoginPageAP.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: data => dispatch(forgotPassword(data)),
    resetForgotPasswordError: () => dispatch(resetForgotPasswordError())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(loginPageStyle)(withRouter(withTranslation()(LoginPageAP))));
