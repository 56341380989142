import {
  GET_MATERIALS,
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS_ERROR,
  GET_MATERIALS_FOR_CATEGORY,
  GET_MATERIALS_FOR_CATEGORY_SUCCESS,
  GET_MATERIALS_FOR_CATEGORY_ERROR,
  GET_MATERIALS_IN_BUNDLE_SUCCESS,
  GET_MATERIALS_IN_BUNDLE_ERROR,
  GET_MATERIALS_IN_BUNDLES,
  GET_MATERIALS_IN_BUNDLES_FINISH,
  GET_MATERIAL,
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_ERROR,
  ADD_MATERIAL,
  ADD_MATERIAL_SUCCESS,
  ADD_MATERIAL_ERROR,
  DUPLICATE_MATERIAL,
  DUPLICATE_MATERIAL_SUCCESS,
  DUPLICATE_MATERIAL_ERROR,
  ADD_COMPOUND_MATERIAL,
  ADD_COMPOUND_MATERIAL_SUCCESS,
  ADD_COMPOUND_MATERIAL_ERROR,
  UPDATE_COMPOUND_MATERIAL,
  UPDATE_COMPOUND_MATERIAL_BASE,
  UPDATE_COMPOUND_MATERIAL_BASE_ERROR,
  UPDATE_COMPOUND_MATERIAL_BASE_SUCCESS,
  UPDATE_MATERIAL,
  UPDATE_MATERIAL_SUCCESS,
  UPDATE_MATERIAL_ERROR,
  ADD_MATERIAL_REQUEST,
  ADD_MATERIAL_REQUEST_SUCCESS,
  ADD_MATERIAL_REQUEST_ERROR,
  UPDATE_MATERIAL_REQUEST,
  UPDATE_MATERIAL_REQUEST_SUCCESS,
  UPDATE_MATERIAL_REQUEST_ERROR,
  APPROVE_MATERIAL,
  APPROVE_MATERIAL_SUCCESS,
  APPROVE_MATERIAL_ERROR,
  SET_MATERIAL_AVAILABILITY,
  START_MATERIAL_FILE_IMPORT,
  START_MATERIAL_FILE_IMPORT_SUCCESS,
  START_MATERIAL_FILE_IMPORT_FAILURE,
  DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED,
  DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_SUCCESS,
  DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_ERROR,
  REMOVE_MATERIAL,
  REMOVE_MATERIAL_SUCCESS,
  REMOVE_MATERIAL_ERROR,
  RESTORE_MATERIAL,
  RESTORE_MATERIAL_SUCCESS,
  RESTORE_MATERIAL_ERROR,
  RESTORE_MATERIALS,
  RESTORE_MATERIALS_SINGLE_SUCCESS,
  RESTORE_MATERIALS_SINGLE_FAILURE,
  RESTORE_MATERIALS_FINISH,
  REMOVE_MATERIALS,
  REMOVE_MATERIALS_SINGLE_SUCCESS,
  REMOVE_MATERIALS_SINGLE_FAILURE,
  REMOVE_MATERIALS_FINISH,
  REMOVE_COMPOUND_MATERIAL,
  REMOVE_COMPOUND_MATERIAL_SUCCESS,
  REMOVE_COMPOUND_MATERIAL_ERROR,
  RESET_MATERIAL_DATA,
  RESET_MATERIAL_DUPLICATE_DATA,
  UPDATE_MATERIAL_CATEGORY_VALUES,
  UPDATE_MATERIAL_CATEGORY_VALUES_SUCCESS,
  UPDATE_MATERIAL_CATEGORY_VALUES_ERROR,
  UPDATE_MULTIPLE_MATERIALS_VALUES,
  UPDATE_MULTIPLE_MATERIALS_VALUES_SUCCESS,
  UPDATE_MULTIPLE_MATERIALS_VALUES_ERROR,
  UPDATE_MULTIPLE_MATERIALS_PRICE,
  UPDATE_MULTIPLE_MATERIALS_PRICE_SUCCESS,
  UPDATE_MULTIPLE_MATERIALS_PRICE_ERROR,
} from '../actionTypes/materialsActionTypes';

import NotificationService from '../../services/NotificationService';

export const getMaterials = (page, filters, searchTerms) => {
  return {
    type: GET_MATERIALS,
    payload: { page, filters, searchTerms }
  };
};

export const getMaterialsSuccess = data => {
  return {
    type: GET_MATERIALS_SUCCESS,
    payload: data
  };
};

export const getMaterialsFailure = error => {
  return { type: GET_MATERIALS_ERROR, payload: error };
};

export const getMaterialsForCategory = (page, filters, searchTerms) => {
  return {
    type: GET_MATERIALS_FOR_CATEGORY
    ,
    payload: { page, filters, searchTerms }
  };
};

export const getMaterialsForCategorySuccess = data => {
  return {
    type: GET_MATERIALS_FOR_CATEGORY_SUCCESS,
    payload: data
  };
};

export const getMaterialsForCategoryFailure = error => {
  return { type: GET_MATERIALS_FOR_CATEGORY_ERROR, payload: error };
};

export const getMaterialsInBundleSuccess = (id, data) => {
  return { type: GET_MATERIALS_IN_BUNDLE_SUCCESS, payload: { id, data } };
};

export const getMaterialsInBundleError = (id, error) => {
  NotificationService.error('Failed to retrieve compound material bundle.');
  return { type: GET_MATERIALS_IN_BUNDLE_ERROR, payload: { id, error } };
};

export const getMaterialsInBundles = (ids, keepCurrent) => {
  return { type: GET_MATERIALS_IN_BUNDLES, payload: { ids, keepCurrent } };
};

export const getMaterialsInBundlesFinish = data => {
  return { type: GET_MATERIALS_IN_BUNDLES_FINISH, payload: data };
};

export const updateCompoundMaterial = (loadListInfo, data) => {
  return { type: UPDATE_COMPOUND_MATERIAL, payload: { loadListInfo, data } };
};

export const updateCompoundMaterialBase = (id, data, loadListInfo) => {
  return { type: UPDATE_COMPOUND_MATERIAL_BASE, payload: { id, data, loadListInfo } };
};

export const updateCompoundMaterialBaseSuccess = (error) => {
  NotificationService.success('Compound Material was updated successfully');
  return { type: UPDATE_COMPOUND_MATERIAL_BASE_SUCCESS, payload: error };
};

export const updateCompoundMaterialBaseError = (error) => {
  NotificationService.error('Compound material update failed');
  return { type: UPDATE_COMPOUND_MATERIAL_BASE_ERROR, payload: error };
};

export const getMaterial = () => {
  return { type: GET_MATERIAL };
};

export const getMaterialSuccess = materials => {
  return {
    type: GET_MATERIAL_SUCCESS,
    payload: materials
  };
};

export const getMaterialFailure = error => {
  return { type: GET_MATERIAL_ERROR, payload: error };
};

export const addCompoundMaterial = (data, loadListInfo) => {
  return { type: ADD_COMPOUND_MATERIAL, payload: { data, loadListInfo } };
};

export const addCompoundMaterialSuccess = () => {
  NotificationService.success('Compound Material was added successfully');
  return { type: ADD_COMPOUND_MATERIAL_SUCCESS };
};

export const addCompoundMaterialFailure = error => {
  NotificationService.error('Something went wrong!');
  return { type: ADD_COMPOUND_MATERIAL_ERROR, payload: error };
};

export const addMaterial = data => {
  return { type: ADD_MATERIAL, payload: data };
};

export const addMaterialSuccess = () => {
  NotificationService.success('Material was added successfully');
  return { type: ADD_MATERIAL_SUCCESS };
};

export const addMaterialFailure = error => {
  NotificationService.success('Something went wrong!');
  return { type: ADD_MATERIAL_ERROR, payload: error };
};

export const duplicateMaterial = (id, loadListInfo) => {
  return { type: DUPLICATE_MATERIAL, payload: { id, loadListInfo } };
};

export const duplicateMaterialSuccess = () => {
  NotificationService.success('Material duplicated successfully');
  return { type: DUPLICATE_MATERIAL_SUCCESS };
};

export const duplicateMaterialFailure = error => {
  // NotificationService.success('Something went wrong!');
  return { type: DUPLICATE_MATERIAL_ERROR, payload: error };
};

export const updateMaterial = ({ id, data }) => {
  return { type: UPDATE_MATERIAL, payload: { id, data } };
};

export const updateMaterialSuccess = () => {
  NotificationService.success('Material updated successfully');
  return { type: UPDATE_MATERIAL_SUCCESS };
};

export const updateMaterialFailure = error => {
  NotificationService.error('Something went wrong!');
  return { type: UPDATE_MATERIAL_ERROR, payload: error };
};

export const addMaterialRequest = (body, loadListInfo) => {
  return { type: ADD_MATERIAL_REQUEST, payload: { body, loadListInfo } };
};

export const addMaterialRequestSuccess = () => {
  NotificationService.success('Material request was added successfully');
  return { type: ADD_MATERIAL_REQUEST_SUCCESS };
};

export const addMaterialRequestFailure = error => {
  // NotificationService.success('Something went wrong!');
  return { type: ADD_MATERIAL_REQUEST_ERROR, payload: error };
};

export const updateMaterialRequest = (id, body, loadListInfo) => {
  return { type: UPDATE_MATERIAL_REQUEST, payload: { id, body, loadListInfo } };
};

export const updateMaterialRequestSuccess = material => {
  NotificationService.success('Material request was updated successfully');
  return { type: UPDATE_MATERIAL_REQUEST_SUCCESS, payload: material };
};

export const updateMaterialRequestFailure = error => {
  return { type: UPDATE_MATERIAL_REQUEST_ERROR, payload: error };
};

export const approveMaterial = id => {
  return { type: APPROVE_MATERIAL, payload: id };
};

export const approveMaterialSuccess = material => {
  return { type: APPROVE_MATERIAL_SUCCESS, payload: material };
};

export const approveMaterialFailure = error => {
  return { type: APPROVE_MATERIAL_ERROR, payload: error };
};

export const setMaterialAvailability = (loadListInfo, data) => {
  return {
    type: SET_MATERIAL_AVAILABILITY,
    payload: { loadListInfo, data }
  };
};

export const startMaterialFileImport = data => {
  return {
    type: START_MATERIAL_FILE_IMPORT,
    payload: data
  };
};

export const startMaterialFileImportSuccess = data => {
  return {
    type: START_MATERIAL_FILE_IMPORT_SUCCESS,
    payload: data
  };
};

export const startMaterialFileImportFailure = error => {
  let errorData = '';
  if (error.responseStatus === 400) {
    errorData = error.responseBody.errors;
  } else if (error.responseStatus === 422) {
    errorData = error.responseBody.errors.file[0];
  } else if (error.responseStatus === 500) {
    errorData = error.responseBody.errors;
  } else {
    errorData = error.responseBody.error;
  }
  NotificationService.error(errorData, true)
  return {
    type: START_MATERIAL_FILE_IMPORT_FAILURE,
    payload: errorData
  };
};

export const downloadMaterialFileImportSkipped = (data) => {
  return {
    type: DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED,
    payload: data,
  };
};

export const downloadMaterialFileImportSkippedSuccess = (blob, filename) => {
  return {
    type: DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_SUCCESS,
    payload: { blob, filename }
  };
};

export const downloadMaterialFileImportSkippedFailure = error => {
  NotificationService.error(error);
  return {
    type: DOWNLOAD_MATERIAL_FILE_IMPORT_SKIPPED_ERROR,
    payload: error
  };
};

export const removeMaterial = (id, purge, loadListInfo) => {
  return {
    type: REMOVE_MATERIAL,
    payload: { id, purge, loadListInfo }
  };
};

export const removeMaterialSuccess = () => {
  NotificationService.success('Material removed successfully!');
  return { type: REMOVE_MATERIAL_SUCCESS };
};

export const removeMaterialFailure = error => {
  NotificationService.error("Error removing material: " + error);
  return { type: REMOVE_MATERIAL_ERROR, payload: error };
};

export const restoreMaterial = (id, loadListInfo) => {
  return {
    type: RESTORE_MATERIAL,
    payload: { id, loadListInfo }
  };
};

export const restoreMaterialSuccess = () => {
  NotificationService.success('Material restored successfully!');
  return { type: RESTORE_MATERIAL_SUCCESS };
};

export const restoreMaterialFailure = error => {
  return { type: RESTORE_MATERIAL_ERROR, payload: error };
};

export const restoreMaterials = (data, loadListInfo) => {
  return {type: RESTORE_MATERIALS, payload: { data, loadListInfo }}
};

export const restoreMaterialsSingleSuccess = (id) => {
  return {type: RESTORE_MATERIALS_SINGLE_SUCCESS, payload: id};
};

export const restoreMaterialsSingleFailure = (id, error) => {
  return {type: RESTORE_MATERIALS_SINGLE_FAILURE, payload: { id, error }};
};

export const restoreMaterialsFinish = (loadListInfo) => {
  return {type: RESTORE_MATERIALS_FINISH, payload: loadListInfo};
};

export const removeMaterials = (data, purge, loadListInfo) => {
  return {type: REMOVE_MATERIALS, payload: { data, purge, loadListInfo }}
};

export const removeMaterialsSingleSuccess = (id) => {
  return {type: REMOVE_MATERIALS_SINGLE_SUCCESS, payload: id};
};

export const removeMaterialsSingleFailure = (id, error) => {
  return {type: REMOVE_MATERIALS_SINGLE_FAILURE, payload: { id, error }};
};

export const removeMaterialsFinish = (loadListInfo) => {
  return {type: REMOVE_MATERIALS_FINISH, payload: loadListInfo};
};

export const removeCompoundMaterial = id => {
  return { type: REMOVE_COMPOUND_MATERIAL, payload: id };
};

export const removeCompoundMaterialSuccess = () => {
  NotificationService.success('Compound Material removed successfully!');
  return { type: REMOVE_COMPOUND_MATERIAL_SUCCESS };
};

export const removeCompoundMaterialFailure = error => {
  return { type: REMOVE_COMPOUND_MATERIAL_ERROR, payload: error };
};

export const resetMaterialData = () => {
  return { type: RESET_MATERIAL_DATA };
};

export const resetMaterialDuplicateData = () => {
  return { type: RESET_MATERIAL_DUPLICATE_DATA };
};

export const updateMaterialCategoryValues = (id, data, loadListInfo) => {
  return { type: UPDATE_MATERIAL_CATEGORY_VALUES, payload: { id, data, loadListInfo } };
};

export const updateMaterialCategoryValuesSuccess = () => {
  NotificationService.success('Material category updated successfully!');
  return { type: UPDATE_MATERIAL_CATEGORY_VALUES_SUCCESS };
};

export const updateMaterialCategoryValuesFailure = error => {
  NotificationService.error('Material category update failed!');
  return { type: UPDATE_MATERIAL_CATEGORY_VALUES_ERROR, payload: error };
};

export const updateMultipleMaterialsValues = (ids, data, loadListInfo) => {
  return { type: UPDATE_MULTIPLE_MATERIALS_VALUES, payload: { ids, data, loadListInfo } };
};

export const updateMultipleMaterialsValuesSuccess = () => {
  NotificationService.success('Selected materials updated successfully!');
  return { type: UPDATE_MULTIPLE_MATERIALS_VALUES_SUCCESS };
};

export const updateMultipleMaterialsValuesFailure = error => {
  NotificationService.error('Selected materials update failed!');
  return { type: UPDATE_MULTIPLE_MATERIALS_VALUES_ERROR, payload: error };
};

export const updateMultipleMaterialsPrice = (ids, data, loadListInfo) => {
  return { type: UPDATE_MULTIPLE_MATERIALS_PRICE, payload: { ids, data, loadListInfo } };
};

export const updateMultipleMaterialsPriceSuccess = () => {
  NotificationService.success('Selected materials price updated successfully!');
  return { type: UPDATE_MULTIPLE_MATERIALS_PRICE_SUCCESS };
};

export const updateMultipleMaterialsPriceFailure = error => {
  NotificationService.error('Selected materials price update failed!');
  return { type: UPDATE_MULTIPLE_MATERIALS_PRICE_ERROR, payload: error };
};

