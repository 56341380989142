import dot from "dot-object";

import {
  GET_DEFAULT_COST_CENTER_LIST,
  GET_DEFAULT_COST_CENTER_LIST_SUCCESS,
  DEFAULT_COST_CENTER_LIST_ERROR,
  UPDATE_DEFAULT_COST_CENTERS,
  GET_MATERIALS_SETTINGS,
  GET_MATERIALS_SETTINGS_SUCCESS,
  GET_MATERIALS_SETTINGS_ERROR,
  UPDATE_MATERIALS_SETTINGS,
  GET_WEB_SHOP_SETTINGS,
  GET_WEB_SHOP_SETTINGS_SUCCESS,
  GET_WEB_SHOP_SETTINGS_ERROR,
  UPDATE_WEB_SHOP_SETTINGS,
  GET_MATERIAL_LIST_ALLOWANCE,
  GET_MATERIAL_LIST_ALLOWANCE_SUCCESS,
  GET_MATERIAL_LIST_ALLOWANCE_ERROR,
  UPDATE_MATERIAL_LIST_ALLOWANCE,
  UPDATE_MATERIAL_LIST_ALLOWANCE_SUCCESS,
  UPDATE_MATERIAL_LIST_ALLOWANCE_ERROR,
  GET_MATERIAL_LIST_ORIENTATION_DATA,
  GET_MATERIAL_LIST_ORIENTATION_DATA_SUCCESS,
  GET_MATERIAL_LIST_ORIENTATION_DATA_ERROR,
  ADD_MATERIAL_LIST_ORIENTATION_CLASS,
  ADD_MATERIAL_LIST_ORIENTATION_CLASS_SUCCESS,
  ADD_MATERIAL_LIST_ORIENTATION_CLASS_ERROR,
  REMOVE_MATERIAL_LIST_ORIENTATION_CLASS,
  REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_SUCCESS,
  REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_ERROR,
  GET_PROJECTS_SETTINGS,
  GET_PROJECTS_SETTINGS_SUCCESS,
  GET_PROJECTS_SETTINGS_ERROR,
  UPDATE_PROJECTS_SETTINGS,
  GET_LETTERHEADS,
  GET_LETTERHEADS_SUCCESS,
  GET_LETTERHEADS_ERROR,
  UPDATE_LETTERHEADS,
  UPDATE_LETTERHEADS_SUCCESS,
  UPDATE_LETTERHEADS_ERROR,
  REMOVE_LETTERHEADS,
  REMOVE_LETTERHEAD_ENTRY_SUCCESS,
  REMOVE_LETTERHEAD_ENTRY_ERROR,
  REMOVE_LETTERHEADS_FINISH,
  RESET_SETTINGS_ERRORS,
} from '../actionTypes/settingsActionsTypes';

export const getDefaultCostCentersList = (isOperational) => {
  return {type: GET_DEFAULT_COST_CENTER_LIST, payload: isOperational}
};

export const getDefaultCostCentersListSuccess = (data) => {
  return {type: GET_DEFAULT_COST_CENTER_LIST_SUCCESS, payload: data}
};

export const defaultCostCentersListFailure = (err) => {
  const errorData = dot.object(err);
  return {type: DEFAULT_COST_CENTER_LIST_ERROR, payload: errorData}
};

export const updateCostCenters = (data) => {
  return {type: UPDATE_DEFAULT_COST_CENTERS, payload: data}
};

export const getMaterialsSettings = () => {
  return {type: GET_MATERIALS_SETTINGS}
};

export const getMaterialsSettingsSuccess = (data) => {
  const standardSurcharges = [];
  data.material_standard_surcharges.map(el => {
    const keyParts = el.key.split("_");
    const materialName = keyParts.slice(0, keyParts.length - 1).join(" ");
    const fieldName = keyParts[keyParts.length - 1];
    let objIndex = standardSurcharges.findIndex(el => el.name == materialName);
    if (objIndex == -1) {
      objIndex = standardSurcharges.length;
      standardSurcharges.push({ name: materialName, settings: {}});
    }
    standardSurcharges[objIndex].settings[fieldName] = el.value;
  });
  const rawAllowances = [];
  const rawAllowancesKeyPrefix = "material_raw_dimension_allowance_settings_";
  data.material_raw_dimensions_allowance_settings.map(el => {
    const keyParts = el.key.substring(rawAllowancesKeyPrefix.length).split("_");
    let fieldName = '';
    let materialName = '';
    if (keyParts.length - 2 > 0 && keyParts[keyParts.length - 2] == "fixed" && keyParts[keyParts.length - 1] == "value") {
      fieldName = 'length_fixed_value';
      materialName = keyParts.slice(0, keyParts.length - 3).join(" ");
    } else if (keyParts[keyParts.length - 1] == "percentage"){
      fieldName = 'length_percentage';
      materialName = keyParts.slice(0, keyParts.length - 2).join(" ");
    } else {
      fieldName = keyParts[keyParts.length - 1];
      materialName = keyParts.slice(0, keyParts.length - 1).join(" ");
    }
    let objIndex = rawAllowances.findIndex(el => el.name == materialName);
    if (objIndex == -1) {
      objIndex = rawAllowances.length;
      rawAllowances.push({ name: materialName, settings: {}});
    }
    rawAllowances[objIndex].settings[fieldName] = el.value;
  });
  return {type: GET_MATERIALS_SETTINGS_SUCCESS, payload: { standardSurcharges, rawAllowances }};
};

export const getMaterialsSettingsFailure = (err) => {
  return {type: GET_MATERIALS_SETTINGS_ERROR, payload: err};
};

export const updateMaterialsSettings = (standardSurcharges, rawAllowances) => {
  return {type: UPDATE_MATERIALS_SETTINGS, payload: { standardSurcharges, rawAllowances }}
};

export const getMaterialListAllowance = () => {
  return {type: GET_MATERIAL_LIST_ALLOWANCE, payload: { }};
};

export const getMaterialListAllowanceSuccess = (data) => {
  return {type: GET_MATERIAL_LIST_ALLOWANCE_SUCCESS, payload: data};
};

export const getMaterialListAllowanceFailure = (err) => {
  return {type: GET_MATERIAL_LIST_ALLOWANCE_ERROR, payload: err};
};

export const updateMaterialListAllowance = (data) => {
  return {type: UPDATE_MATERIAL_LIST_ALLOWANCE, payload: data};
};

export const updateMaterialListAllowanceSuccess = () => {
  return {type: UPDATE_MATERIAL_LIST_ALLOWANCE_SUCCESS, payload: { }};
};

export const updateMaterialListAllowanceFailure = (err) => {
  return {type: UPDATE_MATERIAL_LIST_ALLOWANCE_ERROR, payload: err};
};

export const getMaterialListOrientationData = (id, term) => {
  return {type: GET_MATERIAL_LIST_ORIENTATION_DATA, payload: { id, term }};
};

export const getMaterialListOrientationDataSuccess = (data) => {
  return {type: GET_MATERIAL_LIST_ORIENTATION_DATA_SUCCESS, payload: data};
};

export const getMaterialListOrientationDataFailure = (err) => {
  return {type: GET_MATERIAL_LIST_ORIENTATION_DATA_ERROR, payload: err};
};

export const addMaterialListOrientationClass = (id, name, term) => {
  return {type: ADD_MATERIAL_LIST_ORIENTATION_CLASS, payload: { id, name, term }};
};

export const addMaterialListOrientationClassSuccess = () => {
  return {type: ADD_MATERIAL_LIST_ORIENTATION_CLASS_SUCCESS, payload: { }};
};

export const addMaterialListOrientationClassFailure = (err) => {
  return {type: ADD_MATERIAL_LIST_ORIENTATION_CLASS_ERROR, payload: err};
};

export const removeMaterialListOrientationClass = (id, typeId, term) => {
  return {type: REMOVE_MATERIAL_LIST_ORIENTATION_CLASS, payload: { id, typeId, term }};
};

export const removeMaterialListOrientationClassSuccess = () => {
  return {type: REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_SUCCESS, payload: { }};
};

export const removeMaterialListOrientationClassFailure = (err) => {
  return {type: REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_ERROR, payload: err};
};

export const getWebShopSettings = () => {
  return {type: GET_WEB_SHOP_SETTINGS};
};

export const getWebShopSettingsSuccess = (data) => {
  const webShopData = {};
  data.forEach(item => webShopData[item.key] = item.value);
  return {type: GET_WEB_SHOP_SETTINGS_SUCCESS, payload: webShopData}
};

export const getWebShopSettingsFailure = (err) => {
  return {type: GET_WEB_SHOP_SETTINGS_ERROR, payload: err}
};

export const updateWebShopSettings = (data) => {
  return {type: UPDATE_WEB_SHOP_SETTINGS, payload: data}
};

export const getProjectsSettings = () => {
  return {type: GET_PROJECTS_SETTINGS};
};

export const getProjectsSettingsSuccess = (data) => {
  const projectsData = {};
  data.forEach(item => projectsData[item.key] = item.value);
  return {type: GET_PROJECTS_SETTINGS_SUCCESS, payload: projectsData}
};

export const getProjectsSettingsFailure = (err) => {
  return {type: GET_PROJECTS_SETTINGS_ERROR, payload: err}
};

export const updateProjectsSettings = (data) => {
  return {type: UPDATE_PROJECTS_SETTINGS, payload: data}
};

export const getLetterheads = () => {
  return { type: GET_LETTERHEADS };
};

export const getLetterheadsSuccess = (letterhead1, letterhead2) => {
  return { type: GET_LETTERHEADS_SUCCESS, payload: { invoice_primary_header: letterhead1, invoice_secondary_header: letterhead2 } };
};

export const getLetterheadsFailure = errors => {
  return { type: GET_LETTERHEADS_ERROR, payload: errors };
};

export const updateLetterheads = (letterhead1, letterhead2) => {
  return { type: UPDATE_LETTERHEADS, payload: { letterhead1, letterhead2 } };
};

export const updateLetterheadsSuccess = data => {
  return { type: UPDATE_LETTERHEADS_SUCCESS, payload: data };
};

export const updateLetterheadsFailure = errors => {
  return { type: UPDATE_LETTERHEADS_ERROR, payload: errors };
};

export const removeLetterheads = ids => {
  return { type: REMOVE_LETTERHEADS, payload: ids };
};

export const removeLetterheadEntrySuccess = (id) => {
  return { type: REMOVE_LETTERHEAD_ENTRY_SUCCESS, payload: id };
};

export const removeLetterheadEntryFailure = (id, errors) => {
  return { type: REMOVE_LETTERHEAD_ENTRY_ERROR, payload: { id, errors } };
};

export const removeLetterheadsFinish = () => {
  return { type: REMOVE_LETTERHEADS_FINISH };
};

export const resetSettingsErrors = () => {
  return {type: RESET_SETTINGS_ERRORS}
}
