export const GET_CUSTOMERS = 'customerEpic/GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = 'customerEpic/GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'customerEpic/GET_CUSTOMERS_ERROR';

export const GET_CUSTOMER = 'customerEpic/GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'customerEpic/GET_CUSTOMER_SUCCESS';
export const RESET_CUSTOMER_DATA = 'customerEpic/RESET_CUSTOMER_DATA';

export const ADD_CUSTOMER = 'customerEpic/ADD_CUSTOMER';
export const ADD_CUSTOMER_SUCCESS = 'customerEpic/ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_ERROR = 'customerEpic/ADD_CUSTOMER_ERROR';

export const UPDATE_CUSTOMER = 'customerEpic/UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'customerEpic/UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'customerEpic/UPDATE_CUSTOMER_ERROR';

export const VALIDATE_IMPORTED_CUSTOMER_FILE = 'customerEpic/VALIDATE_IMPORTED_CUSTOMER_FILE';
export const VALIDATE_IMPORTED_CUSTOMER_FILE_SUCCESS = 'customerEpic/VALIDATE_IMPORTED_CUSTOMER_FILE_SUCCESS';
export const VALIDATE_IMPORTED_CUSTOMER_FILE_FAILURE = 'customerEpic/VALIDATE_IMPORTED_CUSTOMER_FILE_FAILURE';

export const START_CUSTOMER_FILE_IMPORT = 'customerEpic/START_CUSTOMER_FILE_IMPORT';
export const START_CUSTOMER_FILE_IMPORT_SUCCESS = 'customerEpic/START_CUSTOMER_FILE_IMPORT_SUCCESS';
export const START_CUSTOMER_FILE_IMPORT_FAILURE = 'customerEpic/START_CUSTOMER_FILE_IMPORT_FAILURE';

export const REMOVE_CUSTOMER = 'customerEpic/REMOVE_CUSTOMER';
export const REMOVE_CUSTOMER_SUCCESS = 'customerEpic/REMOVE_CUSTOMER_SUCCESS';
export const REMOVE_CUSTOMER_FAILURE = 'customerEpic/REMOVE_CUSTOMER_FAILURE';

export const REMOVE_CUSTOMERS = "customerEpic/REMOVE_CUSTOMERS";
export const REMOVE_CUSTOMERS_SINGLE_SUCCESS = "customerEpic/REMOVE_CUSTOMERS_SINGLE_SUCCESS";
export const REMOVE_CUSTOMERS_SINGLE_FAILURE = "customerEpic/REMOVE_CUSTOMERS_SINGLE_FAILURE";
export const REMOVE_CUSTOMERS_FINISH = "customerEpic/REMOVE_CUSTOMERS_SINGLE_FINISH";

export const RESET_CUSTOMER_IMPORT_DATA = "customerEpic/RESET_CUSTOMER_IMPORT_DATA";

export const RESET_CUSTOMER_IMPORT_VALIDATION = "customerEpic/RESET_CUSTOMER_IMPORT_VALIDATION";


