import React from 'react';

import {withRouter} from 'react-router-dom';

import GridItem from 'components/Grid/GridItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import SetProgressCard from './SetProgressCard';

import withStyles from '@material-ui/core/styles/withStyles';
import './style.scss';
import IconButton from "@material-ui/core/IconButton";
import EyeButton from "../../assets/img/buttons/eye.svg";
import CreateUser from "../CreateUser";
import ViewUser from "../ViewUser";
import {filterProgress} from "../../store/actions/progress";
import {connect} from "react-redux";
import {getUser} from "../../store/actions/usersData";

const BorderLinearProgress = withStyles({
  root: {
    height: 9,
    backgroundColor: '#d7e1f0',
    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#44c4ba',
  },
})(LinearProgress);

class ProgressPosition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: false,
      isEditUserOpen: false,
      isViewUserOpen: false
    };
  }

  handleSetProgressOpen = () => {
    this.setState({
      isCardOpen: true
    })
  };

  handleSetProgressClose = () => {
    this.setState({
      isCardOpen: !this.state.isCardOpen
    })
  };

  handleViewButton = () => {
    const {order} = this.props;
    const url = order.type === 0 ? `/admin/orders/edit-order/${order.id}` : `/admin/inquiries/edit-inquiry/${order.id}`;
    this.props.history.push({pathname: url, state: {'tabId': 1}})
  }

  handleShowViewUser = (id) => {
    this.props.getUser(id);
    this.setState({
      isViewUserOpen: true,
      userId: id,
    })
  };

  handleCloseViewUser = () => {
    this.setState({
      isViewUserOpen: false,
    })
  };

  handleShowEditUser = (id) => {
    if (id) {
      this.props.getUser(id);
    }
    this.setState({
      isEditUserOpen: true,
      isViewUserOpen: false,
    })
  };

  handleCloseEditUser = () => {
    this.setState({
      isEditUserOpen: false,
    })
  };

  render() {
    const {
      order,
      data,
      filters,
      user
    } = this.props;
    const {
      isCardOpen,
      isEditUserOpen,
      isViewUserOpen
    } = this.state;
    const coworker = data.coworker_id ? data.coworker : null;
    return (
      <GridItem className="progress-position">
        <GridItem className="pp-col-name">{data.name}</GridItem>
        <GridItem
          className="assignee-name pp-col-assignee"
          onClick={() => this.handleShowViewUser(coworker.user_id)}
        >
          {coworker && `${data.coworker.first_name} ${data.coworker.last_name}`}
        </GridItem>
        <GridItem className="progress-container pp-col-progress" onClick={() => this.handleSetProgressOpen()}>
          <BorderLinearProgress
            variant="determinate"
            value={data.progress}
          />
          {
            isCardOpen &&
            <SetProgressCard
              id={data.id}
              order={order}
              filters={filters}
              onCardClose={this.handleSetProgressClose}
              progress={data.progress}
            />
          }
        </GridItem>
        <GridItem className="pp-col-deadline">{data.due_date}</GridItem>
        <GridItem className="user-view-btn pp-col-actions">
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleViewButton()}
          >
            <img
              alt="users-view-btn"
              src={EyeButton}
            />
          </IconButton>
        </GridItem>
        {
          isEditUserOpen && Object.keys(user).length !== 0 &&
          <CreateUser
            isOpen={isEditUserOpen}
            onClose={this.handleCloseEditUser}
          />
        }
        {
          isViewUserOpen && Object.keys(user).length !== 0 &&
          <ViewUser
            isOpen={isViewUserOpen}
            onClose={this.handleCloseViewUser}
            onEditUser={this.handleShowEditUser}
            id={coworker.user_id}
          />
        }
      </GridItem>
    )
  }
}

const mapStateToProp = state => {
  return {
    user: state.users.user
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: (id) => dispatch(getUser(id)),
  }
};

export default withRouter(connect(
  mapStateToProp,
  mapDispatchToProps
)(ProgressPosition));
