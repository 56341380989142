import { toast } from 'react-toastify';
import {toastDuration} from 'Config';
import {
  CHECK_CURRENT_AUTH_STATUS,
  LOGIN,
  SET_LOGIN_ERRORS,
  RESET_LOGIN_ERRORS,
  AUTHORIZE,
  LOG_OUT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_FORGOT_PASSWORD_ERROR
} from '../actionTypes/auth';
import {
  REGISTER_MANUFACTURER,
  REGISTER_MANUFACTURER_FAILURE,
  REGISTER_MANUFACTURER_SUCCESS,
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS_FAILURE,
  GET_USER_OWN,
  GET_USER_OWN_SUCCESS,
  GET_USER_OWN_FAILURE,
  RESET_REGISTER_DATA
} from "../actionTypes/user";

import AuthAPIService from 'services/API/Auth';
import NotificationService from 'services/NotificationService';
import { getRoles } from './roles';
import dot from "dot-object";

export const checkCurrentAuthStatus = () => {
  return (dispatch, getState) => {
    const tokenExpires = localStorage.getItem('tokenExpires');
    const token = localStorage.getItem('authToken');
    let isAuthorized = token && new Date(tokenExpires).getTime() > Date.now();
    if (!isAuthorized) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('tokenExpires');
    }
    dispatch(authorize(isAuthorized));
    return {
      type: CHECK_CURRENT_AUTH_STATUS,
      payload: isAuthorized
    };
  };
};

export const login = credentials => {
  return async (dispatch, getState) => {
    let response;
    response = await AuthAPIService.login(credentials);
    let errors = {};
    if (response.ok) {
      const tokenExpires = new Date(
        Date.now() + response.response.expires_in * 1000
      );
      localStorage.setItem('authToken', response.response.access_token);
      localStorage.setItem('tokenExpires', tokenExpires.toString());

      dispatch(authorize(true));
      dispatch(getProfileDetails());
    } else if (response.status == 401) {
      errors.verification = response.response.message;
    } else if (response.status == 422) {
      if (response.response.errors.email) {
        errors.email = response.response.errors.email[0]
      }
      if (response.response.errors.password) {
        errors.password = response.response.errors.password[0]
      }

      if (response.response.error === 'Unauthorized') {
        toast.error(`Authorisation failed. Wrong credentials.`, {autoClose: toastDuration});
      }
    }

    dispatch(setLoginErrors(errors));

    dispatch({
      type: LOGIN
    });
  };
};

export const setLoginErrors = errors => {
  return { type: SET_LOGIN_ERRORS, payload: errors };
};

export const resetLoginErrors = fields => {
  return { type: RESET_LOGIN_ERRORS, payload: fields };
};

export const forgotPassword = email => {
  return {type: FORGOT_PASSWORD, payload: email}
};

export const forgotPasswordSuccess = data => {
  return {type: FORGOT_PASSWORD_SUCCESS, payload: data}
};

export const forgotPasswordFailure = error => {
  return {type: FORGOT_PASSWORD_ERROR, payload: error}
};

export const resetForgotPasswordError = () => {
  return {type: RESET_FORGOT_PASSWORD_ERROR}
};

export const authorize = (isAuthorized = false) => {
  return { type: AUTHORIZE, payload: isAuthorized };
};

export const logout = () => {
  AuthAPIService.logout();
  localStorage.removeItem('authToken');
  localStorage.removeItem('tokenExpires');
  localStorage.removeItem("authManufacturerLogo");
  return { type: LOG_OUT, payload: null };
};

export const registerManufacturer = (data) => {
  return {type: REGISTER_MANUFACTURER, payload: data}
};

export const registerManufacturerSuccess = (data) => {
  NotificationService.success(data.message);
  return {type: REGISTER_MANUFACTURER_SUCCESS}
};

export const registerManufacturerFailure = (error) => {
  toast.error(error.message, {autoClose: toastDuration});
  const errorData = dot.object(error.errors);
  return {type: REGISTER_MANUFACTURER_FAILURE, payload: errorData}
};

export const resetRegisterData = () => {
  return {type: RESET_REGISTER_DATA}
};

export const getProfileDetails = () => {
  return { type: GET_PROFILE_DETAILS };
};

export const getProfileDetailsSuccess = (data) => {
  return { type: GET_PROFILE_DETAILS_SUCCESS, payload: data };
};

export const getProfileDetailsFailure = (error) => {
  toast.error(error.message, {autoClose: toastDuration});
  return { type: GET_PROFILE_DETAILS_FAILURE, payload: error.errors };
};

export const getUserOwn = () => {
  return { type: GET_USER_OWN };
};

export const getUserOwnSuccess = (data) => {
  let isUserAdmin = false;
  data.roles.map(item => {if (!isUserAdmin) { item.name === 'admin' ? isUserAdmin = true : isUserAdmin = true}})
  return { type: GET_USER_OWN_SUCCESS, payload: {isAdmin: isUserAdmin, user: data} }
};

export const getUserOwnFailure = (error) => {
  return { type: GET_USER_OWN_FAILURE, payload: error };
};
