import {
  SET_CONTACTS_ROUTES
} from "../actionTypes/routesActionTypes";

import {store} from '../index';
import Contacts from "../../views/Contacts";

export const setContactsRoutes = (data) => {
  /*const contactsViews = [{
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    layout: '/admin'
  }];*/
  const contactsViews = [];
  data.map(item => contactsViews.push({
    path: `/${item.name.toLowerCase().split(' ').join('_')}`,
    id: item.id,
    name: item.name.toLowerCase(),  // use lower case for translation
    component: Contacts,
    layout: '/admin'
  }));
  let routes = [...store.getState().routes.routes];
  routes[2].views = contactsViews;
  return {type: SET_CONTACTS_ROUTES, payload: routes}
};
