import { handleActions } from 'redux-actions';
import {
  GET_LOGS_SUCCESS
} from '../actionTypes/logsActionTypes';

export const DEFAULT_LOGS_STATE = {
  logs: [],
  metadata: {}
};

export const logs = handleActions(
  {
    [GET_LOGS_SUCCESS]: (state, action) => ({
      ...state,
      logs: action.payload.data,
      metadata: action.payload.meta
    })
  },
  DEFAULT_LOGS_STATE
);
