import BaseAPIService from './Base';

export default class ManufacturerAPIService extends BaseAPIService {
  createManufacturerRequest = async (data) => {
    return await this.request('api/manufacturers/request', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  updateManufacturerRequest = async (id, data) => {
    return await this.request(`api/manufacturers/${id}/request`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  getManufacturers = async (searchTerms) => {
    return this.request(`api/manufacturers${searchTerms ? ('?search-term=' + searchTerms) : ''}`).then((data) => {
      return data;
    });
  };

  getManufacturer = async (id) => {
    return this.request(`/api/manufacturers/${id}`).then((data) => {
      return data;
    });
  };

  getManufacturerSettings = async (id) => {
    return this.request(`/api/manufacturer-settings/${id}`).then((data) => {
      return data;
    });
  };

  getManufacturerContacts = async () => {
    return this.request('api/manufacturers/contacts').then((data) => {
      return data;
    });
  };

  createManufacturer = async (data) => {
    return await this.request('api/manufacturers', {
      method: 'POST',
      body: data
    });
  };

  deleteManufacturer = async (id) => {
    return await this.request(`api/manufacturers/${id}`, {
      method: 'DELETE',
    });
  };

  updateManufacturer = async (id, data) => {
    return await this.request(`api/manufacturers/${id}`, {
      method: 'POST',
      body: data
    });
  };

  setManufacturerStatus = async (id, value) => {
    return await this.request(`api/manufacturers/${id}/status/${value}`, {
      method: 'PUT'
    });
  };

  filterManufacturers = async (filters, searchTerms) => {
    let filterCity = [];
    if (filters.city.length > 0 && filters.city[0] != -1) {
      for (let i in filters.city) {
        filterCity.push(`filter[city.id][${i}]=${filters.city[i]}`);
      }
    }
    const filterCityPart = filterCity.length > 0 ? `&${filterCity.join("&")}` : '';
    let filterDeliveryType = [];
    if (filters.deliveryType.length > 0 && filters.deliveryType[0] != -1) {
      for (let i in filters.deliveryType) {
        filterDeliveryType.push(`filter[${filters.deliveryType[i]}][${i}]=1`);
      }
    }
    const filterDeliveryTypePart = filterDeliveryType.length > 0 ? `&${filterDeliveryType.join("&")}` : '';
    let url = `?${filters.page && filters.page !== '' ? `page=${filters.page}` : ''}${searchTerms ? ('&search-term=' + searchTerms) : ''}${filters.order && filters.order !== '' ? `&order=${filters.orderDir ? '' : '-'}${filters.order}` : ''}${filters.status && filters.status !== '' ? `&filter[status]=${filters.status}` : ''}${filterCityPart}${filterDeliveryTypePart}`;
    return this.request(`api/manufacturers${url}`).then((data) => {
      return data;
    });
  };
}
