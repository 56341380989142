/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { withTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
import Checkbox from 'components/StyledCheckbox';
// core components
import InputWithIcon from "../InputWithIcon";

import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx';

import { findPropInContactCategoriesHierarchyList, flattenContactCategoriesHierarchyList } from '../../utils/utils';

import './style.scss';

class CustomMultiselectHierarchical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      inputText: '',
      highlightedProp: null,
    };
  }

  componentDidMount() {
    const { dropdownList } = this.props;
    this.setState({
      highlightedProp: (dropdownList && dropdownList.length > 0) ? dropdownList[0] : null,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.inputText !== this.state.inputText && this.state.inputText) {
      const { dropdownList } = this.props;
      const { inputText, highlightedProp, open } = this.state;
      const filteredDropdownList = this.filterDropdownList(dropdownList, inputText);
      const updateProps = {};
      if (!highlightedProp && filteredDropdownList.length > 0) {
        updateProps.highlightedProp = filteredDropdownList[0];
      } else {
        const flattenedFilteredDropdownList = flattenContactCategoriesHierarchyList(filteredDropdownList);
        if (highlightedProp && !flattenedFilteredDropdownList.find(el => el.id === highlightedProp.id)) {
          updateProps.highlightedProp = filteredDropdownList[0];
        }
      }
      if (!open)
        updateProps.open = true;
      if (Object.keys(updateProps).length > 0) {
        this.setState(updateProps);
      }
    }
  }

  handleInputFocus = () => {
    this.handleClick();
  }

  handleClick = () => {
    const { open } = this.state;
    if (!open) {
      this.setState({
        inputText: '',
        open: true
      });
    }
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({
      open: false,
      inputText: '',
    });
  };

  generateSelectedValuesPreview = () => {
    const { dropdownList, buttonText, translateValues, t } = this.props;
    const selectedElements = flattenContactCategoriesHierarchyList(dropdownList).filter(el => buttonText.indexOf(el.id) !== -1).map(el => el.value);
    if (selectedElements.length == 1) {
      return translateValues ? t(selectedElements[0].toLowerCase()) : selectedElements[0];
    } else if (selectedElements.length >= 2) {
      return `${translateValues ? t(selectedElements[0].toLowerCase()) : selectedElements[0]}, ${translateValues ? t(selectedElements[1].toLowerCase()) : selectedElements[1]}${selectedElements.length > 2 ? ', ...' : ''}`;
    } else {
      return '';
    }
  }

  filterDropdownList = (dropdownList, textFilter) => {
    const filterList = (list, filterText) => {
      if (!list)
        return null;
      const newList = [];
      for (let i in list) {
        const element = list[i];
        if (element.children) {
          element.children = filterList(element.children, filterText);
        }
        if (element.value.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
          (element.children && element.children.length > 0)) {
          newList.push(element);
        }
      }
      return newList;
    };
    const dropdownListClone = JSON.parse(JSON.stringify(dropdownList));
    return filterList(dropdownListClone, textFilter);
  }

  handleSelectionChanged = (prop) => {
    const { dropdownList, buttonText, onChange } = this.props;
    const traceElementParents = (list, needle, trace) => {
      for (let i in list) {
        if (list[i].id === needle) {
          return trace;
        } else if (list[i].children) {
          const subtreeTrace = traceElementParents(list[i].children, needle, [...trace, list[i].id]);
          if (subtreeTrace.length > 0)
            return subtreeTrace;
        }
      }
      return [];
    };
    const listElementChildren = (element) => {
      if (element.children) {
        let result = element.children.map(c => c.id);
        for (let i in element.children) {
          const child = element.children[i];
          const childDescendants = listElementChildren(child);
          result = [...result, ...childDescendants];
        }
        return result;
      } else {
        return [];
      }
    };
    if (onChange) {
      let newButtonText = [...buttonText];
      const index = buttonText.indexOf(prop.id);
      if (index === -1) {
        // make sure that every antecedent is now selected
        newButtonText.push(prop.id);
        const toAdd = traceElementParents(dropdownList, prop.id, []);
        toAdd.forEach(el => {
          if (newButtonText.indexOf(el) === -1)
            newButtonText.push(el);
        });
        newButtonText.sort();
        onChange(newButtonText);
      } else {
        // deselect every descendent
        newButtonText.splice(index, 1);
        const toRemove = listElementChildren(prop);
        newButtonText = newButtonText.filter(el => toRemove.indexOf(el) === -1);
        onChange(newButtonText);
      }
    }
  }

  handleInputChange = (e) => {
    this.setState({
      inputText: e.target.value,
    });
  }

  scrollHighlightedMenuItemIntoView = () => {
    let selectedElements = document.getElementsByClassName("ikt-ap_custom-dropdown-item-highlighted");
    if (selectedElements && selectedElements[0])
      selectedElements[0].scrollIntoView({ block: "nearest" });
  }

  handleKeyDown = event => {
    const { dropdownList } = this.props;
    const { open, highlightedProp, inputText } = this.state;
    
    if (open) {
      if (event.keyCode == 40) {  // down arrow
        const flatList = flattenContactCategoriesHierarchyList(
          this.filterDropdownList(dropdownList, inputText));
        if (highlightedProp) {
          const index = flatList.findIndex(el => el.id == highlightedProp.id);
          if (index + 1 < flatList.length) {
            this.setState({
              highlightedProp: findPropInContactCategoriesHierarchyList(dropdownList, flatList[index + 1].id),
            }, () => {
              this.scrollHighlightedMenuItemIntoView();
            });
          }
        } else {
          if (flatList.length > 0) {
            this.setState({
              highlightedProp: dropdownList[0],
            }, () => {
              this.scrollHighlightedMenuItemIntoView();
            });
          }
        }
      } else if (event.keyCode == 38) {  // up arrow
        const flatList = flattenContactCategoriesHierarchyList(
          this.filterDropdownList(dropdownList, inputText));
        if (highlightedProp) {
          const index = flatList.findIndex(el => el.id == highlightedProp.id);
          if (index - 1 >= 0) {
            this.setState({
              highlightedProp: findPropInContactCategoriesHierarchyList(dropdownList, flatList[index - 1].id),
            }, () => {
              this.scrollHighlightedMenuItemIntoView();
            });
          }
        } else {
          if (flatList.length > 0) {
            this.setState({
              highlightedProp: findPropInContactCategoriesHierarchyList(dropdownList, flatList[flatList.length - 1].id),
            }, () => {
              this.scrollHighlightedMenuItemIntoView();
            });
          }
        }
      } else if (event.keyCode == 13 || event.keyCode == 32) {  // enter or space
        event.preventDefault();
        if (highlightedProp) {
          this.handleSelectionChanged(highlightedProp);
        }
      } else if (event.keyCode == 27) {  // escape
        this.setState({
          open: false,
        });
        if (this.anchorEl)
          this.anchorEl.blur();
      }
    }
  }

  handleMouseItemEnter = (event, prop) => {
    this.setState({
      highlightedProp: prop,
    });
  }

  renderDropdownListElement = (prop, baseKeyOffset, nestingLevel, classes, dropdownItem) => {
    const { buttonText, t, translateValues } = this.props;
    const { highlightedProp } = this.state;
    const result = {
      html: null,
      adjustedKeyOffset: null
    };
    const isHighlighted = highlightedProp && prop.id == highlightedProp.id;
    const isSelected = buttonText.indexOf(prop.id) !== -1;

    if (prop.divider) {
      result.html = (
        <Divider
          key={baseKeyOffset}
          onClick={() => this.handleClose()}
          className={classes.dropdownDividerItem}
        />
      );
      result.adjustedKeyOffset = baseKeyOffset + 1;
    } else {
      let childrenHtml = null;
      let keyOffset = baseKeyOffset + 1;
      if (prop.children) {
        childrenHtml = prop.children.map(child => {
          const childResult = this.renderDropdownListElement(child, keyOffset, nestingLevel + 1, classes, dropdownItem);
          keyOffset = childResult.adjustedKeyOffset;
          return childResult.html;
        });
      }
      const menuItem = (
        <MenuItem
          key={baseKeyOffset}
          onMouseEnter={(event) => this.handleMouseItemEnter(event, prop)}
          onClick={() => this.handleSelectionChanged(prop)}
          className={`${dropdownItem} mh-menu-item${
            nestingLevel > 0 ? ` mh-menu-item-l${nestingLevel}` : ''
          }${
            isHighlighted ? ` ikt-ap_custom-dropdown-item-highlighted` : ``
          }`}
        >
          <span className='checkbox-container'>
            <Checkbox
              checked={isSelected}
              onChange={e => { } /* handled by onClick on MenuItem */}
              color="primary"
            />
          </span>
          <span className='text-container'>{ translateValues ? t(prop.value.toLowerCase()) : prop.value}</span>
        </MenuItem>
      );
      result.html = childrenHtml ? [menuItem, ...childrenHtml] : menuItem;
      result.adjustedKeyOffset = keyOffset;
    }

    return result;
  }

  render() {
    const { open, inputText } = this.state;
    const {
      classes,
      className,
      buttonText,
      dropdownList,
      buttonProps,
      dropup,
      dropdownHeader,
      hoverColor,
      dropPlacement,
      id,
      rtlActive,
      noLiPadding,
      innerDropDown,
      navDropdown,
      error,
      errorMsg,
      placeholder,
      discardIcon,
      key,
      disableTabFocus,
      t
    } = this.props;
    const caretClasses = classNames({
      [classes.caret]: true,
      [classes.caretDropup]: dropup && !open,
      [classes.caretActive]: open && !dropup,
      [classes.caretRTL]: rtlActive
    });
    const dropdownItem = classNames({
      [classes.dropdownItem]: true,
      [classes[hoverColor + 'Hover']]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive
    });

    const filteredDropdownList = (inputText && inputText.length > 0) ? this.filterDropdownList(dropdownList, inputText) : dropdownList;
    let keyOffset = 0;
    const dropDownMenu = (
      <MenuList role="menu" className={classes.menuList}>
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => this.handleClose()}
            className={classes.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {filteredDropdownList.length > 0 ? filteredDropdownList.map(prop => {
            const { html, adjustedKeyOffset } = this.renderDropdownListElement(prop, keyOffset, 0, classes, dropdownItem);
            keyOffset = adjustedKeyOffset;
            return html;
          }) :
          <MenuItem
            className={`${dropdownItem} mh-menu-item`}
          >
            { t('no_items') }
          </MenuItem>
        }
      </MenuList>
    );

    let buttonToggleClasses = `${buttonProps.className} ikt-ap_custom-dropdown-toggle-btn ${error ? 'dropdown-error' : ''}`;
    let inputProps = {
      autoComplete: 'off'
    };
    if (disableTabFocus) {
      inputProps["tabindex"]="-1";
    }

    return (
      <div
        className={`ikt-ap_custom-hierarchical-multiselect dropdown-blue ${className} ${
          innerDropDown ? classes.innerManager : classes.manager
        }`}
        key={key}
        onKeyDown={this.handleKeyDown}
      >
        <div onClick={!buttonProps.disabled && this.handleClick} onFocus={!buttonProps.disabled && this.handleInputFocus} className={buttonText !== undefined ? '' : classes.target}>
          <InputWithIcon
            aria-label="Notifications"
            aria-owns={open ? 'menu-list' : null}
            placeholder={placeholder ? placeholder : t('start_typing') + '...'}
            id={id}
            inputRef={node => {
              this.anchorEl = node;
            }}
            disabled={buttonProps.disabled}
            className={buttonToggleClasses}
            value={open ? inputText : this.generateSelectedValuesPreview() }
            onChange={this.handleInputChange}
            inputProps={inputProps}
          />
          {errorMsg && <span className="ikt-ap_input-error-msg">{errorMsg}</span>}
        </div>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true,
            [classes.popperNav]: open && navDropdown
          })}
        >
          {() => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: '0 100% 0' }
                  : { transformOrigin: '0 0 0' }
              }
            >
              <Paper
                className={`${classes.dropdown} ikt-ap_custom-dropdown-menu ${!discardIcon ? 'ikt-ap_custom-dropdown-menu-icon' : 'ikt-ap_custom-dropdown-menu-no-icon'}`}
              >
                {innerDropDown ? (
                  dropDownMenu
                ) : (
                  <ClickAwayListener onClickAway={this.handleClose}>
                    {dropDownMenu}
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CustomMultiselectHierarchical.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: 'primary'
};

CustomMultiselectHierarchical.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  className: PropTypes.string,
  buttonText: PropTypes.array,
  buttonIcon: PropTypes.object,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  id: PropTypes.string,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    'bottom',
    'top',
    'right',
    'left',
    'bottom-start',
    'bottom-end',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end'
  ]),
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // This is a function that returns the clicked menu item
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  discardIcon: PropTypes.bool,
  disableTabFocus: PropTypes.bool,
};

export default withStyles(customDropdownStyle)(withTranslation()(CustomMultiselectHierarchical));
