import React from "react";

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ErrorTooltip, ErrorTooltipAlt } from '../../components/ErrorTooltip/ErrorTooltip';

import './style.scss';

import Button from '../../components/CustomButtons/Button';
import DecimalInput from 'components/DecimalInput'
import Dropdown from "../../components/CustomDropdown/CustomDropdown";

import CloseButton from "../../assets/img/buttons/delete.svg";

import { resetMaterialData, updateMaterialCategoryValues, updateMultipleMaterialsValues } from "../../store/actions/materials";
import WarningDialog from '../WarningDialog';
import CloseDialog from '../CloseDialog';

class UpdateMaterialCategory extends React.Component {
  static defaultState = {
    category_id: null,
    discount: '',
    surcharges: '',
    waste: '',

    lastEdit: null,

    lastProcessedErrors: null,
    errors: {
      category_id: null,
      discount: null,
      surcharges: null,
      waste: null,
    },

    isCloseDialogOpen: false,
    isWarningDialogOpen: false,
    haveEdits: false,
  };

  constructor(props) {
    super(props);

    this.state = UpdateMaterialCategory.defaultState;
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};
    if (props.requestErrors && props.requestErrors != state.lastProcessedErrors) {
      const errorsString = props.requestErrors.toString();
      const needle = "Response body: ";
      const position = errorsString.indexOf(needle);
      if (position != -1) {
        const substring = errorsString.substring(position + needle.length);
        try {
          const errorsObj = JSON.parse(substring);
          if(errorsObj.errors) {
            const errors = {
              category_id: errorsObj.errors.category_id,
              discount: errorsObj.errors['discount_percentage'],
              surcharges: errorsObj.errors['surcharge_percentage'],
              waste: errorsObj.errors['waste_percentage'],
            };
            newState = {
              ...newState,
              errors,
              lastProcessedErrors: props.requestErrors,
            };
          }
        } catch(e) {
        }
      }
    }
    return newState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isMultipleMaterialsUpdateValid && this.props.isMultipleMaterialsUpdateValid ) {
      this.handleOnClose();
    }
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  handleOnClose = () => {
    // reset the state
    this.setState(UpdateMaterialCategory.defaultState);
    this.props.resetData();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleOnClose();
    }
  }

  handleOnWarningPromptCancel = () => {
    this.setState({
      isWarningDialogOpen: false,
    });
  }

  handleOnWarningPromptConfirm = () => {
    this.setState({
      isWarningDialogOpen: false,
    }, () => {
      this.handleUpdate();
    });
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleOnClose();
    });
  }

  handleInputValueChange = (name, value) => {
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: false,
      },
      haveEdits: true,
    });
  }

  handleUpdate = () => {
    const { byCategory, selectedMaterials } = this.props;
    const { category_id, discount, surcharges, waste } = this.state;
    const data = {
      discount_percentage: parseFloat(discount),
      surcharge_percentage: parseFloat(surcharges),
      waste_percentage: parseFloat(waste),
    }
    if (byCategory)
      this.props.updateMaterialCategoryValues(category_id, data, { page: this.props.currentPage, filters: this.props.currentFilters, searchTerms: this.props.currentSearchTerms });
    else
      this.props.updateMultipleMaterialsValues(selectedMaterials, data, { page: this.props.currentPage, filters: this.props.currentFilters, searchTerms: this.props.currentSearchTerms });
  }

  handleUpdateButton = () => {
    const { byCategory, t } = this.props;
    const { category_id, errors } = this.state;
    if (byCategory && !category_id) {
      this.setState({
        errors: {
          ...errors,
          category_id: [ t('select_value') ],
        }
      });
      return;
    }
    this.setState({
      isWarningDialogOpen: !this.state.isWarningDialogOpen,
    });
  }

  render() {
    const { isOpen, onClose, byCategory, t } = this.props;
    const categoriesArray = this.props.categories.map(cat => ({ id: cat.id, value: cat.name }));
    const selectedCategoryId = this.state.category_id ? this.state.category_id : "";

    const errors = this.state.errors;

    const sanitizeTooltipErrorTitle = error => (error ? error[0] : "");

    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'update-material-category-dialog',
          }
        }}>
        <MuiDialogTitle className="update-material-category-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ byCategory ? t('update_material_category') : t('update_selected_materials')}</Typography>
          <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
          <img
            alt="update-material-category-close-btn"
            src={CloseButton}
          />
          </IconButton>
        </MuiDialogTitle>
        <div className="update-material-category-content">
          <div className="update-material-category-info">
            { byCategory && <div className="info-section">
              <span className="info-title">{ t('category') }</span>
              <ErrorTooltip title={sanitizeTooltipErrorTitle(errors.category_id)}>
                <div className="category-dropdown-container">
                  <Dropdown
                    buttonText={ selectedCategoryId }
                    buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                    dropdownList={[{id:'', value: t('none')},...categoriesArray]}
                    hoverColor="dark"
                    onClick={item => this.handleInputValueChange('category_id', item.id)}
                    error={errors.category_id}
                  />
                </div>
              </ErrorTooltip>
            </div> }
            <div className="info-section">
              <span className="info-title">{ t('discount') }</span>
              <ErrorTooltip title={sanitizeTooltipErrorTitle(errors.discount)}>
                <div className="text-input">
                  <DecimalInput
                    value={ this.state.discount }
                    adornment="%"
                    onChange={value => this.handleInputValueChange('discount', value)}
                    error={errors.discount}
                  />
                </div>
              </ErrorTooltip>
            </div>
            <div className="info-section">
              <span className="info-title">{ t('surcharges') }</span>
              <ErrorTooltip title={sanitizeTooltipErrorTitle(errors.surcharges)}>
                <div className="text-input">
                  <DecimalInput
                    value={ this.state.surcharges }
                    adornment="%"
                    onChange={value => this.handleInputValueChange('surcharges', value)}
                    error={errors.surcharges}
                  />
                </div>
              </ErrorTooltip>
            </div>
            <div className="info-section">
              <span className="info-title">{ t('waste') }</span>
              <ErrorTooltip title={sanitizeTooltipErrorTitle(errors.waste)}>
                <div className="text-input">
                  <DecimalInput
                    value={ this.state.waste }
                    adornment="%"
                    onChange={value => this.handleInputValueChange('waste', value)}
                    error={errors.waste}
                  />
                </div>
              </ErrorTooltip>
            </div>
          </div>
        </div>
        <div className="update-material-category-btn-container">
          <Button
            className="cancel-btn"
            onClick={ this.handleOnClose }
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleUpdateButton}
            disabled={this.props.updateInProgress}
          >
            { t('update').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={byCategory ? t('update_material_category') : t('update_selected_materials')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
        <WarningDialog
          isOpen={this.state.isWarningDialogOpen}
          title={byCategory ? t('confirm_update_material_category') : t('confirm_update_selected_materials')}
          text={byCategory ? t('update_material_category_confirmation') : t('update_selected_materials_confirmation')}
          buttonText={ t('update').toUpperCase() }
          onCancel={this.handleOnWarningPromptCancel}
          onConfirm={this.handleOnWarningPromptConfirm}
        />
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    updateInProgress: state.materials.materialsMutBusy,
    requestErrors: state.materials.materialsError,
    isMultipleMaterialsUpdateValid: state.materials.isMultipleMaterialsUpdateValid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(resetMaterialData()),
    updateMaterialCategoryValues: (id, data, loadListInfo) => dispatch(updateMaterialCategoryValues(id, data, loadListInfo)),
    updateMultipleMaterialsValues: (ids, data, loadListInfo) => dispatch(updateMultipleMaterialsValues(ids, data, loadListInfo)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(UpdateMaterialCategory));
