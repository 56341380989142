import React from "react";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseButton from "../../assets/img/buttons/delete.svg";

import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';

import './style.scss';

class ViewContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="view-notification-title" disableTypography>
          <Typography variant="h6" className="dialog-title">View Notification</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="close-notification-view-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="view-notification-content">
          <div className="notification-title-container">
            <GridItem xs={6}>
              <div className="notification-name">Please log your tim…</div>
            </GridItem>
            <GridItem xs={6}>
              <div className="view-notification-btn">
                <Button
                  className="cancel-btn"
                >
                  DELETE
                </Button>
                <Button
                  className="create-btn"
                >
                  EDIT
                </Button>
              </div>
            </GridItem>
          </div>
          <div className="notification-data-container">
            <div className="notification-info-field">
              <div className="notification-info-item">
                <GridItem xs={4} className="notification-info-title">Frequency</GridItem>
                <GridItem xs={8}>At an Event</GridItem>
              </div>
              <div className="notification-info-item">
                <GridItem xs={4} className="notification-info-title">Recipient</GridItem>
                <GridItem xs={8}>Software Service Providers (Only with pending projects), Staff, Manufacturers, Employees</GridItem>
              </div>
              <div className="notification-info-item">
                <GridItem xs={4} className="notification-info-title">Email Text</GridItem>
                <GridItem xs={8}>Lorem Ipsum</GridItem>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ViewContact
