import {store} from 'store';
import {logout} from '../../store/actions/auth'
import {config} from '../../Config';
import { APIError, FetchError } from './Errors';
import { toast } from 'react-toastify';

export default class BaseAPIService {
    request = async (url, options) => {
        const fetchOptions = options || { method: 'GET' };

        const headers = (options && options.body instanceof FormData)
          ? {
              'Accept': 'application/json'
            }
          : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
        ;

        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            headers.Authorization = `Bearer ${authToken}`;
        }


        fetchOptions.headers = {
            ...headers,
            ...fetchOptions.headers
        };

        let response;

        try {
            response = await fetch(config.apiBaseUrl + url, fetchOptions);
        } catch (error) {
            throw new FetchError(error.message);
        }

        if (response.status === 401) {
            store.dispatch(logout());
        }

        if (!response.ok) {
            let data = null;
            if (response.body) {
                try {
                    data = await response.json();
                } catch (error) {
                    toast.error('Failed to get JSON from the response body!');
                }
            }
            throw new APIError(response, data);
        }

        return response.json();
    }

    requestFile = async (url, options) => {
        const fetchOptions = options || { method: 'GET' };

        const headers = {};

        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            headers.Authorization = `Bearer ${authToken}`;
        }

        fetchOptions.headers = {
            ...headers,
            ...fetchOptions.headers
        };

        let response;

        try {
            response = await fetch(config.apiBaseUrl + url, fetchOptions);
        } catch (error) {
            throw new FetchError(error.message);
        }

        if (response.status === 401) {
            store.dispatch(logout());
        }

        if (!response.ok) {
            let data = null;
            if (response.body) {
                try {
                    data = await response.json();
                } catch (error) {
                    toast.error('Failed to get JSON from the response body!');
                }
            }
            throw new APIError(response, data);
        }

        return response.blob();
    }
}
