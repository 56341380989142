import React from "react";
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CloseButton from "assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';
import TextArea from "../../components/CustomTextArea";

class EditDescriptionDialog extends React.Component {
  textareaRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      textareaSelectionUpdated: false,
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.textareaSelectionUpdated && this.textareaRef.current) {
      const textareaNode = this.textareaRef.current;
      textareaNode.setSelectionRange(textareaNode.value.length, textareaNode.value.length);
      this.setState({
        textareaSelectionUpdated: true,
      });
    }
  }

  handleValueChange = (e) => {
    this.setState({
      value: e.target.value
    })
  }

  handleSaveBtn = () => {
    const {onClose, onChange} = this.props;
    const {value} = this.state;
    onChange(value);
    onClose();
  }

  render() {
    const {
      isOpen,
      onClose,
      onChange,
      t
    } = this.props;
    const {value} = this.state;
    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'edit-description-dialog',
          }
        }}>
        <MuiDialogTitle className="edit-description-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ t('edit_description') }</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="edit-description-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="edit-description-content">
          <div className="description-area">
            <span>{ t('description') }</span>
            <div className="description-test-area">
              <TextArea
                autofocus={true}
                ref={this.textareaRef}
                onChange={e => this.handleValueChange(e)}
                value={value}
              />
            </div>
          </div>
        </div>
        <div className="edit-description-btn-container">
          <Button
            className="cancel-btn"
            onClick={onClose}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleSaveBtn}
          >
            { t('save').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(EditDescriptionDialog);
