export const GET_ALL_MATERIALS = 'materialProvidersEpic/GET_ALL_MATERIALS';
export const GET_ALL_MATERIALS_SUCCESS = 'materialProvidersEpic/GET_ALL_MATERIALS_SUCCESS';
export const GET_ALL_MATERIALS_ERROR = 'materialProvidersEpic/GET_ALL_MATERIALS_ERROR';

export const GET_MAIN_MATERIALS_LIST = 'materialProvidersEpic/GET_MAIN_MATERIALS_LIST';
export const GET_MAIN_MATERIALS_LIST_SUCCESS = 'materialProvidersEpic/GET_MAIN_MATERIALS_LIST_SUCCESS';
export const GET_MAIN_MATERIALS_LIST_ERROR = 'materialProvidersEpic/GET_MAIN_MATERIALS_LIST_ERROR';

export const GET_POSITION_MATERIALS_LIST = 'materialProvidersEpic/GET_POSITION_MATERIALS_LIST';
export const GET_POSITION_MATERIALS_LIST_SUCCESS = 'materialProvidersEpic/GET_POSITION_MATERIALS_LIST_SUCCESS';
export const GET_POSITION_MATERIALS_LIST_ERROR = 'materialProvidersEpic/GET_POSITION_MATERIALS_LIST_ERROR';

export const GET_FITTINGS_MATERIALS_LIST = 'materialProvidersEpic/GET_FITTINGS_MATERIALS_LIST';
export const GET_FITTINGS_MATERIALS_LIST_SUCCESS = 'materialProvidersEpic/GET_FITTINGS_MATERIALS_LIST_SUCCESS';
export const GET_FITTINGS_MATERIALS_LIST_ERROR = 'materialProvidersEpic/GET_FITTINGS_MATERIALS_LIST_ERROR';

export const GET_COVERING_MATERIALS_LIST = 'materialProvidersEpic/GET_COVERING_MATERIALS_LIST';
export const GET_COVERING_MATERIALS_LIST_SUCCESS = 'materialProvidersEpic/GET_COVERING_MATERIALS_LIST_SUCCESS';
export const GET_COVERING_MATERIALS_LIST_ERROR = 'materialProvidersEpic/GET_COVERING_MATERIALS_LIST_ERROR';

export const GET_EDGE_MATERIALS_LIST = 'materialProvidersEpic/GET_EDGE_MATERIALS_LIST';
export const GET_EDGE_MATERIALS_LIST_SUCCESS = 'materialProvidersEpic/GET_EDGE_MATERIALS_LIST_SUCCESS';
export const GET_EDGE_MATERIALS_LIST_ERROR = 'materialProvidersEpic/GET_EDGE_MATERIALS_LIST_ERROR';

export const GET_SURFACE_MATERIALS_LIST = 'materialProvidersEpic/GET_SURFACE_MATERIALS_LIST';
export const GET_SURFACE_MATERIALS_LIST_SUCCESS = 'materialProvidersEpic/GET_SURFACE_MATERIALS_LIST_SUCCESS';
export const GET_SURFACE_MATERIALS_LIST_ERROR = 'materialProvidersEpic/GET_SURFACE_MATERIALS_LIST_ERROR';

export const GET_SELECTED_MATERIALS = 'materialProvidersEpic/GET_SELECTED_MATERIALS';
export const GET_SELECTED_MATERIALS_SUCCESS = 'materialProvidersEpic/GET_SELECTED_MATERIALS_SUCCESS';
export const GET_SELECTED_MATERIALS_ERROR = 'materialProvidersEpic/GET_SELECTED_MATERIALS_ERROR';
