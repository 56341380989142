import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {manufacturerOrderAPI} from 'services/API';
import {
  addManufacturerOrderSuccess,
  addManufacturerOrderFailure,
  updateManufacturerOrderSuccess,
  updateManufacturerOrderFailure,
  getManufacturerOrderSuccess,
  getManufacturerOrderFailure,
  deleteManufacturerOrderSuccess,
  deleteManufacturerOrderFailure,
  getManufacturerOrdersSuccess,
  getManufacturerOrdersFailure
} from '../store/actions/manufacturerOrder';
import {
  ADD_MANUFACTURER_ORDER,
  UPDATE_MANUFACTURER_ORDER,
  GET_MANUFACTURER_ORDER,
  GET_MANUFACTURER_ORDERS,
  DELETE_MANUFACTURER_ORDER,
} from '../store/actionTypes/manufacturerOrderActionTypes';

export const getManufacturerOrderEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURER_ORDER),
  switchMap(({payload}) => from(manufacturerOrderAPI.getManufacturerOrder(payload)).pipe(
    switchMap((response) => {
      return of(getManufacturerOrderSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturerOrderFailure(err))
      }
    )
  ))
);

export const getManufacturerOrdersEpic = (actions$) => actions$.pipe(
  ofType(GET_MANUFACTURER_ORDERS),
  switchMap(({payload}) => from(manufacturerOrderAPI.getManufacturerOrders(payload)).pipe(
    switchMap((response) => {
      return of(getManufacturerOrdersSuccess(response.data))
    }),
    catchError( err => {
        return of(getManufacturerOrdersFailure(err))
      }
    )
  ))
);

export const addManufacturerOrderEpic = (actions$) => actions$.pipe(
  ofType(ADD_MANUFACTURER_ORDER),
  switchMap(({payload}) => from(manufacturerOrderAPI.createManufacturerOrder(payload)).pipe(
    switchMap((response) => {
      return of(addManufacturerOrderSuccess(response))
    }),
    catchError( err => {
        return of(addManufacturerOrderFailure(err))
      }
    )
  ))
);

export const updateManufacturerOrderEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_MANUFACTURER_ORDER),
  switchMap(({payload}) => from(manufacturerOrderAPI.updateManufacturerOrder(payload.id, payload.data)).pipe(
    switchMap((response) => {
      return of(updateManufacturerOrderSuccess(response))
    }),
    catchError( err => {
        return of(updateManufacturerOrderFailure(err))
      }
    )
  ))
);

export const removeManufacturerOrderEpic = (actions$) => actions$.pipe(
  ofType(DELETE_MANUFACTURER_ORDER),
  switchMap(({payload}) => from(manufacturerOrderAPI.deleteManufacturerOrder(payload.id)).pipe(
    switchMap((response) => {
      return of(deleteManufacturerOrderSuccess(response))
    }),
    catchError( err => {
        return of(deleteManufacturerOrderFailure(err))
      }
    )
  ))
);

