import BaseAPIService from './Base';

export default class MaterialsAPIService extends BaseAPIService {
  addMaterial = async data => {
    return this.request('api/materials', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  duplicateMaterial = async (id) => {
    return await this.request(`api/materials/${id}/duplicate`, {
      method: 'PUT',
    });
  };

  removeMaterial = async (id, purge) => {
    return this.request(!purge ? `api/materials/${id}` : `api/recycle-bin/materials/${id}/purge`, {
      method: 'DELETE'
    });
  };

  restoreMaterial = async id => {
    return this.request(`api/recycle-bin/materials/${id}/restore`, {
      method: 'PUT'
    });
  };

  addCompoundMaterial = async data => {
    return this.request('api/bundles', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  removeCompoundMaterial = async id => {
    return this.request(`api/bundles/${id}`, {
      method: 'DELETE'
    });
  };

  updateMaterial = async (id, data) => {
    return this.request(`api/materials/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  addMaterialRequest = async data => {
    return this.request('api/materials/request', {
      method: 'POST',
      body: data
    });
  };

  updateMaterialRequest = async (id, data) => {
    data.append('_method', 'PUT');
    return this.request(`api/materials/${id}/request`, {
      method: 'POST',
      body: data
    });
  };

  getMaterial = async id => {
    return this.request(`api/materials/${id}`).then(data => {
      return data;
    });
  };

  getMaterials = async (page, filters, searchTerms) => {
    let filterData = '';
    for (var key in filters) {
      if (key == 'category') {
        let filterCategory = [];
        if (filters[key].length > 0 && filters[key][0] != -1) {
          for (let i in filters[key]) {
            filterCategory.push(`filter[category_id][${i}]=${filters[key][i]}`);
          }
        }
        const filterCategoryPart = filterCategory.length > 0 ? `&${filterCategory.join("&")}` : '';
        filterData += filterCategoryPart;
      } else if (key == 'sortBy' || key == 'sortByOrder' || key == 'active') {
        continue;
      } else {
        if (filters[key] || filters[key] === 0) {
          filterData += `&filter[${key}]=${filters[key]}`;
        }
      }
    }
    let sortByData = filters.sortBy ? `&order=${filters.sortByOrder ? '' : '-'}${filters.sortBy}` : '';
    const searchTermsString = searchTerms ? `&search-term=${searchTerms}` : '';
    const requestUrl = filters.active ? `api/materials/own?page=${page}${filterData}${sortByData}${searchTermsString}` : `api/recycle-bin/materials?page=${page}${filterData}${sortByData}${searchTermsString}`;
    return this.request(requestUrl).then(data => {
      return data;
    });
  };

  getMaterialsInBundle = async id => {
    return this.request(`api/bundles/${id}/materials`).then(data => {
      return data;
    });
  };

  updateCompoundMaterialBase = async (id, data) => {
    return this.request(`api/bundles/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  updateCompoundMaterial = async (id, data) => {
    data.append("_method", "PUT");
    return this.request(`api/bundles/${id}`, {
      method: 'POST',
      body: data,
    });
  };

  approveMaterial = async id => {
    return this.request(`api/approvals/${id}/approve`);
  };

  setMaterialAvailability = async (id, value) => {
    return await this.request(`api/materials/${id}/in-stock/${value}`, {
      method: 'PUT'
    });
  };

  startImportMaterialFile = async data => {
    return await this.request('api/import/materials/start', {
      method: 'POST',
      body: data
    });
  };

  downloadMaterialFileImportSkipped = async data => {
    return this.requestFile(`api/materials/skipped-materials/export/${data}`);
  }

  updateMaterialCategoryValues = async (id, data) => {
    return this.request(`api/materials/batch/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  updateMultipleMaterialsValues = async (ids, data) => {
    return this.request(`api/materials/batch-by-ids/${ids.join(",")}`, {
      method: 'PUT',
      body: JSON.stringify({
        material_ids: ids,
        ...data,
      }),
    });
  };

  updateMultipleMaterialsPrice = async (ids, data) => {
    return this.request(`api/materials/batch-update-prices/${ids.join(',')}`, {
      method: 'PUT',
      body: JSON.stringify({
        percentage: data,
      }),
    });
  };
}
