import { handleActions } from 'redux-actions';
import {
  GET_MATERIAL_CATEGORIES_PARENTS_SUCCESS,
  GET_MATERIAL_CATEGORIES,
  GET_MATERIAL_CATEGORIES_SUCCESS,
  GET_MATERIAL_CATEGORIES_ERROR,
  GET_MATERIAL_CATEGORY,
  GET_MATERIAL_CATEGORY_SUCCESS,
  GET_MATERIAL_CATEGORY_ERROR,
  ADD_MATERIAL_CATEGORY,
  ADD_MATERIAL_CATEGORY_SUCCESS,
  ADD_MATERIAL_CATEGORY_ERROR,
  UPDATE_MATERIAL_CATEGORY,
  UPDATE_MATERIAL_CATEGORY_SUCCESS,
  UPDATE_MATERIAL_CATEGORY_ERROR,
  GET_MATERIAL_CATEGORY_SETTINGS,
  GET_MATERIAL_CATEGORY_SETTINGS_SUCCESS,
  GET_MATERIAL_CATEGORY_SETTINGS_ERROR,
  RESET_MATERIAL_CATEGORY
} from '../actionTypes/materialCategoriesActionTypes';

export const DEFAULT_MATERIAL_CATEGORIES_STATE = {
  materialCategories: [],
  materialCategoriesParents: [],
  metaData: {},
  materialCategory: {},
  materialCategorySettings: {},
  isMaterialCategoryValid: false,
  materialCategoryError: {},
  materialCategoriesBusy: false,
  materialCategorySettingsBusy: false,
  materialCategoriesError: false
};

export const materialCategories = handleActions(
    {
        [GET_MATERIAL_CATEGORIES]: (state, action) => ({
            ...state,
            materialCategoriesBusy: true
        }),
        [GET_MATERIAL_CATEGORIES_PARENTS_SUCCESS]: (state, action) => ({
          ...state,
          materialCategoriesParents: action.payload
        }),
        [GET_MATERIAL_CATEGORIES_SUCCESS]: (state, action) => ({
            ...state,
            materialCategories: action.payload.data,
            metaData: action.payload.meta,
            materialCategoriesBusy: false
        }),
        [GET_MATERIAL_CATEGORIES_ERROR]: (state, action) => ({
            ...state,
            materialCategoriesBusy: false
        }),
        [GET_MATERIAL_CATEGORY]: (state, action) => ({
            ...state,
            materialCategoriesBusy: true
        }),
        [GET_MATERIAL_CATEGORY_SUCCESS]: (state, action) => ({
            ...state,
            materialCategory: action.payload,
            materialCategoriesBusy: false
        }),
        [GET_MATERIAL_CATEGORY_ERROR]: (state, action) => ({
            ...state,
            materialCategoriesBusy: false
        }),
        [ADD_MATERIAL_CATEGORY]: (state, action) => ({
            ...state,
            materialCategoriesBusy: true
        }),
        [ADD_MATERIAL_CATEGORY_SUCCESS]: (state, action) => ({
            ...state,
            isMaterialCategoryValid: true,
            materialCategoriesBusy: false
        }),
        [ADD_MATERIAL_CATEGORY_ERROR]: (state, action) => ({
            ...state,
            materialCategoriesBusy: false,
            isMaterialCategoryValid: false,
            materialCategoryError: action.payload,
            materialCategoriesError: true
        }),
        [UPDATE_MATERIAL_CATEGORY]: (state, action) => ({
          ...state,
          materialCategoriesBusy: true
        }),
        [UPDATE_MATERIAL_CATEGORY_SUCCESS]: (state, action) => ({
          ...state,
          isMaterialCategoryValid: true,
          materialCategoriesBusy: false
        }),
        [UPDATE_MATERIAL_CATEGORY_ERROR]: (state, action) => ({
          ...state,
          materialCategoriesBusy: false,
          isMaterialCategoryValid: false,
          materialCategoryError: action.payload,
          materialCategoriesError: true
        }),
        [GET_MATERIAL_CATEGORY_SETTINGS]: (state, action) => ({
          ...state,
          materialCategorySettingsBusy: true
        }),
        [GET_MATERIAL_CATEGORY_SETTINGS_SUCCESS]: (state, action) => ({
            ...state,
            materialCategorySettings: action.payload,
            materialCategorySettingsBusy: false
        }),
        [GET_MATERIAL_CATEGORY_SETTINGS_ERROR]: (state, action) => ({
            ...state,
            materialCategorySettingsBusy: false
        }),
        [RESET_MATERIAL_CATEGORY]: (state, action) => ({
            ...state,
            materialCategory: {},
            isMaterialCategoryValid: false,
            materialCategoryError: {},
            materialCategoriesError: false
        }),
    },
    DEFAULT_MATERIAL_CATEGORIES_STATE
);
