import BaseAPIService from './Base';

import moment from 'moment';

export default class AdminTimeSheetAPIService extends BaseAPIService {
  getAdminTimeEntries = async () => {
    return this.request(`api/timesheet/entries/task`).then((data) => {
      return data;
    });
  };

  getAdminTimeEntry = async (id) => {
    return this.request(`api/timesheet/entries/${id}`).then((data) => {
      return data;
    });
  };

  addNewAdminTimeEntry = async (data) => {
    return this.request('api/timesheet/entries', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  updateAdminTimeEntry = async (id, data) => {
    return this.request(`api/timesheet/entries/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  deleteAdminTimeEntry = async (id) => {
    return this.request(`api/timesheet/entries/${id}`, {
      method: 'DELETE',
    });
  };

  filterTimeSheets = async (filters, sort, sortOrder, searchTerms) => {
    let sortValue = null;
    if (sort == 0) {
      sortValue = 'entry_date';
    }
    const dateFrom = filters.dateFrom ? (moment(filters.dateFrom).format('YYYY-MM-DD') + " 00:00:00"): null;
    const dateTo = filters.dateTo ? (moment(filters.dateTo).format('YYYY-MM-DD') + " 23:59:59"): null;
    let queryDatePart = '';
    if (dateFrom && dateTo) {
      queryDatePart = `&filter[entry_date][0]=>${dateFrom}&filter[entry_date][1]=<${dateTo}`;
    } else if (dateFrom) {
      queryDatePart = `&filter[entry_date]=>${dateFrom}`;
    } else if (dateTo) {
      queryDatePart = `&filter[entry_date]=<${dateTo}`;
    }
    const url = `?${filters.page && filters.page !== '' ? `page=${filters.page}` : ''}${filters.coworker && filters.coworker !== '' ? `&filter[coworker_id]=${filters.coworker}` : ''}${filters.order_id && filters.order_id !== '' ? `&filter[loggable.position.order_id]=${filters.order_id}` : ''}${filters.position && filters.position !== '' ? `&filter[loggable.position_id]=${filters.position}` : ''}${filters.task && filters.task !== '' ? `&filter[loggable_id]=${filters.task}` : ''}${queryDatePart}${sortValue ? `&order=${sortOrder ? '' : '-'}${sortValue}` : ''}${searchTerms ? `&search-term=${searchTerms}` : ''}`;
    return this.request(`api/timesheet/entries/task${url}`).then((data) => {
      return data;
    });
  };
}
