import React from "react";
import PropTypes from "prop-types";
import CloseIcon from '@material-ui/icons/Close';

import './style.scss';
import CustomAutocomplete from "../CustomAutocomplete";

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInputValue: '',
    };
  }

  removeTag = (index) => {
    this.props.onRemoveTag(index);
  };

  renderTag = (tag, index) => {
    return (
      <div key={index} className="selected-tag">
        <span>{tag.value}</span>
        <div
          className="tag-delete-btn"
          onClick={() => this.removeTag(index)}
        >
          <CloseIcon fontSize={'small'} />
        </div>
      </div>
    )
  };

  handleInputChange = (value) => {
    const {onAddTag} = this.props;
    onAddTag(value);
    this.setState({
      currentInputValue: ''
    })
  }

  render() {
    const {
      title,
      className,
      dataList,
      data,
      inputIcon,
      getData,
      maxPreviewHeight,
    } = this.props;
    return (
      <div className={`tags-content ${className}`}>
        <div className='title-container'>{title}</div>
        <CustomAutocomplete
          buttonProps={{className: '', round: true, size: "sm"}}
          isClearSelected={true}
          dropdownList={dataList}
          id="custom_tags"
          hoverColor="dark"
          icon={inputIcon}
          getData={getData}
          onClick={value => this.handleInputChange(value)}
          clearInputOnSelect={true}
          excludeItems={data.map(el => el.id)}
        />
        <div className="tags-list" style={maxPreviewHeight ? {maxHeight: maxPreviewHeight} : null}>
          {
           data.map((item, index) => this.renderTag(item, index))
          }
        </div>
      </div>
    );
  }
}

Tags.propTypes = {
  inputIcon: PropTypes.string,
  containerClassName: PropTypes.string.isRequired,
  maxPreviewHeight: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  dataList: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
};

export default Tags;
