import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getCustomer } from "../../store/actions/customers";
import IconButton from "@material-ui/core/IconButton";
import TextArea from "components/CustomTextArea";
import Input from "components/Input/Input";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import ExpandButton from "../../assets/img/buttons/btn-expand.png";
import ContractButton from "../../assets/img/buttons/btn-contract.png";
import CheckboxUncheckedButton from "../../assets/img/buttons/btn-checkbox-unchecked.png";
import CheckboxCheckedButton from "../../assets/img/buttons/btn-checkbox-checked.png";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "../../components/StyledCheckbox";

class Step1 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      textareaExpanded: null,
    };
  }

  componentDidMount() {
    if (this.props.orderData.customer_id !== '' && this.props.orderData.customer_id !== null && this.props.orderData.customer_id !== undefined && this.props.customer && this.props.customer.id !== this.props.orderData.customer_id) {
      this.props.getCustomer(this.props.orderData.customer_id);
    }
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentWillUnmount() {
    
  }

  handleExpandTextArea = (field) => {
    this.setState({
      textareaExpanded: field,
    });
  }

  handleContractTextArea = () => {
    this.setState({
      textareaExpanded: null,
    });
  }

  onSelectTextPreset = (field, id) => {
    const {
      data,
      preTextPresets,
      endTextPresets,
    } = this.props;
    let textContents;
    if (field == 'pre_text') {
      textContents = preTextPresets.find(el => el.id == id).template;
    } else {
      textContents = endTextPresets.find(el => el.id == id).template;
    }
    this.onDataChange(field, `${data[field] ? `${data[field]}\n` : ''}${textContents}`);
  }

  handleToggleAdditionalElementsCheckbox = (field) => {
    const {
      data,
      onDataChange
    } = this.props;
    const newAdditionalElements = { ...data.additional_elements };
    newAdditionalElements[field] = !newAdditionalElements[field];
    if (onDataChange)
      onDataChange({
        ...data,
        additional_elements: newAdditionalElements,
      });
  }

  handleToggleInvoiceType = (id) => {
    const {
      data,
      onDataChange
    } = this.props;
    if (onDataChange)
      onDataChange({
        ...data,
        template_id: id,
      });
  }

  onDataChange = (name, value) => {
    const { data, onDataChange } = this.props;
    const newData = { ...data };
    newData[name] = value;
    if (onDataChange)
      onDataChange(newData);
  }

  renderTextAreaExpanded = () => {
    const {
      data,
      preTextPresets,
      endTextPresets,
      errors,
      t
    } = this.props;
    const {
      textareaExpanded
    } = this.state;
    let selectedPresetList = [];
    if (textareaExpanded == 'pre_text') {
      selectedPresetList = preTextPresets;
    } else {
      selectedPresetList = endTextPresets;
    }
    const selectedPresetListActual = selectedPresetList.map(el => ({
      ...el,
      value: el.name,
    }));
    return (
      <div className="step1-container">
        <div className="details-box box-maximized">
          <div className="details-box-title" xs={12}>
            <span className="title">{ t(textareaExpanded) }</span>
            <div className="controls-right">
              <CustomDropdown
                buttonText={''}
                className='text-preset-dropdown'
                buttonProps={{
                  className: 'dropdown-blue-style',
                  color: 'transparent',
                  round: true,
                  size: 'sm'
                }}
                buttonPlaceholder={t('select_to_load')}
                hoverColor="dark"
                dropdownList={selectedPresetListActual}
                onClick={(prop) => this.onSelectTextPreset(textareaExpanded, prop.id)}
              />
              <IconButton aria-label="close" className="contract-btn" onClick={() => this.handleContractTextArea()}>
                <img alt="contract-btn" src={ContractButton} />
              </IconButton>
            </div>
          </div>
          <div className="details-box-content textarea-container expanded-textarea" xs={12}>
            <TextArea
              autofocus={false}
              value={data[textareaExpanded]}
              onChange={(e) => this.onDataChange(textareaExpanded, e.target.value)}
              error={errors[textareaExpanded]}
            />
          </div>
        </div>
      </div>
    );
  }

  renderEverything = () => {
    const {
      orderData,
      paymentTypeList,
      customer,
      data,
      errors,
      preTextPresets,
      endTextPresets,
      invoiceTypesPresets,
      t
    } = this.props;
    const {
    } = this.state;

    const preTextPresetsActual = preTextPresets.map(el => ({
      ...el,
      value: el.name,
    }));
    const endTextPresetsActual = endTextPresets.map(el => ({
      ...el,
      value: el.name,
    }));

    const buildAddressString = (address, postal_code, city, country) => {
      let result = `${address ? address : ''}`;
      if (result.length > 0) {
        result += ", ";
      }
      if (postal_code) {
        result += postal_code;
      }
      if (result.length > 0) {
        result += " ";
      }
      if (city) {
        result += city;
      }
      if (result.length > 0) {
        result += ", ";
      }
      if (country) {
        result += country;
      }
      return result;
    };

    const renderInvoiceType = (invoiceType, index) => {
      const isChecked = data.template_id === invoiceType.id;
      return (
        <div key={index} className="invoice-type">
          <span>{ invoiceType.value }</span>
          <IconButton aria-label="close" className="checkbox-btn" onClick={() => this.handleToggleInvoiceType(invoiceType.id)}>
            <img alt="checkbox-btn" src={isChecked ? CheckboxCheckedButton : CheckboxUncheckedButton} />
          </IconButton>
        </div>
      );
    };

    let customerInfo = null;
    if (orderData.customer_id !== '' && orderData.customer_id !== null && orderData.customer_id !== undefined && orderData.customer_id == customer.id) {
      customerInfo = customer;
    } else if (orderData.customer && Object.keys(orderData.customer).length > 0) {
      customerInfo = orderData.customer;
    }
    let paymentType = null;
    if (orderData.payment_type !== null && orderData.payment_type !== undefined) {
      paymentType = paymentTypeList.find(el => el.id == orderData.payment_type);
    }
    const deliveryAddress = customerInfo ? buildAddressString(customerInfo.address, customerInfo.postal_code, customerInfo.city, customerInfo.country) : null;
    const customerData = {
      full_name: customerInfo ? `${customerInfo.first_name} ${customerInfo.last_name ? customerInfo.last_name : ''}` : 'N/A',
      phone: customerInfo && customerInfo.phone_mobile ? customerInfo.phone_mobile : 'N/A',
      delivery_address: deliveryAddress ? deliveryAddress : 'N/A',
      billing_address: deliveryAddress ? t('same_as_shipping_address') : 'N/A',
      payment_method: paymentType ? paymentType.value : 'N/A',
    };
    const addressData = {
      full_name: 'N/A',
      phone: 'N/A',
      address: 'N/A',
    };

    if (customerInfo && customerInfo.shipping_addresses.length > 0) {
      const shippingAddress = customerInfo.shipping_addresses[0];
      addressData.full_name = `${shippingAddress.first_name} ${shippingAddress.last_name ? shippingAddress.last_name : ''}`;
      if (shippingAddress.phone_mobile)
        addressData.phone = shippingAddress.phone_mobile;
      const addressString = buildAddressString(shippingAddress.address, shippingAddress.postal_code, shippingAddress.city, shippingAddress.country);
      if (addressString.length > 0)
        addressData.address = addressString;
    }

    const actualInvoiceTypesPresets = invoiceTypesPresets.map(el => ({
      id: el.id,
      value: el.name,
    }));

    return (
      <GridContainer className="step1-container">
        <GridItem className="grid-item-no-padding" xs={12}>
          <GridItem className="grid-item-padding-right" xs={6}>
            <div className="details-box">
              <GridItem className="details-box-title" xs={12}>
                <span className="title">{ t('customer_contact_details') }</span>
              </GridItem>
              <GridItem className="details-box-content" xs={12}>
                <GridItem className="details-box-info-title" xs={4}>
                  <span>{ t('full_name') }:</span>
                  <span>{ t('phone') }:</span>
                  <span>{ t('delivery_address') }:</span>
                  <span>{ t('billing_address') }:</span>
                  <span>{ t('payment_method') }:</span>
                </GridItem>
                <GridItem className="details-box-info-data" xs={8}>
                  <span className="details-box-info-data-item">{customerData.full_name}</span>
                  <span className="details-box-info-data-item">{customerData.phone}</span>
                  <span className="details-box-info-data-item">{customerData.delivery_address}</span>
                  <span className="details-box-info-data-item">{customerData.billing_address}</span>
                  <span className="details-box-info-data-item">{customerData.payment_method}</span>
                </GridItem>
              </GridItem>
            </div>
            <div className="details-box">
              <GridItem className="details-box-title" xs={12}>
                <span className="title">{ t('title') }</span>
              </GridItem>
              <GridItem className="details-box-content input-container" xs={12}>
                <Input
                  autofocus={false}
                  value={data.title}
                  onChange={(e) => this.onDataChange('title', e.target.value)}
                  error={errors.title}
                />
              </GridItem>
            </div>
            <div className="details-box">
              <GridItem className="details-box-title" xs={12}>
                <span className="title">{ t('pre_text') }</span>
                <div className="controls-right">
                  <CustomDropdown
                    buttonText={''}
                    className='text-preset-dropdown'
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      round: true,
                      size: 'sm'
                    }}
                    buttonPlaceholder={t('select_to_load')}
                    hoverColor="dark"
                    dropdownList={preTextPresetsActual}
                    onClick={(prop) => this.onSelectTextPreset('pre_text', prop.id)}
                  />
                  <IconButton aria-label="close" className="expand-btn" onClick={() => this.handleExpandTextArea('pre_text')}>
                    <img alt="expand-btn" src={ExpandButton} />
                  </IconButton>
                </div>
              </GridItem>
              <GridItem className="details-box-content textarea-container" xs={12}>
                <TextArea
                  autofocus={false}
                  value={data.pre_text}
                  onChange={(e) => this.onDataChange('pre_text', e.target.value)}
                  error={errors.pre_text}
                />
              </GridItem>
            </div>
            <div className="details-box">
              <GridItem className="details-box-title" xs={12}>
                <span className="title">{ t('end_text') }</span>
                <div className="controls-right">
                  <CustomDropdown
                    buttonText={''}
                    className='text-preset-dropdown'
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      round: true,
                      size: 'sm'
                    }}
                    buttonPlaceholder={t('select_to_load')}
                    hoverColor="dark"
                    dropdownList={endTextPresetsActual}
                    onClick={(prop) => this.onSelectTextPreset('end_text', prop.id)}
                  />
                  <IconButton aria-label="close" className="expand-btn" onClick={() => this.handleExpandTextArea('end_text')}>
                    <img alt="expand-btn" src={ExpandButton} />
                  </IconButton>
                </div>
              </GridItem>
              <GridItem className="details-box-content textarea-container" xs={12}>
                <TextArea
                  autofocus={false}
                  value={data.end_text}
                  onChange={(e) => this.onDataChange('end_text', e.target.value)}
                  error={errors.end_text}
                />
              </GridItem>
            </div>
          </GridItem>
          <GridItem className="grid-item-padding-left" xs={6}>
            <div className="details-box">
              <GridItem className="details-box-title" xs={12}>
                <span className="title">{ t('shipping_billing_address') }</span>
              </GridItem>
              <GridItem className="details-box-content" xs={12}>
                <GridItem className="details-box-info-title" xs={4}>
                  <span>{ t('full_name') }:</span>
                  <span>{ t('phone') }:</span>
                  <span>{ t('address') }:</span>
                  <br />
                  <br />
                </GridItem>
                <GridItem className="details-box-info-data" xs={8}>
                  <span className="details-box-info-data-item">{addressData.full_name}</span>
                  <span className="details-box-info-data-item">{addressData.phone}</span>
                  <span className="details-box-info-data-item">{addressData.address}</span>
                  <br />
                  <br />
                </GridItem>
              </GridItem>
            </div>
            <div className="details-box-alternative">
              <GridItem className="details-box-alternative-title" xs={12}>
                <span className="title">{ t('select_invoice_types') }</span>
              </GridItem>
              <GridItem className="details-box-alternative-content" xs={12}>
              {
                actualInvoiceTypesPresets.map((el, index) => renderInvoiceType(el, index))
              }
              </GridItem>
            </div>
            <div className="details-box">
              <GridItem className="details-box-title" xs={12}>
                <span className="title">{ t('additional_elements') }</span>
              </GridItem>
              <GridItem className="details-box-content" xs={12}>
                <GridItem className="details-box-info-title" xs={6}>
                  <FormControl className="form-control-checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.additional_elements.logo}
                          onChange={e => this.handleToggleAdditionalElementsCheckbox('logo', e.target.checked) }
                          color="primary"
                        />
                      }
                      label={t('logo')}
                      className={"additional-elements-checkbox " + (data.additional_elements.logo ? "additional-elements-checkbox-checked" : "")}
                    />
                  </FormControl>
                  <FormControl className="form-control-checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.additional_elements.footer_text}
                          onChange={e => this.handleToggleAdditionalElementsCheckbox('footer_text', e.target.checked) }
                          color="primary"
                        />
                      }
                      label={t('footer_text')}
                      className={"additional-elements-checkbox " + (data.additional_elements.footer_text ? "additional-elements-checkbox-checked" : "")}
                    />
                  </FormControl>
                </GridItem>
                <GridItem className="details-box-info-data" xs={6}>
                  <FormControl className="form-control-checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.additional_elements.discount}
                          onChange={e => this.handleToggleAdditionalElementsCheckbox('discount', e.target.checked) }
                          color="primary"
                        />
                      }
                      label={t('discount')}
                      className={"additional-elements-checkbox " + (data.additional_elements.discount ? "additional-elements-checkbox-checked" : "")}
                    />
                  </FormControl>
                  <FormControl className="form-control-checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.additional_elements.sum}
                          onChange={e => this.handleToggleAdditionalElementsCheckbox('sum', e.target.checked) }
                          color="primary"
                        />
                      }
                      label={t('sum')}
                      className={"additional-elements-checkbox " + (data.additional_elements.sum ? "additional-elements-checkbox-checked" : "")}
                    />
                  </FormControl>
                </GridItem>
              </GridItem>
            </div>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    const { textareaExpanded } = this.state;

    if (!textareaExpanded)
      return this.renderEverything();
    else
      return this.renderTextAreaExpanded();
  }
}

const mapStateToProp = state => {
  return {
    customer: state.customers.customer,
    paymentTypeList: state.providers.paymentTypeList,
    preTextPresets: state.invoicesProviders.preTextPresets,
    endTextPresets: state.invoicesProviders.endTextPresets,
    invoiceTypesPresets: state.invoicesProviders.invoiceTypesPresets,
    lastPreTextValue: state.invoicesProviders.lastPreTextValue,
    lastEndTextValue: state.invoicesProviders.lastEndTextValue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomer: (id) => dispatch(getCustomer(id)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Step1));
