import {
  ADD_MANUFACTURER_ORDER_POSITION,
  ADD_MANUFACTURER_ORDER_POSITION_SUCCESS,
  ADD_MANUFACTURER_ORDER_POSITION_ERROR,
  UPDATE_MANUFACTURER_ORDER_POSITION,
  UPDATE_MANUFACTURER_ORDER_POSITION_SUCCESS,
  UPDATE_MANUFACTURER_ORDER_POSITION_ERROR,
  GET_MANUFACTURER_ORDER_POSITION,
  GET_MANUFACTURER_ORDER_POSITION_SUCCESS,
  GET_MANUFACTURER_ORDER_POSITION_ERROR,
  DELETE_MANUFACTURER_ORDER_POSITION,
  DELETE_MANUFACTURER_ORDER_POSITION_SUCCESS,
  DELETE_MANUFACTURER_ORDER_POSITION_ERROR,
  GET_ORDER_POSITION_INDEX,
  GET_ORDER_POSITION_INDEX_SUCCESS,
  GET_ORDER_POSITION_INDEX_ERROR
} from '../actionTypes/manufacturerOrderPositionActionTypes';

import NotificationService from '../../services/NotificationService';

export const addManufacturerOrderPosition = (data) => {
  return {type: ADD_MANUFACTURER_ORDER_POSITION, payload: data}
};

export const addManufacturerOrderPositionSuccess = () => {
  NotificationService.success('Manufacturer order position created.');
  return {type: ADD_MANUFACTURER_ORDER_POSITION_SUCCESS}
};

export const addManufacturerOrderPositionFailure = (error) => {
  NotificationService.error(error);
  return {type: ADD_MANUFACTURER_ORDER_POSITION_ERROR, payload: error}
};

export const updateManufacturerOrderPosition = ({id, data}) => {
  return {type: UPDATE_MANUFACTURER_ORDER_POSITION, payload: {id, data}}
};

export const updateManufacturerOrderPositionSuccess = () => {
  NotificationService.success('Manufacturer order position updated');
  return {type: UPDATE_MANUFACTURER_ORDER_POSITION_SUCCESS}
};

export const updateManufacturerOrderPositionFailure = (error) => {
  NotificationService.error(error);
  return {type: UPDATE_MANUFACTURER_ORDER_POSITION_ERROR, payload: error}
};

export const getManufacturerOrderPosition = (id) => {
  return {type: GET_MANUFACTURER_ORDER_POSITION, payload: id}
};

export const getManufacturerOrderPositionSuccess = (order) => {
  return {type: GET_MANUFACTURER_ORDER_POSITION_SUCCESS, payload: order}
};

export const getManufacturerOrderPositionFailure = (error) => {
  return {type: GET_MANUFACTURER_ORDER_POSITION_ERROR, payload: error}
};

export const deleteManufacturerOrderPosition = (id) => {
  return {type: DELETE_MANUFACTURER_ORDER_POSITION, payload: id}
};

export const deleteManufacturerOrderPositionSuccess = () => {
  NotificationService.success('Manufacturer Order removed');
  return {type: DELETE_MANUFACTURER_ORDER_POSITION_SUCCESS}
};

export const deleteManufacturerOrderPositionFailure = (error) => {
  return {type: DELETE_MANUFACTURER_ORDER_POSITION_ERROR, payload: error}
};

export const getOrderPositionIndex = (id) => {
  return {type: GET_ORDER_POSITION_INDEX, payload: id}
};

export const getOrderPositionIndexSuccess = (orderIndex) => {
  return {type: GET_ORDER_POSITION_INDEX_SUCCESS, payload: orderIndex}
};

export const getOrderPositionIndexFailure = (error) => {
  return {type: GET_ORDER_POSITION_INDEX_ERROR, payload: error}
};
