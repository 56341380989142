import React from "react";
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CloseButton from "assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';

class CloseDialog extends React.Component {
  render() {
    const {
      isOpen,
      title,
      onClose,
      onCancel,
      onConfirm,
      t
    } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <div className="close-dialog-outer-container">
          <MuiDialogTitle className="remove-position-title" disableTypography>
            <Typography variant="h6" className="dialog-title">
              {`${title}`}
            </Typography>
            {onClose ? (
              <IconButton aria-label="close" className="close-btn" onClick={onClose}>
                <img alt="file-import-close-btn" src={CloseButton}/>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <div className="remove-position-content">
            { t('are_you_sure_you_want_to_discard_the_changes') }
          </div>
          <div className="remove-position-btn-container">
            <Button
              className="cancel-btn"
              onClick={onCancel}
            >
              { t('cancel').toUpperCase() }
            </Button>
            <Button
              className="create-btn"
              onClick={onConfirm}
            >
              { t('close').toUpperCase() }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(CloseDialog);
