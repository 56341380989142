import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR
} from "../actionTypes/locationActionTypes";

export const getCountries = () => {
  return {type: GET_COUNTRIES}
};

export const  getCountriesSuccess = (countries) => {
  let selectData = [];
  Object.entries(countries).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {
    type: GET_COUNTRIES_SUCCESS,
    payload: selectData
  }
};

export const getCountriesFailure = (error)  => {
  return {
    type: GET_COUNTRIES_ERROR,
    payload: error
  }
};

export const getCities = (countryId, searchTerm) => {
  return {
    type: GET_CITIES,
    payload: { countryId, searchTerm },
  }
};

export const  getCitiesSuccess = (countries) => {
  let selectData = [];
  Object.entries(countries).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {
    type: GET_CITIES_SUCCESS,
    payload: selectData
  }
};

export const getCitiesFailure = (error)  => {
  return {
    type: GET_CITIES_ERROR,
    payload: error
  }
};
