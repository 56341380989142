import React from "react";
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {validateCustomerImportFile, resetCustomerImportValidationData} from "../../store/actions/customers";

import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';
import Loader from "../../components/Loader/Loader";

class DelimiterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 0,
      selectedFile: null,
      isShowError: false
    };
  }
  componentWillUnmount() {
    this.props.resetData()
  }

  handleDataChange = (value) => {
    this.setState({
      selectedItem: value
    })
  }

  handleAddImportFile = (e)  => {
    this.setState({
      selectedFile: e.target.files[0],
      isShowError: false
    });
    e.target.value = '';
  }

  handleImportButtonClick = () => {
    const {validateCustomerImportFile} = this.props;
    const {selectedItem, selectedFile} = this.state;
    if (selectedFile) {
      let formData = new FormData();
      const delimiter = selectedItem !== 0 ? ';' : ',';
      formData.append('config[delimiter]', delimiter);
      formData.append('config[owner][type]', 'manufacturer');
      formData.append('config[owner][id]', '1');
      formData.append('config[has_mapping]', '1');
      formData.append('file', selectedFile);
      validateCustomerImportFile(formData);
    } else {
      this.setState({
        isShowError: true
      })
    }
  }

  render() {
    const {
      onClose,
      isOpen,
      isStartedValidation,
      t
    } = this.props;
    const {
      isShowError,
      selectedItem,
      selectedFile
    } = this.state;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="delimiter-dialog-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ t('file_import') }</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="file-import-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="delimiter-dialog-content">
          {
            !isStartedValidation
              ?
                <>
                  <div className="file-import-container">
                    <Button
                      className="add-file-btn"
                      component="label"
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleAddImportFile}
                      />
                      { t('add_import_file').toUpperCase() }
                    </Button>
                    <div className="file-name">
                      {selectedFile && <span>{selectedFile.name}</span>}
                    </div>
                    <div className="file-import-error">
                      {isShowError && <span>{ t('the_file_is_required') }</span>}
                    </div>
                  </div>
                  <div>{ t('import_file_delimiter') }</div>
                  <div className="delimiter-dropdown">
                    <Dropdown
                      buttonText={selectedItem}
                      buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                      dropdownList={[{id: 0, value: ','}, {id: 1, value: ';'}]}
                      hoverColor="dark"
                      onClick={(value) => this.handleDataChange(value.id)}
                    />
                  </div>
                </>
              :
                <div className="delimiter_loader">
                  <Loader />
                </div>
          }
        </div>
        <div className="delimiter-dialog-btn-container">
          <Button
            className="delimiter-btn cancel-btn"
            onClick={onClose}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="delimiter-btn create-btn"
            onClick={this.handleImportButtonClick}
          >
            { t('import').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    isStartedValidation: state.customers.isStartedValidation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    validateCustomerImportFile: (data) => dispatch(validateCustomerImportFile(data)),
    resetData: () => dispatch(resetCustomerImportValidationData()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(DelimiterDialog));
