import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';

import CalendarIcon from '../../assets/img/inputIcons/calendar.svg';
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";

import './style.scss';

export default class CustomDatePicker extends React.Component {
  handleDateChange = (date) => {
    if (this.props.handleChange) {
      this.props.handleChange(date);
    }
  };

  render () {
    const {selectedDate, icon, error, className, placeholder, timeFormat, id, requireCloseButton, smallerFontSize, readOnly, shouldDisableDate} = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={`my-date-picker ${className} ${error ? 'error' : ''} ${selectedDate && smallerFontSize ? 'have-date-value' : ''}`}
          margin="normal"
          error={false}
          helperText={''}
          inputVariant="outlined"
          id={id ? id : null}
          format={timeFormat || "dd/MM/yyyy"}
          value={selectedDate}
          onChange={this.handleDateChange}
          placeholder={placeholder || 'Please select'}
          keyboardIcon={<img alt='date-picker-icon' src={icon ? icon : CalendarIcon} />}
          readOnly={readOnly}
          InputProps={selectedDate && requireCloseButton ? {
            endAdornment: (
              <IconButton
                onClick={() => this.handleDateChange(null)}
                style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ClearIcon style={{ fontSize: 20 }} />
              </IconButton>
            )
          } : null}
          InputAdornmentProps={{
            position: "start"
          }}
          shouldDisableDate={shouldDisableDate}
        />
      </MuiPickersUtilsProvider>
    )
  }
}
