import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {coworkersAPI} from 'services/API';
import {
    getCoworkersSuccess,
    getCoworkersFailure,
    getCoworkerSuccess
} from '../store/actions/coworkers';
import {
    ADD_COWORKER,
    GET_COWORKERS,
    GET_COWORKER,
    UPDATE_COWORKER,
    REMOVE_COWORKER
} from '../store/actionTypes/coworkersActionTypes';

export const getCoworkersEpic = (actions$) => actions$.pipe(
    ofType(GET_COWORKERS),
    switchMap(({payload}) => from(coworkersAPI.getCoworkers(payload)).pipe(
        switchMap((response) => {
            return of(getCoworkersSuccess(response.data))
        }),
        catchError( err => {
            return of(getCoworkersFailure(err))
        }
        )
    ))
);

export const getCoworkerEpic = (actions$) => actions$.pipe(
    ofType(GET_COWORKER),
    switchMap(({payload}) => from(coworkersAPI.getCoworker(payload)).pipe(
        switchMap((response) => {
            return of(getCoworkerSuccess(response.data))
        }),
        catchError( err => {
                return of(getCoworkersFailure(err))
            }
        )
    ))
);

export const addCoworkerEpic = (actions$) => actions$.pipe(
    ofType(ADD_COWORKER),
    switchMap(({payload}) => from(coworkersAPI.createCoworker(payload)).pipe(
        switchMap((response) => {
            return of(getCoworkerSuccess(response))
        }),
        catchError( err => {
                return of(getCoworkersFailure(err))
            }
        )
    ))
);

export const updateCoworkerEpic = (actions$) => actions$.pipe(
    ofType(UPDATE_COWORKER),
    switchMap(({payload}) => from(coworkersAPI.updateCoworker(payload.id, payload.data)).pipe(
        switchMap((response) => {
            return of(getCoworkerSuccess(response))
        }),
        catchError( err => {
                return of(getCoworkersFailure(err))
            }
        )
    ))
);

export const removeCoworkerEpic = (actions$) => actions$.pipe(
    ofType(REMOVE_COWORKER),
    switchMap(({payload}) => from(coworkersAPI.deleteCoworker(payload.id)).pipe(
        switchMap((response) => {
            return of(getCoworkersSuccess(response))
        }),
        catchError( err => {
                return of(getCoworkersFailure(err))
            }
        )
    ))
);

