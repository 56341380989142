export const ToolbarButtonType = {
    TextStyle: 'ToolbarButtonType/TextStyle',
    Heading1: 'ToolbarButtonType/Heading1',
    Heading2: 'ToolbarButtonType/Heading2',
    Heading3: 'ToolbarButtonType/Heading3',
    Heading4: 'ToolbarButtonType/Heading4',
    Bold: 'ToolbarButtonType/Bold',
    Italic: 'ToolbarButtonType/Italic',
    TextMore: 'ToolbarButtonType/TextMore',
    Underline: 'ToolbarButtonType/Underline',
    Strikethrough: 'ToolbarButtonType/Strikethrough',
    Superscript: 'ToolbarButtonType/Superscript',
    Subscript: 'ToolbarButtonType/Subscript',
    BulletList: 'ToolbarButtonType/BulletList',
    NumberedList: 'ToolbarButtonType/NumberedList',
    /*Image: 'ToolbarButtonType/Image',
    Table: 'ToolbarButtonType/Table',*/
};

export const TextDecorations = {
    Bold: 'BOLD',
    Italic: 'ITALIC',
    Underline: 'UNDERLINE',
    Strikethrough: 'STRIKETHROUGH',
    Superscript: 'SUPERSCRIPT',
    Subscript: 'SUBSCRIPT',
};

export const BlockTypes = {
    Heading1: 'header-one',
    Heading2: 'header-two',
    Heading3: 'header-three',
    Heading4: 'header-four',
    BulletList: 'unordered-list-item',
    NumberedList: 'ordered-list-item',
};
