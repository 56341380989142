import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { InvoiceTypes } from 'constants/constants';

import IconButton from "@material-ui/core/IconButton";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Button from '../../components/CustomButtons/Button';

import ButtonCheckbox2Checked from "../../assets/img/buttons/btn-checkbox-2-checked.png";
import ButtonCheckbox2Unchecked from "../../assets/img/buttons/btn-checkbox-2-unchecked.png";
import ButtonCheckbox2Disabled from "../../assets/img/buttons/btn-checkbox-2-disabled.png";
import ButtonBurger from "../../assets/img/buttons/btn-burger.png";
import ButtonDelete from "../../assets/img/buttons/btn-delete.png";

import { formatNumericValue } from "utils/utils";

class Step2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

  componentWillUnmount() {
    
  }

  handleSelectAllPositions = () => {
    const { data, orderData, onDataChange } = this.props;
    const newData = { ...data, selectedPositions: orderData.positions.filter(el => !this.isPositionDisabled(el)).map(el => el.id) };
    if (onDataChange)
      onDataChange(newData);
  }

  handleSelectNonePositions = () => {
    const { data, onDataChange } = this.props;
    const newData = { ...data, selectedPositions: [] };
    if (onDataChange)
      onDataChange(newData);
  }

  handleToggleSelectPosition = (id) => {
    const { data, onDataChange } = this.props;
    const newData = { ...data, selectedPositions: [ ...data.selectedPositions ] };
    const currentPositionIndex = newData.selectedPositions.indexOf(id);
    if (currentPositionIndex !== -1) {
      newData.selectedPositions.splice(currentPositionIndex, 1);
    } else {
      newData.selectedPositions.push(id);
    }
    if (onDataChange)
      onDataChange(newData);
  }

  handleRemoveSelectedPosition = (id) => {
    const { data } = this.props;
    data.selectedPositions.indexOf(id) !== -1 && this.handleToggleSelectPosition(id);
  }

  onSelectedPositionDragEnd = (opResult) => {
    const { data, onDataChange } = this.props;
    if (opResult.source && opResult.destination) {
      const srcIndex = opResult.source.index;
      const destIndex = opResult.destination.index;
      if (srcIndex !== destIndex) {
        const posId = data.selectedPositions[srcIndex];
        const newSelectedPositions = [...data.selectedPositions];
        newSelectedPositions.splice(srcIndex, 1);
        newSelectedPositions.splice(destIndex, 0, posId);
        const newData = { ...data, selectedPositions: newSelectedPositions };
        if (onDataChange)
          onDataChange(newData);
      }
    }
  }

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return text;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  isPositionDisabled = (position) => {
    const { editItems, type } = this.props;
    if (type !== InvoiceTypes['invoice']) {
      return false;
    } else {
      if (!editItems || editItems.length == 0) {
        return position.invoiced;
      } else {
        if (position.invoiced) {
          if (editItems.find(el => el.position_id === position.id)) {
            // position is invoiced by this invoice, treat it as non-invoiced so that invoicing can be switched
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    }
  }

  renderPosition = (position, index) => {
    const { data, unitsList, positionCosts, currencySymbol } = this.props;
    const getMetric = (id) => {
      if (unitsList && unitsList.length > 0) {
        const el = unitsList.find(el => el.id == id);
        if (el) {
          return el.value;
        }
      }
      return 'N/A';
    }
    const getPrice = (position) => {
      const id = position.id;
      const totalPrice = positionCosts[id] && positionCosts[id].material_total ? positionCosts[id].material_total : position.unit_price ? position.unit_price : 0;
      return `${currencySymbol} ${formatNumericValue(totalPrice, 2)}`;
    }
    const isPositionSelected = data.selectedPositions.indexOf(position.id) !== -1;
    const isPositionDisabled = this.isPositionDisabled(position);
    return (
      <GridContainer
        className={`positions-item${isPositionDisabled ? ' disabled-position' : ''}${isPositionSelected ? ' selected-position' : ''}`}
        direction="row"
        key={index}
      >
        <GridItem className="positions-item-title ts-col-number bold">
          { position.numeric_name }
        </GridItem>
        <GridItem className="positions-item-title ts-col-name">
          { position.name }
        </GridItem>
        <GridItem className="positions-item-title ts-col-amount">
          { position.amount }
        </GridItem>
        <GridItem className="positions-item-title ts-col-metrics">
          { getMetric(position.metric) }
        </GridItem>
        <GridItem className="positions-item-title ts-col-total-price">
          { getPrice(position) }
        </GridItem>
        <GridItem className="positions-item-title ts-col-vat">
          { `${currencySymbol} ${formatNumericValue(position.vat, 2)}` }
        </GridItem>
        <GridItem className="positions-item-title ts-col-discount">
          { `${currencySymbol} ${formatNumericValue(position.discount, 2)}` }
        </GridItem>
        <GridItem className="positions-item-title right-aligned ts-col-action-btn">
          <IconButton disabled={isPositionDisabled} aria-label="select" className="select-btn" onClick={() => this.handleToggleSelectPosition(position.id)}>
            <img
              alt="position-checkbox-btn"
              src={!isPositionDisabled ? (isPositionSelected ? ButtonCheckbox2Checked : ButtonCheckbox2Unchecked) : ButtonCheckbox2Disabled}
            />
          </IconButton>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    const {
      data,
      orderData,
      positionCosts,
      currencySymbol,
      t,
    } = this.props;
    const {
    } = this.state;

    let totalSelectedPrice = 0;
    let totalSelectedVat = 0;
    let totalSelectedDiscount = 0;
    orderData.positions.filter(el => data.selectedPositions.indexOf(el.id) !== -1).forEach(el => {
      const price = positionCosts[el.id] && positionCosts[el.id].material_total ? positionCosts[el.id].material_total : el.unit_price ? el.unit_price : 0;
      totalSelectedPrice += price;
      totalSelectedDiscount += el.discount;
      totalSelectedVat += el.vat;
    });
    const SELECTION_DROPABLE_ID = "selection-list-droppable-id";
    return (
      <GridContainer className="step2-container">
        <GridItem className="grid-item-no-padding" xs={12}>
          <GridItem className="grid-item-padding-right">
            <div className="step-area">
              <GridItem className="area-title" xs={12}>
                <span className="title">{ t('select_positions_to_add_invoice') }</span>
                <div className="controls-right">
                  <Button
                    className={"area-title-btn"} 
                    onClick={() => this.handleSelectAllPositions()}
                  >
                    { t('select_all').toUpperCase() }
                  </Button>
                  <Button
                    className={"area-title-btn"} 
                    onClick={() => this.handleSelectNonePositions()}
                  >
                    { t('select_none').toUpperCase() }
                  </Button>
                </div>
              </GridItem>
              <GridItem className="area-content" xs={12}>
                <GridContainer
                  className="positions-header"
                  direction="row"
                >
                  <GridItem className="positions-header-title ts-col-number">
                    #
                  </GridItem>
                  <GridItem className="positions-header-title ts-col-name">
                    { t('name') }
                  </GridItem>
                  <GridItem className="positions-header-title ts-col-amount">
                    { t('amount') }
                  </GridItem>
                  <GridItem className="positions-header-title ts-col-metrics">
                    { t('metrics') }
                  </GridItem>
                  <GridItem className="positions-header-title ts-col-total-price">
                    { t('total_price') }
                  </GridItem>
                  <GridItem className="positions-header-title right-aligned ts-col-vat">
                    { t('vat') }
                  </GridItem>
                  <GridItem className="positions-header-title right-aligned ts-col-discount">
                    { t('discount') }
                  </GridItem>
                  <GridItem className="positions-header-title ts-col-action-btn">
                    &nbsp;
                  </GridItem>
                </GridContainer>
                <GridContainer className="positions-body">
                  {orderData.positions && orderData.positions.length !== 0 ? 
                    orderData.positions.map((position, index) =>
                      this.renderPosition(position, index)
                    ) :
                    <div className='positions-no-records'>
                      <span className='no-records'>{ t('no_records_found') }</span>
                    </div>
                  }
                </GridContainer>
              </GridItem>
            </div>
          </GridItem>
          <GridItem className="grid-item-padding-left">
            <div className="step-area">
              <GridItem className="area-title" xs={12}>
                <span className="title">{ `${t('selected_positions')} ${data.selectedPositions.length > 0 ? `(${data.selectedPositions.length})`: ''}` }</span>
              </GridItem>
              <GridItem className="area-content" xs={12}>
                <GridContainer
                  className="selection-header"
                  direction="row"
                >
                  <GridItem className="selection-header-title ts-col-number">
                    #
                  </GridItem>
                  <GridItem className="selection-header-title ts-col-name">
                    { t('name') }
                  </GridItem>
                </GridContainer>
                <div className="selection-body">
                  <DragDropContext
                    onDragEnd={this.onSelectedPositionDragEnd}
                  >
                    <Droppable
                      droppableId={SELECTION_DROPABLE_ID}
                    >
                      {(provided) => (
                        <div 
                         {...provided.droppableProps}
                         ref={provided.innerRef}
                         className="selection-list-container"
                        >
                          { data.selectedPositions.map((el, i) => (
                            <Draggable
                              draggableId={`selection-list-draggable-${el}`}
                              index={i}
                              key={`selection-list-draggable-${el}`}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  className="selection-list-item"
                                >
                                  <span
                                    {...provided.dragHandleProps}
                                    className="drag-img-container"
                                  >
                                    <img src={ButtonBurger} alt=""></img>
                                  </span>
                                  <span className="numeric-name">
                                    { orderData.positions.find(pos => pos.id == el).numeric_name }
                                  </span>
                                  <span className="name">
                                    { orderData.positions.find(pos => pos.id == el).name }
                                  </span>
                                  <IconButton aria-label="delete" className="delete-btn" onClick={() => this.handleRemoveSelectedPosition(el)}>
                                    <img alt="delete-btn" src={ButtonDelete} />
                                  </IconButton>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          { provided.placeholder }
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </GridItem>
            </div>
            <div className="summary-container">
              <GridContainer
                className="summary-header"
                direction="row"
              >
                <GridItem className="summary-header-title ts-col-price">
                  { t('total_price') }
                </GridItem>
                <GridItem className="summary-header-title ts-col-vat">
                  { t('total_vat') }
                </GridItem>
                <GridItem className="summary-header-title ts-col-discount">
                  { t('total_discount') }
                </GridItem>
              </GridContainer>
              <GridContainer
                className="summary-body"
                direction="row"
              >
                <GridItem className="summary-body-title ts-col-price">
                  { `${currencySymbol} ${formatNumericValue(totalSelectedPrice, 2)}` }
                </GridItem>
                <GridItem className="summary-body-title ts-col-vat">
                  { `${currencySymbol} ${formatNumericValue(totalSelectedVat, 2)}` }
                </GridItem>
                <GridItem className="summary-body-title ts-col-discount">
                  { `${currencySymbol} ${formatNumericValue(totalSelectedDiscount, 2)}` }
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProp = state => {
  return {
    unitsList: state.providers.taskUnitList,
    positionCosts: state.orders.orderPositionCosts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Step2));
