import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  RESET_CUSTOMER_DATA,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_ERROR,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMER_SUCCESS,
  REMOVE_CUSTOMER_FAILURE,
  REMOVE_CUSTOMERS,
  REMOVE_CUSTOMERS_SINGLE_SUCCESS,
  REMOVE_CUSTOMERS_SINGLE_FAILURE,
  REMOVE_CUSTOMERS_FINISH,
  VALIDATE_IMPORTED_CUSTOMER_FILE,
  VALIDATE_IMPORTED_CUSTOMER_FILE_SUCCESS,
  VALIDATE_IMPORTED_CUSTOMER_FILE_FAILURE,
  START_CUSTOMER_FILE_IMPORT,
  START_CUSTOMER_FILE_IMPORT_SUCCESS,
  START_CUSTOMER_FILE_IMPORT_FAILURE,
  RESET_CUSTOMER_IMPORT_DATA,
  RESET_CUSTOMER_IMPORT_VALIDATION,
} from '../actionTypes/customerActionTypes';

import NotificationService from 'services/NotificationService';

export const getCustomers = (page, filters, searchTerms) => {
  return {type: GET_CUSTOMERS, payload: { page, filters, searchTerms }}
};

export const getCustomersSuccess = (customers) => {
  return {type: GET_CUSTOMERS_SUCCESS, payload: customers}
};

export const getCustomersFailure = (error) => {
  return {type: GET_CUSTOMERS_ERROR, payload: error}
};

export const getCustomer = (id) => {
  return {type: GET_CUSTOMER, payload: id}
};

export const getCustomerSuccess = (customer) => {
  return {type: GET_CUSTOMER_SUCCESS, payload: customer}
};

export const resetCustomerData = () => {
  return {type: RESET_CUSTOMER_DATA}
};

export const addCustomer = (data, loadListInfo) => {
  return {type: ADD_CUSTOMER, payload: { data, loadListInfo }}
};

export const addCustomerSuccess = (data) => {
  NotificationService.success('Customer successfully created.');
  return {type: ADD_CUSTOMER_SUCCESS, payload: data}
};

export const addCustomerFailure = (error) => {
  return {type: ADD_CUSTOMER_ERROR, payload: error}
};

export const updateCustomer = (id, data, loadListInfo) => {
  return {type: UPDATE_CUSTOMER, payload: { id, data, loadListInfo }}
};

export const updateCustomerSuccess = (data) => {
  NotificationService.success('Customer was updated');
  return {type: UPDATE_CUSTOMER_SUCCESS, payload: data}
};

export const updateCustomerFailure = (error) => {
  return {type: UPDATE_CUSTOMER_ERROR, payload: error}
};

export const validateCustomerImportFile = data => {
  return {
    type: VALIDATE_IMPORTED_CUSTOMER_FILE,
    payload: data
  };
};

export const validateCustomerImportFileSuccess = data => {
  let fieldsArray = [];
  Object.keys(data.fields).map(item => fieldsArray.push({id: item, value: data.fields[item]}));
  return {
    type: VALIDATE_IMPORTED_CUSTOMER_FILE_SUCCESS,
    payload: {data, fieldsArray}
  };
};

export const validateCustomerImportFileFailure = error => {
  let errorData = '';
  if(error.responseStatus === 400){
    errorData = error.responseBody.errors;
  }
  else if(error.responseStatus === 422){
    errorData = error.responseBody.errors.file[0];
  }
  else {
    errorData = error.responseBody.error
  }
  NotificationService.error(errorData, true)
  return {
    type: VALIDATE_IMPORTED_CUSTOMER_FILE_FAILURE,
    payload: errorData
  };
};

export const startCustomerFileImport = data => {
  return {
    type: START_CUSTOMER_FILE_IMPORT,
    payload: data
  };
};

export const startCustomerFileImportSuccess = data => {
  return {
    type: START_CUSTOMER_FILE_IMPORT_SUCCESS,
    payload: data
  };
};

export const startCustomerFileImportFailure = error => {
  NotificationService.error(error, true);
  return {
    type: START_CUSTOMER_FILE_IMPORT_FAILURE,
    payload: error
  };
};

export const removeCustomer = (id, loadListInfo) => {
  return {type: REMOVE_CUSTOMER, payload: { id, loadListInfo }}
};

export const removeCustomerSuccess = () => {
  return {type: REMOVE_CUSTOMER_SUCCESS};
};

export const removeCustomerFailure = (error) => {
  return {type: REMOVE_CUSTOMER_FAILURE, payload: error};
};

export const removeCustomers = (data, loadListInfo) => {
  return {type: REMOVE_CUSTOMERS, payload: { data, loadListInfo }}
};

export const removeCustomersSingleSuccess = (id) => {
  return {type: REMOVE_CUSTOMERS_SINGLE_SUCCESS, payload: id};
};

export const removeCustomersSingleFailure = (id, error) => {
  return {type: REMOVE_CUSTOMERS_SINGLE_FAILURE, payload: { id, error }};
};

export const removeCustomersFinish = (page, filters, searchTerms) => {
  return {type: REMOVE_CUSTOMERS_FINISH, payload: {page, filters, searchTerms}}
};

export const resetCustomerImportData = () => {
  return {type: RESET_CUSTOMER_IMPORT_DATA};
};

export const resetCustomerImportValidationData = () => {
  return {type: RESET_CUSTOMER_IMPORT_VALIDATION};
};
