import { handleActions } from 'redux-actions';

import {
  GET_DEFAULT_COST_CENTER_LIST_SUCCESS,
  GET_MATERIALS_SETTINGS_SUCCESS,
  GET_MATERIALS_SETTINGS_ERROR,
  GET_MATERIAL_LIST_ALLOWANCE_SUCCESS,
  GET_MATERIAL_LIST_ALLOWANCE_ERROR,
  UPDATE_MATERIAL_LIST_ALLOWANCE_ERROR,
  GET_MATERIAL_LIST_ORIENTATION_DATA_SUCCESS,
  GET_MATERIAL_LIST_ORIENTATION_DATA_ERROR,
  ADD_MATERIAL_LIST_ORIENTATION_CLASS_ERROR,
  REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_ERROR,
  GET_WEB_SHOP_SETTINGS_SUCCESS,
  GET_WEB_SHOP_SETTINGS_ERROR,
  GET_PROJECTS_SETTINGS_SUCCESS,
  GET_PROJECTS_SETTINGS_ERROR,
  DEFAULT_COST_CENTER_LIST_ERROR,
  GET_LETTERHEADS_SUCCESS,
  GET_LETTERHEADS_ERROR,
  UPDATE_LETTERHEADS_SUCCESS,
  UPDATE_LETTERHEADS_ERROR,
  REMOVE_LETTERHEADS,
  REMOVE_LETTERHEAD_ENTRY_SUCCESS,
  REMOVE_LETTERHEAD_ENTRY_ERROR,
  REMOVE_LETTERHEADS_FINISH,
  RESET_SETTINGS_ERRORS,
} from '../actionTypes/settingsActionsTypes';

export const DEFAULT_SETTINGS_STATE = {
  costCenterList: [],
  materialsStandardSurcharges: [],
  materialsRawAllowances: [],
  materialsSettingsError: {},
  materialListAllowance: [],
  materialListAllowanceError: {},
  materialListOrientationData: [],
  materialListOrientationError: {},
  webShopSettings: {},
  webShopSettingsError: {},
  projectsSettings: {},
  projectsSettingsError: {},
  costCenterSettingsError: [],
  letterheads: null,
  letterheadsError: [],
};

export const settings = handleActions(
  {
    [GET_DEFAULT_COST_CENTER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      costCenterList: action.payload
    }),
    [GET_MATERIALS_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      materialsStandardSurcharges: action.payload.standardSurcharges,
      materialsRawAllowances: action.payload.rawAllowances,
    }),
    [GET_MATERIALS_SETTINGS_ERROR]: (state, action) => ({
      ...state,
      materialsSettingsError: action.payload,
    }),
    [GET_MATERIAL_LIST_ALLOWANCE_SUCCESS]: (state, action) => ({
      ...state,
      materialListAllowance: action.payload,
    }),
    [GET_MATERIAL_LIST_ALLOWANCE_ERROR]: (state, action) => ({
      ...state,
      materialListAllowanceError: action.payload,
    }),
    [UPDATE_MATERIAL_LIST_ALLOWANCE_ERROR]: (state, action) => ({
      ...state,
      materialListAllowanceError: action.payload,
    }),
    [GET_MATERIAL_LIST_ORIENTATION_DATA_SUCCESS]: (state, action) => ({
      ...state,
      materialListOrientationData: action.payload,
    }),
    [GET_MATERIAL_LIST_ORIENTATION_DATA_ERROR]: (state, action) => ({
      ...state,
      materialListOrientationError: action.payload,
    }),
    [ADD_MATERIAL_LIST_ORIENTATION_CLASS_ERROR]: (state, action) => ({
      ...state,
      materialListOrientationError: action.payload,
    }),
    [REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_ERROR]: (state, action) => ({
      ...state,
      materialListOrientationError: action.payload,
    }),
    [GET_WEB_SHOP_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      webShopSettings: action.payload
    }),
    [GET_WEB_SHOP_SETTINGS_ERROR]: (state, action) => ({
      ...state,
      webShopSettingsError: action.payload
    }),
    [GET_PROJECTS_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      projectsSettings: action.payload
    }),
    [GET_PROJECTS_SETTINGS_ERROR]: (state, action) => ({
      ...state,
      projectsSettingsError: action.payload
    }),
    [DEFAULT_COST_CENTER_LIST_ERROR]: (state, action) => ({
      ...state,
      costCenterSettingsError: action.payload
    }),
    [GET_LETTERHEADS_SUCCESS]: (state, action) => ({
      ...state,
      letterheads: {
        invoice_primary_header: action.payload.invoice_primary_header,
        invoice_secondary_header: action.payload.invoice_secondary_header,
      },
      letterheadsError: [],
    }),
    [GET_LETTERHEADS_ERROR]: (state, action) => ({
      ...state,
      letterheads: null,
      letterheadsError: action.payload,
    }),
    [UPDATE_LETTERHEADS_SUCCESS]: (state, action) => ({
      ...state,
      letterheadsError: null,
    }),
    [UPDATE_LETTERHEADS_ERROR]: (state, action) => ({
      ...state,
      letterheadsError: action.payload,
    }),
    [REMOVE_LETTERHEADS]: (state, action) => ({
      ...state,
      letterheadsError: [],
    }),
    [REMOVE_LETTERHEAD_ENTRY_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [REMOVE_LETTERHEAD_ENTRY_ERROR]: (state, action) => ({
      ...state,
      letterheadsError: [ ...state.letterheadsError, { [action.payload.id]: action.payload.errors } ],
    }),
    [REMOVE_LETTERHEADS_FINISH]: (state, action) => ({
      ...state,
    }),
    [RESET_SETTINGS_ERRORS]: (state) => ({
      ...state,
      webShopSettingsError: {},
      projectsSettingsError: {},
      materialsSettingsError: {},
      materialListAllowanceError: {},
      materialListOrientationError: {},
      costCenterSettingsError: []
    })
  },
  DEFAULT_SETTINGS_STATE
);
