import React from "react";
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from "react-perfect-scrollbar";

import ShippingCard from "./ShippingItem";

import './style.scss';
import Button from "../../components/CustomButtons/Button";

class ShippingDetails extends React.Component {
  render() {
    const {countries, cities, data, onAddNewAddress, onDataChange, onRemoveAddress, errors, t} = this.props;
    return(
      <div className="shipping-content">
        <PerfectScrollbar className="shipping-content-scrollbar">
          {data.length !== 0 &&
            data.map((item, index) => <ShippingCard errors={errors} countries={countries} cities={cities} data={item} key={index} id={index} onRemove = {() => onRemoveAddress(index)} onChange={(fieldName, value) => onDataChange(index, fieldName, value)} />)
          }
          <Button
            className="create-address-btn"
            onClick={() => onAddNewAddress()}
          >
            + { t('add_address').toUpperCase() }
          </Button>
        </PerfectScrollbar>
      </div>
    )
  }
}

export default withTranslation()(ShippingDetails);
