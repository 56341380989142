import BaseAPIService from './Base';

export default class CategoriesAPIService extends BaseAPIService {
  createCategory = async (data) => {
    const url = 'api/manufacturers/own/categories';
    return await this.request(url, {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  updateCategory = async (id, data) => {
    const url = 'api/manufacturers/own/categories';
    return await this.request(`${url}/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  deleteCategory = async (id) => {
    const url = 'api/manufacturers/own/categories';
    return await this.request(`${url}/${id}`, {
      method: 'DELETE',
    });
  };

  getCategory = async (id) => {
    const url = 'api/manufacturers/own/categories';
    return this.request(`${url}/${id}`).then((data) => {
      return data;
    });
  };

  getCategoriesTypes = async () => {
    return this.request('api/manufacturers/categories/provider/types').then((data) => {
      return data;
    });
  };

  getParentCategories = async (data) => {
    return this.request(`api/manufacturers/categories/provider/own/root-list?type=${data.type}`).then((data) => {
      return data;
    });
  };

  getMaterialCategories = async () => {
    return this.request('api/manufacturers/own/categories?filter[type]=0&per-page=0').then((data) => {
      return data;
    });
  };

  getMaterialCategoriesProvider = async () => {
    return this.request('api/manufacturers/categories/provider/own/filter-list?type=0').then((data) => {
      return data;
    });
  };

  getOwnCategories = async ({page, type, searchTerms, isGetAllData, rootOnly}) => {
    const url = 'api/manufacturers/own/categories';
    return this.request(`${url}?page=${page ? page : 1}${type !== '' ? `&filter[type]=${type}` : ''}${searchTerms ? ("&search-term=" + searchTerms) : ''}${isGetAllData ? '&per-page=0' : ''}${rootOnly ? '&filter[parent_id]=' : ''}`).then((data) => {
      return data;
    });
  };

  getOwnContactCategoriesHierarchy = async () => {
    return this.request('api/manufacturers/own/contact-categories').then((data) => {
      return data;
    });
  };

  getCategories = async ({page, type, order, searchTerms, isGetAllData, level1Only}) => {
    let types = [];
    if (type.length > 0 && type[0] != -1) {
      for (let i in type) {
        types.push(`filter[type][${i}]=${type[i]}`);
      }
    }
    const typesPart = types.length > 0 ? `&${types.join("&")}` : '';
    const url = 'api/manufacturers/own/categories';
    return this.request(`${url}?page=${page ? page : 1}${order ? `&order=${order}` : ''}${typesPart}${searchTerms ? ("&search-term=" + searchTerms) : ''}${isGetAllData ? '&per-page=0' : ''}${level1Only ? '&filter[parent_id]=' : ''}`).then((data) => {
      return data;
    });
  };

  getChildCategories = async (parentId) => {
    const url = 'api/manufacturers/own/categories';
    return this.request(`${url}?filter[parent_id]=${parentId}`).then((data) => {
      return data;
    });
  }
}
