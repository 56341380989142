import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import FileSaver from 'file-saver';

import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CustomDateRangePickerDropdown from 'components/CustomDateRangePickerDropdown/index';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import IconButton from '@material-ui/core/IconButton';
import CloseButton from '../../assets/img/buttons/delete.svg';
import EditButton from '../../assets/img/buttons/edit.svg';
import PrintButton from '../../assets/img/buttons/print.png';
import DownloadButton from '../../assets/img/buttons/invoices-download.png';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MaterialArrowUp from 'assets/img/material_arrow_up.svg';
import MaterialArrowDown from 'assets/img/material_arrow_down.svg';

import CreateInvoice from "../CreateInvoice";
import RemoveDialog from "../RemoveDialog";

import { getAllInvoices, deleteInvoice, setInvoiceStatus, downloadInvoice } from 'store/actions/invoices';
import {
  getOrder,
} from 'store/actions/orders';
import { InvoiceStatusList, InvoiceTypes } from 'constants/constants';

import './style.scss';

import Loader from '../../components/Loader/Loader';
import CustomPagination from '../../components/CustomPagination';

import { getInvoiceStyleProgressDependsOnStatus } from 'utils/utils';

class InvoiceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateInvoiceOpen: false,
      isRemoveDialogOpen: false,
      deleteEntryId: null,
      isDownloadMenuOpen: false,
      downloadMenuInvoiceIndex: null,
      downloadButtonRefs: [],
      previewInvoice: false,
      filters: {
        page: '1',
        type: InvoiceTypes['invoice'],
        dateFrom: null,
        dateTo: null,
      },
      sortBy: 'issued_at',
      sortByOrder: true,
      editInvoice: null,
      editInvoiceOrderRetrieved: false,
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.invoices !== this.props.invoices) {
      if (this.props.invoices) {
        this.setState({
          downloadButtonRefs: this.props.invoices.map(el => React.createRef()),
        });
      } else {
        this.setState({
          downloadButtonRefs: [],
        });
      }
    }

    if (this.props.invoiceDownloadContent && prevProps.invoiceDownloadContent !== this.props.invoiceDownloadContent) {
      if (!this.state.previewInvoice) {
        // download
        FileSaver.saveAs(this.props.invoiceDownloadContent, this.props.invoiceDownloadFilename);
      } else {
        // open in new tab
        const fileURL = URL.createObjectURL(this.props.invoiceDownloadContent);
        const newTab = window.open(fileURL);
        setTimeout(() => {
          newTab.document.title = this.props.invoiceDownloadFilename;
        }, 10);
      }
    }
    
    if (prevProps.order != this.props.order && this.props.order && this.state.editInvoice && !this.state.editInvoiceOrderRetrieved && this.props.order.id == this.state.editInvoice.order_id) {
      this.setState({
        isCreateInvoiceOpen: true,
        editInvoiceOrderRetrieved: true,
      });
    }
  }

  reloadData = () => {
    const { searchTerms } = this.props;
    const { filters, sortBy, sortByOrder } = this.state;
    this.props.getAllInvoices(searchTerms, filters, sortBy, sortByOrder);
  }

  handleFiltersChange = (fieldName, value) => {
    const { filters } = this.state;
    const newFilters = { ...filters };
    newFilters[fieldName] = value;
    this.setState({
      filters: newFilters,
    }, () => {
      this.reloadData();
    });
  };

  handleSortChange = (id) => {
    this.setState({
      sortBy: id,
      sortByOrder: true,
    }, () => {
      this.reloadData();
    });
  }

  handleSortFlipOrder = () => {    
    this.setState({
      sortByOrder: !this.state.sortByOrder,
    }, () => {
      this.reloadData();
    });
  };

  handleDateFilterChange = (name, value) => {
    let date = null;
    if (value) {
      date = moment(value).format('YYYY-MM-DD'); 
    }
    this.handleFiltersChange(name, date);
  }

  handlePageClick = data => {
    const { selected } = data;
    this.handleFiltersChange('page', selected + 1);
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    if (wrapper)
      wrapper.scrollTop = 0;
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveDialogOpen: false,
      deleteEntryId: null,
    });
  }

  handleCreateInvoiceClose = () => {
    if (this.state.isCreateInvoiceOpen) {
      this.setState({ isCreateInvoiceOpen: false, editInvoice: null, editInvoiceOrderRetrieved: false });
    }
  }

  handleInvoiceStatusChange = (invoiceIndex, newStatusId) => {
    const { invoices } = this.props;
    const invoice = invoices[invoiceIndex];
    this.props.setInvoiceStatus(invoice.id, newStatusId, this.props.searchTerms, this.state.filters, this.state.sortBy, this.state.sortByOrder);
  }

  handleDeleteInvoiceButton = (invoiceIndex) => {
    const { invoices } = this.props;
    this.setState({
      isRemoveDialogOpen: true,
      deleteEntryId: invoices[invoiceIndex].id,
    });
  }

  handleDeleteInvoice = () => {
    const { deleteEntryId } = this.state;
    this.props.deleteInvoice(deleteEntryId, this.props.searchTerms, this.state.filters, this.state.sortBy, this.state.sortByOrder);
    this.handleRemoveDialogClose();
  }

  handlePrintInvoice = (invoiceIndex) => {
    const { invoices } = this.props;
    this.setState({
      previewInvoice: true,
    }, () => {
      this.props.downloadInvoice(invoices[invoiceIndex].id, 1, invoices[invoiceIndex].invoice_no + '.pdf');
    });
  }

  handleEditInvoice = (invoiceIndex) => {
    const { invoices, getOrder } = this.props;
    const invoice = invoices[invoiceIndex];
    this.setState({
      editInvoice: invoice,
      editInvoiceOrderRetrieved: false,
    });
    getOrder(invoice.order_id);
  }

  handleDownloadInvoice = (invoiceIndex) => {
    this.setState({
      isDownloadMenuOpen: true,
      downloadMenuInvoiceIndex: invoiceIndex,
      previewInvoice: false,
    });
  }

  handleDownloadInvoiceOption = (option) => {
    const { invoices } = this.props;
    const { downloadMenuInvoiceIndex } = this.state;
    let extension = '';
    switch (option) {
      case 1:
        extension = 'pdf';
        break;
      case 2:
        extension = 'odt';
        break;
      case 3:
        extension = 'rtf';
        break;
    }
    this.props.downloadInvoice(invoices[downloadMenuInvoiceIndex].id, option, (!invoices[downloadMenuInvoiceIndex].is_drafted ? invoices[downloadMenuInvoiceIndex].invoice_no : 'draft_invoice') + '.' + extension);
    this.handleDownloadMenuClose();
  }

  handleDownloadMenuClose = () => {
    this.setState({
      isDownloadMenuOpen: false,
      downloadMenuInvoiceIndex: null,
    });
  }

  handleCreateItem = (typeId) => {
    this.showCreateInvoiceDialog(InvoiceTypes[typeId]);
  }

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return text;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  renderInvoice = (invoice, index) => {
    const { t } = this.props;
    const { downloadButtonRefs } = this.state;
    const styleProgressData = getInvoiceStyleProgressDependsOnStatus(invoice.status, InvoiceStatusList);

    const customerName = invoice.customer_info ? `${invoice.customer_info.first_name}${invoice.customer_info.last_name ? ' ' + invoice.customer_info.last_name : ''}` : 'N/A';
    const issuedAtParts = invoice.issued_at ? invoice.issued_at.split('-').map(el => parseInt(el)) : null;
    const issuedAt = issuedAtParts ? new Date(issuedAtParts[0], issuedAtParts[1] - 1, issuedAtParts[2]) : null;
    const paymentTermsMeta = invoice.meta.find(el => el.name == 'payment_terms');
    const paymentTermsValidUntil = issuedAt ? new Date(issuedAt.getFullYear(), issuedAt.getMonth(), issuedAt.getDate() + (paymentTermsMeta ? parseInt(paymentTermsMeta.value) : 0)) : null;
    const invoiceType = Object.keys(InvoiceTypes).find(el => InvoiceTypes[el] === invoice.type);
    const formatDate = date => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const days = date.getDate();
      return `${days >= 10 ? days : '0' + days}/${month >= 10 ? month : '0' + month}/${year}`;
    };
    
    return (
      <GridContainer
        className={`invoices-item ${index % 2 === 0 ? 'dark' : ''}`}
        direction="row"
        key={index}
      >
        <GridItem className="invoices-item-title ts-col-date">
          { issuedAt ? formatDate(issuedAt) : '-' }
        </GridItem>
        <GridItem className="invoices-item-title ts-col-number">
          { invoice.invoice_no ? this.wrapText(invoice.invoice_no, 26) : '-' }
        </GridItem>
        <GridItem className="invoices-item-title ts-col-type">
          { this.wrapText(t(invoiceType), 26) }
        </GridItem>
        <GridItem className="invoices-item-title ts-col-client">
          { this.wrapText(customerName, 14) }
        </GridItem>
        <GridItem className="invoices-item-title ts-col-payment-terms">
          { paymentTermsValidUntil ? formatDate(paymentTermsValidUntil) : '-' }
        </GridItem>
        <GridItem className="invoices-item-title right-aligned ts-col-total-neto">
          { `${invoice.total_neto_value ? invoice.total_neto_value : 'N/A'}` }
        </GridItem>
        <GridItem className="invoices-item-title ts-col-billing-peculiarities">
          { this.wrapText(invoice.billing_peculiarities, 20) }
        </GridItem>
        <GridItem className="invoices-item-title right-aligned ts-col-brutto">
          { invoice.brutto ? invoice.brutto : 'N/A' }
        </GridItem>
        <GridItem className="invoices-item-title right-aligned ts-col-total">
          { invoice.total ? invoice.total : 'N/A' }
        </GridItem>
        <GridItem className={`invoices-item-title ts-col-status ${styleProgressData.style}`}>
          <Dropdown
            buttonText={invoice.status}
            buttonIconType="white"
            buttonProps={{className: 'dropdown-blue-style status-dropdown', round: true, size: "sm"}}
            hoverColor="dark"
            dropdownList={InvoiceStatusList}
            translateValues={true}
            onClick={data => this.handleInvoiceStatusChange(index, data.id)}
          />
        </GridItem>
        <GridItem
          className="invoices-item-title invoices-action-btn right-aligned ts-col-action-btn"
        >
          { Boolean(invoice.modifiable) && (
            <IconButton aria-label="close" className="action-btn" onClick={() => this.handleDeleteInvoiceButton(index)}>
              <img alt="invoices-delete-btn" src={CloseButton} />
            </IconButton>
          ) }
          <IconButton aria-label="print" className="action-btn" onClick={() => this.handlePrintInvoice(index)}>
            <img alt="invoices-print-btn" src={PrintButton} />
          </IconButton>
          { Boolean(invoice.modifiable) && (
            <IconButton aria-label="edit" className="action-btn" onClick={() => this.handleEditInvoice(index)}>
              <img alt="invoices-edit-btn" src={EditButton} />
            </IconButton>
          ) }
          <IconButton aria-label="download" className="action-btn" onClick={() => this.handleDownloadInvoice(index)}>
            <img alt="invoices-download-btn" src={DownloadButton} ref={downloadButtonRefs[index]} />
          </IconButton>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const {
      invoices,
      invoicesBusy,
      metaData,
      t
    } = this.props;
    const {
      isCreateInvoiceOpen,
      isRemoveDialogOpen,
      isDownloadMenuOpen,
      downloadMenuInvoiceIndex,
      downloadButtonRefs,
      filters,
      sortBy,
      sortByOrder,
    } = this.state;
    const sortByList = [{id: 'issued_at', value: 'sort_by_date'}, {id: 'invoice_no', value: 'sort_by_number'}];
    const typesList = Object.keys(InvoiceTypes).map(el => ({
      id: InvoiceTypes[el],
      value: el,
    }));
    return (
      <>
        <div className="project-invoices-list-outer-container">
          <GridContainer className="project-invoices-container">
            <GridItem className="project-invoices-top" xs={12}>
              <GridItem xs={3} />
              <GridItem className="invoices-top-right-bar">
                <Dropdown
                  buttonText={filters.type}
                  buttonProps={{
                    className: 'dropdown-blue-style',
                    round: true,
                    size: 'sm'
                  }}
                  dropdownList={typesList}
                  translateValues={true}
                  onClick={data => this.handleFiltersChange('type', data.id)}
                />
                <CustomDateRangePickerDropdown
                  dateFrom={filters.dateFrom}
                  dateTo={filters.dateTo}
                  dateFromPlaceholder={t('from') + "..."}
                  dateToPlaceholder={t('up_to') + "..."}
                  buttonPlaceholder={t('filter_dates')}
                  buttonProps={{
                    className: 'dropdown-blue-style',
                    color: 'transparent',
                    round: true,
                    size: 'sm'
                  }}
                  hoverColor="rose"
                  onChange={(code, date) => this.handleDateFilterChange(code == 0 ? 'dateFrom' : 'dateTo', date)}
                />
                <Dropdown
                  buttonText={sortBy}
                  buttonProps={{
                    className: 'dropdown-blue-style',
                    round: true,
                    size: 'sm'
                  }}
                  dropdownList={sortByList}
                  translateValues={true}
                  onClick={data => this.handleSortChange(data.id)}
                />
              </GridItem>
            </GridItem>
            <GridContainer
              className="invoices-header"
              direction="row"
            >
              <GridItem className={`invoices-header-title ts-col-date${sortBy == 'issued_at' ? ' col-sort-active' : ''}`}>
                <span onClick={sortBy == 'issued_at' ? this.handleSortFlipOrder : null}>
                  { t('date') } { sortBy == 'issued_at' && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className={`invoices-header-title ts-col-number${sortBy == 'invoice_no' ? ' col-sort-active' : ''}`}>
                <span onClick={sortBy == 'invoice_no' ? this.handleSortFlipOrder : null}>
                  { t('number') } { sortBy == 'invoice_no' && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className="invoices-header-title ts-col-type">
                { t('type') }
              </GridItem>
              <GridItem className="invoices-header-title ts-col-client">
                { t('client') }
              </GridItem>
              <GridItem className="invoices-header-title ts-col-payment-terms">
                { t('payment_terms_date') }
              </GridItem>
              <GridItem className="invoices-header-title right-aligned ts-col-total-neto">
                { t('total_neto_value') }
              </GridItem>
              <GridItem className="invoices-header-title ts-col-billing-peculiarities">
                { t('billing_peculiarities') }
              </GridItem>
              <GridItem className="invoices-header-title right-aligned ts-col-brutto">
                { t('brutto') }
              </GridItem>
              <GridItem className="invoices-header-title right-aligned ts-col-total right-aligned">
                { t('total') }
              </GridItem>
              <GridItem className="invoices-header-title ts-col-status">
                { t('status') }
              </GridItem>
              <GridItem className="invoices-header-title right-aligned ts-col-action-btn right-aligned">
                { t('actions') }
              </GridItem>
            </GridContainer>
            { !invoicesBusy ? (
              <GridContainer className="invoices-content">
                {invoices && invoices.length !== 0 ? 
                  invoices.map((invoice, index) =>
                    this.renderInvoice(invoice, index)
                  ) :
                  <div className='invoices-content-no-records'>
                    <span className='no-records'>{ t('no_records_found') }</span>
                  </div>
                }
              </GridContainer>
            ) : (
              <div className="ikt-ap_loader">
                <Loader />
              </div>
            )}
          </GridContainer>
          { isCreateInvoiceOpen &&
            <CreateInvoice
              isOpen={isCreateInvoiceOpen}
              onClose={this.handleCreateInvoiceClose}
              currentFilters={this.state.filters}
              currentSortBy={sortBy}
              currentSortByOrder={sortByOrder}
              currentSearchTerms={this.props.searchTerms}
              orderData={this.props.order}
              preventLoadOrderData={true}
              edit={this.state.editInvoice}
            />
          }
          { isRemoveDialogOpen && (
            <RemoveDialog
              isOpen={isRemoveDialogOpen}
              title={t('remove_invoice')}
              text={t('remove_invoice_confirmation')}
              onClose={this.handleRemoveDialogClose}
              onRemove={this.handleDeleteInvoice}
            />
          )}
          {
            isDownloadMenuOpen && (
              <Popper
                open={isDownloadMenuOpen}
                anchorEl={downloadButtonRefs[downloadMenuInvoiceIndex] && downloadButtonRefs[downloadMenuInvoiceIndex].current}
                transition
                disablePortal
                placement="bottom-end"
                className="popper-download-menu"
              >
                <ClickAwayListener onClickAway={this.handleDownloadMenuClose}>
                  <ul className="download-list">
                    <li onClick={() => this.handleDownloadInvoiceOption(1)}>{t('download_pdf')}</li>
                    <li onClick={() => this.handleDownloadInvoiceOption(2)}>{t('download_open_document')}</li>
                    <li onClick={() => this.handleDownloadInvoiceOption(3)}>{t('download_otf')}</li>
                  </ul>
                </ClickAwayListener>
              </Popper>
            )
          }
        </div>
        <GridContainer className="pagination-container">
        { metaData.last_page > 1 &&
          <CustomPagination
            pageCount={Math.ceil(metaData.total / metaData.per_page)}
            handlePageClick={this.handlePageClick}
          />
        }
        </GridContainer>
      </>
    );
  }
}

const mapStateToProp = state => {
  return {
    invoices: state.invoices.invoices,
    invoicesBusy: state.invoices.invoicesBusy,
    invoiceDownloadContent: state.invoices.downloadContent,
    invoiceDownloadFilename: state.invoices.downloadFilename,
    metaData: state.invoices.invoicesMetaData,
    order: state.orders.currentOrder,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllInvoices: (searchTerms, filters, sort, sortOrder) => dispatch(getAllInvoices(searchTerms, filters, sort, sortOrder)),
    deleteInvoice: (invoiceId, searchTerms, filters, sortBy, sortByOrder) => dispatch(deleteInvoice(invoiceId, null, searchTerms, filters, sortBy, sortByOrder)),
    setInvoiceStatus: (invoiceId, statusId, searchTerms, filters, sortBy, sortByOrder) => dispatch(setInvoiceStatus(invoiceId, null, statusId, searchTerms, filters, sortBy, sortByOrder)),
    downloadInvoice: (invoiceId, formatId, filename) => dispatch(downloadInvoice(invoiceId, formatId, filename)),
    getOrder: id => dispatch(getOrder(id)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(InvoiceList));
