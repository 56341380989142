import React from 'react';

import { connect } from 'react-redux';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Position from './FittingsPosition';

import './style.scss';

class Fittings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandFirstCard: true,
    };
  }

  componentDidMount() {
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    wrapper.scrollTop = 0;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.expandFirstCard) {
      this.setState({
        expandFirstCard: false,
      });
    }
  }

  render() {
    const { fittingsPositions, positionsData, errors, onDataChange } = this.props;
    const { expandFirstCard } = this.state;
    return (
      <GridContainer>
        <GridItem className="fittings-positions-container" xs={12}>
          {
            fittingsPositions.length !== 0 &&
              fittingsPositions.map((position, index) => {
                return(
                  <Position
                    isCardOpen={expandFirstCard && index === 0}
                    key={index}
                    onChange={(data) => onDataChange(index, data)}
                    data={position}
                    title={positionsData[index].title}
                    numericName={positionsData[index].numeric_name}
                    id={index}
                    modifiable={positionsData[index].can_edit}
                    errors={errors[index]}
                  />
                )
              })
          }
          <div className="list-space-holder">{ '\u00A0' }</div>
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProp = state => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(Fittings);
