export const GET_ORDERS = 'ordersEpic/GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'ordersEpic/GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'ordersEpic/GET_ORDERS_ERROR';

export const GET_CLIENT_INQUIRIES = 'ordersEpic/GET_CLIENT_INQUIRIES';
export const GET_CLIENT_INQUIRIES_SUCCESS = 'ordersEpic/GET_CLIENT_INQUIRIES_SUCCESS';
export const GET_CLIENT_INQUIRIES_ERROR = 'ordersEpic/GET_CLIENT_INQUIRIES_ERROR';

export const GET_ORDER = 'ordersEpic/GET_ORDER';
export const GET_ORDER_SUCCESS = 'ordersEpic/GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'ordersEpic/GET_ORDER_ERROR';

export const GET_ORDER_TOTAL_COST = 'ordersEpic/GET_ORDER_TOTAL_COST';
export const GET_ORDER_TOTAL_COST_SUCCESS = 'ordersEpic/GET_ORDER_TOTAL_COST_SUCCESS';
export const GET_ORDER_TOTAL_COST_ERROR = 'ordersEpic/GET_ORDER_TOTAL_COST_ERROR';

export const GET_ORDER_SETTINGS = 'ordersEpic/GET_ORDER_SETTINGS';
export const GET_ORDER_SETTINGS_SUCCESS = 'ordersEpic/GET_ORDER_SETTINGS_SUCCESS';
export const GET_ORDER_SETTINGS_ERROR = 'ordersEpic/GET_ORDER_SETTINGS_ERROR';

export const UPDATE_ORDER_SETTINGS = 'ordersEpic/UPDATE_ORDER_SETTINGS';
export const UPDATE_ORDER_SETTINGS_SUCCESS = 'ordersEpic/UPDATE_ORDER_SETTINGS_SUCCESS';
export const UPDATE_ORDER_SETTINGS_ERROR = 'ordersEpic/UPDATE_ORDER_SETTINGS_ERROR';

export const CREATE_ORDER = 'ordersEpic/CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'ordersEpic/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'ordersEpic/CREATE_ORDER_ERROR';

export const DUPLICATE_ORDER = 'ordersEpic/DUPLICATE_ORDER';
export const DUPLICATE_ORDER_SUCCESS = 'ordersEpic/DUPLICATE_ORDER_SUCCESS';
export const DUPLICATE_ORDER_ERROR = 'ordersEpic/DUPLICATE_ORDER_ERROR';

export const REMOVE_ORDER = 'ordersEpic/REMOVE_ORDER';
export const REMOVE_ORDER_SUCCESS = 'ordersEpic/REMOVE_ORDER_SUCCESS';
export const REMOVE_ORDER_ERROR = 'ordersEpic/REMOVE_ORDER_ERROR';

export const UPDATE_ORDER = 'ordersEpic/UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'ordersEpic/UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = 'ordersEpic/UPDATE_ORDER_ERROR';

export const VALIDATE_ORDER_DETAILS = 'ordersEpic/VALIDATE_ORDER_DETAILS';
export const VALIDATE_ORDER_DETAILS_SUCCESS = 'ordersEpic/VALIDATE_ORDER_DETAILS_SUCCESS';
export const VALIDATE_ORDER_DETAILS_FAILURE = 'ordersEpic/VALIDATE_ORDER_DETAILS_FAILURE';
export const RESET_ORDER_DETAILS_DATA = 'ordersEpic/RESET_ORDER_DETAILS_DATA';

export const VALIDATE_ORDER_POSITIONS = 'ordersEpic/VALIDATE_ORDER_POSITIONS';
export const VALIDATE_ORDER_POSITIONS_SUCCESS = 'ordersEpic/VALIDATE_ORDER_POSITIONS_SUCCESS';
export const VALIDATE_ORDER_POSITIONS_FAILURE = 'ordersEpic/VALIDATE_ORDER_POSITIONS_FAILURE';
export const RESET_ORDER_POSITIONS_DATA = 'ordersEpic/RESET_ORDER_POSITIONS_DATA';

export const VALIDATE_POSITIONS_TASK = 'ordersEpic/VALIDATE_POSITIONS_TASK';
export const VALIDATE_POSITIONS_TASK_SUCCESS = 'ordersEpic/VALIDATE_POSITIONS_TASK_SUCCESS';
export const VALIDATE_POSITIONS_TASK_FAILURE = 'ordersEpic/VALIDATE_POSITIONS_TASK_FAILURE';
export const RESET_POSITIONS_TASK_ERRORS = 'ordersEpic/RESET_POSITIONS_TASK_ERRORS';

export const VALIDATE_IMPORTED_POSITION_FILE = 'orderEpic/VALIDATE_IMPORTED_POSITION_FILE';
export const VALIDATE_IMPORTED_POSITION_FILE_SUCCESS = 'orderEpic/VALIDATE_IMPORTED_POSITION_FILE_SUCCESS';
export const VALIDATE_IMPORTED_POSITION_FILE_FAILURE = 'orderEpic/VALIDATE_IMPORTED_POSITION_FILE_FAILURE';

export const START_POSITION_FILE_IMPORT = 'orderEpic/START_POSITION_FILE_IMPORT';
export const START_POSITION_FILE_IMPORT_SUCCESS = 'orderEpic/START_POSITION_FILE_IMPORT_SUCCESS';
export const START_POSITION_FILE_IMPORT_FAILURE = 'orderEpic/START_POSITION_FILE_IMPORT_FAILURE';

export const START_TASK_FILE_IMPORT = 'orderEpic/START_TASK_FILE_IMPORT';
export const START_TASK_FILE_IMPORT_SUCCESS = 'orderEpic/START_TASK_FILE_IMPORT_SUCCESS';
export const START_TASK_FILE_IMPORT_FAILURE = 'orderEpic/START_TASK_FILE_IMPORT_FAILURE';

export const VALIDATE_ORDER_COST_CENTER = 'ordersEpic/VALIDATE_ORDER_COST_CENTER';
export const VALIDATE_ORDER_COST_CENTER_SUCCESS = 'ordersEpic/VALIDATE_ORDER_COST_CENTER_SUCCESS';
export const VALIDATE_ORDER_COST_CENTER_FAILURE = 'ordersEpic/VALIDATE_ORDER_COST_CENTER_FAILURE';
export const RESET_COST_CENTER_DATA = 'ordersEpic/RESET_COST_CENTER_DATA';

export const VALIDATE_ORDER_FITTINGS = 'ordersEpic/VALIDATE_ORDER_FITTINGS';
export const VALIDATE_ORDER_FITTINGS_SUCCESS = 'ordersEpic/VALIDATE_ORDER_FITTINGS_SUCCESS';
export const VALIDATE_ORDER_FITTINGS_FAILURE = 'ordersEpic/VALIDATE_ORDER_FITTINGS_FAILURE';
export const RESET_FITTINGS_DATA = 'ordersEpic/RESET_FITTINGS_DATA';

export const VALIDATE_ORDER_COST_CENTER_ITEM = 'ordersEpic/VALIDATE_ORDER_COST_CENTER_ITEM';
export const VALIDATE_ORDER_COST_CENTER_ITEM_SUCCESS = 'ordersEpic/VALIDATE_ORDER_COST_CENTER_ITEM_SUCCESS';
export const VALIDATE_ORDER_COST_CENTER_ITEM_FAILURE = 'ordersEpic/VALIDATE_ORDER_COST_CENTER_ITEM_FAILURE';

export const GET_ORDER_POSITION_DEFAULT_COST_CENTER = 'ordersEpic/GET_ORDER_POSITION_DEFAULT_COST_CENTER';
export const GET_ORDER_POSITION_DEFAULT_COST_CENTER_SUCCESS = 'ordersEpic/GET_ORDER_POSITION_DEFAULT_COST_CENTER_SUCCESS';
export const GET_ORDER_POSITION_DEFAULT_COST_CENTER_FAILURE = 'ordersEpic/GET_ORDER_POSITION_DEFAULT_COST_CENTER_FAILURE';

export const ORDER_POSITION_CALCULATION = 'ordersEpic/ORDER_POSITION_CALCULATION';
export const ORDER_POSITION_CALCULATION_SUCCESS = 'ordersEpic/ORDER_POSITION_CALCULATION_SUCCESS';
export const ORDER_POSITION_CALCULATION_FAILURE = 'ordersEpic/ORDER_POSITION_CALCULATION_FAILURE';

export const ORDER_COST_CENTER_CALCULATION = 'ordersEpic/ORDER_COST_CENTER_CALCULATION';
export const ORDER_COST_CENTER_CALCULATION_SUCCESS = 'ordersEpic/ORDER_COST_CENTER_CALCULATION_SUCCESS';
export const ORDER_COST_CENTER_CALCULATION_FAILURE = 'ordersEpic/ORDER_COST_CENTER_CALCULATION_FAILURE';

export const SET_ORDER_STARRED = 'ordersEpic/SET_ORDER_STARRED';

export const SET_ORDER_STATUS = 'orderEpic/SET_ORDER_STATUS';
export const SET_ORDER_STATUS_SUCCESS = 'orderEpic/SET_ORDER_STATUS_SUCCESS';
export const SET_ORDER_STATUS_FAILURE = 'orderEpic/SET_ORDER_STATUS_FAILURE';

export const SET_ORDER_DEADLINE = 'orderEpic/SET_ORDER_DEADLINE';

export const FILTER_ORDERS = 'ordersEpic/FILTER_ORDERS';

export const RESET_ORDER_DATA = 'ordersEpic/RESET_ORDER_DATA';

export const RESET_FILE_IMPORT_DATA = 'orderEpic/RESET_FILE_IMPORT_DATA';
