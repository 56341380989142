import {
  ADD_MANUFACTURER_REQUEST,
  ADD_MANUFACTURER_REQUEST_SUCCESS,
  ADD_MANUFACTURER_REQUEST_ERROR,
  UPDATE_MANUFACTURER_REQUEST,
  UPDATE_MANUFACTURER_REQUEST_SUCCESS,
  UPDATE_MANUFACTURER_REQUEST_ERROR,
  ADD_MANUFACTURER,
  ADD_MANUFACTURER_SUCCESS,
  ADD_MANUFACTURER_ERROR,
  UPDATE_MANUFACTURER,
  UPDATE_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER_ERROR,
  GET_MANUFACTURERS,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_ERROR,
  GET_MANUFACTURER_CONTACTS,
  GET_MANUFACTURER_CONTACTS_SUCCESS,
  GET_MANUFACTURER_CONTACTS_FAILURE,
  GET_MANUFACTURER,
  GET_MANUFACTURER_SUCCESS,
  GET_MANUFACTURER_ERROR,
  GET_MANUFACTURER_SETTINGS,
  GET_MANUFACTURER_SETTINGS_SUCCESS,
  GET_MANUFACTURER_SETTINGS_ERROR,
  SET_MANUFACTURER_STATUS,
  FILTER_MANUFACTURERS,
  RESET_MANUFACTURER_DATA,
  REMOVE_MANUFACTURER,
  REMOVE_MANUFACTURER_SUCCESS,
  REMOVE_MANUFACTURER_ERROR
} from '../actionTypes/manufacturerActionTypes';

import NotificationService from '../../services/NotificationService';
import dot from "dot-object";

export const addManufacturerRequest = (data) => {
  return {type: ADD_MANUFACTURER_REQUEST, payload: data}
};

export const addManufacturerRequestSuccess = () => {
  NotificationService.success('Manufacturer adding request was done');
  return {type: ADD_MANUFACTURER_REQUEST_SUCCESS}
};

export const addManufacturerRequestFailure = (error) => {
  NotificationService.error(error);
  return {type: ADD_MANUFACTURER_REQUEST_ERROR, payload: error}
};

export const updateManufacturerRequest = ({id, data}) => {
  return {type: UPDATE_MANUFACTURER_REQUEST, payload: {id, data}}
};

export const updateManufacturerRequestSuccess = () => {
  NotificationService.success('Manufacturer updating request was done');
  return {type: UPDATE_MANUFACTURER_REQUEST_SUCCESS}
};

export const updateManufacturerRequestFailure = (error) => {
  NotificationService.error(error);
  return {type: UPDATE_MANUFACTURER_REQUEST_ERROR, payload: error}
};

export const addManufacturer = (data) => {
  return {type: ADD_MANUFACTURER, payload: data}
};

export const addManufacturerSuccess = () => {
  NotificationService.success('Manufacturer added successfully');
  return {type: ADD_MANUFACTURER_SUCCESS}
};

export const addManufacturerFailure = (data) => {
  if (data.error) {
    NotificationService.error(data.error);
  }
  let errorData = data.errors ? dot.object(data.errors) : {};
  return {type: ADD_MANUFACTURER_ERROR, payload: errorData}
};

export const updateManufacturer = (data) => {
  return {type: UPDATE_MANUFACTURER, payload: data}
};

export const updateManufacturerSuccess = () => {
  NotificationService.success('Manufacturer updated');
  return {type: UPDATE_MANUFACTURER_SUCCESS}
};

export const updateManufacturerFailure = (data) => {
  if (data.error) {
    NotificationService.error(data.error);
  }
  let errorData = data.errors ? dot.object(data.errors) : {};
  return {type: UPDATE_MANUFACTURER_ERROR, payload: errorData}
};

export const removeManufacturer = (id, loadListInfo) => {
  return {type: REMOVE_MANUFACTURER, payload: {id, loadListInfo}}
};

export const removeManufacturerSuccess = () => {
  return {type: REMOVE_MANUFACTURER_SUCCESS}
};

export const removeManufacturerFailure = (error) => {
  return {type: REMOVE_MANUFACTURER_ERROR, payload: error}
};

export const getManufacturers = (searchTerms) => {
  return {type: GET_MANUFACTURERS, payload: searchTerms}
};

export const getManufacturersSuccess = (manufacturers) => {
  return {type: GET_MANUFACTURERS_SUCCESS, payload: manufacturers}
};

export const getManufacturersFailure = (error) => {
  return {type: GET_MANUFACTURERS_ERROR, payload: error}
};

export const getManufacturerContacts = () => {
  return {type: GET_MANUFACTURER_CONTACTS}
};

export const getManufacturerContactsSuccess = (data) => {
  let contactsData = [];
  data.map((item) => contactsData.push({user_id: item.id, user: {name: item.first_name}}));
  return {type: GET_MANUFACTURER_CONTACTS_SUCCESS, payload: contactsData}
};

export const getManufacturerContactsFailure = (error) => {
  return {type: GET_MANUFACTURER_CONTACTS_FAILURE, payload: error}
};

export const getManufacturer = (id) => {
  return {type: GET_MANUFACTURER, payload: id}
};

export const getManufacturerSuccess = (manufacturer) => {
  return {type: GET_MANUFACTURER_SUCCESS, payload: manufacturer}
};

export const getManufacturerFailure = (error) => {
  return {type: GET_MANUFACTURER_ERROR, payload: error}
};

export const getManufacturerSettings = (id) => {
  return {type: GET_MANUFACTURER_SETTINGS, payload: id}
};

export const getManufacturerSettingsSuccess = (settings) => {
  return {type: GET_MANUFACTURER_SETTINGS_SUCCESS, payload: settings}
};

export const getManufacturerSettingsFailure = (error) => {
  return {type: GET_MANUFACTURER_SETTINGS_ERROR, payload: error}
};

export const setManufacturerStatus = (id, data) => {
  return {type: SET_MANUFACTURER_STATUS, payload: {id, data}}
};

export const filterManufacturers = (filters, searchTerms) => {
  return {
    type: FILTER_MANUFACTURERS,
    payload: { filters, searchTerms }
  };
};

export const resetManufacturerData = () => {
  return { type: RESET_MANUFACTURER_DATA };
};
