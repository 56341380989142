import {
  GET_NORMAL_SCHEDULE,
  GET_NORMAL_SCHEDULE_SUCCESS,
  GET_NORMAL_SCHEDULE_ERROR,
  UPDATE_NORMAL_SCHEDULE,
  UPDATE_NORMAL_SCHEDULE_SUCCESS,
  UPDATE_NORMAL_SCHEDULE_ERROR,
  GET_DATE_SCHEDULE,
  GET_DATE_SCHEDULE_SUCCESS,
  GET_DATE_SCHEDULE_ERROR,
  UPDATE_DATE_SCHEDULE,
  UPDATE_DATE_SCHEDULE_SUCCESS,
  UPDATE_DATE_SCHEDULE_ERROR,
  DELETE_DATE_SCHEDULE,
  DELETE_DATE_SCHEDULE_SUCCESS,
  DELETE_DATE_SCHEDULE_ERROR,
  GET_MONTH_SCHEDULE,
  GET_MONTH_SCHEDULE_SUCCESS,
  GET_MONTH_SCHEDULE_ERROR
} from '../actionTypes/manufacturerScheduleActionTypes';

import NotificationService from '../../services/NotificationService';

export const getNormalSchedule = (day) => {
  return {type: GET_NORMAL_SCHEDULE, payload: day}
};

export const getNormalScheduleSuccess = (data) => {
  return {type: GET_NORMAL_SCHEDULE_SUCCESS, payload: data}
};

export const getNormalScheduleFailure = (error) => {
  return {type: GET_NORMAL_SCHEDULE_ERROR, payload: error}
};

export const updateNormalSchedule = ({day, data}) => {
  return {type: UPDATE_NORMAL_SCHEDULE, payload: {day, data}}
};

export const updateNormalScheduleSuccess = () => {
  NotificationService.success('Manufacturer normal schedule updated');
  return {type: UPDATE_NORMAL_SCHEDULE_SUCCESS}
};

export const updateNormalScheduleFailure = (error) => {
  NotificationService.error(error);
  return {type: UPDATE_NORMAL_SCHEDULE_ERROR, payload: error}
};

export const getDateSchedule = (day) => {
  return {type: GET_DATE_SCHEDULE, payload: day}
};

export const getDateScheduleSuccess = (data) => {
  return {type: GET_DATE_SCHEDULE_SUCCESS, payload: data}
};

export const getDateScheduleFailure = (error) => {
  return {type: GET_DATE_SCHEDULE_ERROR, payload: error}
};

export const updateDateSchedule = ({day, data}) => {
  return {type: UPDATE_DATE_SCHEDULE, payload: {day, data}}
};

export const updateDateScheduleSuccess = () => {
  NotificationService.success('Manufacturer date schedule updated');
  return {type: UPDATE_DATE_SCHEDULE_SUCCESS}
};

export const updateDateScheduleFailure = (error) => {
  NotificationService.error(error);
  return {type: UPDATE_DATE_SCHEDULE_ERROR, payload: error}
};

export const deleteDateSchedule = (id) => {
  return {type: DELETE_DATE_SCHEDULE, payload: id}
};

export const deleteDateScheduleSuccess = () => {
  NotificationService.success('Manufacturer date schedule deleted successfully');
  return {type: DELETE_DATE_SCHEDULE_SUCCESS}
};

export const deleteDateScheduleFailure = (error) => {
  NotificationService.error(error);
  return {type: DELETE_DATE_SCHEDULE_ERROR, payload: error}
};

export const getMonthSchedule = (day) => {
  return {type: GET_MONTH_SCHEDULE, payload: day}
};

export const getMonthScheduleSuccess = (data) => {
  return {type: GET_MONTH_SCHEDULE_SUCCESS, payload: data}
};

export const getMonthScheduleFailure = (error) => {
  return {type: GET_MONTH_SCHEDULE_ERROR, payload: error}
};
