export const GET_ADMIN_TIME_ENTRIES = 'adminTimeSheetEpic/GET_ADMIN_TIME_ENTRIES';
export const GET_ADMIN_TIME_ENTRIES_SUCCESS = 'adminTimeSheetEpic/GET_ADMIN_TIME_ENTRIES_SUCCESS';
export const GET_ADMIN_TIME_ENTRIES_ERROR = 'adminTimeSheetEpic/GET_ADMIN_TIME_ENTRIES_ERROR';

export const CREATE_ADMIN_TIME_ENTRY = 'adminTimeSheetEpic/CREATE_ADMIN_TIME_ENTRY';
export const CREATE_ADMIN_TIME_ENTRY_SUCCESS = 'adminTimeSheetEpic/CREATE_ADMIN_TIME_ENTRY_SUCCESS';
export const CREATE_ADMIN_TIME_ENTRY_ERROR = 'adminTimeSheetEpic/CREATE_ADMIN_TIME_ENTRY_ERROR';
export const CREATE_ADMIN_TIME_ENTRY_FINISH = 'adminTimeSheetEpic/CREATE_ADMIN_TIME_ENTRY_FINISH';

export const UPDATE_ADMIN_TIME_ENTRY = 'adminTimeSheetEpic/UPDATE_ADMIN_TIME_ENTRY';
export const UPDATE_ADMIN_TIME_ENTRY_SUCCESS = 'adminTimeSheetEpic/UPDATE_ADMIN_TIME_ENTRY_SUCCESS';
export const UPDATE_ADMIN_TIME_ENTRY_ERROR = 'adminTimeSheetEpic/UPDATE_ADMIN_TIME_ENTRY_ERROR';

export const GET_ADMIN_TIME_ENTRY = 'adminTimeSheetEpic/GET_ADMIN_TIME_ENTRY';
export const GET_ADMIN_TIME_ENTRY_SUCCESS = 'adminTimeSheetEpic/GET_ADMIN_TIME_ENTRY_SUCCESS';
export const GET_ADMIN_TIME_ENTRY_ERROR = 'adminTimeSheetEpic/GET_ADMIN_TIME_ENTRY_ERROR';

export const DELETE_ADMIN_TIME_ENTRY = 'adminTimeSheetEpic/DELETE_ADMIN_TIME_ENTRY';
export const DELETE_ADMIN_TIME_ENTRY_SUCCESS = 'adminTimeSheetEpic/DELETE_ADMIN_TIME_ENTRY_SUCCESS';
export const DELETE_ADMIN_TIME_ENTRY_ERROR = 'adminTimeSheetEpic/DELETE_ADMIN_TIME_ENTRY_ERROR';

export const FILTER_ADMIN_TIME_SHEETS = 'adminTimeSheetEpic/FILTER_ADMIN_TIME_SHEETS';
