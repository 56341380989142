import React from "react";
import { withTranslation } from 'react-i18next';

import './style.scss';
import GridItem from "../../components/Grid/GridItem";

class FinanceView extends React.Component {
  
  render() {
    const {data, t} = this.props;
    return (
      <div className="contact-data-container">
        <div className="contact-info-field">
          <div className="contact-info-item">
            <GridItem xs={4} className="contact-info-title">{ t('iban') }</GridItem>
            <GridItem xs={8}>{data.finances_iban}</GridItem>
          </div>
          <div className="contact-info-item">
            <GridItem xs={4} className="contact-info-title">{ t('bank') }</GridItem>
            <GridItem xs={8}>{data.finances_bank}</GridItem>
          </div>
          <div className="contact-info-item">
            <GridItem xs={4} className="contact-info-title">{ t('tax_number') }</GridItem>
            <GridItem xs={8}>{data.finances_tax_no}</GridItem>
          </div>
          <div className="contact-info-item">
            <GridItem xs={4} className="contact-info-title">{ t('bic') }</GridItem>
            <GridItem xs={8}>{data.finances_bic}</GridItem>
          </div>
          <div className="contact-info-item">
            <GridItem xs={4} className="contact-info-title">{ t('vat_id') }</GridItem>
            <GridItem xs={8}>{data.finances_eu_vat_no}</GridItem>
          </div>
          <div className="contact-info-item">
            <GridItem xs={4} className="contact-info-title">{ t('cash_account') }</GridItem>
            <GridItem xs={8}>{data.cash_account}</GridItem>
          </div>
          <div className="contact-info-item">
            <GridItem xs={4} className="contact-info-title">{ t('cash_account_period_8_days') }</GridItem>
            <GridItem xs={8}>{data.cash_account_period}</GridItem>
          </div>
          <div className="contact-info-item">
            <GridItem xs={4} className="contact-info-title">{ t('credit_period_30_days') }</GridItem>
            <GridItem xs={8}>{data.credit_account_period}</GridItem>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FinanceView);
