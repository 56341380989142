import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem';

import './style.scss';
import {updateProgress} from "../../store/actions/progress";
import {connect} from "react-redux";

class SetProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0
    };
  }

  componentDidMount() {
    if (this.props.progress) {
      this.setState({
        progress: this.props.progress
      })
    }
  }

  handleIncreaseBtn = () => {
    const {progress} = this.state;
    if (progress < 100) {
      this.setState({
        progress: progress + 10
      })
    }
  };

  handleDecreaseBtn = () => {
    const {progress} = this.state;
    if (progress > 0) {
      this.setState({
        progress: progress - 10
      })
    }
  };

  handleUpdateBtn = (e) => {
    const {
      updateProgress,
      onCardClose,
      id,
      order,
      filters
    } = this.props;
    e.stopPropagation();
    const {progress} = this.state;
    updateProgress({id, progress, order_id: order.id, filters});
    onCardClose();
  }

  render() {
    const { progress, t} = this.state;
    return (
      <GridItem className="set-progress-card" xs={12}>
        <GridItem xs={12}>
          <span>{ t('set_progress') }</span>
        </GridItem>
        <GridItem className="set-progress-actions">
          <div
            className="progress-change-btn"
            onClick={e => this.handleDecreaseBtn(e)}
          >
            -
          </div>
          <span>{`${progress} %`}</span>
          <div
            className="progress-change-btn"
            onClick={e => this.handleIncreaseBtn(e)}
          >
            +
          </div>
        </GridItem>
        <GridItem className="set-progress-btn-container">
          <Button
            className="ikt-ap_project-details-btn set-btn"
            onClick={this.handleUpdateBtn}
          >
            { t('ok') }
          </Button>
        </GridItem>
      </GridItem>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProgress: (data) => dispatch(updateProgress(data)),
  }
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(SetProgress));
