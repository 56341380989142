import React from 'react';
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar'

import Button from '../../../../components/CustomButtons/Button';
import ContactPerson from './ContactPersonCard';

import './style.scss';

class ContactPersonPage extends React.Component {
  render() {
    const {
      contactPersons,
      removeContactPerson,
      errors,
      onPersonDataChange,
      t
    } = this.props;
    return (
      <div className="contact-person-page">
        <div className="contact-person-page-title">
          <span>{ t('contact_persons') }</span>
        </div>
          <PerfectScrollbar  className="contact-persons-content">
            { contactPersons && contactPersons.length !== 0 && contactPersons.map((item, index) => <ContactPerson key={index} id={index} data={item} errors={errors[index] ? errors[index] : {}} removeContact={removeContactPerson} onChange={onPersonDataChange}/>) }
          </PerfectScrollbar>
        <div className="add-person-btn">
          <Button
            onClick={() => this.props.addNewPerson()}
          >
            { t('add_contact_person') }
          </Button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ContactPersonPage);
