import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {materialProvidersAPI} from 'services/API';
import {
  getAllMaterialsSuccess,
  getAllMaterialsFailure,
  getMainMaterialsListSuccess,
  getMainMaterialsListFailure,
  getPositionMaterialsListSuccess,
  getPositionMaterialsListFailure,
  getFittingsMaterialsListSuccess,
  getFittingsMaterialsListFailure,
  getEdgeMaterialsListSuccess,
  getEdgeMaterialsListFailure,
  getCoveringMaterialsListSuccess,
  getCoveringMaterialsListFailure,
  getSurfaceMaterialsListSuccess,
  getSurfaceMaterialsListFailure,
  getSelectedMaterialsSuccess,
  getSelectedMaterialsFailure,
} from 'store/actions/materialProviders';
import {
  GET_ALL_MATERIALS,
  GET_MAIN_MATERIALS_LIST,
  GET_POSITION_MATERIALS_LIST,
  GET_FITTINGS_MATERIALS_LIST,
  GET_EDGE_MATERIALS_LIST,
  GET_SURFACE_MATERIALS_LIST,
  GET_COVERING_MATERIALS_LIST,
  GET_SELECTED_MATERIALS,
} from 'store/actionTypes/materialProvidersActionTypes';

export const getAllMaterialsEpic = (actions$) => actions$.pipe(
  ofType(GET_ALL_MATERIALS),
  switchMap((action) => from(materialProvidersAPI.getAllMaterials(action.payload.term, action.payload.metric)).pipe(
    switchMap(response => {
      return of(getAllMaterialsSuccess(response.data));
    }),
    catchError(err => {
      return of(getAllMaterialsFailure(err));
    })
  ))
);

export const getMainMaterialsListEpic = (actions$) => actions$.pipe(
  ofType(GET_MAIN_MATERIALS_LIST),
  switchMap((action) => from(materialProvidersAPI.getMainMaterialsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getMainMaterialsListSuccess(response.data))
    }),
    catchError( err => {
        return of(getMainMaterialsListFailure(err))
      }
    )
  ))
);

export const getPositionMaterialsListEpic = (actions$) => actions$.pipe(
  ofType(GET_POSITION_MATERIALS_LIST),
  switchMap((action) => from(materialProvidersAPI.getPositionMaterialsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getPositionMaterialsListSuccess(response.data))
    }),
    catchError( err => {
        return of(getPositionMaterialsListFailure(err))
      }
    )
  ))
);

export const getFittingsMaterialsListEpic = (actions$) => actions$.pipe(
  ofType(GET_FITTINGS_MATERIALS_LIST),
  switchMap((action) => from(materialProvidersAPI.getFittingsMaterialsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getFittingsMaterialsListSuccess(response.data))
    }),
    catchError( err => {
        return of(getFittingsMaterialsListFailure(err))
      }
    )
  ))
);

export const getEdgeMaterialsListEpic = (actions$) => actions$.pipe(
  ofType(GET_EDGE_MATERIALS_LIST),
  switchMap((action) => from(materialProvidersAPI.getEdgeMaterialsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getEdgeMaterialsListSuccess(response.data))
    }),
    catchError( err => {
        return of(getEdgeMaterialsListFailure(err))
      }
    )
  ))
);

export const getCoveringMaterialsListEpic = (actions$) => actions$.pipe(
  ofType(GET_COVERING_MATERIALS_LIST),
  switchMap((action) => from(materialProvidersAPI.getCoveringMaterialsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getCoveringMaterialsListSuccess(response.data))
    }),
    catchError( err => {
        return of(getCoveringMaterialsListFailure(err))
      }
    )
  ))
);

export const getSurfaceMaterialsListEpic = (actions$) => actions$.pipe(
  ofType(GET_SURFACE_MATERIALS_LIST),
  switchMap((action) => from(materialProvidersAPI.getSurfaceMaterialsList(action.payload)).pipe(
    switchMap((response) => {
      return of(getSurfaceMaterialsListSuccess(response.data))
    }),
    catchError( err => {
        return of(getSurfaceMaterialsListFailure(err))
      }
    )
  ))
);

export const getSelectedMaterialsEpic = (actions$) => actions$.pipe(
  ofType(GET_SELECTED_MATERIALS),
  switchMap((action) => from(materialProvidersAPI.getSelectedMaterials(action.payload)).pipe(
    switchMap((response) => {
      return of(getSelectedMaterialsSuccess(response.data))
    }),
    catchError( err => {
        return of(getSelectedMaterialsFailure(err))
      }
    )
  ))
);
