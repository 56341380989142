import LinearProgress from "@material-ui/core/LinearProgress";

import withStyles from '@material-ui/core/styles/withStyles';

const BorderLinearProgress = withStyles({
  root: {
    height: 9,
    backgroundColor: '#d7e1f0',
    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#dd7f3d',
  },
})(LinearProgress);

export default BorderLinearProgress;
