/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

import { debounce } from 'lodash';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
// core components
import InputWithIcon from "../InputWithIcon";

import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx';

import './style.scss';
import InputAdornment from "@material-ui/core/InputAdornment";
import UserIcon from "../../assets/img/inputIcons/user-icon.svg";

class CustomAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: '',
      dropdownList: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.throttleHandleChange = debounce(this.throttleHandleChange.bind(this), 500)
  }

  componentDidMount() {
    if (this.props.dropdownList) {
      const selectedItem = this.props.dropdownList.find(item => {
        const itemId = !item.id ? '' : item.id;
        const buttonText = !this.props.buttonText ? '' : this.props.buttonText;
        return itemId.toString() === buttonText.toString();
      });
      this.setState({
        selected: selectedItem ? selectedItem.value : this.props.initialText ? this.props.initialText : '',
        dropdownList: this.props.excludeItems && this.props.excludeItems.length > 0 ?
          this.props.dropdownList.filter(item => this.props.excludeItems.indexOf(item.id) == -1)
          : this.props.dropdownList,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const stateUpdate = {};
    if(prevProps.dropdownList !== this.props.dropdownList || prevProps.buttonText !== this.props.buttonText || prevProps.initialText !== this.props.initialText || prevState.open && !this.state.open) {
      const selectedItem = this.props.dropdownList.find(item => {
        const itemId = !item.id ? '' : item.id;
        const buttonText = !this.props.buttonText ? '' : this.props.buttonText;
        return itemId.toString() === buttonText.toString();
      });
      if (selectedItem && !this.state.open) {
        stateUpdate['selected'] = selectedItem.value;
      } else if (!this.state.selected && this.props.initialText) {
        stateUpdate['selected'] = this.props.initialText;
      }
    }
    if (prevProps.dropdownList !== this.props.dropdownList || prevProps.excludeItems != this.props.excludeItems) {
      stateUpdate['dropdownList'] = this.props.excludeItems && this.props.excludeItems.length > 0 ? this.props.dropdownList.filter(item => this.props.excludeItems.indexOf(item.id) == -1) : this.props.dropdownList;
    }
    if (Object.keys(stateUpdate).length > 0) {
      this.setState(stateUpdate);
    }
  }

  handleClick = () => {
    if (this.anchorEl) {
      this.throttleHandleChange(this.anchorEl.value);
    }
    this.setState(state => ({ open: !this.state.open}));
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  }

  handleCloseMenu(param) {
    const stateUpdate = { open: false, selected: param.value };
    if (this.props.clearInputOnSelect) {
      stateUpdate['selected'] = '';
    }
    this.setState(stateUpdate);
    if (this.props.onClick) {
      this.props.onClick(param);
    }
  }

  throttleHandleChange = (value) => {
    const {getData} = this.props;
    getData(value);
  }

  handleInputChange = (e) => {
    const {onClick} = this.props;
    this.throttleHandleChange(e.target.value);
    e.target.value.length === 0 && onClick({id: ''});
    this.setState({
      open: true,
      selected: e.target.value
    })
  }

  scrollSelectedMenuItemIntoView = () => {
    let selectedElements = document.getElementsByClassName("ikt-ap_custom-dropdown-item-selected");
    if (selectedElements && selectedElements[0])
      selectedElements[0].scrollIntoView({ block: "nearest" });
  }

  handleKeyDown = event => {
    const { open, selected, dropdownList } = this.state;

    if (open) {
      const selectedIndex = selected ? dropdownList.findIndex(el => el.value == selected) : -1;
      if (event.keyCode == 40) {  // down arrow
        if (selectedIndex !== -1) {
          if (selectedIndex + 1 < dropdownList.length) {
            this.setState({
              selected: dropdownList[selectedIndex + 1].value,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        } else {
          if (dropdownList.length > 0) {
            this.setState({
              selected: dropdownList[0].value,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        }
      } else if (event.keyCode == 38) {  // up arrow
        if (selectedIndex !== -1) {
          if (selectedIndex - 1 >= 0) {
            this.setState({
              selected: dropdownList[selectedIndex - 1].value,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        } else {
          if (dropdownList.length > 0) {
            this.setState({
              selected: dropdownList[dropdownList.length - 1].value,
            }, () => {
              this.scrollSelectedMenuItemIntoView();
            });
          }
        }
      } else if (event.keyCode == 13) {  // enter
        if (selectedIndex !== -1) {
          event.preventDefault();
          this.handleCloseMenu(dropdownList[selectedIndex]);
        } else {
          event.preventDefault();
          if (dropdownList.length > 0)
            this.handleCloseMenu(dropdownList[0]);
        }
      }
    }
  }

  render() {
    const { open, selected, dropdownList } = this.state;
    const {
      classes,
      className,
      buttonText,
      buttonProps,
      dropup,
      dropdownHeader,
      hoverColor,
      dropPlacement,
      id,
      rtlActive,
      noLiPadding,
      innerDropDown,
      navDropdown,
      error,
      placeholder,
      discardIcon,
      key,
      disableTabFocus,
      secondaryValueField,
      endAdornment,
      displayTextFormat,
      alternativeDisabledMode,
      t
    } = this.props;
    const dropdownItem = classNames({
      [classes.dropdownItem]: true,
      [classes[hoverColor + 'Hover']]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive
    });
    const getMenuItemText = (prop) => {
      if (!secondaryValueField || !prop[secondaryValueField]) {
        return prop.value;
      } else {
        const itemTextFormat = displayTextFormat ? displayTextFormat : '{1} ({2})';
        return itemTextFormat.replace('{1}', prop.value).replace('{2}', prop[secondaryValueField]);
      }
    }
    const dropDownMenu = (
      <MenuList role="menu" className={classes.menuList}>
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => this.handleCloseMenu(dropdownHeader)}
            className={classes.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {dropdownList.length !== 0 ? dropdownList.map((prop, key) => {
            const isSelected = selected == prop.value; /*buttonText && 
              buttonText === prop.value ||
              (buttonText.props && buttonText.props.children === prop) : false;*/

            if (prop.divider) {
              return (
                <Divider
                  key={key}
                  onClick={() => this.handleCloseMenu('divider')}
                  className={classes.dropdownDividerItem}
                />
              );
            } else if (
              prop.props !== undefined &&
              prop.props['data-ref'] === 'multi'
            ) {
              return (
                <MenuItem
                  key={key}
                  className={`${dropdownItem}${
                    isSelected ? ` ikt-ap_custom-dropdown-item-selected` : ``}${
                    secondaryValueField ? ` ikt-ap_custom-dropdown-item-whitespace-normal` : ``}`
                  }
                  style={{ overflow: 'visible', padding: 0 }}
                >
                  { getMenuItemText(prop) }
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={key}
                onClick={() => this.handleCloseMenu(prop)}
                className={`${dropdownItem}${
                  isSelected ? ` ikt-ap_custom-dropdown-item-selected` : ``}${
                  secondaryValueField ? ` ikt-ap_custom-dropdown-item-whitespace-normal` : ``}`
                }
              >
                { getMenuItemText(prop) }
              </MenuItem>
            );
          }) :
          <MenuItem
            className={`${dropdownItem}`}
          >
            No Result
          </MenuItem>
        }
      </MenuList>
    );

    let buttonToggleClasses = `${buttonProps.className} ikt-ap_custom-dropdown-toggle-btn ${error ? 'dropdown-error' : ''}`;
    let inputProps = {
      autoComplete: 'off'
    };
    if (disableTabFocus) {
      inputProps["tabindex"]="-1";
    }
    return (
      <div
        className={`ikt-ap_custom-autocomplete dropdown-blue ${className} ${
          innerDropDown ? classes.innerManager : classes.manager
        }`}
        key={key}
        onKeyDown={this.handleKeyDown}
      >
        <div onClick={!buttonProps.disabled && this.handleClick} className={(buttonText !== undefined ? '' : classes.target) + (buttonProps.disabled && alternativeDisabledMode ? ' alternative-disabled-input-container' : '')}>
          { !discardIcon ?
            <InputWithIcon
              aria-label="Notifications"
              aria-owns={open ? 'menu-list' : null}
              startAdornment={
                <InputAdornment position="start">
                  <img
                    alt="customer-autocomplete-img"
                    src={UserIcon}
                  />
                </InputAdornment>
              }
              endAdornment={endAdornment}
              placeholder={placeholder ? placeholder : t('start_typing_name')}
              id={id}
              inputRef={node => {
                this.anchorEl = node;
              }}
              disabled={alternativeDisabledMode ? buttonProps.disabled : false}
              readOnly={buttonProps.disabled}
              className={buttonToggleClasses}
              value={selected}
              onChange={this.handleInputChange}
              inputProps={inputProps}
            />
            :
            <InputWithIcon
              aria-label="Notifications"
              aria-owns={open ? 'menu-list' : null}
              endAdornment={endAdornment}
              placeholder={placeholder ? placeholder : t('start_typing_name')}
              id={id}
              inputRef={node => {
                this.anchorEl = node;
              }}
              disabled={alternativeDisabledMode ? buttonProps.disabled : false}
              readOnly={buttonProps.disabled}
              className={buttonToggleClasses}
              value={selected}
              onChange={this.handleInputChange}
              inputProps={inputProps}
            />
          }
        </div>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true,
            [classes.popperNav]: open && navDropdown
          })}
        >
          {() => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: '0 100% 0' }
                  : { transformOrigin: '0 0 0' }
              }
            >
              <Paper
                className={`${classes.dropdown} ikt-ap_custom-dropdown-menu ${!discardIcon ? 'ikt-ap_custom-dropdown-menu-icon' : 'ikt-ap_custom-dropdown-menu-no-icon'}`}
              >
                {innerDropDown ? (
                  dropDownMenu
                ) : (
                  <ClickAwayListener onClickAway={this.handleClose}>
                    {dropDownMenu}
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CustomAutocomplete.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: 'primary'
};

CustomAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  className: PropTypes.string,
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.object,
  excludeItems: PropTypes.array,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  id: PropTypes.string,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    'bottom',
    'top',
    'right',
    'left',
    'bottom-start',
    'bottom-end',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end'
  ]),
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // This is a function that returns the clicked menu item
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  discardIcon: PropTypes.bool,
  disableTabFocus: PropTypes.bool,
  secondaryValueField: PropTypes.string,
};

export default withStyles(customDropdownStyle)(withTranslation()(CustomAutocomplete));
