import React from "react";
import { withTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Button from 'components/CustomButtons/Button';
import moment from "moment";

import CloseButton from "../../assets/img/buttons/delete.svg";

import './style.scss';

import TimeEntry from './ManualTimeEntry';
import {
  createCoworkerTimeEntry,
  updateCoworkerTimeEntry,
  resetLastFinishedEdit,
  resetTimeEntryData
} from "../../store/actions/coworkerTimeSheet";
import { getOrdersList, getCostCentersList } from 'store/actions/timeSheetsProviders';
import { connect } from "react-redux";

class AddTime extends React.Component {
  static DEFAULT_STATE = {
    timeEntries: [{type: 0, entry_date: moment().format('YYYY-MM-DD'), time: '', description: '', loggable_id: ''}],
    uids: null,
    errors: {},

    editingEntity: {},
    editErrors: {},
  };

  constructor(props) {
    super(props);
    this.state = AddTime.DEFAULT_STATE;
  }

  componentDidMount() {
    const { edit } = this.props;
    if (edit) {
      const newEntity = { ...edit };
      newEntity.time = this.timeFromHis(newEntity.time_spent);
      this.setState({
        editingEntity: newEntity,
      });
    }

    this.props.getOrdersList();
    this.props.getCostCentersList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isTimeEntryValid !== this.props.isTimeEntryValid && this.props.isTimeEntryValid) {
      this.props.onClose();
    }
  }

  componentWillUnmount() {
    this.props.resetTimeEntryData();
  }

  static getDerivedStateFromProps(props, state) {
    const { createResponses, edit, editError, uploadBusy, lastFinishedEditId } = props;
    const { timeEntries, uids, errors, editErrors } = state;

    if (!edit && uids && uids.length > 0) {
      const newErrors = {
        ...errors
      };

      const uidsToRemove = [];
      const entriesToRemove = [];
      for (let i in uids) {
        const uid = uids[i];
        let timeEntryIndex = timeEntries.findIndex(el => el.uid == uid);
        if (timeEntryIndex != -1) {
          if(createResponses.successes[uid]){
            uidsToRemove.push(uid);
            entriesToRemove.push(uid);
          }else if(createResponses.errors[uid]){
            uidsToRemove.push(uid);

            const errorsString = createResponses.errors[uid].toString();
            const needle = "Response body: ";
            const position = errorsString.indexOf(needle);
            if (position != -1) {
              const substring = errorsString.substring(position + needle.length);
              try {
                const errorsObj = JSON.parse(substring);
                if (errorsObj.errors) {
                  const errorData = {
                    description: errorsObj.errors.description,
                    time: errorsObj.errors.time_spent,
                    loggable_id: errorsObj.errors.loggable_id,
                  };
                  newErrors[uid] = errorData;
                }
              } catch(e) {
              }
            }
          }
        }
      }

      const newUids = uids.filter(el => uidsToRemove.indexOf(el) == -1);
      const newTimeEntries = timeEntries.filter(el => entriesToRemove.indexOf(el.uid) == -1);

      return {uids: newUids, timeEntries: newTimeEntries, errors: newErrors };
    } else if (edit && editError && !uploadBusy && edit.id == lastFinishedEditId) {
      let newEditErrors = {};
      const errorsString = editError.toString();
      const needle = "Response body: ";
      const position = errorsString.indexOf(needle);
      if (position != -1) {
        const substring = errorsString.substring(position + needle.length);
        try {
          const errorsObj = JSON.parse(substring);
          if (errorsObj.errors) {
            newEditErrors = {
              description: errorsObj.errors.description,
              time: errorsObj.errors.time_spent,
              loggable_id: errorsObj.errors.loggable_id,
            };
          }
        } catch(e) {
        }
      }
      props.resetLastFinishedEdit();
      return { editErrors: newEditErrors };
    }

    return null;
  }

  validateLastTimeEntry = () => {
    const { t } = this.props;
    const {timeEntries, errors} = this.state;
    if (timeEntries.length == 0)
      return true;

    const timeEntryIndex = timeEntries.length - 1;
    const timeEntry = timeEntries[timeEntryIndex];
    if (!timeEntry.uid) {
      const reservedUid = 'last-before-new-add';
      for (let i = 0, len = timeEntries.length; i < len; ++i) {
        if (timeEntries[i].uid == reservedUid) {
          timeEntries[i].uid = null;
        }
      }
      timeEntry.uid = reservedUid;
      timeEntries[timeEntryIndex].uid = reservedUid;
    }

    const errorToAdd = {};
    if (!timeEntry.time) {
      errorToAdd['time'] = [t('enter_hours')];
    }
    if (!timeEntry.description) {
      errorToAdd['description'] = [t('enter_description')];
    }
    if (!timeEntry.loggable_id) {
      errorToAdd['loggable_id'] = [t('select_position_cost_center')];
    }

    if (Object.keys(errorToAdd).length > 0) {
      const newErrors = {
        ...errors,
        [timeEntry.uid]: errorToAdd,
      };
      this.setState({
        timeEntries,
        errors: newErrors,
      });
      return false;
    } else {
      return true;
    }
  }

  handleAddTimeEntry = () => {
    if (this.validateLastTimeEntry()) {
      const {timeEntries} = this.state;
      const newEntries = [...timeEntries, {type: 0, entry_date: moment().format('YYYY-MM-DD'), time: '', description: '', loggable_id: ''}];
      this.setState({
        timeEntries: newEntries
      });
    }
  };

  handleTimeEntryChange = (id, data) => {
    const { timeEntries, errors } = this.state;
    const newTimeEntries = [...timeEntries];
    newTimeEntries[id] = {
      ...newTimeEntries[id],
      ...data,
    };
    const uid = newTimeEntries[id].uid;
    const newErrors = {
      ...errors
    };
    if (newErrors[uid]) {
      const oldTimeEntry = timeEntries[id];
      if (oldTimeEntry.time != data.time) {
        newErrors[uid]["time"] = null;
      }
      if (oldTimeEntry.loggable_id != data.loggable_id) {
        newErrors[uid]["loggable_id"] = null;
      }
      if (oldTimeEntry.description != data.description) {
        newErrors[uid]["description"] = null;
      }
    }
    this.setState({
      timeEntries: newTimeEntries,
      errors: newErrors,
    })
  }

  handleTimeEntryEditChange = (data) => {
    const { editingEntity, editErrors } = this.state;
    const newEditingEntity = {
      ...editingEntity,
      ...data,
    };

    const newErrors = {
      ...editErrors
    };

    if (editingEntity.time != data.time) {
      newErrors["time"] = null;
    }
    if (editingEntity.loggable_id != data.loggable_id) {
      newErrors["loggable_id"] = null;
    }
    if (editingEntity.description != data.description) {
      newErrors["description"] = null;
    }

    this.setState({
      editingEntity: newEditingEntity,
      editErrors: newErrors,
    });
  }

  handleRemoveEntry = (id) => {
    const {timeEntries} = this.state;
    this.setState({
      timeEntries: [...timeEntries].filter((item, index) => index !== id)
    })
  };

  handleCloseDialog = () => {
    const {onClose} = this.props;
    if (onClose)
      onClose();
    this.setState(AddTime.DEFAULT_STATE);
  }

  timeToHis = time => {
    const prefixWithZero = num => (num < 10 ? '0' + num : num.toString());
    const wholePart = parseInt(time, 10);
    const minutes = Math.floor((time - wholePart) * 60);
    const seconds = Math.floor((time - wholePart) * 3600 % 60);
    return `${prefixWithZero(wholePart)}:${prefixWithZero(minutes)}:${prefixWithZero(seconds)}`;
  }

  timeFromHis = time => {
    const parts = time.split(":");
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]) / 60;
    const seconds = parseInt(parts[2]) / 3600;
    const t = hours + minutes + seconds;
    return t.toFixed(2);
  }

  generateUID = (index) => {
    const randomStr = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6);
    return `${index}-${randomStr}`;
  }

  handleSubmit = () => {
    const { createCoworkerTimeEntry, uploadBusy, updateCoworkerTimeEntry, edit } = this.props;
    if (!edit && !uploadBusy) {
      const { timeEntries } = this.state;
      const newTimeEntries = [...timeEntries];
      const uids = [];
      for (let i = 0, len = newTimeEntries.length; i < len; ++i) {
        const timeEntry = newTimeEntries[i];

        // handle time format conversion
        let timeSpent = parseFloat(timeEntry.time);
        if (timeSpent == NaN)
          timeSpent = 0;
        timeEntry.time_spent = this.timeToHis(timeSpent);

        // generate uid
        const uid = this.generateUID(i);
        uids[i] = uid;
        timeEntry.uid = uids[i];
      }

      this.setState({ timeEntries: newTimeEntries, uids, errors: {} }, () => {
        createCoworkerTimeEntry(this.state.timeEntries, this.props.currentFilters, this.props.currentSort, this.props.currentSortOrder, this.props.currentSearchTerms);
      });
    } else if (edit && !uploadBusy) {
      const { editingEntity } = this.state;
      editingEntity.time_spent = this.timeToHis(editingEntity.time);
      let type = null;
      if (editingEntity.type === 0) {
        type = "App\\Models\\PositionCostCenter";
      } else if (editingEntity.type === 1) {
        type = "App\\Models\\PositionCostCenterItem";
      } else if (editingEntity.type === 2) {
        type = "App\\Models\\CostCenter";
      } else if (editingEntity.type === 3) {
        type = "App\\Models\\CostCenterItem";
      }
      editingEntity.loggable_type = type;
      updateCoworkerTimeEntry(editingEntity.id, editingEntity, this.props.currentFilters, this.props.currentSort, this.props.currentSortOrder, this.props.currentSearchTerms);
    }
  }

  render() {
    const { isOpen, onClose, edit, t } = this.props;
    const { timeEntries, errors, editingEntity, editErrors } = this.state;
    return (
      <Dialog className="manual-timer-finances" maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="manual-timer-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{!edit ? t('add_manual_time') : t('edit_time')}</Typography>
          { onClose ? (
              <IconButton aria-label="close" className="close-btn" onClick={this.handleCloseDialog}>
                <img
                  alt="manual-timer-close-btn"
                  src={CloseButton}
                />
              </IconButton>
            ) : null
          }
        </MuiDialogTitle>
        <div className="manual-timer-content">
          <div className="time-entries-content">
          {
            !edit ? timeEntries.map((item, index) =>
              <TimeEntry
                data={item}
                id={index}
                key={index}
                onChange={(data) => this.handleTimeEntryChange(index, data)}
                onRemoveEntry={() => this.handleRemoveEntry(index)}
                errors={errors[item.uid]}
              />
            ) :
              <TimeEntry
                data={editingEntity}
                id={0}
                key={0}
                onChange={(data) => this.handleTimeEntryEditChange(data)}
                isEdit={true}
                errors={editErrors}
              />
          }
          </div>
          { !edit ? <div className="add-person-btn">
            <Button
              className="manual-timer-btn"
              onClick={this.handleAddTimeEntry}
            >
              + { t('add_entry').toUpperCase() }
            </Button>
          </div> : null }
        </div>
        <div className="manual-timer-btn-container">
          <Button
            className="manual-timer-btn cancel-btn"
            onClick={ this.handleCloseDialog }
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="manual-timer-btn create-btn"
            onClick={ this.handleSubmit }
          >
            { !edit ? t('add_time').toUpperCase() : t('edit_time').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    uploadBusy: state.timeSheets.uploadBusy,
    isTimeEntryValid: state.timeSheets.isTimeEntryValid,
    createResponses: state.timeSheets.createResponses,
    editError: state.timeSheets.editError,
    lastFinishedEditId: state.timeSheets.lastFinishedEditId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createCoworkerTimeEntry: (entries, filters, sort, sortOrder, searchTerms) => dispatch(createCoworkerTimeEntry(entries, filters, sort, sortOrder, searchTerms)),
    updateCoworkerTimeEntry: (id, data, filters, sort, sortOrder, searchTerms) => dispatch(updateCoworkerTimeEntry(id, data, filters, sort, sortOrder, searchTerms)),
    getOrdersList: () => dispatch(getOrdersList()),
    getCostCentersList: () => dispatch(getCostCentersList()),
    resetLastFinishedEdit: () => dispatch(resetLastFinishedEdit()),
    resetTimeEntryData: () => dispatch(resetTimeEntryData()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(AddTime));
