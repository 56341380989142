import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  getPreTextPresets,
  getEndTextPresets,
  getInvoiceTypesPresets,
  getLastPreEndText,
} from "../../store/actions/invoicesProviders";
import { orderPositionCalculation } from '../../store/actions/orders';
import { getTaskUnitList } from "../../store/actions/providers";
import { getProjectsSettings } from "../../store/actions/settings";
import { createInvoice, updateInvoice, previewInvoice } from "../../store/actions/invoices";

import Button from 'components/CustomButtons/Button.jsx';
import CloseDialog from '../CloseDialog';
import WarningDialog from '../WarningDialog';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseButton from "../../assets/img/buttons/delete.svg";

import InvoiceStep1 from "./Step1";
import InvoiceStep2 from "./Step2";
import InvoiceStep3 from "./Step3";

import {
  getPaymentTypeList,
} from 'store/actions/providers';

import { InvoiceTypes } from 'constants/constants';

import './style.scss';

class CreateInvoice extends React.Component {
  static defaultState = {
    step1data: {
      title: '',
      pre_text: '',
      end_text: '',
      template_id: null,
      additional_elements: {
        logo: false,
        footer_text: false,
        discount: false,
        sum: false,
      },
    },
    step2data: {
      selectedPositions: [],
    },
    step3data: {
      billing_peculiarities: '',
      discount1_days: 0,
      discount1_value: 0,
      discount2_days: 0,
      discount2_value: 0,
      payment_terms_days: 0,
    },
    step1errors: {},
    step2errors: {},
    step3errors: {},
    type: null,

    currentStep: 0,

    haveEdits: false,
    isCloseDialogOpen: false,
    isCreateDialogOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = CreateInvoice.defaultState;
  }

  componentDidMount() {
    const { orderData, positionCosts, edit, type } = this.props;

    this.props.getTaskUnitList();
    this.props.getPaymentTypeList();
    this.props.getPreTextPresets();
    this.props.getEndTextPresets();
    this.props.getInvoiceTypesPresets(!edit ? type : edit.type);
    this.props.getLastPreEndText();
    this.props.getProjectsSettings();

    // get missing position costs for Step 2
    orderData.positions.forEach(position => {
      const id = position.id;
      if (!positionCosts[id]) {
        const payload = { tasks: position.tasks, fittings: position.fittings, amount: position.amount, metric: position.metric };
        if (position.unit_price) {
          payload.unit_price = position.unit_price; 
        }
        this.props.orderPositionCalculation({ id, data: payload });
      }
    });

    this.initState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.edit && !this.props.duplicate && !prevProps.haveLastPreEndText && this.props.haveLastPreEndText) {
      this.setState({
        step1data: {
          ...this.state.step1data,
          pre_text: this.props.lastPreTextValue,
          end_text: this.props.lastEndTextValue,
        },
      });
    }

    if (!this.props.edit && !this.props.duplicate && this.props.projectsSettings && prevProps.projectsSettings != this.props.projectsSettings) {
      this.setState({
        step3data: {
          ...this.state.step3data,
          discount1_days: parseInt(this.props.projectsSettings.project_settings_discount_1_days),
          discount1_value: parseInt(this.props.projectsSettings.project_settings_discount_1_value),
          discount2_days: parseInt(this.props.projectsSettings.project_settings_discount_2_days),
          discount2_value: parseInt(this.props.projectsSettings.project_settings_discount_2_value),
          payment_terms_days: parseInt(this.props.projectsSettings.project_settings_payment_term),
        }
      });
    }

    if (!this.props.edit && !this.props.duplicate && prevProps.invoiceTypesPresets !== this.props.invoiceTypesPresets && this.props.invoiceTypesPresets && this.props.invoiceTypesPresets.length > 0) {
      this.setState({
        step1data: {
          ...this.state.step1data,
          template_id: this.props.invoiceTypesPresets[0].id,
        },
      });
    }

    if (this.props.previewContent && prevProps.previewContent !== this.props.previewContent) {
      // open in new tab
      const fileURL = URL.createObjectURL(this.props.previewContent);
      const newTab = window.open(fileURL);
      setTimeout(() => {
        newTab.document.title = this.props.t ? this.props.t('invoice_preview_title') : 'Invoice Preview';
      }, 10);
    }

    if (this.props.invoiceError && prevProps.invoiceError !== this.props.invoiceError && this.props.invoiceError.errors && Boolean(this.props.invoiceError.errors.items)) {
      this.setState({
        currentStep: 1,
      });
    }

    if (!prevProps.isInvoiceValid && this.props.isInvoiceValid) {
      this.handleOnClose();
    }
  }

  componentWillUnmount() {

  }

  generateDialogTitleTypePart = (type) => {
    const { t } = this.props;
    for (let el in InvoiceTypes) {
      if (InvoiceTypes[el] === type) {
        return t(el);
      }
    }
    return '';
  }
  
  initState = () => {
    const { type, edit, duplicate } = this.props;

    if (edit) {
      const logo = edit.meta.find(el => el.name == 'logo');
      const footer_text = edit.meta.find(el => el.name == 'footer_text');
      const discount = edit.meta.find(el => el.name == 'discount');
      const sum = edit.meta.find(el => el.name == 'sum');
      const discount_1_days = edit.meta.find(el => el.name == 'discount_1_days');
      const discount_1_value = edit.meta.find(el => el.name == 'discount_1_value');
      const discount_2_days = edit.meta.find(el => el.name == 'discount_2_days');
      const discount_2_value = edit.meta.find(el => el.name == 'discount_2_value');
      const payment_terms = edit.meta.find(el => el.name == 'payment_terms');
      const selectedPositionsIds = edit.items.sort((obj1, obj2) => {
        if (obj1.order < obj2.order)
          return -1;
        else if (obj1.order > obj2.order)
          return 1;
        else
          return 0;
      }).map(el => el.position_id);
      this.setState({
        step1data: {
          title: edit.title ? edit.title : '',
          pre_text: edit.text_before ? edit.text_before : '',
          end_text: edit.text_after ? edit.text_after : '',
          template_id: edit.template_id,
          additional_elements: {
            logo: logo ? parseInt(logo.value) == 1 : false,
            footer_text: footer_text ? parseInt(footer_text.value) == 1 : false,
            discount: discount ? parseInt(discount.value) == 1 : false,
            sum: sum ? parseInt(sum.value) == 1 : false,
          },
        },
        step2data: {
          selectedPositions: selectedPositionsIds,
        },
        step3data: {
          billing_peculiarities: edit.billing_peculiarities ? edit.billing_peculiarities : '',
          discount1_days: discount_1_days ? parseInt(discount_1_days.value) : 0,
          discount1_value: discount_1_value ? parseInt(discount_1_value.value) : 0,
          discount2_days: discount_2_days ? parseInt(discount_2_days.value) : 0,
          discount2_value: discount_2_value ? parseInt(discount_2_value.value) : 0,
          payment_terms_days: payment_terms ? parseInt(payment_terms.value) : 0,
        },
        type: edit.type,
      });
    } else if (duplicate) {
      const logo = duplicate.meta.find(el => el.name == 'logo');
      const footer_text = duplicate.meta.find(el => el.name == 'footer_text');
      const discount = duplicate.meta.find(el => el.name == 'discount');
      const sum = duplicate.meta.find(el => el.name == 'sum');
      const discount_1_days = duplicate.meta.find(el => el.name == 'discount_1_days');
      const discount_1_value = duplicate.meta.find(el => el.name == 'discount_1_value');
      const discount_2_days = duplicate.meta.find(el => el.name == 'discount_2_days');
      const discount_2_value = duplicate.meta.find(el => el.name == 'discount_2_value');
      const payment_terms = duplicate.meta.find(el => el.name == 'payment_terms');
      this.setState({
        step1data: {
          title: duplicate.title ? duplicate.title : '',
          pre_text: duplicate.text_before ? duplicate.text_before : '',
          end_text: duplicate.text_after ? duplicate.text_after : '',
          template_id: duplicate.template_id,
          additional_elements: {
            logo: logo ? parseInt(logo.value) == 1 : false,
            footer_text: footer_text ? parseInt(footer_text.value) == 1 : false,
            discount: discount ? parseInt(discount.value) == 1 : false,
            sum: sum ? parseInt(sum.value) == 1 : false,
          },
        },
        step3data: {
          billing_peculiarities: duplicate.billing_peculiarities ? duplicate.billing_peculiarities : '',
          discount1_days: discount_1_days ? parseInt(discount_1_days.value) : 0,
          discount1_value: discount_1_value ? parseInt(discount_1_value.value) : 0,
          discount2_days: discount_2_days ? parseInt(discount_2_days.value) : 0,
          discount2_value: discount_2_value ? parseInt(discount_2_value.value) : 0,
          payment_terms_days: payment_terms ? parseInt(payment_terms.value) : 0,
        },
        type: duplicate.type,
      });
    } else {
      // new invoice
      const logo = true;
      const footer_text = true;
      const discount = type === InvoiceTypes['offer'] || type === InvoiceTypes['order_confirmation'] || type === InvoiceTypes['invoice'];
      const sum = type === InvoiceTypes['offer'] || type === InvoiceTypes['order_confirmation'] || type === InvoiceTypes['invoice'];
      this.setState({
        type,
        step1data: {
          ...this.state.step1data,
          title: this.generateDialogTitleTypePart(type),
          additional_elements: {
            logo,
            footer_text,
            discount,
            sum,
          },
        },
      });
    }
  }

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleOnClose();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleOnClose();
    });
  }

  handleOnClose = () => {
    this.setState(CreateInvoice.defaultState);
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleCreateCancel = () => {
    this.setState({
      isCreateDialogOpen: false,
    });
  }

  handleCreateConfirm = () => {
    this.setState({
      isCreateDialogOpen: false,
    }, () => {
      this.handleCreateEdit();
    });
  }

  generateCreateEditData = (isDraft) => {
    const { orderData, positionCosts } = this.props;
    const { step1data, step2data, step3data, type } = this.state;

    const invoiceDataItems = step2data.selectedPositions.map((el, i) => {
      const position = orderData.positions.find(pos => pos.id == el);
      const price = positionCosts[el] && positionCosts[el].material_total ? positionCosts[el].material_total : position.unit_price ? position.unit_price : 0;
      return {
        position_id: el,
        order: i,
        amount: position.amount,
        metric: position.metric,
        total_price: price,
        vat: 0,
        discount: 0,
      };
    });
    const invoiceData = {
      type: type,
      title: step1data.title,
      text_before: step1data.pre_text,
      text_after: step1data.end_text,
      is_drafted: false,
      billing_peculiarities: step3data.billing_peculiarities,
      items: invoiceDataItems,
      template_id: step1data.template_id,
      is_drafted: isDraft ? 1 : 0,
      meta: [
        {
          name: "logo",
          value: step1data.additional_elements.logo,
        },
        {
          name: "footer_text",
          value: step1data.additional_elements.footer_text,
        },
        {
          name: "discount",
          value: step1data.additional_elements.discount,
        },
        {
          name: "sum",
          value: step1data.additional_elements.sum,
        },
        {
          name: "discount_1_days",
          value: step3data.discount1_days,
        },
        {
          name: "discount_1_value",
          value: step3data.discount1_value,
        },
        {
          name: "discount_2_days",
          value: step3data.discount2_days,
        },
        {
          name: "discount_2_value",
          value: step3data.discount2_value,
        },
        {
          name: "payment_terms",
          value: step3data.payment_terms_days,
        },
      ],
    };
    return invoiceData;
  }

  handleCreateEdit = (isDraft) => {
    const { edit, orderData, createInvoice, updateInvoice } = this.props;
    const invoiceData = this.generateCreateEditData(isDraft);
    if (!edit) {
      createInvoice(orderData.id, invoiceData, this.props.currentSearchTerms, this.props.currentFilters, this.props.currentSortBy, this.props.currentSortByOrder);
    } else {
      updateInvoice(!this.props.preventLoadOrderData ? orderData.id : null, edit.id, invoiceData, this.props.currentSearchTerms, this.props.currentFilters, this.props.currentSortBy, this.props.currentSortByOrder);
    }
  }

  handlePreviewButton = () => {
    const { orderData, previewInvoice } = this.props;
    const invoiceData = {
      ...this.generateCreateEditData(false),
      order_id: orderData.id,
    }
    previewInvoice(invoiceData);
  }

  handleSaveDraftButton = () => {
    this.handleCreateEdit(true);
  }

  handleCreateAction = () => {
    this.setState({
      isCreateDialogOpen: true,
    });
  }

  handleNavigationButton = (next) => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: next ? (currentStep + 1) : (currentStep - 1),
    });
  }

  handleStepDataChange = (stepNumber, data) => {
    this.setState({
      [`step${stepNumber}data`]: data,
      haveEdits: true,
    });
  }

  render() {
    const {
      orderData,
      isOpen,
      edit,
      currencySymbol,
      t
    } = this.props;
    const {
      type,
      step1data,
      step1errors,
      step2data,
      step2errors,
      step3data,
      step3errors,
      currentStep,
      isCloseDialogOpen,
      isCreateDialogOpen,
    } = this.state;

    const generateActionButtonText = () => {
      if (!edit) {
        return t('create').toUpperCase();
      } else {
        if (!edit.is_drafted) {
          return t('edit').toUpperCase();
        } else {
          return t('create').toUpperCase();
        }
      }
    };
    
    const invoiceTypeTitle = this.generateDialogTitleTypePart(type);

    let selectedComponent;
    switch(currentStep) {
      case 0:
        selectedComponent =
          <InvoiceStep1
            data={step1data}
            onDataChange={(data) => this.handleStepDataChange(1, data)}
            errors={step1errors}
            orderData={orderData}
          />;
        break;
      case 1:
        selectedComponent =
          <InvoiceStep2
            data={step2data}
            onDataChange={(data) => this.handleStepDataChange(2, data)}
            type={type}
            errors={step2errors}
            orderData={orderData}
            editItems={edit ? edit.items : null}
            currencySymbol={currencySymbol}
          />;
        break;
      case 2:
        selectedComponent =
          <InvoiceStep3
            data={step3data}
            onDataChange={(data) => this.handleStepDataChange(3, data)}
            errors={step3errors}
            orderData={orderData}
            dataStep2={step2data}
            editIssuedAt={edit ? edit.issued_at : null}
            currencySymbol={currencySymbol}
          />;
        break;
      default:
        return null;
    }
    return (
      <Dialog
        maxWidth={false}
        open={isOpen}
        PaperProps={{
          classes: {
            root: 'create-invoice-dialog',
          }
        }}>
        <MuiDialogTitle className="create-invoice-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{`${!edit ? t('create') : t('edit')} ${invoiceTypeTitle}`}</Typography>
          <div className="section-right">
            <span className="step-text">{ t('step_out_of_steps').replace('{0}', currentStep + 1).replace('{1}', 3) }</span>
            <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
              <img
                alt="create-invoice-close-btn"
                src={CloseButton}
              />
            </IconButton>
          </div>
        </MuiDialogTitle>
        <div className="create-invoice-content">
          { selectedComponent }
        </div>
        <div className="invoice-btn-container">
          <div className="lhs-button-container">
            <Button
              className="cancel-btn"
              onClick={this.handleOnClosePrompt}
            >
              { t('cancel').toUpperCase() }
            </Button>
            { currentStep > 0 && <Button
                className="navigation-btn"
                onClick={() => this.handleNavigationButton(false)}
              >
                { t('back').toUpperCase() }
              </Button>
            }
          </div>
          <div className="rhs-button-container">
            { currentStep < 2 && <Button
                className="navigation-btn"
                onClick={() => this.handleNavigationButton(true)}
              >
                { t('next').toUpperCase() }
              </Button>
            }
            <Button
              className="preview-btn"
              onClick={this.handlePreviewButton}
            >
              { t('preview') }
            </Button>
            { !(edit && !edit.is_drafted) && (
              <Button
                className="save-draft-btn"
                onClick={this.handleSaveDraftButton}
              >
                { t('save_draft') }
              </Button>
            )}
            <Button
              className="create-btn"
              onClick={this.handleCreateAction}
            >
              { generateActionButtonText() }
            </Button>
          </div>
        </div>
        { isCloseDialogOpen &&
          <CloseDialog
            isOpen={isCloseDialogOpen}
            title={t('close_document')}
            onCancel={this.handleOnClosePromptCancel}
            onConfirm={this.handleOnClosePromptConfirm}
          />
        }
        { isCreateDialogOpen &&
          <WarningDialog
            isOpen={isCreateDialogOpen}
            title={t('create_document')}
            text={t('create_document_confirmation')}
            buttonText={t('create')}
            onClose={this.handleCreateCancel}
            onCancel={this.handleCreateCancel}
            onConfirm={this.handleCreateConfirm}
          />
        }
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    isInvoiceValid: state.invoices.isInvoiceValid,
    invoiceError: state.invoices.error,
    lastPreTextValue: state.invoicesProviders.lastPreTextValue,
    lastEndTextValue: state.invoicesProviders.lastEndTextValue,
    invoiceTypesPresets: state.invoicesProviders.invoiceTypesPresets,
    haveLastPreEndText: state.invoicesProviders.haveLastPreEndText,
    positionCosts: state.orders.orderPositionCosts,
    projectsSettings: state.settings.projectsSettings,
    previewContent: state.invoices.previewContent,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTaskUnitList: () => dispatch(getTaskUnitList()),
    getPaymentTypeList: () => dispatch(getPaymentTypeList()),
    getPreTextPresets: () => dispatch(getPreTextPresets()),
    getEndTextPresets: () => dispatch(getEndTextPresets()),
    getInvoiceTypesPresets: (typeId) => dispatch(getInvoiceTypesPresets(typeId)),
    getLastPreEndText: () => dispatch(getLastPreEndText()),
    getProjectsSettings: () => dispatch(getProjectsSettings()),
    orderPositionCalculation: (data) => dispatch(orderPositionCalculation(data)),
    createInvoice: (orderId, data, searchTerms, filters, sortBy, sortByOrder) => dispatch(createInvoice(orderId, data, searchTerms, filters, sortBy, sortByOrder)),
    updateInvoice: (orderId, invoiceId, data, searchTerms, filters, sortBy, sortByOrder) => dispatch(updateInvoice(orderId, invoiceId, data, searchTerms, filters, sortBy, sortByOrder)),
    previewInvoice: (data) => dispatch(previewInvoice(data)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateInvoice));
