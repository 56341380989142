import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import CustomMultiselect from 'components/CustomMultiselect/CustomMultiselect';
import CustomDateRangePickerDropdown from 'components/CustomDateRangePickerDropdown/index';
import ProjectTabs from '../../components/ProjectTabs/ProjectTabs';
import ViewContact from "../ViewContact";
import EditContact from '../CreateContact';
import {Link} from 'react-router-dom';
import {filterOrders, getOrders, duplicateOrder, deleteOrder, setOrderStarred, setOrderStatus} from 'store/actions/orders';
import {getCustomer} from 'store/actions/customers';
import {
  getOrderStatusList,
  getInquiryStatusList,
  getTypeList,
  getSelectedCustomers,
} from 'store/actions/providers';
import Loader from 'components/Loader/Loader';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import {getStyleProgressDependsOnStatus, formatNumericValue} from 'utils/utils';

import {OrderAllTabs, OrderListSortByOptions, InquiriesAllTabs, InquiryListSortByOptions, OrderTab, InquiriesTab} from 'constants/constants';

import './style.scss';

import MaterialArrowUp from '../../assets/img/material_arrow_up.svg';
import MaterialArrowDown from '../../assets/img/material_arrow_down.svg';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import ViewButton from "../../components/ViewButton";
import IconButton from "@material-ui/core/IconButton";
import EditButton from "../../assets/img/buttons/edit.svg";
import EyeButton from "../../assets/img/buttons/eye.svg";
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import CloseButton from "../../assets/img/buttons/delete.svg";
import CopyButton from "../../assets/img/buttons/btn-copy.svg";
import CustomPagination from "../../components/CustomPagination";
import RemoveDialog from "../RemoveDialog";
import ConfirmDialog from "../ConfirmDialog";
import { getCustomers } from 'store/actions/customers';

class Orders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isViewTable: true,
      isOrder: false,
      value: 0,
      selectedTabId: 0,
      isViewContactOpen: false,
      isEditContactOpen: false,
      isRemoveOrderOpen: false,
      isStatusChangeOpen: false,
      deletedOrderId: '',
      duplicatedOrderId: '',
      filters: {
        starred: '',
        type: props.match.url === '/admin/orders' ? 0 : 1,
        origin_type: '',
        status: [-1],
        order: 'id',
        orderDir: true,
        page: 1,
        modified_from: null,
        modified_to: null,
        created_from: null,
        created_to: null,
      },
      contactId: ''
    };
  }

  componentDidMount() {
    const {
      getTypeList,
      getCustomers,
      getOrderStatusList,
      getInquiryStatusList,
      filterOrders,
    } = this.props;

    filterOrders(this.state.filters, this.props.searchTerms);
    getTypeList();
    getOrderStatusList();
    getInquiryStatusList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchTerms != prevProps.searchTerms) {
      const {
        filterOrders
      } = this.props;
      filterOrders(this.state.filters, this.props.searchTerms);
    }

    if (prevProps.orders !== this.props.orders && this.props.orders) {
      const orderCustomerIds = [];
      this.props.orders.forEach(el => {
        if (el.customer_id !== null && el.customer_id !== undefined && el.customer_id !== '') {
          orderCustomerIds.push(el.customer_id);
        }
      });
      this.props.getCustomers(orderCustomerIds);
    }

    if ((prevProps.redirectToOrderId === null || prevProps.redirectToOrderId === undefined) && (this.props.redirectToOrderId !== null && this.props.redirectToOrderId !== undefined)) {
      window.open(`/admin/orders/order-details/${this.props.redirectToOrderId}`, '_blank');
    }
    /* sample code that triggers a "clear search", should the page require it
    setTimeout(() => {
      this.props.onSearchReset && this.props.onSearchReset();
    }, 2500);
    */
  }

  handleDeleteButton = (id) => {
    this.setState({
      isRemoveOrderOpen: true,
      deletedOrderId: id
    });
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveOrderOpen: false,
    });
  }

  handleRemoveOrder = () => {
    const {
      deleteOrder,
      searchTerms
    } = this.props;
    const {deletedOrderId} = this.state;
    deleteOrder(deletedOrderId, this.props.match.url === '/admin/orders', this.state.filters, searchTerms);
    this.handleRemoveDialogClose()
  };

  handleDuplicateButton = (id) => {
    this.setState({
      isDuplicateOrderOpen: true,
      duplicatedOrderId: id
    });
  };

  handleDuplicateDialogClose = () => {
    this.setState({
      isDuplicateOrderOpen: false,
    });
  }

  handleDuplicateOrder = () => {
    const {
      duplicateOrder,
      searchTerms
    } = this.props;
    const {duplicatedOrderId} = this.state;
    duplicateOrder({id: duplicatedOrderId, isOrder: this.props.match.url === '/admin/orders', filters: this.state.filters, searchTerms: searchTerms});
    this.handleDuplicateDialogClose()
  };

  /*checkIsOrder = () => {
    const {
      filterOrders
    } = this.props;
    const {filters} = this.state;
    const newFilters = {...filters};
    if (this.props.match.url === '/admin/orders') {
      newFilters.type = 0;
      this.setState({
        filters: newFilters,
      }, () => {
        filterOrders(this.state.filters, this.props.searchTerms);
      });
    } else {
      newFilters.type = 1;
      this.setState({
        filters: newFilters,
      }, () => {
        filterOrders(this.state.filters, this.props.searchTerms);
      });
    }
  }*/

  handleFilters = (fieldName, value) => {
    const {
      filterOrders,
      typeList,
      orderStatusList,
      inquiryStatusList
    } = this.props;
    const { filters, selectedTabId } = this.state;
    const statusList = filters.type === 0 ? orderStatusList : inquiryStatusList;
    const newFilters = {...filters};
    let newSelectedTabId = selectedTabId;
    if (fieldName === 'origin_type') {
      if (+value !== typeList.length) {
        newFilters[fieldName] = value;
      } else {
        newFilters[fieldName] = '';
      }
    } else if (fieldName === 'status') {
      if (value.length == 0) {
        newFilters[fieldName] = [-1];
      } else {
        newFilters[fieldName] = value;
      }
      const statusIndex = filters.type == 0 ? orderStatusList.findIndex(el => el.id == OrderTab.COMPLETED) : inquiryStatusList.findIndex(el => el.id == InquiriesTab.ACCEPTED);
      const condition = ((filters.type == 0 && selectedTabId == OrderTab.COMPLETED) || (filters.type == 1 && selectedTabId == InquiriesTab.ACCEPTED)) &&
        (newFilters[fieldName].length != 1 || newFilters[fieldName].indexOf(statusIndex) != 0);
      if (condition) {  // reset to default tab
        newSelectedTabId = 0;
      }
    } else if (fieldName === 'order') {
      newFilters[fieldName] = value;
      newFilters['orderDir'] = true;
    } else {
      newFilters[fieldName] = value;
    }
    filterOrders(newFilters, this.props.searchTerms);
    this.setState({
      selectedTabId: newSelectedTabId,
      filters: newFilters,
    })
  };

  handleSortByFlipOrder = () => {
    this.handleFilters('orderDir', !this.state.filters.orderDir);
  }

  handleViewChange = () => {
    this.setState({
      isViewTable: !this.state.isViewTable
    })
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  onTabItemClick = id => {
    const { filterOrders, orderStatusList, inquiryStatusList, searchTerms } = this.props;
    const { filters } = this.state;
    switch (id) {
      case 0:
        const myOrderTabFilters = {
          starred: '',
          origin_type: '',
          type: filters.type,
          status: [-1],
          order: 'id'
        };
        filterOrders(myOrderTabFilters, searchTerms);
        this.setState({
          selectedTabId: id,
          filters: myOrderTabFilters
        });
        break;
      case 1:
        const starredTabFilters = {...filters, starred: 1, status: [-1]};
        filterOrders(starredTabFilters, searchTerms);
        this.setState({
          selectedTabId: id,
          filters: starredTabFilters
        });
        break;
      case 2:
        const statusIndex = filters.type === 0 ? orderStatusList.findIndex(el => el.value == "Completed") : inquiryStatusList.findIndex(el => el.value == "Accepted");
        const completedTabFilters = {...filters, status: [statusIndex], starred: ''}; // statusIndex would default to -1 if status cannot be found
        filterOrders(completedTabFilters, searchTerms);
        this.setState({
          selectedTabId: id,
          filters: completedTabFilters
        });
        break;
      default:
        return null
    }
  };

  toggleOrderStar = (id, value) => {
    const { toggleStar, searchTerms } = this.props;
    const { filters } = this.state;
    toggleStar(filters.type === 0, {id, value: value === 0 ? 1 : 0, filters, searchTerms});
  };

  setOrderStatus = (id, value) => {
    const { setStatus, searchTerms } = this.props;
    const { filters } = this.state;
    if (filters.type !== 0 && value == 1) { // 1 is hard-coded from BE (status Accepted)
      this.setState({
        isStatusChangeOpen: true,
        statusChangeData: {id, value, filters, searchTerms},
      });
    } else {
      setStatus(filters.type === 0, {id, value, filters, searchTerms});
    }
  };

  handleStatusChangeDialogClose = () => {
    this.setState({
      isStatusChangeOpen: false,
      statusChangeData: null,
    });
  }

  handleStatusChange = () => {
    this.props.setStatus(false, this.state.statusChangeData);
    this.handleStatusChangeDialogClose();
  }

  isProjectsDataReady() {
    const {
      typeList,
      orderStatusList,
      inquiryStatusList,
      customers
    } = this.props;
    const {filters} = this.state;
    return (
      filters.type !== "" &&
        typeList && typeList.length !== 0 &&
          orderStatusList && orderStatusList.length !== 0 &&
            inquiryStatusList && inquiryStatusList.length !== 0 &&
              customers
    );
  }

  handleShowViewContact = (id) => {
    this.props.getCustomer(id);
    this.setState({
      isViewContactOpen: true,
      contactId: id,
    })
  };

  handleCloseViewContact = () => {
    this.setState({
      isViewContactOpen: false,
    })
  }

  handleShowEditContact = (id) => {
    if (id) {
      this.props.getCustomer(id);
    }
    this.setState({
      isEditContactOpen: true,
      isViewContactOpen: false,
    })
  }

  handleCloseEditContact = () => {
    this.setState({
      isEditContactOpen: false,
    })
  };

  onAfterEditContact = () => {
    // reload customers list to show edit effect
    this.props.getCustomers()
  }

  handlePageClick = (data) => {
    const {selected} = data;
    this.handleFilters('page', selected + 1)
  };

  renderOrder = (order, index) => {
    const {
      orderStatusList,
      inquiryStatusList,
      customers,
      currencySymbol,
    } = this.props;
    const { filters } = this.state;
    const statusList = filters.type === 0 ? orderStatusList : inquiryStatusList;
    const date = moment(order.due_date);
    const delay = moment().diff(date, 'days') > 0  ? moment().diff(date, 'days') : '-';
    const styleProgressData = getStyleProgressDependsOnStatus(order.status, order.type === 0);
    const customer = customers.find(item => item.id === order.customer_id);
    const sanitizeOrderNum = orderNum => {
      if (orderNum && orderNum.length <= 6) {
        return orderNum;
      } else {
        const abbr = `${orderNum.substring(0, 2)}...${orderNum.substring(orderNum.length - 4)}`;
        return <span title={orderNum}>{abbr}</span>;
      }
    };
    const formatTableDate = (date) => {
      if (!date || !date.split)
        return '';
      const originalDateParts = date.split(' ');
      if (originalDateParts.length == 0)
        return '';
      const datePart = originalDateParts[0];
      const datePartParts = datePart.split('-');
      if (datePartParts.length != 3)
        return '';

      return `${datePartParts[2]}.${datePartParts[1]}.${datePartParts[0]}`;
    }
    return(
      <GridContainer
        className={`projects-item ${ index % 2 === 0 ? 'dark' : '' } pr-gd-col-container`}
        key={index}
      >
        <GridItem className="project-item-title pr-gd-col-star">
          <div
            className="ikt-ap_projects-star-wrapper"
            onClick={() => this.toggleOrderStar(order.id, order.is_starred)}
          >
            <Icon
              className={`ikt-ap_projects-star-icon${
                order.is_starred !== 0
                  ? ` ikt-ap_is-active`
                  : ``
              }`}
            >
              star
            </Icon>
          </div>
        </GridItem>
        <GridItem className="project-item-title pr-gd-col-order-id">{order.order_no}</GridItem>
        <GridItem className="project-item-title pr-gd-col-type">{order.origin_type === 0 ? 'Manual' : 'From Webshop'}</GridItem>
        <GridItem className={`project-item-title pr-gd-col-status status-dropdown ${styleProgressData.style}`}>
          <Dropdown
            buttonText={
              order.status
            }
            buttonIconType="white"
            buttonProps={{className: 'dropdown-blue-style status-dropdown', round: true, size: "sm", disabled: !order.can_edit}}
            hoverColor="dark"
            dropdownList={statusList}
            translateValues={true}
            onClick={data => this.setOrderStatus(order.id, data.id)}
          />
        </GridItem>
        <GridItem className="project-item-title pr-gd-col-customer bold customer left-aligned" onClick={() => {customer && this.handleShowViewContact(order.customer_id)}}>
          {`${customer ? customer.value : ''}`}
        </GridItem>
        <GridItem className="project-item-title pr-gd-col-modified left-aligned">
          {formatTableDate(order.updated_at)}
        </GridItem>
        <GridItem className="project-item-title pr-gd-col-created left-aligned">
          {formatTableDate(order.created_at)}
        </GridItem>
        <GridItem className="project-item-title pr-gd-col-cost right-aligned">{`${currencySymbol} ${formatNumericValue(parseFloat(order.cost), 2)}`}</GridItem>
        <GridItem className={`project-item-title pr-gd-col-progress progress-container ${styleProgressData.style}`}>
          <div style={{ maxWidth: "24px" }}>
            <CircularProgressbar
              value={styleProgressData.progress}
              strokeWidth={50}
              styles={buildStyles({
                strokeLinecap: "butt"
              })}
            />
          </div>
        </GridItem>
        <GridItem className="project-item-title pr-gd-col-issues left-aligned">{'-'}</GridItem>
        <GridItem className="project-item-title pr-gd-col-delay delay-container left-aligned">{delay}</GridItem>
        <GridItem className="project-item-title pr-gd-col-actions order-action-container">
          { order.can_delete &&
            <IconButton
              aria-label="close"
              className={`action-btn delete-order-btn ${order.origin_type !== 0 ? 'hidden' : ''}`}
              onClick={() => this.handleDeleteButton(order.id)}
            >
              <img alt="orders-list-delete-order" src={CloseButton}/>
            </IconButton>
          }
          <IconButton
            aria-label="close"
            className={`action-btn delete-order-btn`}
            onClick={() => this.handleDuplicateButton(order.id)}
          >
            <img alt="orders-list-duplicate-order" src={CopyButton}/>
          </IconButton>
          { order.can_edit &&
            <Link
              to={filters.type === 0 ? `/admin/orders/edit-order/${order.id}` : `/admin/inquiries/edit-inquiry/${order.id}`}
            >
              <IconButton aria-label="close" className="action-btn">
                <img alt="orders-list-edit-order" src={EditButton}/>
              </IconButton>
            </Link>
          }
          <Link
            to={filters.type === 0 ? `/admin/orders/order-details/${order.id}` : `/admin/inquiries/inquiry-details/${order.id}`}
          >
            <IconButton aria-label="close" className="action-btn action-btn-view">
              <img alt="orders-list-view-order" src={EyeButton}/>
            </IconButton>
          </Link>
        </GridItem>
      </GridContainer>
    )
  };

  renderOrderCard = (order, index) => {
    const {
      orderStatusList,
      inquiryStatusList,
      customers,
      currencySymbol,
    } = this.props;
    const {filters} = this.state;
    const statusList = filters.type === 0 ? orderStatusList : inquiryStatusList;
    const styleProgressData = getStyleProgressDependsOnStatus(order.status, order.type === 0);
    const customer = customers.find(item => item.id === order.customer_id);
    const date = moment(order.due_date);
    const delay = moment().diff(date, 'days') > 0  ? moment().diff(date, 'days') : '-';
    const handleCustomerName = customerName => {
      const maxCustomerLength = 10;
      if (customerName.length > maxCustomerLength) {
        const abbr = customerName.substring(0, maxCustomerLength) + "...";
        return <div className="order-item bold customer" title={ customerName } onClick={() => {customer && this.handleShowViewContact(order.customer_id)}}>
            { abbr }
        </div>;
      } else {
        return <div className="order-item bold customer" onClick={() => {customer && this.handleShowViewContact(order.customer_id)}}>
            { customerName }
        </div>;
      }
    };
    const sanitizeOrderNum = orderNum => {
      if (orderNum && orderNum.length <= 6) {
        return orderNum;
      } else {
        const abbr = `${orderNum.substring(0, 2)}...${orderNum.substring(orderNum.length - 4)}`;
        return <span title={orderNum}>{abbr}</span>;
      }
    };
    return (
      <GridContainer className="order-item-card" key={index}>
        <div className="order-item-left">
          <div className="order-item category">{order.origin_type === 0 ? 'Manual' : 'From Webshop'}</div>
          { handleCustomerName(customer ? customer.value : '') }
          <div className={`order-item progress-container ${styleProgressData.style}`}>
            <div style={{ maxWidth: "24px" }}>
              <CircularProgressbar
                value={styleProgressData.progress}
                strokeWidth={50}
                styles={buildStyles({
                  strokeLinecap: "butt"
                })}
              />
            </div>
            <div className="issue-text bold">{}</div>
          </div>
          <div className="order-item delay"><span className="bold">Delay</span> {`${delay}`}</div>
          <div className={`card-status-dropdown ${styleProgressData.style}`}>
            <Dropdown
              buttonText={order.status}
              buttonIconType="white"
              buttonProps={{className: 'dropdown-blue-style status-dropdown', round: true, size: "sm", disabled: !order.can_edit}}
              hoverColor="dark"
              dropdownList={statusList}
              translateValues={true}
              onClick={data => this.setOrderStatus(order.id, data.id)}
            />
          </div>
        </div>
        <div className="order-item-right">
          <div className="order-item star-id">
            <div className="project-id">{order.id}</div>
            <div
              className="ikt-ap_projects-star-wrapper star-icon"
              onClick={() => this.toggleOrderStar(order.id, order.is_starred)}
            >
              <Icon
                className={`ikt-ap_projects-star-icon${
                  order.is_starred !== 0
                    ? ` ikt-ap_is-active`
                    : ``
                }`}
              >
                star
              </Icon>
            </div>
          </div>
          <div className="order-item project-price">{`${currencySymbol} ${order.cost}`}</div>
          <div className="remove-btn">
            { order.can_delete && order.origin_type === 0 &&
                <IconButton
                  aria-label="close"
                  className="action-btn"
                  onClick={() => this.handleDeleteButton(order.id)}
                >
                  <img alt="order-card-delete-btn" src={CloseButton}/>
                </IconButton>
            }
          </div>
          <div className="action-icons">
            <IconButton
              aria-label="close"
              className={`action-btn delete-order-btn`}
              onClick={() => this.handleDuplicateButton(order.id)}
            >
              <img alt="orders-list-duplicate-order" src={CopyButton}/>
            </IconButton>
            { order.can_edit &&
              <Link
                to={filters.type === 0 ? `/admin/orders/edit-order/${order.id}` : `/admin/inquiries/edit-inquiry/${order.id}`}
              >
                <IconButton aria-label="close" className="action-btn">
                  <img alt="order-card-edit-btn" src={EditButton}/>
                </IconButton>
              </Link>
            }
            <Link
              to={filters.type === 0 ? `/admin/orders/order-details/${order.id}` : `/admin/inquiries/inquiry-details/${order.id}`}
            >
              <IconButton aria-label="close" className="action-btn action-btn-view">
                <img alt="order-card-view-btn" src={EyeButton}/>
              </IconButton>
            </Link>
          </div>
        </div>
      </GridContainer>
    )
  }

  render() {
    const {
      orders,
      metaData,
      orderStatusList,
      inquiryStatusList,
      typeList,
      customer,
      ordersBusy,
      t
    } = this.props;
    const {
      isViewTable,
      isRemoveOrderOpen,
      isDuplicateOrderOpen,
      isStatusChangeOpen,
      selectedTabId,
      contactId,
      filters
    } = this.state;
    const statusList = filters.type === 0 ? orderStatusList : inquiryStatusList;
    const {isViewContactOpen, isEditContactOpen } = this.state;
    const dropdownTypeList = [{id: typeList.length, value: t('all_types')}, ...typeList];
    const dropdownStatusList = [{id: -1, value: t('all_statuses'), is_select_all: true}, ...statusList].map(el => ({
      ...el,
      id: parseInt(el.id),
    }));
    // following two lines are fix for an obscure bug, and hence are necessary
    const condition = this.props.match.url === '/admin/orders' ? 1 : 0;
    const filteredOrders = orders.filter(order => order.type !== condition);

    return (
      <div className="ikt-ap_projects-container">
        {this.isProjectsDataReady()  ? (
          <>
          <GridContainer>
            <GridItem className="ikt-ap_projects-top-wrapper" xs={12}>
              <GridContainer
                className="ikt-ap_projects-top-container"
                direction="row"
                justify="flex-end"
              >
                <GridItem className="ikt-ap_projects-top-left">
                  <ProjectTabs
                    tabs={filters.type === 0 ? OrderAllTabs : InquiriesAllTabs}
                    selectedTab={selectedTabId}
                    onTabItemClick={this.onTabItemClick}
                  />
                </GridItem>
                <GridItem className="ikt-ap_projects-top-right">
                  <CustomDropdown
                    buttonText={
                      filters.origin_type
                    }
                    buttonPlaceholder={t('all_types')}
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      round: true,
                      size: 'sm'
                    }}
                    menuWidthCorrection={true}
                    hoverColor="rose"
                    dropdownList={dropdownTypeList}
                    translateValues={true}
                    onClick={value => this.handleFilters('origin_type', value.id)}
                  />
                  <CustomMultiselect
                    buttonValue={filters.status}
                    buttonPlaceholder={t('all_statuses')}
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      round: true,
                      size: 'sm'
                    }}
                    menuWidthCorrection={true}
                    hoverColor="rose"
                    dropdownList={dropdownStatusList}
                    translateValues={true}
                    onChange={values => this.handleFilters('status', values)}
                  />
                  <CustomDateRangePickerDropdown
                    dateFrom={filters.modified_from}
                    dateTo={filters.modified_to}
                    dateFromPlaceholder={t('from') + "..."}
                    dateToPlaceholder={t('up_to') + "..."}
                    buttonPlaceholder={t('date_modified') + "..."}
                    buttonProps={{
                    className: 'dropdown-blue-style',
                    color: 'transparent',
                    round: true,
                    size: 'sm'
                    }}
                    hoverColor="rose"
                    onChange={(code, date) => this.handleFilters(code == 0 ? 'modified_from' : 'modified_to', date)}
                  />
                  <CustomDateRangePickerDropdown
                    dateFrom={filters.created_from}
                    dateTo={filters.created_to}
                    dateFromPlaceholder={t('from') + "..."}
                    dateToPlaceholder={t('up_to') + "..."}
                    buttonPlaceholder={t('date_created') + "..."}
                    buttonProps={{
                    className: 'dropdown-blue-style',
                    color: 'transparent',
                    round: true,
                    size: 'sm'
                    }}
                    hoverColor="rose"
                    onChange={(code, date) => this.handleFilters(code == 0 ? 'created_from' : 'created_to', date)}
                  />
                  <CustomDropdown
                    buttonText={filters.order}
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      size: 'sm'
                    }}
                    menuWidthCorrection={true}
                    hoverColor="rose"
                    dropdownList={filters.type === 0 ? OrderListSortByOptions : InquiryListSortByOptions}
                    translateValues={true}
                    onClick={value => this.handleFilters('order', value.id)}
                  />
                  <ViewButton
                    className="project-view-button"
                    isTable={isViewTable}
                    onViewChange={this.handleViewChange}
                  />
                  <Link to={filters.type === 0 ? '/admin/orders/create-order' : '/admin/inquiries/create-inquiry'}>
                    <Button
                      className="ikt-ap_projects-create-btn"
                    >
                      <Icon>add</Icon> { t('create').toUpperCase() }
                    </Button>
                  </Link>
                </GridItem>
              </GridContainer>
            </GridItem>
            {isViewTable && <GridContainer className="projects-table-header pr-gd-col-container" direction="row">
              <GridItem className="projects-header-title pr-gd-col-star">&nbsp;</GridItem>
              <GridItem className={"projects-header-title pr-gd-col-order-id" + (filters.order === 'id' ? ' col-sort-active' : '')}>
                <span onClick={filters.order === 'id' ? this.handleSortByFlipOrder : null}>
                  {`${filters.type === 0 ? t('order_id') : t('inquiry_id')}`} { filters.order === 'id' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className={"projects-header-title pr-gd-col-type" + (filters.order === 'origin_type' ? ' col-sort-active' : '')}>
                <span onClick={filters.order === 'origin_type' ? this.handleSortByFlipOrder : null}>
                  { t('type') } { filters.order === 'origin_type' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className={"projects-header-title pr-gd-col-status left-aligned" + (filters.order === 'status' ? ' col-sort-active' : '')}>
                <span onClick={filters.order === 'status' ? this.handleSortByFlipOrder : null}>
                  { t('status') } { filters.order === 'status' && <img alt="arrow-sort-direction" src={filters.orderDir ? MaterialArrowDown : MaterialArrowUp} /> }
                </span>
              </GridItem>
              <GridItem className="projects-header-title pr-gd-col-customer left-aligned">{ t('customer') }</GridItem>
              <GridItem className="projects-header-title pr-gd-col-modified left-aligned">{ t('last_modified') }</GridItem>
              <GridItem className="projects-header-title pr-gd-col-created left-aligned">{ t('created') }</GridItem>
              <GridItem className="projects-header-title pr-gd-col-cost right-aligned">{ t('cost') }</GridItem>
              <GridItem className="projects-header-title pr-gd-col-progress left-aligned">{ t('progress') }</GridItem>
              <GridItem className="projects-header-title pr-gd-col-issues left-aligned">{ t('issues') }</GridItem>
              <GridItem className="projects-header-title pr-gd-col-delay left-aligned">{ t('delay') }</GridItem>
              <GridItem className="projects-header-title pr-gd-col-actions right-aligned">{ t('actions') }</GridItem>
            </GridContainer>}
            <GridContainer className={`projects-content ${!isViewTable ? 'project-card-view' : ''}`}>
              {filteredOrders.length !== 0 &&
                isViewTable
                  ? filteredOrders.map((project, index) => this.renderOrder(project, index))
                  : filteredOrders.map((project, index) => this.renderOrderCard(project, index))
              }
              {
                !ordersBusy &&
                filteredOrders.length === 0 &&
                    <div className="no_orders">{this.props.match.url === '/admin/orders' ? t('no_orders_found') : t('no_inquiries_found')}</div>
              }
            </GridContainer>
          </GridContainer>
          <GridContainer className="page-ination-container">
            {
              Math.ceil(metaData.total/metaData.per_page) > 1 &&
                <CustomPagination
                  pageCount={Math.ceil(metaData.total/metaData.per_page)}
                  handlePageClick={this.handlePageClick}
                />
             }
          </GridContainer>
          </>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
        {
          isViewContactOpen && Object.keys(customer).length !== 0 &&
          <ViewContact
            isOpen={isViewContactOpen}
            allowDelete={ filters.type === 0 && customer.can_delete /* should always evaluate to false */ }
            onClose={this.handleCloseViewContact}
            onEditContact={this.handleShowEditContact}
            id={contactId}
          />
        }
        {
          isEditContactOpen && Object.keys(customer).length !== 0 &&
          <EditContact
            isOpen={isEditContactOpen}
            onClose={this.handleCloseEditContact}
            isEditContact={true}
            editCustomer={customer}
            editCallback={() => this.onAfterEditContact()}
          />
        }
        {isRemoveOrderOpen && (
          <RemoveDialog
            isOpen={isRemoveOrderOpen}
            title={filters.type === 0 ? t('remove_order') : t('remove_inquiry')}
            text={filters.type === 0 ? t('remove_order_confirmation') : t('remove_inquiry_confirmation')}
            onClose={this.handleRemoveDialogClose}
            onRemove={this.handleRemoveOrder}
          />
        )}
        {isDuplicateOrderOpen && (
          <ConfirmDialog
            isOpen={isDuplicateOrderOpen}
            title={filters.type === 0 ? t('duplicate_order') : t('duplicate_inquiry')}
            text={filters.type === 0 ? t('duplicate_order_confirmation') : t('duplicate_inquiry_confirmation')}
            onClose={this.handleDuplicateDialogClose}
            onConfirm={this.handleDuplicateOrder}
          />
        )}
        {isStatusChangeOpen && (
          <ConfirmDialog
            isOpen={isStatusChangeOpen}
            title={t('change_inquiry_status')}
            text={t('change_inquiry_status_confirmation')}
            onClose={this.handleStatusChangeDialogClose}
            onConfirm={this.handleStatusChange}
          />
        )}
      </div>
    );
  }
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
  searchTerms: PropTypes.string,
  onSearchReset: PropTypes.func
};

const mapStateToProp = state => {
  return {
    orders: state.orders.orders,
    ordersBusy: state.orders.ordersBusy,
    metaData: state.orders.ordersMetaData,
    redirectToOrderId: state.orders.redirectToOrderId,
    customers: state.providers.selectedCustomers,
    customer: state.customers.customer,
    typeList: state.providers.typeList,
    orderStatusList: state.providers.orderStatusList,
    inquiryStatusList: state.providers.inquiryStatusList,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteOrder: (id, isOrder, filters, searchTerms) => dispatch(deleteOrder(id, isOrder, filters, searchTerms)),
    duplicateOrder: (data) => dispatch(duplicateOrder(data)),
    getCustomers: (ids) => dispatch(getSelectedCustomers(ids)),
    getCustomer: (id) => dispatch(getCustomer(id)),
    getTypeList: () => dispatch(getTypeList()),
    getOrderStatusList: () => dispatch(getOrderStatusList()),
    getInquiryStatusList: () => dispatch(getInquiryStatusList()),
    toggleStar: (isOrder, data) => dispatch(setOrderStarred(isOrder, data)),
    setStatus: (isOrder, data) => dispatch(setOrderStatus(isOrder, data)),
    filterOrders: (data, searchTerms) => dispatch(filterOrders(data, searchTerms))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(Orders)));
