import React from "react";
import { withTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CloseButton from "assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import './style.scss';
import CheckMarkIcon from "../../assets/img/checkmark-large.svg";

class ResetPasswordDialog extends React.Component {
  render() {
    const {
      isOpen,
      email,
      onClose,
      t
    } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="reset-password-title" disableTypography>
          <Typography variant="h6" className="dialog-title">
            { t('reset_password') }
          </Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img alt="file-import-close-btn" src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>

        <div className="reset-password-content">
          <div className="check-mark">
            <img src={CheckMarkIcon}/>
          </div>
          <div>
            {`${t('reset_password_email_sent_pre')} ${email} ${t('reset_password_email_sent_post')}`}
          </div>
        </div>
        <div className="reset-password-btn-container">
          <Button
            className="cancel-btn"
            onClick={onClose}
          >
            { t('ok') }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ResetPasswordDialog);
