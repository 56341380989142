import BaseAPIService from './Base';

export default class TimerAPIService extends BaseAPIService {
  getCurrentTimer = async () => {
    return this.request('api/timesheet/timer').then((data) => {
      return data;
    });
  };

  startTimer = async (data) => {
    return this.request('api/timesheet/timer/start', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  pauseTimer = async () => {
    return this.request('api/timesheet/timer/pause', {
      method: 'POST',
    });
  };

  resumeTimer = async () => {
    return this.request('api/timesheet/timer/resume', {
      method: 'POST',
    });
  };

  updateTimerNote = async (note) => {
    return this.request('api/timesheet/timer/notes', {
      method: 'POST',
      body: JSON.stringify({
        notes: note,
      }),
    });
  }

  finalizeTimer = async (data) => {
    return this.request('api/timesheet/timer/stop', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  deleteTimer = async () => {
    return this.request('api/timesheet/timer/delete', {
      method: 'DELETE',
    });
  }
}
