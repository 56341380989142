import React from 'react';
import { withTranslation } from 'react-i18next';
import GridItem from 'components/Grid/GridItem';
import { formatNumericValue } from "utils/utils";

import './style.scss';

class CostDetails extends React.Component {
  renderCostDetail = (detail, index) => {
    const { t, currencySymbol } = this.props;
    return (
      <div key={index} className="details-item">
        <GridItem className="details-item-title">
          <span>{detail.title}</span>
        </GridItem>
        <GridItem
          className="detail-item"
        >
          <span>{ t('hours') }</span>
          <span className="detail-count">{`${detail.amount ? detail.amount : '0'} hrs`}</span>
        </GridItem>
        <GridItem
          className="detail-item"
        >
          <span>{ t('cost') }</span>
          <span className="detail-count">{`${formatNumericValue(detail.value ? detail.value : 0, 2)} ${currencySymbol}`}</span>
        </GridItem>
      </div>
    )
  }

  render() {
    const { data, currencySymbol, t } = this.props;
    return (
      <GridItem className="details-container" xs={2}>
        <div className="details-title">
          <span>{ t('details') }</span>
        </div>
        {data.cost_centers.map((detail, index) => this.renderCostDetail(detail, index))}
        <div className="detail-total-cost">
          <div className="total-cost-item">
            <span>{ t('total_hours') }</span>
            <span className="total-cost">{data.totals.amount}</span>
          </div>
          <div className="total-cost-item">
            <span>{ t('total_cost') }</span>
            <span className="total-cost">{formatNumericValue(data.totals.value, 2)}{currencySymbol}</span>
          </div>
        </div>
      </GridItem>
    )
  }
}

export default withTranslation()(CostDetails);
