import React from 'react';

import { Editor, EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';

import 'draft-js/dist/Draft.css';
import './styles.scss';

class RTViewer extends React.Component {

  render() {
    const { value, className } = this.props;
    const valueChecked = value ? value : '';
    const editorState = EditorState.createWithContent(
      convertFromHTML(valueChecked)
    );
    return (
      <div className={`RTViewer-root ${className}`}>
        <Editor
          editorState={editorState}
          onChange={() => {}}
          readOnly={true}
        />
      </div>
    );
  }
}

export default RTViewer;
