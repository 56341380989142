import { handleActions } from 'redux-actions';
import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
  TOGGLE_PROJECT_STAR,
  GET_SINGLE_PROJECT,
  GET_SINGLE_PROJECT_SUCCESS,
  GET_SINGLE_PROJECT_ERROR,
  RESET_SINGLE_PROJECT,
  UPDATE_SINGLE_PROJECT
} from 'store/actionTypes/projects';

export const DEFAULT_PROJECTS_STATE = {
  projects: [],
  projectsError: null,
  projectsBusy: false,
  project: {},
  projectError: null,
  projectBusy: false
};

export const projects = handleActions(
  {
    [GET_PROJECTS]: (state, action) => ({
      ...state,
      projectsBusy: true
    }),
    [GET_PROJECTS_SUCCESS]: (state, action) => ({
      ...state,
      projects: action.payload,
      projectsError: null,
      projectsBusy: false
    }),
    [GET_PROJECTS_ERROR]: (state, action) => ({
      ...state,
      projectsError: action.payload.message,
      projectsBusy: false
    }),
    [TOGGLE_PROJECT_STAR]: (state, action) => {
      let projects = state.projects;
      let projectId = action.payload;

      projects = projects.map(project => {
        if (project.id === projectId) {
          project.isStarred = !project.isStarred;
        }
        return project;
      });

      return {
        ...state,
        projects
      };
    },
    [GET_SINGLE_PROJECT]: (state, action) => ({
      ...state,
      projectBusy: true
    }),
    [GET_SINGLE_PROJECT_SUCCESS]: (state, action) => ({
      ...state,
      project: action.payload,
      projectError: null,
      projectBusy: false
    }),
    [GET_SINGLE_PROJECT_ERROR]: (state, action) => ({
      ...state,
      projectError: action.payload.message,
      projectBusy: false
    }),
    [RESET_SINGLE_PROJECT]: (state, action) => ({
      ...state,
      project: {}
    }),
    [UPDATE_SINGLE_PROJECT]: (state, action) => {
      const project = action.payload;
      const projects = state.projects.map((currProject) => {
        if (currProject.id === project.id) {
          return project;
        }

        return currProject;
      })
      return {
        ...state,
        projects,
        project
      };
    }
  },
  DEFAULT_PROJECTS_STATE
);
