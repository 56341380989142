import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Input from 'components/DecimalInput';
import TextArea from '../../components/CustomTextArea';
import { formatNumericValue } from "utils/utils";

class Step3 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

  componentWillUnmount() {
    
  }

  onDataChange = (name, value) => {
    const { data, onDataChange } = this.props;
    const newData = { ...data };
    newData[name] = value;
    if (onDataChange)
      onDataChange(newData);
  }

  render() {
    const {
      t,
      data,
      dataStep2,
      positionCosts,
      orderData,
      editIssuedAt,
      errors,
      currencySymbol,
    } = this.props;
    const {
    } = this.state;

    let totalSelectedPrice = 0;
    let totalSelectedVat = 0;
    let totalSelectedDiscount = 0;
    orderData.positions.filter(el => dataStep2.selectedPositions.indexOf(el.id) !== -1).forEach(el => {
      const price = positionCosts[el.id] && positionCosts[el.id].material_total ? positionCosts[el.id].material_total : el.unit_price ? el.unit_price : 0;
      totalSelectedPrice += price;
      totalSelectedDiscount += el.discount;
      totalSelectedVat += el.vat;
    });
    
    let refDate;
    if (!editIssuedAt) {
      refDate = new Date();
    } else {
      const issuedAtParts = editIssuedAt.split('-');
      refDate = new Date(parseInt(issuedAtParts[0]), parseInt(issuedAtParts[1]) - 1, parseInt(issuedAtParts[2]));
    }
    
    const discount1ValidUntil = new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate() + data.discount1_days);
    const discount2ValidUntil = new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate() + data.discount2_days);
    const paymentTermsValidUntil = new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate() + data.payment_terms_days);
    
    const formatDate = date => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const days = date.getDate();
      return `${days >= 10 ? days : '0' + days}/${month >= 10 ? month : '0' + month}/${year}`;
    };
    return (
      <GridContainer className="step3-container">
        <GridItem className="data-container" xs={12}>
          <div className="data-title">
            <span>{ t('invoice_summary') }</span>
          </div>
          <div className="data-content invoice-summary">
            <div className="data-box">
              <span className="name">{ t('selected_positions') }</span>
              <span className="value">{ dataStep2.selectedPositions.length }</span>
            </div>
            <div className="data-box">
              <span className="name">{ t('total_price') }</span>
              <span className="value">{ `${currencySymbol} ${formatNumericValue(totalSelectedPrice, 2)}` }</span>
            </div>
            <div className="data-box">
              <span className="name">{ t('total_vat') }</span>
              <span className="value">{ `${currencySymbol} ${formatNumericValue(totalSelectedVat, 2)}` }</span>
            </div>
            <div className="data-box">
              <span className="name">{ t('total_discount') }</span>
              <span className="value">{ `${currencySymbol} ${formatNumericValue(totalSelectedDiscount, 2)}` }</span>
            </div>
          </div>
        </GridItem>
        <GridItem className="data-container" xs={12}>
          <div className="data-title">
            <span>{ t('discounts') }</span>
          </div>
          <div className="data-content discounts">
            <div className="data-content-title">
              <GridItem xs={6}>
                { t('name') }
              </GridItem>
              <GridItem className="right-aligned" xs={2}>
                { t('days') }
              </GridItem>
              <GridItem className="right-aligned" xs={2}>
                { t('value') }
              </GridItem>
              <GridItem className="right-aligned" xs={2}>
                { t('active_until') }
              </GridItem>
            </div>
            <div className="data-content-body">
              <GridItem className="data-content-body-item" xs={12}>
                <GridItem className="data-title-container" xs={6}>
                  { t('discount') } 1
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    value={data.discount1_days}
                    onChange={(value) => this.onDataChange('discount1_days', parseInt(value) ? parseInt(value) : 0)}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    value={data.discount1_value}
                    onChange={(value) => this.onDataChange('discount1_value', parseInt(value) ? parseInt(value) : 0)}
                    adornment={'%'}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  { formatDate(discount1ValidUntil) }
                </GridItem>
              </GridItem>
              <GridItem className="data-content-body-item" xs={12}>
                <GridItem className="data-title-container" xs={6}>
                  { t('discount') } 2
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    value={data.discount2_days}
                    onChange={(value) => this.onDataChange('discount2_days', parseInt(value) ? parseInt(value) : 0)}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    value={data.discount2_value}
                    onChange={(value) => this.onDataChange('discount2_value', parseInt(value) ? parseInt(value) : 0)}
                    adornment={'%'}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  { formatDate(discount2ValidUntil) }
                </GridItem>
              </GridItem>
              <GridItem className="data-content-body-item" xs={12}>
                <GridItem className="data-title-container" xs={6}>
                  { t('payment_terms') }
                </GridItem>
                <GridItem className="data-value-container payment-terms-input-field" xs={4}>
                  <Input
                    value={data.payment_terms_days}
                    onChange={(value) => this.onDataChange('payment_terms_days', parseInt(value) ? parseInt(value) : 0)}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  { formatDate(paymentTermsValidUntil) }
                </GridItem>
              </GridItem>
            </div>
          </div>
        </GridItem>
        <GridItem className="data-container" xs={12}>
          <div className="data-title">
            <span>{ t('billing_peculiarities') }</span>
          </div>
          <div className="data-content billing-peculiarities">
            <GridItem className="data-content-title" xs={12}>
              { t('billing_peculiarities') }
            </GridItem>
            <GridItem className="data-content-body" xs={12}>
              <TextArea
                value={data.billing_peculiarities}
                onChange={e => this.onDataChange('billing_peculiarities', e.target.value)}
                error={errors && errors.billing_peculiarities}
                errorMsg={errors && errors.billing_peculiarities}
              />
            </GridItem>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProp = state => {
  return {
    positionCosts: state.orders.orderPositionCosts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Step3));
