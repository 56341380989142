import { handleActions } from 'redux-actions';
import {
  GET_METRICS,
  GET_METRICS_SUCCESS,
  GET_METRICS_ERROR,
  GET_CURRENCY,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_ERROR,
} from '../actionTypes/globalsActionTypes';

export const DEFAULT_GLOBALS_STATE = {
  metrics: [],
  currency: null,
  currencySymbol: null,
  globalsError: null,
  globalsBusy: false,
};

export const globals = handleActions(
  {
    [GET_METRICS]: (state, action) => ({
      ...state,
      metrics: [],
      globalsError: null,
      globalsBusy: true,
    }),
    [GET_METRICS_SUCCESS]: (state, action) => ({
      ...state,
      metrics: action.payload,
      globalsError: null,
      globalsBusy: false,
    }),
    [GET_METRICS_ERROR]: (state, action) => ({
      ...state,
      globalsError: action.payload.message,
      globalsBusy: false,
    }),
    [GET_CURRENCY]: (state, action) => ({
      ...state,
      currency: null,
      currencySymbol: null,
      globalsError: null,
      globalsBusy: true,
    }),
    [GET_CURRENCY_SUCCESS]: (state, action) => ({
      ...state,
      currency: action.payload.toUpperCase(),
      currencySymbol: action.payload === 'Usd' ? '$' : '€',
      globalsError: null,
      globalsBusy: false,
    }),
    [GET_CURRENCY_ERROR]: (state, action) => ({
      ...state,
      globalsError: action.payload.message,
      globalsBusy: false,
    }),
  },
  DEFAULT_GLOBALS_STATE
);
