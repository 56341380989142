/*!
/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import ArrowWhite from '../../assets/img/buttons/status-dropdown.svg';

import "./DropdownButton.scss";

class DropdownButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const {
      classes,
      color,
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size,
      block,
      link,
      justIcon,
      className,
      muiClasses,
      dropUp,
      dropPlacement,
      buttonText,
      ref,
      ...rest
    } = this.props;
    const btnClasses = classNames({
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className
    });
    let childrenPortion = null;
    if (children && children.map) {
      childrenPortion = children.map((el, index) => {
        return (<div key={index} className="dropdown-button-menu-item">{ el }</div>);
      });
    } else if (children) {
      childrenPortion = (<div className="dropdown-button-menu-item">{ children }</div>);
    } else {
      childrenPortion = null;
    }
    return (
      <div className="dropdown-button-outer-container">
        <Button
          {...rest}
          ref={ref}
          aria-owns={open ? 'menu-list' : null}
          aria-haspopup="true"
          buttonRef={node => {
            this.anchorEl = node;
          }}
          classes={muiClasses}
          className={btnClasses}
          onClick={this.handleClick}>
          <span className="dropdown-button-label-container">
            { buttonText }
          </span>
          <span className="dropdown-button-img-container">
            <img src={ArrowWhite} className={open ? "img-rotated" : ""} alt="button-img" />
          </span>
        </Button>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className="dropdown-button-menu-outer-container"
        >
          {() => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropUp
                  ? { transformOrigin: '0 100% 0' }
                  : { transformOrigin: '0 0 0' }
              }
            >
              <Paper className="dropdown-button-menu">
                { childrenPortion }
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div></div> 
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

DropdownButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "transparent"
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node
};

export default withStyles(buttonStyle)(DropdownButton);
