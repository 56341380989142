import React from 'react';
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import {orderCostCenterCalculation} from 'store/actions/orders';
import {getCostCenterUnitList} from 'store/actions/providers';

import CostDetails from './CostDetails';
import PositionType from './PositionType';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { formatNumericValue } from 'utils/utils';

import './style.scss';

class Position extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: true,
      specialErrors: null,
    };
  }

  componentDidMount() {
    this.props.getCostCenterUnits();
    this.props.orderCostCenterCalculation({id: this.props.id, data: {cost_centers: this.props.data.cost_centers}});
  }

  onCardClose = () => {
    this.setState({isCardOpen: !this.state.isCardOpen});
  };

  handleDataChange = (id, costCenterData) => {
    const {data, onChange} = this.props;
    const { specialErrors } = this.state;
    const newData = {...data};
    newData.cost_centers[id] = costCenterData;

    if (specialErrors && specialErrors[id]) {
      const newSpecialError = specialErrors[id];
      if (costCenterData.title)
        newSpecialError['title'] = undefined;
      if (costCenterData.amount)
        newSpecialError['amount'] = undefined;
      if (costCenterData.measurement)
        newSpecialError['measurement'] = undefined;
      if (costCenterData.rate)
        newSpecialError['rate'] = undefined;
      this.setState({
        specialErrors: {
          [id]: newSpecialError,
        },
      });
    }

    this.props.orderCostCenterCalculation({id: this.props.id, data: {cost_centers: newData.cost_centers}});
    onChange(newData);
  }

  handleRemoveCostCenter = (id)  => {
    const {data, onChange} = this.props;
    const {specialErrors} = this.state;
    const newCostCenters = {...data}.cost_centers.filter((item, index) => index !== id);
    const newData = {...data};
    newData.cost_centers = newCostCenters;
    if (specialErrors && specialErrors[id]) {
      this.setState({
        specialErrors: null,
      });
    }
    this.props.orderCostCenterCalculation({id: this.props.id, data: {cost_centers: newCostCenters}});
    onChange(newData);
  }

  handleAddNewCostCenter = () => {
    const { data, onChange, t } = this.props;
    const { specialErrors } = this.state;
    let newData = {...data};

    if (newData.cost_centers.length > 0) {
      const lastCostCenter = newData.cost_centers[newData.cost_centers.length - 1];
      const errors = {};
      if (!lastCostCenter.title)
        errors['title'] = [t('input_type')];
      if (!lastCostCenter.amount)
        errors['amount'] = [t('input_amount')];
      if (!lastCostCenter.measurement)
        errors['measurement'] = [t('select_measurement')];
      if (!lastCostCenter.rate)
        errors['rate'] = [t('input_rate')];
      if (Object.keys(errors).length > 0) {
        this.setState({
          specialErrors: {
            [newData.cost_centers.length - 1]: errors,
          },
        });
        return;
      } else {
        if (specialErrors) {
          this.setState({
            specialErrors: null,
          });
        }
      }
    } else if (specialErrors) {
      this.setState({
        specialErrors: null,
      });
    }

    const newCostCenter = {
      title: '',
      amount: '',
      measurement: '',
      rate: '',
      sort_no: newData.cost_centers.length + 1,
      items: []
    }
    newData.cost_centers.push(newCostCenter);
    onChange(newData);
  }

  getErrorsForCostCenter = (index) => {
    const { errors } = this.props;
    const { specialErrors } = this.state;
    if (errors && errors.cost_centers[index])
      return errors.cost_centers[index];
    else if (specialErrors && specialErrors[index])
      return specialErrors[index];
    else
      return null;
  }

  render() {
    const {
      data,
      costs,
      costUnits,
      errors,
      title,
      numericName,
      id,
      modifiable,
      currencySymbol,
      t
    } = this.props;
    const {isCardOpen} = this.state;
    return (
        costUnits.length !== 0 && <div className="cost-position-content">
          <div className={`cost-position-info ${isCardOpen ? 'cost-bottom-borders' : ''}`}>
            <div className="cost-position-info-title">
              <div className="cost-position-title">
                {`${numericName ? numericName : ''} ${title ? title : ''}`}
              </div>
              <div className="total-cost-container">
                <div className="cost-position-total-price">
                  <div className="price">{ t('total_hours') }</div>
                  <div>{`${costs && costs[id] && costs[id].totals ? costs[id].totals.amount : 0}`}</div>
                </div>
                <div className="cost-position-total-price">
                  <div className="price">{ t('total_price') }</div>
                  <div>{`${costs && costs[id] && costs[id].totals ? formatNumericValue(costs[id].totals.value, 2) : 0} ${currencySymbol}`}</div>
                </div>
              </div>
            </div>
            <div className="cost-card-btn-container">
              <div
                className={`card-btn ${isCardOpen ? 'cost-bottom-borders' : ''}`}
                onClick={() => this.onCardClose()}
              >
                {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </div>
            </div>
          </div>
          {
            isCardOpen &&
            <div className="cost-position-info-content">
              <div className="cost-positions">
                {data.cost_centers.length !== 0 &&
                  data.cost_centers.map((type,index) => {
                    return (
                      <PositionType
                        data={type}
                        units={costUnits}
                        onChange={(data) => this.handleDataChange(index, data)}
                        removeCostCenter={() => this.handleRemoveCostCenter(index)}
                        key={index}
                        errors={this.getErrorsForCostCenter(index)}
                        modifiable={modifiable}
                        currencySymbol={currencySymbol}
                      />
                    )
                  })
                }
                <div className="create-cost">
                  <Button
                    className="create-cost-btn"
                    disabled={!modifiable}
                    onClick={this.handleAddNewCostCenter}
                  >
                    + { t('add_main-cost_type').toUpperCase() }
                  </Button>
                </div>
              </div>
              {
                costs && costs[id] && data.cost_centers.length !== 0 ? <CostDetails currencySymbol={currencySymbol} data={costs && costs[id] && costs[id]}/> : <GridItem className="details-container" xs={2}/>
              }
            </div>
          }
        </div>
    )
  }
}

const mapStateToProp = state => {
  return {
    costs: state.orders.orderCostCenterCosts,
    costUnits: state.providers.costCenterUnitList,
    currencySymbol: state.globals.currencySymbol,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    orderCostCenterCalculation: (data) => dispatch(orderCostCenterCalculation(data)),
    getCostCenterUnits: () => dispatch(getCostCenterUnitList())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Position));
