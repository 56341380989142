import React from 'react';
import { connect } from 'react-redux';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ProjectTabs from '../../components/ProjectTabs/ProjectTabs';
import List from './List';

import {
  ManufacturersInquiriesAllTabs
} from 'constants/constants';

import './style.scss';

class ManufacturersInquiriesLists extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 0
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  isDataReady = () => {
    const {
    } = this.props;
    return true;
  }

  onTabItemClick = id => {
    this.setState({
      selectedTabId: id
    });
  };

  render() {
    const {
      searchTerms
    } = this.props;
    const {
      selectedTabId,
    } = this.state;
    let selectedComponent;
    switch(selectedTabId) {
      case 0:
        selectedComponent =
          <List
            searchTerms={searchTerms}
            onSearchReset={this.props.onSearchReset}
            />;
        break;
      case 1:
        selectedComponent =
          <div/>;
        break;
      default:
        return null;
    }
    return (
      <div className="ikt-ap_manufacturers-inquiries-container">
        <GridContainer className="ikt-ap_manufacturer-inquiries-content">
          <GridItem className="ikt-ap_manufacturers-inquiries-top-wrapper" xs={12}>
            <GridContainer className="ikt-ap_manufacturers-inquiries-top-container">
              <GridItem className="ikt-ap_manufacturers-inquiries-top-left">
                <ProjectTabs
                  tabs={ManufacturersInquiriesAllTabs}
                  selectedTab={selectedTabId}
                  onTabItemClick={this.onTabItemClick}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            { selectedComponent }
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(ManufacturersInquiriesLists);
