export const ADD_MANUFACTURER_REQUEST = 'manufacturerEpic/ADD_MANUFACTURER_REQUEST';
export const ADD_MANUFACTURER_REQUEST_SUCCESS = 'manufacturerEpic/ADD_MANUFACTURER_REQUEST_SUCCESS';
export const ADD_MANUFACTURER_REQUEST_ERROR = 'manufacturerEpic/ADD_MANUFACTURER_REQUEST_ERROR';

export const UPDATE_MANUFACTURER_REQUEST = 'manufacturerEpic/UPDATE_MANUFACTURER_REQUEST';
export const UPDATE_MANUFACTURER_REQUEST_SUCCESS = 'manufacturerEpic/UPDATE_MANUFACTURER_REQUEST_SUCCESS';
export const UPDATE_MANUFACTURER_REQUEST_ERROR = 'manufacturerEpic/UPDATE_MANUFACTURER_REQUEST_ERROR';

export const ADD_MANUFACTURER = 'manufacturerEpic/ADD_MANUFACTURER';
export const ADD_MANUFACTURER_SUCCESS = 'manufacturerEpic/ADD_MANUFACTURER_SUCCESS';
export const ADD_MANUFACTURER_ERROR = 'manufacturerEpic/ADD_MANUFACTURER_ERROR';

export const UPDATE_MANUFACTURER = 'manufacturerEpic/UPDATE_MANUFACTURER';
export const UPDATE_MANUFACTURER_SUCCESS = 'manufacturerEpic/UPDATE_MANUFACTURER_SUCCESS';
export const UPDATE_MANUFACTURER_ERROR = 'manufacturerEpic/UPDATE_MANUFACTURER_ERROR';

export const REMOVE_MANUFACTURER = 'manufacturerEpic/REMOVE_MANUFACTURER';
export const REMOVE_MANUFACTURER_SUCCESS = 'manufacturerEpic/REMOVE_MANUFACTURER_SUCCESS';
export const REMOVE_MANUFACTURER_ERROR = 'manufacturerEpic/REMOVE_MANUFACTURER_ERROR';

export const GET_MANUFACTURERS = 'manufacturerEpic/GET_MANUFACTURERS';
export const GET_MANUFACTURERS_SUCCESS = 'manufacturerEpic/GET_MANUFACTURERS_SUCCESS';
export const GET_MANUFACTURERS_ERROR = 'manufacturerEpic/GET_MANUFACTURERS_ERROR';

export const GET_MANUFACTURER = 'manufacturerEpic/GET_MANUFACTURER';
export const GET_MANUFACTURER_SUCCESS = 'manufacturerEpic/GET_MANUFACTURER_SUCCESS';
export const GET_MANUFACTURER_ERROR = 'manufacturerEpic/GET_MANUFACTURER_ERROR';

export const GET_MANUFACTURER_CONTACTS = 'manufacturerEpic/GET_MANUFACTURER_CONTACTS';
export const GET_MANUFACTURER_CONTACTS_SUCCESS = 'manufacturerEpic/GET_MANUFACTURER_CONTACTS_SUCCESS';
export const GET_MANUFACTURER_CONTACTS_FAILURE = 'manufacturerEpic/GET_MANUFACTURER_CONTACTS_FAILURE';

export const GET_MANUFACTURER_SETTINGS = 'manufacturerEpic/GET_MANUFACTURER_SETTINGS';
export const GET_MANUFACTURER_SETTINGS_SUCCESS = 'manufacturerEpic/GET_MANUFACTURER_SETTINGS_SUCCESS';
export const GET_MANUFACTURER_SETTINGS_ERROR = 'manufacturerEpic/GET_MANUFACTURER_SETTINGS_ERROR';

export const SET_MANUFACTURER_STATUS = 'manufacturerEpic/SET_MANUFACTURER_STATUS';

export const FILTER_MANUFACTURERS = 'manufacturerEpic/FILTER_MANUFACTURERS';

export const RESET_MANUFACTURER_DATA = 'manufacturerEpic/RESET_MANUFACTURER_DATA';
