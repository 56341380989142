import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
  TOGGLE_PROJECT_STAR,
  GET_SINGLE_PROJECT,
  GET_SINGLE_PROJECT_SUCCESS,
  GET_SINGLE_PROJECT_ERROR,
  RESET_SINGLE_PROJECT,
  UPDATE_SINGLE_PROJECT
} from 'store/actionTypes/projects';

export const getProjects = () => {
  return { type: GET_PROJECTS };
};

export const getProjectsSuccessful = projects => {
  return { type: GET_PROJECTS_SUCCESS, payload: projects };
};

export const getProjectsFailed = error => {
  return { type: GET_PROJECTS_ERROR, payload: error };
};

export const toggleProjectStar = id => {
  return { type: TOGGLE_PROJECT_STAR, payload: id };
};

export const getSingleProject = id => {
  return (dispatch, getState) => {
    dispatch(resetSingleProject());
    dispatch({ type: GET_SINGLE_PROJECT, payload: id });
  };
};

export const getSingleProjectSuccessful = project => {
  return { type: GET_SINGLE_PROJECT_SUCCESS, payload: project };
};

export const getSingleProjectFailed = error => {
  return { type: GET_SINGLE_PROJECT_ERROR, payload: error };
};

export const resetSingleProject = () => {
  return { type: RESET_SINGLE_PROJECT, payload: null };
};

export const updateSingleProject = project => {
  return { type: UPDATE_SINGLE_PROJECT, payload: project };
};
