import React from 'react';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import DecimalInput from 'components/DecimalInput'
import Input from 'components/Input/Input';

import './style.scss';

class SubMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleInputValueChange = (fieldName, value) => {
    const { data } = this.props;
    this.props.onDataChange({
      ...data,
      settings: {
        ...data.settings,
        [fieldName]: value,
      }
    });
  }

  render() {
    const { data, errors } = this.props;
    const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
    return (
      <GridContainer className="position-info-header submaterial-group">
        <GridItem className="pihi-name">
          <Input
            value={capitalize(data.name)}
            disabled={true}
          />
        </GridItem>
        <GridItem className="pihi-discount">
          <DecimalInput
            value={ data.settings.discount }
            adornment="%"
            maxValue={100}
            onChange={value => this.handleInputValueChange('discount', value)}
            error={errors.discount && errors.discount[0] ? errors.discount[0] : null}
          />
        </GridItem>
        <GridItem className="pihi-overheads">
          <DecimalInput
            value={ data.settings.surcharge }
            adornment="%"
            maxValue={100}
            onChange={value => this.handleInputValueChange('surcharge', value)}
            error={errors.surcharge && errors.surcharge[0] ? errors.surcharge[0] : null}
          />
        </GridItem>
        <GridItem className="pihi-waste">
          <DecimalInput
            value={ data.settings.waste }
            adornment="%"
            maxValue={100}
            onChange={value => this.handleInputValueChange('waste', value)}
            error={errors.waste && errors.waste[0] ? errors.waste[0] : null}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

export default SubMaterial;
