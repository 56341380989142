import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import Button from "components/CustomButtons/Button.jsx";
import CreateRole from "../CreateRole";
import Dropdown from "components/CustomDropdown/CustomDropdown.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Loader from "../../components/Loader/Loader";
import ViewContact from "../ViewContact";

import Add from "@material-ui/icons/Add";
import CloseButton from "../../assets/img/buttons/delete.svg";
import EditButton from "../../assets/img/buttons/edit.svg";
import IconButton from "@material-ui/core/IconButton";
import ViewButton from "../../assets/img/buttons/eye.svg";

import './style.scss';
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import withStyles from "@material-ui/core/styles/withStyles";

const roles = [
  {userId: 'Super Admin', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'},
  {userId: 'Admin', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'},
  {userId: 'Supplier', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'},
  {userId: 'Manufacturer', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'},
  {userId: 'Co-worker', firstName: '', lastName: '', email: '', phone: '', role: '', status: 'Active'}
];

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateRoleOpen: false,
      isViewRoleOpen: false
    };
  }

  isRolesDataReady = () => {
    /*const {
      users
    } = this.props;*/
    return (
      roles &&
      roles.length !== 0
    );
  }

  handleToggleRoleChange = () => {
    this.setState({isCreateRoleOpen: !this.state.isCreateRoleOpen})
  };

  handleToggleViewRole = () => {
    this.setState({isViewRoleOpen: !this.state.isViewRoleOpen})
  };

  renderRole = (role, index) => {
    return(
      <GridContainer
        className={`roles-item ${ index % 2 === 0 ? 'dark' : '' }`}
        direction="row"
        xs={12}
      >
        <GridItem className="role-item-title bold" xs={2}>{role.userId}</GridItem>
        <GridItem className="role-item-title" xs={1}>{role.firstName}</GridItem>
        <GridItem className="role-item-title" xs={1}>{role.lastName}</GridItem>
        <GridItem className="role-item-title" xs={2}>{role.email}</GridItem>
        <GridItem className="role-item-title" xs={1}>{role.phone}</GridItem>
        <GridItem className="role-item-title" xs={2}>{role.role}</GridItem>
        <GridItem className="role-item-title status" xs={1}>
          <Dropdown
            buttonText={''}
            buttonIconType={"white"}
            buttonProps={{className: `dropdown-blue-style stock-dropdown`, round: true, size: "sm"}}
            dropdownList={[{id: 0, value: "Active"}, {id: 1, value:"Inactive"}]}
            hoverColor="dark"
          />
        </GridItem>
        <GridItem className="role-item-title" xs={2}>
          <IconButton aria-label="close" className="action-btn">
            <img alt="role-delete" src={CloseButton}/>
          </IconButton>
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleToggleViewRole()}>
            <img alt="role-view" src={ViewButton}/>
          </IconButton>
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleToggleRoleChange()}>
            <img alt="role-edit" src={EditButton}/>
          </IconButton>
        </GridItem>
      </GridContainer>
    )
  };

  render() {
    const { isCreateRoleOpen, isViewRoleOpen } = this.state;
    return (
      <div className="ikt-ap_roles-container">
        {this.isRolesDataReady() ? (
          <GridContainer>
            <GridItem className="roles-dropdown-content" direction="row" justify="flex-end" xs={12}>
              <Dropdown
                buttonText={''}
                buttonProps={{className: 'role-dropdown-style', round: true, size: "sm"}}
                dropdownList={[{id: 0, value: 'Test'}]}
              />
              <Button  onClick={this.handleToggleRoleChange} size="sm" className="create-role-btn">
                <Add /> CREATE
              </Button>
            </GridItem>
            <GridContainer className="roles-header" direction="row" xs={12}>
              <GridItem className="roles-header-title" xs={2}>User ID</GridItem>
              <GridItem className="roles-header-title" xs={1}>First Name</GridItem>
              <GridItem className="roles-header-title" xs={1}>Last Name</GridItem>
              <GridItem className="roles-header-title" xs={2}>Email</GridItem>
              <GridItem className="roles-header-title" xs={1}>Phone</GridItem>
              <GridItem className="roles-header-title" xs={2}>Role</GridItem>
              <GridItem className="roles-header-title" xs={1}>Status</GridItem>
              <GridItem className="roles-header-title" xs={2}>Actions</GridItem>
            </GridContainer>
            <GridContainer className="roles-content">
              {roles && roles.map((role, index) => this.renderRole(role, index))}
            </GridContainer>
          </GridContainer>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
        <CreateRole isOpen={isCreateRoleOpen} onClose={this.handleToggleRoleChange} />
        <ViewContact isOpen={isViewRoleOpen} onClose={this.handleToggleViewRole} />
      </div>
    );
  }
}

Roles.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Roles));
