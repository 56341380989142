import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { mapTo, catchError, delay } from 'rxjs/operators';
import {
  getMaterialsSuccess,
  getMaterialsFailure
} from 'store/actions/materials';
import { GET_MATERIALS } from 'store/actionTypes/materials';
import {
  bodyMaterials,
  skeletonMaterials,
  handlesMaterials
} from './epicsData';

const dummyMaterials = {
  bodyMaterials,
  skeletonMaterials,
  handlesMaterials
};

export const getMaterials = actions$ => {
  return actions$.pipe(
    ofType(GET_MATERIALS),
    delay(2000),
    mapTo(getMaterialsSuccess(dummyMaterials)),
    catchError((error, caught) => merge(of(getMaterialsFailure(error)), caught))
  );
};
