import { handleActions } from 'redux-actions';
import {
  GET_REQUESTS_LIST,
  GET_REQUESTS_LIST_SUCCESS,
  GET_REQUESTS_LIST_ERROR,
  APPROVE_REQUEST,
  APPROVE_REQUEST_SUCCESS,
  APPROVE_REQUEST_ERROR,
  REJECT_REQUEST,
  REJECT_REQUEST_SUCCESS,
  REJECT_REQUEST_ERROR
} from '../actionTypes/requestsActionTypes';

export const DEFAULT_REQUESTS_STATE = {
  requests: [],
  requestsMetaData: {},
  requestsErrors: {},
  requestsBusy: false
};

export const requests = handleActions(
  {
    [GET_REQUESTS_LIST]: (state, action) => ({
      ...state,
      requestsBusy: true
    }),
    [GET_REQUESTS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      requests: action.payload.data,
      requestsMetaData: action.payload,
      requestsBusy: false
    }),
    [GET_REQUESTS_LIST_ERROR]: (state, action) => ({
      ...state,
      requestsErrors: action.payload.message,
      requestsBusy: false
    })
  },
  DEFAULT_REQUESTS_STATE
);
