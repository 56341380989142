export const GET_DEFAULT_COST_CENTER_LIST = 'settingsEpic/GET_DEFAULT_COST_CENTER_LIST';
export const GET_DEFAULT_COST_CENTER_LIST_SUCCESS = 'settingsEpic/GET_DEFAULT_COST_CENTER_LIST_SUCCESS';
export const UPDATE_DEFAULT_COST_CENTERS = 'settingsEpic/UPDATE_DEFAULT_COST_CENTERS';
export const DEFAULT_COST_CENTER_LIST_ERROR = 'settingsEpic/DEFAULT_COST_CENTER_LIST_ERROR';

export const GET_MATERIALS_SETTINGS = 'settingsEpic/GET_MATERIALS_SETTINGS';
export const GET_MATERIALS_SETTINGS_SUCCESS = 'settingsEpic/GET_MATERIALS_SETTINGS_SUCCESS';
export const GET_MATERIALS_SETTINGS_ERROR = 'settingsEpic/GET_MATERIALS_SETTINGS_ERROR';
export const UPDATE_MATERIALS_SETTINGS = 'settingsEpic/UPDATE_MATERIALS_SETTINGS';

export const GET_MATERIAL_LIST_ALLOWANCE = 'settingsEpic/GET_MATERIAL_LIST_ALLOWANCE';
export const GET_MATERIAL_LIST_ALLOWANCE_SUCCESS = 'settingsEpic/GET_MATERIAL_LIST_ALLOWANCE_SUCCESS';
export const GET_MATERIAL_LIST_ALLOWANCE_ERROR = 'settingsEpic/GET_MATERIAL_LIST_ALLOWANCE_ERROR';

export const UPDATE_MATERIAL_LIST_ALLOWANCE = 'settingsEpic/UPDATE_MATERIAL_LIST_ALLOWANCE';
export const UPDATE_MATERIAL_LIST_ALLOWANCE_SUCCESS = 'settingsEpic/UPDATE_MATERIAL_LIST_ALLOWANCE_SUCCESS';
export const UPDATE_MATERIAL_LIST_ALLOWANCE_ERROR = 'settingsEpic/UPDATE_MATERIAL_LIST_ALLOWANCE_ERROR';

export const GET_MATERIAL_LIST_ORIENTATION_DATA = 'settingsEpic/GET_MATERIAL_LIST_ORIENTATION_DATA';
export const GET_MATERIAL_LIST_ORIENTATION_DATA_SUCCESS = 'settingsEpic/GET_MATERIAL_LIST_ORIENTATION_DATA_SUCCESS';
export const GET_MATERIAL_LIST_ORIENTATION_DATA_ERROR = 'settingsEpic/GET_MATERIAL_LIST_ORIENTATION_DATA_ERROR';

export const ADD_MATERIAL_LIST_ORIENTATION_CLASS = 'settingsEpic/ADD_MATERIAL_LIST_ORIENTATION_CLASS';
export const ADD_MATERIAL_LIST_ORIENTATION_CLASS_SUCCESS = 'settingsEpic/ADD_MATERIAL_LIST_ORIENTATION_CLASS_SUCCESS';
export const ADD_MATERIAL_LIST_ORIENTATION_CLASS_ERROR = 'settingsEpic/ADD_MATERIAL_LIST_ORIENTATION_CLASS_ERROR';

export const REMOVE_MATERIAL_LIST_ORIENTATION_CLASS = 'settingsEpic/REMOVE_MATERIAL_LIST_ORIENTATION_CLASS';
export const REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_SUCCESS = 'settingsEpic/REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_SUCCESS';
export const REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_ERROR = 'settingsEpic/REMOVE_MATERIAL_LIST_ORIENTATION_CLASS_ERROR';

export const GET_WEB_SHOP_SETTINGS = 'settingsEpic/GET_WEB_SHOP_SETTINGS';
export const GET_WEB_SHOP_SETTINGS_SUCCESS = 'settingsEpic/GET_WEB_SHOP_SETTINGS_SUCCESS';
export const GET_WEB_SHOP_SETTINGS_ERROR = 'settingsEpic/GET_WEB_SHOP_SETTINGS_ERROR';
export const UPDATE_WEB_SHOP_SETTINGS = 'settingsEpic/UPDATE_WEB_SHOP_SETTINGS';

export const GET_PROJECTS_SETTINGS = 'settingsEpic/GET_PROJECTS_SETTINGS';
export const GET_PROJECTS_SETTINGS_SUCCESS = 'settingsEpic/GET_PROJECTS_SETTINGS_SUCCESS';
export const GET_PROJECTS_SETTINGS_ERROR = 'settingsEpic/GET_PROJECTS_SETTINGS_ERROR';
export const UPDATE_PROJECTS_SETTINGS = 'settingsEpic/UPDATE_PROJECTS_SETTINGS';

export const GET_LETTERHEADS = 'settingsEpic/GET_LETTERHEADS';
export const GET_LETTERHEADS_SUCCESS = 'settingsEpic/GET_LETTERHEADS_SUCCESS';
export const GET_LETTERHEADS_ERROR = 'settingsEpic/GET_LETTERHEADS_ERROR';
export const UPDATE_LETTERHEADS = 'settingsEpic/UPDATE_LETTERHEADS';
export const UPDATE_LETTERHEADS_SUCCESS = 'settingsEpic/UPDATE_LETTERHEADS_SUCCESS';
export const UPDATE_LETTERHEADS_ERROR = 'settingsEpic/UPDATE_LETTERHEADS_ERROR';
export const REMOVE_LETTERHEADS = 'settingsEpic/REMOVE_LETTERHEADS';
export const REMOVE_LETTERHEAD_ENTRY_SUCCESS = 'settingsEpic/REMOVE_LETTERHEAD_ENTRY_SUCCESS';
export const REMOVE_LETTERHEAD_ENTRY_ERROR = 'settingsEpic/REMOVE_LETTERHEAD_ENTRY_ERROR';
export const REMOVE_LETTERHEADS_FINISH = 'settingsEpic/REMOVE_LETTERHEADS_FINISH';

export const RESET_SETTINGS_ERRORS = 'settingsEpic/RESET_SETTINGS_ERRORS';
