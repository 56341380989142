import React from "react";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import {getCustomer} from "../../store/actions/customers";

import CustomAutocomplete from "../../components/CustomAutocomplete";
import Autocomplete from "../../components/Autocomplete";
import Button from '../../components/CustomButtons/Button';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';

import { getMainMaterialsList, getEdgeMaterialsList, getCoveringMaterialsList, getSurfaceMaterialsList } from "../../store/actions/materialProviders";

import './style.scss';

class ImportCustomerDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_id: '',
      missed_materials: [],
    }
  }

  componentDidMount() {
    this.props.getMainMaterialsList();
    this.props.getEdgeMaterialsList();
    this.props.getCoveringMaterialsList();
    this.props.getSurfaceMaterialsList();

    const customer_id = this.props.orderPositionImportedFileData.data.order.customer_id ? this.props.orderPositionImportedFileData.data.order.customer_id : this.props.orderCustomerId;
    this.setState({
      customer_id,
      missed_materials: this.props.orderPositionImportedFileData ? this.props.orderPositionImportedFileData.data.missed_materials.map(mm => ({ type: mm.type, label: mm.code, id: null })) : [],
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.orderPositionImportedFileData && this.props.orderPositionImportedFileData != prevProps.orderPositionImportedFileData) {
      this.setState({
        missed_materials: this.props.orderPositionImportedFileData.data.missed_materials.map(mm => ({ type: mm.type, label: mm.code, id: null })),
      });
    }
  }

  handleCustomerChange = (id) => {
    this.setState({
      customer_id: id
    })
  }

  handleSkipButtonClick = () => {
    const {
      onAddImportedPosition,
      onClose
    } = this.props;
    onAddImportedPosition();
    onClose();
  }

  handleSetCustomer = () => {
    const {
      onAddImportedPosition,
      getCustomer,
      onCustomerChange,
      onClose,
      includeCustomer
    } = this.props;
    const {customer_id, missed_materials} =  this.state;
    if (includeCustomer) {
      getCustomer(customer_id);
      onCustomerChange(customer_id);
    }
    onAddImportedPosition(missed_materials);
    onClose();
  }

  handleMissedMaterialChange = (index, id) => {
    const { missed_materials } = this.state;
    const new_missed_materials = [...missed_materials];
    new_missed_materials[index].id = id;
    this.setState({
      missed_materials: new_missed_materials,
    });
  }

  performGetData = (missed_material, value) => {
    const { getMainMaterialsList, getEdgeMaterialsList, getCoveringMaterialsList, getSurfaceMaterialsList } = this.props;
    if (missed_material.type == "main_material") {
      getMainMaterialsList(value);
    } else if (missed_material.type == "edge_material") {
      getEdgeMaterialsList(value);
    } else if (missed_material.type == "covering_material") {
      getCoveringMaterialsList(value);
    } else if (missed_material.type == "surface_material") {
      getSurfaceMaterialsList(value);
    }
  }

  resolveMaterialsList = (missed_material) => {
    const { mainMaterials, edgeMaterials, coveringMaterials, surfaceMaterials } = this.props;
    let baseList = null;
    if (missed_material.type == "main_material") {
      baseList = mainMaterials;
    } else if (missed_material.type == "edge_material") {
      baseList = edgeMaterials;
    } else if (missed_material.type == "covering_material") {
      baseList = coveringMaterials;
    } else if (missed_material.type == "surface_material") {
      baseList = surfaceMaterials;
    } else {
      baseList = [];
    }
    return baseList.map(m => ({id: m.id, value: m.code, name: m.name, code: m.code}));
  }

  render() {
    const {
      isOpen,
      onClose,
      customers,
      includeCustomer,
      orderPositionImportedFileData,
      onCreateCustomerOpen,
      t
    } = this.props;
    const {customer_id, missed_materials} = this.state;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="import-customer-selection-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{ t('file_import') }</Typography>
        </MuiDialogTitle>
        { includeCustomer &&
          <div className={"import-customer-selection-content" + ( missed_materials.length == 0 ? " import-customer-selection-content-min-height" : "")}>
            <div className="content-text">
              { t('which_customer_link_import') }
            </div>
            <Autocomplete
              buttonText={customer_id}
              buttonProps={{className: '', round: true, size: "sm"}}
              dropdownList={customers}
              hoverColor="dark"
              onClick={
                value => this.handleCustomerChange(value.id)
              }
            />
            {orderPositionImportedFileData.data.order.customer_id === null &&
              <Button
                className="add-customer-btn"
                onClick={onCreateCustomerOpen}
              >
                { t('add_new_customer').toUpperCase() }
              </Button>
            }
          </div>
        }
        { missed_materials.length > 0 && <div className="missed-materials-mapping-content">
          <div className="content-text">
            { t('material_names_not_recognized') }
          </div>
          <div className="content-mapping-container">
          {
            missed_materials.map((mm, index) => (
              <div className="content-mapping-group" key={index}>
                <span className="missed-material-label">{mm.label}</span>
                <CustomAutocomplete
                  buttonText={mm.id ? mm.id : ''}
                  buttonProps={{className: '', round: true, size: "sm"}}
                  discardIcon={true}
                  placeholder={ t('please_select') + '...' }
                  className={'missed-material-selector'}
                  getData={(value) => this.performGetData(mm, value)}
                  dropdownList={ this.resolveMaterialsList(mm) }
                  hoverColor="dark"
                  onClick={(value) => this.handleMissedMaterialChange(index, value.id)}
                />
              </div>
            ))
          }
          </div>
        </div>
        }
        <div className="import-customer-selection-btn-container">
          <Button
            className="cancel-btn"
            onClick={this.handleSkipButtonClick}
          >
            { t('skip').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleSetCustomer}
          >
            { t('save').toUpperCase() }
          </Button>
        </div>
      </Dialog>
    );
  }
}


const mapStateToProp = state => {
  return {
    customers: state.providers.customersList,
    orderPositionImportedFileData: state.orders.orderPositionImportedFileData,
    mainMaterials: state.materialProviders.mainMaterials,
    edgeMaterials: state.materialProviders.edgeMaterials,
    coveringMaterials: state.materialProviders.coveringMaterials,
    surfaceMaterials: state.materialProviders.surfaceMaterials,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomer: (id) => dispatch(getCustomer(id)),
    getMainMaterialsList: () => dispatch(getMainMaterialsList()),
    getEdgeMaterialsList: () => dispatch(getEdgeMaterialsList()),
    getCoveringMaterialsList: () => dispatch(getCoveringMaterialsList()),
    getSurfaceMaterialsList: () => dispatch(getSurfaceMaterialsList()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ImportCustomerDialog));
