/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx';

import { getMetrics, getCurrency } from "../store/actions/globals";
import { getPersistedSidebarState, persistSidebarState } from '../utils/utils';
import { getOwnContactsCategories } from "../store/actions/categories";
import { getUserOwn, getProfileDetails } from "../store/actions/auth";
import { isUserAuthorized } from 'utils/utils';

import Timer from '../views/Timer/Timer';
import CloseButton from "../assets/img/buttons/delete.svg";
import IconButton from "@material-ui/core/IconButton";

var ps;

const miniActiveWinWidthThreshold = 768;

class Dashboard extends React.Component {
  historyListener;
  mainPanel = React.createRef();

  constructor() {
    super();

    let sidebarMiniActive;
    if (window.innerWidth >= miniActiveWinWidthThreshold) {
      const sidebarState = getPersistedSidebarState();
      if (sidebarState && Object.keys(sidebarState).indexOf('open') != -1) {
        sidebarMiniActive = !sidebarState.open;
      } else {
        sidebarMiniActive = false;
      }
    } else {
      sidebarMiniActive = true;
    }

    this.state = {
      mobileOpen: false,
      miniActive: sidebarMiniActive,
      isSmUp: window.innerWidth >= miniActiveWinWidthThreshold,
      image: require('assets/img/sidebar-2.jpg'),
      color: 'blue',
      bgColor: 'white',
      hasImage: true,
      fixedClasses: 'dropdown',
      logo: require('assets/img/logo-white.svg'),
      searchTerms: null,
      resetSearch: false,
      isTimerOpen: false,
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1 && this.mainPanel.current) {
      ps = new PerfectScrollbar(this.mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);
    this.historyUnlistener = this.props.history.listen((location, action) => {
      this.handleOnSearchReset();
    });
    if (this.props.auth.isAuthorized) {
      this.props.getMetrics();
      this.props.getCurrency();
      this.props.getOwnUserData();
      this.props.getProfileDetails();
      this.props.getOwnContactsCategories(true);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1 && this.mainPanel.current) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
    if (this.historyListener)
      this.historyUnlistener();
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.mainPanel.current) {
        this.mainPanel.current.scrollTop = 0;
      }
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };

  handleColorClick = color => {
    this.setState({ color: color });
  };

  handleBgColorClick = bgColor => {
    switch (bgColor) {
      case 'white':
        this.setState({ logo: require('assets/img/logo.svg') });
        break;
      default:
        this.setState({ logo: require('assets/img/logo-white.svg') });
        break;
    }
    this.setState({ bgColor: bgColor });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show' });
    } else {
      this.setState({ fixedClasses: 'dropdown' });
    }
  };

  handleSearchPerformed = terms => {
    this.setState({ resetSearch: false, searchTerms: terms && terms.length > 0 ? terms : null });
  };

  handleOnSearchReset = () => {
    this.setState({ resetSearch: true, searchTerms: null });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };

  getActiveRoute = routes => {
    let activeRoute = 'orders';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        let route = `${routes[i].layout}${routes[i].path}${routes[i].id ? `/${routes[i].id}` : ''}$`;
        let routeMatcher = new RegExp(route.replace(/:[^\s/]+/g, '([\\w-_]+)'));
        let url = window.location.href;

        if (routeMatcher.test(url)) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        const isUserAllowed = !prop.auth || isUserAuthorized(prop.auth);
        const routePath = `${prop.layout}${prop.id ? `${prop.path}/:id` : prop.path}`;
        if (isUserAllowed) {
          return (
            <Route
              exact
              path={routePath}
              render={props => <prop.component {...props} searchTerms={this.state.searchTerms} onSearchReset={this.handleOnSearchReset} />}
              key={key}
            />
          );
        } else {
          return (<Redirect from={routePath} to={`/`} />);
        }
      } else {
        return null;
      }
    });
  };

  sidebarMinimize = () => {
    let sidebarState = getPersistedSidebarState();
    if (!sidebarState) {
      sidebarState = {};
    }
    sidebarState.open = this.state.miniActive;
    persistSidebarState(sidebarState);
    this.setState({ miniActive: !this.state.miniActive });
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }

    if (window.innerWidth >= miniActiveWinWidthThreshold) {
      if (!this.state.isSmUp) {
        let sidebarState = getPersistedSidebarState();
        this.setState({ isSmUp: true, miniActive: !Boolean(sidebarState.open) });
      }
    }else{
      if (this.state.isSmUp) {
        this.setState({ isSmUp: false, miniActive: true });
      }
    }
  };

  handleTimerClose = () => {
    this.setState({
      isTimerOpen: false
    });
  };

  handleTimerOpen = () => {
    this.setState({
      isTimerOpen: true
    });
  };

  render() {
    const { classes, auth, routes, location, t, ...rest } = this.props;
    const { isTimerOpen } = this.state;
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1
      });
    return (
      <>
        {auth.isAuthorized ? (
          <div className={classes.wrapper}>
            <Sidebar
              routes={routes}
              logoText={'IKT'}
              logo={this.state.logo}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color={this.state.color}
              bgColor={this.state.bgColor}
              miniActive={this.state.miniActive}
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              {...rest}
            />
            <div className={mainPanel} id='ikt-admin-mainPanel' ref={this.mainPanel}>
              <AdminNavbar
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                brandText={this.getActiveRoute(routes)}
                handleDrawerToggle={this.handleDrawerToggle}
                searchFunc={this.handleSearchPerformed}
                resetSearch={this.state.resetSearch}
                isTimerOpen={isTimerOpen}
                handleTimerOpen={this.handleTimerOpen}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                auth.isOwnUserDataReceived && (
                  <div className={classes.content}>
                    <div className={classes.container}>
                      <Switch>
                        {this.getRoutes(routes)}
                        <Redirect from="/admin" to="/admin/orders" exact={true} />
                      </Switch>
                    </div>
                  </div>
                )
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/orders" exact={true} />
                  </Switch>
                </div>
              )}
             {/* {this.getRoute() ? <Footer fluid /> : null}*/}
              {/*<FixedPlugin
                handleImageClick={this.handleImageClick}
                handleColorClick={this.handleColorClick}
                handleBgColorClick={this.handleBgColorClick}
                handleHasImage={this.handleHasImage}
                color={this.state['color']}
                bgColor={this.state['bgColor']}
                bgImage={this.state['image']}
                handleFixedClick={this.handleFixedClick}
                fixedClasses={this.state.fixedClasses}
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
              />*/}
            </div>
            <Timer onTimerClose={this.handleTimerClose} isTimerOpen={isTimerOpen} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/auth-ap/login-page',
              state: { from: location }
            }}
          />
        )}
      </>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    auth: state.auth,
    routes: state.routes.routes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOwnUserData: () => dispatch(getUserOwn()),
    getProfileDetails: () => dispatch(getProfileDetails()),
    getMetrics: () => dispatch(getMetrics()),
    getCurrency: () => dispatch(getCurrency()),
    getOwnContactsCategories: (rootOnly) => dispatch(getOwnContactsCategories(rootOnly))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(appStyle)(withRouter(withTranslation()(Dashboard))));
