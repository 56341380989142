import {config} from 'Config';

class AuthAPIService {
  login = async ({ email = '', password = '' }) => {
    let body = new FormData();
    body.append('email', email);
    body.append('password', password);
    let headers = {'Accept': 'application/json'};
    const fetchResult = await fetch(`${config.apiBaseUrl}api/auth/login`, {
      method: 'post',
      headers,
      body
    });

    const authResponse = await fetchResult.json();
    return {
      ok: fetchResult.ok,
      status: fetchResult.status,
      response: authResponse
    };
  };

  logout = async () => {
    const token = localStorage.getItem('authToken');

    let headers = new Headers({
      Authorization: `Bearer ${token}`
    });

    const fetchResult = await fetch(`${config.apiBaseUrl}api/auth/logout`, {
      method: 'post',
      headers,
      mode: 'cors'
    });

    if (!fetchResult.ok) {
      console.error(
        `Bad response from server.  ${fetchResult.statusText} (${fetchResult.status})`
      );
    }
    return true;
  };
}

export default new AuthAPIService()
