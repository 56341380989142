import {ofType} from 'redux-observable';
import {from, of, concat, merge} from 'rxjs';
import {switchMap, mergeMap, catchError, endWith} from 'rxjs/operators';
import {adminTimeSheetServiceApi} from 'services/API';
import NotificationService from '../services/NotificationService';
import {
  filterAdminTimeSheets,
  getAdminTimeEntriesSuccess,
  getAdminTimeEntriesFailure,
  getAdminTimeEntrySuccess,
  getAdminTimeEntryFailure,
  createAdminTimeEntrySuccess,
  createAdminTimeEntryFailure,
  createAdminTimeEntryFinish,
  updateAdminTimeEntrySuccess,
  updateAdminTimeEntryFailure,
  deleteAdminTimeEntrySuccess,
  deleteAdminTimeEntryFailure
} from 'store/actions/adminTimeSheet';
import {
  GET_ADMIN_TIME_ENTRIES,
  GET_ADMIN_TIME_ENTRY,
  CREATE_ADMIN_TIME_ENTRY,
  CREATE_ADMIN_TIME_ENTRY_FINISH,
  UPDATE_ADMIN_TIME_ENTRY,
  DELETE_ADMIN_TIME_ENTRY,
  FILTER_ADMIN_TIME_SHEETS
} from 'store/actionTypes/adminTimeSheetActionTypes';

export const getAdminTimeEntriesEpic = (actions$) => actions$.pipe(
  ofType(GET_ADMIN_TIME_ENTRIES),
  switchMap(() => from(adminTimeSheetServiceApi.getAdminTimeEntries()).pipe(
    switchMap((response) => {
      return of(getAdminTimeEntriesSuccess(response))
    }),
    catchError( err => {
        return of(getAdminTimeEntriesFailure(err))
      }
    )
  ))
);

export const getAdminTimeEntryEpic = (actions$) => actions$.pipe(
  ofType(GET_ADMIN_TIME_ENTRY),
  switchMap((action) => from(adminTimeSheetServiceApi.getAdminTimeEntry(action.payload)).pipe(
    switchMap((response) => {
      return of(getAdminTimeEntrySuccess(response.data))
    }),
    catchError( err => {
        return of(getAdminTimeEntryFailure(err))
      }
    )
  ))
);

export const addAdminTimeEntryEpic = (actions$) => actions$.pipe(
  ofType(CREATE_ADMIN_TIME_ENTRY),
  mergeMap(({payload}) => {
    const observables = payload.entries.map(el => from(adminTimeSheetServiceApi.addNewAdminTimeEntry(el)).pipe(
      switchMap((response) => {
        NotificationService.success("Time entry created successfully.");
        return of(createAdminTimeEntrySuccess(el.uid, response));
      }),
      catchError(err => {
        return of(createAdminTimeEntryFailure(el.uid, err));
      })
    ));
    return merge(
      ...observables
    ).pipe(endWith(createAdminTimeEntryFinish(payload.filters, payload.sort)));
  }
));

export const createAdminTimeEntryFinishEpic = (actions$) => actions$.pipe(
  ofType(CREATE_ADMIN_TIME_ENTRY_FINISH),
  switchMap(({payload}) => of(filterAdminTimeSheets(payload.filters, payload.sort, payload.sortOrder)))
);

export const updateAdminTimeEntryEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_ADMIN_TIME_ENTRY),
  switchMap(({payload}) => from(adminTimeSheetServiceApi.updateAdminTimeEntry(payload.id, payload.data)).pipe(
    switchMap((response) => {
      NotificationService.success("Time entry edited successfully.");
      return concat(
        of(filterAdminTimeSheets(payload.filters, payload.sort, payload.sortOrder)),
        of(updateAdminTimeEntrySuccess(payload.id, response)),
      );
    }),
    catchError( err => {
        return of(updateAdminTimeEntryFailure(payload.id, err))
      }
    )
  ))
);

export const removeAdminTimeEntryEpic = (actions$) => actions$.pipe(
  ofType(DELETE_ADMIN_TIME_ENTRY),
  switchMap(({payload}) => from(adminTimeSheetServiceApi.deleteAdminTimeEntry(payload.id)).pipe(
    switchMap((response) => {
      NotificationService.success("Time entry deleted successfully.");
      return concat(
        of(filterAdminTimeSheets(payload.filters, payload.sort, payload.sortOrder)),
        of(deleteAdminTimeEntrySuccess(response))
      );
    }),
    catchError( err => {
        return of(deleteAdminTimeEntryFailure(err))
      }
    )
  ))
);

export const filterAdminTimeSheetsEpic = (actions$) => actions$.pipe(
  ofType(FILTER_ADMIN_TIME_SHEETS),
  switchMap((action) => from(adminTimeSheetServiceApi.filterTimeSheets(action.payload.filters, action.payload.sort, action.payload.sortOrder)).pipe(
    switchMap((response) => {
      return of(getAdminTimeEntriesSuccess(response))
    }),
    catchError( err => {
        return of(getAdminTimeEntriesFailure(err))
      }
    )
  ))
);
