import BaseAPIService from './Base';

export default class ManufacturerAvailabilityAPIService extends BaseAPIService {
  updateDateAvailability = async (day, data) => {
    return this.request(`api/manufacturers/${day}/schedule/${day}/availability`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  deleteDateAvailability = async (day) => {
    return this.request(`api/manufacturers/${day}/schedule/${day}/availability`, {
      method: 'DELETE'
    });
  };
}
