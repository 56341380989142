import {
    GET_PROJECT_STATUSES,
    GET_PROJECT_STATUSES_SUCCESS,
    GET_PROJECT_STATUSES_ERROR
} from 'store/actionTypes/projectStatuses';

export const getProjectStatuses = () => {
    return {type: GET_PROJECT_STATUSES}
};

export const getProjectStatusesSuccessful = (projectStauses) => {
    return {type: GET_PROJECT_STATUSES_SUCCESS, payload: projectStauses}
};

export const getProjectStatusesFailed = (error) => {
    return {type: GET_PROJECT_STATUSES_ERROR, payload: error}
};
