import React from 'react';

import Popover from '@material-ui/core/Popover';

import { Editor, EditorState, RichUtils, SelectionState } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import IconButton from "@material-ui/core/IconButton";

import EditorIconTextStyle from '../../assets/img/components/rteditor/editor-icon-text-style.svg';
import EditorIconTextStyleActive from '../../assets/img/components/rteditor/editor-icon-text-style-active.svg';
import EditorIconBold from '../../assets/img/components/rteditor/editor-icon-bold.svg';
import EditorIconBoldActive from '../../assets/img/components/rteditor/editor-icon-bold-active.svg';
import EditorIconItalic from '../../assets/img/components/rteditor/editor-icon-italic.svg';
import EditorIconItalicActive from '../../assets/img/components/rteditor/editor-icon-italic-active.svg';
import EditorIconTextMore from '../../assets/img/components/rteditor/editor-icon-text-more.svg';
import EditorIconTextMoreActive from '../../assets/img/components/rteditor/editor-icon-text-more-active.svg';
import EditorIconUnorderedList from '../../assets/img/components/rteditor/editor-icon-unordered-list.svg';
import EditorIconUnorderedListActive from '../../assets/img/components/rteditor/editor-icon-unordered-list-active.svg';
import EditorIconOrderedList from '../../assets/img/components/rteditor/editor-icon-ordered-list.svg';
import EditorIconOrderedListActive from '../../assets/img/components/rteditor/editor-icon-ordered-list-active.svg';
/*import EditorIconImage from '../../assets/img/components/rteditor/editor-icon-image.png';
import EditorIconTable from '../../assets/img/components/rteditor/editor-icon-table.png';*/

import { ToolbarButtonType, TextDecorations, BlockTypes } from './RTEditorEnums.jsx';

import { ErrorTooltip } from '../ErrorTooltip/ErrorTooltip';

import 'draft-js/dist/Draft.css';
import './styles.scss';

class RTEditor extends React.Component {

  static ToolbarButtons = [
    [
      { type: ToolbarButtonType.TextStyle, alt: 'text-style', icon: EditorIconTextStyle, iconActive: EditorIconTextStyleActive, children: [
        { type: ToolbarButtonType.Heading1, className: 'heading-1-option', text: 'Heading 1' },
        { type: ToolbarButtonType.Heading2, className: 'heading-2-option', text: 'Heading 2' },
        { type: ToolbarButtonType.Heading3, className: 'heading-3-option', text: 'Heading 3' },
        { type: ToolbarButtonType.Heading4, className: 'heading-4-option', text: 'Heading 4' },
      ] },
    ], [
      { type: ToolbarButtonType.Bold, alt: 'bold', icon: EditorIconBold, iconActive: EditorIconBoldActive },
      { type: ToolbarButtonType.Italic, alt: 'italic', icon: EditorIconItalic, iconActive: EditorIconItalicActive },
      { type: ToolbarButtonType.TextMore, alt: 'text-more', icon: EditorIconTextMore, iconActive: EditorIconTextMoreActive, children: [
        { type: ToolbarButtonType.Underline, className: 'underline-option', text: 'Underline' },
        { type: ToolbarButtonType.Strikethrough, className: 'strikethrough-option', text: 'Strikethrough' },
        { type: ToolbarButtonType.Superscript, className: 'superscript-option', text: 'Superscript' },
        { type: ToolbarButtonType.Subscript, className: 'subscript-option', text: 'Subscript' },
      ] },
    ], [
      { type: ToolbarButtonType.BulletList, alt: 'bullet-list', icon: EditorIconUnorderedList, iconActive: EditorIconUnorderedListActive },
      { type: ToolbarButtonType.NumberedList, alt: 'numbered-list', icon: EditorIconOrderedList, iconActive: EditorIconOrderedListActive },
    ]/*, [
      { type: ToolbarButtonType.Image, alt: 'image', icon: EditorIconImage },
      { type: ToolbarButtonType.Table, alt: 'table', icon: EditorIconTable },
    ]*/
  ];

  menuAnchorRefs = {
    [ToolbarButtonType.TextStyle]: React.createRef(),
    [ToolbarButtonType.TextMore]: React.createRef(),
  };

  menuRefs = {
    [ToolbarButtonType.TextStyle]: React.createRef(),
    [ToolbarButtonType.TextMore]: React.createRef(),
  };

  static DEFAULT_MENU_STATES = {
    [ToolbarButtonType.TextStyle]: false,
    [ToolbarButtonType.TextMore]: false,
    openTimePassed: false,
  };

  static CONVERT_TO_HTML_RULES = {
    styleToHTML: (style) => {
      if (style === 'SUPERSCRIPT') {
        return <sup />;
      } else if (style === 'SUBSCRIPT') {
        return <sub />;
      } else if (style === 'STRIKETHROUGH') {
        return <del />;
      } else if (style === 'UNDERLINE') {
        return <u />;
      }
    }
  };

  static CONVERT_FROM_HTML_RULES = {
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'sup') {
        return currentStyle.add('SUPERSCRIPT');
      } else if (nodeName === 'sub') {
        return currentStyle.add('SUBSCRIPT');
      } else if (nodeName === 'del') {
        return currentStyle.add('STRIKETHROUGH');
      } else if (nodeName === 'u') {
        return currentStyle.add('UNDERLINE');
      } else {
        return currentStyle;
      }
    },
  };

  constructor() {
    super();
    this.state = {
      editorState: EditorState.createEmpty(),
      menuStates: RTEditor.DEFAULT_MENU_STATES,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const { editorState } = this.state;
    const presentContentStateHtml = convertToHTML(RTEditor.CONVERT_TO_HTML_RULES)(editorState.getCurrentContent());
    if (presentContentStateHtml !== value) {
      // need to recreate state from scratch, happens on first prop setup
      const actualValue = value ? value : '';
      const newEditorState = EditorState.createWithContent(
        convertFromHTML(RTEditor.CONVERT_FROM_HTML_RULES)(actualValue));
      this.setState({
        editorState: newEditorState,
      });
    }

    window.addEventListener("click", this.handleGlobalMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleGlobalMouseClick);
  }

  handleGlobalMouseClick = (e) => {
    const { menuStates } = this.state;
    if (Object.values(menuStates).indexOf(true) != -1 && menuStates.openTimePassed) {
      let isOutside = true;
      const keys = Object.keys(this.menuRefs);
      for (let i = 0, len = keys.length; i < len; ++i) {
        if (keys[i].current) {
          const el = keys[i].current;
          if (e.clientX >= el.left && e.clientX <= el.right && e.clientY >= el.top && e.clientY <= el.bottom) {
            isOutside = false;
            break;
          }
        }
      }
      if (isOutside) {
        this.setState({
          menuStates: RTEditor.DEFAULT_MENU_STATES,
        });
      }
    }
  }

  onChangeState = newEditorState => {
    this.setState({
      editorState: newEditorState
    }, () => {
      const { onChange } = this.props;
      if (onChange) {
        const { editorState } = this.state;
        onChange(
          convertToHTML(RTEditor.CONVERT_TO_HTML_RULES)(editorState.getCurrentContent()));
      }
    });
  }

  toggleInlineStyle = (inlineStyle) => {
    const { editorState } = this.state;

    let resultingEditorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);

    // if toggling the style off
    const currentInlineStyle = editorState.getCurrentInlineStyle();
    if (currentInlineStyle.has(inlineStyle)) {
      var selectionState = editorState.getSelection();
      var selStart = selectionState.getStartOffset();
      var selEnd = selectionState.getEndOffset();
      if (selStart == selEnd) {
        // if there is no selection
        const currentBlock = editorState.getCurrentContent().getBlockForKey(
          selectionState.getAnchorKey()
        );
        const currentBlockLength = currentBlock.getLength();

        // then grow the selection to remove the entire inline style
        let newStart = selStart;
        while (newStart > 0 &&
          currentBlock.getInlineStyleAt(newStart - 1).has(inlineStyle)) {
            --newStart;
        }
        let newEnd = selEnd;
        while(newEnd < currentBlockLength - 1 && currentBlock.getInlineStyleAt(newEnd + 1).has(inlineStyle)) {
          ++newEnd;
        }

        const newSelectionState = SelectionState.createEmpty().merge({
          anchorOffset: newStart,
          anchorKey: selectionState.getAnchorKey(),
          focusOffset: newEnd + 1,
          focusKey: selectionState.getAnchorKey(),
        });
        let newEditorState = EditorState.forceSelection(
          editorState,
          newSelectionState,
        );  // make the selection change
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, inlineStyle);  // remove the inline style
        resultingEditorState = EditorState.forceSelection(
          newEditorState,
          selectionState,
        );  // bring back the old selection
      }
    }

    this.onChangeState(resultingEditorState);
  }

  toggleBlockStyle = (blockType) => {
    const { editorState } = this.state;
    this.onChangeState(
      RichUtils.toggleBlockType(editorState, blockType));
  }

  handleToolbarAction = (buttonType) => {
    switch (buttonType) {
      case ToolbarButtonType.Heading1:
        this.toggleBlockStyle(BlockTypes.Heading1);
        break;
      case ToolbarButtonType.Heading2:
        this.toggleBlockStyle(BlockTypes.Heading2);
        break;
      case ToolbarButtonType.Heading3:
        this.toggleBlockStyle(BlockTypes.Heading3);
        break;
      case ToolbarButtonType.Heading4:
        this.toggleBlockStyle(BlockTypes.Heading4);
        break;
      case ToolbarButtonType.Bold:
        this.toggleInlineStyle(TextDecorations.Bold);
        break;
      case ToolbarButtonType.Italic:
        this.toggleInlineStyle(TextDecorations.Italic);
        break;
      case ToolbarButtonType.Underline:
        this.toggleInlineStyle(TextDecorations.Underline);
        break;
      case ToolbarButtonType.Strikethrough:
        this.toggleInlineStyle(TextDecorations.Strikethrough);
        break;
      case ToolbarButtonType.Superscript:
        this.toggleInlineStyle(TextDecorations.Superscript);
        break;
      case ToolbarButtonType.Subscript:
        this.toggleInlineStyle(TextDecorations.Subscript);
        break;
      case ToolbarButtonType.BulletList:
        this.toggleBlockStyle(BlockTypes.BulletList);
        break;
      case ToolbarButtonType.NumberedList:
        this.toggleBlockStyle(BlockTypes.NumberedList);
        break;
    }
  }

  handleToolbarMenuItemClick = (type) => {
    this.setState({
      menuStates: RTEditor.DEFAULT_MENU_STATES,
    }, () => {
      this.handleToolbarAction(type);
    });
  }

  handleToolbarButtonClick = (button) => {
    if (!button.children) {
      // simple button
      this.handleToolbarAction(button.type);
    } else {
      // open menu
      this.setState({
        menuStates: {
          ...RTEditor.DEFAULT_MENU_STATES,
          [button.type]: true,
        },
      }, () => {
        setTimeout(() => {
          const { menuStates } = this.state;
          this.setState({
            menuStates: {
              ...menuStates,
              openTimePassed: true,
            },
          });
        }, 300);
      });
    }
  }

  renderToolbarMenus = () => {
    const { menuStates, editorState } = this.state;
    const menus = [];
    const isMenuItemSelected = (buttonType) => {
      let textDecorations = [];
      let blockTypes = [];
      switch (buttonType) {
        case ToolbarButtonType.Heading1:
          blockTypes = [ BlockTypes.Heading1 ];
          break;
        case ToolbarButtonType.Heading2:
          blockTypes = [ BlockTypes.Heading2 ];
          break;
        case ToolbarButtonType.Heading3:
          blockTypes = [ BlockTypes.Heading3 ];
          break;
        case ToolbarButtonType.Heading4:
          blockTypes = [ BlockTypes.Heading4 ];
          break;
        case ToolbarButtonType.Underline:
          textDecorations = [ TextDecorations.Underline ];
          break;
        case ToolbarButtonType.Strikethrough:
          textDecorations = [ TextDecorations.Strikethrough ];
          break;
        case ToolbarButtonType.Superscript:
          textDecorations = [ TextDecorations.Superscript ];
          break;
        case ToolbarButtonType.Subscript:
          textDecorations = [ TextDecorations.Subscript ];
          break;
      }
      if (textDecorations.length > 0) {
        const currentInlineStyle = editorState.getCurrentInlineStyle();
        for (let i in textDecorations) {
          if (currentInlineStyle.has(textDecorations[i])) {
            return true;
          }
        }
      } else if (blockTypes.length > 0) {
        const currentKey = editorState.getSelection().getFocusKey();
        const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);
        for (let i in blockTypes) {
          if (currentBlock.getType() == blockTypes[i]) {
            return true;
          }
        }
      }
      return false;
    };
    for (let i = 0, leni = RTEditor.ToolbarButtons.length; i < leni; ++i) {
      const g = RTEditor.ToolbarButtons[i];
      for (let j = 0, lenj = g.length; j < lenj; ++j) {
        const button = g[j];
        if (button.children) {
          const menuAnchorRef = this.menuAnchorRefs[button.type];
          const menuRef = this.menuRefs[button.type];
          const menuState = menuStates[button.type];
          menus.push(
            <Popover
              key={j}
              id={`simple-menu-${i}-${j}`}
              anchorEl={menuAnchorRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={Boolean(menuAnchorRef.current) && menuState}
              ref={menuRef}
            >
              <ul className="RTEditor-toolbar-menu">
                {
                  button.children.map((child, index) => (
                    <li key={index} className={`${child.className} ${isMenuItemSelected(child.type) ? 'selected': ''}`} onClick={() => this.handleToolbarMenuItemClick(child.type)}>{ child.text }</li>
                  ))
                }
              </ul>
            </Popover>
          );
        }
      }
    }
    return menus;
  }

  renderToolbarItem = (btn, index) => {
    const { editorState } = this.state;
    const resolveToolbarButtonIcon = (data, editorState) => {
      if (!data.iconActive) {
        return data.icon;
      } else {
        let textDecorations = [];
        let blockTypes = [];
        switch (data.type) {
          case ToolbarButtonType.TextStyle:
            blockTypes = [ BlockTypes.Heading1, BlockTypes.Heading2, BlockTypes.Heading3, BlockTypes.Heading4 ];
            break;
          case ToolbarButtonType.Bold:
            textDecorations = [ TextDecorations.Bold ];
            break;
          case ToolbarButtonType.Italic:
            textDecorations = [ TextDecorations.Italic ];
            break;
          case ToolbarButtonType.TextMore:
            textDecorations = [ TextDecorations.Underline, TextDecorations.Strikethrough, TextDecorations.Superscript, TextDecorations.Subscript ];
            break;
          case ToolbarButtonType.BulletList:
            blockTypes = [ BlockTypes.BulletList ];
            break;
          case ToolbarButtonType.NumberedList:
            blockTypes = [ BlockTypes.NumberedList ];
            break;
        }
        let matches = false;

        if (textDecorations.length > 0) {
          const currentInlineStyle = editorState.getCurrentInlineStyle();
          for (let i in textDecorations) {
            matches = currentInlineStyle.has(textDecorations[i]);
            if (matches)
              break;
          }
        }

        if (blockTypes.length > 0) {
          const currentKey = editorState.getSelection().getFocusKey();
          const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);
          for (let i in blockTypes) {
            matches = currentBlock.getType() == blockTypes[i];
            if (matches)
              break;
          }
        }

        return matches ? data.iconActive : data.icon;
      }
    };
    const buttonRef = this.menuAnchorRefs[btn.type];
    return (
      <IconButton
        key={index}
        aria-label={btn.alt}
        className="toolbar-btn"
        onClick={() => this.handleToolbarButtonClick(btn)}
        ref={buttonRef ? buttonRef : null}
      >
        <img alt={btn.alt} src={resolveToolbarButtonIcon(btn, editorState)} />
      </IconButton>
    );
  }

  render() {
    const { className, error, placeholder } = this.props;
    const { editorState } = this.state;
    const actualError = error ? error[0] : '';
    return (
      <ErrorTooltip title={actualError}>
        <div className={`RTEditor-root ${error ? 'RTEditor-error' : ''} ${className}`}>
          <div className="RTEditor-toolbar">
            {
              RTEditor.ToolbarButtons.map((g, i) => (
                <div
                  key={i}
                  className="toolbar-button-group"
                > {
                    g.map((btn, j) => this.renderToolbarItem(btn, j))
                } </div>
              ))
            }
            {
              this.renderToolbarMenus()
            }
          </div>
          <Editor
            placeholder={placeholder}
            editorState={editorState}
            onChange={this.onChangeState}
            customStyleMap={{
              SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
              SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' }
            }}
          />
        </div>
      </ErrorTooltip>
    );
  }
}

export default RTEditor;
