import { handleActions } from 'redux-actions';
import {
  GET_CATEGORIES_TYPES,
  GET_CATEGORIES_TYPES_SUCCESS,
  GET_CATEGORIES_TYPES_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_OWN_CONTACTS_CATEGORIES,
  GET_OWN_CONTACTS_CATEGORIES_SUCCESS,
  GET_OWN_CONTACTS_CATEGORIES_ERROR,
  GET_OWN_CONTACTS_CATEGORIES_HIERARCHY,
  GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_SUCCESS,
  GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_ERROR,
  GET_PARENT_CATEGORIES,
  GET_PARENT_CATEGORIES_SUCCESS,
  GET_PARENT_CATEGORIES_ERROR,
  GET_CHILD_CATEGORIES,
  GET_CHILD_CATEGORIES_SUCCESS,
  GET_CHILD_CATEGORIES_ERROR,
  GET_MATERIALS_CATEGORIES_SUCCESS,
  GET_MATERIALS_CATEGORIES_PROVIDER_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  RESET_CATEGORY,
} from '../actionTypes/categoriesActionTypes';

export const DEFAULT_CATEGORIES_STATE = {
  categories: [],
  categoriesBundles: {
    parentIds: [],
    successes: {},
    errors: {},
  },
  contactsOwnCategories: [],
  contactCategoriesHierarchy: [],
  metaData: {},
  parentCategories: [],
  materialsCategories: [],
  filterMaterialsCategories: [],
  categoriesTypes: [],
  category: {},
  categoryError: {},
  categoryBusy: false,
  isCategoryValid: false,
  categoriesBusy: false,
  categoriesError: false
};

export const categories = handleActions(
  {
    [GET_CATEGORIES_TYPES]: (state) => ({
      ...state,
      categoriesBusy: true
    }),
    [GET_CATEGORIES_TYPES_SUCCESS]: (state, action) => ({
      ...state,
      categoriesTypes: action.payload,
      categoriesBusy: false
    }),
    [GET_CATEGORIES_TYPES_ERROR]: (state) => ({
      ...state,
      categoriesBusy: false
    }),
    [GET_CATEGORIES]: (state, action) => ({
      ...state,
      categoriesBusy: true,
      categoriesBundles: {
        parentIds: [],
        successes: {},
        errors: {},
      },
    }),
    [GET_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      categories: action.payload.data,
      metaData: action.payload.meta,
      categoriesBusy: false
    }),
    [GET_CATEGORIES_ERROR]: (state, action) => ({
      ...state,
      categoriesBusy: false
    }),
    [GET_PARENT_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      parentCategories: action.payload,
    }),
    [GET_CHILD_CATEGORIES]: (state, action) => ({
      ...state,
      categoriesBundles: {
        parentIds: action.payload.keepCurrent
          ? [...state.categoriesBundles.parentIds, action.payload.parentId]
          : [action.payload.parentId],
        successes: action.payload.keepCurrent ? state.categoriesBundles.successes : {},
        errors: action.payload.keepCurrent ? state.categoriesBundles.errors : {}
      },
      //categoriesBusy: true,
    }),
    [GET_CHILD_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      categoriesBundles: {
        ...state.categoriesBundles,
        successes: {
          ...state.categoriesBundles.successes,
          [action.payload.id]: action.payload.data
        }
      },
      //categoriesBusy: false,
    }),
    [GET_CHILD_CATEGORIES_ERROR]: (state, action) => ({
      ...state,
      categoriesBundles: {
        ...state.categoriesBundles,
        errors: {
          ...state.categoriesBundles.errors,
          [action.payload.id]: action.payload.error
        }
      },
      //categoriesBusy: false,
    }),
    [GET_MATERIALS_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      materialsCategories: action.payload,
    }),
    [GET_MATERIALS_CATEGORIES_PROVIDER_SUCCESS]: (state, action) => ({
      ...state,
      filterMaterialsCategories: action.payload,
    }),
    [GET_OWN_CONTACTS_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      contactsOwnCategories: action.payload,
    }),
    [GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_SUCCESS]: (state, action) => ({
      ...state,
      contactCategoriesHierarchy: action.payload,
    }),
    [GET_CATEGORY]: (state, action) => ({
      ...state,
      categoryBusy: true
    }),
    [GET_CATEGORY_SUCCESS]: (state, action) => ({
      ...state,
      category: action.payload,
      categoryBusy: false
    }),
    [GET_CATEGORY_ERROR]: (state, action) => ({
      ...state,
      categoryBusy: false
    }),
    [ADD_CATEGORY]: (state, action) => ({
      ...state,
      categoryBusy: true
    }),
    [ADD_CATEGORY_SUCCESS]: (state, action) => ({
      ...state,
      categoryBusy: false,
      isCategoryValid: true
    }),
    [ADD_CATEGORY_ERROR]: (state, action) => ({
      ...state,
      categoryBusy: false,
      isCategoryValid: false,
      categoryError: action.payload,
      categoriesError: true
    }),
    [UPDATE_CATEGORY]: (state, action) => ({
      ...state,
      categoryBusy: true
    }),
    [UPDATE_CATEGORY_SUCCESS]: (state, action) => ({
      ...state,
      isCategoryValid: true,
      categoryBusy: false
    }),
    [UPDATE_CATEGORY_ERROR]: (state, action) => ({
      ...state,
      categoryBusy: false,
      isCategoryValid: false,
      categoryError: action.payload,
      categoriesError: true
    }),
    [RESET_CATEGORY]: (state, action) => ({
      ...state,
      category: {},
      categoriesBundles: {
        parentIds: [],
        successes: {},
        errors: {},
      },
      parentCategories: [],
      categoriesError: false,
      isCategoryValid: false,
      categoryError: {},
    }),
  },
  DEFAULT_CATEGORIES_STATE
);
