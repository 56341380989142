import React from 'react';

import Input from '../../../../components/Input/Input';
import { withTranslation } from 'react-i18next';

import CustomDropdownLanguage from 'components/CustomDropdownLanguage/CustomDropdownLanguage';
import i18n from 'i18next';

import './style.scss';

class WelcomePage extends React.Component {
  render() {
    const {
      data,
      errors,
      onChange,
      t,
    } = this.props;
    return (
      <div className="welcome-page">
        <div className="welcome-page-title">
          <span>{ t('welcome') }</span>
        </div>
        <div className="welcome-text">
          <span dangerouslySetInnerHTML={{ __html: t('welcome_text_register') }}></span>
        </div>
        <div className="register-input-container">
          <span className="input-container-title">{ t('preferred_language') }</span>
          <div className="input-container-inner language-selector-container">
            <CustomDropdownLanguage
              buttonText={i18n.language}
              onClick={prop => {
                i18n.changeLanguage(prop.id);
              }}
            />
          </div>
          <span className="input-container-title">{ t('email') }</span>
          <div className="input-container-inner">
            <Input
              value={data.email}
              onChange={e => onChange('email', e.target.value)}
              error={errors.email && errors.email.error}
              errorMsg={errors.email && errors.email.message}
            />
          </div>
          <span className="input-container-title">{ t('password') }</span>
          <div className="input-container-inner">
            <Input
              value={data.password}
              onChange={e => onChange('password', e.target.value)}
              type='password'
              error={errors.password && errors.password.error}
              errorMsg={errors.password && errors.password.message}
            />
          </div>
          <span className="input-container-title">{ t('repeat_password') }</span>
          <div className="input-container-inner">
            <Input
              value={data.password_confirmation}
              onChange={e => onChange('password_confirmation', e.target.value)}
              type='password'
              error={errors.password_confirmation && errors.password_confirmation.error}
              errorMsg={errors.password_confirmation && errors.password_confirmation.message}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(WelcomePage);
