import React from 'react';
import { withTranslation } from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import './style.scss';
import Autocomplete from 'components/Autocomplete/index';
import Checkbox from 'components/StyledCheckbox';
import Input from 'components/Input/Input';
import DecimalInput from 'components/DecimalInput';
import UploadImage from "components/UploadImg";

class Projects extends React.Component {

  constructor(props) {
    super(props);
    this.discountContainerRef = React.createRef();
    this.projectOwnershipTitleRef = React.createRef();
    this.generalTitleRef = React.createRef();
  }

  resolveErrorForKey = (key) => {
    const { errors } = this.props;
    if (errors && errors[key])
      return errors[key];
    else
      return null;
  }

  componentDidMount() {
    this.forceUpdate(); // discountContainerRef.current is undefined the first render, it needs to be defined so that input field widths are set correctly
  }

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return <span>{text}</span>;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  render() {
    const {
      data,
      errors,
      letterheadData,
      letterheadErrors,
      onDataChange,
      coworkers,
      t
    } = this.props;

    // dynamic calculation of input field width based on existing bootstrap grid usage, where it itself cannot be used
    const calculatedWidth = this.discountContainerRef && this.discountContainerRef.current ? this.discountContainerRef.current.clientWidth : null;
    const inputWidthStyle = {};
    if (calculatedWidth) {
      inputWidthStyle['width'] = `${calculatedWidth}px`;
    }

    const generalFieldWidth = this.generalTitleRef && this.generalTitleRef.current ? this.generalTitleRef.current.parentNode.clientWidth : null;
    let text = t('payment_term_in_days');
    let numChars = Math.floor((generalFieldWidth - 10) / 8.5);
    const paymentTermText = this.wrapText(text, numChars);
    const projectFieldWidth = this.projectOwnershipTitleRef && this.projectOwnershipTitleRef.current ? this.projectOwnershipTitleRef.current.parentNode.clientWidth : null;
    text = t('default_owner_of_the_orders');
    numChars = Math.floor((projectFieldWidth - 20) / 8);
    const projectOrdersText = this.wrapText(text, numChars);
    text = t('default_owner_of_the_inquiries');
    numChars = Math.floor((projectFieldWidth - 10) / 8);
    const projectInquiriesText = this.wrapText(text, numChars);

    return (
      <GridContainer className="projects-settings-container">
        <GridItem className="projects-data-container-outer">
          <GridItem className="projects-data-container-inner general-container" xs={4}>
            <div className="projects-data-title">
              <span>{ t('general') }</span>
            </div>
            <div className="projects-data-content">
              <div className="data-content-title">
                <GridItem xs={9}>
                  { t('name') }
                </GridItem>
                <GridItem xs={3}>
                  { t('value') }
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={9}>
                  <div ref={this.generalTitleRef}></div>
                  {paymentTermText}
                </GridItem>
                <GridItem className="data-value-container" xs={3}>
                  <DecimalInput
                    value={data.project_settings_payment_term}
                    onChange={(value) => onDataChange('project_settings_payment_term', value)}
                    error={this.resolveErrorForKey('project_settings_payment_term')}
                  />
                </GridItem>
              </div>
            </div>
          </GridItem>
          <GridItem className="projects-data-container-inner discounts-container" xs={4}>
            <div className="projects-data-title">
              <span>{ t('discounts') }</span>
            </div>
            <div className="projects-data-content">
              <div className="data-content-title">
                <GridItem xs={6}>
                  { t('name') }
                </GridItem>
                <GridItem xs={3}>
                  { t('days') }
                </GridItem>
                <GridItem xs={3}>
                  { t('value') }
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('discount') } 1
                </GridItem>
                <GridItem className="data-value-container" xs={3}>
                  <div ref={this.discountContainerRef}>
                    <DecimalInput
                      value={data.project_settings_discount_1_days}
                      onChange={(value) => onDataChange('project_settings_discount_1_days', value)}
                      error={this.resolveErrorForKey('project_settings_discount_1_days')}
                    />
                  </div>
                </GridItem>
                <GridItem className="data-value-container" xs={3}>
                  <DecimalInput
                    value={data.project_settings_discount_1_value}
                    onChange={(value) => onDataChange('project_settings_discount_1_value', value)}
                    error={this.resolveErrorForKey('project_settings_discount_1_value')}
                  />
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('discount') } 2
                </GridItem>
                <GridItem className="data-value-container" xs={3}>
                  <DecimalInput
                    value={data.project_settings_discount_2_days}
                    onChange={(value) => onDataChange('project_settings_discount_2_days', value)}
                    error={this.resolveErrorForKey('project_settings_discount_2_days')}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={3}>
                  <DecimalInput
                    value={data.project_settings_discount_2_value}
                    onChange={(value) => onDataChange('project_settings_discount_2_value', value)}
                    error={this.resolveErrorForKey('project_settings_discount_2_value')}
                  />
                </GridItem>
              </div>
            </div>
          </GridItem>
          <GridItem className="projects-data-container-inner" xs={4}>
          <div className="projects-data-title">
            <span>{ t('project_ownership') }</span>
          </div>
          <div className="projects-data-content">
            <div className="data-content-title">
              <GridItem className="grid-item-width-6">
                { t('name') }
              </GridItem>
              <GridItem className="grid-item-width-6">
                { t('person') }
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-item-width-6">
                <div ref={this.projectOwnershipTitleRef}></div>
                {projectOrdersText}
              </GridItem>
              <GridItem className="data-value-container grid-item-width-6">
                <Autocomplete
                  buttonText={data.project_settings_orders_default_owner}
                  buttonProps={{className: '', round: true, size: "sm"}}
                  dropdownList={coworkers}
                  hoverColor="dark"
                  onClick={value => onDataChange('project_settings_orders_default_owner', value.id)}
                  error={this.resolveErrorForKey('project_settings_orders_default_owner')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-item-width-6">
                {projectInquiriesText}
              </GridItem>
              <GridItem className="data-value-container grid-item-width-6">
                <Autocomplete
                  buttonText={data.project_settings_inquiries_default_owner}
                  buttonProps={{className: '', round: true, size: "sm"}}
                  dropdownList={coworkers}
                  hoverColor="dark"
                  onClick={value => onDataChange('project_settings_inquiries_default_owner', value.id)}
                  error={this.resolveErrorForKey('project_settings_inquiries_default_owner')}
                />
              </GridItem>
            </div>
          </div>
        </GridItem>
        </GridItem>
        <GridItem className="projects-data-container-outer">
          <GridItem className="projects-data-container-inner automatic-numering-container" xs={8}>
          <div className="projects-data-title">
            <span>{ t('automatic_numbering') }</span>
          </div>
          <div className="projects-data-content">
            <div className="data-content-title">
              <GridItem className="grid-an-name">
                { t('name') }
              </GridItem>
              <GridItem style={inputWidthStyle}>
                { t('prefix') }
              </GridItem>
              <GridItem style={inputWidthStyle}>
                { t('start_no') }
              </GridItem>
              <GridItem style={inputWidthStyle}>
                { t('postfix') }
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-an-name">
                { t('order') }
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <Input
                  value={data.project_settings_order_prefix}
                  onChange={(e) => onDataChange('project_settings_order_prefix', e.target.value)}
                  error={this.resolveErrorForKey('project_settings_order_prefix')}
                  placeholder={''}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_order_staring_number}
                  onChange={(value) => onDataChange('project_settings_order_staring_number', value)}
                  error={this.resolveErrorForKey('project_settings_order_staring_number')}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_order_postfix}
                  onChange={(value) => onDataChange('project_settings_order_postfix', value)}
                  error={this.resolveErrorForKey('project_settings_order_postfix')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-an-name">
                { t('inquiry') }
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <Input
                  value={data.project_settings_inquiry_prefix}
                  onChange={(e) => onDataChange('project_settings_inquiry_prefix', e.target.value)}
                  error={this.resolveErrorForKey('project_settings_inquiry_prefix')}
                  placeholder={''}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_inquiry_staring_number}
                  onChange={(value) => onDataChange('project_settings_inquiry_staring_number', value)}
                  error={this.resolveErrorForKey('project_settings_inquiry_staring_number')}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_inquiry_postfix}
                  onChange={(value) => onDataChange('project_settings_inquiry_postfix', value)}
                  error={this.resolveErrorForKey('project_settings_inquiry_postfix')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-an-name">
                { t('full_invoice') }
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <Input
                  value={data.project_settings_full_invoice_prefix}
                  onChange={(e) => onDataChange('project_settings_full_invoice_prefix', e.target.value)}
                  error={this.resolveErrorForKey('project_settings_full_invoice_prefix')}
                  placeholder={''}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_full_invoice_staring_number}
                  onChange={(value) => onDataChange('project_settings_full_invoice_staring_number', value)}
                  error={this.resolveErrorForKey('project_settings_full_invoice_staring_number')}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_full_invoice_postfix}
                  onChange={(value) => onDataChange('project_settings_full_invoice_postfix', value)}
                  error={this.resolveErrorForKey('project_settings_full_invoice_postfix')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-an-name">
                { t('partial_invoice') }
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <Input
                  value={data.project_settings_partial_invoice_prefix}
                  onChange={(e) => onDataChange('project_settings_partial_invoice_prefix', e.target.value)}
                  error={this.resolveErrorForKey('project_settings_partial_invoice_prefix')}
                  placeholder={''}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_partial_invoice_staring_number}
                  onChange={(value) => onDataChange('project_settings_partial_invoice_staring_number', value)}
                  error={this.resolveErrorForKey('project_settings_partial_invoice_staring_number')}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_partial_invoice_postfix}
                  onChange={(value) => onDataChange('project_settings_partial_invoice_postfix', value)}
                  error={this.resolveErrorForKey('project_settings_partial_invoice_postfix')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-an-name">
                { t('crediting_invoice') }
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <Input
                  value={data.project_settings_crediting_invoice_prefix}
                  onChange={(e) => onDataChange('project_settings_crediting_invoice_prefix', e.target.value)}
                  error={this.resolveErrorForKey('project_settings_crediting_invoice_prefix')}
                  placeholder={''}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_crediting_invoice_staring_number}
                  onChange={(value) => onDataChange('project_settings_crediting_invoice_staring_number', value)}
                  error={this.resolveErrorForKey('project_settings_crediting_invoice_staring_number')}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_crediting_invoice_postfix}
                  onChange={(value) => onDataChange('project_settings_crediting_invoice_postfix', value)}
                  error={this.resolveErrorForKey('project_settings_crediting_invoice_postfix')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-an-name">
                { t('cancellation_invoice') }
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <Input
                  value={data.project_settings_cancellation_invoice_prefix}
                  onChange={(e) => onDataChange('project_settings_cancellation_invoice_prefix', e.target.value)}
                  error={this.resolveErrorForKey('project_settings_cancellation_invoice_prefix')}
                  placeholder={''}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_cancellation_invoice_staring_number}
                  onChange={(value) => onDataChange('project_settings_cancellation_invoice_staring_number', value)}
                  error={this.resolveErrorForKey('project_settings_cancellation_invoice_staring_number')}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_cancellation_invoice_postfix}
                  onChange={(value) => onDataChange('project_settings_cancellation_invoice_postfix', value)}
                  error={this.resolveErrorForKey('project_settings_cancellation_invoice_postfix')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-an-name">
                { t('deposit_invoice') }
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <Input
                  value={data.project_settings_deposit_invoice_prefix}
                  onChange={(e) => onDataChange('project_settings_deposit_invoice_prefix', e.target.value)}
                  error={this.resolveErrorForKey('project_settings_deposit_invoice_prefix')}
                  placeholder={''}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_deposit_invoice_staring_number}
                  onChange={(value) => onDataChange('project_settings_deposit_invoice_staring_number', value)}
                  error={this.resolveErrorForKey('project_settings_deposit_invoice_staring_number')}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_deposit_invoice_postfix}
                  onChange={(value) => onDataChange('project_settings_deposit_invoice_postfix', value)}
                  error={this.resolveErrorForKey('project_settings_deposit_invoice_postfix')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container grid-an-name">
                { t('offer') }
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <Input
                  value={data.project_settings_offer_prefix}
                  onChange={(e) => onDataChange('project_settings_offer_prefix', e.target.value)}
                  error={this.resolveErrorForKey('project_settings_offer_prefix')}
                  placeholder={''}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_offer_starting_number}
                  onChange={(value) => onDataChange('project_settings_offer_starting_number', value)}
                  error={this.resolveErrorForKey('project_settings_offer_starting_number')}
                />
              </GridItem>
              <GridItem className="data-value-container" style={inputWidthStyle}>
                <DecimalInput
                  value={data.project_settings_offer_postfix}
                  onChange={(value) => onDataChange('project_settings_offer_postfix', value)}
                  error={this.resolveErrorForKey('project_settings_offer_postfix')}
                />
              </GridItem>
            </div>
          </div>
          </GridItem>
          <GridItem className="projects-data-container-inner" xs={4}>
          <div className="projects-data-title">
            <span>{ t('vat') }</span>
          </div>
          <div className="projects-data-content">
            <div className="data-content-title">
              <GridItem xs={9}>
                { t('name') }
              </GridItem>
              <GridItem xs={3}>
                { t('value') }
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container" xs={9}>
                { t('normal_vat_rate') }
              </GridItem>
              <GridItem className="data-value-container" xs={3}>
                <DecimalInput
                  value={data.project_settings_normal_vat_rate}
                  onChange={(value) => onDataChange('project_settings_normal_vat_rate', value)}
                  error={this.resolveErrorForKey('project_settings_normal_vat_rate')}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container" xs={9}>
                { t('reduced_vat_rate') }
              </GridItem>
              <GridItem className="data-value-container" xs={3}>
                <DecimalInput
                  value={data.project_settings_reduced_vat_rate}
                  onChange={(value) => onDataChange('project_settings_reduced_vat_rate', value)}
                  error={this.resolveErrorForKey('project_settings_reduced_vat_rate')}
                />
              </GridItem>
            </div>
            <div className="data-content vat-checkboxes-container">
              <div className="checkbox-container">
                <Checkbox
                  checked={data.project_settings_vat_rate === "1"}
                  onChange={(e) => onDataChange("project_settings_vat_rate", "1")}
                  color="primary"
                />
                <span className="checkbox-label">{ t('normal_vat_rate') }</span>
              </div>
              <div className="checkbox-container">
                <Checkbox
                  checked={data.project_settings_vat_rate === "2"}
                  onChange={(e) => onDataChange("project_settings_vat_rate", "2")}
                  color="primary"
                />
                <span className="checkbox-label">{ t('reduced_vat_rate') }</span>
              </div>
              <div className="checkbox-container">
                <Checkbox
                  checked={data.project_settings_vat_rate === "3"}
                  onChange={(e) => onDataChange("project_settings_vat_rate", "3")}
                  color="primary"
                />
                <span className="checkbox-label">{ t('zero_vat_rate') }</span>
              </div>
            </div>
          </div>
          </GridItem>
        </GridItem>
        <GridItem className="projects-data-container-outer">
          <GridItem className="projects-data-container-inner letterhead1-container" xs={4}>
            <div className="projects-data-title">
              <span>{ t('invoice_letterhead_1') }</span>
            </div>
            <div className="projects-data-content">
              <UploadImage
                className="upload-img"
                file={!letterheadData.letterhead1Removed ? letterheadData.letterhead1 : null}
                onChange={file => this.props.handleLetterheadChange('letterhead1', file)}
                errorMsg={letterheadErrors && letterheadErrors.invoice_primary_header}
              />
            </div>
          </GridItem>
          <GridItem className="projects-data-container-inner letterhead2-container" xs={4}>
            <div className="projects-data-title">
              <span>{ t('invoice_letterhead_2') }</span>
            </div>
            <div className="projects-data-content">
              <UploadImage
                className="upload-img"
                file={!letterheadData.letterhead2Removed ? letterheadData.letterhead2 : null}
                onChange={file => this.props.handleLetterheadChange('letterhead2', file)}
                errorMsg={letterheadErrors && letterheadErrors.invoice_secondary_header}
              />
            </div>
          </GridItem>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withTranslation()(Projects);
