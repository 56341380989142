export const GET_COWORKERS = 'coworkersEpic/GET_COWORKERS';
export const GET_COWORKERS_SUCCESS = 'coworkersEpic/GET_COWORKERS_SUCCESS';
export const GET_COWORKERS_ERROR = 'coworkersEpic/GET_COWORKERS_ERROR';

export const GET_COWORKER = 'coworkersEpic/GET_COWORKER';
export const GET_COWORKER_SUCCESS = 'coworkersEpic/GET_COWORKER_SUCCESS';

export const ADD_COWORKER = 'coworkersEpic/ADD_COWORKER';

export const UPDATE_COWORKER = 'coworkersEpic/UPDATE_COWORKER';

export const REMOVE_COWORKER = 'coworkersEpic/REMOVE_COWORKER';
