import {
  GET_CATEGORIES_TYPES,
  GET_CATEGORIES_TYPES_SUCCESS,
  GET_CATEGORIES_TYPES_ERROR,
  GET_PARENT_CATEGORIES,
  GET_PARENT_CATEGORIES_SUCCESS,
  GET_PARENT_CATEGORIES_ERROR,
  GET_MATERIALS_CATEGORIES,
  GET_MATERIALS_CATEGORIES_SUCCESS,
  GET_MATERIALS_CATEGORIES_ERROR,
  GET_MATERIALS_CATEGORIES_PROVIDER,
  GET_MATERIALS_CATEGORIES_PROVIDER_SUCCESS,
  GET_MATERIALS_CATEGORIES_PROVIDER_ERROR,
  GET_OWN_CONTACTS_CATEGORIES,
  GET_OWN_CONTACTS_CATEGORIES_SUCCESS,
  GET_OWN_CONTACTS_CATEGORIES_ERROR,
  GET_OWN_CONTACTS_CATEGORIES_HIERARCHY,
  GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_SUCCESS,
  GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  ADD_CATEGORY,
  GET_CHILD_CATEGORIES,
  GET_CHILD_CATEGORIES_SUCCESS,
  GET_CHILD_CATEGORIES_ERROR,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  REMOVE_CATEGORY,
  REMOVE_CATEGORY_SUCCESS,
  REMOVE_CATEGORY_ERROR,
  RESET_CATEGORY
} from '../actionTypes/categoriesActionTypes';

import NotificationService from '../../services/NotificationService';

export const getCategoriesTypes = () => {
  return {type: GET_CATEGORIES_TYPES}
};

export const getCategoriesTypesSuccess = (types) => {
  return {type: GET_CATEGORIES_TYPES_SUCCESS, payload: types}
};

export const getCategoriesTypesFailure = (error) => {
  return {type: GET_CATEGORIES_TYPES_ERROR, payload: error}
};

export const getCategories = (data) => {
    return {type: GET_CATEGORIES, payload: data}
};

export const getCategoriesSuccess = (categories) => {
    return {type: GET_CATEGORIES_SUCCESS, payload: categories}
};

export const getCategoriesFailure = (error) => {
    return {type: GET_CATEGORIES_ERROR, payload: error}
};

export const getOwnContactsCategories = (rootOnly) => {
  return {type: GET_OWN_CONTACTS_CATEGORIES, payload: { rootOnly }}
};

export const getOwnContactsCategoriesSuccess = (categories) => {
  return {type: GET_OWN_CONTACTS_CATEGORIES_SUCCESS, payload: categories}
};

export const getOwnContactsCategoriesFailure = (error) => {
  return {type: GET_OWN_CONTACTS_CATEGORIES_ERROR, payload: error}
};

export const getOwnContactsCategoriesHierarchy = () => {
  return {type: GET_OWN_CONTACTS_CATEGORIES_HIERARCHY, payload: {}};
};

export const getOwnContactsCategoriesHierarchySuccess = (categories) => {
  return {type: GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_SUCCESS, payload: categories}
};

export const getOwnContactsCategoriesHierarchyFailure = (error) => {
  return {type: GET_OWN_CONTACTS_CATEGORIES_HIERARCHY_ERROR, payload: error}
};

export const getParentCategories = (data) => {
  return {type: GET_PARENT_CATEGORIES, payload: data}
};

export const getParentCategoriesSuccess = (categories) => {
  let selectData = [];
  Object.entries(categories).forEach((item, i) => {
    selectData.push({'id':item[0], 'value':item[1]})
  })
  return {type: GET_PARENT_CATEGORIES_SUCCESS, payload: selectData}
};

export const getParentCategoriesFailure = (error) => {
  return {type: GET_PARENT_CATEGORIES_ERROR, payload: error}
};

export const getChildCategories = (parentId, keepCurrent) => {
  return {type: GET_CHILD_CATEGORIES, payload: { parentId, keepCurrent }};
};

export const getChildCategoriesSuccess = (id, data) => {
  return {type: GET_CHILD_CATEGORIES_SUCCESS, payload: { id, data }};
};

export const getChildCategoriesFailure = (id, error) => {
  return {type: GET_CHILD_CATEGORIES_ERROR, payload: { id, error }};
};

export const getMaterialsCategories = () => {
  return {type: GET_MATERIALS_CATEGORIES}
};

export const getMaterialsCategoriesSuccess = (categories) => {
  return {type: GET_MATERIALS_CATEGORIES_SUCCESS, payload: categories}
};

export const getMaterialsCategoriesFailure = (error) => {
  return {type: GET_MATERIALS_CATEGORIES_ERROR, payload: error}
};

export const getMaterialsCategoriesProvider = () => {
  return {type: GET_MATERIALS_CATEGORIES_PROVIDER}
};

export const getMaterialsCategoriesProviderSuccess = (categories) => {
  let selectData = [];
  Object.entries(categories).forEach((item, i) => {
    selectData.push({'id':item[0], 'name':item[1]})
  })
  return {type: GET_MATERIALS_CATEGORIES_PROVIDER_SUCCESS, payload: selectData}
};

export const getMaterialsCategoriesProviderFailure = (error) => {
  return {type: GET_MATERIALS_CATEGORIES_PROVIDER_ERROR, payload: error}
};

export const getCategory = (id) => {
    return {type: GET_CATEGORY, payload: id}
};

export const getCategorySuccess = (coworker) => {
    return {type: GET_CATEGORY_SUCCESS, payload: coworker}
};

export const getCategoryFailure = (error) => {
    return {type: GET_CATEGORY_ERROR, payload: error}
};

export const addCategory = (data) => {
    return {type: ADD_CATEGORY, payload: data}
};

export const addCategorySuccess = () => {
    NotificationService.success('Category was added successfully');
    return {type: ADD_CATEGORY_SUCCESS}
};

export const addCategoryFailure = (error) => {
    return {type: ADD_CATEGORY_ERROR, payload: error}
};

export const updateCategory = (data) => {
    return {type: UPDATE_CATEGORY, payload: data}
};

export const updateCategorySuccess = () => {
    NotificationService.success('Category updated successfully');
    return {type: UPDATE_CATEGORY_SUCCESS}
};

export const updateCategoryFailure = (error) => {
    return {type: UPDATE_CATEGORY_ERROR, payload: error}
};

export const deleteCategory = (data) => {
    return {type: REMOVE_CATEGORY, payload: data}
};

export const deleteCategorySuccess = () => {
    NotificationService.success('Category removed');
    return {type: REMOVE_CATEGORY_SUCCESS}
};

export const deleteCategoryFailure = (error) => {
    NotificationService.error(error);
    return {type: REMOVE_CATEGORY_ERROR, payload: error}
};

export const resetCategory = () => {
    return {type: RESET_CATEGORY}
};

