import {
  GET_PROJECT_MANUFACTURERS,
  GET_PROJECT_MANUFACTURERS_SUCCESS,
  GET_PROJECT_MANUFACTURERS_ERROR
} from 'store/actionTypes/projectManufacturers';

export const getProjectManufacturers = () => {
  return { type: GET_PROJECT_MANUFACTURERS };
};

export const getProjectManufacturersSuccessful = projectManufacturers => {
  return { type: GET_PROJECT_MANUFACTURERS_SUCCESS, payload: projectManufacturers };
};

export const getProjectManufacturersFailed = error => {
  return { type: GET_PROJECT_MANUFACTURERS_ERROR, payload: error };
};
