import React from "react";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import {getCities, getCountries} from "../../store/actions/locations";

import GridItem from "../../components/Grid/GridItem";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import './style.scss';

class ShippingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: this.props.id === 0
    };
  }

  componentDidMount() {
   // this.props.getCountries();
   // this.props.getCities(this.props.data.country_id)
  }

  onCardClose = () => {
    this.setState({
      isCardOpen: !this.state.isCardOpen
    })
  }

  render() {
    const {
      data,
      cities,
      countries,
      id,
      t
    } = this.props;
    const {isCardOpen} = this.state;
   // const cityName = cities.length !== 0 ? cities.find(item => item.id === data.city_id.toString()).value: '';
   // const countryName = countries.length !== 0 ? countries.find(item => item.id === data.country_id.toString()).value : '';
    return(
      <div className="shipping-card-content">
        <div className={`shipping-card-info ${isCardOpen ? 'shipping-card-bottom-borders' : ''}`}>
          <div className="shipping-card-info-title">
            <div className="shipping-card-title">
              {`Shipping Address ${id + 1}`}
            </div>
            {
              isCardOpen &&
              <div className="shipping-card-data">
                <div className="shipping-card-info-field">
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('first_name') }</GridItem>
                    <GridItem xs={8}>{data.first_name}</GridItem>
                  </div>
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('last_name') }</GridItem>
                    <GridItem xs={8}>{data.last_name}</GridItem>
                  </div>
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('telephone') }</GridItem>
                    <GridItem xs={8}>{data.phone}</GridItem>
                  </div>
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('mobile_phone') }</GridItem>
                    <GridItem xs={8}>{data.phone_mobile}</GridItem>
                  </div>
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('email') }</GridItem>
                    <GridItem xs={8}>{data.email}</GridItem>
                  </div>
                </div>
                <div className="shipping-card-info-field">
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('address') }</GridItem>
                    <GridItem xs={8}>{data.address}</GridItem>
                  </div>
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('post_code') }</GridItem>
                    <GridItem xs={8}>{data.postal_code}</GridItem>
                  </div>
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('city') }</GridItem>
                    <GridItem xs={8}>{data.city}</GridItem>
                  </div>
                  <div className="shipping-card-info-item">
                    <GridItem xs={4} className="shipping-card-info-name">{ t('country') }</GridItem>
                    <GridItem xs={8}>{data.country}</GridItem>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="shipping-card-btn-container">
            <div
              className={`card-btn ${isCardOpen ? 'cost-bottom-borders' : ''}`}
              onClick={() => this.onCardClose()}
            >
              {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProp = state => {
  return {
    cities: state.locations.cities,
    countries: state.locations.countries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCountries: () => dispatch(getCountries()),
    getCities: (id) => dispatch(getCities(id))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ShippingDetails));
