/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
// core components
import Button from 'components/CustomButtons/Button.jsx';
import CustomDatePicker from 'components/CustomDatePicker/index';

import Arrow from '../../assets/img/buttons/chevron-down.svg';
import ArrowWhite from '../../assets/img/buttons/status-dropdown.svg';

import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx';

import './styles.scss';

class CustomDateRangePickerDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleDateChange = (code, value) => {
    if (this.props.onChange)
      this.props.onChange(code, value);
  }

  handleClick = () => {
    const { open } = this.state;
    if (open) {
      if (this.props.onClose)
        this.props.onClose();
    }
    this.setState({
      open: !open,
    })
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
    if (this.props.onClose)
      this.props.onClose();
  };

  handleCloseMenu = param => {
    this.setState({ open: false });
    if (this.props.onClose)
      this.props.onClose();
  }

  handleKeyDown = event => {
    const { open } = this.state;
    if (!open) {
      if (event.keyCode == 13 || event.keyCode == 32) {  // enter or space
        event.preventDefault();
        this.setState({
          open: true,
        });
      }
    } else {
      if (event.keyCode == 27) {  // escape
        this.setState({
          open: false,
        });
      }
    }
  }

  render() {
    const { open, selectedIndex } = this.state;
    const {
      classes,
      dateFrom,
      dateTo,
      dateFromPlaceholder,
      dateToPlaceholder,
      buttonPlaceholder,
      buttonIconType,
      dropdownList,
      buttonProps,
      dropup,
      dropdownHeader,
      id,
      hoverColor,
      dropPlacement,
      rtlActive,
      noLiPadding,
      innerDropDown,
      navDropdown,
      error,
      shouldDisableDateFrom,
      shouldDisableDateTo,
    } = this.props;
    const caretClasses = classNames({
      [classes.caret]: true,
      [classes.caretDropup]: dropup && !open,
      [classes.caretActive]: open && !dropup,
      [classes.caretRTL]: rtlActive
    });

    let buttonToggleClasses = `${buttonProps.className} ikt-ap_custom-dropdown-toggle-btn ${error ? 'dropdown-error' : ''} ${dateFrom && dateTo ? 'have-both-dates' : ''}`;
    const getDisplayText = () => {
      if (dateFrom && dateTo) {
        return `${moment(dateFrom).format('YYYY-MM-DD')} - ${moment(dateTo).format('YYYY-MM-DD')}`;
      } else if (dateFrom) {
        return `From ${moment(dateFrom).format('YYYY-MM-DD')}`;
      } else if (dateTo) {
        return `Up to ${moment(dateTo).format('YYYY-MM-DD')}`;
      } else {
        if (buttonPlaceholder) {
          return buttonPlaceholder;
        } else {
          return 'Select date range...';
        }
      }
    }
    return (
      <div
        className={`ikt-ap_custom-dropdown dropdown-blue date-range-picker ${
          innerDropDown ? classes.innerManager : classes.manager
        }`}
        onKeyDown={this.handleKeyDown}
      >
        <div className={''}>
          <Button
            aria-label="Notifications"
            id={id}
            aria-owns={open ? 'menu-list' : null}
            aria-haspopup="true"
            buttonRef={node => {
              this.anchorEl = node;
            }}
            {...buttonProps}
            className={buttonToggleClasses}
            onClick={this.handleClick}
          >
            { getDisplayText() }
            {buttonIconType === 'white'
              ? <img alt="dropdown-caret-white" src={ArrowWhite} className={caretClasses} />
              : <img alt="dropdown-caret-blue" src={Arrow} className={caretClasses} />}
          </Button>
        </div>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true,
            [classes.popperNav]: open && navDropdown
          })}
        >
          {() => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: '0 100% 0' }
                  : { transformOrigin: '0 0 0' }
              }
            >
              <Paper
                className={`${classes.dropdown} ikt-ap_custom-dropdown-menu`}
              >
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div className="date-range-picker-body-container">
                    <CustomDatePicker
                      className="date-picker-from"
                      placeholder={dateFromPlaceholder ? dateFromPlaceholder : "From..."}
                      handleChange={date => this.handleDateChange(0, date)}
                      selectedDate={dateFrom ? dateFrom : null}
                      requireCloseButton={true}
                      smallerFontSize={true}
                      shouldDisableDate={shouldDisableDateFrom}
                    />
                    <CustomDatePicker
                      className="date-picker-to"
                      placeholder={dateToPlaceholder ? dateToPlaceholder : "Up to..."}
                      handleChange={date => this.handleDateChange(1, date)}
                      selectedDate={dateTo ? dateTo : null}
                      requireCloseButton={true}
                      smallerFontSize={true}
                      shouldDisableDate={shouldDisableDateTo}
                    />
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CustomDateRangePickerDropdown.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: 'primary'
};

CustomDateRangePickerDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  buttonIcon: PropTypes.object,
  id: PropTypes.string,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    'bottom',
    'top',
    'right',
    'left',
    'bottom-start',
    'bottom-end',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end'
  ]),
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

export default withStyles(customDropdownStyle)(CustomDateRangePickerDropdown);
