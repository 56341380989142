import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getMainMaterialsList,
  getSurfaceMaterialsList
} from "../../../store/actions/materialProviders";

import { default as CreateTask, edgesLeftAmountChange, edgesRightAmountChange, edgesTopAmountChange, edgesBottomAmountChange } from '../../CreateTask';
import EdgesStructure from'../../CreateTask/EdgesStructure';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import IconButton from '@material-ui/core/IconButton';
import Input from 'components/Input/Input';
import InputWithIcon from 'components/InputWithIcon';

import EditIcon from '../../../assets/img/buttons/edit.svg';
import DeleteIcon from '../../../assets/img/buttons/delete.svg';

import './style.scss';
import InputAdornment from '@material-ui/core/InputAdornment';
import RemoveDialog from "../../RemoveDialog";
import Autocomplete from "../../../components/CustomAutocomplete";
import Dropdown from "../../../components/CustomDropdown/CustomDropdown";

class PositionTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTaskEditOpen: false,
      isRemoveTaskOpen: false,
      isWideView: false
    };
  }

  componentDidMount() {
    const mediaMatcher = window.matchMedia("(min-width: 1150px)");
    this.setState({ isWideView: mediaMatcher.matches });
    mediaMatcher.addListener(e => {
      this.setState({ isWideView: e.matches });
    });
  }

  handleToggleEditTask = () => {
    this.setState({
      isTaskEditOpen: !this.state.isTaskEditOpen
    });
  };

  handleRemoveDialogClose = () => {
    this.setState({ isRemoveTaskOpen: !this.state.isRemoveTaskOpen });
  };

  handleDataChange = (fieldName, value, isData) => {
    const { id, onDataChange, data } = this.props;
    let newData = { ...data };
    isData ? (newData.data[fieldName] = value) : (newData[fieldName] = value);
    onDataChange(id, newData);
  };

  handleTaskDataChange = taskData => {
    const { id, onDataChange } = this.props;
    onDataChange(id, taskData);
  };

  handleEdgesLeftAmountChange = (value) => {
    const { id, onDataChange, data } = this.props;
    const newData = { ...data };
    newData.data = edgesLeftAmountChange(newData.data, value);
    onDataChange(id, newData);
  }

  handleEdgesRightAmountChange = (value) => {
    const { id, onDataChange, data } = this.props;
    const newData = { ...data };
    newData.data = edgesRightAmountChange(newData.data, value);
    onDataChange(id, newData);
  }

  handleEdgesTopAmountChange = (value) => {
    const { id, onDataChange, data } = this.props;
    const newData = { ...data };
    newData.data = edgesTopAmountChange(newData.data, value);
    onDataChange(id, newData);
  }

  handleEdgesBottomAmountChange = (value) => {
    const { id, onDataChange, data } = this.props;
    const newData = { ...data };
    newData.data = edgesBottomAmountChange(newData.data, value);
    onDataChange(id, newData);
  }

  wrapText = (text, noOfChars) => {
    if (text.length <= noOfChars) {
      return <span>{text ? text : '\u200C'}</span>;
    } else {
      const abbr = `${text.substring(0, noOfChars)}...`;
      return <span title={text}>{abbr}</span>;
    }
  };

  render() {
    const {
      data,
      errors,
      id,
      onRemoveTask,
      unitsList,
      getMainMaterialsList,
      mainMaterials,
      getSurfaceMaterialsList,
      surfaceMaterials,
      materials,
      modifiable,
      t
    } = this.props;
    const {
      isTaskEditOpen,
      isRemoveTaskOpen,
    } = this.state;

    const initialMaterial = data.data.material_id ? materials.find(item => item.id === data.data.material_id) : null;
    const initialLeftEdgeMaterial = data.data.edge_left_material_id ? materials.find(item => item.id === data.data.edge_left_material_id) : null;
    const initialRightEdgeMaterial = data.data.edge_right_material_id ? materials.find(item => item.id === data.data.edge_right_material_id) : null;
    const initialTopEdgeMaterial = data.data.edge_top_material_id ? materials.find(item => item.id === data.data.edge_top_material_id) : null;
    const initialBottomEdgeMaterial = data.data.edge_bottom_material_id ? materials.find(item => item.id === data.data.edge_bottom_material_id) : null;
    const initialTopSurfaceMaterial = data.data.surface_top_material_id ? materials.find(item => item.id === data.data.surface_top_material_id) : null;
    const initialBottomSurfaceMaterial = data.data.surface_bottom_material_id ? materials.find(item => item.id === data.data.surface_bottom_material_id) : null;
    const initialTopCoveringMaterial = data.data.covering_top_material_id ? materials.find(item => item.id === data.data.covering_top_material_id) : null;
    const initialBottomCoveringMaterial = data.data.covering_bottom_material_id ? materials.find(item => item.id === data.data.covering_bottom_material_id) : null;

    const mainMaterialsActual = mainMaterials.map(el => ({
      ...el,
      value: el.code,
    }));
    const surfaceMaterialsActual = surfaceMaterials.map(el => ({
      ...el,
      value: el.code,
    }));
    return (
      <GridContainer className="task-data-container">
        <GridItem className="task-data-item tdi-child-one" xs={2}>
          <div className="item-with-title task-name">
            <span className="assignee-title">{ t('item_name') }</span>
            <Input
              className="task-name"
              onChange={(e) => this.handleDataChange('name', e.target.value, false)}
              value={data.name}
              error={errors && errors.name}
              readOnly={!modifiable}
            />
          </div>
        </GridItem>
        <GridItem className="task-data-item tdi-child-one" xs={1}>
          <div className="item-with-title">
            <span className="assignee-title">{ t('material') }</span>
            <Autocomplete
              className='task-material'
              buttonText={data.data.material_id ? data.data.material_id : ''}
              buttonProps={{className: '', round: true, size: "sm", disabled: !modifiable}}
              dropdownList={mainMaterialsActual}
              error={errors && errors['data.material_id']}
              hoverColor="dark"
              getData={getMainMaterialsList}
              onClick={(value) => this.handleDataChange('material_id', value.id, true)}
              placeholder={'\u00A0'}
              initialText={initialMaterial ? initialMaterial.value : ''}
              discardIcon={true}
            />
          </div>
        </GridItem>
        <GridItem className="task-data-item" xs={this.state.isWideView ? 1 : 2}>
          <GridItem className="task-data-item tdi-child-one" xs={6}>
            <div className="item-with-title">
              <span className="amount-title">{ t('amount') }</span>
              <Input
                className="task-amount"
                onChange={(e) => this.handleDataChange('amount', e.target.value, true)}
                value={data.data.amount}
                error={errors && errors['data.amount']}
                readOnly={!modifiable}
              />
            </div>
          </GridItem>
          <GridItem className="task-data-item tdi-child-one" xs={6}>
            <div className="item-with-title">
              <span className="assignee-title">{ t('me') }</span>
              <Dropdown
                className='task-me'
                buttonText={data.data.unit ? data.data.unit : ''}
                buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                dropdownList={unitsList}
                error={errors && errors['data.unit']}
                disabled={!modifiable}
                hoverColor="dark"
                buttonPlaceholder={'\u00A0'}
                onClick={(value) => this.handleDataChange('unit', value.id, true)}
              />
            </div>
          </GridItem>
        </GridItem>
        <GridItem className="task-data-item tdi-child-three" xs={this.state.isWideView ? 2 : 3}>
          <InputWithIcon
            className="finished-dimension-l"
            value={data.data.length}
            onChange={(e) => this.handleDataChange('length', e.target.value, true)}
            error={errors && errors.data && errors.data['length']}
            readOnly={!modifiable}
            removePlaceholder={true}
            endAdornment={<InputAdornment position="end">L</InputAdornment>}
          />
          <InputWithIcon
            className="finished-dimension-w"
            value={data.data.width}
            onChange={(e) => this.handleDataChange('width', e.target.value, true)}
            error={errors && errors.data && errors.data['width']}
            readOnly={!modifiable}
            removePlaceholder={true}
            endAdornment={<InputAdornment position="end">W</InputAdornment>}
          />
          <InputWithIcon
            className="finished-dimension-t"
            value={data.data.thickness}
            onChange={(e) => this.handleDataChange('thickness', e.target.value, true)}
            error={errors && errors.data && errors.data['thickness']}
            readOnly={!modifiable}
            removePlaceholder={true}
            endAdornment={<InputAdornment position="end">T</InputAdornment>}
          />
        </GridItem>
        <GridItem className="task-data-item" xs={this.state.isWideView ? 5 : 3}>
          <GridItem className="task-data-item tdi-child-four" xs={this.state.isWideView ? 6 : 12}>
            <div className="item-with-title">
              { this.wrapText(initialLeftEdgeMaterial ? initialLeftEdgeMaterial.value : '', 6) }
              <InputWithIcon
                className="edges-l"
                value={data.data.edge_left_amount}
                onChange={(e) => this.handleEdgesLeftAmountChange(e.target.value)}
                error={errors && errors.data && errors.data['edge_left_amount']}
                readOnly={!modifiable}
                removePlaceholder={true}
                endAdornment={<InputAdornment position="end">L</InputAdornment>}
              />
            </div>
            <div className="item-with-title">
              { this.wrapText(initialRightEdgeMaterial ? initialRightEdgeMaterial.value : '', 6) }
              <InputWithIcon
                className="edges-r"
                value={data.data.edge_right_amount}
                onChange={(e) => this.handleEdgesRightAmountChange(e.target.value)}
                error={errors && errors.data && errors.data['edge_right_amount']}
                readOnly={!modifiable}
                removePlaceholder={true}
                endAdornment={<InputAdornment position="end">R</InputAdornment>}
              />
            </div>
            <div className="item-with-title">
              { this.wrapText(initialTopEdgeMaterial ? initialTopEdgeMaterial.value : '', 6) }
              <InputWithIcon
                className="edges-r"
                value={data.data.edge_top_amount}
                onChange={(e) => this.handleEdgesTopAmountChange(e.target.value)}
                error={errors && errors.data && errors.data['edge_top_amount']}
                readOnly={!modifiable}
                removePlaceholder={true}
                endAdornment={<InputAdornment position="end">T</InputAdornment>}
              />
            </div>
            <div className="item-with-title">
              { this.wrapText(initialBottomEdgeMaterial ? initialBottomEdgeMaterial.value : '', 6) }
              <InputWithIcon
                className="edges-b"
                value={data.data.edge_bottom_amount}
                onChange={(e) => this.handleEdgesBottomAmountChange(e.target.value)}
                error={errors && errors.data && errors.data['edge_bottom_amount']}
                readOnly={!modifiable}
                removePlaceholder={true}
                endAdornment={<InputAdornment position="end">B</InputAdornment>}
              />
            </div>
          </GridItem>
          { this.state.isWideView && <>
            <GridItem className="task-data-item tdi-child-two" xs={3}>
              <div className="item-with-title">
                { this.wrapText('', 6) }
                <Autocomplete
                  buttonText={data.data.surface_top_material_id ? data.data.surface_top_material_id : ''}
                  initialText={initialTopSurfaceMaterial ? initialTopSurfaceMaterial.value : ''}
                  buttonProps={{className: '', round: true, size: "sm", disabled: !modifiable}}
                  className="surface-t"
                  dropdownList={surfaceMaterialsActual}
                  getData={getSurfaceMaterialsList}
                  hoverColor="dark"
                  disableTabFocus={true}
                  discardIcon={true}
                  placeholder={'\u00A0'}
                  endAdornment={<InputAdornment position="end">T</InputAdornment>}
                  onClick={(value) => this.handleDataChange('surface_top_material_id', value.id, true)}
                  error={errors && errors['data.surface_top_material_id']}
                />
              </div>
              <div className="item-with-title">
                { this.wrapText('', 6) }
                <Autocomplete
                  buttonText={data.data.surface_bottom_material_id ? data.data.surface_bottom_material_id : ''}
                  initialText={initialBottomSurfaceMaterial ? initialBottomSurfaceMaterial.value : ''}
                  buttonProps={{className: '', round: true, size: "sm", disabled: !modifiable}}
                  className="surface-b"
                  dropdownList={surfaceMaterialsActual}
                  getData={getSurfaceMaterialsList}
                  hoverColor="dark"
                  disableTabFocus={true}
                  discardIcon={true}
                  placeholder={'\u00A0'}
                  endAdornment={<InputAdornment position="end">B</InputAdornment>}
                  onClick={(value) => this.handleDataChange('surface_bottom_material_id', value.id, true)}
                  error={errors && errors['data.surface_bottom_material_id']}
                />
              </div>
              </GridItem>
              <GridItem className="task-data-item tdi-child-two" xs={3}>
                <div className="item-with-title">
                  { this.wrapText(initialTopCoveringMaterial ? initialTopCoveringMaterial.value : '', 6) }
                  <InputWithIcon
                    className="covering-t"
                    value={data.data.covering_top_amount}
                    onChange={(e) => this.handleDataChange('covering_top_amount', e.target.value, true)}
                    error={errors && errors.data && errors.data['covering_top_amount']}
                    readOnly={!modifiable}
                    removePlaceholder={true}
                    endAdornment={<InputAdornment position="end">T</InputAdornment>}
                  />
                </div>
                <div className="item-with-title">
                  { this.wrapText(initialBottomCoveringMaterial ? initialBottomCoveringMaterial.value : '', 6) }
                  <InputWithIcon
                    className="covering-b"
                    value={data.data.covering_bottom_amount}
                    onChange={(e) => this.handleDataChange('covering_bottom_amount', e.target.value, true)}
                    error={errors && errors.data && errors.data['covering_bottom_amount']}
                    readOnly={!modifiable}
                    removePlaceholder={true}
                    endAdornment={<InputAdornment position="end">B</InputAdornment>}
                  />
                </div>
              </GridItem>
            </>
          }
        </GridItem>
        <GridItem className="task-data-item edges-structure-grid-container" xs={1}>
          <EdgesStructure
            edge_left_amount={data.data.edge_left_amount}
            edge_right_amount={data.data.edge_right_amount}
            edge_top_amount={data.data.edge_top_amount}
            edge_bottom_amount={data.data.edge_bottom_amount}
            edge_left_top_corner={data.data.edge_left_top_corner}
            edge_right_top_corner={data.data.edge_right_top_corner}
            edge_left_bottom_corner={data.data.edge_left_bottom_corner}
            edge_right_bottom_corner={data.data.edge_right_bottom_corner}
            panel_type={data.data.panel_type}
            onChange={(fieldName, value) => this.handleDataChange(fieldName, value, true)}
          />
        </GridItem>
        <GridItem className="task-action-btn" xs={1}>
          { modifiable && (
            <IconButton
              aria-label="close"
              className="action-btn"
              disabled={!modifiable}
              onClick={this.handleRemoveDialogClose}
            >
              <img alt="remove-position-task-btn" src={DeleteIcon} />
            </IconButton>
          )}
          { modifiable && (
            <IconButton
              aria-label="close"
              className="action-btn"
              disabled={!modifiable}
              onClick={this.handleToggleEditTask}
            >
              <img alt="edit-position-task-btn" src={EditIcon} />
            </IconButton>
          )}
        </GridItem>
        {isTaskEditOpen && (
          <CreateTask
            taskId={data.id ? data.id : id}
            preventPersistId={!data.id}
            taskData={data}
            isOpen={isTaskEditOpen}
            onClose={this.handleToggleEditTask}
            onTaskDataSave={this.handleTaskDataChange}
          />
        )}
        {isRemoveTaskOpen && (
          <RemoveDialog
            isOpen={isRemoveTaskOpen}
            title={t('remove_item')}
            text={t('remove_item_confirmation')}
            onClose={this.handleRemoveDialogClose}
            focusRemoveButton={true}
            onRemove={() => {
              this.handleRemoveDialogClose();
              onRemoveTask();
            }
            }
          />
        )}
      </GridContainer>
    );
  }
}

const mapStateToProp = state => {
  return {
    unitsList: state.providers.taskUnitList,
    materials: state.providers.materialsList,
    mainMaterials: state.materialProviders.mainMaterials,
    surfaceMaterials: state.materialProviders.surfaceMaterials,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMainMaterialsList: (term) => dispatch(getMainMaterialsList(term)),
    getSurfaceMaterialsList: (term) => dispatch(getSurfaceMaterialsList(term)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(PositionTask));
