export const REGISTER_MANUFACTURER = 'user/REGISTER_MANUFACTURER';
export const REGISTER_MANUFACTURER_SUCCESS = 'user/REGISTER_MANUFACTURER_SUCCESS';
export const REGISTER_MANUFACTURER_FAILURE = 'user/REGISTER_MANUFACTURER_FAILURE';
export const RESET_REGISTER_DATA = 'user/RESET_REGISTER_DATA';

export const GET_PROFILE_DETAILS = 'user/GET_PROFILE_DETAILS';
export const GET_PROFILE_DETAILS_SUCCESS = 'user/GET_PROFILE_DETAILS_SUCCESS';
export const GET_PROFILE_DETAILS_FAILURE = 'user/GET_PROFILE_DETAILS_FAILURE';

export const GET_USER_OWN = 'user/GET_USER_OWN';
export const GET_USER_OWN_SUCCESS = 'user/GET_USER_OWN_SUCCESS';
export const GET_USER_OWN_FAILURE = 'user/GET_USER_OWN_FAILURE';
