import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { getNumberFormatSeparators, formatNumericValue } from 'utils/utils';
import './style.scss';

class DecimalInput extends React.Component {
  constructor(props) {
    super(props);
    const numberSeparators = getNumberFormatSeparators();
    this.state = {
      decimalSeparator: numberSeparators.decimalSeparator,
      groupSeparator: numberSeparators.thousandsSeparator,
    };
  }

  globalizeOutput(value) {
    return (value !== null && value !== undefined && value !== '') ? (value + '').replace(this.state.thousandsSeparator, '').replace(this.state.decimalSeparator, '.') : '';
  }

  localizeInput(value) {
    if (value !== null && value !== undefined && value !== '') {
      const temp = value + '';
      const parts = temp.split('.');
      const integerPart = parseInt(parts[0]);
      if (parts.length > 1 && parts[1]) {
        return `${formatNumericValue(integerPart, 0)}${this.state.decimalSeparator}${parts[1]}`;
      } else if (parts.length > 1) {
        return `${formatNumericValue(integerPart, 0)}${this.state.decimalSeparator}`;
      } else {
        return formatNumericValue(integerPart, 0);
      }
    } else {
      return '';
    }
  }

  render() {
    const {
      className,
      error,
      errorMsg,
      onChange,
      adornment,
      correctAdornmentPadding,
      placeholder,
      disabled,
      value
    } = this.props;
    let containerClassName = `ikt-ap_decimal-inputs${error ? '_error' : ''}${className ? ` ${className}` : ``}${correctAdornmentPadding ? ` ikt-ap_decimal-inputs-padding-correction` : ``}`;
    
    return (
      <div className={`ikt-ap_decimal-input-container${className ? ` ${className}` : ``}`}>
        <div className={containerClassName}>
          <CurrencyInput
            className={`decimal-input${correctAdornmentPadding ? ' decimal-input-padding-correction' : ''}`}
            value={this.localizeInput(value)}
            allowDecimals={true}
            decimalsLimit={2}
            placeholder={placeholder}
            readOnly={disabled}
            decimalSeparator={this.state.decimalSeparator}
            groupSeparator={this.state.groupSeparator}
            onChange={(value, name) => onChange && onChange(this.globalizeOutput(value))}
          />
          <div className={`decimal-input-adornment${correctAdornmentPadding ? ' adornment-padding-correction' : ''}`}>{adornment ? adornment : ''}</div>
        </div>
        {errorMsg && <span className="ikt-ap_decimal-inputs-error-msg">{errorMsg}</span>}
      </div>
    );
  }
}

export default DecimalInput;
