import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ProjectTabs from '../../components/ProjectTabs/ProjectTabs';

import ListView from './List';
import Overview from './Overview';
import ManualTimer from './ManualTimer';

import {
  AllTimeSheetDateTabs,
  TimeSheetDateTab,
  AllTimeSheetTabs,
  TimeSheetTab,
  MonthNames,
} from 'constants/constants';

import {
  getHoursLogged,
  getOrdersList,
  getCostCentersList,
} from 'store/actions/timeSheetsProviders';

import { getCoworkersList } from 'store/actions/providers';

import './style.scss';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

class TimesheetsList extends React.Component {
  constructor(props) {
    super(props);

    const dateFrom = moment().startOf('isoweek').toDate(),
      dateTo = moment().endOf('isoweek').toDate();
    this.state = {
      selectedDateTabId: TimeSheetDateTab.WEEK,
      selectedTabId: TimeSheetTab.OVERVIEW,
      currentDateFrom: dateFrom,
      currentDateTo: dateTo,
      weekDateFrom: dateFrom,
      weekDateTo: dateTo,
      monthDate: moment(dateTo).startOf('month').toDate(),
      isDatePickerOpen: false,
      isManualTimerOpen: false,
    };
  }

  componentDidMount() {
    this.props.getHoursLogged(this.state.currentDateFrom, this.state.currentDateTo);
    this.props.getCoworkers();
    this.props.getOrders();
    this.props.getCostCentersList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.currentDateFrom != this.state.currentDateFrom || prevState.currentDateTo != this.state.currentDateTo) {
      this.props.getHoursLogged(this.state.currentDateFrom, this.state.currentDateTo);
    }
  }

  onTabItemClick = id => {
    this.setState({
      selectedTabId: id,
    });
  };

  onDateTabItemClick = id => {
    const { weekDateFrom, weekDateTo, monthDate } = this.state;
    let newDateFrom, newDateTo;
    if (id === TimeSheetDateTab.WEEK) {
      newDateFrom = weekDateFrom;
      newDateTo = weekDateTo;
    } else {
      newDateFrom = moment(monthDate).startOf('month').toDate();
      newDateTo = moment(monthDate).endOf('month').toDate();
    }
    this.setState({
      selectedDateTabId: id,
      currentDateFrom: newDateFrom,
      currentDateTo: newDateTo,
    });
  };

  handleChangeDate = () => {
    this.setState({
      isDatePickerOpen: true,
    });
  }

  handleChangeDateCommit = (date) => {
    const { selectedDateTabId } = this.state;
    let newDateFrom, newDateTo;
    if (selectedDateTabId === TimeSheetDateTab.WEEK) {
      newDateFrom = moment(date).startOf('isoweek').toDate();
      newDateTo = moment(date).endOf('isoweek').toDate();
      this.setState({
        currentDateFrom: newDateFrom,
        currentDateTo: newDateTo,
        weekDateFrom: newDateFrom,
        weekDateTo: newDateTo,
      });
    } else {
      newDateFrom = moment(date).startOf('month').toDate();
      newDateTo = moment(date).endOf('month').toDate();
      this.setState({
        currentDateFrom: newDateFrom,
        currentDateTo: newDateTo,
        monthDate: newDateFrom,
      });
    }
  }

  handleChangeDateClose = () => {
    this.setState({
      isDatePickerOpen: false,
    });
  }

  handleLogTime = () => {
    this.setState({
      isManualTimerOpen: true,
    });
  }

  handleCloseManualTimer = () => {
    this.setState({
      isManualTimerOpen: false,
    });
  }

  render() {
    const {
      hoursLogged,
      t
    } = this.props;
    const {
      currentDateFrom,
      currentDateTo,
      selectedTabId,
      selectedDateTabId,
      isDatePickerOpen,
      isManualTimerOpen,
    } = this.state;

    const generateDateString = () => {
      let label, datePart;
      if (selectedDateTabId === TimeSheetDateTab.WEEK) {
        label = t('week');
        let extraYearFrom, extraYearTo;
        if (currentDateFrom.getFullYear() !== currentDateTo.getFullYear()) {
          extraYearFrom = ` ${currentDateFrom.getFullYear()}`;
          extraYearTo = ` ${currentDateTo.getFullYear()}`;
        } else {
          extraYearFrom = '';
          extraYearTo = new Date().getFullYear() !== currentDateFrom.getFullYear() ? ` ${currentDateFrom.getFullYear()}` : '';
        }
        datePart = `${currentDateFrom.getDate()} ${t(MonthNames[currentDateFrom.getMonth()])}${extraYearFrom} - ${currentDateTo.getDate()} ${t(MonthNames[currentDateTo.getMonth()])}${extraYearTo}`;
      } else {
        label = t('month');
        const extraYear = new Date().getFullYear() !== currentDateFrom.getFullYear() ? ` ${currentDateFrom.getFullYear()}` : '';
        datePart = `${t(MonthNames[currentDateFrom.getMonth()])}${extraYear}`;
      }
      return `${label} ${datePart}`;
    };

    let selectedComponent;
    switch(selectedTabId) {
      case 0:
        selectedComponent =
          <Overview
            onLogTime={this.handleLogTime}
            onViewDetails={() => this.onTabItemClick(TimeSheetTab.LIST)}
          />;
        break;
      case 1:
        selectedComponent =
          <ListView
            timePeriodStart={currentDateFrom}
            timePeriodEnd={currentDateTo}
          />;
        break;
      default:
        return null;
    }
    return (
      <div className="ikt-ap_timesheets-list-container">
        <GridContainer>
          <GridItem className="ikt-ap_timesheets-list-top-wrapper" xs={12}>
            <GridContainer className="ikt-ap_timesheets-list-top-container">
              <GridItem className="ikt-ap_timesheets-list-top-left">
                <span className="time-period" onClick={this.handleChangeDate}>
                  { generateDateString() }
                </span>
              </GridItem>
              <GridItem className="ikt-ap_timesheets-list-top-right">
                <ProjectTabs
                  tabs={AllTimeSheetDateTabs}
                  selectedTab={selectedDateTabId}
                  onTabItemClick={this.onDateTabItemClick}
                />
              </GridItem>
            </GridContainer>
            <GridContainer className="ikt-ap_timesheets-list-hours-container">
              <GridItem className="ikt-ap_timesheets-list-hours">
                <div className="hours-logged">
                  <span className="hours-logged-value">{ hoursLogged !== null && hoursLogged !== undefined ? (hoursLogged !== 0 ? hoursLogged.toFixed(2) : '0') : 'N/A' }</span>
                  <span className="hours-logged-label">{ t('hours_logged') }</span>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem className="timesheets-content" xs={12}>
            <div className="time-sheet-tabs-container">
              <ProjectTabs
                tabs={AllTimeSheetTabs}
                selectedTab={selectedTabId}
                onTabItemClick={this.onTabItemClick}
              />
            </div>
            {selectedComponent}
          </GridItem>
        </GridContainer>
        { isDatePickerOpen &&
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              open={true}
              margin="normal"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={currentDateFrom}
              onChange={this.handleChangeDateCommit}
              onClose={this.handleChangeDateClose}
              TextFieldComponent={() => null}
            />
          </MuiPickersUtilsProvider>
        }
        {
          isManualTimerOpen && 
          <ManualTimer
            isOpen={isManualTimerOpen}
            onClose={this.handleCloseManualTimer}
          />
        }
      </div>
    );
  }
}

TimesheetsList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    hoursLogged: state.timeSheetsProviders.hoursLogged,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCoworkers: () => dispatch(getCoworkersList()),
    getOrders: () => dispatch(getOrdersList()),
    getCostCentersList: () => dispatch(getCostCentersList()),
    getHoursLogged: (dateFrom, dateTo) => dispatch(getHoursLogged(dateFrom, dateTo)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(TimesheetsList)));
