import React from 'react';
import { withTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import MaterialIconSimple from '../../assets/img/material_icon_simple.svg';
import CloseButton from '../../assets/img/buttons/delete.svg';
import ConfirmDialog from "../ConfirmDialog";

import GridContainer from 'components/Grid/GridContainer.jsx';

import './style.scss';
import { connect } from 'react-redux';

import {
  getMaterials,
  setMaterialAvailability,
  removeMaterial,
  duplicateMaterial,
  removeCompoundMaterial,
  updateCompoundMaterial,
  getMaterialsInBundles,
  resetMaterialDuplicateData
} from '../../store/actions/materials';
import ViewIcon from '../../assets/img/buttons/eye.svg';
import DeleteIcon from '../../assets/img/buttons/delete.svg';
import DuplicateIcon from '../../assets/img/buttons/btn-copy.svg';
import EditIcon from '../../assets/img/buttons/edit.svg';
import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import RemoveDialog from '../RemoveDialog';
import ViewMaterial from '../ViewMaterial';
import CreateMaterial from '../CreateMaterial';
import CreateCompoundMaterial from '../CreateCompoundMaterial';
import { convertToFormData, calculateMaterialRetailPrice } from '../../utils/utils';

class ViewCompoundMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateMaterialOpen: false,
      isEditCompoundMaterialOpen: false,
      isViewMaterialOpen: false,
      isRemoveMaterialOpen: false,
      isDuplicateMaterialOpen: false,
      duplicatedMaterialId: '',
      deletedMaterialId: '',
      deletedBundleMaterialId: '',
      bundleMaterials: [],
    };
  }

  componentDidMount() {
    this.updateBundlesData();
    const { bundleMaterial } = this.props;
    this.props.getMaterialsInBundles([bundleMaterial.id], true);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.materialsBundles.successes !==
      this.props.materialsBundles.successes
    ) {
      if(Object.keys(this.props.materialsBundles.successes).length === 0) {
        const { bundleMaterial } = this.props;
        this.props.getMaterialsInBundles([bundleMaterial.id], true);
      }
      const {bundleMaterials} = this.state;
        const bundleMats = this.props.materialsBundles.successes[
          this.props.bundleMaterial.id
        ];
        const newSelectedMats =
        bundleMats && bundleMats.length !== 0
            ? [...bundleMats]
            : this.state.bundleMaterials;
        this.setState({
          bundleMaterials: newSelectedMats,
        });
      }
    if (prevProps.isMaterialDuplicated !== this.props.isMaterialDuplicated && this.props.isMaterialDuplicated) {
      this.props.onClose()
    }
  }

  componentWillUnmount() {
    this.props.resetMaterialDuplicateData()
  }

  handleEditMaterial = material => {
    this.setState(
      {
        editMaterial: material
      },
      () => {
        this.handleToggleMaterialChange();
      }
    );
  };

  updateBundlesData = () => {
    const { bundleMaterial } = this.props;
    this.props.getMaterialsInBundles([bundleMaterial.id], true);
  };

  updateMaterialAvailability = (id, value) => {
    const { setMaterialAvailability, currentPage, currentFilters, currentSearchTerms, currentCompoundMaterials } = this.props;
    setMaterialAvailability({ page: currentPage, filters: currentFilters, searchTerms: currentSearchTerms, loadCompoundMaterials: currentCompoundMaterials }, { id, value });
  };

  handleDeleteButton = id => {
    this.setState({
      isRemoveMaterialOpen: true,
      deletedMaterialId: id
    });
  };
  handleViewMaterialDelete = () => {
    const { viewMaterial } = this.state;
    this.handleDeleteButton(viewMaterial.id);
  };

  handleDuplicateButton = (id) => {
    this.setState({
      isDuplicateMaterialOpen: true,
      duplicatedMaterialId: id
    });
  }

  handleDuplicateMaterial = () => {
    const {
      duplicateMaterial,
      searchTerms
    } = this.props;
    const {
      currentPage,
      filters
    } = this.state;
    const {duplicatedMaterialId} = this.state;
    duplicateMaterial(duplicatedMaterialId);
    this.handleDuplicateDialogClose()
  }


  handleDuplicateDialogClose = () => {
    this.setState({
      isDuplicateMaterialOpen: false,
    });
  }

  handleDeleteBundleButton = id => {
    this.setState({
      isRemoveMaterialOpen: true,
      deletedBundleMaterialId: id
    });
  };

  handleRemoveMaterial = () => {
    const { removeMaterial } = this.props;
    const { deletedMaterialId } = this.state;
    if (!isNaN(deletedMaterialId)) {
      removeMaterial(deletedMaterialId, this.props.purge, { page: this.props.currentPage, filters: this.props.currentFilters, searchTerms: this.props.currentSearchTerms, loadCompoundMaterials: this.props.currentCompoundMaterials });
      this.setState({ viewMaterial: null, isViewMaterialOpen: false });
      this.props.onClose();
    } else {
      const { materialsBundles, updateCompoundMaterial } = this.props;
      const materialBundle = [
        ...materialsBundles.successes[deletedMaterialId.compoundMaterial.id]
      ];
      const bundleToRemove = materialBundle.find(
        el => el.id == deletedMaterialId.bundleMaterialId
      );
      const indexOfBundleToRemove = materialBundle.indexOf(bundleToRemove);
      materialBundle.splice(indexOfBundleToRemove, 1);
      for (let i in materialBundle) {
        materialBundle[i].value = materialBundle[i].usage;
      }

      const data = {
        ...deletedMaterialId.compoundMaterial,
        category_id:
          deletedMaterialId.compoundMaterial.category.id,
        material_meta: deletedMaterialId.compoundMaterial.material_meta
          ? deletedMaterialId.compoundMaterial.material_meta[0]
          : null,
        materials: materialBundle
      };
      const formData = convertToFormData(data);
      updateCompoundMaterial({ page: this.props.currentPage, filters: this.props.currentFilters, searchTerms: this.props.searchTerms, loadCompoundMaterials: this.props.currentCompoundMaterials }, { id: deletedMaterialId.compoundMaterial.id, body: formData });
      this.updateBundlesData();
    }

    this.handleRemoveDialogClose();
  };

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveMaterialOpen: false
    });
  };
  handleViewMaterial = material => {
    this.setState(
      {
        viewMaterial: material
      },
      () => {
        this.handleToggleViewMaterial();
      }
    );
  };
  handleToggleViewMaterial = () => {
    if (this.state.isViewMaterialOpen) {
      this.setState({ isViewMaterialOpen: false, viewMaterial: null });
    } else {
      this.setState({ isViewMaterialOpen: true });
    }
  };
  handleToggleEditCompoundMaterial = () => {
    this.setState({
      isEditCompoundMaterialOpen: !this.state.isEditCompoundMaterialOpen
    });
  };
  handleViewMaterialEdit = () => {
    this.setState({
      isViewMaterialOpen: false,
      viewMaterial: null,
      editMaterial: this.state.viewMaterial,
      isCreateMaterialOpen: true
    });
  };
  handleToggleMaterialChange = () => {
    if (this.state.isCreateMaterialOpen) {
      this.setState({ isCreateMaterialOpen: false, editMaterial: null });
    } else {
      this.setState({ isCreateMaterialOpen: true });
    }
  };

  handleToggleCompoundMaterialChange = () => {
    this.setState({
      isEditCompoundMaterialOpen: !this.state.isEditCompoundMaterialOpen
    });
  };
  renderMaterialsList = () => {
    const bundleMaterials = this.props.materialsBundles.successes[
      this.props.bundleMaterial.id
    ];
    if (bundleMaterials) {
      return (
        <>
          {bundleMaterials &&
            bundleMaterials.map(material => this.renderMaterialItem(material))}
        </>
      );
    } else {
      return null;
    }
  };
  
  getMaterialRetailPrice = material => {
    const { currencySymbol } = this.props;
    const formatPrice = price => {
      return (Math.round((price + Number.EPSILON) * 1000) / 1000)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    let purchasingPrice = null;
    if (
      material.material_price &&
      material.material_price[0] &&
      !isNaN(material.material_price[0].price)
    )
      purchasingPrice = material.material_price[0].price;
    else purchasingPrice = material.default_price;

    let retailPrice = null;
    if (purchasingPrice && !isNaN(purchasingPrice)) {
      retailPrice = calculateMaterialRetailPrice(purchasingPrice, parseFloat(material.discount_percentage), parseFloat(material.surcharge_percentage), parseFloat(material.waste_percentage));
    }
    if (retailPrice && !isNaN(retailPrice)) {
      return `${currencySymbol} ${formatPrice(retailPrice)}`;
    } else {
      return 'N/A';
    }
  };

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return <span>{text}</span>;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  renderMaterialItem = material => {
    const { bundleMaterial, t } = this.props;
    let brands = null;
    if (material.brands) {
      brands = material.brands.map((el, index) => (
        <span key={index} className="brands-item">{el}</span>
      ));
    }
    let suppliers = null;
    if (material.suppliers) {
      suppliers = material.suppliers.map((el, index) => (
        <span key={index} className="suppliers-item">{el}</span>
      ));
    }
    return (
      <GridContainer className="material-item" direction="row" xs={12}>
        <GridItem className="material-item-title" xs={2}>
          {this.wrapText(material.code,14)}
        </GridItem>
        <GridItem className="material-item-title bold" xs={2}>
          {this.wrapText(material.name,18)}
        </GridItem>
        <GridItem className="material-item-title" xs={1}>
          { material.category ? this.wrapText(material.category.name, 10) : '' }
        </GridItem>
        <GridItem className="material-item-title" xs={1}>
          {brands}
        </GridItem>
        <GridItem className="material-item-title" xs={1}>
          {this.getMaterialRetailPrice(material)}
        </GridItem>
        <GridItem
          className={
            'material-item-title availability-dropdown mat-grid-col-availability ' +
            (material.in_stock == 1 ? 'dropdown-green' : 'dropdown-red')
          }
          xs={2}
        >
            <Dropdown
            buttonText={material.in_stock == 1 ? 0 : 1}
            buttonIconType="white"
            buttonProps={{
              className: `dropdown-blue-style status-dropdown ${material.in_stock ? 'dropdown-green' : 'dropdown-red'}`,
              round: true,
              size: 'sm'
            }}
            hoverColor="dark"
            dropdownList={[
              { id: 0, value: t('in_stock') },
              { id: 1, value: t('out_of_stock') }
            ]}
            disabled={this.props.purge}
            onClick={data =>
              this.updateMaterialAvailability(material.id, data.id == 0 ? 1 : 0)
            }
          />
        </GridItem>
        <GridItem className="material-item-title" xs={2}>
          {material.supplier}
        </GridItem>
        <GridItem className="material-item-title action-btn-container">
            <IconButton
              aria-label="close"
              className="action-btn"
              onClick={() => this.handleViewMaterial(material)}
            >
              <img alt="material-view-btn" src={ViewIcon} />
            </IconButton>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const {
      isOpen,
      onClose,
      id,
      bundleMaterial,
      updateCompoundMaterial,
      categories,
      materials,
      t
    } = this.props;
    const {
      isCreateMaterialOpen,
      isEditCompoundMaterialOpen,
      isViewMaterialOpen,
      isRemoveMaterialOpen,
      isDuplicateMaterialOpen,
      deletedMaterialId,
      bundleMaterials
    } = this.state;
    const amount = bundleMaterials
      ? bundleMaterials.reduce((sum, mat) => sum + mat.usage, 0)
      : 0;
      const bundleMats = this.props.materialsBundles.successes[
        this.props.bundleMaterial.id
      ];
      let total = 0;
      if(bundleMats) {
         bundleMats.map(mat => {
          total+= mat.usage;
        });
      }
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <div className="view-compound-material-container">
          <MuiDialogTitle className="view-material-title" disableTypography>
            <Typography variant="h6" className="dialog-title">{ t('view_compound_material') }</Typography>
            {onClose ? (
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <img alt="view-category-close-btn" src={CloseButton} />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <div className="view-category-content">
            <div className="category-title-container">
              <GridItem xs={6}>
                <div className="category-name">{bundleMaterial.name}</div>
              </GridItem>
              <GridItem xs={6}>
                <div className="view-category-btn">
                  <Button
                    className="cancel-btn"
                    onClick={() => this.handleDeleteButton(bundleMaterial.id)}
                  >
                    { t('delete').toUpperCase() }
                  </Button>
                  <Button
                    className="duplicate-btn"
                    onClick={() => this.props.onDuplicate(bundleMaterial.id)}
                    disabled={this.props.purge}
                  >
                    { t('duplicate').toUpperCase() }
                  </Button>
                  <Button
                    className="create-btn"
                    onClick={() =>
                      this.props.onEdit()
                    }
                    disabled={this.props.purge}
                  >
                    { t('edit').toUpperCase() }
                  </Button>
                </div>
              </GridItem>
            </div>
            <div className="category-data-container">
              <div className="category-info-field">
                <div className="category-info-item">
                  <GridItem xs={5} className="category-info-title">
                    { t('material_short_name') }
                  </GridItem>
                  <GridItem xs={7}>{bundleMaterial.code}</GridItem>
                </div>
                <div className="category-info-item">
                  <GridItem xs={5} className="category-info-title">
                    { t('category') }
                  </GridItem>
                  <GridItem xs={7}>
                    {' '}
                    {bundleMaterial.category ? bundleMaterial.category.name : ''}
                  </GridItem>
                </div>
              </div>
              <div className="category-info-field">
                <div className="category-info-item">
                  <GridItem xs={4} className="category-info-title">
                    { t('description') }
                  </GridItem>
                  <GridItem xs={8}>{bundleMaterial.description}</GridItem>
                </div>
              </div>
            </div>
            <div className="category-data-container">
              <GridContainer className="materials-content ">
                <div className="materials-list-title">{ t('compound_materials') }</div>
                {this.renderMaterialsList(bundleMaterials)}
              </GridContainer>
            </div>
          </div>
          <div className="category-footer-container">
            <div className="footer-info">
              <div className="footer-info-item">
                <GridItem className="footer-info-title">
                  {t('total')} ({bundleMaterial.metric})
                </GridItem>
                <GridItem>{total}</GridItem>
              </div>
              <div className="footer-info-item">
                <GridItem className="footer-info-title">{t('overspray')}</GridItem>
                <GridItem>{bundleMaterial.over_spray || 0} </GridItem>
              </div>
            </div>
          </div>
          {isViewMaterialOpen && (
            <ViewMaterial
              isOpen={isViewMaterialOpen}
              onClose={this.handleToggleViewMaterial}
              onDelete={this.handleViewMaterialDelete}
              onDuplicate={this.handleDuplicateButton}
              onEdit={this.handleViewMaterialEdit}
              currentPage={1}
              material={this.state.viewMaterial}
              purge={this.props.purge}
              availabilityOptions={[
                { id: 0, value: t('in_stock') },
                { id: 1, value: t('out_of_stock') }
              ]}
            />
          )}
        {isDuplicateMaterialOpen && (
          <ConfirmDialog
            isOpen={isDuplicateMaterialOpen}
            title={t('duplicate_material')}
            text={t('duplicate_material_confirmation')}
            onClose={this.handleDuplicateDialogClose}
            onConfirm={this.handleDuplicateMaterial}
          />
        )}
          {isRemoveMaterialOpen && (
            <RemoveDialog
              isOpen={isRemoveMaterialOpen}
              title={t('remove_material')}
              text={t('remove_material_confirmation')}
              onClose={this.handleRemoveDialogClose}
              onRemove={this.handleRemoveMaterial}
            />
          )}
          {isCreateMaterialOpen && (
            <CreateMaterial
              isOpen={isCreateMaterialOpen}
              onClose={this.handleToggleMaterialChange}
              categories={this.props.categories}
              edit={this.state.editMaterial}
              categories={this.props.categories}
            />
          )}
          {isEditCompoundMaterialOpen && (
            <CreateCompoundMaterial
              isOpen={isEditCompoundMaterialOpen}
              onClose={this.handleToggleCompoundMaterialChange}
              categories={this.props.categories}
              materials={this.props.materials}
              editMaterial={bundleMaterial}
            />
          )}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    materials: state.materials.materials,
    materialsBundles: state.materials.materialsBundles,
    currencySymbol: state.globals.currencySymbol,
    metaData: state.materials.metaData,
    isMaterialDuplicated: state.materials.isMaterialDuplicated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMaterials: page => dispatch(getMaterials(page)),
    setMaterialAvailability: (page, data) =>
      dispatch(setMaterialAvailability(page, data)),
    removeMaterial: (data, purge, loadListInfo) =>
      dispatch(removeMaterial(data, purge, loadListInfo)),
      duplicateMaterial: (page, data) =>
      dispatch(duplicateMaterial(page, data)),
    removeCompoundMaterial: data => dispatch(removeCompoundMaterial(data)),
    updateCompoundMaterial: (loadListInfo, data) =>
      dispatch(updateCompoundMaterial(loadListInfo, data)),
    getMaterialsInBundles: (ids, keepCurrent) =>
      dispatch(getMaterialsInBundles(ids, keepCurrent)),
    resetMaterialDuplicateData: () => dispatch(resetMaterialDuplicateData()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ViewCompoundMaterial));
