import React from 'react';

import { connect } from 'react-redux';
import {getTaskUnitList, getMaterialsList} from "../../store/actions/providers";

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Position from './TasksPosition';

import { checkArraySetEquality } from 'utils/utils';

import './style.scss';

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandFirstCard: true,
    };
  }

  buildMaterialsToRetrieve = (positions) => {
    const materialsToRetrieve = [];
    positions.forEach((position, index) => {
      for (let i = 0, len = position.tasks.length; i < len; ++i) {
        const task = position.tasks[i];
        if (task.data.material_id && materialsToRetrieve.indexOf(task.data.material_id) == -1) {
          materialsToRetrieve.push(task.data.material_id);
        }
        if (task.data.edge_left_material_id && materialsToRetrieve.indexOf(task.data.edge_left_material_id) == -1) {
          materialsToRetrieve.push(task.data.edge_left_material_id);
        }
        if (task.data.edge_right_material_id && materialsToRetrieve.indexOf(task.data.edge_right_material_id) == -1) {
          materialsToRetrieve.push(task.data.edge_right_material_id);
        }
        if (task.data.edge_top_material_id && materialsToRetrieve.indexOf(task.data.edge_top_material_id) == -1) {
          materialsToRetrieve.push(task.data.edge_top_material_id);
        }
        if (task.data.edge_bottom_material_id && materialsToRetrieve.indexOf(task.data.edge_bottom_material_id) == -1) {
          materialsToRetrieve.push(task.data.edge_bottom_material_id);
        }
        if (task.data.surface_top_material_id && materialsToRetrieve.indexOf(task.data.surface_top_material_id) == -1) {
          materialsToRetrieve.push(task.data.surface_top_material_id);
        }
        if (task.data.surface_bottom_material_id && materialsToRetrieve.indexOf(task.data.surface_bottom_material_id) == -1) {
          materialsToRetrieve.push(task.data.surface_bottom_material_id);
        }
        if (task.data.covering_top_material_id && materialsToRetrieve.indexOf(task.data.covering_top_material_id) == -1) {
          materialsToRetrieve.push(task.data.covering_top_material_id);
        }
        if (task.data.covering_bottom_material_id && materialsToRetrieve.indexOf(task.data.covering_bottom_material_id) == -1) {
          materialsToRetrieve.push(task.data.covering_bottom_material_id);
        }
      }
    });
    return materialsToRetrieve;
  }

  componentDidMount() {
    const { getTaskUnitList, getMaterials, positionsData } = this.props;
    getTaskUnitList();
    
    const materialsToRetrieve = this.buildMaterialsToRetrieve(positionsData);
    getMaterials(materialsToRetrieve);

    const wrapper = document.getElementById('ikt-admin-mainPanel');
    wrapper.scrollTop = 0;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.expandFirstCard) {
      this.setState({
        expandFirstCard: false,
      });
    }

    const materials = this.props.materials.map(el => el.id);
    const materialsToRetrieve = this.buildMaterialsToRetrieve(this.props.positionsData);
    if (!checkArraySetEquality(materials, materialsToRetrieve)) {
      this.props.getMaterials(materialsToRetrieve);
    }
  }

  render() {
    const { positionsData, errors, onDataChange, onFileChange } = this.props;
    const { expandFirstCard } = this.state;
    return (
      <GridContainer>
        <GridItem className="tasks-positions-container" xs={12}>
          {
            positionsData.length !== 0 &&
            positionsData.map((position, index) => {
                return(
                  <Position
                    isCardOpen={expandFirstCard && index === 0}
                    key={index}
                    onFileChange={e => onFileChange(e, index)}
                    onDataChange={(data) => onDataChange(index, data)}
                    data={position.tasks}
                    title={position.title}
                    numericName={position.numeric_name}
                    modifiable={position.can_edit}
                    id={index}
                    errors={errors && errors[index]}
                  />
                )
              })
          }
          <div className="list-space-holder">{ '\u00A0' }</div>
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProp = state => {
  return {
    materials: state.providers.materialsList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTaskUnitList: () => dispatch(getTaskUnitList()),
    getMaterials: (data) => dispatch(getMaterialsList(data)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(Tasks);
