import { handleActions } from 'redux-actions';
import {
  GET_PROJECT_CATEGORIES,
  GET_PROJECT_CATEGORIES_SUCCESS,
  GET_PROJECT_CATEGORIES_ERROR
} from 'store/actionTypes/projectCategories';

export const DEFAULT_PROJECT_CATEGORIES_STATE = {
  projectCategories: [],
  projectCategoriesError: null,
  projectCategoriesBusy: false
};

export const projectCategories = handleActions(
  {
    [GET_PROJECT_CATEGORIES]: (state, action) => ({
      ...state,
      projectCategoriesBusy: true
    }),
    [GET_PROJECT_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      projectCategories: action.payload,
      projectCategoriesError: null,
      projectCategoriesBusy: false
    }),
    [GET_PROJECT_CATEGORIES_ERROR]: (state, action) => ({
      ...state,
      projectCategoriesError: action.payload.message,
      projectCategoriesBusy: false
    })
  },
  DEFAULT_PROJECT_CATEGORIES_STATE
);
