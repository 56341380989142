import React from 'react';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import IconButton from '@material-ui/core/IconButton';
import Input from 'components/Input/Input';

import './style.scss';
import CloseButton from "../../../../assets/img/buttons/delete.svg";
import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";
import InputWithIcon from "../../../../components/DecimalInput";

class PositionTask extends React.Component {
  render() {
    const {
      data,
      errors,
      costCenterUnits,
      onDataChange,
      currencySymbol
    } = this.props;
    return (
      <GridContainer className="sub-cost-container">
        <GridItem className="sub-cost-item" xs={7}>
          <Input
            error={errors && errors.title}
            value={data.title}
            onChange={e => onDataChange('title', e.target.value)}
          />
        </GridItem>
        <GridItem className="sub-cost-item" xs={2}>
          <CustomDropdown
            buttonText={
              data.measurement !== null ? data.measurement : ''
            }
            buttonProps={{
              className: 'dropdown-blue-style',
              color: 'transparent',
              round: true,
              size: 'sm'
            }}
            hoverColor="rose"
            dropdownList={costCenterUnits}
            error={errors && errors.measurement}
            onClick={value => onDataChange('measurement', value.id)}
          />
        </GridItem>
        <GridItem className="sub-cost-item" xs={2}>
          <InputWithIcon
            error={errors && errors.rate}
            value={data.rate}
            onChange={value => onDataChange('rate', value)}
            adornment={currencySymbol}
          />
        </GridItem>
        <GridItem className="sub-cost-item" xs={1}>
          <IconButton
            aria-label="close"
            className="sub-cost-remove-btn"
            onClick={() => this.props.onDeleteSubCost()}
          >
            <img alt="sub-cost-delete-btn" src={CloseButton}/>
          </IconButton>
        </GridItem>
      </GridContainer>
    )
  }
}

export default PositionTask;
