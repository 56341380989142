import { handleActions } from 'redux-actions';
import {
  GET_MANUFACTURERS,
  FILTER_MANUFACTURERS,
  ADD_MANUFACTURER,
  ADD_MANUFACTURER_SUCCESS,
  ADD_MANUFACTURER_ERROR,
  UPDATE_MANUFACTURER,
  UPDATE_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER_ERROR,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_ERROR,
  GET_MANUFACTURER,
  GET_MANUFACTURER_SUCCESS,
  GET_MANUFACTURER_ERROR,
  GET_MANUFACTURER_CONTACTS,
  GET_MANUFACTURER_CONTACTS_SUCCESS,
  GET_MANUFACTURER_CONTACTS_FAILURE,
  GET_MANUFACTURER_SETTINGS,
  GET_MANUFACTURER_SETTINGS_SUCCESS,
  GET_MANUFACTURER_SETTINGS_ERROR,
  REMOVE_MANUFACTURER_SUCCESS,
  RESET_MANUFACTURER_DATA
} from 'store/actionTypes/manufacturerActionTypes';

export const DEFAULT_MANUFACTURERS_STATE = {
  manufacturers: [],
  metaData: {},
  manufacturer: {},
  manufacturerError: {},
  isManufacturerValid: false,
  isManufacturerDeleted: false,
  manufacturerContacts: [],
  manufacturerSettings: [],
  manufacturersError: null,
  manufacturersBusy: false,
  manufacturersMutBusy: false,
};

export const manufacturer = handleActions(
  {
    [GET_MANUFACTURERS]: (state, action) => ({
      ...state,
      manufacturersBusy: true
    }),
    [FILTER_MANUFACTURERS]: (state, action) => ({
      ...state,
      manufacturersBusy: true
    }),
    [ADD_MANUFACTURER]: (state, action) => ({
      ...state,
      manufacturersMutBusy: true,
    }),
    [ADD_MANUFACTURER_SUCCESS]: (state, action) => ({
      ...state,
      isManufacturerValid: true,
      manufacturersMutBusy: false,
    }),
    [ADD_MANUFACTURER_ERROR]: (state, action) => ({
      ...state,
      isManufacturerValid: false,
      manufacturerError: action.payload,
      manufacturersMutBusy: false,
    }),
    [UPDATE_MANUFACTURER]: (state, action) => ({
      ...state,
      manufacturersMutBusy: true,
    }),
    [UPDATE_MANUFACTURER_SUCCESS]: (state, action) => ({
      ...state,
      isManufacturerValid: true,
      manufacturersMutBusy: false,
    }),
    [UPDATE_MANUFACTURER_ERROR]: (state, action) => ({
      ...state,
      isManufacturerValid: false,
      manufacturerError: action.payload,
      manufacturersMutBusy: false,
    }),
    [GET_MANUFACTURERS_SUCCESS]: (state, action) => ({
      ...state,
      manufacturers: action.payload.data,
      metaData: action.payload.meta,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURERS_ERROR]: (state, action) => ({
      ...state,
      manufacturersError: action.payload,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER]: (state, action) => ({
      ...state,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER_SUCCESS]: (state, action) => ({
      ...state,
      manufacturer: action.payload,
      manufacturersError: null,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER_ERROR]: (state, action) => ({
      ...state,
      manufacturersError: null,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER_CONTACTS]: (state, action) => ({
      ...state,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER_CONTACTS_SUCCESS]: (state, action) => ({
      ...state,
      manufacturerContacts: action.payload,
      manufacturersError: null,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER_CONTACTS_FAILURE]: (state, action) => ({
      ...state,
      manufacturersError: null,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER_SETTINGS]: (state, action) => ({
      ...state,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      manufacturerSettings: action.payload,
      manufacturersError: null,
      manufacturersBusy: false
    }),
    [GET_MANUFACTURER_SETTINGS_ERROR]: (state, action) => ({
      ...state,
      manufacturersError: null,
      manufacturersBusy: false
    }),
    [REMOVE_MANUFACTURER_SUCCESS]: (state, action) => ({
      ...state,
      isManufacturerDeleted: true
    }),
    [RESET_MANUFACTURER_DATA]: (state, action) => ({
      ...state,
      manufacturer: {},
      isManufacturerDeleted: false,
      isManufacturerValid: false,
      manufacturerError: {}
    })
  },
  DEFAULT_MANUFACTURERS_STATE
);
