import React from "react";
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {getUserOwn} from 'store/actions/auth';
import {getRoles} from 'store/actions/roles';
import {resetUserData, addUser, updateUser} from 'store/actions/usersData';
import {capitalize} from 'utils/utils';

import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Button from '../../components/CustomButtons/Button';
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import Input from "../../components/Input/Input";
import GridItem from "../../components/Grid/GridItem";
import StyledRadio from "../../components/StyledRadioBtn";
import { translationSupportedLanguages } from "constants/constants";

import CloseButton from "../../assets/img/buttons/delete.svg";

import './style.scss';
import {RadioGroup} from "@material-ui/core";
import TextArea from "../../components/CustomTextArea";
import CloseDialog from '../CloseDialog';

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {
        first_name: '',
        last_name: '',
        selected_language: props.i18n && props.i18n.language ? props.i18n.language : 'en',
        email: '',
        phone: '',
        status: 0,
        role_id: '',
        notes: ''
      },

      isCloseDialogOpen: false,
      haveEdits: false,
      shouldRefreshPage: false,
    };
  }

  componentDidMount() {
    if (this.props.user.id) {
      this.init();
    } else {
      if (this.props.userRoles && this.props.userRoles.length > 0) {
        const coworkerRole = this.props.userRoles.filter(el => el.name === 'coworker');
        if (coworkerRole && coworkerRole.length > 0) {
          this.setState({
            userDetails: {
              ...this.state.userDetails,
              role_id: coworkerRole[0].id,
            }
          });
        }
      }
    }
    if (!this.props.userRoles || this.props.userRoles.length == 0) {
      this.props.getRoles();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isUserValid && this.props.isUserValid ) {
      this.props.onClose();
      if (this.state.shouldRefreshPage) {
        setTimeout(() => {
          window.location.reload();
        }, 200);
        this.setState({
          shouldRefreshPage: false,
        });
      }
    }
    if (prevProps.userRoles != this.props.userRoles && this.props.userRoles) {
      const coworkerRole = this.props.userRoles.filter(el => el.name === 'coworker');
      if (coworkerRole && coworkerRole.length > 0) {
        this.setState({
          userDetails: {
            ...this.state.userDetails,
            role_id: coworkerRole[0].id,
          }
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetData();
    if (this.props.user && localStorage.getItem("authUserId") == this.props.user.id) {
      // editing self, reset profile data
      this.props.getUserOwn();
    }
  }

  init = () => {
    const {user} = this.props;
    const updateObj = user;
    if (this.props.userRoles) {
      const coworkerRole = this.props.userRoles.filter(el => el.name === 'coworker');
      if (coworkerRole && coworkerRole.length > 0) {
        updateObj.role_id = coworkerRole[0].id;
      }
    }
    this.setState({
      userDetails: updateObj,
    })
  };

  handleCloseBtn = () => {
    const {onClose, resetData} = this.props;
    onClose();
    resetData()
  };

  handleOnClosePrompt = () => {
    if (this.state.haveEdits) {
      this.setState({
        isCloseDialogOpen: !this.state.isCloseDialogOpen,
      });
    } else {
      this.handleCloseBtn();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    });
  }

  handleOnClosePromptConfirm = () => {
    this.setState({
      isCloseDialogOpen: !this.state.isCloseDialogOpen,
    }, () => {
      this.handleCloseBtn();
    });
  }

  handleDataChange = (fieldName, value) => {
    const {userDetails} = this.state;
    const newUserDetails = {...userDetails};
    newUserDetails[fieldName] = value;
    this.setState({
      userDetails: newUserDetails,
      haveEdits: true,
    })
  };

  handleRoleChange = (e) => {
    this.handleDataChange('role_id', +e.target.value)
  }

  handleCreateUpdateButton = () => {
    const {
      user,
      createUser,
      updateUser,
      filters,
      searchTerms
    } = this.props;
    const {userDetails} = this.state;
    if (user.id) {
      this.setState({
        shouldRefreshPage: userDetails.selected_language !== user.selected_language && user.id == localStorage.getItem("authUserId"), // on language change
      }, () => {
        updateUser({id: user.id, data: userDetails, filters, searchTerms });
      });
    } else {
      createUser({data: userDetails, filters, searchTerms })
    }
  }

  render() {
    const {
      isOpen,
      userRoles,
      user,
      onClose,
      errors,
      t
    } = this.props;
    const { userDetails } = this.state;
    const dropdownList = [{id: 0, value: t('active')}];
    if(this.props.user.id) {
      dropdownList.push({id: 1, value: t('inactive')});
    }
    const userRolesActual = userRoles.filter(el => el.name === "coworker"); // allow only coworker creation
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="create-user-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{this.props.user.id ? t('edit_user') : t('create_user')}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={this.handleOnClosePrompt}>
              <img
                alt="create-user-close-btn"
                src={CloseButton}/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="create-user-content">
          <div className="user-info-item">
            <GridItem xs={6}>
              <span className="input-title">{ t('first_name') }</span>
              <Input
                autofocus={true}
                className={`${errors && errors.first_name && errors.first_name[0] && errors.first_name[0].length > 34 ? 'long-error' : ''}`}
                error={errors && errors.first_name && errors.first_name[0]}
                errorMsg={errors && errors.first_name && errors.first_name[0]}
                value={userDetails.first_name}
                onChange={e => this.handleDataChange('first_name', e.target.value)}
              />
              <span className="input-title">{ t('last_name') }</span>
              <Input
                className={`${errors && errors.last_name && errors.last_name[0] && errors.last_name[0].length > 34 ? 'long-error' : ''}`}
                error={errors && errors.last_name && errors.last_name[0]}
                errorMsg={errors && errors.last_name && errors.last_name[0]}
                value={userDetails.last_name}
                onChange={e => this.handleDataChange('last_name', e.target.value)}
              />
              <div className="input-with-desc">
                <span className="input-title">{ t('email') }</span>
                <Input
                  className={`${errors && errors.email && errors.email[0] && errors.email[0].length > 34 ? 'long-error' : ''}`}
                  error={errors && errors.email && errors.email[0]}
                  errorMsg={errors && errors.email && errors.email[0]}
                  value={userDetails.email}
                  readOnly={Boolean(this.props.user.id)}
                  onChange={e => this.handleDataChange('email', e.target.value)}
                />
                <div className="input-desc-text">*{ t('email_cannot_change') }</div>
              </div>
              <span className="input-title">{ t('phone') }</span>
              <Input
                error={errors && errors.phone && errors.phone[0]}
                errorMsg={errors && errors.phone && errors.phone[0]}
                value={userDetails.phone}
                onChange={e => this.handleDataChange('phone', e.target.value)}
              />
              <span className="input-title">{ t('language') }</span>
              <Dropdown
                buttonText={userDetails.selected_language}
                buttonProps={{className: 'dropdown-blue-style', round: true, size: "sm"}}
                dropdownList={translationSupportedLanguages}
                error={errors && errors.selected_language && errors.selected_language[0]}
                hoverColor="dark"
                onClick={(value) => this.handleDataChange('selected_language', value.id)}
              />
            </GridItem>
            <GridItem xs={6}>
              <div className="user-status">
              <span className="input-title">{ t('status') }</span>
                <Dropdown
                  buttonText={userDetails.status}
                  buttonIconType="white"
                  buttonProps={{className: `dropdown-blue-style stock-dropdown user-status-dropdown ${userDetails.status === 1 ? 'inactive_user' : ''}`}}
                  dropdownList={dropdownList}
                  hoverColor="dark"
                  onClick={(value) => this.handleDataChange('status', value.id)}
                />
              </div>
              <div className="user-info">
                <span className="margin" >{ t('role') }</span>
                <FormControl className="user-radio-container">
                  <RadioGroup value={userDetails.role_id} onChange={this.handleRoleChange}>
                    {
                      userRolesActual.map((role, index) => <FormControlLabel value={role.id} key={index} control={<StyledRadio />} label={t(role.name)} />)
                    }
                  </RadioGroup>
                </FormControl>
              </div>
            </GridItem>
          </div>
          <div className="user-info-item">
            <GridItem className="user-info" xs={12}>
              <span className="input-title">{ t('note') }</span>
              <TextArea
                value={userDetails.notes}
                onChange={(e) => this.handleDataChange('notes', e.target.value)}
                error={errors && errors.notes && errors.notes[0]}
                errorMsg={errors && errors.notes && errors.notes[0]}
              />
            </GridItem>
          </div>
        </div>
        <div className="user-btn-container">
          <Button
            className="cancel-btn"
            onClick={this.handleCloseBtn}
          >
            { t('cancel').toUpperCase() }
          </Button>
          <Button
            className="create-btn"
            onClick={this.handleCreateUpdateButton}
          >
            {user.id ? t('update_user').toUpperCase() : t('create_user').toUpperCase() }
          </Button>
        </div>
        <CloseDialog
          isOpen={this.state.isCloseDialogOpen}
          title={t('close_user')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
      </Dialog>
    );
  }
}

const mapStateToProp = state => {
  return {
    user: state.users.user,
    userRoles: state.roles.roles,
    isUserValid: state.users.isUserValid,
    metaData: state.users.metaData,
    errors: state.users.userError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(resetUserData()),
    createUser: (data) => dispatch(addUser(data)),
    updateUser: (data) => dispatch(updateUser(data)),
    getUserOwn: () => dispatch(getUserOwn()),
    getRoles: () => dispatch(getRoles()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(CreateUser));
