import React from 'react';
import { withTranslation } from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import './style.scss';
import InputAdornment from "@material-ui/core/InputAdornment";
import InputWithIcon from "../../../components/InputWithIcon";
import Input from 'components/DecimalInput';
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";

import { formatNumericValue } from "utils/utils";

class Webshop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const {
      data,
      errors,
      currenciesList,
      onDataChange,
      selectedCurrency,
      selectedCurrencySymbol,
      t
    } = this.props;
    return (
      <GridContainer className="webshop-settings-container">
        <GridItem className="webshop-data-container-double" xs={12}>
          <GridItem className="webshop-data-container-inner" xs={6}>
            <div className="webshop-data-title">
              <span>{ t('general') }</span>
            </div>
            <div className="webshop-data-content">
              <div className="data-content-title">
                <GridItem xs={10}>
                  { t('name') }
                </GridItem>
                <GridItem xs={2}>
                  { t('value') }
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={10}>
                  { t('discount') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  {/*<InputWithIcon
                    className="finished-dimension-t"
                    error={errors.discount && errors.discount}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    value={data.discount}
                    onChange={(e) => onDataChange('discount', e.target.value)}
                  />*/}
                  <Input
                    className="finished-dimension-t"
                    error={errors.discount && errors.discount}
                    value={data.discount}
                    onChange={(value) => onDataChange('discount', value)}
                    adornment={'%'}
                  />
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={10}>
                  { t('vat') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  {/*<InputWithIcon
                    className="finished-dimension-t"
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    value={data.vat}
                    onChange={(e) => onDataChange('vat', e.target.value)}
                  />*/}
                  <Input
                    className="finished-dimension-t"
                    value={data.vat}
                    onChange={(value) => onDataChange('vat', value)}
                    adornment={'%'}
                  />
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={10}>
                  { t('currency') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <CustomDropdown
                    buttonText={
                      data.currency
                    }
                    buttonProps={{
                      className: 'dropdown-blue-style',
                      color: 'transparent',
                      round: true,
                      size: 'sm'
                    }}
                    hoverColor="rose"
                    dropdownList={currenciesList}
                    onClick={value => onDataChange('currency', value.id)}
                  />
                </GridItem>
              </div>
            </div>
          </GridItem>
          <GridItem className="webshop-data-container-inner" xs={6}>
          <div className="webshop-data-title">
            <span>{ t('material_cost_calculation') }</span>
          </div>
          <div className="webshop-data-content">
            <div className="data-content-title">
              <GridItem xs={10}>
                { t('name') }
              </GridItem>
              <GridItem xs={2}>
                { t('value') }
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container" xs={10}>
                { t('waste_material') }
              </GridItem>
              <GridItem className="data-value-container" xs={2}>
                <Input
                  className="finished-dimension-t"
                  value={data.waste}
                  onChange={(value) => onDataChange('waste', value)}
                  adornment={'%'}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container" xs={10}>
                { t('extra_charge_for_small_materials') }
              </GridItem>
              <GridItem className="data-value-container" xs={2}>
                <Input
                  className="finished-dimension-t"
                  value={data.extra_charge_for_small_materials}
                  onChange={(value) => onDataChange('extra_charge_for_small_materials', value)}
                  adornment={'%'}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container" xs={10}>
                { t('extra_charge_to_materials') }
              </GridItem>
              <GridItem className="data-value-container" xs={2}>
                <Input
                  className="finished-dimension-t"
                  value={data.extra_charge_for_materials}
                  onChange={(value) => onDataChange('extra_charge_for_materials', value)}
                  adornment={'%'}
                />
              </GridItem>
            </div>
            <div className="data-content">
              <GridItem className="data-title-container" xs={10}>
                { t('risk_and_profit') }
              </GridItem>
              <GridItem className="data-value-container" xs={2}>
                <Input
                  className="finished-dimension-t"
                  value={data.material_profit}
                  onChange={(value) => onDataChange('material_profit', value)}
                  adornment={'%'}
                />
              </GridItem>
            </div>
          </div>
        </GridItem>
        </GridItem>
        <GridItem className="webshop-data-container">
          <div className="webshop-data-title">
            <span>{ t('work_cost_calculation') }</span>
          </div>
          <div className="webshop-data-content">
            <div className="work-cost-item">
              <div className="work-cost-item-name">
                { t('corpus') }
              </div>
              <div className="data-content-title">
                <GridItem xs={6}>
                  { t('name') }
                </GridItem>
                <GridItem xs={2}>
                  { t('hours_needed_per_m') }
                </GridItem>
                <GridItem xs={2}>
                  { t('rates_per_hr').replace('{0}', selectedCurrency) }
                </GridItem>
                <GridItem xs={2}>
                  { t('total_cost_of_work') }
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('machine_work') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.corpus_machine_work_hours_per_m2}
                    onChange={(value) => onDataChange('corpus_machine_work_hours_per_m2', value)}
                    adornment={'H'}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.corpus_machine_work_hourly_rate}
                    onChange={(value) => onDataChange('corpus_machine_work_hourly_rate', value)}
                    adornment={selectedCurrencySymbol}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.corpus_machine_work_hours_per_m2 * +data.corpus_machine_work_hourly_rate, 2)}
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('manual_work') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.corpus_manual_work_hours_per_m2}
                    onChange={(value) => onDataChange('corpus_manual_work_hours_per_m2', value)}
                    adornment={'H'}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.corpus_manual_work_hourly_rate}
                    onChange={(value) => onDataChange('corpus_manual_work_hourly_rate', value)}
                    adornment={selectedCurrencySymbol}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.corpus_manual_work_hours_per_m2 * +data.corpus_manual_work_hourly_rate, 2)}
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="total-title" xs={6}>
                  { t('total_hours_needed') }
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.corpus_machine_work_hours_per_m2 + +data.corpus_manual_work_hours_per_m2, 2)}
                </GridItem>
                <GridItem className="total-title" xs={2}>
                  { t('total_cost_of_work') }
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.corpus_machine_work_hours_per_m2 * +data.corpus_machine_work_hourly_rate + +data.corpus_manual_work_hours_per_m2 * +data.corpus_manual_work_hourly_rate, 2)}
                </GridItem>
              </div>
            </div>
            <div className="work-cost-item">
              <div className="work-cost-item-name">
                { t('back') }
              </div>
              <div className="data-content-title">
                <GridItem xs={6}>
                  { t('name') }
                </GridItem>
                <GridItem xs={2}>
                  { t('hours_needed_per_m') }
                </GridItem>
                <GridItem xs={2}>
                  { t('rates_per_hr').replace('{0}', this.props.selectedCurrency) }
                </GridItem>
                <GridItem xs={2}>
                  { t('total_cost_of_work') }
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('machine_work') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.back_machine_work_hours_per_m2}
                    onChange={(value) => onDataChange('back_machine_work_hours_per_m2', value)}
                    adornment={'H'}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.back_machine_work_hourly_rate}
                    onChange={(value) => onDataChange('back_machine_work_hourly_rate', value)}
                    adornment={selectedCurrencySymbol}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.back_machine_work_hours_per_m2 * +data.back_machine_work_hourly_rate, 2)}
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('manual_work') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.back_manual_work_hours_per_m2}
                    onChange={(value) => onDataChange('back_manual_work_hours_per_m2', value)}
                    adornment={'H'}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.back_manual_work_hourly_rate}
                    onChange={(value) => onDataChange('back_manual_work_hourly_rate', value)}
                    adornment={selectedCurrencySymbol}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.back_manual_work_hours_per_m2 * +data.back_manual_work_hourly_rate, 2)}
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="total-title" xs={6}>
                  { t('total_hours_needed') }
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.back_machine_work_hours_per_m2 + +data.back_manual_work_hours_per_m2, 2)}
                </GridItem>
                <GridItem className="total-title" xs={2}>
                  { t('total_cost_of_work') }
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.back_machine_work_hours_per_m2 * +data.back_machine_work_hourly_rate + +data.back_manual_work_hours_per_m2 * +data.back_manual_work_hourly_rate, 2)}
                </GridItem>
              </div>
            </div>
            <div className="work-cost-item">
              <div className="work-cost-item-name">
                { t('front') }
              </div>
              <div className="data-content-title">
                <GridItem xs={6}>
                  { t('name') }
                </GridItem>
                <GridItem xs={2}>
                  { t('hours_needed_per_m') }
                </GridItem>
                <GridItem xs={2}>
                  { t('rates_per_hr').replace('{0}', this.props.selectedCurrency) }
                </GridItem>
                <GridItem xs={2}>
                  { t('total_cost_of_work') }
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('machine_work') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.front_machine_work_hours_per_m2}
                    onChange={(value) => onDataChange('front_machine_work_hours_per_m2', value)}
                    adornment={'H'}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.front_machine_work_hourly_rate}
                    onChange={(value) => onDataChange('front_machine_work_hourly_rate', value)}
                    adornment={selectedCurrencySymbol}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.front_machine_work_hours_per_m2 * +data.front_machine_work_hourly_rate, 2)}
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('manual_work') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.front_manual_work_hours_per_m2}
                    onChange={(value) => onDataChange('front_manual_work_hours_per_m2', value)}
                    adornment={'H'}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.front_manual_work_hourly_rate}
                    onChange={(value) => onDataChange('front_manual_work_hourly_rate', value)}
                    adornment={selectedCurrencySymbol}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.front_manual_work_hours_per_m2 * +data.front_manual_work_hourly_rate, 2)}
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="total-title" xs={6}>
                  { t('total_hours_needed') }
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.front_machine_work_hours_per_m2 + +data.front_manual_work_hours_per_m2, 2)}
                </GridItem>
                <GridItem className="total-title" xs={2}>
                  { t('total_cost_of_work') }
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.front_machine_work_hours_per_m2 * +data.front_machine_work_hourly_rate + +data.front_manual_work_hours_per_m2 * +data.front_manual_work_hourly_rate, 2)}
                </GridItem>
              </div>
            </div>
            <div className="work-cost-item">
              <div className="work-cost-item-name">
                { t('drawers') }
              </div>
              <div className="data-content-title">
                <GridItem xs={6}>
                  { t('name') }
                </GridItem>
                <GridItem xs={2}>
                  { t('hours_needed_per_m') }
                </GridItem>
                <GridItem xs={2}>
                  { t('rates_per_hr').replace('{0}', this.props.selectedCurrency) }
                </GridItem>
                <GridItem xs={2}>
                  { t('total_cost_of_work') }
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('machine_work') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.drawers_machine_work_hours_per_m2}
                    onChange={(value) => onDataChange('drawers_machine_work_hours_per_m2', value)}
                    adornment={'H'}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.drawers_machine_work_hourly_rate}
                    onChange={(value) => onDataChange('drawers_machine_work_hourly_rate', value)}
                    adornment={selectedCurrencySymbol}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.drawers_machine_work_hours_per_m2 * +data.drawers_machine_work_hourly_rate, 2)}
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="data-title-container" xs={6}>
                  { t('manual_work') }
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.drawers_manual_work_hours_per_m2}
                    onChange={(value) => onDataChange('drawers_manual_work_hours_per_m2', value)}
                    adornment={'H'}
                  />
                </GridItem>
                <GridItem className="data-value-container" xs={2}>
                  <Input
                    className="finished-dimension-t"
                    value={data.drawers_manual_work_hourly_rate}
                    onChange={(value) => onDataChange('drawers_manual_work_hourly_rate', value)}
                    adornment={selectedCurrencySymbol}
                  />
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.drawers_manual_work_hours_per_m2 * +data.drawers_manual_work_hourly_rate, 2)}
                </GridItem>
              </div>
              <div className="data-content">
                <GridItem className="total-title" xs={6}>
                  { t('total_hours_needed') }
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.drawers_machine_work_hours_per_m2 + +data.drawers_manual_work_hours_per_m2, 2)}
                </GridItem>
                <GridItem className="total-title" xs={2}>
                  { t('total_cost_of_work') }
                </GridItem>
                <GridItem className="data-title-container right-aligned" xs={2}>
                  {formatNumericValue(+data.drawers_machine_work_hours_per_m2 * +data.drawers_machine_work_hourly_rate + +data.drawers_manual_work_hours_per_m2 * +data.drawers_manual_work_hourly_rate, 2)}
                </GridItem>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withTranslation()(Webshop);
