import {
  START_TIMER,
  START_TIMER_SUCCESS,
  START_TIMER_ERROR,
  PAUSE_TIMER,
  PAUSE_TIMER_SUCCESS,
  PAUSE_TIMER_ERROR,
  RESUME_TIMER,
  RESUME_TIMER_SUCCESS,
  RESUME_TIMER_ERROR,
  UPDATE_TIMER_NOTE,
  UPDATE_TIMER_NOTE_SUCCESS,
  UPDATE_TIMER_NOTE_ERROR,
  FINALIZE_TIMER,
  FINALIZE_TIMER_SUCCESS,
  FINALIZE_TIMER_ERROR,
  DELETE_TIMER,
  DELETE_TIMER_SUCCESS,
  DELETE_TIMER_ERROR,
  GET_CURRENT_TIMER,
  GET_CURRENT_TIMER_SUCCESS,
  GET_CURRENT_TIMER_ERROR,
  RESET_ERROR,
} from '../actionTypes/timerActionTypes';

export const startTimer = (data) => {
  return {type: START_TIMER, payload: data};
};

export const startTimerSuccess = (data) => {
  return {type: START_TIMER_SUCCESS, payload: data};
};

export const startTimerFailure = (error) => {
  return {type: START_TIMER_ERROR, payload: error};
};

export const pauseTimer = () => {
  return {type: PAUSE_TIMER};
};

export const pauseTimerSuccess = (data) => {
  return {type: PAUSE_TIMER_SUCCESS, payload: data};
};

export const pauseTimerFailure = (error) => {
  return {type: PAUSE_TIMER_ERROR, payload: error};
};

export const resumeTimer = () => {
  return {type: RESUME_TIMER};
};

export const resumeTimerSuccess = (data) => {
  return {type: RESUME_TIMER_SUCCESS, payload: data};
};

export const resumeTimerFailure = (error) => {
  return {type: RESUME_TIMER_ERROR, payload: error};
};

export const updateTimerNote = (note) => {
  return {type: UPDATE_TIMER_NOTE, payload: note};
};

export const updateTimerNoteSuccess = () => {
  return {type: UPDATE_TIMER_NOTE_SUCCESS};
};

export const updateTimerNoteFailure = (error) => {
  return {type: UPDATE_TIMER_NOTE_ERROR, payload: error};
};

export const finalizeTimer = (data) => {
  return {type: FINALIZE_TIMER, payload: data};
};

export const finalizeTimerSuccess = () => {
  return {type: FINALIZE_TIMER_SUCCESS};
};

export const finalizeTimerFailure = (error) => {
  return {type: FINALIZE_TIMER_ERROR, payload: error};
};

export const deleteTimer = () => {
  return {type: DELETE_TIMER};
};

export const deleteTimerSuccess = () => {
  return {type: DELETE_TIMER_SUCCESS};
};

export const deleteTimerFailure = (error) => {
  return {type: DELETE_TIMER_ERROR, payload: error};
};

export const getCurrentTimer = () => {
  return {type: GET_CURRENT_TIMER};
};

export const getCurrentTimerSuccess = (data) => {
  return {type: GET_CURRENT_TIMER_SUCCESS, payload: data};
};

export const getCurrentTimerFailure = (error) => {
  return {type: GET_CURRENT_TIMER_ERROR, payload: error};
};

export const resetError = () => {
  return {type: RESET_ERROR};
};
