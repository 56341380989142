import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  getCategories,
  getCategory,
  getChildCategories,
  getCategoriesTypes,
  deleteCategory
} from 'store/actions/categories';

import Button from 'components/CustomButtons/Button.jsx';
import CreateCategory from '../CreateCategory';
//import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CustomMultiselect from 'components/CustomMultiselect/CustomMultiselect';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Loader from '../../components/Loader/Loader';
import ViewCategory from '../ViewCategory';

import Add from '@material-ui/icons/Add';
import CloseButton from '../../assets/img/buttons/delete.svg';
import EditButton from '../../assets/img/buttons/edit.svg';
import IconButton from '@material-ui/core/IconButton';
import ViewButton from '../../assets/img/buttons/eye.svg';
import IconContract from '../../assets/img/material_icon_contract.svg';
import IconExpand from '../../assets/img/material_icon_expand.svg';
import IconListBullet from '../../assets/img/material_icon_bullet.svg';

import './styles.scss';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import CustomPagination from '../../components/CustomPagination';
import GridViewButton from '../../components/ViewButton';
import RemoveDialog from '../RemoveDialog';
import {CategoriesSortByOptions} from "../../constants/constants";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { getAutomaticTypeDirectiveNames } from 'typescript';

class Categories extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateCategoryOpen: false,
      isEditCategoryOpen: false,
      isViewCategoryOpen: false,
      isRemoveCategoryOpen: false,
      isViewTable: true,
      bundleStates: {},
      deletedCategoryId: '',
      selectedType: [-1],
      order: 'name',
      page: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { bundleStates } = state;
    const { categories, categoriesBundles } = props;

    // states for category bundles

    // for everything in categories that has children create state
    for (let i in categories) {
      const category = categories[i];
      if (category.children_count > 0 && !bundleStates[category.id]) {
        bundleStates[category.id] = {
          data: false,
          open: false,
        };
      }
    }
    // for every retrieved child category that has children create state
    const categoriesBundlesParentIds = Object.keys(categoriesBundles.successes);
    for (let i in categoriesBundlesParentIds) {
      const key = categoriesBundlesParentIds[i];
      const categories = categoriesBundles.successes[key];
      for (let j in categories) {
        const category = categories[j];
        if (category.children_count > 0 && !bundleStates[category.id]) {
          bundleStates[category.id] = {
            data: false,
            open: false,
          };
        }
      }
    }
    // remove state for every category has been removed from categoriesBundles (due to update, create, remove category, etc.)
    const bundleStatesIds = Object.keys(bundleStates);
    for (let i in bundleStatesIds) {
      const id = bundleStatesIds[i];
      if (!categories.find(el => el.id == id)) {
        const categoriesBundlesSuccessesIds = Object.keys(categoriesBundles.successes);
        let found = false;
        for (let j in categoriesBundlesSuccessesIds) {
          const key = categoriesBundlesSuccessesIds[j];
          const childCategories = categoriesBundles.successes[key];
          if (childCategories.find(el => el.id == id)) {
            found = true;
            break;
          }
        }
        if (!found) {
          delete bundleStates[id];
        }
      }
    }
    // update data availability for categories based on categoriesBundles state
    for (let i in categoriesBundlesParentIds) {
      const key = categoriesBundlesParentIds[i];
      if (bundleStates[parseInt(key)])
        bundleStates[parseInt(key)].data = true;
    }
    for (let i in bundleStatesIds) {
      const id = bundleStatesIds[i];
      if (!categoriesBundles.successes[id] && bundleStates[id]) { /* second part of if is necessary because categoriesBundles is shared with CreateDialog */
        bundleStates[id].data = false;
      }
      if (categoriesBundles.parentIds.indexOf(parseInt(id)) == -1 && !categoriesBundles.successes[id] && bundleStates[id] && bundleStates[id].open) { // close expanded categories on page reload (icon status)
        bundleStates[id].open = false;
      }
    }

    return { bundleStates };
  }

  componentDidMount() {
    this.props.getTypes();
    this.props.getCategories(null, this.state.selectedType, this.state.order, this.props.searchTerms, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchTerms != prevProps.searchTerms) {
      if (this.state.page != 1) {
        this.setState({
          page: 1,
        }, () => {
          this.props.getCategories(this.state.page, this.state.selectedType, this.state.order, this.props.searchTerms, true);
        });
      } else {
        this.props.getCategories(this.state.page, this.state.selectedType, this.state.order, this.props.searchTerms, true);
      }
    }
    /* sample code that triggers a "clear search", should the page require it
    setTimeout(() => {
      this.props.onSearchReset && this.props.onSearchReset();
    }, 2500);
    */
  }

  handleViewChange = () => {
    this.setState({
      isViewTable: !this.state.isViewTable
    });
  };

  handleTypeChange = (value) => {
    const {types} = this.props;
    const {page, order} = this.state;
    let type;
    if (value.length == 0) {
      type = [-1];
    } else {
      type = value;
    }
    this.setState({selectedType: type});
    this.props.getCategories(page, type, order, this.props.searchTerms, true);
  };

  handleOrderChange = (value) => {
    const {selectedType, page} = this.state;
    this.setState({
      order: value
    });
    this.props.getCategories(page, selectedType, value, this.props.searchTerms, true);
  };

  handlePageClick = data => {
    const { selected } = data;
    const {selectedType, order} = this.state;
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    wrapper.scrollTop = 0;
    this.props.getCategories(selected + 1, selectedType, order, this.props.searchTerms, true);
    this.setState({page: selected + 1, bundleStates: {}})
  };

  isCategoriesDataReady = () => {
    const {
      categoriesBusy,
    } = this.props;
    return !categoriesBusy;
  };

  handleDeleteButton = (id) => {
    this.setState({
      isRemoveCategoryOpen: true,
      deletedCategoryId: id
    })
  }

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveCategoryOpen: false,
    });
  }

  handleRemoveCategory = () => {
    const { deleteCategory, metaData } = this.props;
    const {deletedCategoryId, selectedType, order} = this.state;
    deleteCategory({ id: deletedCategoryId, currentPage: metaData.current_page, type: selectedType, order: order,  searchTerms: this.props.searchTerms, level1Only: true });
    this.handleRemoveDialogClose();
  };

  handleShowViewContact = id => {
    this.props.getCategory(id);
    this.setState({
      isViewCategoryOpen: true
    });
  };

  handleCloseViewContact = () => {
    this.setState({
      isViewCategoryOpen: false
    });
  };

  handleShowEditCategory = id => {
    if (id) {
      this.props.getCategory(id);
    }
    this.setState({
      isEditCategoryOpen: true,
      isViewCategoryOpen: false
    });
  };

  handleCloseEditCategory = () => {
    this.setState({
      isEditCategoryOpen: false
    });
  };

  handleToggleCreateCategory = () => {
    this.setState({
      isCreateCategoryOpen: !this.state.isCreateCategoryOpen
    });
  };

  handleShowCategoryChildrenButton = categoryId => {
    const { getChildCategories } = this.props;
    const { bundleStates } = this.state;

    if (!bundleStates[categoryId].open) {
      // expand
      const newBundleStates = {
        ...bundleStates,
        [categoryId]: {
          ...bundleStates[categoryId],
          open: true,
        },
      };
      this.setState({
        bundleStates: newBundleStates,
      });
      if (!bundleStates[categoryId].data) {
        getChildCategories(categoryId, true);
      }
    } else {
      // close
      const newBundleStates = {
        ...bundleStates,
        [categoryId]: {
          ...bundleStates[categoryId],
          open: false,
        },
      };
      this.setState({
        bundleStates: newBundleStates,
      });
    }
  };

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return <span>{text}</span>;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  getCategoryTypeString = (type) => {
    const { types } = this.props;
    let base = types[type];
    return base.substring(0, 1).toUpperCase() + base.substring(1).toLowerCase();
  }

  renderCategory = (category, index) => {
    const {types, categoriesBundles, categories, t} = this.props;
    const { bundleStates } = this.state;
    const renderChildren = category.children_count > 0 && bundleStates[category.id].open && bundleStates[category.id].data;
    const handleCategoryNameButton = () => {
      if (category.children_count > 0) {
        const { bundleStates } = this.state;
        const bundleState = bundleStates[category.id];
        const icon = bundleState.open ?
          <IconButton
            className="category-name-btn"
            onClick={() => this.handleShowCategoryChildrenButton(category.id)}
          >
            <img alt="contract" src={IconContract} />
          </IconButton> :
          <IconButton
            className="category-name-btn"
            onClick={() => this.handleShowCategoryChildrenButton(category.id)}
          >
            <img alt="expand" src={IconExpand} />
          </IconButton>;
        return icon;
      } else {
        if (category.level > 1) {
          return <img alt="bullet" className="bullet-icon" src={IconListBullet} />;
        } else {
          return null;
        }
      }
    };
    const haveCategoryChildrenOnPage = Boolean(categories.find(category => category.children_count > 0));
    let paddingClass = `${category.level == 1 ? 'name-l1-padding' : (category.level == 2 ? 'name-l2-padding' : 'name-l3-padding')}`;
    if (category.children_count > 0 && category.level == 1) {
      paddingClass = '';
    }
    if (!haveCategoryChildrenOnPage) {
      paddingClass = 'name-l0-padding';
    }

    const nameClassesAdditional = `${category.children_count > 0 ? 'bold' : ''} ${paddingClass}`;
    const maxNameCharacters = category.level == 1 ? 25 : (category.level == 2 ? 20 : 15);
    const mainEntry = (
      <GridContainer
        className={`categories-item ${index % 2 === 0 ? 'dark' : ''}`}
        key={!renderChildren ? index : 0}
      >
        <GridItem className={"category-item-title " + nameClassesAdditional} xs={3}>
          {handleCategoryNameButton()}
          {this.wrapText(category.name, maxNameCharacters)}
        </GridItem>
        <GridItem className="category-item-title" xs={2}>
          { this.getCategoryTypeString(category.type) }
        </GridItem>
        <GridItem className="category-item-title _description" xs={4} md={5}>
          {this.wrapText(category.description,90)}
        </GridItem>
        <GridItem className="category-item-title _actions" xs={3} md={2}>
          { category.can_delete &&
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleDeleteButton(category.id)}
          >
            <img alt="category-card-remove" src={CloseButton} />
          </IconButton>
          }
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleShowEditCategory(category.id)}
          >
            <img
              alt="category-card-edit"
              className="category-card-edit"
              src={EditButton}
            />
          </IconButton>
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleShowViewContact(category.id)}
          >
            <img alt="category-card-view" src={ViewButton} />
          </IconButton>
        </GridItem>
      </GridContainer>
    );
    if (!renderChildren) {
      return mainEntry;
    } else {
      const childCategoriesData = categoriesBundles.successes[category.id];
      const childCategories = childCategoriesData.map((childCategory, index) => this.renderCategory(childCategory, index + 1));
      return (
        <React.Fragment key={index}>
          {mainEntry}
          {childCategories}
        </React.Fragment>
      );
    }
  };

  renderCategoryCard = (category, index) => {
    const {types, t} = this.props;
    return (
      <GridContainer className="categories-card-item" key={index}>
        <div>
        <div className="contact-name">{category.name}</div>
        <div className="contact-company-name">{`${ t('type') }: ${this.getCategoryTypeString(category.type)}`}</div>
        <div className="contact-card-info">{ t('description') }:</div>
        <div className="contact-card-info">{this.wrapText(category.description,60)}</div>
        </div>
        <div className="categories-card-action-btn">
          {category.can_delete &&
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleDeleteButton(category.id)}
          >
            <img alt="remove-card-customer" src={CloseButton} />
          </IconButton>
          }
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleShowEditCategory(category.id)}
          >
            <img alt="edit-card-customer" src={EditButton} />
          </IconButton>
          <IconButton
            aria-label="close"
            className="action-btn"
            onClick={() => this.handleShowViewContact(category.id)}
          >
            <img alt="view-card-customer" src={ViewButton} />
          </IconButton>
        </div>
      </GridContainer>
    );
  };

  render() {
    const {
      categories,
      category,
      metaData,
      types,
      t
    } = this.props;
    const {
      isCreateCategoryOpen,
      isEditCategoryOpen,
      isViewCategoryOpen,
      isViewTable,
      isRemoveCategoryOpen,
      deletedCategoryId,
      selectedType,
      order
    } = this.state;
    let categoriesTypes = [];
    types.map((item, index) => categoriesTypes.push({id: index, value: item}));
    const dropdownTypeList = [{id: -1, value: t('all_types'), is_select_all: true}, ...categoriesTypes];
    return (
      <div className="ikt-ap_categories-container">
        {types.length !== 0 ? (
          <>
          <GridContainer>
            <GridItem
              className="categories-dropdown-content"
              xs={12}
            >
              <CustomMultiselect
                buttonValue={selectedType}
                buttonProps={{
                  className: 'category-dropdown-style',
                  round: true,
                  size: 'sm'
                }}
                menuWidthCorrection={true}
                buttonPlaceholder={ t('all_types') }
                dropdownList={dropdownTypeList}
                onChange={data => this.handleTypeChange(data)}
              />
              <CustomDropdown
                buttonText={order}
                buttonProps={{
                  className: 'dropdown-blue-style',
                  color: 'transparent',
                  size: 'sm'
                }}
                menuWidthCorrection={true}
                hoverColor="rose"
                dropdownList={CategoriesSortByOptions}
                translateValues={true}
                onClick={value => this.handleOrderChange(value.id)}
              />
              <GridViewButton
                className="project-view-button"
                isTable={isViewTable}
                onViewChange={this.handleViewChange}
              />
              <Button
                onClick={this.handleToggleCreateCategory}
                size="sm"
                className="create-category-btn"
              >
                <Add /> { t('create').toUpperCase() }
              </Button>
            </GridItem>
            {isViewTable && (
              <GridContainer
                className="categories-header"
              >
                <GridItem className="categories-header-title name-header-title" xs={3}>
                  { t('name') }
                </GridItem>
                <GridItem className="categories-header-title" xs={2}>
                  { t('type') }
                </GridItem>
                <GridItem className="categories-header-title" xs={3} sm={5}>
                  { t('description') }
                </GridItem>
                <GridItem className="categories-header-title _actions" xs={4} sm={2}>
                  { t('actions') }
                </GridItem>
              </GridContainer>
            )}
            {
              this.isCategoriesDataReady() ?
                <GridContainer
                  className={`categories-content ${
                    !isViewTable ? 'card-content' : ''
                  }`}
                >
                  { categories.length > 0 ?
                    (isViewTable
                      ? categories.map((category, index) =>
                        this.renderCategory(category, index)
                      )
                      : categories.map((category, index) =>
                        this.renderCategoryCard(category, index)
                      ))
                    : (
                      <div className="no_data">{ t('no_categories_found') }</div>
                    )
                  }
                </GridContainer>
              :
                (
                  <div className="ikt-ap_loader">
                    <Loader />
                  </div>
                )
            }
          </GridContainer>
          <GridContainer className="pagination-container">
            {metaData && metaData.last_page > 1 && (
                <CustomPagination
                    pageCount={metaData.last_page}
                    handlePageClick={this.handlePageClick}
                    />
            )}
          </GridContainer>
          </>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
        {isCreateCategoryOpen && (
          <CreateCategory
            isOpen={isCreateCategoryOpen}
            type={selectedType}
            order={order}
            onClose={this.handleToggleCreateCategory}
            searchTerms={this.props.searchTerms}
            level1Only={true}
          />
        )}
        {isEditCategoryOpen && Object.keys(category).length !== 0 && (
          <CreateCategory
            isOpen={isEditCategoryOpen}
            type={selectedType}
            order={order}
            onClose={this.handleCloseEditCategory}
            searchTerms={this.props.searchTerms}
            level1Only={true}
          />
        )}
        {isViewCategoryOpen && Object.keys(category).length !== 0 && (
          <ViewCategory
            isOpen={isViewCategoryOpen}
            onClose={this.handleCloseViewContact}
            onEditCategory={this.handleShowEditCategory}
            currentPage={metaData.current_page}
            currentType={selectedType}
            searchTerms={this.props.searchTerms}
            level1Only={true}
          />
        )}
      {isRemoveCategoryOpen && (
        <RemoveDialog
          isOpen={isRemoveCategoryOpen}
          title={t('remove_category')}
          text={t('remove_category_confirmation')}
          onClose={this.handleRemoveDialogClose}
          onRemove={this.handleRemoveCategory}
        />
      )}
      </div>
    );
  }
}

Categories.propTypes = {
  classes: PropTypes.object.isRequired,
  searchTerms: PropTypes.string,
  onSearchReset: PropTypes.func
};

const mapStateToProp = state => {
  return {
    categories: state.categories.categories,
    categoriesBundles: state.categories.categoriesBundles,
    categoriesBusy: state.categories.categoriesBusy,
    category: state.categories.category,
    metaData: state.categories.metaData,
    types: state.categories.categoriesTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCategories: (page, type, order, searchTerms, level1Only) =>
      dispatch(getCategories({page, type, order, searchTerms, level1Only})),
    getChildCategories: (parentId, keepCurrent) => dispatch(getChildCategories(parentId, keepCurrent)),
    getCategory: id => dispatch(getCategory(id)),
    deleteCategory: data => dispatch(deleteCategory(data)),
    getTypes: () => dispatch(getCategoriesTypes())
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(Categories)));
