import React from "react";
import PropTypes from "prop-types";
import CloseIcon from '@material-ui/icons/Close';
import InputWithIcon from '../../components/InputWithIcon';
import InputAdornment from '@material-ui/core/InputAdornment';

import './style.scss';

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInputValue: '',
    };
  }

  handleInputChange = (e) => {
    this.setState({ currentInputValue: e.target.value });
  };

  handleInputKeyPress = (e) => {
    if (e.key == 'Enter') {
      let { currentInputValue } = this.state;
      currentInputValue = currentInputValue.trim();
      if (currentInputValue.length > 0 && this.props.data.indexOf(currentInputValue) == -1) {
        this.props.onAddTag(currentInputValue);
        this.setState({ currentInputValue: '' });
      }
    }
  }

  removeTag = (index) => {
    this.props.onRemoveTag(index);
  };

  renderTag = (tag, index) => {
    return (
      <div key={index} className="selected-tag">
        <span>{tag}</span>
        <div
          className="tag-delete-btn"
          onClick={() => this.removeTag(index)}
        >
          <CloseIcon fontSize={'small'} />
        </div>
      </div>
    )
  };

  render() {
    const {title, className, inputIcon, containerClassName} = this.props;
    return (
      <div className={`tags-content ${className}`}>
        <div className='title-container'>{title}</div>
        <InputWithIcon
          className={containerClassName}
          startAdornment={
            <InputAdornment position="start">
              <img
                className="input-icon"
                alt="tags-input-icon"
                src={inputIcon}
              />
            </InputAdornment>
          }
          onChange={this.handleInputChange}
          onKeyPress={this.handleInputKeyPress}
          value={this.state.currentInputValue}
        />
        <div className="tags-list">
          {
            this.props.data.map((item, index) => this.renderTag(item, index))
          }
        </div>
      </div>
    );
  }
}

Tags.propTypes = {
  inputIcon: PropTypes.string,
  containerClassName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
};

export default Tags;
