import React from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";

import CreateFitting from 'views/CreateFitting';
import Fitting from './Fitting';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import RemoveDialog from '../../RemoveDialog';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './style.scss';

class Position extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardOpen: false,
      isCreateFittingOpen: false,
      isRemoveFittingOpen: false,
      editFittingIndex: null,
      removeFittingIndex: null,
    };
  }

  componentDidMount() {
    if (this.props.isCardOpen) {
      this.setState({
        isCardOpen: true,
      });
    }
  }

  onCardClose = () => {
    this.setState({isCardOpen: !this.state.isCardOpen})
  };

  
  handleCreateFittingCommit = (fittingData) => {
    const { data, onChange } = this.props;
    const { editFittingIndex } = this.state;

    let newFittings = data.fittings;
    if (editFittingIndex === null) {
      newFittings.push(fittingData);
    } else {
      newFittings[editFittingIndex] = fittingData;
    }

    const newData = {
      ...data,
      fittings: newFittings,
    };
    onChange(newData);

    if (this.state.isCreateFittingOpen) {
      this.setState({ isCreateFittingOpen: false, editFittingIndex: null });
    }
  }

  handleCreateFittingClose = () => {
    if (this.state.isCreateFittingOpen) {
      this.setState({ isCreateFittingOpen: false, editFittingIndex: null });
    }
  };

  handleEditFitting = (index) => {
    this.setState({ isCreateFittingOpen: true, editFittingIndex: index });
  }

  handleRemoveFittingButton = (index) => {
    this.setState({
      isRemoveFittingOpen: true,
      removeFittingIndex: index,
    });
  }

  handleRemoveFittingClose = () => {
    this.setState({
      isRemoveFittingOpen: false,
      removeFittingIndex: null,
    });
  }

  handleFittingDataChange = (index, fittingData) => {
    const { data, onChange } = this.props;
    let newFittings = data.fittings;
    newFittings[index] = fittingData;
    const newData = {
      ...data,
      fittings: newFittings,
    };
    onChange(newData);
  }

  handleRemoveFitting = ()  => {
    const { data, onChange } = this.props;
    const { removeFittingIndex } = this.state;
    let newFittings = data.fittings;
    newFittings = newFittings.filter((item, i) => i !== removeFittingIndex);
    const newData = {
      ...data,
      fittings: newFittings,
    };
    onChange(newData);
    this.handleRemoveFittingClose();
  }

  handleDuplicateFitting = (index) => {
    const {data, onChange} = this.props;
    const newFittings = data.fittings;
    const duplicate = newFittings[index];
    newFittings.splice(index, 0, duplicate);
    const newData = {
      ...data,
      fittings: newFittings,
    };
    onChange(newData);
  }

  handleAddNewFitting = () => {
    this.setState({ isCreateFittingOpen: true, editFittingIndex: null });
  }

  render() {
    const {
      data,
      numericName,
      title,
      errors,
      modifiable,
      t
    } = this.props;
    const {
      isCardOpen,
      isCreateFittingOpen,
      isRemoveFittingOpen,
      editFittingIndex,
    } = this.state;
    return (
      <div className="fitting-position-content">
        <div className={`fitting-position-info ${isCardOpen ? 'fitting-bottom-borders' : ''}`}>
          <div className="fitting-position-info-title">
            <div className="fitting-position-title">
              {`${numericName ? numericName : ''} ${title ? title : ''}`}
            </div>
          </div>
          <div className="fitting-card-btn-container">
            <div
              className={`card-btn ${isCardOpen ? 'fitting-bottom-borders' : ''}`}
              onClick={() => this.onCardClose()}
            >
              {isCardOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </div>
          </div>
        </div>
        {
          isCardOpen &&
          <div className="fitting-position-info-content">
            {data.fittings.length !== 0 &&
              <GridContainer className="fitting-position-header">
                <GridItem className="grid-item fc-article">{ t('name') }</GridItem>
                <GridItem className="grid-item fc-amount">{ t('amount') }</GridItem>
                <GridItem className="grid-item fc-metric">{ t('metrics') }</GridItem>
                <GridItem className="grid-item fc-price">{ t('price') }</GridItem>
                <GridItem className="grid-item fc-total-price">{ t('total_price') }</GridItem>
                <GridItem className="grid-item fc-description">{ t('description') }</GridItem>
              </GridContainer>
            }
            <div className="fitting-positions">
              {data.fittings.length !== 0 &&
                data.fittings.map((fitting, index) => {
                  return (
                    <Fitting
                      data={fitting}
                      onEdit={() => this.handleEditFitting(index)}
                      onDataChange={(data) => this.handleFittingDataChange(index, data)}
                      onRemove={() => this.handleRemoveFittingButton(index)}
                      onDuplicate={() => this.handleDuplicateFitting(index)}
                      errors={errors && errors.fittings ? errors.fittings[index] : null}
                      modifiable={modifiable}
                      key={index}
                    />
                  )
                })
              }
              <div className="create-fitting">
                <Button
                  className="create-fitting-btn"
                  disabled={!modifiable}
                  onClick={this.handleAddNewFitting}
                >
                  + { t('fitting').toUpperCase() }
                </Button>
              </div>
            </div>
          </div>
        }
        { isCreateFittingOpen &&
          <CreateFitting
            isOpen={isCreateFittingOpen}
            onCommit={this.handleCreateFittingCommit}
            onClose={this.handleCreateFittingClose}
            errors={editFittingIndex !== null && errors && errors.fittings ? errors.fittings[editFittingIndex] : null}
            edit={editFittingIndex !== null ? data.fittings[editFittingIndex] : null}
          />
        }
        { isRemoveFittingOpen &&
          <RemoveDialog
            isOpen={isRemoveFittingOpen}
            title={t('remove_fitting')}
            text={t('remove_fitting_confirmation')}
            onClose={this.handleRemoveFittingClose}
            onRemove={this.handleRemoveFitting}
          />
        }
      </div>
    )
  }
}

const mapStateToProp = state => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(Position));
