import React from "react";

import Button from '../../components/CustomButtons/Button';
import CloseButton from "../../assets/img/buttons/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import Dropdown from "../../components/CustomDropdown/CustomDropdown";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridItem from "../../components/Grid/GridItem";
import Input from "../../components/Input/Input";
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import {RadioGroup} from "@material-ui/core";
import StyledCheckbox from "../../components/StyledCheckbox";

import './style.scss';

class CreateRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Dialog maxWidth={false} open={isOpen}>
        <MuiDialogTitle className="create-role-title" disableTypography>
          <Typography variant="h6" className="dialog-title">{`Create Role`}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <img
                alt="create-role-close-btn"
                src={CloseButton}
              />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <div className="create-role-content">
          <div className="role-info-item">
            <GridItem xs={12}>
              <span>Description</span>
              <Input/>
            </GridItem>
          </div>
          <div className="role-info-item">
            <GridItem xs={12}>
              <Dropdown
                buttonText={""}
                buttonProps={{className: 'dropdown-blue-style stock-dropdown', round: true, size: "sm"}}
                dropdownList={[{id: 0, value: "tes"}]}
                hoverColor="dark"
              />
            </GridItem>
          </div>
          <div className="role-info-item">
            <GridItem xs={3}>
              <span>Privileges</span>
            </GridItem>
            <GridItem xs={2}>
              <span>Create</span>
            </GridItem>
            <GridItem xs={2}>
              <span>View</span>
            </GridItem>
            <GridItem xs={2}>
              <span>Edit</span>
            </GridItem>
            <GridItem xs={2}>
              <span>Delete</span>
            </GridItem>
          </div>
          <div className="role-info-item">
            <GridItem className="role-checkbox" xs={3}>
              <span>Project</span>
            </GridItem>
            <FormControl>
              <RadioGroup className="role-checkbox-container">
                <FormControlLabel value="superAdmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="Admin" control={<StyledCheckbox />}/>
                <FormControlLabel value="dmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="min" control={<StyledCheckbox />}/>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="role-info-item">
            <GridItem className="role-checkbox" xs={3}>
              <span>Products</span>
            </GridItem>
            <FormControl>
              <RadioGroup className="role-checkbox-container">
                <FormControlLabel value="superAdmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="Admin" control={<StyledCheckbox />}/>
                <FormControlLabel value="dmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="min" control={<StyledCheckbox />}/>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="role-info-item">
            <GridItem className="role-checkbox" xs={3}>
              <span>Material</span>
            </GridItem>
            <FormControl>
              <RadioGroup className="role-checkbox-container">
                <FormControlLabel value="superAdmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="Admin" control={<StyledCheckbox />}/>
                <FormControlLabel value="dmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="min" control={<StyledCheckbox />}/>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="role-info-item">
            <GridItem className="role-checkbox" xs={3}>
              <span>Inventory</span>
            </GridItem>
            <FormControl>
              <RadioGroup className="role-checkbox-container">
                <FormControlLabel value="superAdmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="Admin" control={<StyledCheckbox />}/>
                <FormControlLabel value="dmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="min" control={<StyledCheckbox />}/>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="role-info-item">
            <GridItem className="role-checkbox" xs={3}>
              <span>Disputes</span>
            </GridItem>
            <FormControl>
              <RadioGroup className="role-checkbox-container">
                <FormControlLabel value="superAdmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="Admin" control={<StyledCheckbox />}/>
                <FormControlLabel value="dmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="min" control={<StyledCheckbox />}/>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="role-info-item">
            <GridItem className="role-checkbox" xs={3}>
              <span>Ticket</span>
            </GridItem>
            <FormControl>
              <RadioGroup className="role-checkbox-container">
                <FormControlLabel value="superAdmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="Admin" control={<StyledCheckbox />}/>
                <FormControlLabel value="dmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="min" control={<StyledCheckbox />}/>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="role-info-item">
            <GridItem className="role-checkbox" xs={3}>
              <span>Users</span>
            </GridItem>
            <FormControl>
              <RadioGroup className="role-checkbox-container">
                <FormControlLabel value="superAdmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="Admin" control={<StyledCheckbox />}/>
                <FormControlLabel value="dmin" control={<StyledCheckbox />}/>
                <FormControlLabel value="min" control={<StyledCheckbox />}/>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="role-btn-container">
          <Button
            className="cancel-btn"
          >
            CANCEL
          </Button>
          <Button
            className="create-btn"
          >
            CREATE ROLE
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default CreateRole
