import React from 'react';
import { withTranslation } from 'react-i18next';
import './projectTabs.scss';

class ProjectTabs extends React.Component {
  onTabItemClick = tabId => {
    return () => {
      const { onTabItemClick } = this.props;
      if (onTabItemClick) {
        onTabItemClick(tabId);
      }
    };
  };

  render() {
    const { className, tabs, selectedTab, disableTranslatingValues, t } = this.props;
    const activeTab = selectedTab === undefined ? tabs[0] : selectedTab;
    let containerClassName = `ikt-ap_project-tabs-container${
      className ? ` ${className}` : ``
    }`;

    return (
      <div className={containerClassName}>
        {tabs.map((tab, index) => {
          return (
            <button
              key={index}
              className={`ikt-ap_project-tab-item ${
                tab.id === activeTab ? ` active` : ``
              }`}
              onClick={this.onTabItemClick(tab.id)}
            >
              { !disableTranslatingValues ? t(tab.value) : tab.value }
            </button>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(ProjectTabs);
