import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import Button from 'components/CustomButtons/Button.jsx';
import Dropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CustomDateRangePickerDropdown from "../../components/CustomDateRangePickerDropdown";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import IconButton from '@material-ui/core/IconButton';
import CloseButton from '../../assets/img/buttons/delete.svg';
import EditButton from '../../assets/img/buttons/edit.svg';
import MaterialArrowUp from '../../assets/img/material_arrow_up.svg';
import MaterialArrowDown from '../../assets/img/material_arrow_down.svg';

import ManualTimer from './ManualTimer';
import RemoveDialog from "../RemoveDialog";

import './style.scss';

import {
  getCoworkerTimeEntry,
  deleteCoworkerTimeEntry,
  filterCoworkerTimeSheets,
  resetLastFinishedEdit,
} from 'store/actions/coworkerTimeSheet';

import {
  getPositionsList,
  getPositionCostCentersList,
  getPositionCostCenterItemsList,
} from 'store/actions/timeSheetsProviders';

import Loader from '../../components/Loader/Loader';
import CustomPagination from '../../components/CustomPagination';
import { isCurrentUserManufacturer } from 'utils/utils';

class ProjectTimesheets extends React.Component {
  
  static SortByList = [{id: 0, data: 'entry_date', value: 'sort_by_date'}];

  constructor(props) {
    super(props);
    this.state = {
      isTimerOpen: true,
      isManualTimerOpen: false,
      isRemoveDialogOpen: false,
      deleteEntryId: null,
      filters: {
        page: '1',
        entry_type: 0,  // needed
        coworker: '',
        position: '',
        position_cost_center: '',
        position_cost_center_item: '',
        dateFrom: null,
        dateTo: null,
        order_id: props.order.id,
      },
      sortBy: 0,
      sortByOrder: true,
      editing: null,
    };
  }

  componentDidMount() {
    this.reloadDataList();
    this.props.getPositionsList(this.props.order.id);
  }

  componentDidUpdate() {
    if (this.state.editing && this.state.editing.id === this.props.lastFinishedEditId && !this.props.editError) {
      // if editing finished, close the dialog
      this.closeManualTimer();
      this.props.resetLastFinishedEdit();
    }
  }

  reloadDataList = () => {
    const { filterCoworkerTimeSheets, searchTerms } = this.props;
    const { filters, sortBy, sortByOrder } = this.state;
    filterCoworkerTimeSheets(filters, ProjectTimesheets.SortByList[sortBy].data, sortByOrder, searchTerms);
  }

  handleFiltersChange = (fieldName, value) => {
    const { filters } = this.state;
    const newFilters = { ...filters };
    newFilters[fieldName] = value;
    if (fieldName == "position") {
      newFilters["position_cost_center"] = '';
      newFilters["position_cost_center_item"] = '';
    } else if (fieldName == "position_cost_center") {
      newFilters["position_cost_center_item"] = '';
    }
    this.setState({
      filters: newFilters,
    }, () => {
      this.reloadDataList();
    });
  };

  handleSortChange = (id) => {
    this.setState({
      sortBy: id,
    }, () => {
      this.reloadDataList();
    });
  }

  handleSortFlipOrder = () => {
    this.setState({
      sortByOrder: !this.state.sortByOrder,
    }, () => {
      this.reloadDataList();
    });
  };

  handleCoworkerFilterChange = (id) => {
    this.handleFiltersChange("coworker", id != -1 ? id : '');
  }

  handleDateFilterChange = (fieldName, value) => {
    let date = null;
    if (value) {
      date = moment(value).format('YYYY-MM-DD');
    }
    this.handleFiltersChange(fieldName, date);
  }

  handlePositionDropdownChange = id => {
    if (id != -1)
      this.props.getPositionCostCentersList(id);
    this.handleFiltersChange("position", id != -1 ? id : '');
  };

  handleCostCentersDropdownChange = id => {
    if (id != -1)
      this.props.getPositionCostCenterItemsList(id);
    this.handleFiltersChange("position_cost_center", id != -1 ? id : '');
  }

  handleCostCenterItemsDropdownChange = id => {
    this.handleFiltersChange("position_cost_center_item", id != -1 ? id : '');
  }

  handlePageClick = data => {
    const { selected } = data;
    this.handleFiltersChange('page', selected + 1);
    const wrapper = document.getElementById('ikt-admin-mainPanel');
    if (wrapper)
      wrapper.scrollTop = 0;
  };

  handleManualTimerClick = () => {
    this.setState({
      isManualTimerOpen: !this.state.isManualTimerOpen,
      editing: null,
    });
  };

  closeManualTimer = () => {
    this.setState({
      isManualTimerOpen: false,
      editing: null,
    });
  }

  handleDeleteEntryClick = (entry) => {
    this.setState({
      isRemoveDialogOpen: !this.state.isRemoveDialogOpen,
      deleteEntryId: entry.id,
    });
  }

  handleRemoveDialogClose = () => {
    this.setState({
      isRemoveDialogOpen: !this.state.isRemoveDialogOpen,
      deleteEntryId: null,
    });
  }

  handleDeleteEntry = () => {
    const { searchTerms } = this.props;
    const { deleteEntryId, filters, sortBy, sortByOrder } = this.state;
    this.props.deleteCoworkerTimeEntry(deleteEntryId, filters, sortBy, sortByOrder, searchTerms);
    this.handleRemoveDialogClose();
  }

  handleEditEntryClick = (index, entry, positionId) => {
    let type;
    if (entry.loggable_type == "App\\Models\\PositionCostCenter") {
      type = 0;
    } else if (entry.loggable_type == "App\\Models\\PositionCostCenterItem") {
      type = 1;
    }
    const data = { ...entry, position_id: positionId, type };
    this.setState({
      isManualTimerOpen: !this.state.isManualTimerOpen,
      editing: data,
    });
  }

  wrapText = (text, noOfChars) => {
    if (text) {
      if (text && text.length <= noOfChars) {
        return text;
      } else {
        const abbr = `${text.substring(0, noOfChars)}...`;
        return <span title={text}>{abbr}</span>;
      }
    }
    return '';
  };

  renderTimeSheet = (timeSheet, index) => {
    const { coworkers } = this.props;
    const timeFromHmi = time => {
      const parts = time.split(":");
      const decimal = parseInt(parts[0], 10) + parseInt(parts[1], 10) / 60 + parseInt(parts[2], 10) / 3600;
      return decimal.toFixed(1);
    };
    let entryCostCenterName = null,
        entryCostCenterItemName = null,
        entryPositionName = null,
        entryPositionId = null;
    if (timeSheet.loggable_meta) {
      if (timeSheet.loggable_type === "App\\Models\\PositionCostCenter") {
        entryCostCenterName = timeSheet.loggable_meta.position_cost_center_name;
      } else if (timeSheet.loggable_type === "App\\Models\\PositionCostCenterItem") {
        entryCostCenterName = timeSheet.loggable_meta.position_cost_center_name;
        entryCostCenterItemName = timeSheet.loggable_meta.position_cost_center_item_name;
      }
      entryPositionName = timeSheet.loggable_meta.position_name;
      entryPositionId = timeSheet.loggable_meta.position_id;
    }
    const coworker = coworkers.find(item => item.id === timeSheet.coworker_id.toString());
    return (
      <GridContainer
        className={`timesheets-item ${index % 2 === 0 ? 'dark' : ''}`}
        direction="row"
        key={index}
      >
        <GridItem className="timesheets-item-title ts-col-date">
          {timeSheet.entry_date}
        </GridItem>
        <GridItem className="timesheets-item-title ts-col-position">
          { entryPositionName ? this.wrapText(entryPositionName, 26) : 'N/A' }
        </GridItem>
        <GridItem className="timesheets-item-title ts-col-cost-center">
          { entryCostCenterName ? this.wrapText(entryCostCenterName, 26) : 'N/A' }
        </GridItem>
        <GridItem className="timesheets-item-title ts-col-cost-center-item">
          { entryCostCenterItemName ? this.wrapText(entryCostCenterItemName, 26) : 'N/A' }
        </GridItem>
        <GridItem className="timesheets-item-title bold blue ts-col-coworker">
          { this.wrapText(coworker.value, 16) }
        </GridItem>
        <GridItem className="timesheets-item-title ts-col-description">
          {this.wrapText(timeSheet.description, 24)}
        </GridItem>
        <GridItem className="timesheets-item-title bold right-aligned ts-col-time-estimated">
          {timeSheet.estimated_time ? this.wrapText(timeSheet.estimated_time, 5) : "N/A"}
        </GridItem>
        <GridItem className="timesheets-item-title bold right-aligned ts-col-time-spent">
          { timeFromHmi(timeSheet.time_spent) }
        </GridItem>
        <GridItem className="timesheets-item-title time-sheet-action-btn right-aligned ts-col-action-btn">
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleDeleteEntryClick(timeSheet)}>
            <img alt="time-sheet-delete-btn" src={CloseButton} />
          </IconButton>
          <IconButton aria-label="close" className="action-btn" onClick={() => this.handleEditEntryClick(index, timeSheet, entryPositionId)}>
            <img alt="time-sheet-edit-btn" src={EditButton} />
          </IconButton>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const {
      timeSheets,
      timeSheetBusy,
      metaData,
      coworkers,
      positionsList,
      positionCostCentersList,
      positionCostCenterItemsList,
      order,
      t
    } = this.props;
    const { isRemoveDialogOpen, isManualTimerOpen, filters, sortBy, sortByOrder, editing, isTimerOpen } = this.state;
    const positionsListPre = positionsList[order.id] ? positionsList[order.id] : [];
    const positionsListActual = [{id: -1, value: t('all_positions')}, ...positionsListPre];
    const positionId = parseInt(filters.position);
    const costCentersListPre = positionId != -1 ? (positionCostCentersList ? positionCostCentersList : []) : [];
    const costCentersListActual = [{id: -1, value: t('all_cost_centers')}, ...costCentersListPre];
    const costCenterItemsListPre = positionId != -1 ? (positionCostCenterItemsList ? positionCostCenterItemsList : []) : [];
    const costCenterItemsListActual = [{id: -1, value: t('all_cost_center_items')}, ...costCenterItemsListPre];
    const coworkersListActual = [{id: -1, value: t('all_employees')}, ...coworkers];
    const sortByList = ProjectTimesheets.SortByList.map(el => ({
      ...el,
      value: t(el.value),
    }));
    return (
      <>
        <div className="project-timesheets-outer-container">
          <GridContainer className="project-timesheets-container">
            {!timeSheetBusy ? (
              <>
                <GridItem className="project-timesheets-top" xs={12}>
                  <GridItem xs={3} />
                  <GridItem className="timesheets-top-right-bar">
                    <CustomDateRangePickerDropdown
                      dateFrom={filters.dateFrom}
                      dateTo={filters.dateTo}
                      dateFromPlaceholder={t('from') + "..."}
                      dateToPlaceholder={t('up_to') + "..."}
                      buttonPlaceholder={t('filter_dates') + "..."}
                      buttonProps={{
                        className: 'dropdown-blue-style',
                        color: 'transparent',
                        round: true,
                        size: 'sm'
                      }}
                      hoverColor="rose"
                      onChange={(code, date) => this.handleDateFilterChange(code == 0 ? 'dateFrom' : 'dateTo', date)}
                    />
                    { isCurrentUserManufacturer() ? <Dropdown
                      buttonText={filters.coworker ? filters.coworker : -1}
                      buttonProps={{
                        className: 'dropdown-blue-style',
                        round: true,
                        size: 'sm'
                      }}
                      dropdownList={coworkersListActual}
                      onClick={data => this.handleCoworkerFilterChange(data.id)}
                    /> : null }
                    <Dropdown
                      buttonText={filters.position ? filters.position : -1}
                      buttonProps={{
                        className: 'dropdown-blue-style timer-dropdown',
                        round: true,
                        size: 'sm'
                      }}
                      dropdownList={positionsListActual}
                      onClick={value => this.handlePositionDropdownChange(value.id)}
                    />
                    <Dropdown
                      buttonText={filters.position_cost_center ? filters.position_cost_center : -1}
                      buttonProps={{
                        className: 'dropdown-blue-style timer-dropdown',
                        round: true,
                        size: 'sm',
                        disabled: !filters.position,
                      }}
                      dropdownList={costCentersListActual}
                      onClick={value => this.handleCostCentersDropdownChange(value.id)}
                    />
                    <Dropdown
                      buttonText={filters.position_cost_center_item ? filters.position_cost_center_item : -1}
                      buttonProps={{
                        className: 'dropdown-blue-style timer-dropdown',
                        round: true,
                        size: 'sm',
                        disabled: !filters.position || !filters.position_cost_center,
                      }}
                      dropdownList={costCenterItemsListActual}
                      onClick={value => this.handleCostCenterItemsDropdownChange(value.id)}
                    />
                    { isCurrentUserManufacturer() ? <Dropdown
                      buttonText={sortBy}
                      buttonProps={{
                        className: 'dropdown-blue-style',
                        round: true,
                        size: 'sm'
                      }}
                      dropdownList={sortByList}
                      onClick={data => this.handleSortChange(data.id)}
                    /> : null }
                    <Button
                      className="ikt-ap_order-details-btn create-btn"
                      onClick={this.handleManualTimerClick}
                    >
                      + { t('log_time').toUpperCase() }
                    </Button>
                  </GridItem>
                </GridItem>
                <GridContainer
                  className="timesheets-header"
                  direction="row"
                >
                  <GridItem className={`timesheets-header-title ts-col-date${sortBy == 0 ? ' col-sort-active' : ''}`}>
                    <span onClick={sortBy == 0 ? this.handleSortFlipOrder : null}>
                      { t('date') } { sortBy == 0 && <img alt="arrow-sort-direction" src={sortByOrder ? MaterialArrowDown : MaterialArrowUp} /> }
                    </span>
                  </GridItem>
                  <GridItem className="timesheets-header-title ts-col-position">
                    { t('position') }
                  </GridItem>
                  <GridItem className="timesheets-header-title ts-col-cost-center">
                    { t('cost_center') }
                  </GridItem>
                  <GridItem className="timesheets-header-title ts-col-cost-center-item">
                    { t('cost_center_item') }
                  </GridItem>
                  <GridItem className="timesheets-header-title ts-col-coworker">
                    { t('employee') }
                  </GridItem>
                  <GridItem className="timesheets-header-title ts-col-description">
                    { t('note') }
                  </GridItem>
                  <GridItem className="timesheets-header-title right-aligned ts-col-time-estimated">
                    { t('estimated_time') }
                  </GridItem>
                  <GridItem className="timesheets-header-title right-aligned ts-col-time-spent">
                    { t('actual_time') }
                  </GridItem>
                  <GridItem className="timesheets-header-title right-aligned ts-col-action-btn">
                    { t('actions') }
                  </GridItem>
                </GridContainer>
                <GridContainer className="timesheets-content">
                { timeSheets && order.positions && timeSheets.length !== 0 && order.positions.length !== 0 ?
                    timeSheets.map((timeSheet, index) =>
                      this.renderTimeSheet(timeSheet, index)
                    ) :
                    <div className='timesheets-content-no-records'>
                      <span className='no-records'>{ t('no_records_found') }</span>
                    </div>
                }
                </GridContainer>
              </>
            ) : (
              <div className="ikt-ap_loader">
                <Loader />
              </div>
            )}
          </GridContainer>
          <ManualTimer
            isOpen={isManualTimerOpen}
            order={order}
            currentFilters={this.state.filters}
            currentSort={ProjectTimesheets.SortByList[this.state.sortBy].data}
            currentSortOrder={this.state.sortByOrder}
            currentSearchTerms={this.props.searchTerms}
            onClose={this.closeManualTimer}
            edit={editing}
          />
          {isRemoveDialogOpen && (
            <RemoveDialog
              isOpen={isRemoveDialogOpen}
              title={ t('remove_timesheet_entry') }
              text={ t('remove_timesheet_entry_confirmation') }
              onClose={this.handleRemoveDialogClose}
              onRemove={this.handleDeleteEntry}
            />
          )}
        </div>
        <GridContainer className="pagination-container">
          {metaData.last_page > 1 &&
            <CustomPagination
              pageCount={Math.ceil(metaData.total / metaData.per_page)}
              handlePageClick={this.handlePageClick}
            />
          } 
        </GridContainer>
      </>
    );
  }
}

const mapStateToProp = state => {
  return {
    timeSheets: state.timeSheets.timeEntries,
    timeSheetBusy: state.timeSheets.timeSheetBusy,
    metaData: state.timeSheets.timeEntriesMetaData,
    coworkers: state.providers.coworkersList,
    positionsList: state.timeSheetsProviders.positionsList,
    positionCostCentersList: state.timeSheetsProviders.positionCostCentersList,
    positionCostCenterItemsList: state.timeSheetsProviders.positionCostCenterItemsList,
    editError: state.timeSheets.editError,
    lastFinishedEditId: state.timeSheets.lastFinishedEditId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCoworkerTimeEntry: id => dispatch(getCoworkerTimeEntry(id)),
    deleteCoworkerTimeEntry: (id, filters, sortBy, sortByOrder, searchTerms) => dispatch(deleteCoworkerTimeEntry(id, filters, sortBy, sortByOrder, searchTerms)),
    filterCoworkerTimeSheets: (data, sortBy, sortByOrder, searchTerms) => dispatch(filterCoworkerTimeSheets(data, sortBy, sortByOrder, searchTerms)),
    getPositionsList: id => dispatch(getPositionsList(id)),
    getPositionCostCentersList: id => dispatch(getPositionCostCentersList(id)),
    getPositionCostCenterItemsList: id => dispatch(getPositionCostCenterItemsList(id)),
    resetLastFinishedEdit: () => dispatch(resetLastFinishedEdit()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withTranslation()(ProjectTimesheets));
