import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {progressServiceApi} from 'services/API';
import {
  getProgress,
  getProgressSuccess,
  getProgressFailure,
  filterProgress
} from '../store/actions/progress';
import {
  GET_PROGRESS,
  UPDATE_PROGRESS,
  FILTER_PROGRESS
} from '../store/actionTypes/progressActionTypes';

export const getProgressEpic = (actions$) => actions$.pipe(
  ofType(GET_PROGRESS),
  switchMap((action) => from(progressServiceApi.getProgress(action.payload)).pipe(
    switchMap((response) => {
      return of(getProgressSuccess(response))
    }),
    catchError( err => {
        return of(getProgressFailure(err))
      }
    )
  ))
);

export const filterProgressEpic = (actions$) => actions$.pipe(
  ofType(FILTER_PROGRESS),
  switchMap((action) => from(progressServiceApi.filterProgress(action.payload.id, action.payload.filters)).pipe(
    switchMap((response) => {
      return of(getProgressSuccess(response))
    }),
    catchError( err => {
        return of(getProgressFailure(err))
      }
    )
  ))
);

export const updateProgressEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_PROGRESS),
  switchMap((action) => from(progressServiceApi.updateProgress(action.payload.id, action.payload.progress)).pipe(
    switchMap(
      (response) =>
        of(filterProgress({id: action.payload.order_id, filters: action.payload.filters}))
    ),
    catchError( err => {
        return of(getProgressFailure(err))
      }
    )
  ))
);
