import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { userServiceApi } from 'services/API';
import {
  registerManufacturerFailure,
  registerManufacturerSuccess,
  getProfileDetailsFailure,
  getProfileDetailsSuccess,
  getUserOwnFailure,
  getUserOwnSuccess,
  forgotPasswordSuccess,
  forgotPasswordFailure
} from '../store/actions/auth';
import {
  REGISTER_MANUFACTURER,
  GET_PROFILE_DETAILS,
  GET_USER_OWN
} from '../store/actionTypes/user';
import i18n from 'i18next';

import {FORGOT_PASSWORD} from "../store/actionTypes/auth";

export const registerManufacturerEpic = (actions$) => actions$.pipe(
  ofType(REGISTER_MANUFACTURER),
  switchMap(({payload}) => from(userServiceApi.registerManufacturer(payload)).pipe(
    switchMap((response) => {
      return of(registerManufacturerSuccess(response))
    }),
    catchError( err => {
        return of(registerManufacturerFailure(err.responseBody))
      }
    )
  ))
);

export const forgotPasswordEpic = (actions$) => actions$.pipe(
  ofType(FORGOT_PASSWORD),
  switchMap((action) => from(userServiceApi.forgotPassword(action.payload)).pipe(
    switchMap((response) => {
      return of(forgotPasswordSuccess(response))
    }),
    catchError( err => {
        return of(forgotPasswordFailure(err.responseBody))
      }
    )
  ))
);

export const getProfileDetailsEpic = (actions$) => actions$.pipe(
  ofType(GET_PROFILE_DETAILS),
  switchMap(() => from(userServiceApi.getProfileDetails()).pipe(
    switchMap((response) => {
      return of(getProfileDetailsSuccess(response))
    }),
    catchError(err => {
        return of(getProfileDetailsFailure(err.responseBody))
      }
    )
  ))
);

export const getUserOwnEpic = (actions$) => actions$.pipe(
  ofType(GET_USER_OWN),
  switchMap(() => from(userServiceApi.getUserOwn()).pipe(
    switchMap((response) => {
      localStorage.setItem("authUserRoles", JSON.stringify(response.data.roles));
      i18n.changeLanguage(response.data.selected_language);
      return of(getUserOwnSuccess(response.data))
    }),
    catchError(err => {
        return of(getUserOwnFailure(err.responseBody))
      }
    )
  ))
);
