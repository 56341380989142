import {
  contactsDetailsPageItems,
  contactsShippingPageItems,
  contactsFinancePageItems,
  contactsNotesPageItems
} from '../constants/constants';
import i18n from 'i18next';

export const convertToFormData = (model, form, namespace) => {
  let formData = form || new FormData();

  for (let propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || model[propertyName] === null || model[propertyName] === undefined) continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date)
      formData.append(formKey, model[propertyName].toISOString());
    else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element, index) => {
        const tempFormKey = `${formKey}[${index}]`;
        if (typeof(element) == 'object') {
          convertToFormData(element, formData, tempFormKey);
        } else {
          formData.append(tempFormKey, element);
        }
      });
    }
    else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
      convertToFormData(model[propertyName], formData, formKey);
    else if(typeof model[propertyName] === 'object' && model[propertyName] instanceof File)
      formData.append(formKey, model[propertyName]);
    else
      formData.append(formKey, model[propertyName].toString());
  }
  return formData;
};

export const getStyleProgressDependsOnStatus = (statusId, isOrder) => { 
  const status = statusId !== null && statusId !== undefined ? statusId.toString() : '';
  let style = '';
  let progress = 0;
  if (isOrder)
    switch (status) {
      case '0': // preparation
        style = 'first-order-status';
        progress = 16.67;
        break;
      case '1': // manufacturing
        style = 'second-order-status';
        progress = 33.33;
        break;
      case '2': // assembly
        style = 'third-order-status';
        progress = 50;
        break;
      case '3': // manufactured
        style = 'fourth-order-status';
        progress = 66.67;
        break;
      case '4': // post-calculation
        style = 'fifth-order-status';
        progress = 75;
        break;
      case '5': // archive
        style = 'sixth-order-status';
        progress = 100;
        break;
      default:
        style = 'gray';
        progress = 0;
        break;
    }
  else
    switch (status) {
      case '0': // pending
        style = 'first-order-status';
        progress = 15;
        break;
      case '1': // accepted
        style = 'fourth-order-status';
        progress = 100;
        break;
      case '3': // cancelled
        style = 'fifth-order-status';
        progress = 0;
        break;
      default:
        style = 'gray';
        progress = 0;
        break;
    }
  return {style, progress}
};

export const getInvoiceStyleProgressDependsOnStatus = (statusId) => {
  const status = statusId !== null && statusId !== undefined ? statusId.toString() : '';
  let style = '';
  switch (status) {
    case '0': // open
      style = 'orange';
      break;
    case '1': // partially paid
      style = 'kellyGreen';
      break;
    case '2': // paid
      style = 'blue';
      break;
    default:
      style = 'gray';
      break;
  }
  return {style};
};

export const getContactsErrorPage = (errors) => {
  let isErrorOnDetailsPage = false;
  let isErrorOnShippingPage = false;
  let isErrorOnFinancePage = false;
  let isErrorOnNotesPage = false;
  contactsDetailsPageItems.map(item => errors[item] ? isErrorOnDetailsPage = true : null);
  // snce there can be multiple shipping_addresses we will just check if key exists in errors
  isErrorOnShippingPage = Object.keys(errors).some( key => {
      return key.startsWith('shipping_addresses');
  });
  contactsFinancePageItems.map(item => errors[item] ? isErrorOnFinancePage = true : null);
  contactsNotesPageItems.map(item => errors[item] ? isErrorOnNotesPage = true : null);
  return isErrorOnDetailsPage ? 0 : isErrorOnShippingPage ? 1 :  isErrorOnFinancePage ? 2 :  isErrorOnNotesPage ? 3 : 0
}

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getPersistedSidebarState = () => {
  const sidebarState = localStorage.getItem('sidebarState');
  if (sidebarState) {
    return JSON.parse(sidebarState);
  }
  return null;
};

export const persistSidebarState = (sidebarState) => {
  if (sidebarState) {
    const jsonString = JSON.stringify(sidebarState);
    localStorage.setItem('sidebarState', jsonString);
  }
};

export const isUserAuthorized = (roles) => {
  const userRoles = JSON.parse(
    localStorage.getItem("authUserRoles"));
  for (let i in userRoles) {
    if (roles.indexOf(userRoles[i].name) != -1) {
      return true;
    }
  }
  return false;
};

export const extractNonnegativeNumberFromString = (str) => {
  const REGEXP = /[\d]*[.]{0,1}[\d]+/g;
  if (str)
    return str.match(REGEXP);
  else
    return null;
}

export const checkArraySetEquality = (array1, array2) => {
  const set1 = new Set(array1),
        set2 = new Set(array2);
  if (set1.size !== set2.size)
    return false;
  for (let b of set2)
    if (!set1.has(b))
      return false;
  return true;
}

export const transformContactCategoriesHierarchy = (list) => {
  if (list) {
    return list.map(el => ({
      id: el.id,
      value: el.name,
      children: el.children && el.children.length > 0 ? transformContactCategoriesHierarchy(el.children) : null,
    }));
  } else {
    return null;
  }
}

export const flattenContactCategoriesHierarchyList = (list) => {
  if (list) {
    let result = [];
    for (let i = 0, len = list.length; i < len; ++i) {
      const el = list[i];
      const subtree = flattenContactCategoriesHierarchyList(el.children);
      result = [...result, el, ...subtree];
    }
    return result;
  } else {
    return [];
  }
};

export const findPropInContactCategoriesHierarchyList = (list, propId) => {
  for (let i in list) {
    const element = list[i];
    if (element.id === propId) {
      return element;
    } else if (element.children) {
      const subtreeProp = findPropInContactCategoriesHierarchyList(element.children, propId);
      if (subtreeProp)
        return subtreeProp;
    }
  }
  return null;
};

export const calculateMaterialRetailPrice = (purchasingPrice, discountPercentage, surchargePercentage, wastePercentage) => {
  const step0 = purchasingPrice;
  const step1 = step0 * (1 - discountPercentage / 100.0);
  const step2 = step1 * (1 + surchargePercentage / 100.0);
  const step3 = step2 * (1 + wastePercentage / 100.0);
  return step3;
};

export const isCurrentUserManufacturer = () => {
  const userRoles = JSON.parse(localStorage.getItem("authUserRoles"));
  return userRoles && userRoles.find(el => el.name == "admin" || el.name == "super-admin" || el.name == "manufacturer");
};

export const getNumberFormatSeparators = () => {
  let thousandsSeparator, decimalSeparator;
  if (i18n.language == 'en') {
    thousandsSeparator = ',';
    decimalSeparator = '.';
  } else if (i18n.language == 'de') {
    thousandsSeparator = '.';
    decimalSeparator = ',';
  }
  return {
    thousandsSeparator,
    decimalSeparator,
  };
};

export const formatNumericValue = (value, decimals=2) => {
  if (isNaN(value))
    return 'NaN';

  const { thousandsSeparator, decimalSeparator } = getNumberFormatSeparators();

  const number = value;
  const dec_point = decimalSeparator;
  const thousands_sep = thousandsSeparator;

  // https://stackoverflow.com/a/2901136
  var n = !isFinite(+number) ? 0 : +number, 
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      toFixedFix = function (n, prec) {
          // Fix for IE parseFloat(0.55).toFixed(0) = 0;
          var k = Math.pow(10, prec);
          return Math.round(n * k) / k;
      },
      s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
  if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};
