import {ofType} from 'redux-observable';
import {from, of, concat} from 'rxjs';
import {switchMap, mergeMap, catchError} from 'rxjs/operators';
import {manufacturerScheduleAPI} from 'services/API';
import {
  getNormalScheduleSuccess,
  getNormalScheduleFailure,
  updateNormalScheduleSuccess,
  updateNormalScheduleFailure,
  getDateScheduleSuccess,
  getDateScheduleFailure,
  updateDateScheduleSuccess,
  updateDateScheduleFailure,
  deleteDateScheduleSuccess,
  deleteDateScheduleFailure,
  getMonthScheduleSuccess,
  getMonthScheduleFailure
} from '../store/actions/manufacturerSchedule';
import {
  GET_NORMAL_SCHEDULE,
  UPDATE_NORMAL_SCHEDULE,
  GET_DATE_SCHEDULE,
  UPDATE_DATE_SCHEDULE,
  DELETE_DATE_SCHEDULE,
  GET_MONTH_SCHEDULE
} from '../store/actionTypes/manufacturerScheduleActionTypes';

export const getNormalScheduleEpic = (actions$) => actions$.pipe(
  ofType(GET_NORMAL_SCHEDULE),
  switchMap(({payload}) => from(manufacturerScheduleAPI.getNormalSchedule(payload)).pipe(
    switchMap((response) => {
      return of(getNormalScheduleSuccess(response.data))
    }),
    catchError( err => {
        return of(getNormalScheduleFailure(err))
      }
    )
  ))
);

export const updateNormalScheduleEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_NORMAL_SCHEDULE),
  mergeMap((action) => from(manufacturerScheduleAPI.updateNormalSchedule(action.payload.day, action.payload.data)).pipe(
    switchMap((response) => {
      return of(updateNormalScheduleSuccess(response))
    }),
    catchError( err => {
        return of(updateNormalScheduleFailure(err))
      }
    )
  ))
);

export const getDateScheduleEpic = (actions$) => actions$.pipe(
  ofType(GET_DATE_SCHEDULE),
  switchMap(({payload}) => from(manufacturerScheduleAPI.getDateSchedule(payload)).pipe(
    switchMap((response) => {
      return of(getDateScheduleSuccess(response.data))
    }),
    catchError( err => {
        return of(getDateScheduleFailure(err))
      }
    )
  ))
);

export const updateDateScheduleEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_DATE_SCHEDULE),
  mergeMap((action) => from(manufacturerScheduleAPI.updateDateSchedule(action.payload.day, action.payload.data)).pipe(
    switchMap((response) => {
      return of(updateDateScheduleSuccess(response))
    }),
    catchError( err => {
        return of(updateDateScheduleFailure(err))
      }
    )
  ))
);

export const removeDateScheduleEpic = (actions$) => actions$.pipe(
  ofType(DELETE_DATE_SCHEDULE),
  mergeMap((action) => from(manufacturerScheduleAPI.deleteDateSchedule(action.payload)).pipe(
    switchMap((response) => concat(
      of(deleteDateScheduleSuccess(response)),
    )),
    catchError( err => {
        return of(deleteDateScheduleFailure(err))
      }
    )
  ))
);

export const getMonthScheduleEpic = (actions$) => actions$.pipe(
  ofType(GET_MONTH_SCHEDULE),
  switchMap(() => from(manufacturerScheduleAPI.getMonthSchedule()).pipe(
    switchMap((response) => {
      return of(getMonthScheduleSuccess(response.data))
    }),
    catchError( err => {
        return of(getMonthScheduleFailure(err))
      }
    )
  ))
);
