import {
  GET_PROJECT_CATEGORIES,
  GET_PROJECT_CATEGORIES_SUCCESS,
  GET_PROJECT_CATEGORIES_ERROR
} from 'store/actionTypes/projectCategories';

export const getProjectCategories = () => {
  return { type: GET_PROJECT_CATEGORIES };
};

export const getProjectCategoriesSuccessful = projectCategories => {
  return { type: GET_PROJECT_CATEGORIES_SUCCESS, payload: projectCategories };
};

export const getProjectCategoriesFailed = error => {
  return { type: GET_PROJECT_CATEGORIES_ERROR, payload: error };
};
