import BaseAPIService from './Base';

export default class MaterialCategoriesAPIService extends BaseAPIService {
  createMaterialCategory = async (data) => {
    return this.request('api/material-categories', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  updateMaterialCategory = async (id, data) => {
    return this.request(`api/material-categories/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  deleteMaterialCategory = async (id) => {
    return this.request(`api/material-categories/${id}`, {
      method: 'DELETE',
    });
  };

  getMaterialCategory = async (id) => {
    return this.request(`api/material-categories/${id}`).then((data) => {
      return data;
    });
  };

  getMaterialCategories = async (page, searchTerms, isGetAllData) => {
    return this.request(`api/material-categories?page=${page ? page : 1}${searchTerms ? ("&search-term=" + searchTerms) : ''}${isGetAllData ? '&per-page=0' : ''}`).then((data) => {
      return data;
    });
  };

  getMaterialCategoriesParents = async (searchTerms) => {
    return this.request(`api/material-categories-parents${searchTerms ? ("?search-term=" + searchTerms) : ''}`).then((data) => {
      return data;
    });
  } 

  getMaterialCategorySettings = async () => {
    return this.request(`api/manufacturer-material-category-specific-settings`).then((data) => {
      return data;
    });
  };

}
