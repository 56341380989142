import { handleActions } from 'redux-actions';
import {
  GET_ADMIN_TIME_ENTRIES,
  GET_ADMIN_TIME_ENTRIES_SUCCESS,
  GET_ADMIN_TIME_ENTRIES_ERROR,
  CREATE_ADMIN_TIME_ENTRY,
  CREATE_ADMIN_TIME_ENTRY_SUCCESS,
  CREATE_ADMIN_TIME_ENTRY_ERROR,
  CREATE_ADMIN_TIME_ENTRY_FINISH,
  UPDATE_ADMIN_TIME_ENTRY,
  UPDATE_ADMIN_TIME_ENTRY_SUCCESS,
  UPDATE_ADMIN_TIME_ENTRY_ERROR,
} from '../actionTypes/adminTimeSheetActionTypes';
import {
  GET_COWORKER_TIME_ENTRIES,
  GET_COWORKER_TIME_ENTRIES_SUCCESS,
  GET_COWORKER_TIME_ENTRIES_ERROR,
  CREATE_COWORKER_TIME_ENTRY,
  CREATE_COWORKER_TIME_ENTRY_SUCCESS,
  CREATE_COWORKER_TIME_ENTRY_ERROR,
  CREATE_COWORKER_TIME_ENTRY_FINISH,
  UPDATE_COWORKER_TIME_ENTRY,
  UPDATE_COWORKER_TIME_ENTRY_SUCCESS,
  UPDATE_COWORKER_TIME_ENTRY_ERROR,
  FILTER_COWORKER_TIME_SHEETS,
  RESET_LAST_FINISHED_EDIT,
  RESET_TIME_ENTRY_DATA
} from '../actionTypes/coworkerTimeSheetActionsTypes';

export const DEFAULT_TIME_SHEETS_STATE = {
  timeSheetBusy: false,
  timeEntries: [],
  timeEntriesMetaData: {},
  selectedTimeEntry: {},
  filterRequest: null,
  uploadBusy: false,
  isTimeEntryValid: false,
  createResponses: {
    uids: [],
    successes: {},
    errors: {},
  },
  editError: null,
  lastFinishedEditId: null,
};

export const timeSheets = handleActions(
  {
    [GET_ADMIN_TIME_ENTRIES]: (state) => ({
      ...state,
      timeEntries: [],
      timeSheetBusy: true,
    }),
    [GET_ADMIN_TIME_ENTRIES_SUCCESS]: (state, action) => ({
      ...state,
      timeSheetBusy: false,
      timeEntries: action.payload.data,
      timeEntriesMetaData: action.payload.meta,
    }),
    [GET_ADMIN_TIME_ENTRIES_ERROR]: (state) => ({
      ...state,
      timeSheetBusy: false
    }),
    [GET_COWORKER_TIME_ENTRIES]: (state) => ({
      ...state,
      timeEntries: [],
      timeSheetBusy: true,
    }),
    [GET_COWORKER_TIME_ENTRIES_SUCCESS]: (state, action) => ({
      ...state,
      timeSheetBusy: false,
      timeEntries: action.payload.data,
      timeEntriesMetaData: action.payload.meta,
    }),
    [GET_COWORKER_TIME_ENTRIES_ERROR]: (state) => ({
      ...state,
      timeSheetBusy: false
    }),
    [CREATE_ADMIN_TIME_ENTRY]: (state, action) => ({
      ...state,
      createResponses: {
        uids: action.payload.entries.map(el => el.uid),
        successes: {},
        errors: {},
      },
      uploadBusy: true,
    }),
    [CREATE_ADMIN_TIME_ENTRY_SUCCESS]: (state, action) => ({
      ...state,
      createResponses: {
        ...state.createResponses,
        successes: {
          ...state.createResponses.successes,
          [action.payload.uid]: action.payload.data,
        },
      }
    }),
    [CREATE_ADMIN_TIME_ENTRY_ERROR]: (state, action) => ({
      ...state,
      createResponses: {
        ...state.createResponses,
        errors: {
          ...state.createResponses.errors,
          [action.payload.uid]: action.payload.error,
        },
      }
    }),
    [CREATE_ADMIN_TIME_ENTRY_FINISH]: (state, action) => ({
      ...state,
      uploadBusy: false,
    }),
    [CREATE_COWORKER_TIME_ENTRY]: (state, action) => ({
      ...state,
      createResponses: {
        uids: action.payload.entries.map(el => el.uid),
        successes: {},
        errors: {},
      },
      uploadBusy: true,
    }),
    [CREATE_COWORKER_TIME_ENTRY_SUCCESS]: (state, action) => ({
      ...state,
      isTimeEntryValid: true,
      createResponses: {
        ...state.createResponses,
        successes: {
          ...state.createResponses.successes,
          [action.payload.uid]: action.payload.data,
        },
      }
    }),
    [CREATE_COWORKER_TIME_ENTRY_ERROR]: (state, action) => ({
      ...state,
      createResponses: {
        ...state.createResponses,
        errors: {
          ...state.createResponses.errors,
          [action.payload.uid]: action.payload.error,
        },
      }
    }),
    [CREATE_COWORKER_TIME_ENTRY_FINISH]: (state, action) => ({
      ...state,
      uploadBusy: false,
    }),
    [UPDATE_ADMIN_TIME_ENTRY]: (state, action) => ({
      ...state,
      uploadBusy: true,
      lastFinishedEditId: null,
      editError: null,
    }),
    [UPDATE_ADMIN_TIME_ENTRY_SUCCESS]: (state, action) => ({
      ...state,
      uploadBusy: false,
      lastFinishedEditId: action.payload.id,
      editError: null,
    }),
    [UPDATE_ADMIN_TIME_ENTRY_ERROR]: (state, action) => ({
      ...state,
      uploadBusy: false,
      lastFinishedEditId: action.payload.id,
      editError: action.payload.error,
    }),
    [UPDATE_COWORKER_TIME_ENTRY]: (state, action) => ({
      ...state,
      uploadBusy: true,
      lastFinishedEditId: null,
      editError: null,
    }),
    [UPDATE_COWORKER_TIME_ENTRY_SUCCESS]: (state, action) => ({
      ...state,
      uploadBusy: false,
      isTimeEntryValid: true,
      lastFinishedEditId: action.payload.id,
      editError: null,
    }),
    [UPDATE_COWORKER_TIME_ENTRY_ERROR]: (state, action) => ({
      ...state,
      uploadBusy: false,
      lastFinishedEditId: action.payload.id,
      editError: action.payload.error,
    }),
    [FILTER_COWORKER_TIME_SHEETS]: (state, action) => ({
      ...state,
      filterRequest: {
        filters: action.payload.filters,
        sortBy: action.payload.sortBy,
        sortByOrder: action.payload.sortByOrder,
        searchTerms: action.payload.searchTerms,
      },
    }),
    [RESET_LAST_FINISHED_EDIT]: (state, action) => ({
      ...state,
      lastFinishedEditId: null,
      editError: null,
    }),
    [RESET_TIME_ENTRY_DATA]: (state, action) => ({
      ...state,
      isTimeEntryValid: false
    }),
  },
  DEFAULT_TIME_SHEETS_STATE
);
