import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {switchMap, catchError, mergeMap} from 'rxjs/operators';
import {inquiryServiceApi} from 'services/API';
import {
  getInquiries,
  getInquiriesSuccess,
  getInquiriesFailure,
  getInquiry,
  getInquirySuccess,
  getInquiryFailure,
  createInquirySuccess,
  createInquiryFailure,
  updateInquirySuccess,
  updateInquiryFailure
} from '../store/actions/inquiries';
import {
  GET_INQUIRIES,
  GET_INQUIRY,
  CREATE_INQUIRY,
  REMOVE_INQUIRY,
  UPDATE_INQUIRY
} from '../store/actionTypes/inquiriesActionTypes';
import NotificationService from "../services/NotificationService";

export const getInquiriesEpic = (actions$) => actions$.pipe(
  ofType(GET_INQUIRIES),
  switchMap(() => from(inquiryServiceApi.getInquiries()).pipe(
    switchMap((response) => {
      return of(getInquiriesSuccess(response))
    }),
    catchError( err => {
        return of(getInquiriesFailure(err))
      }
    )
  ))
);

export const getInquiryEpic = (actions$) => actions$.pipe(
  ofType(GET_INQUIRY),
  switchMap(({payload}) => from(inquiryServiceApi.getInquiry(payload)).pipe(
    switchMap((response) => {
      return of(getInquirySuccess(response.data))
    }),
    catchError( err => {
        return of(getInquiryFailure(err))
      }
    )
  ))
);

export const createInquiryEpic = (actions$) => actions$.pipe(
  ofType(CREATE_INQUIRY),
  switchMap((action) => from(inquiryServiceApi.createInquiry(action.payload)).pipe(
    switchMap((response) => {
      return of(createInquirySuccess(response))
    }),
    catchError( err => {
        return of(createInquiryFailure(err))
      }
    )
  ))
);


export const removeInquiryEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_INQUIRY),
  switchMap((action) => from(inquiryServiceApi.deleteInquiry(action.payload)).pipe(
    switchMap((response) => {
      NotificationService.success('Order was removed');
      return of(getInquiries())
    }),
    catchError( err => {
        return of(getInquiriesFailure(err))
      }
    )
  ))
);

export const updateInquiryEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_INQUIRY),
  mergeMap(({payload}) => from(inquiryServiceApi.updateInquiry(payload.id, payload.data)).pipe(
    switchMap((response) => {
      return of(updateInquirySuccess(response))
    }),
    catchError( err => {
        return of(updateInquiryFailure(err))
      }
    )
  ))
);
