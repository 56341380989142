import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {Link, Prompt} from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import {getInquiryStatusList, getMaterialsList, getOrderStatusList, resetProvidersData, getDeliveryTypeList, getPaymentTypeList, getCoworkersList, getCustomersList} from 'store/actions/providers';
import {getManufacturerContacts} from "store/actions/manufacturer";
import {convertToFormData} from 'utils/utils';
import {
  createOrder,
  updateOrder,
  getOrder,
  validateOrderDetails,
  resetOrderDetailsData,
  validateOrderPositions,
  validateOrderPositionImportFile,
  resetOrderPositionsData,
  validateOrderCostCenter,
  resetOrderCostCenterData,
  validateOrderFittings,
  resetOrderFittingsData,
  resetOrderData,
  resetImportData,
  startPositionFileImport,
  startTaskFileImport
} from 'store/actions/orders';
import { getProjectsSettings } from "../../store/actions/settings";

import Button from 'components/CustomButtons/Button.jsx';
import FileImportDialog from '../FileImportInfo';
import ImportCustomerDialog from '../ImportCustomerDialog';
import ImportMissingMaterialsDialog from '../ImportMissingMaterialsDialog';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ProjectTabs from 'components/ProjectTabs/ProjectTabs';
import Loader from 'components/Loader/Loader';
import ProjectDetails from '../ProjectOrderDetails';
import MaterialList from '../MaterialList';
import CostCenter from '../CostCenter';
import Tasks from '../Tasks';
import Fittings from '../Fittings';
import CreateContact from '../CreateContact';
import LocalStorageDialog from '../LocalStorageDialog/index';
import ImportErrorDialog from '../ImportErrorDialog';
import CloseDialog from '../CloseDialog';
import ConfirmDialog from "../ConfirmDialog";

import {
  CreateOrderAllTabs,
  CreateInquiryAllTabs
} from 'constants/constants';

import './style.scss';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import {getOrderPositionDefaultCostCenters} from "store/actions/orders";
import {getCustomer} from "../../store/actions/customers";

class CreateOrder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOrder: false,
      selectedTabId: 0,
      importedCustomerData: {},
      isCreateCustomerOpen: false,
      isOrderReady: false,
      isImportDialogOpen: false,
      isImportErrorDialogOpen: false,
      isLocalStorageDialogOpen: false,
      isImportCustomerDialogOpen: false,
      isImportMissingMaterialsDialogOpen: false,
      isStatusChangeDialogOpen: false,
      isNewPositionCreated: false,
      newSubpositionIndex: -1,
      selectedPositionId: '',
      orderDetails: {
        name: '',
        description: '',
        coworker_id: '',
        due_date: moment().format('YYYY-MM-DD'),
        customer_id: '',
        status: '',
        type: 0,
        origin_type: '0',
        contact_person: '',
        delivery_type: '',
        payment_type: '',
        linked_inquiries: [],
        inquiries_added: [],
      },
      orderDetailsErrors: [],
      positionsData: [],
      positionsErrors: [],
      fittings: [],
      fittingsErrors: [],
      costCenters: [],
      costCenterErrors: [],
      support_files: [],
      imported_files: [],
      haveEditChanges: false,
      navigateAwayData: null,
      isCloseDialogOpen: false,
    };
    this.props.history.listen(() => {
      this.setState({
        selectedTabId: (this.props.history.location.state && this.props.history.location.state.selectedTabId) ? this.props.history.location.state.selectedTabId : 0,
      })
    });
  }

  componentDidMount() {
    this.props.getCustomers();
    this.props.getCoworkers();
    this.props.getMaterials();
    this.props.getOrderStatusList();
    this.props.getInquiryStatusList();
    this.props.getManufacturerContacts();
    this.props.getDeliveryTypeList();
    this.props.getPaymentTypeList();
    this.props.getDefaultCostCenters();
    this.checkIsOrder();
    if (this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id);
    } else {
      this.props.getProjectsSettings();
    }
    if(localStorage.getItem('orderData') && !this.props.match.params.id) {
      const data = JSON.parse(localStorage.getItem('orderData'));
      const isOpen = data.orderDetails.type === 0 && this.props.match.url === '/admin/orders/create-order' || data.orderDetails.type === 1 && this.props.match.url === '/admin/inquiries/create-inquiry';
      this.setState({
        isLocalStorageDialogOpen: isOpen,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.match.params.id && prevProps.projectsSettings !== this.props.projectsSettings && this.props.projectsSettings) {
      // set default owner for order / inquiry
      let selectedOwnerId = '';
      if (this.props.match.url === '/admin/orders/create-order') {
        if (this.props.projectsSettings.project_settings_orders_default_owner)
          selectedOwnerId = this.props.projectsSettings.project_settings_orders_default_owner;
      } else {
        if (this.props.projectsSettings.project_settings_inquiries_default_owner)
          selectedOwnerId = this.props.projectsSettings.project_settings_inquiries_default_owner;
      }
      this.setState({
        orderDetails: {
          ...this.state.orderDetails,
          coworker_id: selectedOwnerId,
        }
      });
    }
    if (Object.keys(prevProps.orderDetailsErrors).length !== Object.keys(this.props.orderDetailsErrors).length) {
      this.props.history.push(this.props.match.url, {'selectedTabId': 0});
      this.setState({orderDetailsErrors: this.props.orderDetailsErrors})
    }
    if ((prevProps.orderPositionsErrors.length !== this.props.orderPositionsErrors.length) && this.props.orderPositionsErrors.length !== 0) {
      if (Object.keys(prevProps.orderDetailsErrors).length === 0) {
        let forwardToTabId = 2; // forward to tasks as default
        const errorsArr = this.props.orderPositionsErrors;
        for (let i = 0, len = errorsArr.length; i < len; ++i) {
          const errorObj = errorsArr[i];
          if (!errorObj)
            continue;
          if (Object.keys(errorObj).length > 1 || !errorObj['tasks']) {
            forwardToTabId = 1; // there is at least a single position (non-task) error, forward to positions
            break;
          }
        }
        this.props.history.push(this.props.match.url, {'selectedTabId': forwardToTabId});
      }
      this.setState({positionsErrors: this.props.orderPositionsErrors});
    }
    if (prevProps.orderFittingsErrors.length !== this.props.orderFittingsErrors.length) {
      if (Object.keys(prevProps.orderDetailsErrors).length === 0 && Object.keys(this.props.orderPositionsErrors).length === 0) {
        this.props.history.push(this.props.match.url, {'selectedTabId': 3});
      }
      this.setState({fittingsErrors: this.props.orderFittingsErrors})
    }
    if (prevProps.orderCostCenterErrors.length !== this.props.orderCostCenterErrors.length) {
      if (Object.keys(prevProps.orderDetailsErrors).length === 0 && Object.keys(this.props.orderPositionsErrors).length === 0 && Object.keys(this.props.orderFittingsErrors).length === 0) {
        this.props.history.push(this.props.match.url, {'selectedTabId': 4});
      }
      this.setState({costCenterErrors: this.props.orderCostCenterErrors})
    }
    if ((prevProps.isOrderCreated === false && this.props.isOrderCreated) ||
      (prevProps.isOrderUpdated === false && this.props.isOrderUpdated)
    ) {
      localStorage.removeItem('orderData');
      this.setState({
        haveEditChanges: false,
      }, () => {
        this.props.match.url.includes('admin/orders')
          ? this.props.history.push('/admin/orders')
          : this.props.history.push('/admin/inquiries');
      });
      if ((prevProps.redirectToOrderId === null || prevProps.redirectToOrderId === undefined) && (this.props.redirectToOrderId !== null && this.props.redirectToOrderId !== undefined)) {
        window.open(`/admin/orders/order-details/${this.props.redirectToOrderId}`, '_blank');
      }
    }
    if (prevProps.order !== this.props.order) {
      if (this.props.order && this.props.order.id == this.props.match.params.id && !this.props.order.can_edit) {
        this.props.history.replace(this.props.order.type === 0 ? '/admin/orders' : '/admin/inquiries');
      } else {
        this.init();
      }
    }
    if (prevProps.orderPositionImportValidationData.processID !== this.props.orderPositionImportValidationData.processID && this.props.orderPositionImportValidationData.processID !== '') {
      this.handleToggleImportDialog();
    }
    if (
      (prevProps.orderPositionImportValidationErrors !== this.props.orderPositionImportValidationErrors) &&
        Object.keys(this.props.orderPositionImportValidationErrors).length !== 0
    ) {
      this.handleToggleImportErrorDialog();
    }
    if ((prevProps.orderPositionImportedFileData.data.order.customer_id !== this.props.orderPositionImportedFileData.data.order.customer_id) && this.props.orderPositionImportedFileData.data.order.customer_id !== undefined) {
      this.setCustomerData();
      this.handleToggleImportCustomerDialog();
    }
    if (
      (prevProps.orderPositionImportedFileData.processID !== this.props.orderPositionImportedFileData.processID) &&
      this.props.orderPositionImportedFileData.data.order.customer_id === undefined && this.props.orderPositionImportedFileData.processID !== ''
    ) {
      if (this.props.orderPositionImportedFileData.data.missed_materials && this.props.orderPositionImportedFileData.data.missed_materials.length > 0) {
        this.handleToggleImportCustomerDialog();
      } else {
        this.addImportedPositions();
      }
    }
    if (
      (prevProps.orderTaskImportedFileData.data.processID !== this.props.orderTaskImportedFileData.data.processID) &&
      this.props.orderTaskImportedFileData.data.processID !== undefined && this.props.orderTaskImportedFileData.data.processID !== ''
    ) {
      const data = this.props.orderTaskImportedFileData.data.data;
      if (data.missed_materials && data.missed_materials.length > 0) {
        this.handleToggleImportMissingMaterialsDialog();
      } else {
        this.addImportedTasks();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetData();
    this.props.resetProvidersData();
  }

  checkIsOrder = () => {
    const {orderDetails} = this.state;
    const newOrderDetails = {...orderDetails};
    if (this.props.match.url === '/admin/orders/create-order') {
      newOrderDetails.type = 0;
      this.props.history.push('/admin/orders/create-order',{'selectedTabId': 0})
      this.setState({
        orderDetails: newOrderDetails
      });
    }
    if (this.props.match.url === '/admin/inquiries/create-inquiry') {
      newOrderDetails.type = 1;
      this.props.history.push('/admin/inquiries/create-inquiry',{'selectedTabId': 0})
      this.setState({
        orderDetails: newOrderDetails
      });
    }
    if (
      this.props.match.url.includes('/admin/orders/edit-order') || this.props.match.url.includes('/admin/inquiries/edit-inquiry')
    ) {
      const selectedTabId = this.props.history.location.state && this.props.history.location.state.tabId ? this.props.history.location.state.tabId : 0;
      if (this.props.history.location.state && this.props.history.location.state.isCreatePosition) {
        this.props.history.push(this.props.match.url,{'selectedTabId': selectedTabId, isCreatePosition: true})
      } else {
        this.props.history.push(this.props.match.url,{'selectedTabId': selectedTabId})
      }
    }
  }

  init = () => {
    const {
      order,
      getCustomer,
      getCustomers
    } = this.props;
    if (Object.keys(order).length !== 0) {
      getCustomer(order.customer_id);
      const currentOrderDetails = {
        name: order.name,
        description: order.description,
        coworker_id: order.coworker_id,
        due_date: order.due_date,
        customer_id: order.customer_id !== '' && order.customer_id !== null && order.customer_id !== undefined ? order.customer_id : (order.customer ? order.customer.id : null),
        status: order.status,
        type: order.type,
        origin_type: order.origin_type,
        contact_person: order.contact_person,
        delivery_type: order.delivery_type,
        payment_type: order.payment_type,
        linked_inquiries: order.linked_inquiries,
        inquiries_added: [],
      };
      const orderPositionsData = order.positions;
      let orderCostCenterData = [];
      let orderFittingsData = [];
      order.positions && order.positions.forEach(item => {
        orderCostCenterData.push({cost_centers: item.cost_centers});
        orderFittingsData.push({fittings: item.fittings});
      });
      const supportFiles = order.support_files;
      this.setState({
        orderDetails: currentOrderDetails,
        positionsData: orderPositionsData,
        fittings: orderFittingsData,
        costCenters: orderCostCenterData,
        support_files: supportFiles,
        isOrderReady: true,
      });
    }
  };

  initLocalStorageData = () => {
    const {getCustomer} = this.props;
    const orderData = JSON.parse(localStorage.getItem('orderData'));
    getCustomer(orderData.orderDetails.customer_id);
    this.setState({
      orderDetails: orderData.orderDetails,
      positionsData: orderData.positionsData,
      fittings: orderData.fittings,
      costCenters: orderData.costCenters,
      support_files: orderData.support_files,
      imported_files: orderData.imported_files,
      isLocalStorageDialogOpen: false
    });
  };

  handleMissedMaterialMapping = (data, missed_positions_map) => {
    if (missed_positions_map) {
      // using missed_positions_map to map missed to user-selected materials
      const missed_material_mapping_keys = Object.keys(data.missed_material_mapping);
      for (let i in missed_material_mapping_keys) {
        const missed_material_mapping_key = missed_material_mapping_keys[i];
        const missed_material_mapping_value = data.missed_material_mapping[missed_material_mapping_key];
        const position_task_keys = Object.keys(missed_material_mapping_value);
        for (let j in position_task_keys) {
          const position_task_key = position_task_keys[j];
          const position_task_value = missed_material_mapping_value[position_task_key];
          if (position_task_value) {
            const fields = Object.keys(position_task_value);
            for (let k in fields) {
              const field = fields[k];
              const fieldValue = position_task_value[field];
              const mapped_material = missed_positions_map.find(el => el.label == fieldValue);
              if (!mapped_material)
                continue; // should never happen
              data.positions[missed_material_mapping_key].tasks[position_task_key].data[field] = mapped_material.id;
            }
          }
        }
      }
    }
  }

  addImportedPositions = (missed_positions_map) => {
    const { orderPositionImportedFileData, defaultCostCenters } = this.props;
    const { orderDetails, positionsData, fittings, costCenters, imported_files, support_files } = this.state;

    const sanitizeNumericName = (numericName) => {
      if (!numericName)
        return numericName;

      const parts = numericName.split('.');
      for (let i = 0, len = parts.length; i < len; ++i) {
        const part = parts[i];
        const num = parseInt(part, 10);
        if (!isNaN(num))
          parts[i] = num;
        else
          return '';  // invalid name
      }
      return parts.join('.');
    };

    const importedPositions = [];
    const importedPositionsFittings = [];
    const importedPositionsCostCenters = [];
    
    const data = {...orderPositionImportedFileData.data};
    this.handleMissedMaterialMapping(data, missed_positions_map);

    const default_cost_centers_data = [];
    defaultCostCenters.map((item,index) => default_cost_centers_data.push({title: item.title, amount: item.amount, measurement: item.measurement, rate: item.rate, sort_no: index + 1, items: item.items.map(el => ({...el, id: null}))}));
    Object.keys(data.positions).forEach(item => {
      const position = data.positions[item];
      position.numeric_name = sanitizeNumericName(position.numeric_name)
      importedPositions.push({
        type: 0,
        is_alternative: 0,
        can_edit: true,
        ...position,
      });
      importedPositionsFittings.push({fittings: []});
      importedPositionsCostCenters.push({cost_centers: default_cost_centers_data});
    });
    const newImportedFiles = [...imported_files, orderPositionImportedFileData.supportFileID];

    let newPositions = [...positionsData, ...importedPositions];
    let newFittings = [...fittings, ...importedPositionsFittings];
    let newCostCenters = [...costCenters, ...importedPositionsCostCenters];

    // combine arrays to sort once
    for (let i = 0, len = newPositions.length; i < len; ++i) {
      newPositions[i].preserve_fittings = newFittings[i];
      newPositions[i].preserve_cost_centers = newCostCenters[i];
    }

    newPositions.sort(this.materialPositionsSortProc);

    // split arrays
    newFittings = new Array(newPositions.length);
    newCostCenters = new Array(newPositions.length);
    for (let i = 0, len = newPositions.length; i < len; ++i) {
      newFittings[i] = newPositions[i].preserve_fittings;
      delete newPositions[i].preserve_fittings;
      newCostCenters[i] = newPositions[i].preserve_cost_centers;
      delete newPositions[i].preserve_cost_centers;
    }

    const orderData = {
      orderDetails,
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      support_files,
      imported_files: newImportedFiles,
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderPositionsData();

    this.setState({
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      imported_files: newImportedFiles,
      importedCustomerData: {}
    });
  }

  addImportedTasks = (missed_positions_map) => {
    const {orderTaskImportedFileData} = this.props;
    const {orderDetails, positionsData, fittings, costCenters, imported_files, support_files} = this.state;
    let newPositionsData = [...positionsData];
    let newTasks = [];
    const data = {...orderTaskImportedFileData.data.data};
    this.handleMissedMaterialMapping(data, missed_positions_map);
    Object.keys(data.positions).forEach((item, index) => index === 0 ? newTasks = [...newTasks, ...data.positions[item].tasks] : null);
    newPositionsData[orderTaskImportedFileData.id].tasks = [...newPositionsData[orderTaskImportedFileData.id].tasks, ...newTasks];
    const newImportedFiles = [...imported_files, orderTaskImportedFileData.data.supportFileID];
    const orderData = {
      orderDetails,
      positionsData: newPositionsData,
      fittings,
      costCenters,
      support_files,
      imported_files: newImportedFiles,
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderPositionsData();
    this.setState({
      positionsData: newPositionsData,
      imported_files: newImportedFiles
    });
  };

  setCustomerData = () => {
    const {orderPositionImportedFileData} = this.props;
    this.setState({
      importedCustomerData:  orderPositionImportedFileData.data.order.customer
    })
  }

  handleFileChange = (e, id) => {
    const { resetImportData, validateImportedFile } = this.props;
    resetImportData();
    let formData = new FormData();
    formData.append('config[owner][type]', 'order');
    formData.append('config[parse_only]', '1');
    formData.append('config[has_header]', '1');
    formData.append('file', e.target.files[0]);
    validateImportedFile(formData);
    e.target.value = '';
    this.setState({
      selectedPositionId: id
    })
  }

  handleCancelButtonClick = () => {
    const {orderDetails} = this.state;
    localStorage.removeItem('orderData');
    const url = orderDetails.type === 0 ? '/admin/orders' : '/admin/inquiries';
    this.props.history.push(url);
  }

  onStepChange = id => {
    const {
      selectedTabId,
    } = this.state;
    if (id > selectedTabId || id === 4) {
      switch(selectedTabId) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
          this.props.history.push(this.props.match.url, {'selectedTabId':id})
          break;
        default:
          return null
      }
    } else if (id < selectedTabId || id === 0) {
      this.props.history.push(this.props.match.url, {'selectedTabId':id})
    }
  };

  isOrderDataReady = () => {
    const {
      orderStatusList,
      inquiryStatusList,
      paymentTypeList,
      deliveryTypeList,
    } = this.props;
    return (
      orderStatusList.length !== 0 &&
      inquiryStatusList.length !== 0 &&
      paymentTypeList.length !== 0 &&
      deliveryTypeList.length !== 0
    )
  };

  handleCreateCustomerOpen = () => {
    this.setState({
      isCreateCustomerOpen: true
    })
  };

  handleCreateCustomerClose = () => {
    this.setState({
      isCreateCustomerOpen: false
    });
    this.props.getCustomers();
  };

  handleToggleImportDialog = () => {
    this.setState({
      isImportDialogOpen: !this.state.isImportDialogOpen,
    })
  };

  handleToggleImportErrorDialog = () => {
    this.setState({
      isImportErrorDialogOpen: !this.state.isImportErrorDialogOpen,
    })
  };

  handleToggleImportCustomerDialog = () => {
    this.setState({
      isImportCustomerDialogOpen: !this.state.isImportCustomerDialogOpen,
    })
  };

  handleToggleImportMissingMaterialsDialog = () => {
    this.setState({
      isImportMissingMaterialsDialogOpen: !this.state.isImportMissingMaterialsDialogOpen,
    })
  };

  handleCloseLocalStorageDialog = () => {
    this.setState({
      isLocalStorageDialogOpen: false,
    })
  };

  handleCloseStatusChangeDialog = () => {
    this.setState({
      isStatusChangeDialogOpen: false,
    });
  }

  handleStartButton = () => {
    const {
      startImport,
      startTaskImport,
      orderPositionImportValidationData
    } = this.props;
    const {selectedPositionId}= this.state;
    const data = {processId: orderPositionImportValidationData.processID};
    selectedPositionId !== undefined ? startTaskImport({id:selectedPositionId, data: data}) : startImport(data);
    this.handleToggleImportDialog()
  }

  handleOrderDetailsData = (fieldName, value) => {
    const {orderDetails, positionsData, fittings, costCenters, support_files, imported_files} = this.state;
    const newOrderDetails = {...orderDetails, [fieldName]: value};
    const orderData = {
      orderDetails: newOrderDetails,
      positionsData,
      fittings,
      costCenters,
      support_files,
      imported_files
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderDetailsData();
    this.setState({orderDetails: newOrderDetails, haveEditChanges: Boolean(this.props.match.params.id)});
  };

  handleSupportFileChange = (data) => {
    this.setState({
      support_files: data
    })
  }

  addNewMaterialListPosition = (isArticle=false) => {
    const {defaultCostCenters} = this.props;
    const {
      orderDetails,
      positionsData,
      fittings,
      costCenters,
      support_files,
      imported_files
    } = this.state;
    
    let numericName = 1;
    if (positionsData.length > 0) {
      const lastPositionNN = positionsData[positionsData.length - 1].numeric_name;
      if (lastPositionNN) {
        const lastPositionNNFirstPart = lastPositionNN.split('.')[0];
        if (lastPositionNNFirstPart) {
          numericName = parseInt(lastPositionNNFirstPart) + 1;
        }
      }
    }
    const newPosition = {
      type: !isArticle ? 0 : 1,
      numeric_name: numericName.toString(),
      name: '',
      material_id: null,
      can_edit: true,
      can_delete: true,
      is_alternative: 0,
      coworker_id: '',
      due_date: moment().format('YYYY-MM-DD'),
      description: '',
      unit_price: null,
      metric: null,
      amount: null,
      tasks: [],
    };
    let default_cost_centers_data = [];
    defaultCostCenters.map((item,index) => default_cost_centers_data.push({title: item.title, amount: item.amount, measurement: item.measurement, rate: item.rate, sort_no: index + 1, items: item.items.map(el => ({...el, id: null}))}));
    const newCostCenterPosition = {
      cost_centers: default_cost_centers_data,
    };
    const newPositions = [...positionsData, newPosition];
    const newFittings = [...fittings, {fittings: []}];
    const newCostCenters = [...costCenters, newCostCenterPosition];
    this.props.resetOrderPositionsData();
    const orderData = {
      orderDetails,
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      support_files,
      imported_files
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.setState({
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      isNewPositionCreated: true,
      haveEditChanges: Boolean(this.props.match.params.id)
    });
  }

  addNewMaterialListSubposition = (index) => {
    const {defaultCostCenters} = this.props;
    const {
      orderDetails,
      positionsData,
      fittings,
      costCenters,
      support_files,
      imported_files
    } = this.state;

    const parent = positionsData[index];
    let existingDescendantsCount = 0;
    let existingChildrenCount = 0;
    if (parent.numeric_name) {
      const getNestingLevel = (numericName) => {
        return numericName.split('.').length;
      };
      const parentLevel = getNestingLevel(parent.numeric_name);
      existingChildrenCount = positionsData.filter(el => el.numeric_name.indexOf(parent.numeric_name) == 0 && el.numeric_name != parent.numeric_name && parentLevel == getNestingLevel(el.numeric_name) - 1).length;
      existingDescendantsCount = positionsData.filter(el => el.numeric_name.indexOf(parent.numeric_name) == 0 && el.numeric_name != parent.numeric_name).length;
    }
    const subpositionIndex = index + 1 + existingDescendantsCount;
    const newSubposition = {
      type: 0,
      numeric_name: `${parent.numeric_name ? `${parent.numeric_name}.${existingChildrenCount + 1}` : ''}`,
      name: '',
      material_id: null,
      can_edit: true,
      can_delete: true,
      is_alternative: 0,
      coworker_id: '',
      due_date: moment().format('YYYY-MM-DD'),
      description: '',
      unit_price: null,
      metric: null,
      amount: null,
      tasks: []
    };
    let default_cost_centers_data = [];
    defaultCostCenters.map((item,index) => default_cost_centers_data.push({title: item.title, amount: item.amount, measurement: item.measurement, rate: item.rate, sort_no: index + 1, items: item.items.map(el => ({...el, id: null}))}));
    const newCostCenterPosition = {
      cost_centers: default_cost_centers_data,
    };
    const newPositions = [...positionsData];
    newPositions.splice(subpositionIndex, 0, newSubposition);
    const newFittings = [...fittings];
    newFittings.splice(subpositionIndex, 0, { fittings: [] });
    const newCostCenters = [...costCenters];
    newCostCenters.splice(subpositionIndex, 0, newCostCenterPosition);
    this.props.resetOrderPositionsData();
    const orderData = {
      orderDetails,
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      support_files,
      imported_files
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.setState({
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      newSubpositionIndex: subpositionIndex,
      haveEditChanges: Boolean(this.props.match.params.id)
    });
  }

  materialPositionsSortProc = (pos1, pos2) => {
    if (!pos1.numeric_name && !pos2.numeric_name) {
      return 0;
    } else if (!pos1.numeric_name) {
      return -1;
    } else if (!pos2.numeric_name) {
      return 1;
    } else {
      const pos1NNParts = pos1.numeric_name.split('.');
      const pos2NNParts = pos2.numeric_name.split('.');
      for (let i = 0, len = Math.min(pos1NNParts.length, pos2NNParts.length); i < len; ++i) {
        const part1 = parseInt(pos1NNParts[i]);
        const part2 = parseInt(pos2NNParts[i]);
        if (part1 < part2) {
          return -1;
        } else if (part1 > part2) {
          return 1;
        } else {
          continue;
        }
      }
      if (pos1NNParts.length < pos2NNParts.length) {
        return -1;
      } else if (pos1NNParts.length > pos2NNParts.length) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  rearrangeMaterialListSubposition = (index, value) => {
    const { orderDetails, positionsData, costCenters, fittings, support_files, imported_files } = this.state;

    // new numeric name validation
    const valueParts = value.split('.').map(el => parseInt(el));
    if (positionsData[index].numeric_name === value) {
      // there is no reason to update on no change (provided that this is ever reached)
      return;
    } else if (value && valueParts.findIndex(el => isNaN(el) || el <= 0) !== -1) {
      // invalid value
      return;
    }

    const positionsIndexMapPrior = {};
    positionsData.forEach((el, index) => {
      positionsIndexMapPrior[el.id] = index;
    });

    const newPositions = [...positionsData];
    newPositions[index].numeric_name = value;
    newPositions.sort(this.materialPositionsSortProc);

    // rearrange the cost centers and fittings
    const newCostCenters = new Array(newPositions.length);
    const newFittings = new Array(newPositions.length)
    for (let i = 0, len = newPositions.length; i < len; ++i) {
      const currentPosition = newPositions[i];
      const indexPrior = positionsIndexMapPrior[currentPosition.id];
      newCostCenters[i] = costCenters[indexPrior];
      newFittings[i] =  fittings[indexPrior];
    }

    const orderData = {
      orderDetails,
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      support_files,
      imported_files
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderPositionsData();
    this.setState({
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      haveEditChanges: Boolean(this.props.match.params.id)
    });
  }

  removeMaterialListPosition = (index) => {
    this.removeMaterialListPositions([index]);
  };

  removeMaterialListPositions = (indeces) => {
    const { orderDetails, positionsData, fittings, costCenters, support_files, imported_files } = this.state;

    const newPositions = positionsData.filter((item, index) => indeces.indexOf(index) === -1);
    const newFittings = fittings.filter((item, index) => indeces.indexOf(index) === -1);
    const newCostCenters = costCenters.filter((item, index) => indeces.indexOf(index) === -1);

    const orderData = {
      orderDetails,
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      support_files,
      imported_files
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderPositionsData();
    this.setState({
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      haveEditChanges: Boolean(this.props.match.params.id)
    });
  };
  
  addInquiryMaterialListPositions = (id) => {
    const {inquiries} = this.props;
    const {
      orderDetails,
      positionsData,
      fittings,
      costCenters,
      support_files,
      imported_files
    } = this.state;
    const selectedInquiry = inquiries.find(item => item.id === id);
    const newPositions = [...positionsData];
    const newFittings = [...fittings];
    const newCostCenters = [...costCenters];

    selectedInquiry.positions.map(item => {
      newPositions.push({...item, inquiry_item: true, order_inquire_id: id});
      newFittings.push({fittings: item.fittings});
      newCostCenters.push({cost_centers: item.cost_centers});
    });

    const orderData = {
      orderDetails: {
        ...orderDetails,
        linked_inquiries: [...orderDetails.linked_inquiries, selectedInquiry],
        inquiries_added: [...orderDetails.inquiries_added, id],
      },
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      support_files,
      imported_files,
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderPositionsData();
    this.setState({
      orderDetails: orderData.orderDetails,
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      haveEditChanges: Boolean(this.props.match.params.id),
    });
  };

  removeInquiryMaterialListPositions = (id) => {
    const {inquiries} = this.props;
    const {
      orderDetails,
      positionsData,
      fittings,
      costCenters,
      support_files,
      imported_files
    } = this.state;

    const selectedInquiry = inquiries.find(item => item.id === id);

    // only if the inquiry was added in the current order edit do we remove its positions
    let newPositions, newFittings, newCostCenters;
    if (orderDetails.inquiries_added.indexOf(id) != -1) {
      const idsToRemove = selectedInquiry.positions.map(el => el.id);
      const indecesToRemove = [];
      positionsData.forEach((el, index) => {
        if (idsToRemove.indexOf(el.id) !== -1)
          indecesToRemove.push(index);
      });

      newPositions = positionsData.filter((_, index) => indecesToRemove.indexOf(index) === -1);
      newFittings = fittings.filter((_, index) => indecesToRemove.indexOf(index) === -1);
      newCostCenters = costCenters.filter((_, index) => indecesToRemove.indexOf(index) === -1);
    } else {
      newPositions = [ ...positionsData ];
      newFittings = [ ...fittings ];
      newCostCenters = [ ...costCenters ];
    }

    const orderData = {
      orderDetails: {
        ...orderDetails,
        inquiries_added: orderDetails.inquiries_added.filter(el => el != id),
        linked_inquiries: orderDetails.linked_inquiries.filter(el => el.id != id),
      },
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      support_files,
      imported_files
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderPositionsData();
    this.setState({
      orderDetails: orderData.orderDetails,
      positionsData: newPositions,
      fittings: newFittings,
      costCenters: newCostCenters,
      haveEditChanges: Boolean(this.props.match.params.id)
    });
  }

  handleMaterialListDataChange = (id, data) => {
    const {orderDetails, positionsData, fittings, costCenters, support_files, imported_files} = this.state;
    const newPositions = [...positionsData];
    newPositions[id] = data;
    const orderData = {
      orderDetails,
      positionsData: newPositions,
      fittings,
      costCenters,
      support_files,
      imported_files
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderPositionsData();
    this.setState({positionsData: newPositions, haveEditChanges: Boolean(this.props.match.params.id)});
  }

  handleTasksDataChange = (index, data) => {
    const {orderDetails, positionsData, fittings, costCenters, support_files, imported_files} = this.state;
    const newPositions = [...positionsData];
    newPositions[index].tasks = data;
    const orderData = {
      orderDetails,
      positionsData: newPositions,
      fittings,
      costCenters,
      support_files,
      imported_files
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderPositionsData();
    this.setState({positionsData: newPositions, haveEditChanges: Boolean(this.props.match.params.id)});
  }

  handleFittingsDataChange = (id, data) => {
    const {orderDetails, positionsData, fittings, costCenters, support_files, imported_files} = this.state;
    const newFittings = [...fittings];
    newFittings[id] = data;
    const orderData = {
      orderDetails,
      positionsData,
      fittings: newFittings,
      costCenters,
      support_files,
      imported_files,
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderFittingsData();
    this.setState({fittings: newFittings, haveEditChanges: Boolean(this.props.match.params.id)});
  }

  handleCostCenterDataChange = (id, data) => {
    const {orderDetails, positionsData, fittings, costCenters, support_files, imported_files} = this.state;
    const newCostCenters = [...costCenters];
    newCostCenters[id] = data;
    const orderData = {
      orderDetails,
      positionsData,
      fittings,
      costCenters: newCostCenters,
      support_files,
      imported_files,
    };
    localStorage.setItem('orderData', JSON.stringify(orderData));
    this.props.resetOrderCostCenterData();
    this.setState({costCenters: newCostCenters, haveEditChanges: Boolean(this.props.match.params.id)});
  }

  handleCreateUpdateOrder = () => {
    const {
      isOrderBusy,
    } = this.props;
    const {
      orderDetails,
      positionsData,
      fittings,
      costCenters,
      support_files,
      imported_files
    } = this.state;
    if (isOrderBusy)
      return;
    const purgeInquiryItemId = (obj) => {
      const result = { ...obj };
      if (result.id || result.id === 0) {
        delete result.id;
      }
      Object.keys(result).forEach(key => {
        if (typeof result[key] === "object" && result[key]) {
          result[key] = purgeInquiryItemId(result[key]);
        }
      });
      return result;
    };
    const order = {...orderDetails};
    order.linked_inquiries = order.linked_inquiries.map(el => ({ id: el.id }));
    order.inquiries_added = null;
    const positions = [...positionsData];
    positionsData.forEach((item, index) => {
      positions[index].fittings = fittings[index].fittings;
      positions[index].cost_centers = costCenters[index].cost_centers;
    });
    positionsData.forEach((item, index) => {
      if (positions[index].inquiry_item) {
        positions[index] = purgeInquiryItemId(positions[index]);
        delete positions[index].inquiry_item;
      }
    });
    order.type = order.type !== null ? order.type.toString() : '';
    order.status = order.status !== null ? order.status.toString() : '';
    order.origin_type = order.origin_type !== null ? order.origin_type.toString() : '';
    order.payment_type = order.payment_type !== null ? order.payment_type.toString() : '';
    order.due_date = order.due_date ? order.due_date : '';
    this.props.validateOrderDetails({order: orderDetails});
    this.props.validateOrderPositions({positions: positionsData});
    this.props.validateOrderFittings({positions: fittings});
    this.props.validateOrderCostCenter({positions: costCenters});
    let isOrder = this.props.match.url.includes('admin/orders');
    if (this.props.match.params.id) {
      const data = {
        order: order,
        positions: positions,
        imported_files: imported_files,
        support_files: support_files
      };
      let formData = convertToFormData(data);
      formData.append('_method', 'put');
      this.props.updateOrder({id: this.props.match.params.id, data: formData, isOrder})
    } else {
      const data = {
        order: order,
        positions: positions,
        imported_files: imported_files
      };
      let formData = convertToFormData(data);
      support_files.map((item, index) => formData.append(`support_files[${index}][file]`, support_files[index].file));
      this.props.createOrder({data: formData, isOrder})
    }
  }

  handleCreateUpdateOrderPrepare = () => {
    const { orderDetails } = this.state;

    if (this.props.match.params.id && orderDetails.type === 1 && this.props.order && this.props.order.status != 1 && orderDetails.status == 1) {  // 1 is hard-coded from BE (status Accepted)
      // prompt user to confirm changing inquiry status
      this.setState({
        isStatusChangeDialogOpen: true,
      });
    } else {
      // proceed to updating directly
      this.handleCreateUpdateOrder();
    }
  }

  handleOnClosePromptCancel = () => {
    this.setState({
      isCloseDialogOpen: false,
      navigateAwayData: null,
    });
  }

  handleOnClosePromptConfirm = () => {
    const { navigateAwayData } = this.state;
    this.setState({
      isCloseDialogOpen: false,
      navigateAwayData: null,
      haveEditChanges: false, // required so that navigation is not blocked anymore
    }, () => {
      const { history } = this.props;
      if (navigateAwayData.action == "PUSH") {
        history.push(navigateAwayData.location.pathname);
      } else if (navigateAwayData.action == "POP") {
        // due to some peculiarity, both are required
        history.goBack();
        history.goBack();
      }
    });
  }

  render() {
    const {
      coworkers,
      manufacturerContacts,
      orderStatusList,
      inquiryStatusList,
      paymentTypeList,
      deliveryTypeList,
      orderPositionImportValidationErrors,
      t
    } = this.props;
    const {
      selectedTabId,
      orderDetails,
      orderDetailsErrors,
      positionsData,
      positionsErrors,
      fittings,
      fittingsErrors,
      costCenters,
      costCenterErrors,
      importedCustomerData,
      isCreateCustomerOpen,
      isOrderReady,
      isImportDialogOpen,
      isImportCustomerDialogOpen,
      isImportMissingMaterialsDialogOpen,
      isImportErrorDialogOpen,
      isLocalStorageDialogOpen,
      isNewPositionCreated,
      newSubpositionIndex,
      support_files,
      haveEditChanges,
      isCloseDialogOpen,
      isStatusChangeDialogOpen,
    } = this.state;
    let missedTasksMaterials = null;
    if (this.props.orderTaskImportedFileData && this.props.orderTaskImportedFileData.data && this.props.orderTaskImportedFileData.data.data) {
      missedTasksMaterials = this.props.orderTaskImportedFileData.data.data.missed_materials;
    }
    let selectedComponent;

    let bottomMenuWidth = '100%';
    const bottomMenuCandidates = document.getElementsByClassName('create-project-bottom');
    if (bottomMenuCandidates && bottomMenuCandidates[0]) {
      const el = bottomMenuCandidates[0];
      bottomMenuWidth = getComputedStyle(el.parentNode).width;
    }
    const linkedInquiries = [ ...orderDetails.linked_inquiries.map(el => el.id) ];
    const addedInquiries = [ ...orderDetails.inquiries_added ];
    switch(selectedTabId) {
      case 0:
        selectedComponent =
          <ProjectDetails
            onDataChange={this.handleOrderDetailsData}
            onSupportFileChange={this.handleSupportFileChange}
            addInquiryMaterialListPositions={this.addInquiryMaterialListPositions}
            removeInquiryMaterialListPositions={this.removeInquiryMaterialListPositions}
            linkedInquiries={linkedInquiries}
            addedInquiries={addedInquiries}
            manufacturerContacts={manufacturerContacts}
            orderStatusList={orderStatusList}
            inquiryStatusList={inquiryStatusList}
            paymentTypeList={paymentTypeList}
            deliveryTypeList={deliveryTypeList}
            data={orderDetails}
            referentialOrderId={this.props.match.params.id}
            editing={Boolean(this.props.match.params.id)}
            positions={positionsData}
            support_files={support_files}
            errors={orderDetailsErrors}
          />;
        break;
      case 1:
        selectedComponent =
          <MaterialList
            onDataChange={this.handleMaterialListDataChange}
            onAddPosition={this.addNewMaterialListPosition}
            onAddSubposition={this.addNewMaterialListSubposition}
            onRearrangeSubposition={this.rearrangeMaterialListSubposition}
            onRemovePosition={this.removeMaterialListPosition}
            onRemovePositions={this.removeMaterialListPositions}
            positions={positionsData}
            fittingsPositions={fittings}
            isNewPositionCreated={isNewPositionCreated}
            newSubpositionIndex={newSubpositionIndex}
            errors={positionsErrors}
            coworkers={coworkers}
          />;
        break;
      case 2:
        selectedComponent =
          <Tasks
            onDataChange={this.handleTasksDataChange}
            onFileChange={this.handleFileChange}
            positionsData={positionsData}
            errors={positionsErrors}            
          />;
        break;
      case 3:
        selectedComponent =
          <Fittings
            onDataChange={this.handleFittingsDataChange}
            fittingsPositions={fittings}
            positionsData={positionsData}
            errors={fittingsErrors}
          />;
        break;
      case 4:
        selectedComponent =
          <CostCenter
            onDataChange={this.handleCostCenterDataChange}
            costCenterPositions={costCenters}
            positionsData={positionsData}
            errors={costCenterErrors}
          />;
        break;
      default:
        return null;
    }
    return (
      <div className="ikt-ap_create-order-container">
        {this.isOrderDataReady() && (this.props.match.params.id ? isOrderReady : true) ? (
          <GridContainer className="ikt-ap_create-order-content">
            <GridItem className="ikt-ap_create-order-top-wrapper" xs={12}>
              <GridContainer
                className="ikt-ap_create-order-top-container"
                direction="row"
                justify="flex-end"
              >
                <GridItem className="ikt-ap_create-project-top-left">
                  <ProjectTabs
                    tabs={orderDetails.type === 0 ? CreateOrderAllTabs: CreateInquiryAllTabs}
                    selectedTab={selectedTabId}
                    onTabItemClick={this.onStepChange}
                  />
                </GridItem>
                <GridItem className="ikt-ap_create-project-top-right">
                  {
                    this.props.order.id &&
                    <div className="project-number">
                      <span>
                        { t('order_number') }
                      </span>
                        <span className="project-number-title">
                          {this.props.order.order_no}
                      </span>
                    </div>
                  }
                  {
                    selectedTabId === 1 &&
                    <Button
                      className="ikt-ap_create-project-add-customer-btn position-btn"
                      component="label"
                    >
                      { t('import_positions_and_items').toUpperCase() }
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleFileChange}
                      />
                    </Button>
                  }
                  {
                    selectedTabId === 0 &&
                    <Button
                      className={`ikt-ap_create-project-add-customer-btn ${selectedTabId === 0 ? "position-btn" : ''}`}
                      onClick={this.handleCreateCustomerOpen}
                    >
                      { t('add_new_customer').toUpperCase() }
                    </Button>
                  }
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem className="ikt-ap_create-project-tab-content" xs={12}>
              {selectedComponent}
            </GridItem>
            <GridItem className="create-project-bottom" style={{width: bottomMenuWidth}} xs={12}>
              <Button
                className="cancel-btn"
                onClick={this.handleCancelButtonClick}
              >
                { t('cancel').toUpperCase() }
              </Button>
              <div className="next-back-btn-container">
                {
                  selectedTabId > 0 &&
                  <Button
                    className="cancel-btn back-btn"
                    onClick={() => this.onStepChange(selectedTabId !== 0 ? (selectedTabId - 1) : selectedTabId)}
                  >
                    { t('back').toUpperCase() }
                  </Button>
                }
                {
                  selectedTabId < 4 && <Button
                    className="next-btn"
                    onClick={() => this.onStepChange(selectedTabId + 1)}
                  >
                    { t('next').toUpperCase() }
                  </Button>
                }
                <Button
                  className="create-btn"
                  onClick={this.handleCreateUpdateOrderPrepare}
                >
                  { this.props.match.params.id ? (this.props.match.url.includes('admin/orders') ? t('update_order').toUpperCase() : t('update_inquiry').toUpperCase()) : (this.props.match.url.includes('admin/orders') ? t('create_order').toUpperCase() : t('create_inquiry').toUpperCase()) }
                </Button>
              </div>
            </GridItem>
            {isCreateCustomerOpen &&
              <CreateContact
                customerData={importedCustomerData}
                isOpen={isCreateCustomerOpen}
                isEditContact={false}
                onClose={this.handleCreateCustomerClose}
              />
            }
            <FileImportDialog
              isOpen={isImportDialogOpen}
              onClose={this.handleToggleImportDialog}
              onStartButton={this.handleStartButton}
            />
            {
              isImportCustomerDialogOpen &&
              <ImportCustomerDialog
                isOpen={isImportCustomerDialogOpen}
                onClose={this.handleToggleImportCustomerDialog}
                onCreateCustomerOpen={this.handleCreateCustomerOpen}
                includeCustomer={this.props.orderPositionImportedFileData.data.order.customer_id !== undefined}
                onCustomerChange={(value) => this.handleOrderDetailsData('customer_id', value)}
                onAddImportedPosition={this.addImportedPositions}
                orderCustomerId={orderDetails.customer_id}
              />
            }
            {
              isImportMissingMaterialsDialogOpen &&
              <ImportMissingMaterialsDialog
                isOpen={isImportMissingMaterialsDialogOpen}
                onClose={this.handleToggleImportMissingMaterialsDialog}
                onAdd={this.addImportedTasks}
                missedMaterials={missedTasksMaterials}
              />
            }
            <ImportErrorDialog
              isOpen={isImportErrorDialogOpen}
              error={orderPositionImportValidationErrors}
              onClose={this.handleToggleImportErrorDialog}
            />
            <LocalStorageDialog
              isOpen={isLocalStorageDialogOpen}
              isOrder={this.props.match.url === '/admin/orders/create-order'}
              onClose={this.handleCloseLocalStorageDialog}
              onRestoreOrder={this.initLocalStorageData}
            />
          </GridContainer>
        ) : (
          <div className="ikt-ap_loader">
            <Loader />
          </div>
        )}
        <Prompt
          message={(location, action) => {
            if (!window.location.pathname.endsWith(location.pathname)) { // prevents dialog pop on tab change
              this.setState({
                navigateAwayData: {location, action},
                isCloseDialogOpen: true,
              });
              return false;
            } else {
              return true;
            }
          }}
          when={haveEditChanges}
        />
        <CloseDialog
          isOpen={isCloseDialogOpen}
          title={this.props.match.url.indexOf('/admin/orders/edit-order') === 0 ? t('edit_order') : t('edit_inquiry')}
          onCancel={this.handleOnClosePromptCancel}
          onConfirm={this.handleOnClosePromptConfirm}
        />
        <ConfirmDialog
          isOpen={isStatusChangeDialogOpen}
          title={t('change_inquiry_status')}
          text={t('change_inquiry_status_confirmation')}
          onClose={this.handleCloseStatusChangeDialog}
          onConfirm={this.handleCreateUpdateOrder}
        />
      </div>
    );
  }
}

CreateOrder.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    customer: state.customers.customer,
    customers: state.providers.customersList,
    coworkers: state.providers.coworkersList,
    cities: state.locations.cities,
    manufacturerContacts: state.manufacturer.manufacturerContacts,
    orderStatusList: state.providers.orderStatusList,
    inquiryStatusList: state.providers.inquiryStatusList,
    paymentTypeList: state.providers.paymentTypeList,
    deliveryTypeList: state.providers.deliveryTypeList,
    projectsSettings: state.settings.projectsSettings,
    order: state.orders.currentOrder,
    inquiries: state.orders.clientInquiries,
    isOrderBusy: state.orders.ordersBusy,
    isOrderCreated: state.orders.isOrderCreated,
    isOrderUpdated: state.orders.isOrderUpdated,
    isOrderDetailsValid: state.orders.isOrderDetailsValid,
    orderDetailsErrors: state.orders.orderDetailsValidationErrors,
    isOrderPositionsValid: state.orders.isOrderPositionsValid,
    orderPositionsErrors: state.orders.orderPositionsValidationErrors,
    orderPositionImportValidationData: state.orders.orderPositionImportValidationData,
    orderPositionImportValidationErrors: state.orders.orderPositionImportValidationErrors,
    orderPositionImportedFileData: state.orders.orderPositionImportedFileData,
    orderTaskImportedFileData: state.orders.orderTaskImportedFileData,
    isOrderFittingsValid: state.orders.isOrderFittingsValid,
    orderFittingsErrors: state.orders.orderFittingsValidationErrors,
    isOrderCostCenterValid: state.orders.isOrderCostCenterValid,
    orderCostCenterErrors: state.orders.orderCostCenterValidationErrors,
    defaultCostCenters: state.orders.defaultCostCenterList,
    redirectToOrderId: state.orders.redirectToOrderId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrder: data => dispatch(createOrder(data)),
    updateOrder: data => dispatch(updateOrder(data)),
    getOrder: data => dispatch(getOrder(data)),
    getCustomer: (id) => dispatch(getCustomer(id)),
    getCustomers: (term) => dispatch(getCustomersList(term)),
    getCoworkers: () => dispatch(getCoworkersList()),
    getMaterials: () => dispatch(getMaterialsList()),
    getManufacturerContacts: () => dispatch(getManufacturerContacts()),
    getDefaultCostCenters: () => dispatch(getOrderPositionDefaultCostCenters()),
    getOrderStatusList: () => dispatch(getOrderStatusList()),
    getInquiryStatusList: () => dispatch(getInquiryStatusList()),
    getDeliveryTypeList: () => dispatch(getDeliveryTypeList()),
    getPaymentTypeList: () => dispatch(getPaymentTypeList()),
    getProjectsSettings: () => dispatch(getProjectsSettings()),
    validateOrderDetails: data => dispatch(validateOrderDetails(data)),
    resetOrderDetailsData: () => dispatch(resetOrderDetailsData()),
    validateOrderPositions: data => dispatch(validateOrderPositions(data)),
    validateImportedFile: data => dispatch(validateOrderPositionImportFile(data)),
    resetOrderPositionsData: () => dispatch(resetOrderPositionsData()),
    validateOrderFittings: data => dispatch(validateOrderFittings(data)),
    validateOrderCostCenter: data => dispatch(validateOrderCostCenter(data)),
    startImport: (data) => dispatch(startPositionFileImport(data)),
    startTaskImport: (data) => dispatch(startTaskFileImport(data)),
    resetOrderFittingsData: () => dispatch(resetOrderFittingsData()),
    resetOrderCostCenterData: () => dispatch(resetOrderCostCenterData()),
    resetData: () => dispatch(resetOrderData()),
    resetProvidersData: () => dispatch(resetProvidersData()),
    resetImportData: () => dispatch(resetImportData()),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(dashboardStyle)(withTranslation()(CreateOrder)));
