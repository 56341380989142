import {
  ADD_MANUFACTURER_ORDER,
  ADD_MANUFACTURER_ORDER_SUCCESS,
  ADD_MANUFACTURER_ORDER_ERROR,
  UPDATE_MANUFACTURER_ORDER,
  UPDATE_MANUFACTURER_ORDER_SUCCESS,
  UPDATE_MANUFACTURER_ORDER_ERROR,
  GET_MANUFACTURER_ORDER,
  GET_MANUFACTURER_ORDER_SUCCESS,
  GET_MANUFACTURER_ORDER_ERROR,
  DELETE_MANUFACTURER_ORDER,
  DELETE_MANUFACTURER_ORDER_SUCCESS,
  DELETE_MANUFACTURER_ORDER_ERROR,
  GET_MANUFACTURER_ORDERS,
  GET_MANUFACTURER_ORDERS_SUCCESS,
  GET_MANUFACTURER_ORDERS_ERROR
} from '../actionTypes/manufacturerOrderActionTypes';

import NotificationService from '../../services/NotificationService';

export const addManufacturerOrder = (data) => {
  return {type: ADD_MANUFACTURER_ORDER, payload: data}
};

export const addManufacturerOrderSuccess = () => {
  NotificationService.success('Manufacturer order created.');
  return {type: ADD_MANUFACTURER_ORDER_SUCCESS}
};

export const addManufacturerOrderFailure = (error) => {
  NotificationService.error(error);
  return {type: ADD_MANUFACTURER_ORDER_ERROR, payload: error}
};

export const updateManufacturerOrder = ({id, data}) => {
  return {type: UPDATE_MANUFACTURER_ORDER, payload: {id, data}}
};

export const updateManufacturerOrderSuccess = () => {
  NotificationService.success('Manufacturer order updated');
  return {type: UPDATE_MANUFACTURER_ORDER_SUCCESS}
};

export const updateManufacturerOrderFailure = (error) => {
  NotificationService.error(error);
  return {type: UPDATE_MANUFACTURER_ORDER_ERROR, payload: error}
};

export const getManufacturerOrder = (id) => {
  return {type: GET_MANUFACTURER_ORDER, payload: id}
};

export const getManufacturerOrderSuccess = (order) => {
  return {type: GET_MANUFACTURER_ORDER_SUCCESS, payload: order}
};

export const getManufacturerOrderFailure = (error) => {
  return {type: GET_MANUFACTURER_ORDER_ERROR, payload: error}
};

export const deleteManufacturerOrder = (id) => {
  return {type: DELETE_MANUFACTURER_ORDER, payload: id}
};

export const deleteManufacturerOrderSuccess = () => {
  NotificationService.success('Manufacturer Order removed');
  return {type: DELETE_MANUFACTURER_ORDER_SUCCESS}
};

export const deleteManufacturerOrderFailure = (error) => {
  return {type: DELETE_MANUFACTURER_ORDER_ERROR, payload: error}
};

export const getManufacturerOrders = (id) => {
  return {type: GET_MANUFACTURER_ORDERS, payload: id}
};

export const getManufacturerOrdersSuccess = (order) => {
  return {type: GET_MANUFACTURER_ORDERS_SUCCESS, payload: order}
};

export const getManufacturerOrdersFailure = (error) => {
  return {type: GET_MANUFACTURER_ORDERS_ERROR, payload: error}
};
