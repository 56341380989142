import React from "react";
import { withTranslation } from 'react-i18next';

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Input from "../../components/Input/Input";

import './style.scss';
import {contactsFinancePageItems} from "../../constants/constants";

class FinanceDetails extends React.Component {
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.errors !== this.props.errors && Object.keys(this.props.errors).length !== 0) {
      const fieldId = Object.keys(this.props.errors).sort((a,b) => contactsFinancePageItems[a] - contactsFinancePageItems[b])[0];
      if (document.getElementById(fieldId)) {
        document.getElementById(fieldId).focus();
      }
    }
  }

  render() {
    const {
      data,
      errors,
      onDataChange,
      t
    } = this.props;
    return(
      <div className="finance-content">
        <GridContainer>
          <GridItem className="finance-data-item" xs={4}>
            <span className="finance-data-item-title">{ t('iban') }</span>
            <Input
              autofocus={true}
              value={data.finances_iban}
              onChange={ (e) => onDataChange('finances_iban', e.target.value)}
              error={errors.finances_iban}
              errorMsg={errors.finances_iban && errors.finances_iban[0]}
              id="finances_iban"
            />
          </GridItem>
          <GridItem className="finance-data-item" xs={4}>
            <span className="finance-data-item-title">{ t('bank') }</span>
            <Input
              value={data.finances_bank}
              onChange={ (e) => onDataChange('finances_bank', e.target.value)}
              error={errors.finances_bank}
              errorMsg={errors.finances_bank && errors.finances_bank[0]}
              id="finances_bank"
            />
          </GridItem>
          <GridItem className="finance-data-item" xs={4}>
            <span className="finance-data-item-title">{ t('tax_number') }</span>
            <Input
              value={data.finances_tax_no}
              onChange={ (e) => onDataChange('finances_tax_no', e.target.value)}
              error={errors.finances_tax_no}
              errorMsg={errors.finances_tax_no && errors.finances_tax_no[0]}
              id="finances_tax_no"
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem className="finance-data-item" xs={4}>
            <span className="finance-data-item-title">{ t('bic') }</span>
            <Input
              value={data.finances_bic}
              onChange={ (e) => onDataChange('finances_bic', e.target.value)}
              error={errors.finances_bic}
              errorMsg={errors.finances_bic && errors.finances_bic[0]}
              id="finances_bic"
            />
          </GridItem>
          <GridItem className="finance-data-item" xs={4}>
            <span className="finance-data-item-title">{ t('vat_id') }</span>
            <Input
              value={data.finances_eu_vat_no}
              onChange={ (e) => onDataChange('finances_eu_vat_no', e.target.value)}
              error={errors.finances_eu_vat_no}
              errorMsg={errors.finances_eu_vat_no && errors.finances_eu_vat_no[0]}
              id="finances_eu_vat_no"
            />
          </GridItem>
          <GridItem xs={4}/>
        </GridContainer>
        <GridContainer>
          <GridItem className="finance-data-item" xs={4}>
            <span className="finance-data-item-title">{ t('cash_account') }</span>
            <Input
              value={data.cash_account}
              onChange={(e) => onDataChange('cash_account', e.target.value)}
              error={errors.cash_account}
              errorMsg={errors.cash_account && errors.cash_account[0]}
              id="cash_account"
            />
          </GridItem>
          <GridItem className="finance-data-item" xs={4}>
            <span className="finance-data-item-title">{ t('cash_account_period_8_days') }</span>
            <Input
              value={data.cash_account_period}
              onChange={(e) => onDataChange('cash_account_period', e.target.value)}
              error={errors.cash_account_period}
              errorMsg={errors.cash_account_period && errors.cash_account_period[0]}
              id="cash_account_period"
            />
          </GridItem>
          <GridItem className="finance-data-item" xs={4}>
            <span className="finance-data-item-title">{ t('credit_period_30_days') }</span>
            <Input
              value={data.credit_account_period}
              onChange={(e) => onDataChange('credit_account_period', e.target.value)}
              error={errors.credit_account_period}
              errorMsg={errors.credit_account_period && errors.credit_account_period[0]}
              id="credit_account_period"
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withTranslation()(FinanceDetails);
