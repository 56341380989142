export const GET_INQUIRIES = 'inquiryEpic/GET_INQUIRIES';
export const GET_INQUIRIES_SUCCESS = 'inquiryEpic/GET_INQUIRIES_SUCCESS';
export const GET_INQUIRIES_ERROR = 'inquiryEpic/GET_INQUIRIES_ERROR';

export const GET_INQUIRY = 'inquiryEpic/GET_INQUIRY';
export const GET_INQUIRY_SUCCESS = 'inquiryEpic/GET_INQUIRY_SUCCESS';
export const GET_INQUIRY_ERROR = 'inquiryEpic/GET_INQUIRY_ERROR';

export const CREATE_INQUIRY = 'inquiryEpic/CREATE_INQUIRY';
export const CREATE_INQUIRY_SUCCESS = 'inquiryEpic/CREATE_INQUIRY_SUCCESS';
export const CREATE_INQUIRY_ERROR = 'inquiryEpic/CREATE_INQUIRY_ERROR';

export const REMOVE_INQUIRY = 'inquiryEpic/REMOVE_INQUIRY';
export const REMOVE_INQUIRY_SUCCESS = 'inquiryEpic/REMOVE_INQUIRY_SUCCESS';
export const REMOVE_INQUIRY_ERROR = 'inquiryEpic/REMOVE_INQUIRY_ERROR';

export const UPDATE_INQUIRY = 'inquiryEpic/UPDATE_INQUIRY';
export const UPDATE_INQUIRY_SUCCESS = 'inquiryEpic/UPDATE_INQUIRY_SUCCESS';
export const UPDATE_INQUIRY_ERROR = 'inquiryEpic/UPDATE_INQUIRY_ERROR';
